import locker from "../assests/attendanceIcons/locker-icon.svg";
import uniform from "../assests/attendanceIcons/uniform-icon.svg";
import card from "../assests/attendanceIcons/card-icon.svg";
import gx from "../assests/attendanceIcons/GX.svg";
import golf from "../assests/attendanceIcons//golf.svg";
import pilates from "../assests/attendanceIcons/pilates.svg";
import session from "../assests/attendanceIcons/session.svg";
import yoga from "../assests/attendanceIcons/yoga.svg";
import weight from "../assests/icons/gym-settings-icons/weightPlate.svg";
import backMaching from "../assests/icons/gym-settings-icons/backMachine.svg";
import barbell from "../assests/icons/gym-settings-icons/barbelIcon.svg";
import cardio from "../assests/icons/gym-settings-icons/cardioMachine.svg";
import chest from "../assests/icons/gym-settings-icons/chestMachine.svg";
import freeWeight from "../assests/icons/gym-settings-icons/freeWeightMaching.svg";
import legCurl from "../assests/icons/gym-settings-icons/legCurlMachine.svg";
import legMachine from "../assests/icons/gym-settings-icons/legMachine.svg";
import smithMachine from "../assests/icons/gym-settings-icons/smithMachine.svg";
import weightSupport from "../assests/icons/gym-settings-icons/weightSupport.svg";

export const ROLES = {
  0: "staff",
  1: "trainer",
  2: "owner",
  3: "user",
  4: "admin",
};

export const WORKOUT_LISTS_TITLES = {
  TRAINER_CREATED: "Logs created by trainer",
  SHARED_BY_MEMBER: "Logs Shared by Member",
  PERSONAL: "Member's personal logs",
};

export const STAFF_STATUS = {
  employed: "Employed",
  resigned: "Resigned",
  suspended: "Suspended",
  on_leave: "On Leave",
};

export const EVENT_TYPES = {
  continuous: "Continuous",
  limited_time: "Limited Time",
  first_come_first_served: "First-come-First-served",
  re_purchase: "Re-Purchase",
  new: "New",
};

export const PRODUCT_TYPES = {
  membership: "Membership",
  PT: "PT Sessions",
  golf: "Golf Membership",
  locker: "Locker",
  uniform: "Uniform",
  gx: "GX Membership",
  yoga: "Yoga Membership",
  pt_membership: "Membership + Session",
  pilates: "Pilates Membership",
  golf_session: "Golf Sessions",
  yoga_session: "Yoga Sessions",
  pilates_session: "Pilates sessions",
};

export const MEMBER_PRODUCT_NAME = [
  "GX Membership",
  "Golf Membership",
  "Yoga Membership",
  "Pilates Membership",
];

export const SESSION_PRODUCT_NAME = [
  "Golf Sessions",
  "Yoga Sessions",
  "Pilates sessions",
];

export const MEMBER_PT_PRODUCT_NAME = [
  "GX Membership",
  "Golf Membership",
  "Yoga Membership",
  "Pilates Membership",
  "Golf Sessions",
  "Yoga Sessions",
  "Pilates sessions",
];

export const ETC_PRODUCT_NAME = ["Locker", "Uniform"];

export const REGISTER_PRODUCT_TYPES = {
  membership: "Membership",
  PT: "PT Sessions",
  golf: "Golf Membership",
  locker: "Locker",
  uniform: "Uniform",
  gx: "GX Membership",
  yoga: "Yoga Membership",
  pt_membership: "Membership + Session",
  pilates: "Pilates Membership",
  golf_session: "Golf Sessions",
  yoga_session: "Yoga Sessions",
  pilates_session: "Pilates sessions",
  etc: "etc",
};

export const PRODUCT_TYPES_LIST = [
  "Membership",
  "PT Sessions",
  "Golf Membership",
  "Locker",
  "Uniform",
  "GX Membership",
  "Yoga Membership",
  "Membership + Session",
  "Pilates Membership",
  "Golf Sessions",
  "Yoga Sessions",
  "Pilates sessions",
  "etc",
];

export const PRODUCT_IMAGE = [
  card,
  session,
  golf,
  locker,
  uniform,
  gx,
  yoga,
  card,
  pilates,
  golf,
  yoga,
  pilates,
];

export const SESSION_PRODUCT = {
  PT: "PT Sessions",
  // pt_membership: "Membership + Session",
  pilates: "Pilates Membership",
  golf_session: "Golf Sessions",
  yoga_session: "Yoga Sessions",
  pilates_session: "Pilates sessions",
  locker: "locker",
};

export const EQUIPMENT_ICONS = {
  "Maximum Dumbbell Weight": barbell,
  "Total Weight of Plates": weight,
  "Power Racks": weightSupport,
  "Smith Machines": smithMachine,
  "Chest Machines": chest,
  "Back Machines": backMaching,
  "Leg Machines": legMachine,
  "Cardio Machines": cardio,
  "Free weight equipment": freeWeight,
  "Leg Curl machines": legCurl,
};
