import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
class MembersSublyApi {
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${BASE_URL}${endpoint}`;
    const headers = { ...header };
    try {
      const result = await axios({ url, method, data, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /* ------Registration API -----*/

  static async registrationMember(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    let res = await this.request(`register-member`, data, "post", header);
    return res;
  }

  /* ------Product list API -----*/

  static async gymProductList(token, id) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `products?userId=${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------Member list API -----*/

  static async memberList(offset, token, name, number) {
    const header = { Authorization: `Bearer ${token}` };
    if (name || number) {
      let res = await this.request(
        `get-members?page=${offset}&name=${name}&cellphone=${number}`,
        undefined,
        "get",
        header
      );
      return res;
    } else {
      let res = await this.request(
        `get-members?page=${offset}`,
        undefined,
        "get",
        header
      );
      return res;
    }
  }

  /* ------Locker list API -----*/

  static async lockerList(offset, status, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `locker?page=${offset}&status=${status}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------MEMBER DETAIL API -----*/

  static async memberDetail(id, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `admin/get-member/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------MEMBER AUTHENTICATION API -----*/

  static async memberAuth(token, id) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `member/authorize/${id}`,
      undefined,
      "post",
      header
    );
    return res;
  }

  /* -----MEMO DELETE API -----*/

  static async memoDelete(id, memoId, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `memo/${id}?memoId=${memoId}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  /* -----MEMO EDIT API -----*/

  static async memoEdit(id, data, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`memo/${id}`, data, "put", header);
    return res;
  }

  /* -----ADD MEMO API -----*/

  static async addMemo(id, data, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`memo/${id}`, data, "post", header);
    return res;
  }

  /* -----LOCKER EDIT API -----*/

  static async editLocker(id, data, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`locker/${id}`, data, "put", header);
    return res;
  }

  /* -----LOCKER DELETE API -----*/

  static async deleteLocker(id, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`locker/${id}`, undefined, "delete", header);
    return res;
  }

  /* ------PRODUCT LIST API -----*/

  static async productList(id, type, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `products-info/${id}?type=${type}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------SELECT GYM API -----*/

  static async selectYourGym(id, role, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `gym/select/${id}?role=${role}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------EDIT MEMBER INFO API -----*/

  static async editMemberInfo(id, data, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`edit-member/${id}`, data, "put", header);
    return res;
  }

  /* ------TRAINER LIST API -----*/

  static async fetchTrainerList(name, token, id) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `admin/trainer-list?name=${name}&trainerId=${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------ CHANGE TRAINER  API -----*/

  static async changeTrainer(token, data) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`change-trainer`, data, "post", header);
    return res;
  }

  /* ------fetch detail through member code-----*/

  static async fetchMemberCode(code, token, id) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `admin/get-member-code/${code}?userId=${id || ""}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------take over product and membership-----*/

  static async takeOver(data, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`admin/take-over`, data, "post", header);
    return res;
  }

  /* ------refund product list-----*/

  static async refundProductList(id, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `admin/member-products/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------refund product API-----*/

  static async refundProduct(data, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`admin/refund-products`, data, "post", header);
    return res;
  }

  /* ------Update product API -----*/

  static async memberProductUpdate(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`add-product`, data, "post", header);
    return res;
  }

  /* ------product hoalding API -----*/

  static async memberProductHolding(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/pause-member-products`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ------product hoalding list API -----*/

  static async productHoldingList(token, memberId, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/complete-pause-req/${memberId}/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------product hoalding delete API -----*/

  static async deleteProductHolding(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/pause/${id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  // =============API to know staff permission============
  static async staffPermission(token, memberId, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `staff/authority/${memberId}`,
      undefined,
      "get",
      header
    );
    return res;
  }
}

export default MembersSublyApi;
