import React, { useState } from "react";
import { strings } from "../../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import GymSettingSublyApi from "../../../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import Loader from "../../../../../../utils/Loader";
import ErrorModal from "../../../../../commonComponents/modalScreens/ErrorModal";
import $ from "jquery";

const EditInfoSettings = ({
  onClose,
  classNameValue,
  setIsLoader,
  infoData,
}) => {
  const { token } = useSelector((state) => state.user);
  const [time, setTime] = useState(infoData?.ptCancelHour);
  const [min, setMin] = useState(infoData?.ptCancelMin);
  const [showTime, setShowTime] = useState(false);
  const [showMin, setShowMin] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  async function upDatePtInfo() {
    setIsLoader(true);
    const data = {
      gymIntroduction: {
        ptCancelHour: time,
        ptCancelMin: min,
      },
    };
    await GymSettingSublyApi.editGymInfo(token, data).then((response) => {
      setIsLoader(false);
      if (response.statusCode === 200) {
        onClose(false);
      } else {
        setOpenModal(true);
        seterrorMessage(response.data.message);
      }
    });
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setShowTime(false);
      setShowMin(false);
    }
  });

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[70%] max-lg:w-[90%] max-h-[50%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 my-auto"}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[80%] m-auto border-b border-[#505050]">
            {
              <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
                {strings.EDIT_INFORMATION_SETTING}
              </p>
            }
          </div>
          <p className="text-white text-center text-2xl font-bold mt-4 mb-4">
            {strings.SET_CANCEL_TIME_OF_PT}
          </p>
          <div className="bg-[#414142] flex items-center gap-5 p-4 rounded-[10px] w-[70%] mx-auto justify-center">
            <div className="flex items-center gap-2.5">
              {" "}
              <div
                className="bg-[#2C2C2E] flex items-center justify-between py-2 w-36 px-2 rounded-[10px]  relative cursor-pointer dropdownClass "
                onClick={() => {
                  setShowTime(!showTime);
                  setShowMin(false);
                }}
              >
                <p className="!mb-0 text-xl font-extrabold">{time}</p>
                <Icon
                  icon="icon-park-solid:down-one"
                  width="20"
                  height="20"
                  style={{ color: "white", cursor: "pointer" }}
                  rotate={showTime ? 2 : 4}
                />
                {showTime && <TimeDropDown setTime={setTime} />}
              </div>
              <p className="!mb-0 text-white text-lg font-bold">
                {strings.HOURS}
              </p>
            </div>
            <div className="flex items-center gap-2.5">
              <div
                className="bg-[#2C2C2E] flex items-center justify-between p-2 w-36 px-2 rounded-[10px] relative cursor-pointer dropdownClass"
                onClick={() => {
                  setShowMin(!showMin);
                  setShowTime(false);
                }}
              >
                <p className="!mb-0 text-xl font-extrabold">{min}</p>
                <Icon
                  icon="icon-park-solid:down-one"
                  width="20"
                  height="20"
                  style={{ color: "white", cursor: "pointer" }}
                  rotate={showMin ? 2 : 4}
                />
                {showMin && <MinDropDown setMin={setMin} />}
              </div>
              <p className="!mb-0 text-white text-lg font-bold">
                {strings.MINUTES}
              </p>
            </div>
          </div>
          <div
            className={` ${"flex-wrap flex items-center justify-center gap-3 mt-20"}`}
          >
            <button
              onClick={() => {
                onClose(false);
              }}
              className={`${"max-sm:w-full text-white kakaoClass hover:text-black hover:bg-common_color disabled:bg-[#ccff0094]"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px] hover:text-black hover:bg-common_color disabled:bg-[#ccff0094]"}`}
            >
              {strings.CLOSE}
            </button>
            <button
              className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              onClick={() => {
                upDatePtInfo();
              }}
            >
              {strings.UPDATE}
            </button>
            ;
          </div>
        </div>
      </div>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
    </section>
  );
};

export default EditInfoSettings;

export function TimeDropDown({ setTime }) {
  const timeData = [
    { hours: "0", val: 0 },
    { hours: "1", val: 1 },
    { hours: "2", val: 2 },
    { hours: "3", val: 3 },
    { hours: "4", val: 4 },
    { hours: "5", val: 5 },
    { hours: "6", val: 6 },
    { hours: "7", val: 7 },
    { hours: "8", val: 8 },
    { hours: "9", val: 9 },
    { hours: "10", val: 10 },
    { hours: "11", val: 11 },
    { hours: "12", val: 12 },
    { hours: "13", val: 13 },
    { hours: "14", val: 14 },
    { hours: "15", val: 15 },
    { hours: "16", val: 16 },
    { hours: "17", val: 17 },
    { hours: "18", val: 18 },
    { hours: "19", val: 29 },
    { hours: "20", val: 20 },
    { hours: "21", val: 21 },
    { hours: "22", val: 22 },
    { hours: "23", val: 23 },
    { hours: "24", val: 24 },
  ];
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[50px] right-0 top-10 max-h-[250px] overflow-auto z-20 px-3">
      {/* <p
        className="!mb-0 text-white font-bold py-2 text-center cursor-pointer whitespace-nowrap"
        onClick={() => {
          setTime("");
        }}
      >
        --{strings.NOT_SELECTED}--
      </p> */}
      {timeData.map((item) => {
        return (
          <div
            onClick={() => {
              setTime(item.hours);
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{item.hours} hr</span>
          </div>
        );
      })}
    </div>
  );
}
export function MinDropDown({ setMin }) {
  const timeData = [
    { min: "0", val: 0 },
    { min: "1", val: 1 },
    { min: "2", val: 2 },
    { min: "3", val: 3 },
    { min: "4", val: 4 },
    { min: "5", val: 5 },
    { min: "6", val: 6 },
    { min: "7", val: 7 },
    { min: "8", val: 8 },
    { min: "9", val: 9 },
    { min: "10", val: 10 },
    { min: "11", val: 11 },
    { min: "12", val: 12 },
    { min: "13", val: 13 },
    { min: "14", val: 14 },
    { min: "15", val: 15 },
    { min: "16", val: 16 },
    { min: "17", val: 17 },
    { min: "18", val: 18 },
    { min: "19", val: 29 },
    { min: "20", val: 20 },
    { min: "21", val: 21 },
    { min: "22", val: 22 },
    { min: "23", val: 23 },
    { min: "24", val: 24 },
    { min: "25", val: 25 },
    { min: "26", val: 26 },
    { min: "27", val: 27 },
    { min: "28", val: 28 },
    { min: "29", val: 29 },
    { min: "30", val: 30 },
    { min: "31", val: 31 },
    { min: "32", val: 32 },
    { min: "33", val: 33 },
    { min: "34", val: 34 },
    { min: "35", val: 35 },
    { min: "36", val: 36 },
    { min: "37", val: 37 },
    { min: "38", val: 38 },
    { min: "39", val: 39 },
    { min: "40", val: 40 },
    { min: "41", val: 41 },
    { min: "42", val: 42 },
    { min: "43", val: 43 },
    { min: "44", val: 44 },
    { min: "45", val: 45 },
    { min: "46", val: 46 },
    { min: "47", val: 47 },
    { min: "48", val: 48 },
    { min: "49", val: 49 },
    { min: "50", val: 50 },
    { min: "51", val: 51 },
    { min: "52", val: 52 },
    { min: "53", val: 53 },
    { min: "54", val: 54 },
    { min: "55", val: 55 },
    { min: "56", val: 56 },
    { min: "57", val: 57 },
    { min: "58", val: 58 },
    { min: "59", val: 59 },
  ];
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[100px] right-0 top-10 max-h-[200px] overflow-auto z-20 px-3">
      {/* <p
        className="!mb-0 text-white font-bold py-2 text-center cursor-pointer whitespace-nowrap"
        onClick={() => {
          setMin("");
        }}
      >
        --{strings.NOT_SELECTED}--
      </p> */}
      {timeData.map((item) => {
        return (
          <div
            onClick={() => {
              setMin(item.min);
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            {/* <Icon icon="bi:dot" width="35" height="35" /> */}
            <span className="mb-0 text-lg font-bold">{item.min} min</span>
          </div>
        );
      })}
    </div>
  );
}
