import React from "react";
import { strings } from "../../../utils/String";
import { InputGroup, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Icon } from "@iconify-icon/react";
import MembersSublyApi from "../../../helper/MemberApi";
import { useSelector } from "react-redux";
import Moment from "react-moment";

function TakeOverModal({
  classNameValue,
  openTakeOver,
  setOpenTakeOver,
  setProductId,
  setUserData,
  userData,
  error,
  setError,
  otp,
  setOtp,
  productId,
  setTakeOverProduct,
  takeOverProduct,
  setUserdetail,
  setNewTakeOver,
}) {
  const { token } = useSelector((state) => state.user);

  // ============API for getting member code details============
  async function fetchMemberCodehandle() {
    await MembersSublyApi.fetchMemberCode(otp, token)
      .then((response) => {
        if (response.statusCode == 200) {
          if (response.data?.memberOwnProducts) {
            setUserData(response.data);
            setError("");
          } else {
            setError(strings.NO_USER_FOUND);
            setUserData("");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass classForMargin"}`}
        show={openTakeOver}
        onHide={() => {
          setOpenTakeOver(false);
          setUserData("");
          setOtp("");
          setError("");
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8 overflow-auto max-h-[calc(100vh_-_150px)]">
          <div className="border-b border-[#444444] flex items-center justify-between mb-4">
            <p></p>
            <p className="!mb-2 pb-2 text-[40px] max-lg:text-[32px] font-extrabold">
              {strings.TAKE_OVER}
            </p>
            <Icon
              icon="maki:cross"
              width="24"
              height="24"
              style={{ color: "white" }}
              onClick={() => {
                setOpenTakeOver(false);
                setUserData("");
                setOtp("");
                setError("");
              }}
              className="cursor-pointer"
            />
          </div>
          <p className="text-[30px] max-lg:text-[20px] font-bold">
            {strings.FROM_USER_CODE}
          </p>
          <div className=" bg-[#1F2022] rounded-[15px] text-[30px] w-[400px] m-auto font-bold flex items-center justify-center gap-4 py-2">
            #
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              containerStyle={"justify-center gap-4"}
              inputStyle={
                "text-center text-3xl bg-[#1F2022] border-b border-[#444444]  text-white"
              }
            />
          </div>
          <div className="flex justify-center my-4">
            <button
              disabled={otp ? false : true}
              onClick={() => {
                fetchMemberCodehandle();
              }}
              className="bg-common_color rounded-[28px] h-[40px] w-[104px] text-black text-sm font-extrabold disabled:bg-[#ccff0094]"
            >
              {strings.SEARCH}
            </button>
          </div>
          <div className="flex justify-center mt-3">
            <div>
              {userData?.productsList
                ? Object.values(userData?.productsList)
                    .flat(2)
                    .map((items, index) => {
                      return (
                        <div
                          className="flex justify-start items-center gap-1"
                          key={index}
                        >
                          <InputGroup className="checkboxClass">
                            <InputGroup.Checkbox
                              className="w-[31px] h-[31px] bg-[#CECECE80]"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const id = [...productId];
                                  const products = [...takeOverProduct];
                                  id.push(items.id);
                                  products.push(items);
                                  setProductId(id);
                                  setTakeOverProduct(products);
                                } else {
                                  const id = [...productId];
                                  const products = [...takeOverProduct];
                                  id.splice(index, 1);
                                  products.splice(index, 1);
                                  setProductId(id);
                                  setTakeOverProduct(products);
                                }
                              }}
                            />
                          </InputGroup>

                          {items?.category !== "PT" ? (
                            <p
                              className={`text-[22px] max-lg:text-base text-start font-bold mb-0 flex items-center gap-2`}
                            >
                              {items.productName}
                              <Icon
                                icon="lets-icons:date-today"
                                width="25"
                                height="25"
                                style={{ color: "#CCFF00" }}
                              />
                              <Moment format="YY.MM.DD">
                                {items?.membershipStartDate}
                              </Moment>{" "}
                              {"~"}{" "}
                              <Moment format="YY.MM.DD">
                                {items?.membershipEndDate}
                              </Moment>
                            </p>
                          ) : (
                            <p className="text-[22px] max-lg:text-base font-bold mb-0 flex items-center gap-2">
                              {items.productName} ,{" "}
                              {`(${
                                items.sessionCount - items.sessionCompleted
                              } sessions left)`}
                            </p>
                          )}
                        </div>
                      );
                    })
                : ""}
            </div>
          </div>
          {error && (
            <p className="text-[30px] max-lg:text-xl font-extrabold text-[#FFB0B0] my-5">
              {error}
            </p>
          )}
          {productId.length > 0 && (
            <div className="flex justify-center mt-6">
              <button
                onClick={() => {
                  setUserdetail(userData);
                  setOpenTakeOver(false);
                  setNewTakeOver(true);
                }}
                className="text-lg font-extrabold text-white kakaoClass h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.CONFIRM}
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TakeOverModal;
