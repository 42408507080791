import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import profile from "../../../assests/images/demoProfile.svg";
import sessionCard from "../../../assests/icons/statistics PT session/sessionCardColor.svg";
import { strings } from "../../../utils/String";
import dateModify from "../../../assests//icons/dateModify.svg";
import intensity from "../../../assests/icons/review-detail-icons/workoutIntensity.svg";
import direction from "../../../assests/icons/review-detail-icons/workoutDirection.svg";
import punchulity from "../../../assests/icons/review-detail-icons/sessionPunchulity.svg";
import workoutType from "../../../assests/icons/review-detail-icons/workoutType.svg";
import communication from "../../../assests/icons/review-detail-icons/communication.svg";
import feedbackTime from "../../../assests/icons/review-detail-icons/feedbackTiming.svg";
import duration from "../../../assests/icons/review-detail-icons/workoutDuration.svg";
import dumble from "../../../assests/icons/review-detail-icons/dumbleIcon.svg";
import trainer from "../../../assests/icons/boldTrainer.svg";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";

const iconObj = {
  workoutSatisfaction: { type: "Workout satisfaction", icon: dumble },
  workoutIntensity: { type: "Workout intensity", icon: intensity },
  workoutDuration: { type: "Workout Duration", icon: duration },
  workoutDirection: { type: "Workout direction", icon: direction },
  workoutTypeSatisfaction: {
    type: "Workout type satisfaction",
    icon: workoutType,
  },
  communicationSatisfaction: {
    type: "Communication satisfaction",
    icon: communication,
  },
  feedbackTimingSatisfaction: {
    type: "Feedback timing satisfaction",
    icon: feedbackTime,
  },
  sessionPunctuality: { type: "Session Punctuality", icon: punchulity },
};

function ReviewDetail({
  setOpenDetail,
  openDetail,
  setIsLoader,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [detailValue, setDetailValue] = useState("");

  // =============FETCH REVIEW DETAIL API==============
  useEffect(() => {
    async function fetchReviewDetail() {
      setIsLoader(true);
      await PT_InformationSublyApi.fetchReviewDetail(token, openDetail?.id)
        .then((res) => {
          setIsLoader(false);
          if (res.statusCode == 200) {
            setDetailValue(res.data?.reviews);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchReviewDetail();
  }, []);

  const reviewDetail = Object.keys(iconObj).map((field) => ({
    key: field,
    type: iconObj[field]?.type,
    icon: iconObj[field]?.icon,
    rating: detailValue[field],
    discribe: detailValue[`${field}Reason`],
  }));

  return (
    <section
      className={`${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[65%] max-xl:w-[90%] max-h-[93%] py-3 px-7 mt-8 z-20 overflow-auto"}`}
      >
        <div className="w-full m-auto">
          <div className="flex items-center justify-between gap-3 flex-wrap pt-2 pb-4 border-b border-[#505050]">
            <div className="flex items-center gap-x-5 flex-wrap gap-y-3">
              <div className="flex items-center gap-2">
                <img
                  src={
                    detailValue?.userProfile?.mProfileImg?.prof_img1_min
                      ? detailValue?.userProfile?.mProfileImg.prof_img1_min
                      : profile
                  }
                  alt="profile-img"
                  className="rounded-full w-[65px] h-[65px]"
                />
                <p className="mb-0 text-[28px] font-extrabold border-r border-[#7B7B7B] pr-6">
                  {detailValue?.userProfile?.name}
                </p>
              </div>
              <div className="flex items-center gap-2 border-r border-[#7B7B7B] pr-6">
                <img src={sessionCard} alt="session-card" />
                <p className="mb-0 text-2xl font-bold">
                  {detailValue?.sessionDetails?.sessionCompleted}
                  <span className="text-white text-lg">
                    /{detailValue?.sessionDetails?.totalCount}
                  </span>
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Icon
                  icon="gridicons:calendar"
                  width="32px"
                  height="32px"
                  style={{ color: " #C5C5C5" }}
                />
                <p className="mb-0 text-2xl font-bold ">
                  {moment(detailValue?.sessionDetails?.bookedDate).format(
                    "YY.MM.DD"
                  )}
                </p>
              </div>
            </div>
            <p className="mb-0 text-[30px] font-extrabold flex items-center gap-2">
              <Icon
                icon="tabler:star-filled"
                width="40"
                height="40"
                style={{ color: "#CCFF00" }}
              />
              {detailValue?.avgRating?.toFixed(1)}
            </p>
          </div>
          <div className="px-3 py-3">
            <div className="flex items-center justify-end gap-3">
              <p className="mb-0 text-2xl font-extrabold">
                {strings.WRITTEN_ON}:
              </p>
              <p
                style={{ border: "1px solid #505050" }}
                className="mb-0 flex items-center gap-1 text-2xl font-extrabold rounded-[5px] px-2 py-1"
              >
                <img src={dateModify} alt="modify" />
                {moment(detailValue?.regDate).format("YY.MM.DD")}
              </p>
            </div>
            <div className="my-3">
              {reviewDetail.map((val, index) => (
                <div
                  key={index}
                  className={`${" py-3 border-b border-[#505050] last:border-b-0"}`}
                >
                  <div className="flex items-center justify-between gap-3 flex-wrap">
                    <div className="flex items-center gap-x-6">
                      <img src={val.icon} />
                      <p className="mb-0 text-2xl font-extrabold">{val.type}</p>
                    </div>
                    <div className="flex items-center gap-1.5 ">
                      {[1, 2, 3, 4, 5].map((item) =>
                        item <= val.rating ? (
                          <Icon
                            icon="ph:star-fill"
                            width="25"
                            height="25"
                            style={{ color: "#F6EB61" }}
                          />
                        ) : (
                          <Icon
                            icon="ph:star-light"
                            width="25"
                            height="25"
                            style={{ color: "#616161" }}
                          />
                        )
                      )}
                    </div>
                  </div>
                  {val.rating <= 2 && (
                    <div className="flex items-center justify-end">
                      {" "}
                      <p className="mb-0 text-lg font-bold w-[94%]">
                        {val?.discribe}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div
              className="flex items-center gap-x-1 gap-y-2 bg-[#2C2C2E] rounded-[5px] w-max px-2 py-1"
              style={{ border: "1px solid #ccff0073" }}
            >
              <img src={trainer} alt="trainer" className="w-[22px] h-[23px]" />
              <span className="text-2xl font-bold text-[#CCCDCD]">
                {strings.TRAINER}:
              </span>
              <p className="mb-0 text-2xl font-bold max-w-[90px] overflow-hidden text-ellipsis whitespace-nowrap">
                {detailValue.gymStaffInfo?.gymStaffName}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center mt-2 pt-4 mb-3 border-t border-[#505050]">
            <button
              onClick={() => {
                setOpenDetail({ status: false, id: "" });
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReviewDetail;
