import { Icon } from "@iconify-icon/react";
import React from "react";

function Loader() {
  return (
    <div className="loaderClass block_notify">
      <Icon
        icon="eos-icons:bubble-loading"
        width="90"
        height="90"
        style={{ color: "#CCFF00" }}
        className="loader-icon"
      />
    </div>
  );
}

export default Loader;
