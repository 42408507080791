import React from "react";
import { strings } from "../../../utils/String";
import { InputGroup, Modal } from "react-bootstrap";

function ChangeProductStatus({
  changeStatus,
  setChangeStatus,
  head,
  setChangeValue,
  changeValue,
  itemValue,
  changeStatusHandle,
}) {
  return (
    <Modal
      className={`${"modalBoxClass, modalWidthClass"}`}
      show={changeStatus}
      onHide={() => {
        setChangeStatus(false);
      }}
    >
      <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
        <div className="w-[80%] m-auto border-b-4 border-[#444444]">
          <p className="border-b-2 border-[#444444] max-lg:text-[28px] pb-4 mb-6 text-[40px] font-bold ">
            {head}
          </p>
          <div className="flex items-center gap-x-2.5 mt-7 mb-4 flex-wrap bg-[#414142] py-1.5 px-3 rounded-[10px]">
            <p className="mb-0 text-[20px] text-[#FFFFFFBF] font-bold">
              {strings.CHANGE_STATUS_TO}
            </p>
            <div className="flex items-center">
              {" "}
              <InputGroup className="checkboxClass paddingManage !p-0">
                <InputGroup.Checkbox
                  className="w-[31px] h-[31px] !bg-[#353638] !p-0"
                  value={3}
                  checked={changeValue == 3 ? true : false}
                  onChange={(e) => {
                    setChangeValue(e.target.value);
                  }}
                />
              </InputGroup>
              <p className="mb-0 text-[22px] font-bold">{strings.ON_SALE}</p>
            </div>
            <div className="flex items-center gap-x-2.5 flex-wrap">
              {" "}
              <div className="flex items-center ">
                <InputGroup className="checkboxClass paddingManage !p-0">
                  <InputGroup.Checkbox
                    className="w-[31px] h-[31px] !bg-[#353638] !p-0"
                    value={1}
                    checked={changeValue == 1 ? true : false}
                    onChange={(e) => {
                      setChangeValue(e.target.value);
                    }}
                  />
                </InputGroup>
                <p className="mb-0 text-[22px] font-bold">{strings.EXPIRED}</p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-2.5 mt-3 mb-4 flex-wrap bg-[#414142] py-3.5 px-3 rounded-[10px]">
            <p className="mb-0 text-[20px] text-[#FFFFFFBF] font-bold">
              {itemValue?.productName || itemValue?.eventName}:
            </p>
            <div className="flex items-center">
              <p className="mb-0 text-[24px] font-extrabold">
                {" "}
                {itemValue?.periodMonths || itemValue?.periodMonth}
                {itemValue?.periodMonths ||
                  (itemValue?.periodMonth && "M")}{" "}
                {itemValue?.addonMonths || itemValue?.addonMonth
                  ? `(${
                      itemValue?.addonMonths || itemValue?.addonMonth
                        ? `+${itemValue?.addonMonths || itemValue?.addonMonth}m`
                        : ""
                    } ${
                      itemValue?.addonDays ? `+${itemValue?.addonDays}d` : ""
                    })`
                  : ""}{" "}
                {`(${strings.ALWAYS_AVAILABLE})`}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-8 ">
          <button
            onClick={() => {
              setChangeStatus(false);
            }}
            className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass w-[160px] h-[60px] disabled:bg-[#ccff0094]"
          >
            {strings.CLOSE}
          </button>
          <button
            disabled={changeValue != 3 && changeValue == 1 ? false : true}
            onClick={() => {
              changeStatusHandle();
            }}
            className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
          >
            {strings.CONFIRM}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ChangeProductStatus;
