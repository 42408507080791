import React, { useEffect, useState } from "react";
import { strings } from "../../../../../utils/String";
import Header from "../../../../commonComponents/Header";
import SideBar from "../../../../commonComponents/SideBar";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import dumbbell from "../../../../../assests/icons/dumbbell.svg";
import setting from "../../../../../assests/icons/setting-icon.svg";
import ptIcon from "../../../../../assests/icons/person-icon.svg";
import gallery from "../../../../../assests/images/gallery.png";
import calendarIcon from "../../../../../assests/icons/calender-icon.svg";
import parkingIcon from "../../../../../assests/icons/parking-icon.svg";
import watchIcon from "../../../../../assests/icons/watch-icon.svg";
import ptSessionIcon from "../../../../../assests/icons/pt-session-icon.svg";
import showerIcon from "../../../../../assests/icons/shower-icon.svg";
import instIcon from "../../../../../assests/icons/insta-icon.svg";
import locationIcon from "../../../../../assests/icons/location-icon.svg";
import telephoneIcon from "../../../../../assests/icons/telephone-icon.svg";
import dumbbellIcon from "../../../../../assests/icons/holiday-icon.svg";
import ceoIcon from "../../../../../assests/icons/ceo-icon.svg";
import gymIcon from "../../../../../assests/icons/Gym.svg";
import EditGymInformation from "./EditGymInformation";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import Loader from "../../../../../utils/Loader";
import moment from "moment";
import RegisterHoliday from "./RegisterHoliday";
import Holidays from "./Holidays";
import { scrollToTopFun } from "../../StaffSetting";
import manBody from "../../../../../assests/icons/male-body-icon.svg";
import femaleBody from "../../../../../assests/icons/female-body-icon.svg";
import { FormMedia } from "../../../boardScreens/RegistrationForm";
import $ from "jquery";
import { formatCustomNumber } from "../../../../../utils/commonUtils";

function GymInfoScreen() {
  const { gymId } = useSelector((state) => state.user.selectedGym);
  const { token } = useSelector((state) => state.user);
  const [showDropDown, setshowDropDown] = useState(false);
  const [gymText, setGymText] = useState({ str: "Gym Information", val: 0 });
  const [editGymInfo, setEditGymInfo] = useState(false);
  const [gymData, setGymData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [registerHoliday, setRegisterHoliday] = useState(false);
  const [holidayCalander, setHolidayCalander] = useState(false);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [updateHoliday, setupdateHoliday] = useState(false);

  useEffect(() => {
    !registerHoliday && getGymInfo();
  }, [editGymInfo, registerHoliday]);
  async function getGymInfo() {
    setIsLoader(true);
    await GymSettingSublyApi.getGymDetails(token)
      .then((response) => {
        // console.log("-------response", response);
        setIsLoader(false);
        if (response.statusCode == 200) {
          setGymData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0
    ) {
      if (editGymInfo && !registerHoliday) {
        setEditGymInfo(false);
      } else if (registerHoliday && editGymInfo) {
        setEditGymInfo(true);
        setRegisterHoliday(false);
      } else if (registerHoliday && holidayCalander) {
        setHolidayCalander(true);
        setRegisterHoliday(false);
      } else if (!registerHoliday && holidayCalander) {
        setHolidayCalander(false);
      }
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`${
              editGymInfo || registerHoliday
                ? "!overflow-hidden  h-screen"
                : "!overflow-auto"
            } ${"p-0 relative"}`}
          >
            <Header text={strings.GYM_SETTING_GYM} />
            <div className="mx-6 ">
              <div className="flex items-center justify-between my-1 flex-wrap gap-3 mt-4">
                <p className="mb-0 text-2xl font-extrabold">
                  {strings.GYM_INFORMATION}
                </p>
                <div className="flex items-center gap-x-3 max-sm:flex-wrap gap-y-3">
                  <button
                    className={`${"text-base font-bold h-[50px] w-[120px]  rounded-[28px] text-white kakaoClass"}`}
                    onClick={() => {
                      setHolidayCalander(true);
                      scrollToTopFun();
                    }}
                  >
                    <p className="!mb-0 text-lg font-bold">{strings.HOLIDAY}</p>
                  </button>
                  <button
                    className={`${"text-base font-bold h-[50px] w-[120px] rounded-[28px] hover:text-black  text-white  hover:bg-[#CCFF00]  kakaoClass"}`}
                    onClick={() => {
                      setEditGymInfo(true);
                      scrollToTopFun();
                      // alert("hello")
                    }}
                  >
                    <span className="!mb-0 text-lg font-bold">
                      {strings.EDIT}
                    </span>
                  </button>
                </div>
              </div>
              <div className="rounded-[10px] bg-[#2C2C2E] p-4 my-4">
                <Container fluid>
                  <Row>
                    <Col
                      lg="6"
                      className="  lg:border-r-[1px] lg:border-[#444444]"
                    >
                      <div className="flex flex-wrap  gap-3 items-center my-3">
                        <div className="flex flex-wrap  items-center gap-3">
                          <img src={gymIcon} alt="gym-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Gym Name :
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold w-[60%]">
                          {gymData?.name || "--"}
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={calendarIcon} alt="loading" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Founded:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold w-[60%]">
                          {moment(
                            gymData?.gymIntroduction?.establishDate || undefined
                          ).format("YYYY.MM.DD")}
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={parkingIcon} alt="parking-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32 ">
                            Parking:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold w-[60%]">
                          {gymData?.gymIntroduction?.supportParkingHour} Hours{" "}
                          {gymData?.gymIntroduction?.supportParkingMin} Min Free
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={watchIcon} alt="watch-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Working Time:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold flex items-center gap-2 flex-wrap w-[60%] ">
                          {Object.keys(gymData?.workingHour || {})?.map(
                            (val, index) => {
                              // console.log("-------TIME VAL", gymData?.workingHour);
                              return (
                                <p
                                  key={index}
                                  className="mb-0 text-xl font-bold capitalize"
                                >
                                  {val}:{" "}
                                  {/* {`${moment(
                                    gymData?.workingHour[val],
                                    "HH:mm"
                                  ).format("h:mm A")} - ${moment(
                                    gymData?.workingHour[val],
                                    "HH:mm"
                                  ).format("h:mm A")}`} */}
                                  {gymData?.workingHour[val]}
                                  {`${
                                    index + 1 !==
                                    Object.keys(gymData?.workingHour).length
                                      ? ","
                                      : ""
                                  }`}
                                </p>
                              );
                            }
                          )}
                          {!gymData?.workingHour && "--"}
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={ptSessionIcon} alt="pt-session" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            PT sessions:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold w-[60%]">
                          {gymData?.gymIntroduction?.ptSessions || "--"}
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={showerIcon} alt="shower-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Shower:
                          </p>
                        </div>
                        <div className="flex flex-wrap gap-2">
                          {" "}
                          <p className="!mb-0 text-[22px] text-white font-bold  flex items-start gap-1">
                            <img src={manBody} alt="man-body" />
                            {gymData?.gymIntroduction?.menShowerFacil} male
                            booth
                          </p>
                          <p className="!mb-0 text-[22px] text-white font-bold  flex items-start gap-1 mt-0">
                            <img src={femaleBody} alt="female-body" />
                            {gymData?.gymIntroduction?.womenShowerFacil} female
                            booth
                          </p>
                        </div>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={instIcon} alt="inst-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Instagram:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold w-[60%]">
                          {gymData?.gymIntroduction?.instaId || "--"}
                        </p>
                      </div>
                    </Col>
                    <Col lg="6" className="lg:pl-16 lg:pr-10 ">
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={locationIcon} alt="location-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Address:
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <p className="!mb-0 text-[22px] text-white font-bold ">
                            {gymData?.gymIntroduction?.address1 || "--"}
                          </p>
                          {/* <Icon
                            icon="mingcute:location-3-line"
                            width="24"
                            height="24"
                            style={{ color: "white" }}
                          /> */}
                        </div>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={ceoIcon} alt="ceo-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            CEO:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold w-[60%]">
                          {gymData?.ownerProfile?.name || "--"}
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={telephoneIcon} alt="telephone-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Telephone:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold w-[60%]">
                          {formatCustomNumber(gymData?.gymTelephone) || "--"}
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={dumbbellIcon} alt="dumbell-icon" />
                          <p className="text-lg font-bold text-white !mb-0 w-32 ">
                            Holidays:
                          </p>
                        </div>
                        <div className="flex items-center gap-x-3 flex-wrap">
                          {gymData?.gymIntroduction?.holidays?.map(
                            (val, index, array) =>
                              index <= 3 && (
                                <p
                                  key={index}
                                  className="!mb-0 text-[22px] text-white font-bold whitespace-nowrap mt-0" 
                                >
                                  {val.name || "--"}
                                  {index < Math.min(array.length - 1, 3)
                                    ? ","
                                    : ""}
                                </p>
                              )
                          ) || "--"}
                          {gymData?.gymIntroduction?.holidays?.length > 4 && (
                            <p
                              onClick={() => setHolidayCalander(true)}
                              className="mb-0 text-base font-bold text-common_color cursor-pointer"
                            >
                              More...
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={dumbbell} alt="dumb-bell" />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Exercises:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold ">
                          {gymData?.gymIntroduction?.programs || "--"}
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={setting} />
                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            Provides:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold ">
                          {gymData?.gymIntroduction?.provides || "--"}
                        </p>
                      </div>
                      <div className="flex  gap-3 items-center my-3">
                        <div className="flex  items-center gap-3">
                          <img src={ptIcon} alt="pt=icon" />

                          <p className="text-lg font-bold text-white !mb-0 w-32">
                            PT Trainers:
                          </p>
                        </div>
                        <p className="!mb-0 text-[22px] text-white font-bold flex flex-col sm:flex-row gap-2">
                          <p className="!mb-0 text-[22px] text-white font-bold  flex items-start gap-1">
                            <img src={manBody} alt="man-body" />
                            {gymData?.gymIntroduction?.maleStaffs} male trainer
                          </p> 
                          <p className="!mb-0 text-[22px] text-white font-bold  flex items-start gap-1 mt-0">
                            <img src={femaleBody} alt="man-body"/>
                            {gymData?.gymIntroduction?.femaleStaffs} female trainer
                          </p>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <div className="border-t-[1px] border-[#444444] flex gap-3">
                  {gymData?.gymBgImages?.map((val, index) =>
                    val.type == "image" ? (
                      <div
                        key={index}
                        className="bg-[#949494] w-52	h-52 rounded-[10px] flex items-center justify-center my-4 mx-2"
                      >
                        <img
                          src={val.url}
                          className="rounded-[10px] w-full h-full object-cover"
                        />
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="bg-[#949494] w-52	h-52 rounded-[10px] flex items-center justify-center my-4 mx-2 relative"
                      >
                        <img src={val.url} />
                        <span
                          onClick={() => {
                            setVideoPreview(true);
                            setuploadedMedia(val);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            {editGymInfo && (
              <EditGymInformation
                onClose={setEditGymInfo}
                gymData={gymData}
                setRegisterHoliday={setRegisterHoliday}
                setIsLoader={setIsLoader}
                setupdateHoliday={setupdateHoliday}
                classNameValue={`${
                  editGymInfo || registerHoliday ? "block_notify" : "hideNotify"
                }`}
              />
            )}

            {holidayCalander && (
              <Holidays
                onCLose={setHolidayCalander}
                setRegisterHoliday={setRegisterHoliday}
                setIsLoader={setIsLoader}
                registerHoliday={registerHoliday}
                classNameValue={`${
                  holidayCalander ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            {registerHoliday && (
              <RegisterHoliday
                onClose={setRegisterHoliday}
                setIsLoader={setIsLoader}
                updateHoliday={updateHoliday}
                setEditGymInfo={setEditGymInfo}
                setGymData={setGymData}
                classNameValue={`${
                  registerHoliday || editGymInfo ? "block_notify" : "hideNotify"
                }`}
              />
            )}
          </Col>
        </Row>
      </Container>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
      />
    </section>
  );
}

export default GymInfoScreen;

export function MembershipDropDown({ setGymText }) {
  const navigate = useNavigate();
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[270px] right-4 top-14 max-h-[250px] overflow-auto z-20 px-2">
      <div
        onClick={() => {
          setGymText({ str: strings.GYM_INFORMATION, val: 0 });
          navigate("/Gym-setting/Gym-settings/Gym-information");
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">
          {strings.GYM_INFORMATION}
        </span>
      </div>
      <div
        onClick={() => {
          setGymText({ str: strings.SPORTS_EQUIPMENT_INFORMATION, val: 1 });
          navigate("/Gym-setting/Gym-settings/Sports-equipment-setting");
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">
          {strings.SPORTS_EQUIPMENT_INFORMATION}
        </span>
      </div>
      <div
        onClick={() => {
          setGymText({ str: strings.INFORMATION_SETTING, val: 2 });
          navigate("/Gym-setting/Gym-settings/Information-setting");
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">
          {strings.INFORMATION_SETTING}
        </span>
      </div>
      <div
        onClick={() => {
          setGymText({ str: strings.GYM_LOCKER_SETTING, val: 3 });
          navigate("/Gym-setting/Gym-settings/GymLockerSetting");
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">
          {strings.GYM_LOCKER_SETTING}
        </span>
      </div>
    </div>
  );
}
