import { Icon } from "@iconify-icon/react";
import React, { useEffect, useState } from "react";
import { Mention } from "primereact/mention";
import medal from "../../../assests/icons/medal.svg";
import moment from "moment";
import { placeholder, strings } from "../../../utils/String";
import BoardSublyApi from "../../../helper/BoardApi";
import { useSelector } from "react-redux";
import CommentBox from "./CommentBox";
import DeleteConfirmation from "../../commonComponents/modalScreens/DeleteConfirmation";
import { FormMedia } from "./RegistrationForm";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import experience from "../../../assests/icons/badge-icon/competitionExperience.png";
import winner from "../../../assests/icons/badge-icon/competitionWinner.png";
import risingStar from "../../../assests/icons/badge-icon/risingStar.png";
import ProfessionalCertificate from "../../../assests/icons/badge-icon/ProfessionalCertificateHolder.png"
import teamLeader from "../../../assests/icons/badge-icon/teamLeader.png";
import MembersSublyApi from "../../../helper/MemberApi";
import dumy from "../../../assests/images/demoProfile.svg";

function GeneralDetailScreen({
  setIsLoader,
  id,
  setOpenDetail,
  setBoardId,
  setOpenForm,
  setIsEdit,
  setEditedValue,
  isNotice,
  type,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [generalDetail, setGeneralDetail] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [updateResponse, setUpdateResponse] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState();
  const [allUsers, setAllUsers] = useState();
  const [customers, setCustomers] = useState([]);
  const [tagId, setTagId] = useState([]);

  // ==============API for getting board general detail================
  useEffect(() => {
    async function boardDetailHandle() {
      setIsLoader(true);
      await BoardSublyApi.fetchBoardDetail(id, token, type)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode === 200) {
            setGeneralDetail(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    boardDetailHandle();
  }, [updateResponse]);

  //   ===============API for send comment =================
  async function sendCommentHandle() {
    const nameValue = commentValue.match(/@\w+/g, "");
    const filterValue = tagId
      .filter((element) => nameValue.includes("@" + element.tagName))
      .map((val) => val.tagId);
    const newTagId = [...new Set(filterValue)];

    setIsDisable(true);
    const requestData = {
      comment: commentValue,
      boardId: id,
      type,
      tags: newTagId,
    };
    await BoardSublyApi.sendComment(requestData, token)
      .then((response) => {
        if (response.statusCode == 200) {
          setCommentValue("");
          setUpdateResponse(!updateResponse);
          setIsDisable(false);
          setTagId([]);
        } else {
          setIsDisable(false);
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // =============API for delete comments=============
  async function deleteHandle() {
    await BoardSublyApi.baordDelete(id, token, type)
      .then((response) => {
        if (response.statusCode == 200) {
          setUpdateResponse(!updateResponse);
          setOpenDetail(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getBadgeImage = (badge) => {
    switch (badge) {
      case "Team Leader":
        return teamLeader;
      case "Rising Star":
        return risingStar;
      case "Competition Experience":
        return experience;
      case "Competition Winner":
        return winner;
      case "Professional Certificate Holder":
        return ProfessionalCertificate;
      // default:
      //   return medal;
    }
  };

  // --------Api For Tag Staff---------

  useEffect(() => {
    async function getMentionList() {
      try {
        const response = await BoardSublyApi.getAllStaff(token);
        const fetchedCustomers = response;

        if (fetchedCustomers) {
          const customersWithNicknames = fetchedCustomers?.data?.map(
            (customer) => ({
              ...customer,
              nickname: `${customer.name.replace(/\s+/g, "").toLowerCase()}`,
            })
          );
          setCustomers(customersWithNicknames);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    }

    getMentionList();
  }, [token]);

  const onSearch = (event) => {
    const comment = commentValue.replace(/@\w+/g, "").trim();
    if (comment?.length) {
      return null;
    }
    setTimeout(() => {
      const query = event.query;
      let suggestions;

      if (!query.trim().length) {
        suggestions = [...customers];
      } else {
        suggestions = customers.filter((customer) => {
          return customer.nickname
            .toLowerCase()
            .startsWith(query.toLowerCase());
        });
      }

      setSuggestions(suggestions);
    }, 250);
  };

  const itemTemplate = (suggestion) => {
    return (
      <div
        className="flex align-items-center w-[33%] bg-[#5C5C5C] tagBorder border-b border-[#747474] py-1"
        onClick={() => {
          setTagId([
            ...tagId,
            { tagId: suggestion.id, tagName: suggestion.name.toLowerCase() },
          ]);
        }}
      >
        <img
          src={
            suggestion?.mProfileImg?.prof_img1_min
              ? suggestion?.mProfileImg?.prof_img1_min
              : dumy
          }
          width="32"
          className="rounded-full mx-2"
        />
        <span className="flex flex-column text-white hover:text-[#CCFF00]  ">
          {suggestion.name}
        </span>
      </div>
    );
  };
  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[95%] max-h-[92%] max-lg:p-4 max-lg:py-8  p-10 pb-6 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto">
          <div>
            <div className="py-2 px-4 bg-[#474749] rounded-[10px]">
              <p className="mb-0 text-[30px] font-bold">
                {generalDetail?.title}
              </p>
            </div>
            <div className="flex items-center justify-between py-4 flex-wrap gap-3 border-b border-[#595959] ">
              <div>
                {" "}
                {generalDetail?.suggestionType && (
                  <div className="py-2 px-4 bg-[#474749] rounded-[10px] mb-2">
                    <p className="mb-0 text-[22px] font-bold">
                      <span className="text-[22px] text-[#FFFFFFBF]">
                        Type:
                      </span>{" "}
                      {generalDetail?.suggestionType}
                    </p>
                  </div>
                )}
                <div className="flex items-center gap-2">
                  <Icon
                    icon="uil:user"
                    width="30"
                    height="30"
                    style={{ color: "#DBDBDB" }}
                  />
                  <p className="mb-0 text-[24px] font-extrabold">
                    {generalDetail?.gymStaffInfo?.gymStaffName}
                  </p>

                  {generalDetail?.gymStaffInfo?.badge && (
                    <img
                      src={getBadgeImage(generalDetail?.gymStaffInfo?.badge)}
                      alt="badge"
                      className="w-[37px] h-[15px]"
                    />
                  )}
                </div>{" "}
              </div>
              <div className="flex items-center gap-x-4 gap-y-3 flex-wrap">
                <div className="flex items-center gap-2 ">
                  <Icon
                    icon="lets-icons:date-today"
                    width="30"
                    height="30"
                    style={{ color: "#C5C5C5" }}
                  />
                  <p className="mb-0 text-[22px] font-extrabold border-r border-[#444444] pr-8 max-sm:pr-3">
                    {moment(generalDetail?.regDate).format("YYYY.MM.DD")}
                  </p>
                </div>
                <div className="flex items-center gap-2 border-r border-[#444444] pr-8">
                  <Icon
                    icon="ion:time-outline"
                    width="30"
                    height="30"
                    style={{ color: "#C5C5C5" }}
                  />
                  <p className="mb-0 text-[22px] font-extrabold">
                    {moment(generalDetail?.regDate).format("h:mm")}{" "}
                    <span className="text-white text-lg font-bold">
                      {moment(generalDetail?.regDate).format("A")}
                    </span>
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <Icon
                    icon="bi:eye"
                    width="30"
                    height="30"
                    style={{ color: "#C5C5C5" }}
                  />
                  <p className="mb-0 text-[22px] font-extrabold">
                    {generalDetail?.views}
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-auto max-h-[calc(100vh-440px)] pr-3 relative">
              <div
                className={`${
                  isNotice ? "border-b border-[#595959]" : ""
                } ${"py-4"}`}
              >
                <p className="mb-0 text-xl font-bold whitespace-pre-wrap break-words pb-3">
                  {generalDetail?.content}
                </p>
                <div className="flex items-center gap-3">
                  {generalDetail?.files?.map((item, index) => (
                    <div
                      key={index}
                      className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] relative"
                    >
                      <img
                        src={item.url}
                        className="w-full h-full object-cover rounded-[10px]"
                      />
                      {item?.type == "video" && (
                        <span
                          onClick={() => {
                            setuploadedMedia(item);
                            setVideoPreview(true);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {!isNotice && (
                <div
                  className={`${
                    generalDetail?.boardComments ? "pb-4" : "py-4"
                  }`}
                >
                  {generalDetail?.boardComments?.map((val, index) => (
                    <div key={index}>
                      <CommentBox
                        val={val}
                        commentId={commentId}
                        setCommentId={setCommentId}
                        setUpdateResponse={setUpdateResponse}
                        updateResponse={updateResponse}
                        isDisable={isDisable}
                        setIsDisable={setIsDisable}
                        type={type}
                        boardId={id}
                        classNameValue={classNameValue}
                      />
                    </div>
                  ))}
                  <div className="sticky -bottom-1 bg-[#353638] flex flex-col my-4 gap-y-2">
                    <textarea
                      style={{ border: " 1px solid #747474" }}
                      value={commentValue}
                      onChange={(e) => {
                        setCommentValue(e.target.value);
                      }}
                      placeholder={placeholder.WRITE_CONTENT}
                      className="bg-transparent w-[80%] h-[120px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
                    />
                    {/* <Mention
                      value={commentValue}
                      onChange={(e) => {
                        setCommentValue(e.target.value);
                        setIsDisable(false);
                      }}
                      suggestions={suggestions}
                      onSearch={onSearch}
                      field="nickname"
                      placeholder={placeholder.WRITE_CONTENT}
                      rows={5}
                      cols={40}
                      itemTemplate={itemTemplate}
                      style={{ border: "1px solid #747474" }}
                      inputClassName="bg-transparent w-[100%] resize-none"
                      className="bg-transparent w-[80%] h-[120px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
                    /> */}
                    {/* <MentionComponent/> */}
                    <button
                      disabled={commentValue && !isDisable ? false : true}
                      onClick={() => {
                        sendCommentHandle();
                      }}
                      className="text-base font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[40px] disabled:bg-[#ccff0094] my-2"
                    >
                      {strings.POST_COMMENT}
                    </button>
                  </div>
                </div>
              )}
            </div>
            {generalDetail?.loginId == generalDetail?.gymStaffId ? (
              <div
                className={` ${"flex items-center flex-wrap justify-center gap-3 mt-8 mb-4"}`}
              >
                <button
                  onClick={() => {
                    setDeleteAlert(true);
                  }}
                  className={`${"max-sm:w-full text-white kakaoClass hover:text-black hover:bg-common_color "} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
                >
                  {strings.DELETE}
                </button>
                <button
                  onClick={() => {
                    setOpenForm(true);
                    setIsEdit(true);
                    setOpenDetail(false);
                    setEditedValue({
                      file: generalDetail?.files,
                      title: generalDetail?.title,
                      content: generalDetail?.content,
                      ...(generalDetail?.suggestionType && {
                        type: generalDetail?.suggestionType,
                      }),
                    });
                  }}
                  className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass disabled:border-0 hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                >
                  {strings.EDIT}
                </button>
                <button
                  onClick={() => {
                    setOpenDetail(false);
                    setBoardId({ id: "" });
                  }}
                  className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                >
                  {strings.CLOSE}
                </button>
              </div>
            ) : (
              <div
                className={` ${"flex items-center justify-center gap-3 mt-20 mb-4"}`}
              >
                <button
                  onClick={() => {
                    setOpenDetail(false);
                    setBoardId({ id: "" });
                  }}
                  className="text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                >
                  {strings.CLOSE}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
      <DeleteConfirmation
        textHead={strings.DELETE_COMMENT}
        text={strings.DO_YOU_WANT_DELETE_GENERAL_BOARD}
        deleteMemo={deleteAlert}
        setDeleteMemo={setDeleteAlert}
        clickBtn={deleteHandle}
        btnText={strings.DELETE}
        classNameValue={classNameValue}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default GeneralDetailScreen;
