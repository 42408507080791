import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import StatisticsSublyApi from "../../../helper/StatisticsApi";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import moment from "moment";
import { color } from "chart.js/helpers";

function SaleGraph({
  image,
  text,
  openGraph,
  setOpenGraph,
  graphValue,
  graphgLoader,
  graphText,
}) {
  // Chart.defaults.color = "#000";
  // console.log("-------=======graphValue", graphValue);
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    LineController,
    BarController
  );
  // console.log("--------SALES VALUE",graphValue)
  const labels = graphValue?.date?.map((val) => {
    return moment(val).format("YY.MM.DD");
  });
  const value = graphValue?.value;
  // console.log("......labels", labels);
  const data = {
    labels,
    color: "white",
    datasets: [
      {
        type: "line",
        borderColor: "#CCFF00",
        borderWidth: 2,
        fill: false,
        data: value,
        color: "#fff",
      },
      {
        type: "bar",
        backgroundColor: "#505051",
        data: value,
        borderColor: "#505051",
        borderWidth: 0,
        color: "#fff",
      },
    ],
  };
  const options = {
    scales: {
      x: {
        ticks: {
          color: "#fff" 
        }
      },
      y: {
        ticks: {
          color: "#fff" 
        }
      }
    }
  };
  
  return (
    <>
      <Modal
        className={`${" classForMargin modalWidthClass"}`}
        show={openGraph}
        onHide={() => {
          setOpenGraph(false);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <p className="border-b-2 border-[#444444] pb-3 mb-0 text-[40px] max-lg:text-[28px] font-extrabold flex items-center gap-2 justify-center">
            <img src={image} alt="graph" className="w-[56px] h-[56px]" /> {text}{" "}
          </p>
          <div>
            {graphgLoader ? (
              <div className="w-full h-full flex   items-center justify-center min-h-60">
                <Icon
                  icon="eos-icons:bubble-loading"
                  width="60"
                  height="60"
                  style={{ color: "#CCFF00" }}
                  className="loader-icon "
                />
              </div>
            ) : (
              <Chart data={data} options={options} style={{ color: "#fff" }} />
            )}
          </div>
          <div className="flex justify-center pt-8 gap-4">
            <button
              onClick={() => {
                setOpenGraph(false);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                  >
              {strings.CLOSE}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SaleGraph;
