import React from "react";
import { Modal } from "react-bootstrap";
import { strings } from "../../../utils/String";
import { CloseButton } from "../AuthButton";
import "../../../assests/CommonStyle.css";

function LoginFailed({ showModal, setShowModal, errorText }) {
  return (
    <>
      <Modal
        className="modalBoxClass"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-12 px-12">
          <p className="text-4xl font-extrabold">{strings.LOGIN_FAILED}</p>
          <p className="text-3xl font-bold mt-6 mb-10">{errorText}</p>
          <div className="flex justify-center">
            <CloseButton
              clickFun={() => {
                setShowModal(false);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginFailed;
