import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import { strings } from "../../../utils/String";
import Header from "../../commonComponents/Header";
import ReactDatePicker from "react-datepicker";
import { Icon } from "@iconify-icon/react";
import StatisticCards from "../../commonComponents/StatisticCards";
import expire from "../../../assests/icons/expireIcon.svg";
import activeMember from "../../../assests/icons/active member.svg";
import newMember from "../../../assests/icons/new member.svg";
import male from "../../../assests/icons/maleUser.svg";
import female from "../../../assests/icons/femaleUser.svg";
import ageIcon from "../../../assests/icons/ageIcon.svg";
import avgIcon from "../../../assests/icons/avgIcon.svg";
import SaleGraph from "../../commonComponents/modalScreens/SaleGraph";
import { useSelector } from "react-redux";
import StatisticsSublyApi from "../../../helper/StatisticsApi";
import Loader from "../../../utils/Loader";
import cardIcon from "../../../assests/icons/membership-card.svg";
import AgeGraph from "../../commonComponents/modalScreens/AgeGraph";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import moment from "moment";
import { DatePicker } from "rsuite";

function Members() {
  const { token } = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openGraph, setOpenGraph] = useState(false);
  const [isLoader, setIsLoader] = useState("");
  const [searchType, setSearchType] = useState("month");
  const [memberData, setMemberData] = useState("");
  const [membership, setMembership] = useState("");
  const [genderType, setGenderType] = useState("all");
  const [openAgeGraph, setOpenAgeGraph] = useState(false);
  const [ageGraphValue, setAgeGraphValue] = useState();
  const [graphValue, setGraphValue] = useState("");
  const [metaData, setMetadata] = useState();
  const [metaGraph, setMetaGraph] = useState(false);
  const [graphgLoader, setGraphLoader] = useState(false);
  const [graphText, setGraphText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  // const [currentEndMonth, setCurrentEndMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");

  // ============statistics sale API============
  useEffect(() => {
    if (searchType !== "") {
      fetchMemberHandle();
    }
  }, [searchType]);

  async function fetchMemberHandle(event) {
    setIsLoader(true);
    if (event) {
      const requestData = {
        searchType: searchType,
        startDate: startDate,
        endDate: endDate,
      };
      await StatisticsSublyApi.statisticsMemberList(requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            if (startDate || endDate) {
              setSearchType("");
            }
            setMemberData(response.data.memberResponse);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const requestData = {
        searchType: searchType,
        startDate: "",
        endDate: "",
      };
      await StatisticsSublyApi.statisticsMemberList(requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setEndDate("");
            setStartDate("");
            setMemberData(response.data.memberResponse);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // ================API for fetching membership list================
  useEffect(() => {
    membershipListHandle();
  }, [genderType]);

  async function membershipListHandle() {
    setIsLoader(true);
    await StatisticsSublyApi.statisticsMemberShip(token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setMembership(response.data);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getMetaValue(key, type, filter) {
    setGraphLoader(true);
    await StatisticsSublyApi.memberMetaGraph(
      token,
      key,
      type,
      filter,
      startDate,
      endDate
    )
      .then((response) => {
        if (response.statusCode == 200) {
          setMetadata(response.data);
          setGraphLoader(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  }

  async function getGraphValue(type, filter) {
    setGraphLoader(true);
    await StatisticsSublyApi.statisticsMemberGraph(
      token,
      type,
      filter,
      startDate,
      endDate
    )
      .then((response) => {
        if (response.statusCode == 200) {
          setGraphLoader(false);
          setGraphValue(response.data);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  }

  // const filterMonthDates = (date) => {
  //   const condition =
  //     new Date(date).getMonth() === new Date(currentMonth).getMonth();
  //   return condition;
  // };
  // const filterEndMonthDates = (date) => {
  //   const condition =
  //     new Date(date).getMonth() === new Date(currentEndMonth).getMonth();
  //   return condition;
  // };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
  }, [isCalenderOpen, currentMonth]);

  return (
    <section>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col xl={10} className="p-0 relative overflow-auto max-h-screen">
            <Header text={strings.STATISTICS_MEMBERS_DES} />
            <div className="mx-6 ">
              <div className="my-4 flex items-center justify-between gap-3 flex-wrap">
                <div className="flex items-center gap-2 flex-wrap">
                  <button
                    onClick={() => {
                      setSearchType("today");
                    }}
                    className={`${
                      searchType == "today"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {" "}
                    {strings.TODAY}
                  </button>
                  <button
                    onClick={() => {
                      setSearchType("week");
                    }}
                    className={`${
                      searchType == "week"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {" "}
                    {strings.ONE_WEEK}
                  </button>
                  <button
                    onClick={() => {
                      setSearchType("month");
                    }}
                    className={`${
                      searchType == "month"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {" "}
                    {strings.ONE_MONTH}
                  </button>
                  <button
                    onClick={() => {
                      setSearchType("year");
                    }}
                    className={`${
                      searchType == "year"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E]  text-white"
                    } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.ONE_YEAR}
                  </button>
                </div>
                <div className="flex items-center gap-2 max-sm:flex-wrap">
                  <div className="flex items-center gap-2 dateInputClass ml-10 pl-3 max-[390px]:-ml-6 max-[430px]:-ml-4 max-[360px]:-ml-8">
                    <Icon
                      icon="lets-icons:date-today"
                      width="30"
                      height="30"
                      style={{ color: "#CCFF00" }}
                    />
                    <div className="w-full flex flex-col md:flex-row gap-2 ">
                      <div className="newDatePickerClass">
                        <DatePicker
                          oneTap
                          editable={false}
                          id="datepicker-1"
                          placeholder="From-"
                          format="yyyy.MM.dd"
                          block
                          appearance="subtle"
                          className="custom-datepicker"
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          onChangeCalendarDate={(e) => {
                            setCurrentMonth(e);
                          }}
                          onNextMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onPrevMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onOpen={() => {
                            setIsCalenderOpen(true);
                            setCurrentMonth(
                              startDate ? new Date(startDate) : new Date()
                            );
                            setDatePickerCheck(1);
                          }}
                          onClose={() => {
                            setIsCalenderOpen(false);
                            setCurrentMonth(
                              startDate ? new Date(startDate) : new Date()
                            );
                            setDatePickerCheck("");
                          }}
                          disabledDate={(date) => {
                            const maxDate = new Date();
                            return date > maxDate;
                          }}
                        />
                      </div>
                      <div className="newDatePickerClass">
                        <DatePicker
                          oneTap
                          editable={false}
                          id="datepicker-2"
                          placeholder="To-"
                          format="yyyy.MM.dd"
                          block
                          appearance="subtle"
                          className="custom-datepicker"
                          onChange={(date) => setEndDate(date)}
                          value={endDate}
                          onNextMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onPrevMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onOpen={() => {
                            setIsCalenderOpen(true);
                            setCurrentMonth(
                              endDate ? new Date(endDate) : new Date()
                            );
                            setDatePickerCheck(2);
                          }}
                          onClose={() => {
                            setIsCalenderOpen(false);
                            setDatePickerCheck("");
                            setCurrentMonth(
                              endDate ? new Date(endDate) : new Date()
                            );
                          }}
                          disabledDate={(date) => {
                            const minDate = new Date(startDate);
                            const maxDate = new Date();
                            return date < minDate || date > maxDate;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      fetchMemberHandle(true);
                    }}
                    className="text-black bg-[#CCFF00] text-base font-bold h-[50px] w-[120px] rounded-[28px]"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              <div className="bg-[#353638] px-3 rounded-[10px]">
                {" "}
                <div className="pt-3 pb-4 border-b-2 border-[#444444]">
                  <p className="text-[26px] font-extrabold mb-2">
                    {strings.ALL_MEMBERS}
                  </p>
                  <div className="gap-2 flex items-center flex-wrap webkit-scrollbar:w-[1px] pb-4 scrollingClass">
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px]">
                      <StatisticCards
                        isMember={true}
                        image={activeMember}
                        clickBtn={() => {
                          setOpenGraph(true);
                          getGraphValue(0, searchType);
                          setMetaGraph(false);
                        }}
                        data={memberData?.totalMemberCount}
                        text={strings.ACTIVE_MEMBER}
                        subText={strings.PEOPLE}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px]">
                      <StatisticCards
                        isMember={true}
                        image={newMember}
                        data={memberData?.newTotalMemberCount}
                        text={strings.NEW_MEMBER}
                        subText={strings.PEOPLE}
                        clickBtn={() => {
                          setOpenGraph(true);
                          getMetaValue("newMember", 0, searchType);
                          setMetaGraph(true);
                        }}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px]">
                      <StatisticCards
                        isMember={true}
                        image={expire}
                        // addStyle={true}
                        data={memberData?.memberShipExpiringIn7days}
                        text={strings.EXPIRE_IN_SEVEN_DAYS}
                        subText={strings.PEOPLE}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px]">
                      <StatisticCards
                        isMember={true}
                        image={expire}
                        data={memberData?.memberShipExpiringIn30days}
                        text={strings.EXPIRE_IN_THIRTY_DAYS}
                        subText={strings.PEOPLE}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-3 pb-4">
                  <p className="text-[20px] font-extrabold mb-3 flex items-center gap-2">
                    <img src={male} alt="male" />
                    {strings.MALE}
                  </p>
                  <div className="gap-2 flex items-center flex-wrap webkit-scrollbar:w-[1px] pb-4 scrollingClass  ">
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px]">
                      <StatisticCards
                        isMember={true}
                        image={activeMember}
                        // clickBtn={setOpenGraph}
                        isPercent={`${(
                          (memberData?.maleMemberCount /
                            memberData?.totalMemberCount || 0) * 100
                        ).toFixed(1)}%`}
                        data={memberData?.maleMemberCount}
                        text={strings.ACTIVE_MEMBER}
                        clickBtn={() => {
                          setOpenGraph(true);
                          getGraphValue("male", searchType);
                        }}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px]">
                      <StatisticCards
                        isMember={true}
                        image={newMember}
                        data={memberData?.newMaleMemberCount}
                        text={strings.NEW_MEMBER}
                        subText={strings.PEOPLE}
                        clickBtn={() => {
                          setOpenGraph(true);
                          getMetaValue("newMember", "male", searchType);
                          setMetaGraph(true);
                        }}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px]">
                      <StatisticCards
                        isMember={true}
                        image={avgIcon}
                        // addStyle={true}
                        data={memberData?.avgMaleAttending}
                        text={strings.AVG_ATTENDING_DAYS}
                        subText={
                          memberData?.avgMaleAttending > 0
                            ? strings.DAYS
                            : strings.DAY
                        }
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:min-w-full ">
                      <StatisticCards
                        isMember={true}
                        image={ageIcon}
                        data={memberData?.ageRange?.male}
                        text={strings.AGE_DISTRIBUTION}
                        isAge={true}
                        clickBtn={() => {
                          memberData?.ageRange?.male.length > 0
                            ? setOpenAgeGraph(true)
                            : setOpenAgeGraph(false);
                          setAgeGraphValue(memberData?.ageRange?.male);
                        }}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-3 pb-4">
                  <p className="text-[20px] font-bold mb-3 flex items-center gap-2">
                    {" "}
                    <img src={female} alt="female" />
                    {strings.FEMALE}
                  </p>
                  <div className="gap-2 flex items-center flex-wrap  webkit-scrollbar:w-[1px] pb-4 scrollingClass">
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                      <StatisticCards
                        isMember={true}
                        image={activeMember}
                        // clickBtn={setOpenGraph}
                        isPercent={`${(
                          (memberData?.femaleMemberCount /
                            memberData?.totalMemberCount || 0) * 100
                        ).toFixed(1)}%`}
                        data={memberData?.femaleMemberCount}
                        text={strings.ACTIVE_MEMBER}
                        clickBtn={() => {
                          setOpenGraph(true);
                          getGraphValue("female", searchType);
                        }}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                      <StatisticCards
                        isMember={true}
                        image={newMember}
                        data={memberData?.newFemaleMemberCount}
                        text={strings.NEW_MEMBER}
                        subText={strings.PEOPLE}
                        clickBtn={() => {
                          setOpenGraph(true);
                          getMetaValue("newMember", "female", searchType);
                          setMetaGraph(true);
                        }}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                      <StatisticCards
                        isMember={true}
                        image={avgIcon}
                        // addStyle={true}
                        data={memberData?.avgFemaleAttending}
                        text={strings.AVG_ATTENDING_DAYS}
                        subText={
                          memberData?.avgFemaleAttending > 0
                            ? strings.DAYS
                            : strings.DAY
                        }
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                    <div className="min-w-[250px] max-sm:min-w-full ">
                      <StatisticCards
                        isMember={true}
                        image={ageIcon}
                        data={memberData?.ageRange?.female}
                        text={strings.AGE_DISTRIBUTION}
                        isAge={true}
                        clickBtn={() => {
                          memberData?.ageRange?.female.length > 0
                            ? setOpenAgeGraph(true)
                            : setOpenAgeGraph(false);
                          setAgeGraphValue(memberData?.ageRange?.female);
                        }}
                        graphgLoader={graphgLoader}
                        setGraphText={setGraphText}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {(membership?.male?.length > 0 ||
                membership?.female?.length > 0) && (
                <div className="bg-[#353638] px-3 rounded-[10px] my-4">
                  {" "}
                  <div className="pt-3 pb-4">
                    <div className="flex items-center flex-wrap gap-2 pb-2.5">
                      <p className="text-[26px] font-extrabold mb-2 mr-5">
                        {strings.PRODUCT}
                      </p>
                      <div className="flex items-center gap-x-2 gap-y-3 max-sm:flex-wrap">
                        <button
                          onClick={() => {
                            setGenderType("all");
                          }}
                          className={` ${
                            genderType == "all"
                              ? "text-black bg-[#CCFF00]"
                              : "text-white kakaoClass"
                          } ${"hover:text-black hover:bg-[#CCFF00] text-base font-bold  h-[40px] w-[120px] rounded-[28px]"}`}
                        >
                          {strings.ALL}
                        </button>
                        <button
                          onClick={() => {
                            setGenderType("male");
                          }}
                          className={` ${
                            genderType == "male"
                              ? "text-black bg-[#CCFF00]"
                              : "text-white kakaoClass"
                          } ${"hover:text-black hover:bg-[#CCFF00] text-base font-bold  h-[40px] w-[120px] rounded-[28px]"}`}
                        >
                          {strings.MALE}
                        </button>
                        <button
                          onClick={() => {
                            setGenderType("female");
                          }}
                          className={` ${
                            genderType == "female"
                              ? "text-black bg-[#CCFF00]"
                              : "text-white kakaoClass"
                          } ${"hover:text-black hover:bg-[#CCFF00] text-base font-bold  h-[40px] w-[120px] rounded-[28px]"}`}
                        >
                          {strings.FEMALE}
                        </button>
                      </div>
                    </div>
                    {membership?.male?.length > 0 &&
                      (genderType == "all" || genderType == "male") && (
                        <div className="pt-3 pb-4">
                          <p className="text-[20px] font-bold mb-3 flex items-center gap-2">
                            {" "}
                            <img src={male} alt="male" />
                            {strings.MALE}
                          </p>
                          <div
                            className="gap-2 grid grid-cols-6 max-[1850px]:grid-cols-5 max-[1650px]:grid-cols-4 max-[1350px]:grid-cols-3
                          max-[900px]:grid-cols-2 max-[600px]:grid-cols-1  webkit-scrollbar:w-[1px] pb-4 scrollingClass"
                          >
                            {membership?.male?.length > 0 &&
                              membership?.male?.map((items, index) => (
                                <div key={index}>
                                  <StatisticCards
                                    isMember={true}
                                    image={cardIcon}
                                    isPercent={
                                      items?.percentage &&
                                      `${items?.percentage?.toFixed(1)}%`
                                    }
                                    data={items?.count}
                                    text={items.productName}
                                    graphgLoader={graphgLoader}
                                    setGraphText={setGraphText}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    {membership?.female?.length > 0 &&
                      (genderType == "all" || genderType == "female") && (
                        <div className="pt-3 pb-4">
                          <p className="text-[20px] font-bold mb-3 flex items-center gap-2">
                            {" "}
                            <img src={female} alt="female" />
                            {strings.FEMALE}
                          </p>
                          <div
                            className="gap-2  grid grid-cols-6 max-[1850px]:grid-cols-5 max-[1650px]:grid-cols-4 max-[1350px]:grid-cols-3
                          max-[900px]:grid-cols-2 max-[600px]:grid-cols-1 webkit-scrollbar:w-[1px] pb-4 scrollingClass"
                          >
                            {membership?.female?.length > 0 &&
                              membership?.female?.map((items, index) => (
                                <div key={index}>
                                  <StatisticCards
                                    isMember={true}
                                    image={cardIcon}
                                    isPercent={
                                      items?.percentage &&
                                      `${items?.percentage?.toFixed(1)}%`
                                    }
                                    data={items?.count}
                                    text={items.productName}
                                    graphgLoader={graphgLoader}
                                    setGraphText={setGraphText}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <SaleGraph
        image={activeMember}
        text={graphText}
        openGraph={openGraph}
        setOpenGraph={setOpenGraph}
        graphValue={!metaGraph ? graphValue : metaData}
        graphgLoader={graphgLoader}
      />
      <AgeGraph
        image={ageIcon}
        text={strings.AGE_DISTRIBUTION}
        openAgeGraph={openAgeGraph}
        setOpenAgeGraph={setOpenAgeGraph}
        graphValue={ageGraphValue}
        graphgLoader={graphgLoader}
      />
    </section>
  );
}

export default Members;
