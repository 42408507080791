import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { strings } from "../../../utils/String";
import StaffListTable from "./StaffListTable";
import StaffAuthorityTable from "./StaffAuthorityTable";
import GymSettingSublyApi from "../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import UpdateStaffAuthority from "../../commonComponents/modalScreens/UpdateStaffAuthority";
import StaffRegister from "./StaffRegister";
import StaffInformation from "./StaffInformation";
import $ from "jquery";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import AlertModal from "./AlertModal";

export const scrollToTopFun = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
function StaffSetting() {
  const { token } = useSelector((state) => state.user);
  const [staffList, setStaffList] = useState("");
  const [staffAuthList, setStaffAuthList] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [updatedValue, setUpdatedValue] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [informationModal, setInformationModal] = useState(false);
  const [editStaff, setEditStaff] = useState(false);
  const [staffId, setStaffId] = useState("");
  const [editStaffData, setEditStaffData] = useState("");
  const [staffData, setStaffData] = useState();
  const [modifiedStaff, setModifiedStaff] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  function getModifiedName() {
    const value =
      staffAuthList?.length > 0 &&
      updatedValue?.map((item) => {
        return staffAuthList?.find((val) => val.staffId == item.staffId);
      });
    setModifiedStaff(value);
  }

  // =============API for fetching staff list===========
  useEffect(() => {
    async function staffListHandle() {
      setIsLoader(true);
      await GymSettingSublyApi.fetchStaffList(token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setStaffList(response.data.staffList);
            setStaffAuthList(response.data.staffAuthorityList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (!updateModal && !registerModal) {
      staffListHandle();
    }
  }, [registerModal, updateModal]);

  // =============API for update staff authority==============
  async function updateStaffHandle() {
    setIsLoader(true);
    updatedValue?.forEach((data) => {
      Object.keys(data?.authority)?.forEach((key) => {
        if (key?.includes("Edit")) {
          delete data?.authority[key];
        }
      });
    });
    const requestData = { authorityData: updatedValue };
    await GymSettingSublyApi.staffAuthorityUpdate(requestData, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setUpdatedValue([]);
          setUpdateModal(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function staffDetails() {
    await GymSettingSublyApi.getStaffDetails(token, staffId)
      .then((response) => {
        if (response.statusCode == 200) {
          setStaffData(response.data);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0
    ) {
      setRegisterModal(false);
      setInformationModal(false);
      setStaffId("");
      setStaffData("");
      setEditStaff(false);
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar updateModal={updateModal} />
          </Col>
          <Col
            xl={10}
            className={`${
              registerModal || informationModal
                ? "!overflow-hidden h-screen"
                : "!overflow-auto"
            } ${"p-0 relative"}`}
          >
            <Header text={strings.GUM_SETTING_STAFF_SETTING} />
            <div className="mx-6 mb-4">
              <div className="my-4 flex items-center justify-between">
                <p className="text-[30px] font-extrabold mb-0">
                  {strings.STAFF_LIST}
                </p>
                <button
                  onClick={() => {
                    setRegisterModal(true);
                  }}
                  className="text-black font-[Archivo] bg-[#CCFF00] text-lg font-bold h-[50px] w-[120px] rounded-[28px]"
                >
                  {strings.REGISTER}
                </button>
              </div>
              <div onClick={() => scrollToTopFun()}>
                <StaffListTable
                  staffList={staffList}
                  setStaffList={setStaffList}
                  openInformation={setInformationModal}
                  setStaffId={setStaffId}
                  setEditStaffData={setEditStaffData}
                  scrollToTopFun={scrollToTopFun}
                  editStaff={setEditStaff}
                  staffId={staffId}
                />
              </div>
              <div className="my-4 flex items-center justify-between">
                <p className="text-[30px] font-extrabold mb-0">
                  {strings.STAFF_AUTHORITY}
                </p>
                <button
                  disabled={updatedValue.length > 0 ? false : true}
                  onClick={() => {
                    setUpdateModal(true);
                    getModifiedName();
                  }}
                  className="text-black font-[Archivo] bg-[#CCFF00] text-lg font-bold h-[50px] w-[120px] rounded-[28px] disabled:bg-[#ccff0094]"
                >
                  {strings.UPDATE}
                </button>
              </div>
              <div>
                <StaffAuthorityTable
                  staffAuthority={staffAuthList}
                  setUpdatedValue={setUpdatedValue}
                  updatedValue={updatedValue}
                  setModifiedStaff={setModifiedStaff}
                  modifiedStaff={modifiedStaff}
                  setOpenAlert={setOpenAlert}
                />
              </div>
            </div>
            {registerModal && (
              <StaffRegister
                setRegisterModal={setRegisterModal}
                onClose={setRegisterModal}
                defultValue={staffData}
                editStaff={editStaff}
                setEditStaff={setEditStaff}
                setStaffData={setStaffData}
                setIsLoader={setIsLoader}
                setStaffId={setStaffId}
                classNameValue={`${
                  registerModal ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            {informationModal && (
              <StaffInformation
                openEdit={setEditStaff}
                closeMOdal={setInformationModal}
                staffId={staffId}
                getStaffData={staffDetails}
                setRegisterModal={setRegisterModal}
                setStaffDataValue={setStaffData}
                setIsLoader={setIsLoader}
                setStaffId={setStaffId}
                classNameValue={`${
                  informationModal ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            <AlertModal openAlert={openAlert} setOpenAlert={setOpenAlert} />
            {/* {editStaff && <EditStaffInfo setRegisterModal={setEditStaff}  defultValue={staffData}   editStaff={editStaff}
                onClose={setEditStaff}
                setStaffData={setStaffData}
                setIsLoader={setIsLoader}
                />} */}
          </Col>
        </Row>
      </Container>
      <UpdateStaffAuthority
        updateModal={updateModal}
        updateStaffHandle={updateStaffHandle}
        setUpdateModal={setUpdateModal}
        modifiedStaff={modifiedStaff}
        setUpdatedValue={setUpdatedValue}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
    </section>
  );
}

export default StaffSetting;
