import React from "react";
import warningIcon from "../../../../../assests/icons/warning-icon.svg";
import { strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";

const WarningModal = ({ setIsWarning, headText, text, subText, classNameValue }) => {
  return (
    <div className={`${classNameValue} ${"bg-[#353638] w-[400px] p-3 shadow-2xl rounded-[10px] absolute top-0 -right-5"}`}>
      <div className="flex items-center justify-between border-b-[1px] border-[#444444] pb-3 ">
        <div className="flex item-center gap-3">
          <img src={warningIcon} />
          <p className="!mb-0 text-xl font-extrabold">{headText}</p>
        </div>
        <Icon
          icon="maki:cross"
          width="16"
          height="16"
          style={{ color: "white" }}
          onClick={() => {
            setIsWarning(false);
          }}
        />
      </div>
      <p className="mt-3 text-lg font-bold">{text}</p>
      <p className="mt-3 text-lg font-bold">{subText}</p>
    </div>
  );
};

export default WarningModal;
