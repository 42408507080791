import React, { useEffect, useState } from "react";
import { strings } from "../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import logo from "../assests/attendanceIcons/logo-icon.svg";
import { InputGroup } from "react-bootstrap";
import closeEye from "../assests/attendanceIcons/close-eye-icon.svg";
import OpenEye from "../assests/attendanceIcons/visible-icon.svg";
import NotValidInfoModal from "./NotValidInfoModal";
import ConfirmationModal from "./ConfirmationModal";
import CheckYourEmailModal from "./CheckYourEmailModal";
import AttendanceSublyApi from "../helper/AttendanceApi";
import { error } from "jquery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminPanelHandle,
  rememberMeHandle,
  userLoginAttendance,
} from "../Store/slices/AuthSlice";
import Loader from "../utils/Loader";
import ErrorModal from "../components/commonComponents/modalScreens/ErrorModal";

function AttendanceLoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rememberMe } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState({
    gymId: false,
    gymError: "",
    passwordError: "",
    password: false,
  });
  const [gymId, setGymId] = useState("");
  const [password, setPassword] = useState("");
  const [isNotVaild, setIsNotValid] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [ownerDetail, setOwnerDetail] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  useEffect(() => {
    dispatch(adminPanelHandle(false));
    setGymId(rememberMe?.gymId);
    setPassword(rememberMe?.password);
  }, []);

  // ============API for login to attendance screen===============
  async function loginHandle() {
    if (gymId && password) {
      setIsLoader(true);
      setIsError({ gymId: false, password: false });
      const requestedData = { gymId: gymId, password: password };
      await dispatch(userLoginAttendance(requestedData))
        .then((responsejson) => {
          setIsLoader(false);
          let response = responsejson.payload.response;
          if (response.statusCode == 200) {
            navigate("/Attendance/Member");
          } else {
            setOpenErrorModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (!gymId && password) {
        setIsError({
          ...isError,
          gymId: true,
          gymError: "Please enter the value of gym ID",
        });
      } else if (gymId && !password) {
        setIsError({
          ...isError,
          password: true,
          passwordError: "Please enter the value of password",
        });
      } else {
        setIsError({
          gymId: true,
          password: true,
          gymError: "Please enter the value of gym ID",
          passwordError: "Please enter the value of password",
        });
      }
    }
  }

  // ==============API to get owner detail for forget password===============
  async function ownerDetilHandle() {
    if (gymId) {
      await AttendanceSublyApi.getOwnerDetail(gymId)
        .then((response) => {
          if (response.statusCode == 200) {
            setOwnerDetail(response.data);
            setOpenModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setIsError({
        ...isError,
        gymId: true,
        gymError: "Please enter the value of gym ID",
      });
    }
  }

  // ===============API for send forgot password link to email===============
  async function forgotPasswordHandle() {
    await AttendanceSublyApi.forgotPassword({ gymId: gymId })
      .then((response) => {
        if (response.statusCode == 200) {
          setOpenModal(false);
          setCheckEmail(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section className=" flex items-center justify-center h-screen">
      <ErrorModal
        openModal={openErrorModal}
        closeModal={setOpenErrorModal}
        errorMessage={errorMessage}
        classNameValue="block_notify"
      />
      {isLoader ? <Loader /> : ""}{" "}
      <div className="bg-box_bg w-[60%] max-lg:w-[80%] rounded-lg max-md:w-[90%] py-12 px-6">
        <div className="w-[80%] m-auto max-lg:w-full text-center">
          <div>
            <img src={logo} alt="logo" className="mx-auto" />
            <h2 className="font-extrabold text-white text-[30px] mt-4 max-lg:mt-6 mb-6">
              {strings.ATTENDANCE_SYSTEM}
            </h2>
          </div>
          <div className="mt-6 mb-10 w-[85%] mx-auto max-lg:w-full">
            <label htmlFor="email" className="w-full">
              <p className="!mb-0.5 ps-2 flex items-center gap-2 text-white font-bold text-lg whitespace-nowrap">
                {strings.GYM_ID}
              </p>
              <input
                type="text"
                autoComplete="off"
                className={`${
                  isError.gymId ? "errorClass placeholder:text-[#F87171]" : ""
                } ${"h-[50px] px-3 flex items-center gap-2 rounded-[30px] bg-[#2C2C2E] text-white w-full "}`}
                id="email"
                value={gymId}
                placeholder={strings.GYM_ID}
                onChange={(e) => {
                  setGymId(e.target.value);
                  if (e.target.value) {
                    setIsError({ ...isError, gymId: false });
                  } else {
                    setIsError({
                      ...isError,
                      gymId: true,
                      gymError: "Please enter the value of gym ID",
                    });
                  }
                }}
              />
            </label>
            {!gymId && (
              <p className="mb-0 text-base text-start text-[#F87171] ">
                {isError.gymError}
              </p>
            )}
            <label htmlFor="phone" className="mt-3 w-full">
              <p className="!mb-0.5 ps-2 flex items-center gap-2 text-white font-bold text-lg whitespace-nowrap">
                {strings.PASSWORD}
              </p>
              <p className="mb-0 relative">
                <input
                  type={showPassword ? "text" : "password"}
                  autoComplete="off"
                  className={`${
                    isError.password
                      ? "errorClass placeholder:text-[#F87171]"
                      : ""
                  } ${"h-[50px] px-3 flex items-center gap-2 rounded-[30px] bg-[#2C2C2E] text-white w-full "}`}
                  id="phone"
                  placeholder={strings.PASSWORD}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (e.target.value) {
                      setIsError({ ...isError, password: false });
                    } else {
                      setIsError({
                        ...isError,
                        password: true,
                        passwordError: "Please enter the value of gym ID",
                      });
                    }
                  }}
                />
                {!showPassword ? (
                  <Icon
                    icon="ant-design:eye-invisible-outlined"
                    width="23"
                    height="23"
                    onClick={() => {
                      setShowPassword(true);
                    }}
                    style={{ color: "#B6B6B6" }}
                    className="absolute right-4 top-3.5 cursor-pointer"
                  />
                ) : (
                  <Icon
                    icon="ant-design:eye-outlined"
                    width="23"
                    height="23"
                    onClick={() => {
                      setShowPassword(false);
                    }}
                    style={{ color: "#B6B6B6" }}
                    className="absolute right-4 top-3.5 cursor-pointer"
                  />
                )}
              </p>
            </label>
            {!password && (
              <p className="mb-0 text-start text-base text-[#F87171] ">
                {isError.passwordError}
              </p>
            )}
            <div className="flex items-center my-1.5 justify-between">
              <div className="flex items-center">
                <InputGroup className="checkboxClass paddingManage rememberMeClass !p-0">
                  <InputGroup.Checkbox
                    className="w-[24px] h-[24px] bg-[#CECECE80] !p-0"
                    value={"normal"}
                    // checked={changeValue == "normal" ? true : false}
                    onChange={(e) => {
                      dispatch(
                        rememberMeHandle({ gymId: gymId, password: password })
                      );
                    }}
                  />
                </InputGroup>
                <p className="mb-0 text-lg font-bold">{strings.REMEMBER_ME}</p>
              </div>
              <p
                onClick={() => {
                  ownerDetilHandle();
                }}
                className="mb-0 text-lg font-bold text-[#787878] cursor-pointer"
              >
                {strings.FORGOT_PASSWORD}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center gap-4">
            <button
              onClick={() => {
                loginHandle();
              }}
              className="bg-common_color disabled:bg-[#ccff0094] text-xl font-extrabold text-black rounded-[30px] h-[50px] w-[155px]"
            >
              {strings.LOGIN}
            </button>
          </div>
        </div>
      </div>
      <NotValidInfoModal
        setIsNotValid={setIsNotValid}
        isNotVaild={isNotVaild}
        subText={strings.CHECK_ID_PW_AND_TRY_AGAIN}
        headText={strings.NOT_VALID_INFO}
      />
      <ConfirmationModal
        headText={strings.PASSWORD_RESET}
        subText={strings.WOULD_YOU_LIKE_TO_SEND + `{${ownerDetail?.email}}`}
        openModal={openModal}
        setOpenModal={setOpenModal}
        forgotPasswordHandle={forgotPasswordHandle}
      />
      <CheckYourEmailModal
        setCheckEmail={setCheckEmail}
        checkEmail={checkEmail}
        headText={strings.CHECK_YOUR_EMAIL}
        subText={`{${ownerDetail?.email}} has received a password reset link.`}
        subTextLineTwo={"Please change your password and log in."}
        ownerDetail={ownerDetail}
      />
    </section>
  );
}

export default AttendanceLoginScreen;
