import React, { useState } from "react";
import profile from "../../../assests/images/demoProfile.svg";
import { strings } from "../../../utils/String";
import moment from "moment";
import GymSettingSublyApi from "../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { STAFF_STATUS } from "../../../utils/CommonData";
import { formatCustomNumber } from "../../../utils/commonUtils";

function StaffListTable({
  staffList,
  setStaffList,
  openInformation,
  setStaffId,
  setEditStaffData,
  scrollToTopFun,
  editStaff,
  staffId,
}) {
  const { token } = useSelector((state) => state.user);
  // ===============API for update staff status=============
  async function updateStatus(id, statusValue, index) {
    const requestData = { staffId: id, status: statusValue };
    await GymSettingSublyApi.staffStatus(requestData, token)
      .then((response) => {
        if (response.statusCode == 200) {
          const status = [...staffList];
          status[index].status = statusValue;
          setStaffList(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="overflow-auto 2xl:max-h-[calc(100vh_-_540px)] max-2xl:max-h-[600px]">
      <div className="max-2xl:w-[1600px] staffListClass">
        <Table className="w-full !bg-transparent">
          <thead className="bg-[#353638] sticky top-[-1px] z-10">
            <th className="!text-center text-lg font-semibold text-white w-[15%] ">
              {strings.NAME_}
            </th>
            <th className="!text-center text-lg px-4 font-semibold text-white w-[10%] ">
              {strings.CELL_PHONE}
            </th>
            <th className="!text-center text-lg font-semibold text-white w-[15%] ">
              {strings.EMAIL_}
            </th>
            <th className="!text-center text-lg font-semibold text-white w-[21%] ">
              {strings.WORKING_HOUR}
            </th>
            <th className="!text-center text-lg font-semibold text-white w-[8%] ">
              {strings.START_DATE}
            </th>
            <th className="!text-center text-lg font-semibold text-white w-[8%] ">
              {strings.DEPARTURE_DATE}
            </th>
            <th className="!text-center text-lg font-semibold text-white w-[7%] ">
              {strings.LEVEL}
            </th>
            <th className="!text-center text-lg font-semibold text-white w-[16%] ">
              {strings.STATUS}
            </th>
          </thead>
          <tbody>
            {staffList?.length > 0
              ? staffList?.map((item, index) => {
                  return (
                    <tr
                      onClick={() => {
                        openInformation(true);
                        setStaffId(item.staffId);
                        setEditStaffData(item);
                        scrollToTopFun();
                      }}
                      key={index}
                      className={`${"cursor-pointer "} ${
                        staffId == item.staffId
                          ? "border-[1px] border-[#CCFF00]"
                          : ""
                      }`}
                    >
                      <td
                        align="center"
                        className={` 
                          ${"text-white font-bold text-lg relative"}  ${
                          staffId == item.staffId ? "border-[0px]" : ""
                        }`}
                      >
                        <div className="flex items-center text-start  gap-2 ">
                          <img
                            src={item?.profile?.prof_img1_min || profile}
                            alt="item"
                            className="rounded-full w-[35px] h-[35px]"
                          />
                          <p className="!mb-0  w-40 textOverflowClass">
                            {item.name || "- -"}
                          </p>
                        </div>
                      </td>
                      <td className="!text-center text-[18px]  font-bold text-white ">
                        {formatCustomNumber(item.cellphone) || "- -"}
                      </td>
                      <td className="!text-center text-[18px] font-bold text-white ">
                        {item.email || "- -"}
                      </td>
                      <td className="!text-start text-[18px] font-bold text-white pl-3">
                        {" "}
                        {Object.keys(item?.workingHour || {})?.map(
                          (val, index) =>
                            index < 2 ? (
                              <p key={index} className="mb-0 text-lg font-bold">
                                {val}:{" "}
                                {`${moment(
                                  item?.workingHour[val].startTime,
                                  "HH:mm"
                                ).format("h:mm A")} - ${moment(
                                  item?.workingHour[val].endTime,
                                  "HH:mm"
                                ).format("h:mm A")}`}
                              </p>
                            ) : (
                              index == 3 && (
                                <p className="mb-0 text-common_color text-base font-bold">
                                  {strings.MORE}...
                                </p>
                              )
                            )
                        )}{" "}
                        {!item?.workingHour && (
                          <p className="mb-0 text-center">{"- -"}</p>
                        )}
                      </td>
                      <td className="!text-center text-[18px] font-bold text-white ">
                        {moment(item.startDate).format("YY.MM.DD") || "- -"}
                      </td>{" "}
                      <td className="!text-center text-[18px] font-bold text-white ">
                        {moment(item.regDate).format("YY.MM.DD") || "- -"}
                      </td>{" "}
                      <td className="!text-center text-[18px] font-bold text-white ">
                        {item.level || "- -"}
                      </td>{" "}
                      <td
                        className={`!text-center text-[18px] font-bold text-white ${
                          staffId == item.staffId ? "border-[0px]" : ""
                        }`}
                      >
                        <div className="flex items-center justify-center gap-3">
                          <p
                            style={{
                              border:
                                item.status == "employed"
                                  ? "1px solid #00C2FF"
                                  : item.status == "resigned"
                                  ? "1px solid #fff"
                                  : item.status == "on_leave" ||
                                    item.status == "suspended"
                                  ? "1px solid #FFB9B9"
                                  : "none",
                            }}
                            className={`${
                              item.status == "employed"
                                ? "bg-[#00C2FF33]"
                                : item.status == "resigned"
                                ? "bg-[#FFFFFF66]"
                                : item.status == "on_leave" ||
                                  item.status == "suspended"
                                ? "bg-[#FDC8C866]"
                                : ""
                            } ${"font-bold w-[175px] flex items-center justify-center h-[40px] rounded-[28px] font-[Archivo] mb-0"}`}
                          >
                            {STAFF_STATUS[item.status]}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default StaffListTable;
