import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import memberIconGray from "../../../assests/icons/member-group gray.svg";
import { Icon } from "@iconify-icon/react";
import moment from "moment";
import { Table } from "react-bootstrap";
import { PRODUCT_TYPES, PRODUCT_TYPES_LIST } from "../../../utils/CommonData";
import shuffle from "../../../assests/icons/shuffle-icon.svg";
import ManagementSublyApi from "../../../helper/ManagementApi";
import { useDispatch, useSelector } from "react-redux";
import { chatIdHandle } from "../../../Store/slices/AuthSlice";

function RefundTable({
  activeTab,
  setActiveTab,
  activeIndex,
  setactiveIndex,
  refundListValue,
  setshowModal,
  fetchRequestList,
  setSortKey,
  setOrderValue,
}) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);

  // =======Api to get refund requested member id=======
  async function fetchChatId(memberId, id) {
    const requested = { memberId: memberId, refundRequestId: id };
    await ManagementSublyApi.getChatId(token, requested)
      .then((response) => {
        if (response.statusCode == 200) {
          dispatch(chatIdHandle({ response: response.data, status: true }));
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className="overflow-auto 2xl:max-h-[calc(100vh_-_300px)] max-2xl:max-h-[600px]">
      <div className="max-2xl:w-[1600px] staffListClass">
        <Table className="w-full !bg-transparent">
          <thead className="bg-[#353638] sticky top-[-1px]">
            <th
              onClick={() => {
                setActiveTab(activeTab == 1 ? null : 1);
                let sort = activeTab == 1 ? "DESC" : "ASC";
                fetchRequestList(sort, "productName");
                setSortKey(sort);
                setOrderValue("productName");
              }}
              className="!text-center text-lg font-semibold !p-0 text-white w-[25%] "
            >
              <div
                className={`${
                  activeTab == 1 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white  border-none flex items-center h-[48px] ps-4"}`}
              >
                {strings.PRODUCT_NAME}
                <Icon
                  icon={`${
                    activeTab == 1
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{ color: activeTab == 1 ? "#CCFF00" : "#D9D9D9" }}
                />
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 2 ? null : 2);
                let sort = activeTab == 2 ? "DESC" : "ASC";
                fetchRequestList(sort, "name");
                setSortKey(sort);
                setOrderValue("name");
              }}
              className="!text-start text-lg !p-0 font-semibold text-white  w-[15%]"
            >
              <div
                className={`${
                  activeTab == 2 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white flex items-center border-none h-[48px] ps-4"}`}
              >
                {strings.NAME}
                {activeTab == 2 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    className="ms-3"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    className="ms-3"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 3 ? null : 3);
                let sort = activeTab == 3 ? "DESC" : "ASC";
                fetchRequestList(sort, "date");
                setSortKey(sort);
                setOrderValue("date");
              }}
              className="!text-center text-lg !p-0 font-semibold text-white  w-[13%]"
            >
              <div
                className={`${
                  activeTab == 3 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white flex items-center justify-center ps-4 h-[48px] border-none"}`}
              >
                {strings.DATE}
                {activeTab == 3 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    className="ms-3"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    className="ms-3"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 4 ? null : 4);
                let sort = activeTab == 4 ? "DESC" : "ASC";
                fetchRequestList(sort, "type");
                setSortKey(sort);
                setOrderValue("type");
              }}
              className="!text-center text-lg  !p-0 font-semibold text-white  w-[15%]"
            >
              <div
                className={`${
                  activeTab == 4 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white flex items-center justify-center ps-4 h-[48px] border-none"}`}
              >
                {strings.TYPE}
                {activeTab == 4 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    className="ms-3"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    className="ms-3"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </div>
            </th>
            <th className="!text-center text-lg font-semibold text-white  w-[14%]">
              <div className="text-white flex items-center justify-center h-[48px] border-none">
                {strings.CHAT}
              </div>
            </th>
            <th className="!text-center text-lg font-semibold text-white  w-[18%]">
              <div className="text-white flex items-center justify-center h-[48px] border-none">
                {strings.CALL}
              </div>
            </th>
          </thead>
          <tbody>
            {refundListValue?.length > 0 &&
              refundListValue?.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    setshowModal(true);
                    setactiveIndex(item.id);
                  }}
                  className={`${
                    activeIndex == item.id
                      ? "kakaoClass !border-t-2 border-[#CCFF00] bg-[#353638]"
                      : ""
                  } ${"cursor-pointer"}`}
                >
                  <td
                    className={` 
                        ${"text-white font-bold text-lg p-0 "} ${
                      activeIndex == item.id ? "!border-0" : ""
                    }`}
                  >
                    <div className="!text-center text-lg font-bold font-[Archivo] text-white px-2 py-1 ps-4 flex flex-col items-start ">
                      <p className=" m-0">{item.itemName}</p>
                      {item.status == 2 ? (
                        <p className=" mt-1 m-0 flex items-center">
                          <Icon
                            icon="fa6-solid:check"
                            width="22px"
                            height="22px"
                            style={{ color: "#05E700" }}
                          />
                          <p className="ms-1 mb-0 text-[#05E700]">
                            {`(${strings.FINISHED})`}{" "}
                            <span className="!text-white">
                              feedback by{" "}
                              {item.reqFeedbackType == 1
                                ? strings.CHAT
                                : strings.CALL}
                            </span>
                          </p>{" "}
                        </p>
                      ) : item.status == 3 ? (
                        <p className=" mt-1 m-0 flex items-center ">
                          <img src={shuffle} alt="shuffle" />
                          <p className="ms-1 mb-0 !text-[#FFE500]">
                            {`(${strings.ON_GOING})`}{" "}
                            <span className="!text-white">
                              feedback by{" "}
                              {item.reqFeedbackType == 1
                                ? strings.CHAT
                                : strings.CALL}
                            </span>
                          </p>{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                  <td
                    className={`!text-center text-[18px] p-0 font-bold text-white  ${
                      activeIndex == item.id ? "!border-0" : ""
                    }`}
                  >
                    <div className="!text-center text-lg font-bold font-[Archivo] text-white px-2 py-1 ps-4    flex flex-col  items-start ">
                      <p className=" m-0">{item?.userName}</p>
                      {(item.status == 2 || item.status == 3) && (
                        <p className=" mt-1 m-0 flex items-center">
                          <Icon
                            icon="material-symbols:line-end-arrow"
                            width="25px"
                            height="25px"
                            style={{ color: "#CCFF00" }}
                          />

                          <span className="ms-1">{item?.staffName}</span>
                        </p>
                      )}
                    </div>
                  </td>
                  <td
                    className={`text-center text-[18px] font-bold text-white  ${
                      activeIndex == item.id ? "!border-0" : ""
                    }`}
                  >
                    <div className="!text-center text-lg font-bold font-[Archivo] text-white px-2 py-1     ">
                      <p className=" m-0">
                        {moment(item?.requestDate).format("YYYY.MM.DD")}
                      </p>
                      {(item.status == 2 || item.status == 3) && (
                        <p className=" m-0">
                          {moment(item?.resFeedbackDate).format("YYYY.MM.DD")}
                        </p>
                      )}
                    </div>
                  </td>
                  <td
                    className={`text-center text-[18px] font-bold text-white pl-3 ${
                      activeIndex == item.id ? "!border-0" : ""
                    }`}
                  >
                    {PRODUCT_TYPES_LIST[item?.prodType]}
                  </td>
                  <td
                    className={`text-center text-[18px] font-bold text-white pl-3 ${
                      activeIndex == item.id ? "!border-0" : ""
                    }`}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        fetchChatId(item?.memberId, item?.id);
                      }}
                      className="kakaoClass flex items-center justify-center w-1/2 m-auto gap-2 p-2 rounded-[28px]"
                    >
                      <Icon
                        icon="fluent:chat-12-filled"
                        width="21px"
                        height="21px"
                        style={{ color: "#CCFF00" }}
                      />
                      <p className="text-base font-bold mb-0">{strings.CHAT}</p>
                    </div>
                  </td>
                  <td
                    className={`text-center text-[18px] font-bold text-white pl-3 ${
                      activeIndex == item.id ? "!border-0" : ""
                    }`}
                  >
                    <div className="flex items-center gap-2 justify-center">
                      <Icon
                        icon="ic:sharp-call"
                        width="22px"
                        height="22px"
                        style={{ color: "#CCFF00" }}
                      />
                      <p className="mb-0 text-xl font-extrabold">
                        {item?.cellphone}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default RefundTable;
