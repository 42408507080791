import { Icon } from "@iconify-icon/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import BoardSublyApi from "../../../helper/BoardApi";
import { useSelector } from "react-redux";
import DeleteConfirmation from "../../commonComponents/modalScreens/DeleteConfirmation";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import ifImgNull from "../../../assests/images/demoProfile.svg";
import { Mention } from "primereact/mention";
import MembersSublyApi from "../../../helper/MemberApi";
import "../../../assests/CommonStyle.css";
import dumy from "../../../assests/images/demoProfile.svg";

function CommentBox({
  val,
  boardId,
  commentId,
  setCommentId,
  setUpdateResponse,
  updateResponse,
  isDisable,
  setIsDisable,
  type,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [replyValue, setReplyValue] = useState("");
  const [deleteComment, setDeleteComment] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [suggestions, setSuggestions] = useState();
  const [customers, setCustomers] = useState([]);
  const [tagId, setTagId] = useState([]);
  //   ===============API for send comment =================
  async function commentReplyHandle() {
    const nameValue = replyValue.match(/@\w+/g, "");
    const filterValue = tagId
      .filter((element) => nameValue.includes("@" + element.tagName))
      .map((val) => val.tagId);
    const newTagId = [...new Set(filterValue)];

    setIsDisable(true);
    const requestData = {
      comment: replyValue,
      parentCommentId: commentId,
      boardId,
      type,
      tags: newTagId,
    };

    await BoardSublyApi.commmetReply(requestData, token)
      .then((response) => {
        if (response.statusCode == 200) {
          setReplyValue("");
          setCommentId("");
          setUpdateResponse(!updateResponse);
          setIsDisable(false);
          setTagId([]);
        } else {
          setOpenModal(true);
          setIsDisable(false);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // =============API for delete comments=============
  async function deleteCommentHandle() {
    await BoardSublyApi.commmetDelete(boardId, deleteCommentId, type, token)
      .then((response) => {
        if (response.statusCode == 200) {
          setUpdateResponse(!updateResponse);
          setDeleteCommentId("");
          setDeleteComment(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // =============API for Tag Member=============

  useEffect(() => {
    async function getMentionList() {
      try {
        const response = await BoardSublyApi.getAllStaff(token);
        const fetchedCustomers = response;

        if (fetchedCustomers) {
          const customersWithNicknames = fetchedCustomers?.data?.map(
            (customer) => ({
              ...customer,
              nickname: `${customer.name.replace(/\s+/g, "").toLowerCase()}`,
            })
          );
          setCustomers(customersWithNicknames);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    }

    getMentionList();
  }, [token]);

  const onSearch = (event) => {
    const comment = replyValue.replace(/@\w+/g, "").trim();
    if (comment?.length) {
      return null;
    }
    setTimeout(() => {
      const query = event.query;
      let suggestions;

      if (!query.trim().length) {
        suggestions = [...customers];
      } else {
        suggestions = customers.filter((customer) => {
          return customer.nickname
            .toLowerCase()
            .startsWith(query.toLowerCase());
        });
      }

      setSuggestions(suggestions);
    }, 250);
  };

  const itemTemplate = (suggestion) => {
    return (
      <div
        className="flex align-items-center w-[33%] bg-[#5C5C5C] tagBorder border-b border-[#747474] py-1"
        onClick={() => {
          setTagId([
            ...tagId,
            { tagId: suggestion.id, tagName: suggestion.name.toLowerCase() },
          ]);
        }}
      >
        <img
          src={
            suggestion?.mProfileImg?.prof_img1_min
              ? suggestion?.mProfileImg?.prof_img1_min
              : dumy
          }
          width="32"
          className="rounded-full mx-2"
        />
        <span className="flex flex-column text-white hover:text-[#CCFF00]  ">
          {suggestion.name}
        </span>
      </div>
    );
  };

  return (
    <>
      {" "}
      <div className="py-3 border-y border-[#595959]">
        <div className="flex  items-center justify-between max-lg:flex-wrap ">
          <div className="flex gap-2  w-[54%] max-lg:w-full">
            <img
              src={val?.gymStaffInfo?.mProfileImg?.prof_img1_min || ifImgNull}
              className="w-[30px] h-[30px] rounded-full"
            />
            {/* <img src={ifImgNull} className="w-[30px] h-[30px] rounded-full" /> */}

            <p className="mb-0 text-lg font-bold whitespace-pre-wrap break-words  max-lg:w-full">
              <p className="mb-0 text-lg flex font-bold whitespace-pre-wrap break-words  max-lg:w-full">
                {" "}
                <span className="mr-2 text-[#B6B6B6] text-lg font-bold">
                  {val?.tags?.map((item) => (
                    <>
                      {" @"}
                      <span className="!mb-0 text-lg font-bold  text-[#B6B6B6]">
                        {item?.gymStaffName}
                      </span>
                    </>
                  ))}{" "}
                  <span className="mb-0 text-white">
                    {" "}
                    {val?.comment}
                    <span
                      onClick={() => {
                        setCommentId(val.id);
                      }}
                      className={`${
                        commentId == val.id
                          ? "text-[#CCFF00]"
                          : "text-[#C5C5C5]"
                      } ${"gap-2 text-sm font-bold inline-flex items-center align-middle pl-2 cursor-pointer"}`}
                    >
                      <Icon
                        icon="f7:arrowshape-turn-up-left-fill"
                        width="16"
                        height="16"
                        style={{ color: "#CCFF00" }}
                      />{" "}
                      {strings.REPLY}
                    </span>
                  </span>
                </span>
              </p>
              {""}
            </p>
          </div>

          <div className="flex items-center w-[43%] justify-between gap-3 max-lg:flex-wrap max-lg:w-full">
            <div className="flex items-center gap-x-9 gap-y-3 max-lg:flex-wrap">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2 ">
                  <Icon
                    icon="lets-icons:date-today"
                    width="25"
                    height="25"
                    style={{ color: "#fff" }}
                  />
                  <p className="mb-0 text-lg font-bold border-r border-[#838485] pr-4 max-sm:pr-3">
                    {moment(val?.regDate).format("YYYY.MM.DD")}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <Icon
                    icon="ion:time-outline"
                    width="25"
                    height="25"
                    style={{ color: "#fff" }}
                  />
                  <p className="mb-0 text-base font-bold">
                    {moment(val?.regDate).format("h:mm")}{" "}
                    <span className="text-white text-lg font-bold">
                      {moment().format("A")}
                    </span>
                  </p>
                </div>
              </div>
              <p className="mb-0 text-base font-bold">
                {val?.gymStaffInfo?.gymStaffName}
              </p>
            </div>
            <Icon
              icon="material-symbols:delete-outline"
              width="25"
              height="25"
              className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
              // style={{ color: " }}
              onClick={() => {
                setDeleteCommentId(val?.id);
                setDeleteComment(true);
              }}
            />
          </div>
        </div>
        {commentId == val.id && (
          <div className="sticky bottom-0 bg-[#353638] flex items-end gap-2 justify-start my-4">
            <textarea
              style={{ border: " 1px solid #747474" }}
              value={replyValue}
              onChange={(e) => {
                setReplyValue(e.target.value);
              }}
              placeholder={placeholder.ADD_A_REPLY}
              className="bg-transparent w-[80%] h-[100px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
            />
            {/* <div className=" w-[80%]">
              <Mention
                value={replyValue}
                onChange={(e) => {
                  setReplyValue(e.target.value);
                  setIsDisable(false);
                }}
                suggestions={suggestions}
                onSearch={onSearch}
                field="nickname"
                placeholder={placeholder.ADD_A_REPLY}
                rows={5}
                cols={40}
                itemTemplate={itemTemplate}
                style={{ border: "1px solid #747474" }}
                inputClassName="bg-transparent w-[100%] resize-none"
                className="bg-transparent w-[100%] h-[120px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
                autoResize
                panelStyle={
                  {
                    // backgroundColor: "#5C5C5C",
                    // borderRadius: "10px",
                    // width:"50px",
                    // maxWidth:"200px"
                  }
                }
              />
            </div> */}
            <button
              disabled={replyValue && !isDisable ? false : true}
              onClick={() => {
                commentReplyHandle();
              }}
              className="text-base font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[90px] h-[35px] disabled:bg-[#ccff0094] my-2"
            >
              {strings.REPLY}
            </button>
          </div>
        )}
      </div>
      {val?.comments?.map((item, index) => {
        return (
          <div key={index}>
            <div className="flex items-center justify-end">
              <div className="w-[95.5%] py-3 border-b  border-[#595959] ">
                <div className="flex items-start justify-between max-lg:flex-wrap">
                  <div className="flex   gap-2 w-[54%] max-lg:w-full">
                    <img
                      src={
                        item?.gymStaffInfo?.mProfileImg?.prof_img1_min ||
                        ifImgNull
                      }
                      // src={ifImgNull}
                      className="w-[30px] h-[30px] rounded-full "
                    />

                    <p className="mb-0 text-lg font-bold whitespace-pre-wrap break-words  max-lg:w-full ">
                      <span className="text-[#C5C5C5] text-lg font-extrabold mr-2 ">
                        <span className=" text-[#B6B6B6] text-lg font-black whitespace-nowrap">
                          {" "}
                          {item?.authorDetails?.gymStaffName}
                        </span>
                        {/* {(item?.authorDetails?.gymStaffName).replace(" ", "_")} */}
                        {item?.tags?.map((item) => (
                          <span className="!mb-0 text-lg font-bold whitesspanace-nowrap  text-[#B6B6B6]">
                            {" @"}
                            {item?.gymStaffName}
                          </span>
                        ))}{" "}
                        <span className="mb-0 text-white">
                          {" "}
                          {item?.comment}
                          <span
                            onClick={() => {
                              setCommentId(item.id);
                            }}
                            className={`${
                              commentId == item.id
                                ? "text-[#CCFF00]"
                                : "text-[#C5C5C5]"
                            } ${"gap-2 text-sm font-bold inline-flex items-center align-middle pl-2 cursor-pointer"}`}
                          >
                            <Icon
                              icon="f7:arrowshape-turn-up-left-fill"
                              width="16"
                              height="16"
                              style={{ color: "#CCFF00" }}
                            />{" "}
                            {strings.REPLY}
                          </span>
                        </span>
                      </span>{" "}
                      {""}
                    </p>
                  </div>

                  <div className="flex items-center w-[45%] justify-between gap-3 max-lg:flex-wrap max-lg:w-full">
                    <div className="flex items-center gap-x-9 gap-y-3 max-lg:flex-wrap">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-2 ">
                          <Icon
                            icon="lets-icons:date-today"
                            width="25"
                            height="25"
                            style={{ color: "#fff" }}
                          />
                          <p className="mb-0 text-lg font-bold border-r border-[#838485] pr-4 max-sm:pr-3">
                            {moment(item?.regDate).format("YYYY.MM.DD")}
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <Icon
                            icon="ion:time-outline"
                            width="25"
                            height="25"
                            style={{ color: "#fff" }}
                          />
                          <p className="mb-0 text-base font-bold whitespace-nowrap">
                            {moment(item?.regDate).format("h:mm")}{" "}
                            <span className="text-white text-lg font-bold">
                              {moment().format("A")}
                            </span>
                          </p>
                        </div>
                      </div>
                      <p className="mb-0 text-base font-bold">
                        {item?.gymStaffInfo?.gymStaffName}
                      </p>
                    </div>
                    <Icon
                      icon="material-symbols:delete-outline"
                      width="25"
                      height="25"
                      className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                      onClick={() => {
                        setDeleteCommentId(item?.id);
                        setDeleteComment(true);
                      }}
                    />
                  </div>
                </div>
                {commentId == item.id && (
                  <div className="sticky bottom-0 bg-[#353638] flex items-end gap-2 justify-start my-4">
                    <textarea
                      style={{ border: " 1px solid #747474" }}
                      value={replyValue}
                      onChange={(e) => {
                        setReplyValue(e.target.value);
                      }}
                      placeholder={placeholder.ADD_A_REPLY}
                      className="bg-transparent w-full h-[100px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
                    />
                    {/* <Mention
                      value={replyValue}
                      onChange={(e) => {
                        setReplyValue(e.target.value);
                        setIsDisable(false);
                      }}
                      suggestions={suggestions}
                      onSearch={onSearch}
                      field="nickname"
                      placeholder={placeholder.ADD_A_REPLY}
                      rows={5}
                      cols={40}
                      itemTemplate={itemTemplate}
                      style={{ border: "1px solid #747474" }}
                      inputClassName="bg-transparent w-[100%] resize-none"
                      className="bg-transparent w-[80%] h-[120px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
                    /> */}
                    <button
                      disabled={replyValue && !isDisable ? false : true}
                      onClick={() => {
                        commentReplyHandle();
                      }}
                      className="text-base font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[90px] h-[35px] disabled:bg-[#ccff0094] my-2"
                    >
                      {strings.REPLY}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {item?.comments?.map((msg, index) => (
              <div key={index} className=" flex items-center justify-end ">
                <div className="w-[90%] py-2.5 border-b border-[#595959] ">
                  <div className="flex items-center justify-between max-lg:flex-wrap  gap-y-3">
                    <div className="flex items-start gap-2">
                      <img
                        src={
                          msg?.gymStaffInfo?.mProfileImg?.prof_img1_min ||
                          ifImgNull
                        }
                        // src={ifImgNull}
                        className="w-[30px] h-[30px] rounded-full "
                      />

                      <p className="mb-0 text-lg flex font-bold whitespace-pre-wrap break-words w-full max-lg:w-full ">
                        <span className="text-[#C5C5C5] text-lg font-extrabold">
                          {"@"}
                          {(msg?.authorDetails?.gymStaffName).replace(" ", "_")}
                        </span>{" "}
                        {msg?.comment}{" "}
                        <span
                          onClick={() => {
                            setCommentId(msg.id);
                          }}
                          className={`${
                            commentId == msg.id
                              ? "text-[#CCFF00]"
                              : "text-[#C5C5C5]"
                          } ${"gap-2 text-sm font-bold inline-flex items-center align-middle pl-2 cursor-pointer"}`}
                        >
                          <Icon
                            icon="f7:arrowshape-turn-up-left-fill"
                            width="16"
                            height="16"
                            style={{ color: "#CCFF00" }}
                          />{" "}
                          {strings.REPLY}
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center w-[47.7%] justify-between gap-3 max-lg:flex-wrap max-lg:w-full ">
                      <div className="flex items-center gap-x-9 gap-y-3 max-lg:flex-wrap justify-start">
                        <div className="flex items-center gap-2">
                          <div className="flex items-center gap-2 ">
                            <Icon
                              icon="lets-icons:date-today"
                              width="25"
                              height="25"
                              style={{ color: "#fff" }}
                            />
                            <p className="mb-0 text-lg font-bold border-r border-[#838485] pr-4 max-sm:pr-3">
                              {moment(msg?.regDate).format("YYYY.MM.DD")}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <Icon
                              icon="ion:time-outline"
                              width="25"
                              height="25"
                              style={{ color: "#fff" }}
                            />
                            <p className="mb-0 text-base font-bold">
                              {moment(msg?.regDate).format("h:mm")}{" "}
                              <span className="text-white text-lg font-bold">
                                {moment().format("A")}
                              </span>
                            </p>
                          </div>
                        </div>
                        <p className="mb-0 text-base font-bold">
                          {msg?.gymStaffInfo?.gymStaffName}
                        </p>
                      </div>
                      <Icon
                        icon="material-symbols:delete-outline"
                        width="25"
                        height="25"
                        className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                        onClick={() => {
                          setDeleteCommentId(msg?.id);
                          setDeleteComment(true);
                        }}
                      />
                    </div>
                  </div>
                  {commentId == msg.id && (
                    <div className="sticky bottom-0 bg-[#353638] flex items-end gap-2 justify-start my-4">
                      <textarea
                        style={{ border: " 1px solid #747474" }}
                        value={replyValue}
                        onChange={(e) => {
                          setReplyValue(e.target.value);
                        }}
                        placeholder={placeholder.ADD_A_REPLY}
                        className="bg-transparent w-full h-[100px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
                      />
                      {/* <Mention
                        value={replyValue}
                        onChange={(e) => {
                          setReplyValue(e.target.value);
                          setIsDisable(false);
                        }}
                        suggestions={suggestions}
                        onSearch={onSearch}
                        field="nickname"
                        placeholder={placeholder.ADD_A_REPLY}
                        rows={5}
                        cols={40}
                        itemTemplate={itemTemplate}
                        style={{ border: "1px solid #747474" }}
                        inputClassName="bg-transparent w-[100%] resize-none"
                        className="bg-transparent w-[80%] h-[120px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
                      /> */}
                      <button
                        disabled={replyValue && !isDisable ? false : true}
                        onClick={() => {
                          commentReplyHandle();
                        }}
                        className="text-base font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[90px] h-[35px] disabled:bg-[#ccff0094] my-2"
                      >
                        {strings.REPLY}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        );
      })}
      <DeleteConfirmation
        textHead={strings.DELETE_COMMENT}
        text={strings.DO_YOU_WANT_DELETE_COMMENT}
        deleteMemo={deleteComment}
        setDeleteMemo={setDeleteComment}
        clickBtn={deleteCommentHandle}
        btnText={strings.DELETE}
        classNameValue={classNameValue}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </>
  );
}

export default CommentBox;
