import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import logo from "../../../assests/icons/Logo.svg";
import { strings } from "../../../utils/String";
import GymCard from "../../commonComponents/GymCard";
import { Container } from "react-bootstrap";
import LoginFailed from "../../commonComponents/modalScreens/LoginFailed";
import WelcomeModal from "../../commonComponents/modalScreens/WelcomeModal";
import { useDispatch, useSelector } from "react-redux";
import {
  centerHandler,
  getToken,
  selectYourGymHandle,
} from "../../../Store/slices/AuthSlice";
import MembersSublyApi from "../../../helper/MemberApi";
import Loader from "../../../utils/Loader";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";

function SelectYourGym() {
  const { gymDetails } = useSelector((state) => state.user.userDetails);
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
  const [gymId, setGymId] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [userName, setUserName] = useState("");
  const [gymData, setGymData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: gymDetails.length > 6 ? 6 : gymDetails.length,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: gymDetails.length > 4 ? 5 : gymDetails.length,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1624,
        settings: {
          slidesToShow: gymDetails.length > 4 ? 5 : gymDetails.length,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: gymDetails.length > 3 ? 4 : gymDetails.length,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: gymDetails.length > 2 ? 3 : gymDetails.length,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: gymDetails.length > 1 ? 2 : gymDetails.length,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: gymDetails.length > 1 ? 2 : gymDetails.length,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  // ================Select gym API==============
  async function selectGymHandle() {
    setIsLoader(true);
    await MembersSublyApi.selectYourGym(gymId.gymId, gymId?.staffType, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setOpenWelcomeModal(true);
          setUserName(response?.data?.user?.name);
          setGymData(response?.data);
          setTimeout(() => {
            dispatch(selectYourGymHandle(response.data));
            dispatch(
              centerHandler({
                name: response.data?.user?.name,
                image: response.data?.selectedGymDetails?.images,
                role: response.data?.role,
                centerName: response.data?.selectedGymDetails?.name,
                userProfile: response.data?.user?.profile?.prof_img1_min,
              })
            );
            dispatch(getToken(response.data.token));
          }, 2000);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section className="py-12">
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
      {isLoader ? <Loader /> : ""}
      <div className="text-center mb-12">
        <img src={logo} alt="logo" className="w-32 h-16 mx-auto mb-4" />
      </div>
      <p className="text-3xl font-bold text-white text-center pb-1">
        {strings.FOUND_AT_MULTIPLE_GYMS}
      </p>
      <Container fluid>
        <Slider {...settings} arrows={false} rows={1}>
          {gymDetails?.map((item, index) => (
            <div key={index} className="my-2 gap-1 flex">
              <GymCard item={item} gymId={gymId} setGymId={setGymId} />
            </div>
          ))}
        </Slider>
      </Container>
      <div className="flex justify-center mt-12">
        <button
          disabled={gymId ? false : true}
          onClick={() => {
            selectGymHandle();
          }}
          className="text-black font-extrabold text-xl h-[60px] bg-common_color p-2.5 rounded-[28px] w-[30%] max-lg:w-[40%] max-sm:w-[60%] max-md:w-[50%]"
        >
          {strings.LOGIN}
        </button>
      </div>
      F{" "}
      <WelcomeModal
        showModal={openWelcomeModal}
        setShowModal={setOpenWelcomeModal}
        gymData={gymData}
        userName={userName}
      />
    </section>
  );
}

export default SelectYourGym;
