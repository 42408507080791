import { strings } from "./String";
import board from "../assests/icons/sideBar icon/Board.svg";
import pt_info from "../assests/icons/sideBar icon/user-info.svg";
import userSetting from "../assests/icons/sideBar icon/user-setting.svg";
import statistic from "../assests/icons/sideBar icon/Statics icon.svg";
import statisticBold from "../assests/icons/sideBar icon/bold icon/Statics bold icon.svg";
import user from "../assests/icons/sideBar icon/user.svg";
import setting from "../assests/icons/sideBar icon/Setting.svg";
import userBold from "../assests/icons/sideBar icon/bold icon/user bold.svg";
import boardBold from "../assests/icons/sideBar icon/bold icon/Board bold.svg";
import pt_infoBold from "../assests/icons/sideBar icon/bold icon/user-info bold.svg";
import userSettingBold from "../assests/icons/sideBar icon/bold icon/user-setting bold.svg";
import settingBold from "../assests/icons/sideBar icon/bold icon/Setting bold.svg";

export const sideBarMenu = [
  {
    name: strings.MEMBER_SEARCH,
    icon: user,
    activeIcon: userBold,
    path: "/Member-search",
    isOption: false,
    key: "",
  },
  {
    name: strings.STATISTICS,
    icon: statistic,
    activeIcon: statisticBold,
    path: "/Statistics-Sales",
    isOption: true,
    key: "statisticsSales",
    backUpPath: "/Statistics-Members",
  },
  {
    name: strings.PT_INFORMATION,
    icon: pt_info,
    activeIcon: pt_infoBold,
    path: "/PT-information/Review",
    isOption: true,
    key: "ptReviews",
    backUpPath: "/PT-information/Workout-log",
  },
  {
    name: strings.BOARD,
    icon: board,
    activeIcon: boardBold,
    path: "/Board/General",
    isOption: true,
    key: "board",
  },
  {
    name: strings.MANAGEMENT,
    icon: userSetting,
    activeIcon: userSettingBold,
    path: "/Management/Product",
    isOption: true,
    key: "events",
    secondKey: "events",
    backUpPath: "/Management/Refund-request",
  },
  {
    name: strings.GYM_SETTING,
    icon: setting,
    activeIcon: settingBold,
    path: "/Gym-setting/Staff-setting",
    isOption: true,
    key: "staffSettings",
    backUpPath: "/Gym-setting/Gym-settings/Gym-information",
  },
];

export const statisticSubOption = [
  {
    name: strings.SALES,
    path: "/Statistics-Sales",
    key: "statisticsSales",
  },
  {
    name: strings.MEMBERS,
    path: "/Statistics-Members",
    key: "statisticsMembers",
  },
  { name: strings.PT, path: "/Statistics-PT", key: "statisticsPt" },
];

export const ptInformationSubOption = [
  {
    name: strings.REVIEW,
    path: "/PT-information/Review",
    key: "ptReviews",
  },
  { name: strings.WORKOUT_LOG, path: "/PT-information/Workout-log", key: "" },
  { name: strings.DIET_LOG, path: "/PT-information/Diet-log", key: "" },
  { name: strings.SCHEDULE, path: "/PT-information/Schedule", key: "" },
  { name: strings.STATUS, path: "/PT-information/Status", key: "" },
];

export const boardSubOption = [
  {
    name: strings.GENERAL,
    path: "/Board/General",
    key: "",
  },
  { name: strings.SUGGESTIONS_LOG, path: "/Board/Suggestions-log", key: "" },
  { name: strings.NOTICE, path: "/Board/Notice", key: "noticeSettings" },
];

export const gymSettingSubOption = [
  {
    name: strings.STAFF_SETTING,
    path: "/Gym-setting/Staff-setting",
    key: "staffSettings",
  },
  {
    name: strings.GYM_SETTING,
    path: "/Gym-setting/Gym-settings/Gym-information",
    key: "gymSettings",
  },
  {
    name: strings.MESSAGE_SETTING,
    path: "/Gym-setting/Message-setting",
    key: "messageSetting",
  },
];

export const managementSubOption = [
  {
    name: strings.PRODUCT,
    path: "/Management/Product",
    key: "events",
  },
  { name: strings.EVENT, path: "/Management/Event", key: "events" },
  { name: strings.REFUND_REQUEST, path: "/Management/Refund-request", key: "" },
  {
    name: strings.HOLDING_REQUEST,
    path: "/Management/Holding-request",
    key: "refundHolding",
  },
  {
    name: strings.Change_Trainer_Req,
    path: "/Management/Change-Trainer-Req",
    key: "",
  },
];

export const gymSettingsSubOption = [
  {
    name: strings.GYM_INFORMATION,
    path: "/Gym-setting/Gym-settings/Gym-information",
    key: "",
  },
  {
    name: strings.SPORTS_EQUIPMENT_INFORMATION,
    path: "/Gym-setting/Gym-settings/Sports-equipment-setting",
    key: "",
  },
  {
    name: strings.INFORMATION_SETTING,
    path: "/Gym-setting/Gym-settings/Information-setting",
    key: "",
  },
  {
    name: strings.GYM_LOCKER_SETTING,
    path: "/Gym-setting/Gym-settings/GymLockerSetting",
    key: "",
  },
];
