import React, { useState } from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";

function AgeGraph({ image, text, openAgeGraph, setOpenAgeGraph, graphValue }) {
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    LineController,
    BarController,
    ArcElement
  );
  // const ageData = graphValue?.ageData
  // console.log("----graphValue", graphValue);
  const data = {
    labels: [],
    data: [],
  };
  (graphValue || []).map((item, index) => {
    data.labels.push(
      `${graphValue[index - 1] ? graphValue[index - 1]?.age + 1 : 0}-${
        item?.age
      }`
    );
    data.data.push(item?.percentage);
  });

  // console.log("🚀 ~ AgeGraph ~ data:", data);
  const datavalue = {
    labels: data?.labels?.reverse(),
    datasets: [
      {
        label: "# of Votes",
        data: data?.data?.reverse(),
        backgroundColor: [
          "#00C808",
          "#5C80FF",
          "#FFAD33",
          "#FF9999",
          "#DCE002",
          "#D9D9D9",
          "#FF6347",
          "#ADFF2F",
          "#20B2AA",
          "#9370DB",
          "#3CB371",
        ],
        borderColor: [
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
        ],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    indexAxis: "y", 
    scales: {
      x: {
        ticks: {
          color: "#fff" 
        }
      },
      y: {
        ticks: {
          color: "#fff" 
        }
      }
    }
  };

  return (
    <>
      <Modal
        className={`${"modalBoxClass classForMargin"}`}
        show={openAgeGraph}
        onHide={() => {
          setOpenAgeGraph(false);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <p className="border-b-2 border-[#444444] pb-3 mb-0 text-[40px] max-lg:text-[28px] font-extrabold flex items-center gap-2 justify-center">
            <img src={image} className="w-[56px] h-[56px]" /> {text}{" "}
          </p>

          <div className=" m-auto my-5 flex items-center">
            {/* <p className="rotate-90">age AGE_DISTRIBUTION</p> */}
            {/* <div className="w-full h-full"> */}

            <Bar data={datavalue} options={options} />
            {/* </div> */}
          </div>
          <div className="flex justify-center pt-8 gap-4">
            <button
              onClick={() => {
                setOpenAgeGraph(false);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                  >
              {strings.CLOSE}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AgeGraph;
