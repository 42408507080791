import React, { useEffect, useState } from "react";
import Loader from "../../../utils/Loader";
import { Col, Container, Placeholder, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import BoardSublyApi from "../../../helper/BoardApi";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import ReactPaginate from "react-paginate";
import RegistrationForm from "./RegistrationForm";
import GeneralDetailScreen from "./GeneralDetailScreen";
import $ from "jquery";

function GeneralScreen() {
  const type = 0;
  const { token } = useSelector((state) => state.user);
  const [isLoader, setIsLoader] = useState(false);
  const [generalBoard, setGeneralBoard] = useState("");
  const [openMonth, setOpenMonth] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [monthValue, setMonthValue] = useState({ text: "All" });
  const [titleValue, setTitleValue] = useState({
    text: "Title",
    value: "title",
  });
  const [active, setActive] = useState("active");
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [boardId, setBoardId] = useState({ id: "" });
  const [openDetail, setOpenDetail] = useState(false);
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  //   ============API for fetching board general list Data==============
  useEffect(() => {
    if (!openForm && !openDetail) {
      boardListHandle();
    }
  }, [offset, openForm, openDetail]);

  async function boardListHandle(durationValue, page) {
    setIsLoader(true);
    await BoardSublyApi.fetchBoardList(
      type,
      token,
      page ? page : offset,
      titleValue.value,
      search,
      durationValue
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode === 200) {
          setGeneralBoard(response.data.boardData);
          setPage(response.data.count / 20);
          page && setOffset(1);
          boardId({ id: "" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setOpenDetail(false);
      setOpenForm(false);
      setBoardId("");
      setEditedValue("");
      setOpenMonth(false);
      setOpenTitle(false);
      setIsEdit("");
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col xl={10} className="p-0 relative overflow-auto">
            <Header text={strings.GYM_BOARD_GENERAL} />
            <div className="mx-6 ">
              <div className="mt-4 mb-7 flex items-center justify-between gap-3 flex-wrap">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <Icon
                      icon="lets-icons:date-today"
                      width="33"
                      height="33"
                      style={{ color: "#CCFF00" }}
                    />
                    <p className="mb-0 text-[24px] font-extrabold">
                      {moment(new Date()).format("YYYY.MM.DD")}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Icon
                      icon="ion:time-outline"
                      width="33"
                      height="33"
                      style={{ color: "#CCFF00" }}
                    />
                    <p className="mb-0 text-[24px] font-extrabold">
                      {moment().format("h:mm")}
                      <span className="text-white text-lg font-bold">
                        {moment().format("A")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-x-3 gap-y-3 flex-wrap">
                  <label
                    onClick={() => {
                      setOpenMonth(!openMonth);
                      setOpenTitle(false);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 cursor-pointer w-full text-[#fff] font-bold text-lg whitespace-nowrap">
                      {monthValue.text}{" "}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={openMonth ? 2 : 4}
                    />
                    {openMonth && (
                      <MonthFilter
                        setMonthValue={setMonthValue}
                        boardListHandle={boardListHandle}
                      />
                    )}
                  </label>
                  <label
                    onClick={() => {
                      setOpenTitle(!openTitle);
                      setOpenMonth(false);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                      {titleValue.text}{" "}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={openTitle ? 2 : 4}
                    />
                    {openTitle && <TitleFilter setTitleValue={setTitleValue} />}
                  </label>
                  <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Keywords"
                      className="bg-transparent w-full text-white text-lg font-bold"
                    />
                  </label>
                  <button
                    onClick={() => {
                      setOffset(1);
                      boardListHandle();
                    }}
                    className="hover:text-black hover:bg-[#CCFF00] text-lg font-extrabold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              {generalBoard?.length == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              {generalBoard?.length > 0 && (
                <div className="overflow-auto scrollingClass">
                  <div className="max-2xl:w-[1300px]">
                    <div className="flex justify-around items-center bg-[#353638] h-[60px] border-b border-[#353638]">
                      <th className="!text-center text-[20px] font-semibold text-white w-[20%] border-l-2 border-[#444444] h-[58px] flex justify-center items-center">
                        {strings.ID}
                      </th>
                      <th className="!text-center text-[20px] font-semibold text-white w-full border-x-2 border-[#444444] h-[58px] flex justify-center items-center px-4">
                        {strings.TITLE}
                      </th>
                      <th className="!text-center text-[20px] font-semibold text-white w-[30%] ">
                        {placeholder.NAME}
                      </th>
                      <th className="!text-center text-[20px] font-semibold text-white w-[30%] border-x-2 border-[#444444] h-[58px] flex justify-center items-center ">
                        {strings.DATE}
                      </th>
                      <th className="!text-center text-[20px] font-semibold text-white w-[30%] flex justify-center items-center border-r-2 border-[#444444] h-[58px]">
                        {strings.VIEWS}
                      </th>
                    </div>
                    <div className="border-b border-[#444444] overflow-auto max-h-[calc(100vh_-_380px)] ">
                      {generalBoard &&
                        generalBoard?.map((items, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setBoardId({ id: items.id });
                              setOpenDetail(true);
                            }}
                            className={` ${
                              boardId.id == items.id
                                ? "activeBorder bg-[#353638] last:border-b"
                                : "border-b-2 border-[#444444] last:border-b-0"
                            } ${"flex justify-around items-center cursor-pointer "}`}
                          >
                            <td
                              align="center"
                              className={` 
                              ${
                                boardId.id == items.id
                                  ? ""
                                  : "border-l-2 border-[#444444]"
                              } ${"text-white font-bold text-lg  relative w-[20%] h-[40px] flex items-center justify-center"}`}
                            >
                              {20 * (offset - 1) + (index + 1)}
                            </td>
                            <th
                              className={`${
                                boardId.id == items.id
                                  ? ""
                                  : "border-x-2 border-[#444444]"
                              } ${"!text-start text-[18px] font-bold text-white w-full h-[40px] flex items-center px-4 overflow-auto titleScrollClass text-ellipsis whitespace-nowrap"}`}
                            >
                              {items.title}{" "}
                              {items?.commentCounts > 0 &&
                                `(${items.commentCounts})`}
                            </th>
                            <th className="!text-center text-[18px] font-bold text-white w-[30%] ">
                              {items?.gymStaffInfo?.gymStaffName || "- -"}
                            </th>
                            <th
                              className={`${
                                boardId.id == items.id
                                  ? ""
                                  : "border-x-2 border-[#444444]"
                              } ${"!text-center text-[18px] font-bold text-white w-[30%] h-[40px] flex justify-center items-center "}`}
                            >
                              <p className="mb-0 text-lg font-bold">
                                <Moment format="YY.MM.DD">
                                  {items?.regDate}
                                </Moment>
                              </p>
                            </th>
                            <th
                              className={`${
                                boardId.id == items.id
                                  ? ""
                                  : "border-r-2 border-[#444444]"
                              } ${"!text-center text-[18px] font-bold text-white w-[30%] flex justify-center items-center h-[40px]"}`}
                            >
                              {items.views || 0}
                            </th>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center gap-1 mt-2 mb-4 py-4 border-b border-[#444444]">
                <div className="w-full">
                  {page && page > 1 && (
                    <div className="mt-3">
                      {" "}
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        forcePage={offset == 1 ? 0 : offset - 1}
                        pageCount={page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={active}
                        onClick={() => setActive("active")}
                      />
                    </div>
                  )}
                </div>
                <button
                  onClick={() => {
                    setOpenForm(true);
                  }}
                  className="text-black bg-[#CCFF00] text-lg font-extrabold rounded-[30px] h-[50px] w-[160px]"
                >
                  {strings.WRITE}
                </button>
              </div>
            </div>
            {openForm && (
              <RegistrationForm
                setOpenForm={setOpenForm}
                setIsLoader={setIsLoader}
                isEdit={isEdit}
                id={boardId.id}
                setIsEdit={setIsEdit}
                editedValue={editedValue}
                setBoardId={setBoardId}
                type={type}
                classNameValue={`${openForm ? "block_notify" : "hideNotify"}`}
                head={`${isEdit ? "Edit article" : "Register"}`}
              />
            )}
            {openDetail && (
              <GeneralDetailScreen
                setIsLoader={setIsLoader}
                setOpenDetail={setOpenDetail}
                id={boardId.id}
                setBoardId={setBoardId}
                setOpenForm={setOpenForm}
                setIsEdit={setIsEdit}
                setEditedValue={setEditedValue}
                type={type}
                classNameValue={`${openDetail ? "block_notify" : "hideNotify"}`}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default GeneralScreen;

function MonthFilter({ setMonthValue, boardListHandle }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setMonthValue({ text: "All" });
          boardListHandle("all", 1);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      <div
        onClick={() => {
          setMonthValue({ text: "1 week" });
          boardListHandle("week", 1);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ONE_WEEK}</span>
      </div>
      <div
        onClick={() => {
          setMonthValue({ text: "1 month" });
          boardListHandle("month", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ONE_MONTH}</span>
      </div>
      <div
        onClick={() => {
          setMonthValue({ text: "6 month" });
          boardListHandle("half_month", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.SIX_MONTH}</span>
      </div>
      <div
        onClick={() => {
          setMonthValue({ text: "1 year" });
          boardListHandle("year", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ONE_YEAR}</span>
      </div>
    </div>
  );
}

function TitleFilter({ setTitleValue }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-14 max-h-[200px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setTitleValue({ text: "Title", value: "title" });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.TITLE}</span>
      </div>
      <div
        onClick={() => {
          setTitleValue({ text: "Content", value: "content" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.CONTENT}</span>
      </div>
      <div
        onClick={() => {
          setTitleValue({ text: "Title + Content", value: "title_content" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">
          {strings.TITLE} + {strings.CONTENT}
        </span>
      </div>
      <div
        onClick={() => {
          setTitleValue({ text: "Writer", value: "writer" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.WRITER}</span>
      </div>
    </div>
  );
}
