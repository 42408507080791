import React, { useState } from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";

function DeleteConfirmation({
  classNameValue,
  text,
  textHead,
  deleteMemo,
  setDeleteMemo,
  clickBtn,
  btnText,
  buttonKey,
  subText,
  setLockerId,
}) {
  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass"}`}
        show={deleteMemo}
        onHide={() => {
          setDeleteMemo(false);
          setLockerId && setLockerId("");
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <p className="border-b-2 border-[#444444] pb-3 mb-6 text-[40px] max-lg:text-[28px] font-extrabold">
            {textHead}
          </p>
          <p className="text-[30px] max-lg:text-[18px] font-bold pb-2 mb-0">
            {text}
            {subText && <p className="mt-4">{subText}</p>}
          </p>
          <div className="flex justify-center pt-8 gap-4">
            {buttonKey ? (
              <button
                onClick={() => {
                  setDeleteMemo(false);
                  setLockerId && setLockerId("");
                }}
                className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.CLOSE}
              </button>
            ) : (
              <>
                <button
                  onClick={() => {
                    setDeleteMemo(false);
                    setLockerId && setLockerId("");
                  }}
                  className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
                >
                  {strings.CANCEL}
                </button>
                <button
                  onClick={() => clickBtn()}
                  className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px]"
                >
                  {btnText}
                </button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteConfirmation;
