import React, { useEffect, useState } from "react";
import { strings } from "../../../../../utils/String";
import weight from "../../../../../assests/icons/gym-settings-icons/weightPlate.svg";
import { scrollToTopFun } from "../../StaffSetting";
import { EQUIPMENT_ICONS } from "../../../../../utils/CommonData";

function EquipmentInformation({
  setshowMachineInfo,
  setMachineId,
  setaddInformation,
  setShowAddMachineInfo,
  setsportEuipmentInfo,
  freeWeightData,
  equpmentData,
  equipmentType,
}) {
  return (
    <div>
      <>
        {equipmentType === "Sports equipment information" ||
          ("All" && (
            <>
              {" "}
              <div className="flex items-center justify-between my-1 flex-wrap gap-3">
                <p className="mb-0 text-2xl font-extrabold">
                  {strings.MACHINES}
                </p>
                <div className="flex items-center gap-x-3 max-sm:flex-wrap gap-y-3">
                  <button
                    className={`${"text-base font-bold h-[50px] w-[120px]  rounded-[28px] text-black  bg-[#CCFF00]"}`}
                    onClick={() => {
                      setShowAddMachineInfo(true);
                    }}
                  >
                    {strings.ADD}
                  </button>
                </div>
              </div>
              {equpmentData?.machine.length > 0 ? (
                <div className="rounded-[10px] bg-[#2C2C2E] p-4 my-4">
                  <div
                    className={`flex items-center gap-3 flex-wrap ${
                      equpmentData?.machine.length > 5 ? "justify-center" : ""
                    }`}
                  >
                    {equpmentData?.machine?.map((item, index) => {
                      return (
                        <div className="">
                          <div
                            className="w-[235px] h-[235px] bg-[#949494] flex items-center justify-center rounded-[10px]"
                            onClick={() => {
                              setshowMachineInfo(true);
                              setMachineId(item.id);
                              scrollToTopFun();
                            }}
                          >
                            <img
                              className="w-[235px] h-[235px] rounded-[10px] cursor-pointer"
                              src={item.files[0]?.url}
                            />
                          </div>

                          <p className="mb-0 text-xl font-extrabold text-center py-3.5">
                            {item?.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
            </>
          ))}
      </>

      {equipmentType === "Machines" ||
        ("All" && (
          <>
            <div className="flex items-center justify-between my-1 flex-wrap gap-3">
              <p className="mb-0 text-2xl font-extrabold">
                {strings.SPORTS_EQUIPMENT_INFORMATION}
              </p>
              <div className="flex items-center gap-x-3 max-sm:flex-wrap gap-y-3">
                {/* <button
                  className={`${"text-base font-bold h-[50px] w-[120px]  rounded-[28px] hover:text-black hover:bg-[#CCFF00] text-white kakaoClass"}`}
                  onClick={() => {
                    setaddInformation(true);
                    scrollToTopFun();
                  }}
                >
                  {strings.ADD}
                </button> */}
                <button
                  className={`${"text-base font-bold h-[50px] w-[120px] rounded-[28px] hover:text-black hover:bg-[#CCFF00] text-white kakaoClass"}`}
                  onClick={() => {
                    setsportEuipmentInfo(true);
                    freeWeightData(equpmentData?.freeWeight);
                    scrollToTopFun();
                  }}
                >
                  {strings.UPDATE}
                </button>
              </div>
            </div>
            {equpmentData?.freeWeight.length > 0 ? (
              <div className="rounded-[10px] bg-[#2C2C2E] p-4 my-4">
                <div className="flex items-center gap-x-3 gap-y-7 flex-wrap justify-start">
                  {equpmentData?.freeWeight?.map((item) => {
                    return (
                      <div className="w-[180px] h-[180px] px-2 flex items-center justify-center border-r border-[#585858]">
                        <div className="">
                          <div className="w-14 h-14 flex items-center justify-between m-auto">
                            <img
                              src={EQUIPMENT_ICONS[item?.title]}
                              className="w-full h-full"
                            />
                          </div>
                          <p className="mb-0 text-lg font-bold text-center text-[#E0E0E0] pt-3 ">
                            {item?.title}
                          </p>
                          <p className="mb-0 text-[26px] font-extrabold text-center pt-1.5">
                            {item?.quantity}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                {strings.NO_RECORD_FOUND}
              </p>
            )}
          </>
        ))}
    </div>
  );
}

export default EquipmentInformation;
