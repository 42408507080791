import React  from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";

function StatusChanged({ statusModal, setStatusModal, productStatus }) {
  return (
    <>
      <Modal
        className={`${"modalBoxClass modalWidthClass"}`}
        show={statusModal}
        onHide={() => {
          setStatusModal(false);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <h3 className="flex items-center justify-center gap-3 border-b-2 border-[#555555] max-lg:text-[28px] pb-4 mb-6 text-[40px] font-extrabold w-[80%] m-auto">
            <Icon
              icon="ep:warning-filled"
              width="40"
              height="40"
              style={{ color: "#FF5C5C" }}
            />
            {strings.STATUS_CHANGED}
          </h3>
          <p className="text-[30px] max-lg:text-[18px] font-bold pt-4 m-auto w-[70%]">
            {`The status of ${productStatus.productType} has been changed to`}{" "}
            <span className="text-white text-[33px] max-lg:text-[20px] font-bold ">
              {productStatus.action ? "On" : "Off"}
            </span>
            .
          </p>
          <p className="mb-0 text-[30px] max-lg:text-[18px] font-bold">
            Members can now select the product during registration.{" "}
          </p>
          <div className="flex justify-center pt-8 gap-4">
            <button
              onClick={() => {
                setStatusModal(false);
              }}
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CONFIRM}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StatusChanged;
