import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LoginScreen from "../components/authComponents/loginScreen/LoginScreen";
import SelectYourGym from "../components/authComponents/selectYourGym/SelectYourGym";
import ForgotAccount from "../components/authComponents/forgotAccount/ForgotAccount";
import Verification from "../components/authComponents/verificationScreen/Verification";
import MemberSearch from "../components/appComponents/memberSearch/MemberSearch";
import { useSelector } from "react-redux";
import Sales from "../components/appComponents//statisticsScreens/Sales";
import Members from "../components/appComponents/statisticsScreens/Members";
import DietLog from "../components/appComponents/ptInformation/DietLog";
import WorkoutLog from "../components/appComponents/ptInformation/WorkoutLog";
import PtScreen from "../components/appComponents/statisticsScreens/PtScreen";
import GeneralScreen from "../components/appComponents/boardScreens/GeneralScreen";
import StaffSetting from "../components/appComponents/gymSettings/StaffSetting";
import StatusScreen from "../components/appComponents/ptInformation/StatusScreen";
import SuggestionsLog from "../components/appComponents/boardScreens/SuggestionsLog";
import ReviewScreen from "../components/appComponents/ptInformation/ReviewScreen";
import Product from "../components/appComponents/management/Product";
import ScheduleScreen from "../components/appComponents/ptInformation/ScheduleScreen";
import NoticeScreen from "../components/appComponents/boardScreens/NoticeScreen";
import EventScreen from "../components/appComponents/management/EventScreen";
import GymSettingsScreen from "../components/appComponents/gymSettings/gymSettingsScreens/sportsEquipmentInfo/GymSettingsScreen";
import HoldingRequest from "../components/appComponents/management/HoldingRequest";
import ChangeTrainerRequest from "../components/appComponents/management/ChangeTrainerRequest";
import GymLockerSetting from "../components/appComponents/gymSettings/gymSettingsScreens/gymLockerSetting/GymLockerSetting";
import GymInfoScreen from "../components/appComponents/gymSettings/gymSettingsScreens/gymInformationScreens/GymInfoScreen";
import RefundRequest from "../components/appComponents/management/RefundRequest";
import AttendanceLoginScreen from "../attendanceScreens/AttendanceLoginScreen";
import ResetYourPassword from "../attendanceScreens/ResetYourPassword";
import MemberAttendance from "../attendanceScreens/MemberAttendance";
import InformationSetting from "../components/appComponents/gymSettings/gymSettingsScreens/gymInformationScreens/informationSettings/InformationSetting";
import UserInfoScreen from "../attendanceScreens/UserInfoScreen";
import MessageSetting from "../components/appComponents/gymSettings/messageSettiing/MessageSetting";
import MemberNotFound from "../components/authComponents/loginScreen/MemberNotFound";

function Router() {
  const { token } = useSelector((state) => state.user);
  const { attendanceDetail } = useSelector((state) => state.user);
  const { selectedGym } = useSelector((state) => state.user);
  const { gymDetails } = useSelector((state) => state.user.userDetails);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            token && selectedGym?.gymId ? (
              <Navigate to={"/Member-search"} />
            ) : (
              <LoginScreen />
            )
          }
        />
        <Route
          path="/Member-not-found"
          element={
            token && gymDetails?.length == 0 ? (
              <MemberNotFound />
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Select-your-gym"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <SelectYourGym />
              ) : (
                <Navigate to={"/Member-search"} />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Forgot-account"
          element={
            token ? <Navigate to={"/Member-search"} /> : <ForgotAccount />
          }
        />
        <Route
          path="/Verification"
          element={
            token ? <Navigate to={"/Member-search"} /> : <Verification />
          }
        />
        <Route
          path="/Member-search"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <MemberSearch />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Statistics-Sales"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <Sales />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Statistics-Members"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <Members />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/PT-information/Diet-log"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <DietLog />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/PT-information/Workout-log"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <WorkoutLog />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Statistics-PT"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <PtScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Board/General"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <GeneralScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Gym-setting/Staff-setting"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <StaffSetting />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/PT-information/Status"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <StatusScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Board/Suggestions-log"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <SuggestionsLog />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/PT-information/Review"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <ReviewScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Management/Product"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <Product />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/PT-information/Schedule"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <ScheduleScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Board/Notice"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <NoticeScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Management/Event"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <EventScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Management/Refund-request"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <RefundRequest />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Management/Holding-request"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <HoldingRequest />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />{" "}
        <Route
          path="/Gym-setting/Gym-settings/Sports-equipment-setting"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <GymSettingsScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Gym-setting/Gym-settings/Gym-information"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <GymInfoScreen />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Management/Change-Trainer-Req"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <ChangeTrainerRequest />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Gym-setting/Gym-settings/GymLockerSetting"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <GymLockerSetting />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Gym-setting/Gym-settings/Information-setting"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <InformationSetting />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
        <Route
          path="/Gym-setting/Message-setting"
          element={
            token ? (
              !selectedGym?.gymId ? (
                <Navigate to={"/"} />
              ) : (
                <MessageSetting />
              )
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
      </Routes>
      {/* ================================================ATTENDANCE SECTION================================================= */}
      <Routes>
        <Route
          path="/Attendance/Login"
          element={
            !attendanceDetail?.token ? (
              <AttendanceLoginScreen />
            ) : (
              <Navigate to={"/Attendance/Member"} />
            )
          }
        />
        <Route
          path="/Attendance/Reset-password"
          element={
            !attendanceDetail?.token ? (
              <ResetYourPassword />
            ) : (
              <Navigate to={"/Attendance/Member"} />
            )
          }
        />
        <Route
          path="/Attendance/Member"
          element={
            attendanceDetail?.token ? (
              <MemberAttendance />
            ) : (
              <Navigate to={"/Attendance/Login"} />
            )
          }
        />
        <Route
          path="/Attendance/User-info"
          element={
            attendanceDetail?.token ? (
              <UserInfoScreen />
            ) : (
              <Navigate to={"/Attendance/Login"} />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
