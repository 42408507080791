import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import trainer from "../../../assests/icons/trainerIcon.svg";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import sessionCard from "../../../assests/icons/statistics PT session/sessionCardIcon.svg";
import ReviewCard from "../../commonComponents/ReviewCard";
import ReviewDetail from "./ReviewDetail";
import ReactPaginate from "react-paginate";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import $ from "jquery";
import { alphaOnly, numberOnly } from "../../../utils/commonUtils";

function ReviewScreen() {
  const { token } = useSelector((state) => state.user);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [openDetail, setOpenDetail] = useState({ status: false, id: "" });
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState("");
  const [trainerValue, setTrainerValue] = useState({ id: "", name: "All" });
  const [status, setStatus] = useState({
    text: "Session date",
    value: "session_date",
  });
  const [reviewData, setReviewData] = useState("");
  const [trainerList, setTrainerList] = useState("");
  const [openTrainer, setOpenTrainer] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  // =============Fetch review list API==============
  useEffect(() => {
    reviewListHandle();
  }, [status, trainerValue, offset]);
  async function reviewListHandle(pageValue) {
    setIsLoader(true);
    await PT_InformationSublyApi.fetchReviewList(
      token,
      pageValue ? pageValue : offset,
      name,
      trainerValue.id,
      phone,
      status.value
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          pageValue && setOffset(1);
          setReviewData(response.data.reviews);
          setPage(response.data.count / 9);
          setTrainerList(response.data.trainerList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  $("#nameId").bind("keypress", alphaOnly);
  $("#numberId").bind("keypress", numberOnly);

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0 
    ) {
      setOpenDetail({ status: false, id: "" });
      setOpenTrainer(false)
      setOpenDropDown(false)
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`${
              openDetail.status
                ? "!overflow-hidden  h-screen"
                : "!overflow-auto  h-screen"
            } ${"p-0 relative"}`}
          >
            <Header text={strings.PT_INFORMATION_REVIEW} />
            <div className="mx-6 relative">
              <div className="flex items-center justify-between gap-3 flex-wrap my-4">
                <div className="flex items-center gap-3 flex-wrap">
                  <div className="flex items-center justify-between gap-3 flex-wrap">
                    <label
                      htmlFor="nameId"
                      className="bg-[#2C2C2E] w-[310px] max-sm:w-full h-[50px] px-3 flex items-center gap-2 rounded-[30px]"
                    >
                      <p className="!mb-0 flex items-center gap-2 text-[#A1A1AA] font-bold text-lg whitespace-nowrap">
                        <Icon
                          icon="majesticons:user-line"
                          width="24"
                          height="24"
                          style={{ color: "white" }}
                        />
                      </p>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        className="bg-[#2C2C2E] text-white font-bold"
                        id="nameId"
                        placeholder={placeholder.NAME}
                        autoComplete="off"
                      />
                    </label>
                    <label
                      htmlFor="numberId"
                      className="bg-[#2C2C2E] w-[310px] max-sm:w-full h-[50px] px-3 flex items-center gap-2 rounded-[30px] numberFieldClass"
                    >
                      <p className="!mb-0 flex items-center gap-2 text-[#A1A1AA] font-bold text-lg whitespace-nowrap">
                        <Icon
                          icon="ph:phone"
                          width="24"
                          height="24"
                          style={{ color: "white" }}
                        />
                      </p>
                      <input
                        type="number"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        className="bg-[#2C2C2E] text-white font-bold numberField"
                        id="numberId"
                        placeholder={placeholder.CELL_PHONE}
                        autoComplete="off"
                      />
                    </label>
                    <label
                      onClick={() => {
                        setOpenTrainer(!openTrainer);
                        setOpenDropDown(false);
                      }}
                      className="bg-[#2C2C2E] w-[310px] max-sm:w-full h-[50px] px-6 flex items-center justify-between gap-2 rounded-[30px] relative dropdownClass"
                    >
                      <p className="!mb-0 flex items-center gap-2 text-[#fff] font-bold text-lg whitespace-nowrap">
                        <img
                          src={trainer}
                          alt="trainer"
                          className="w-[22px] h-[22px]"
                        />
                        {trainerValue?.name}
                      </p>
                      <Icon
                        icon="bxs:down-arrow"
                        width="16"
                        height="16"
                        style={{ color: "#D9D9D9" }}
                        rotate={openTrainer ? 2 : 4}
                      />
                      {openTrainer && (
                        <TrainerDropdown
                          trainerList={trainerList}
                          setTrainerValue={setTrainerValue}
                        />
                      )}
                    </label>
                  </div>
                  <button
                    onClick={() => {
                      reviewListHandle(1);
                    }}
                    className="font-[archivo] hover:text-black hover:bg-[#CCFF00] text-lg font-bold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
                <label
                  onClick={() => {
                    setOpenDropDown(!openDropDown);
                    setOpenTrainer(false);
                  }}
                  className="bg-[#2C2C2E] w-[310px] max-sm:w-full h-[50px] px-6 flex items-center justify-between gap-2 rounded-[30px] relative dropdownClass"
                >
                  <p className="!mb-0 flex items-center gap-2 text-[#fff] font-bold text-lg whitespace-nowrap">
                    <img
                      src={sessionCard}
                      alt="card"
                      className="w-[23px] h-[21px]"
                    />
                    {status.text}
                  </p>
                  <Icon
                    icon="bxs:down-arrow"
                    width="16"
                    height="16"
                    style={{ color: "#D9D9D9" }}
                    rotate={openDropDown ? 2 : 4}
                  />
                  {openDropDown && <KeyDropdown setStatus={setStatus} />}
                </label>
              </div>
              {reviewData?.length == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              <Row className="overflow-auto h-[calc(100vh_-_200px)] gap-y-4">
                {reviewData?.length > 0 &&
                  reviewData?.map((item, index) => (
                    <Col key={index} lg={6} xxl={4}>
                      <ReviewCard item={item} setOpenDetail={setOpenDetail} />
                    </Col>
                  ))}
                {page && page > 1 && (
                  <div className="w-full">
                    <div className="mt-3">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        forcePage={offset == 1 ? 0 : offset - 1}
                        pageCount={page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </Row>
            </div>
            {openDetail.status && (
              <ReviewDetail
                setOpenDetail={setOpenDetail}
                openDetail={openDetail}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  openDetail.status ? "block_notify" : "hideNotify"
                }`}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ReviewScreen;

function TrainerDropdown({ trainerList, setTrainerValue }) {
  return (
    <div
      style={{ border: "1px solid #686868" }}
      className="absolute rounded-[10px] bg-[#353638] min-w-[160px] right-4 top-10 max-h-[200px] overflow-auto z-10 px-2"
    >
      <div className="text-white text-lg font-bold py-2 trainerList">
        <p
          onClick={() => {
            setTrainerValue({ id: "", name: "All" });
          }}
          className="hover:bg-[#686868] py-1 mb-0 flex items-center gap-0.5 px-2"
        >
          {/* <Icon
            icon="radix-icons:dot-filled"
            width="20"
            height="20"
            style={{ color: "white" }}
          /> */}
          {strings.ALL}
        </p>
        {trainerList?.length > 0 &&
          trainerList?.map((item, index) => (
            <p
              key={index}
              onClick={() => {
                setTrainerValue({ id: item.id, name: item.gymStaffName });
              }}
              className="hover:bg-[#686868] py-1 mb-0 flex items-center gap-0.5 px-2"
            >
              {/* <Icon
                icon="radix-icons:dot-filled"
                width="20"
                height="20"
                style={{ color: "white" }}
              /> */}
              {item.gymStaffName}
            </p>
          ))}
      </div>
    </div>
  );
}

function KeyDropdown({ setStatus }) {
  return (
    <div
      style={{ border: "1px solid #686868" }}
      className="absolute rounded-[10px] bg-[#353638] min-w-[160px] px-3 right-4 top-10 max-h-[200px] overflow-auto z-10"
    >
      <div className="text-white text-lg font-bold py-2 trainerList">
        <p
          onClick={() => {
            setStatus({ text: "Session date", value: "session_date" });
          }}
          className="hover:bg-[#686868] py-1 mb-0 flex items-center gap-0.5 px-2"
        >
          {/* <Icon
            icon="radix-icons:dot-filled"
            width="20"
            height="20"
            style={{ color: "white" }}
          /> */}
          {strings.SESSION_DATE}
        </p>
        <p
          onClick={() => {
            setStatus({ text: "Review date", value: "review_date" });
          }}
          className="hover:bg-[#686868] py-1 mb-0 flex items-center gap-0.5 px-2"
        >
          {/* <Icon
            icon="radix-icons:dot-filled"
            width="20"
            height="20"
            style={{ color: "white" }}
          /> */}
          {strings.REVIEW_DATE}
        </p>
      </div>
    </div>
  );
}
