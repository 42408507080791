import React, { useEffect, useState } from "react";
import logo from "../../../assests/icons/Logo.svg";
import { strings } from "../../../utils/String";
import AuthButton from "../../commonComponents/AuthButton";
import "../loginScreen/LoginScreen.css";
import { Container } from "react-bootstrap";
import apple from "../../../assests/icons/apple.svg";
import kakao from "../../../assests/icons/kakao.svg";
import never from "../../../assests/icons/never.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  adminPanelHandle,
  getToken,
  selectYourGymHandle,
  userLoginWith,
  userLoginWithApple,
  userLoginWithNaver,
} from "../../../Store/slices/AuthSlice";
import KakaoLogin from "react-kakao-login";
import NaverLogin from "react-naver-login";
import Loader from "../../../utils/Loader";
import LoginFailed from "../../commonComponents/modalScreens/LoginFailed";
import AppleSignin from "react-apple-signin-auth";

function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeBtn, setActiveBtn] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    dispatch(adminPanelHandle(true));
  }, []);

  // =============Calling API for login with Kakao===============
  async function loginHandle(e) {
    setIsLoader(true);
    await dispatch(userLoginWith(e.response.access_token)).then(
      async (responsejson) => {
        const response = responsejson.payload.response;
        setIsLoader(false);
        if (response.statusCode == 200) {
          setActiveBtn("");
          if (response.data.gymDetails.length > 1) {
            await dispatch(getToken(response.data.token));
            await navigate("/Select-your-gym");
          } else if (response.data.gymDetails.length == 1) {
            await dispatch(getToken(response.data.token));
            await dispatch(selectYourGymHandle(response.data));
            await navigate("/Member-search");
          } else {
            // await getToken("");
            // setShowModal(true);
            setErrorText("Please try again later or contact to the admin");
            setTimeout(() => {
              setShowModal(false);
              navigate("/Member-not-found");
            }, 2000);
          }
        } else {
          setShowModal(true);
          setErrorText(strings.TRY_IT_AGAIN_WITH_CORRECT);
        }
      }
    );
  }

  // =============Calling API for login with Naver===============
  async function naverloginHandle(e) {
    setIsLoader(true);
    const requestData = e;
    await dispatch(userLoginWithNaver(requestData)).then(
      async (responsejson) => {
        const response = responsejson.payload.response;
        setIsLoader(false);
        if (response.statusCode == 200) {
          setActiveBtn("");
          if (response.data.gymDetails.length > 1) {
            await navigate("/Select-your-gym");
          } else if (response.data.gymDetails.length == 1) {
            await dispatch(selectYourGymHandle(response.data));
            await navigate("/Member-search");
          } else {
            // await getToken("");
            // setShowModal(true);
            setErrorText("Please try again later or contact to the admin");
            setTimeout(() => {
              setShowModal(false);
              navigate("/Member-not-found");
            }, 2000);
          }
        } else {
          setShowModal(true);
          setErrorText(strings.TRY_IT_AGAIN_WITH_CORRECT);
        }
      }
    );
  }

  // =========Apple login handling========
  async function appleLoginHandle(e) {
    setIsLoader(true);
    await dispatch(userLoginWithApple(e.authorization.id_token)).then(
      async (responsejson) => {
        const response = responsejson.payload.response;
        setIsLoader(false);
        if (response.statusCode == 200) {
          setActiveBtn("");
          if (response.data.gymDetails.length > 1) {
            await navigate("/Select-your-gym");
          } else if (response.data.gymDetails.length == 1) {
            await dispatch(selectYourGymHandle(response.data));
            await navigate("/Member-search");
          } else {
            // await getToken("");
            setShowModal(true);
            setErrorText("Please try again later or contact to the admin");
            setTimeout(() => {
              setShowModal(false);
              navigate("/Member-not-found");
            }, 2000);
          }
        } else {
          setShowModal(true);
          setErrorText(strings.TRY_IT_AGAIN_WITH_CORRECT);
        }
      }
    );
  }

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container className="flex items-center justify-center h-screen">
        <div className="bg-box_bg w-[70%] rounded-lg max-md:w-[90%] py-10 px-6">
          <div className="w-[60%] m-auto max-lg:w-full text-center">
            <div>
              <img src={logo} alt="logo" className="w-32 h-24 mx-auto mb-4" />
            </div>
            <div>
              <div>
                <KakaoLogin
                  token={process.env.REACT_APP_KAKAO_TOKEN}
                  onSuccess={(e) => {
                    loginHandle(e);
                    // setActiveBtn(1);
                  }}
                  onFail={console.error}
                  onLogout={console.info}
                  style={{ backgroundColor: "transparent", width: "100%" }}
                >
                  <AuthButton
                    btnStyle={
                      activeBtn == 1
                        ? "kakaoClass"
                        : "bg-[#FFFFFF1A] logibBtnOver"
                    }
                    icon={kakao}
                  />
                </KakaoLogin>
              </div>
              <div className="my-4">
                <NaverLogin
                  clientId={process.env.REACT_APP_NAVER_CLIENT_ID}
                  callbackUrl={process.env.REACT_APP_NAVER_CALLBACK}
                  render={(props) => {
                    return (
                      <div>
                        <AuthButton
                          btnStyle={
                            activeBtn == 2
                              ? "kakaoClass"
                              : "bg-[#FFFFFF1A] logibBtnOver"
                          }
                          icon={never}
                          clickFun={() => {
                            props.onClick();
                            // setActiveBtn(2);
                          }}
                        />
                      </div>
                    );
                  }}
                  onSuccess={(naverUser) => naverloginHandle(naverUser)}
                  onFailure={(result) => console.error(result)}
                />
              </div>
              <div>
                <AppleSignin
                  authOptions={{
                    clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                    redirectURI: "https://thejal.duckdns.org/",
                    scope: "email name",
                    state: "",
                    nonce: "nonce",
                    usePopup: true,
                  }}
                  className="apple-auth-btn"
                  onSuccess={appleLoginHandle}
                  onError={(error) => console.error(error)}
                  render={(renderProps) => {
                    return (
                      <AuthButton
                        btnStyle={
                          activeBtn == 3
                            ? "kakaoClass"
                            : "bg-[#FFFFFF1A] logibBtnOver"
                        }
                        icon={apple}
                        clickFun={() => {
                          renderProps.onClick();
                          // setActiveBtn(2);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <p
              onClick={() => navigate("/Forgot-account")}
              className="text-[#787878] text-xl font-bold mt-3 cursor-pointer max-lg:text-lg"
            >
              {strings.FORGOT_ACCOUNT}
            </p>
          </div>
        </div>
      </Container>
      <LoginFailed
        showModal={showModal}
        setShowModal={setShowModal}
        errorText={errorText}
      />{" "}
    </section>
  );
}

export default LoginScreen;
