import React, { useEffect, useState } from "react";
import profile from "../../../assests/images/demoProfile.svg";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import { Table } from "react-bootstrap";
import rightArrow from "../../../assests/icons/right-arrow-icon.svg";
import { useSelector } from "react-redux";

function StaffAuthorityTable({
  staffAuthority,
  updatedValue,
  setUpdatedValue,
  setOpenAlert,
}) {
  const [checkboxValue, setCheckboxValue] = useState([]);
  const { role } = useSelector((state) => state.user.selectedGym);
  // const [openAlert, setOpenAlert] = useState(false);
  useEffect(() => {
    const authorityData =
      staffAuthority &&
      staffAuthority?.map((val) => {
        return val.authority;
      });
    setCheckboxValue(authorityData);
  }, [staffAuthority]);

  // =============Staff Authority Value handling============
  async function staffAuthorityHandle(index, id) {
    const authority = checkboxValue[index];
    const copyValue = [...updatedValue];
    const value = copyValue.find((val) => val?.staffId == id);

    if (value) {
      value.authority = authority;
      setUpdatedValue([...copyValue]);
    } else {
      setUpdatedValue([...copyValue, { staffId: id, authority }]);
    }
  }
  return (
    <>
      <div className="overflow-auto 2xl:max-h-[calc(100vh_-_540px)] max-2xl:max-h-[600px]">
        <div className="max-2xl:w-[1600px] staffListClass">
          <Table className="max-w-max !bg-transparent">
            <thead className="bg-[#353638] sticky top-[-1px] z-10">
              <th className="!text-center text-lg px-2 font-semibold text-white w-[13%] ">
                {strings.NAME_}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[7%] ">
                {strings.STATISTICS_SALES}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[9%] ">
                {strings.STATISTICS_MEMBERS}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[8%] ">
                {strings.STATISTICS_PT}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[10%] ">
                {strings.GYM_STAFF_SETTING}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[10%] ">
                {strings.GYM_GYM_SETTING}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[9%] ">
                {strings.GYM_NOTICE}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[7%] ">
                {strings.BOARD}
              </th>
              {/* {role == 2 && ( */}
              <th className="!text-center text-lg px-2 font-semibold text-white w-[9%] ">
                {strings.PT_INFORMATION_REVIEW}
              </th>
              {/* )} */}
              <th className="!text-center text-lg px-2 font-semibold text-white w-[9%] ">
                {strings.MENAGEMENT_R_H}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[9%] ">
                {strings.MENAGEMENT_P_E}
              </th>
              <th className="!text-center text-lg px-2 font-semibold text-white w-[9%] ">
                {strings.MESSAGE_SETTING}
              </th>
            </thead>
            <tbody>
              {staffAuthority?.length > 0
                ? staffAuthority?.map((item, index) => (
                    <tr key={index} className={`${"cursor-pointer"}`}>
                      <td
                        align="center"
                        className={` 
                        ${"text-white font-bold text-lg ps-3 relative"}`}
                      >
                        <div className="flex items-center gap-2 text-start">
                          <img
                            src={item?.profile?.prof_img1_min || profile}
                            alt="item-profile"
                            className="rounded-full w-[35px] h-[35px]"
                          />
                          {item.name || "- -"}
                        </div>
                      </td>
                      <td
                        className={`${"!text-center text-[18px] !px-0 font-bold text-white"}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isStatisticsSalesEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          {" "}
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue &&
                                checkboxValue[index]?.statisticsSales
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].statisticsSales = true;
                                  value[index].isStatisticsSalesEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].statisticsSales = false;
                                  value[index].isStatisticsSalesEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue &&
                            checkboxValue[index]?.statisticsSales ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>
                          {checkboxValue &&
                            checkboxValue[index]?.isStatisticsSalesEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isStatisticsSalesEdited &&
                                !checkboxValue[index]?.statisticsSales
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isStatisticsSalesEdited &&
                                !checkboxValue[index]?.statisticsSales
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>
                      <td
                        className={` ${"!text-center text-[18px] !px-0  font-bold text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isStatisticsMembersEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-2 py-1.5"}`}
                        >
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue &&
                                checkboxValue[index]?.statisticsMembers
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].statisticsMembers = true;
                                  value[index].isStatisticsMembersEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].statisticsMembers = false;
                                  value[index].isStatisticsMembersEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue &&
                            checkboxValue[index]?.statisticsMembers ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>
                          {checkboxValue &&
                            checkboxValue[index]?.isStatisticsMembersEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]
                                  ?.isStatisticsMembersEdited &&
                                !checkboxValue[index]?.statisticsMembers
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]
                                  ?.isStatisticsMembersEdited &&
                                !checkboxValue[index]?.statisticsMembers
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>
                      <td
                        className={`${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isStatisticsPtEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue &&
                                checkboxValue[index]?.statisticsPt
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].statisticsPt = true;
                                  value[index].isStatisticsPtEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].statisticsPt = false;
                                  value[index].isStatisticsPtEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue &&
                            checkboxValue[index]?.statisticsPt ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>
                          {checkboxValue &&
                            checkboxValue[index]?.isStatisticsPtEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isStatisticsPtEdited &&
                                !checkboxValue[index]?.statisticsPt
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isStatisticsPtEdited &&
                                !checkboxValue[index]?.statisticsPt
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>
                      <td
                        className={`${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isStaffSettingsEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          {" "}
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue &&
                                checkboxValue[index]?.staffSettings
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].staffSettings = true;
                                  value[index].isStaffSettingsEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].staffSettings = false;
                                  value[index].isStaffSettingsEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue &&
                            checkboxValue[index]?.staffSettings ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>{" "}
                          {checkboxValue &&
                            checkboxValue[index]?.isStaffSettingsEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isStaffSettingsEdited &&
                                !checkboxValue[index]?.staffSettings
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isStaffSettingsEdited &&
                                !checkboxValue[index]?.staffSettings
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>{" "}
                      <td
                        className={`${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isGymSettingsEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          {" "}
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue &&
                                checkboxValue[index]?.gymSettings
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].gymSettings = true;
                                  value[index].isGymSettingsEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].gymSettings = false;
                                  value[index].isGymSettingsEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue &&
                            checkboxValue[index]?.gymSettings ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>{" "}
                          {checkboxValue &&
                            checkboxValue[index]?.isGymSettingsEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isGymSettingsEdited &&
                                !checkboxValue[index]?.gymSettings
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isGymSettingsEdited &&
                                !checkboxValue[index]?.gymSettings
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>{" "}
                      <td
                        className={`${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isNoticeSettingsEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          {" "}
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue &&
                                checkboxValue[index]?.noticeSettings
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].noticeSettings = true;
                                  value[index].isNoticeSettingsEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].noticeSettings = false;
                                  value[index].isNoticeSettingsEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue &&
                            checkboxValue[index]?.noticeSettings ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>{" "}
                          {checkboxValue &&
                            checkboxValue[index]?.isNoticeSettingsEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isNoticeSettingsEdited &&
                                !checkboxValue[index]?.noticeSettings
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isNoticeSettingsEdited &&
                                !checkboxValue[index]?.noticeSettings
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>{" "}
                      <td
                        className={` ${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue && checkboxValue[index]?.isBoardEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue && checkboxValue[index]?.board
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].board = true;
                                  value[index].isBoardEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].board = false;
                                  value[index].isBoardEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue && checkboxValue[index]?.board ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>{" "}
                          {checkboxValue &&
                            checkboxValue[index]?.isBoardEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isBoardEdited &&
                                !checkboxValue[index]?.board
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isBoardEdited &&
                                !checkboxValue[index]?.board
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>{" "}
                      {
                        <td
                          className={`${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                        >
                          <div
                            className={`${
                              checkboxValue &&
                              checkboxValue[index]?.isPtReviewsEdited
                                ? "isEditedClass"
                                : ""
                            } ${"w-full h-full px-4 py-1.5"}`}
                          >
                            <label className="flex justify-center items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="hidden"
                                disabled={item?.staffType == 2 ? true : false}
                                checked={
                                  checkboxValue &&
                                  checkboxValue[index]?.ptReviews
                                }
                                onChange={(e) => {
                                  if (role == 2) {
                                    if (e.target.checked) {
                                      let value = [...checkboxValue];
                                      value[index].ptReviews = true;
                                      value[index].isPtReviewsEdited = true;
                                      setCheckboxValue(value);
                                      staffAuthorityHandle(index, item.staffId);
                                    } else {
                                      let value = [...checkboxValue];
                                      value[index].ptReviews = false;
                                      value[index].isPtReviewsEdited = true;
                                      setCheckboxValue(value);
                                      staffAuthorityHandle(index, item.staffId);
                                    }
                                  } else {
                                    setOpenAlert(true);
                                  }
                                }}
                              />
                              {checkboxValue &&
                              checkboxValue[index]?.ptReviews ? (
                                <Icon
                                  icon="fluent:radio-button-20-filled"
                                  width="25"
                                  height="25"
                                  style={{ color: "#ccff00" }}
                                />
                              ) : (
                                <Icon
                                  icon="fluent:radio-button-20-regular"
                                  width="25"
                                  height="25"
                                  style={{ color: "#888888" }}
                                  className="bg-[#2C2C2E] rounded-full"
                                />
                              )}
                            </label>{" "}
                            {checkboxValue &&
                              checkboxValue[index]?.isPtReviewsEdited && (
                                <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                  {checkboxValue &&
                                  checkboxValue[index]?.isPtReviewsEdited &&
                                  !checkboxValue[index]?.ptReviews
                                    ? strings.ON
                                    : strings.OFF}
                                  <img src={rightArrow} alt="right-arrow" />
                                  {checkboxValue &&
                                  checkboxValue &&
                                  checkboxValue[index]?.isPtReviewsEdited &&
                                  !checkboxValue[index]?.ptReviews
                                    ? strings.OFF
                                    : strings.ON}
                                </p>
                              )}
                          </div>
                        </td>
                      }{" "}
                      <td
                        className={`${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isRefundHoldingEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          {" "}
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue &&
                                checkboxValue[index]?.refundHolding
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].refundHolding = true;
                                  value[index].isRefundHoldingEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].refundHolding = false;
                                  value[index].isRefundHoldingEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue &&
                            checkboxValue[index]?.refundHolding ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>{" "}
                          {checkboxValue &&
                            checkboxValue[index]?.isRefundHoldingEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isRefundHoldingEdited &&
                                !checkboxValue[index]?.refundHolding
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isRefundHoldingEdited &&
                                !checkboxValue[index]?.refundHolding
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>{" "}
                      <td
                        className={`${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isEventsEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          {" "}
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue && checkboxValue[index]?.events
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].events = true;
                                  value[index].isEventsEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].events = false;
                                  value[index].isEventsEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue && checkboxValue[index]?.events ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>{" "}
                          {checkboxValue &&
                            checkboxValue[index]?.isEventsEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isEventsEdited &&
                                !checkboxValue[index]?.events
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isEventsEdited &&
                                !checkboxValue[index]?.events
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>{" "}
                      <td
                        className={`${"!text-center text-[18px] font-bold !px-0  text-white "}`}
                      >
                        <div
                          className={`${
                            checkboxValue &&
                            checkboxValue[index]?.isMessageSettingEdited
                              ? "isEditedClass"
                              : ""
                          } ${"w-full h-full px-4 py-1.5"}`}
                        >
                          {" "}
                          <label className="flex justify-center items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="hidden"
                              disabled={item?.staffType == 2 ? true : false}
                              checked={
                                checkboxValue &&
                                checkboxValue[index]?.messageSetting
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let value = [...checkboxValue];
                                  value[index].messageSetting = true;
                                  value[index].isMessageSettingEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                } else {
                                  let value = [...checkboxValue];
                                  value[index].messageSetting = false;
                                  value[index].isMessageSettingEdited = true;
                                  setCheckboxValue(value);
                                  staffAuthorityHandle(index, item.staffId);
                                }
                              }}
                            />
                            {checkboxValue &&
                            checkboxValue[index]?.messageSetting ? (
                              <Icon
                                icon="fluent:radio-button-20-filled"
                                width="25"
                                height="25"
                                style={{ color: "#ccff00" }}
                              />
                            ) : (
                              <Icon
                                icon="fluent:radio-button-20-regular"
                                width="25"
                                height="25"
                                style={{ color: "#888888" }}
                                className="bg-[#2C2C2E] rounded-full"
                              />
                            )}
                          </label>{" "}
                          {checkboxValue &&
                            checkboxValue[index]?.isMessageSettingEdited && (
                              <p className="flex items-center justify-center gap-1 text-sm font-bold mb-0">
                                {checkboxValue &&
                                checkboxValue[index]?.isMessageSettingEdited &&
                                !checkboxValue[index]?.messageSetting
                                  ? strings.ON
                                  : strings.OFF}
                                <img src={rightArrow} alt="right-arrow" />
                                {checkboxValue &&
                                checkboxValue &&
                                checkboxValue[index]?.isMessageSettingEdited &&
                                !checkboxValue[index]?.messageSetting
                                  ? strings.OFF
                                  : strings.ON}
                              </p>
                            )}
                        </div>
                      </td>{" "}
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default StaffAuthorityTable;
