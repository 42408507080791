import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthSublyApi from "../../helper/AuthApi";
import AttendanceSublyApi from "../../helper/AttendanceApi";

const initialState = {
  userDetails: {},
  selectedGym: {},
  attendanceDetail: {},
  token: "",
  isLoading: false,
  success: false,
  staffAuthRespo: {},
  chatIdResponse: { id: "", status: false },
  totalUnreadMessage: "",
  isAdminPanel: false,
  rememberMe: { gymId: "", password: "" },
  centerDetail: {},
};

// Thunk for user login
export const userLoginWith = createAsyncThunk(
  "loginWithKakaoAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthSublyApi.loginWithKakao(data);
      return { response: response };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user login
export const userLoginWithNaver = createAsyncThunk(
  "loginWithNaverAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthSublyApi.loginWithNaver(data);
      return { response: response };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userLoginWithApple = createAsyncThunk(
  "loginWithAppleAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthSublyApi.loginWithApple(data);
      return { response: response };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user login on attendance screen
export const userLoginAttendance = createAsyncThunk(
  "loginAttendance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AttendanceSublyApi.attendanceLogin(data);
      return { response: response };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userMediaSlice = createSlice({
  name: "loginWithAdmin",
  initialState,
  reducers: {
    deleteUserDetail: (state) => {
      state.userDetails = {};
      state.selectedGym = {};
      state.token = "";
    },
    selectYourGymHandle: (state, action) => {
      state.selectedGym = action.payload;
    },
    getToken: (state, action) => {
      state.token = action.payload;
    },
    getUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    fetchStaffAuth: (state, action) => {
      state.staffAuthRespo = action.payload;
    },
    chatIdHandle: (state, action) => {
      state.chatIdResponse = {
        id: action.payload.response,
        status: action.payload.status,
      };
    },
    messageCount: (state, action) => {
      state.totalUnreadMessage = action.payload;
    },
    adminPanelHandle: (state, action) => {
      state.isAdminPanel = action.payload;
    },
    attendanceLogout: (state, action) => {
      state.attendanceDetail = {};
    },
    rememberMeHandle: (state, action) => {
      state.rememberMe = {
        gymId: action.payload.gymId,
        password: action.payload.password,
      };
    },
    centerHandler: (state, action) => {
      state.centerDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for Login kakao-------
    builder.addCase(userLoginWith.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLoginWith.fulfilled, (state, action) => {
      const response = action.payload.response;
      state.isLoading = false;
      if (response?.statusCode === 200) {
        state.userDetails = response.data;
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(userLoginWith.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for Login naver-------
    builder.addCase(userLoginWithNaver.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLoginWithNaver.fulfilled, (state, action) => {
      const response = action.payload.response;
      state.isLoading = false;
      if (response?.statusCode === 200) {
        state.userDetails = response.data;
        state.token = response.data.token;
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(userLoginWithNaver.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for Login apple-------
    builder.addCase(userLoginWithApple.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLoginWithApple.fulfilled, (state, action) => {
      const response = action.payload.response;
      state.isLoading = false;
      if (response?.statusCode === 200) {
        state.userDetails = response.data;
        state.token = response.data.token;
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(userLoginWithApple.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for Login attendance-------
    builder.addCase(userLoginAttendance.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLoginAttendance.fulfilled, (state, action) => {
      const response = action.payload.response;
      state.isLoading = false;
      if (response?.statusCode === 200) {
        state.attendanceDetail = response.data;
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(userLoginAttendance.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  deleteUserDetail,
  selectYourGymHandle,
  getToken,
  getUserDetails,
  fetchStaffAuth,
  chatIdHandle,
  messageCount,
  adminPanelHandle,
  attendanceLogout,
  rememberMeHandle,
  centerHandler,
} = userMediaSlice.actions;
export default userMediaSlice.reducer;
