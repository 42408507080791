import React from "react";
import { placeholder, strings } from "../../../utils/String";
import moment from "moment";
import memberIconGray from "../../../assests/icons/member-group gray.svg";
import profile from "../../../assests/images/demoProfile.svg";
import { scrollToTopFun } from "../gymSettings/StaffSetting";

function StatusTrainerTable({
  item,
  index,
  setTrainerId,
  setPtHistory,
  trainerId,
  setMessageList,
  setmemberId,
}) {
  return (
    <div className="bg-[#333333] rounded-[10px] p-3 mb-3" key={index}>
      <div className="flex items-center justify-between">
        <p className="mb-0 text-xl font-semibold font-[Archivo] flex items-center gap-2">
          <p className="rounded-full w-[38px] h-[38px] mb-0">
            <img
              src={profile}
              className="object-cover w-full h-full rounded-full"
            />
          </p>
          {item?.trainerName}
        </p>
        <div className="flex items-center gap-2 justify-center bg-[#2C2C2E] rounded-[10px] w-[178px] p-2 mr-4">
          <img src={memberIconGray} />
          <p className="mb-0 text-lg font-semibold font-[Archivo]">
            {item?.memberCount} {strings.MEMBERS}
          </p>
        </div>
      </div>
      <div className="overflow-auto scrollingClass my-2">
        <div className="max-2xl:w-[1600px]">
          <div className="flex justify-around items-center bg-[#474749] min-h-[60px] border-b border-[#353638]">
            <th
              className={`${"border-l border-[#444444]"} ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-3 py-1 w-[40%]  min-h-[60px] flex justify-around items-center"}`}
            >
              {placeholder.NAME}
            </th>
            <th
              className={`${"border-l border-[#444444]"} ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[60px] flex justify-around items-center"}`}
            >
              {strings.CAP_SESSION}
            </th>
            <th
              className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[40%]  min-h-[60px] flex justify-around items-center "}`}
            >
              {strings.RECENT_SESSION}
            </th>
            <th
              className={` ${"text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 text-center py-1 w-[20%] flex justify-around items-center min-h-[60px]"}`}
            >
              {strings.CANCEL_COUNT}
            </th>
            <th
              className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[22%] flex justify-around items-center min-h-[60px]"}`}
            >
              {strings.NOSHOW_COUNT}
            </th>
            <th
              className={`${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[50%] flex justify-around items-center min-h-[60px]"}`}
            >
              {strings.PT_MEMBERSHIP_DATE}
            </th>
            <th
              className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
            >
              {strings.REGISTER_DATE}
            </th>
            <th
              className={`${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
            >
              {strings.STATUS}
            </th>
            <th className="!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[30%] flex justify-center items-center border-x border-[#444444] min-h-[60px]">
              {strings.SENT_MSG}
            </th>
          </div>
          <div className="border-b border-[#444444] overflow-auto max-h-[calc(100vh_-_550px)] ">
            {item?.members?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setTrainerId(item.memberOwnProductId);
                  setPtHistory(true);
                  scrollToTopFun();
                }}
                className={`${
                  item.memberOwnProductId == trainerId && "kakaoClass"
                } ${"flex justify-around items-center cursor-pointer"}`}
              >
                {item.userProfile?.name ? (
                  <th className="!text-start text-base font-semibold font-[Archivo] text-white px-3 py-1 w-[40%] border-l border-[#444444] min-h-[35px] flex justify-start items-center">
                    {index + 1}. {item.userProfile?.name}
                  </th>
                ) : (
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[40%] border-l border-[#444444] min-h-[35px] flex justify-center items-center">
                    - -
                  </th>
                )}
                <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] border-l border-[#444444] min-h-[35px] flex justify-center items-center">
                  {`${item.completeCnt}/${item.totalSession}`}
                </th>

                <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[40%] border-x border-[#444444] min-h-[35px] flex justify-center items-center ">
                  {item.latestCompletedSession
                    ? `${moment(item.latestCompletedSession).format(
                        "YY.MM.DD"
                      )}`
                    : "- -"}
                  {item.recentSession ? `( +${item.recentSession}d)` : ""}
                </th>
                <th className="text-base font-semibold font-[Archivo] text-white px-2 text-start py-1 w-[20%] flex justify-center items-center min-h-[35px]">
                  {item.cancelCnt}{" "}
                </th>
                <th className="!text-start text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[22%] flex justify-center items-center border-x border-[#444444] min-h-[35px]">
                  {item.noShowCnt}
                </th>
                <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[50%] flex justify-center items-center min-h-[35px]">
                  {moment(item.membershipStartDate).format("YY.MM.DD")} -{" "}
                  {moment(item.membershipEndDate).format("YY.MM.DD")}
                </th>
                <th className="!text-start text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-center border-x border-[#444444] items-center min-h-[35px]">
                  {moment(item.userProfile.registrationDate).format(
                    "YY.MM.DD"
                  ) || "- -"}{" "}
                </th>
                <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-center items-center min-h-[35px]">
                  {!item.status ? "Expired" : "- -"}
                </th>
                <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[30%] flex justify-center items-center border-x border-[#444444] min-h-[35px]">
                  {item.conversationExists ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setMessageList(true);
                        setmemberId(item?.userProfile?.memberId);
                      }}
                      className="text-black font-[Archivo] bg-[#CCFF00] text-base font-bold h-[28px] w-[100px] rounded-[28px]"
                    >
                      {strings.LIST}
                    </button>
                  ) : (
                    "- -"
                  )}
                </th>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusTrainerTable;
