import React, { useState } from "react";
import { strings } from "../../../../../utils/String";
import ReactPaginate from "react-paginate";
import {
  getPaginatedRecord,
  numberOnly,
} from "../../../../../utils/commonUtils";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";
import $ from "jquery";
import DuplicateLockers from "./DuplicateLockers";
function AddLockersModal({ setaddLocker, setIsLoader, classNameValue }) {
  const { token } = useSelector((state) => state.user);

  const TOTAL_LOCKERS = 200;
  const [lockers, setLockers] = useState(
    Array.from({ length: TOTAL_LOCKERS }, () => ({}))
  );
  const [page, setPage] = useState(TOTAL_LOCKERS / 15);
  const [offset, setOffset] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [duplicateLocker, setDuplicateLocker] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [lockerError, setLockerError] = useState();
  const [errorHeading, setErrorHeading] = useState(false);
  const [lockerCheck, setLockerCheck] = useState(false);

  const handlePageClick = async (data) => {
    setOffset(data.selected + 1);
  };

  async function saveLockers() {
    setIsLoader(true);
    const data = lockers?.filter(
      (locker) => {
        if (locker?.lockerPassword) {
          locker.lockerPassword = locker.lockerPassword;
        } else {
          delete locker.lockerPassword;
        }
        return locker.lockerNumber;
      }
      // (locker) => Object.values(locker || {})?.length
    );
    await GymSettingSublyApi.addLockers(token, { lockers: data })
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setaddLocker(false);
          setLockers(Array.from({ length: TOTAL_LOCKERS }, () => ({})));
        } else if (response.data.data.isDuplicate) {
          setDuplicateLocker(true);
          setLockerError(response.data.data.lockers);
          setErrorHeading(true);
        } else if (response.data.data.isAlreadyExists) {
          setDuplicateLocker(true);
          setLockerError(response.data.data.lockers);
          setErrorHeading(false);
        } else {
          // setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const validateLockerNumber = (value) => {
    const regex = /^[A-Za-z0-9]+$/;
    return regex.test(value);
  };

  // const handleLockerInsert = (value, key, index) => {
  //   if (!validateLockerNumber(value)) {
  //   //  setLockerCheck(
  //   //    "Locker number can only contain English letters and numbers."
  //   //  );
  //     setLockerCheck(true);
  //     return;
  //   }
  //   const newLockers = [...lockers];
  //   newLockers[index] = { ...newLockers[index], [key]: value };
  //   setLockers(newLockers);
  // };

  const handleLockerInsert = (value, key, index) => {
    const newLockers = [...lockers];
    newLockers[index] = { ...newLockers[index], [key]: value };
    setLockers(newLockers);
  };
  const lockerList = getPaginatedRecord(lockers, offset, 15);
  //--------function for handling contact input filed------
  // $("#numberId").bind("keypress", numberOnly);

  const handleKeyPress = (e) => {
    if (!validateLockerNumber(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex items-center justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4  z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[90%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.ADD_LOCKERS}
            </p>
          </div>
          <div className="mt-4 flex flex-col gap-2 lg:px-32">
            {lockerList?.map((item, index) => {
              const currentSno =
                (!offset || offset <= 1 ? 0 : offset - 1) * 15 + (index + 1);
              const lastUndefined = lockers?.findIndex((i) => !i?.lockerNumber);
              return (
                <>
                  <div
                    key={currentSno.toString()}
                    className="flex flex-wrap items-center justify-center gap-2.5"
                  >
                    <div className="flex flex-auto items-center gap-2.5 w-full sm:w-auto">
                      <div className="text-white text-base font-semibold font-[Archivo]">
                        {currentSno}
                      </div>
                      <input
                        className={`flex-1 h-[40px] bg-[#414142] text-[#fff] font-bold placeholder:font-normal placeholder:text-[#A1A1AA] rounded-[10px] px-3`}
                        placeholder="Add Locker No."
                        type="text"
                        value={item?.lockerNumber}
                        disabled={currentSno - 2 >= lastUndefined}
                        onChange={(e) => {
                          handleLockerInsert(
                            e?.target?.value.trim(),
                            "lockerNumber",
                            currentSno - 1
                          );
                        }}
                        onKeyPress={handleKeyPress}
                        id="numberId"
                        autoComplete="off"
                      />
                    </div>
                    <input
                      className="  h-[40px] ms-6 sm:ms-0  flex-auto bg-[#414142] text-[#fff] font-bold placeholder:font-normal placeholder:text-[#A1A1AA] rounded-[10px] px-3"
                      placeholder="Password"
                      type="text"
                      value={item?.lockerPassword}
                      disabled={currentSno - 2 >= lastUndefined}
                      onChange={(e) =>
                        handleLockerInsert(
                          e?.target?.value.trim(),
                          "lockerPassword",
                          currentSno - 1
                        )
                      }
                      onKeyPress={handleKeyPress}
                      autoComplete="off"
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className=" pt-8 pb-2">
            {page && page > 1 && (
              <div className=" border-b-2 border-[#444444] pt-8 pb-2">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  // forcePage={offset == 1 ? 0 : offset - 1}
                  pageCount={page}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-3 mb-4"}`}
          >
            <button
              onClick={() => {
                setaddLocker(false);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
            <button
              onClick={saveLockers}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.REGISTER}
            </button>
          </div>
        </div>
      </div>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
      {duplicateLocker && (
        <DuplicateLockers
          openModal={duplicateLocker}
          onClose={() => setDuplicateLocker(false)}
          textHead={
            errorHeading
              ? strings.DUPLICATELOCKERS
              : strings.ALREADY_LOCKER_REGISTER
          }
          text={
            errorHeading
              ? `Locker number ${lockerError?.map(
                  (item) => item
                )} have been entered as duplicates.`
              : `Locker number ${lockerError?.map(
                  (item) => item
                )} is already registered lockers`
          }
          subText={
            errorHeading
              ? "Please try again with correct input."
              : "Please  select another number."
          }
          classNameValue={classNameValue}
        />
      )}
    </section>
  );
}

export default AddLockersModal;
