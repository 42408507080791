import React, { useState } from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import DeleteConfirmation from "./DeleteConfirmation";
import DeleteSuccess from "./DeleteSuccess";
import MembersSublyApi from "../../../helper/MemberApi";
import { useSelector } from "react-redux";
import moment from "moment";

function MemoListModal({
  classNameValue,
  memoModal,
  setMemoModal,
  memoList,
  id,
  setIsUpdate,
  setIsLoader,
}) {
  const { token } = useSelector((state) => state.user);
  const [isEdit, setIsEdit] = useState("");
  const [memoId, setMemoId] = useState("");
  const [deleteMemo, setDeleteMemo] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [memoValue, setMemoValue] = useState([]);
  const [isUpdateButton, setIsUpdateButton] = useState(false);
  const [edited, setEdited] = useState(false);

  // ===========Memo delete API=============
  async function deleteMemoHandle() {
    setIsLoader(true);
    await MembersSublyApi.memoDelete(id, memoId, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setDeleteSuccess(true);
          setIsUpdate(true);
          setDeleteMemo(false);

          memoModal.modify
            ? setMemoModal({ status: true, edit: false })
            : setMemoModal({ status: false, edit: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ==============Edit memo API=============
  async function editMemoHandle() {
    setIsLoader(true);
    const request = { memos: memoValue };
    await MembersSublyApi.memoEdit(id, request, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          // setIsUpdate(true);

          memoModal.modify
            ? setMemoModal({ status: true, edit: false })
            : setMemoModal({ status: false, edit: false });

          // if (isUpdateButton) {

          // } else {
          //   setMemoModal({ status: true, edit: false });
          // }
          // setIsUpdateButton(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ===========function for add memo handling=========
  function handleMemo(id, text) {
    const memoArr = [...memoValue];
    if (id == isEdit) {
      const memoPresent = memoArr?.find((memo) => memo.id === id);
      if (memoPresent) {
        memoPresent.memo = text;
      } else {
        memoArr.push({ id: id, memo: text });
      }
      setMemoValue(memoArr);
    }
  }

  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass classForMargin"}`}
        show={memoModal.status}
        onHide={() => {
          setMemoModal({ status: false, edit: false });
          setIsEdit("");
          setMemoId("");
          setIsUpdate(false);
          setMemoValue([]);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8 w-[1200px]">
          <p className="border-b-2 border-[#444444] pb-3 mb-0 text-[40px] font-extrabold">
            {strings.MEMO}{" "}
          </p>
          <div className="w-[90%] max-h-[500px] pt-2 my-8 px-2  m-auto max-lg:w-full overflow-auto">
            <ol className="list-decimal">
              {memoList &&
                memoList.map(
                  (items, index) =>
                    items.isActive && (
                      <li className="text-[20px] font-bold my-4">
                        <div className="flex items-center justify-between gap-4">
                          {isEdit == items.id && memoModal.edit == true ? (
                            <input
                              type="text"
                              onChange={(e) => {
                                handleMemo(items.id, e.target.value);
                                memoList[index].memo = e.target.value;
                                setEdited(true);
                              }}
                              placeholder="Enter"
                              value={items.memo}
                              className="memoInputField"
                            />
                          ) : (
                            <>
                              <p className="mb-0 text-start w-[60%] ">{items.memo}</p>
                              {!memoModal.edit && (
                                <div className="flex  item-center gap-3">
                                  <p className="mb-0 text-start ">
                                    {moment(items?.modifyDate).format(
                                      "YY.MM.DD"
                                    )}
                                  </p>
                                  <p className="mb-0 text-start mt-0 ">
                                    {`(${items?.staffName})`}
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                          {memoModal.edit == true && (
                            <span className="flex items-center gap-2">
                              {isEdit !== items.id ? (
                                <Icon
                                  icon="bxs:edit"
                                  width="20"
                                  height="20"
                                  className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer "
                                  onClick={() => {
                                    setIsEdit(items.id);
                                  }}
                                />
                              ) : (
                                <Icon
                                  icon="bxs:edit"
                                  width="20"
                                  height="20"
                                  className="text-[#6B6B6B] hover:text-[#CCFF00] text-[#CCFF00] cursor-pointer"
                                  onClick={() => {
                                    setIsEdit(items.id);
                                  }}
                                />
                              )}
                              <Icon
                                icon="mdi:trash-can-outline"
                                width="22"
                                height="22"
                                className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                                onClick={() => {
                                  setDeleteMemo(true);
                                  setMemoId(items.id);
                                }}
                              />
                            </span>
                          )}
                        </div>
                      </li>
                    )
                )}
            </ol>
          </div>
          <div className="flex justify-center pt-10 gap-4">
            <button
              onClick={() => {
                setMemoModal({ status: false, edit: false });
                setIsEdit("");
                setMemoValue([]);
                setIsUpdate(false);
                setEdited(false);
              }}
              className="text-[24px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CLOSE}
            </button>
            {!memoModal.edit ? (
              <button
                onClick={() => {
                  setMemoModal({ status: true, edit: true, modify: true });
                  setIsUpdateButton(false);
                }}
                className="text-[24px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.MODIFY}
              </button>
            ) : (
              <button
                disabled={!edited ? true : false}
                onClick={() => {
                  // setMemoModal({ status: true, edit: true, modify: true });
                  // setIsUpdate(true);
                  editMemoHandle();
                  setIsEdit();
                }}
                className="text-[24px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px] disabled:bg-[#ccff0094]"
              >
                {strings.UPDATE}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <DeleteConfirmation
        classNameValue={classNameValue}
        textHead={strings.DELETE_MEMO}
        text={strings.DO_YOU_WANT_TO_DELETE_MEMO}
        deleteMemo={deleteMemo}
        setDeleteMemo={setDeleteMemo}
        clickBtn={deleteMemoHandle}
        btnText={strings.DELETE}
      />
      <DeleteSuccess
        classNameValue={classNameValue}
        text={strings.MEMO_DELETED_SUCCESSFULLY}
        deleteSuccess={deleteSuccess}
        setDeleteSuccess={setDeleteSuccess}
        setIsUpdate={setIsUpdate}
        isDelete={true}
      />
    </>
  );
}

export default MemoListModal;
