import React from "react";
import demoProfile from "../../assests/images/demoProfile.svg";
import protien from "../../assests/icons/protien.svg";
import carb from "../../assests/icons/carb icon.svg";
import fat from "../../assests/icons/fat icon.svg";
import fiber from "../../assests/icons/Fiber_icon.svg";
import { Icon } from "@iconify-icon/react";
import memberReview from "../../assests/icons/dietlog-detail-icons/member comment.svg";
import trainerReview from "../../assests/icons/dietlog-detail-icons/trainer comment.svg";
import imagePlaceholder from "../../assests/images/no-image.png";
import breakfast from "../../assests/icons/dietlog-detail-icons/breakfast.svg";
import dinner from "../../assests/icons/dietlog-detail-icons/dinner.svg";
import snack from "../../assests/icons/dietlog-detail-icons/snack.svg";

const ratings = [
  { nutrient: "Protien", rating: null, tainerRate: null },
  { nutrient: "Carb", rating: null, tainerRate: null },
  { nutrient: "Fat", rating: null, tainerRate: null },
  { nutrient: "Fiber", rating: null, tainerRate: null },
];

const iconValue = { Protien: protien, Carb: carb, Fat: fat, Fiber: fiber };
const iconsObj = {
  Lunch: dinner,
  Snacks: snack,
  Breakfast: breakfast,
  Dinner: dinner,
};

function DietDetailCard({ val }) {
  const newRating = ratings?.map((curr) => {
    const memberPresent = val.dietLogs?.memberLogs?.find(
      (data) => data.nutrient === curr?.nutrient
    );
    const trainerPresent = val.dietLogs?.trainerLogs?.find(
      (data) => data.nutrient === curr?.nutrient
    );
    return {
      nutrient: curr?.nutrient,
      memberRating: memberPresent?.rating || curr?.rating,
      tainerRating: trainerPresent?.rating || curr?.rating,
    };
  });

  return (
    <div className="bg-[#2C2C2E] p-2.5 rounded-[10px] w-[430px]">
      <div className="flex items-center gap-2">
        <img src={iconsObj[val.mealValues]} alt="icons" />
        <p className="mb-0 text-[24px] font-bold">{val.mealValues}</p>
      </div>
      <div className="w-full h-[275px] rounded-[10px] py-2.5">
        <img
          src={val?.images ? val?.images?.compressed : imagePlaceholder}
          alt="img"
          className="w-full h-full rounded-[10px] object-cover"
        />
      </div>
      <div className="flex items-center justify-between gap-2 pb-2.5 px-2.5 border-b border-[#505050]">
        <div className="flex items-center gap-2 ">
          <span className="rounded-full w-[37px] h-[37px]">
            <img
              src={
                val?.memberDetails?.mProfilePic?.prof_img1_min || demoProfile
              }
              alt="demo-profile"
              className="w-full h-full rounded-full"
            />
          </span>
          <p className="mb-0 text-[20px] font-bold">
            {val?.memberDetails?.name}
          </p>
        </div>
        <div className="flex items-center gap-2 ">
          <span className="rounded-full w-[37px] h-[37px]">
            <img
              src={val?.staffDetails?.mProfilePic?.prof_img1_min || demoProfile}
              alt="demo-profile"
              className="w-full h-full rounded-full"
            />
          </span>
          <p className="mb-0 text-[20px] font-bold">
            {val?.staffDetails?.name}
          </p>
        </div>
      </div>
      {newRating?.map((item, index) => (
        <div
          className="flex items-center justify-between px-2 py-1.5  gap-3"
          key={index}
        >
          <div className="w-[130px]">
            <p className="mb-0 text-[20px] font-extrabold flex items-center gap-1 ">
              {item.memberRating ? (
                Array.from({ length: item.memberRating }, (_, i) => i).map(
                  () => (
                    <Icon
                      icon="tabler:star-filled"
                      width="22"
                      height="22"
                      style={{ color: "#fff" }}
                    />
                  )
                )
              ) : (
                <Icon
                  icon="tabler:star-filled"
                  width="22"
                  height="22"
                  style={{ color: "#515151" }}
                />
              )}
              {/* {item.memberRating} */}
            </p>
          </div>
          <div className="flex items-center gap-2 justify-start w-[100px] ">
            <img
              src={iconValue[item.nutrient]}
              alt="icon"
              className="w-[25px] h-[25px]"
            />
            <p className="text-[20px] font-bold mb-0">{item?.nutrient}</p>
          </div>
          <div className="w-[130px]">
            <p className="mb-0 text-[20px] font-extrabold flex items-center justify-end gap-1 ">
              {!item.tainerRating ? (
                <Icon
                  icon="tabler:star-filled"
                  width="22"
                  height="22"
                  style={{ color: "#515151" }}
                />
              ) : (
                Array.from({ length: item.tainerRating }, (_, i) => i).map(
                  () => (
                    <Icon
                      icon="tabler:star-filled"
                      width="22"
                      height="22"
                      style={{ color: "#fff" }}
                    />
                  )
                )
              )}
              {/* {item.tainerRating || ""} */}
            </p>
          </div>
        </div>
      ))}
      {val?.dietLogs?.memberNotes && (
        <div className="flex items-center gap-2 py-2.5 px-2 border-b border-[#505050]">
          <img src={memberReview} alt="member-review" />
          <p className="mb-0 text-xl font-bold w-[95%]">
            {val?.dietLogs?.memberNotes}
          </p>
        </div>
      )}
      {val?.dietLogs?.trainerNotes && (
        <div className="flex items-center gap-2 py-2.5 px-2 ">
          <img src={trainerReview} alt="trainer-review" />
          <p className="mb-0 text-xl font-bold w-[95%] text-wrap">
            {val?.dietLogs?.trainerNotes}
          </p>
        </div>
      )}
    </div>
  );
}

export default DietDetailCard;
