import React from "react";
import calender from "../../../assests/icons/calenderWithTick.svg";
import calenderGreen from "../../../assests/icons/calenderWithTickGreen.svg";
import rightTick from "../../../assests/icons/rightTickBatch.svg";
import workoutLog from "../../../assests/icons/scheduleWorkoutLog.svg";
import reserve from "../../../assests/icons/reserveIcon.svg";
import moment from "moment";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { strings } from "../../../utils/String";
import profile from "../../../assests/images/demoProfile.svg";

function ScheduleStatistics({
  statisticsData,
  calenderFilter,
  trainerStateData,
  stateFilter,
}) {
  return (
    <>
      {calenderFilter == "all" ? (
        <div className="h-screen overflow-auto mb-3">
          {!statisticsData?.currentCount && !statisticsData?.previousCount && (
            <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
              {strings.NO_RECORD_FOUND}
            </p>
          )}
          {statisticsData?.currentCount && (
            <div className="rounded-[10px] bg-[#353638] mb-3">
              <div className="border-b border-[#444444]">
                <div className="flex items-center flex-wrap gap-1.5 px-4 py-3">
                  <p className="mb-0 text-xl font-bold">
                    {stateFilter === "week"
                      ? "This Week"
                      : stateFilter === "month"
                      ? "This Month"
                      : strings.TODAY}
                    :
                  </p>

                  <div className="flex items-center gap-1">
                    <Icon
                      icon="lets-icons:date-today"
                      width="25"
                      height="25"
                      style={{ color: "#CCFF00" }}
                    />
                    {stateFilter !== "day" ? (
                      <p className="mb-0 text-xl font-bold">
                        {moment.utc(statisticsData?.currentCount?.startDate).format(
                          "YYYY.MM.DD"
                        )}
                        {" - "}
                        {moment.utc(statisticsData?.currentCount?.endDate)
                          .subtract(1, "D")
                          .format("YYYY.MM.DD")}
                      </p>
                    ) : (
                      <p className="mb-0 text-xl font-bold">
                        {moment.utc(statisticsData?.currentCount?.startDate).format(
                          "YYYY.MM.DD"
                        )}{" "}
                        {`(${moment().format("ddd")})`}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-2.5 px-3 border-b border-[#444444]">
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#ABA9FF33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={calender} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-xl font-bold">{strings.BOOKED}:</p>
                    <p className="mb-0 text-xl font-bold text-[#B3B1FF] mt-0">
                      {statisticsData?.currentCount?.bookedCount} sessions
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5">
                    <p className="mb-0 text-xl font-bold">
                      {statisticsData?.currentCount?.bookedCountPercentage}%
                    </p>
                    {statisticsData?.currentCount?.bookedCountPercentage ==
                      0 && (
                      <Icon
                        icon="ion:reorder-two-sharp"
                        width="35"
                        height="35"
                        style={{ color: "#2AD100" }}
                      />
                    )}
                    {statisticsData?.currentCount?.bookedCountPercentage <
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        rotate={90}
                        style={{ color: "#0096FF" }}
                      />
                    )}
                    {statisticsData?.currentCount?.bookedCountPercentage >
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        style={{ color: "#FF0000" }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#FFE50033] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={reserve} className="w-[24px] h-[24px]" />
                    </div>{" "}
                    <p className="mb-0 text-xl font-bold">
                      {strings.RESERVATIONS}:
                    </p>
                    <p className="mb-0 text-xl font-bold mt-0">
                      {statisticsData?.currentCount?.requestCount} sessions
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5">
                    <p className="mb-0 text-xl font-bold">
                      {statisticsData?.currentCount?.requestCountPercentage}%
                    </p>
                    {statisticsData?.currentCount?.requestCountPercentage ==
                      0 && (
                      <Icon
                        icon="ion:reorder-two-sharp"
                        width="35"
                        height="35"
                        style={{ color: "#2AD100" }}
                      />
                    )}
                    {statisticsData?.currentCount?.requestCountPercentage <
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        rotate={90}
                        style={{ color: "#0096FF" }}
                      />
                    )}
                    {statisticsData?.currentCount?.requestCountPercentage >
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        style={{ color: "#FF0000" }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#CBFF8A33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={rightTick} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.COMPLETED}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#CBFF8A] mt-0">
                      {statisticsData?.currentCount?.completedCount} sessions
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5">
                    <p className="mb-0 text-xl font-bold">
                      {statisticsData?.currentCount?.completedCountPercentage}%
                    </p>
                    {statisticsData?.currentCount?.completedCountPercentage ==
                      0 && (
                      <Icon
                        icon="ion:reorder-two-sharp"
                        width="35"
                        height="35"
                        style={{ color: "#2AD100" }}
                      />
                    )}
                    {statisticsData?.currentCount?.completedCountPercentage <
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        rotate={90}
                        style={{ color: "#0096FF" }}
                      />
                    )}
                    {statisticsData?.currentCount?.completedCountPercentage >
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        style={{ color: "#FF0000" }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#FF919133] w-[35px] h-[35px] flex items-center justify-center">
                      <Icon
                        icon="fontisto:close"
                        width="23"
                        height="23"
                        style={{ color: "#FF9191" }}
                      />{" "}
                    </div>
                    <p className="mb-0 text-xl font-bold">{strings.CANCEL}:</p>
                    <p className="mb-0 text-xl font-bold text-[#FF9191] mt-0">
                      {statisticsData?.currentCount?.rejectedCount} sessions
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5">
                    <p className="mb-0 text-xl font-bold">
                      {statisticsData?.currentCount?.rejectedCountPercentage}%
                    </p>
                    {statisticsData?.currentCount?.rejectedCountPercentage ==
                      0 && (
                      <Icon
                        icon="ion:reorder-two-sharp"
                        width="35"
                        height="35"
                        style={{ color: "#2AD100" }}
                      />
                    )}
                    {statisticsData?.currentCount?.rejectedCountPercentage <
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        rotate={90}
                        style={{ color: "#0096FF" }}
                      />
                    )}
                    {statisticsData?.currentCount?.rejectedCountPercentage >
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        style={{ color: "#FF0000" }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#ABA9FF33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={calender} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.NO_SHOW.replace(" ", "")}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#B3B1FF] mt-0">
                      {statisticsData?.currentCount?.noShowCount} sessions
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5">
                    <p className="mb-0 text-xl font-bold">
                      {statisticsData?.currentCount?.noShowCountPercentage}%
                    </p>
                    {statisticsData?.currentCount?.noShowCountPercentage ==
                      0 && (
                      <Icon
                        icon="ion:reorder-two-sharp"
                        width="35"
                        height="35"
                        style={{ color: "#2AD100" }}
                      />
                    )}
                    {statisticsData?.currentCount?.noShowCountPercentage <
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        rotate={90}
                        style={{ color: "#0096FF" }}
                      />
                    )}
                    {statisticsData?.currentCount?.noShowCountPercentage >
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        style={{ color: "#FF0000" }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#CBFF8A33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={calenderGreen} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.TOTAL_COMPLETED}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#CCFF00] mt-0">
                      {statisticsData?.currentCount?.totalCount} sessions
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5"></div>
                </div>
              </div>
              <div className="px-3 py-3">
                <div className="flex items-center justify-between gap-3 py-2.5">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#FFFFFF33] w-[35px] h-[35px] flex items-center justify-center">
                      <Icon
                        icon="lucide:user-round"
                        width="24"
                        height="24"
                        style={{ color: "#CCFF00" }}
                      />{" "}
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.TOTAL_MEMBERS}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#fff] mt-0">
                      {statisticsData?.currentCount?.totalMembers}
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5">
                    <p className="mb-0 text-xl font-bold">
                      {statisticsData?.currentCount?.totalMemberPercentage}%
                    </p>
                    {statisticsData?.currentCount?.totalMemberPercentage ==
                      0 && (
                      <Icon
                        icon="ion:reorder-two-sharp"
                        width="35"
                        height="35"
                        style={{ color: "#2AD100" }}
                      />
                    )}
                    {statisticsData?.currentCount?.totalMemberPercentage <
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        rotate={90}
                        style={{ color: "#0096FF" }}
                      />
                    )}
                    {statisticsData?.currentCount?.totalMemberPercentage >
                      0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        style={{ color: "#FF0000" }}
                      />
                    )}
                  </div>{" "}
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#FFFFFF33] w-[35px] h-[35px] flex items-center justify-center">
                      <Icon
                        icon="octicon:person-add-16"
                        width="24"
                        height="24"
                        style={{ color: "#CCFF00" }}
                      />
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.NEW_MEMBERS}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#fff] mt-0">
                      {statisticsData?.currentCount?.newMembers}
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5">
                    <p className="mb-0 text-xl font-bold">
                      {statisticsData?.currentCount?.newMembersPercentage}%
                    </p>
                    {statisticsData?.currentCount?.newMembersPercentage ==
                      0 && (
                      <Icon
                        icon="ion:reorder-two-sharp"
                        width="35"
                        height="35"
                        style={{ color: "#2AD100" }}
                      />
                    )}
                    {statisticsData?.currentCount?.newMembersPercentage < 0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        rotate={90}
                        style={{ color: "#0096FF" }}
                      />
                    )}
                    {statisticsData?.currentCount?.newMembersPercentage > 0 && (
                      <Icon
                        icon="mdi:arrow-up-bold"
                        width="35"
                        height="35"
                        style={{ color: "#FF0000" }}
                      />
                    )}
                  </div>{" "}
                </div>
              </div>
            </div>
          )}
          {statisticsData?.previousCount && (
            <div className="rounded-[10px] bg-[#353638]">
              <div className="border-b border-[#444444]">
                <div className="flex items-center flex-wrap gap-1.5 px-4 py-3">
                  <p className="mb-0 text-xl font-bold">
                    {stateFilter == "week"
                      ? strings.PREVIOUS_WEEK
                      : stateFilter == "day"
                      ? strings.PREVIOUS_DAY
                      : stateFilter == "month"
                      ? strings.PREVIOUS_MONTH
                      : ""}
                  </p>
                  <div className="flex items-center gap-1">
                    <Icon
                      icon="lets-icons:date-today"
                      width="25"
                      height="25"
                      style={{ color: "#CCFF00" }}
                    />
                    {stateFilter !== "day" ? (
                      <p className="mb-0 text-xl font-bold">
                        {moment.utc(
                          statisticsData?.previousCount?.startDate
                        ).format("YYYY.MM.DD")}
                        {" - "}
                        {moment.utc(statisticsData?.previousCount?.endDate).format(
                          "YYYY.MM.DD"
                        )}
                      </p>
                    ) : (
                      <p className="mb-0 text-xl font-bold">
                        {moment.utc(
                          statisticsData?.previousCount?.startDate
                        ).format("YYYY.MM.DD")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-2.5 px-3 border-b border-[#444444]">
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#ABA9FF33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={calender} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-xl font-bold">{strings.BOOKED}:</p>
                    <p className="mb-0 text-xl font-bold text-[#B3B1FF] mt-0">
                      {statisticsData?.previousCount?.bookedCount} sessions
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#FFE50033] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={reserve} className="w-[24px] h-[24px]" />
                    </div>{" "}
                    <p className="mb-0 text-xl font-bold">
                      {strings.RESERVATIONS}:
                    </p>
                    <p className="mb-0 text-xl font-bold mt-0">
                      {statisticsData?.previousCount?.requestCount} sessions
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#CBFF8A33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={rightTick} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.COMPLETED}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#CBFF8A] mt-0">
                      {statisticsData?.previousCount?.completedCount} sessions
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#FF919133] w-[35px] h-[35px] flex items-center justify-center">
                      <Icon
                        icon="fontisto:close"
                        width="23"
                        height="23"
                        style={{ color: "#FF9191" }}
                      />{" "}
                    </div>
                    <p className="mb-0 text-xl font-bold">{strings.CANCEL}:</p>
                    <p className="mb-0 text-xl font-bold text-[#FF9191] mt-0">
                      {statisticsData?.previousCount?.rejectedCount} sessions
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#ABA9FF33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={calender} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.NO_SHOW.replace(" ", "")}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#B3B1FF] mt-0">
                      {statisticsData?.previousCount?.noShowCount} sessions
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#CBFF8A33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={calenderGreen} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.TOTAL_COMPLETED}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#CCFF00] mt-0">
                      {statisticsData?.previousCount?.totalCount} sessions
                    </p>
                  </div>
                  <div className="flex items-center gap-0.5"></div>
                </div>
              </div>
              <div className="px-3 py-3">
                <div className="flex items-center justify-between gap-3 py-2.5">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#FFFFFF33] w-[35px] h-[35px] flex items-center justify-center">
                      <Icon
                        icon="lucide:user-round"
                        width="24"
                        height="24"
                        style={{ color: "#CCFF00" }}
                      />{" "}
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.TOTAL_MEMBERS}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#fff] mt-0">
                      {statisticsData?.previousCount?.totalMembers}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2.5">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#FFFFFF33] w-[35px] h-[35px] flex items-center justify-center">
                      <Icon
                        icon="octicon:person-add-16"
                        width="24"
                        height="24"
                        style={{ color: "#CCFF00" }}
                      />
                    </div>
                    <p className="mb-0 text-xl font-bold">
                      {strings.NEW_MEMBERS}:
                    </p>
                    <p className="mb-0 text-xl font-bold text-[#fff] mt-0">
                      {statisticsData?.previousCount?.newMembers}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="h-screen overflow-auto mb-3">
          {trainerStateData?.currentData?.length == 0 &&
            trainerStateData?.previousData?.length == 0 && (
              <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                {strings.NO_RECORD_FOUND}
              </p>
            )}
          {trainerStateData?.currentData?.length > 0 && (
            <div className="rounded-[10px] bg-[#353638] mb-3">
              <div className="border-b border-[#444444]">
                <div className="flex flex-wrap items-center gap-1.5 px-4 py-3">
                  <p className="mb-0 text-xl font-bold">
                    {stateFilter === "week"
                      ? "This Week"
                      : stateFilter === "month"
                      ? "This Month"
                      : strings.TODAY}
                    :
                  </p>
                  <div className="flex items-center gap-1">
                    <Icon
                      icon="lets-icons:date-today"
                      width="25"
                      height="25"
                      style={{ color: "#CCFF00" }}
                    />
                    {stateFilter !== "day" ? (
                      <p className="mb-0 text-xl font-bold mt-0">
                        {`(${moment.utc(trainerStateData?.currentStartDate).format(
                          "YYYY.MM.DD"
                        )})`}
                        {" - "}
                        {`(${moment.utc(trainerStateData?.currentEndDate).format(
                          "YYYY.MM.DD"
                        )})`}
                      </p>
                    ) : (
                      <p className="mb-0 text-xl font-bold">
                        {`${moment.utc(trainerStateData?.currentStartDate).format(
                          "YYYY.MM.DD"
                        )}`}{" "}
                        {`(${moment().format("ddd")})`}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="py-2.5 px-3 border-b border-[#535353]">
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#CBFF8A33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={rightTick} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-2xl font-bold">
                      {strings.COMPLETED_SESSIONS}
                    </p>
                  </div>
                </div>
                {trainerStateData?.currentData?.length > 0 &&
                  trainerStateData?.currentData?.map((item, index) => (
                    <div className="flex items-center justify-between gap-2 py-2.5">
                      <div className="flex items-center gap-2">
                        <div className="rounded-full w-[35px] h-[35px] flex items-center justify-center">
                          <img
                            src={profile}
                            className="object-cover w-full h-full rounded-full"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          trainerStateData?.currentData?.length == index + 1
                            ? ""
                            : "border-b border-[#444444] pb-1"
                        } ${"flex items-center gap-0.5 flex-wrap justify-between w-[98%]"}`}
                      >
                        <div className="flex items-center gap-2">
                          <p className="mb-0 text-xl font-bold">
                            {item?.gymStaffInfo?.gymStaffName}:
                          </p>
                          <p className="mb-0 text-xl font-bold mt-0">
                            {item?.sessionCount} {strings.SESSION}
                          </p>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <p className="mb-0 text-xl font-bold">
                            {item?.sessionPercentage}%
                          </p>
                          {item?.sessionPercentage == 0 && (
                            <Icon
                              icon="ion:reorder-two-sharp"
                              width="35"
                              height="35"
                              style={{ color: "#2AD100" }}
                            />
                          )}
                          {item?.sessionPercentage < 0 && (
                            <Icon
                              icon="mdi:arrow-up-bold"
                              width="35"
                              height="35"
                              rotate={90}
                              style={{ color: "#0096FF" }}
                            />
                          )}
                          {item?.sessionPercentage > 0 && (
                            <Icon
                              icon="mdi:arrow-up-bold"
                              width="35"
                              height="35"
                              style={{ color: "#FF0000" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="py-2.5 px-3">
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#CBFF8A33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={workoutLog} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-2xl font-bold">
                      {strings.WORKOUT_LOG}
                    </p>
                  </div>
                </div>
                {trainerStateData?.currentData?.length > 0 &&
                  trainerStateData?.currentData?.map((item, index) => (
                    <div className="flex items-center justify-between gap-2 py-2.5">
                      <div className="flex items-center gap-2">
                        <div className="rounded-full w-[35px] h-[35px] flex items-center justify-center">
                          <img
                            src={profile}
                            className="object-cover w-full h-full rounded-full"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          trainerStateData?.currentData?.length == index + 1
                            ? ""
                            : "border-b border-[#444444] pb-1"
                        } ${"flex items-center gap-0.5 flex-wrap justify-between w-[98%]"}`}
                      >
                        <div className="flex items-center gap-2">
                          <p className="mb-0 text-xl font-bold">
                            {item?.gymStaffInfo?.gymStaffName}:
                          </p>
                          <p className="mb-0 text-xl font-bold mt-0">
                            {item?.workoutLogCount}/{item?.sessionCount}{" "}
                            {`(${item?.sessionWorkoutPercentage}%)`}
                          </p>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <p className="mb-0 text-xl font-bold">
                            {item?.workoutPercentage}%
                          </p>
                          {item?.workoutPercentage == 0 && (
                            <Icon
                              icon="ion:reorder-two-sharp"
                              width="35"
                              height="35"
                              style={{ color: "#2AD100" }}
                            />
                          )}
                          {item?.workoutPercentage < 0 && (
                            <Icon
                              icon="mdi:arrow-up-bold"
                              width="35"
                              height="35"
                              rotate={90}
                              style={{ color: "#0096FF" }}
                            />
                          )}
                          {item?.workoutPercentage > 0 && (
                            <Icon
                              icon="mdi:arrow-up-bold"
                              width="35"
                              height="35"
                              style={{ color: "#FF0000" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {trainerStateData?.previousData?.length > 0 && (
            <div className="rounded-[10px] bg-[#353638] mb-3">
              <div className="border-b border-[#444444]">
                <div className="flex items-center flex-wrap gap-1.5 px-4 py-3">
                  <p className="mb-0 text-xl font-bold">
                    {stateFilter == "week"
                      ? strings.PREVIOUS_WEEK
                      : stateFilter == "day"
                      ? strings.PREVIOUS_DAY
                      : stateFilter == "month"
                      ? strings.PREVIOUS_MONTH
                      : ""}
                  </p>
                  <div className="flex items-center gap-1">
                    <Icon
                      icon="lets-icons:date-today"
                      width="25"
                      height="25"
                      style={{ color: "#CCFF00" }}
                    />
                    {stateFilter !== "day" ? (
                      <p className="mb-0 text-xl font-bold">
                        {`(${moment.utc(trainerStateData?.previousStartDate).format(
                          "YYYY.MM.DD"
                        )})`}
                        {" - "}
                        {`(${moment.utc(trainerStateData?.previousEndDate).format(
                          "YYYY.MM.DD"
                        )})`}
                      </p>
                    ) : (
                      <p className="mb-0 text-xl font-bold">
                        {`(${moment.utc(trainerStateData?.previousStartDate).format(
                          "YYYY.MM.DD"
                        )})`}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="py-2.5 px-3 border-b border-[#535353]">
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#CBFF8A33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={rightTick} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-2xl font-bold">
                      {strings.COMPLETED_SESSIONS}
                    </p>
                  </div>
                </div>
                {trainerStateData?.previousData?.length > 0 &&
                  trainerStateData?.previousData?.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between gap-2 py-2.5"
                    >
                      <div className="flex items-center gap-2">
                        <div className="rounded-full w-[35px] h-[35px] flex items-center justify-center">
                          <img
                            src={profile}
                            className="object-cover w-full h-full rounded-full"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          trainerStateData?.previousData?.length == index + 1
                            ? ""
                            : "border-b border-[#444444] pb-1"
                        } ${"flex items-center gap-0.5 flex-wrap justify-between w-[98%]"}`}
                      >
                        <div className="flex items-center gap-2">
                          <p className="mb-0 text-xl font-bold">
                            {item?.gymStaffInfo?.gymStaffName}:
                          </p>
                          <p className="mb-0 text-xl font-bold mt-0">
                            {item?.sessionCount} {strings.SESSION}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="py-2.5 px-3">
                <div className="flex items-center justify-between gap-3 py-2.5 border-b border-[#444444]">
                  <div className="flex items-center gap-2 ">
                    <div className="rounded-[6px] bg-[#CBFF8A33] w-[35px] h-[35px] flex items-center justify-center">
                      <img src={workoutLog} className="w-[24px] h-[24px]" />
                    </div>
                    <p className="mb-0 text-2xl font-bold">
                      {strings.WORKOUT_LOG}
                    </p>
                  </div>
                </div>
                {trainerStateData?.previousData?.length > 0 &&
                  trainerStateData?.previousData?.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between gap-2 py-2.5"
                    >
                      <div className="flex items-center gap-2">
                        <div className="rounded-full w-[35px] h-[35px] flex items-center justify-center">
                          <img
                            src={profile}
                            className="object-cover w-full h-full rounded-full"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          trainerStateData?.previousData?.length == index + 1
                            ? ""
                            : "border-b border-[#444444] pb-1"
                        } ${"flex items-center gap-0.5 flex-wrap justify-between w-[98%]"}`}
                      >
                        <div className="flex items-center gap-2">
                          <p className="mb-0 text-xl font-bold">
                            {item?.gymStaffInfo?.gymStaffName}:
                          </p>
                          <p className="mb-0 text-xl font-bold mt-0">
                            {item?.workoutLogCount}/{item?.sessionCount}{" "}
                            {`(${item?.sessionWorkoutPercentage}%)`}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ScheduleStatistics;
