import React, { useEffect, useState } from "react";
import { strings } from "../../../../../utils/String";
import Header from "../../../../commonComponents/Header";
import SideBar from "../../../../commonComponents/SideBar";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import ReactPaginate from "react-paginate";
import AddLockers from "./AddLockersModal";
import AddLockersModal from "./AddLockersModal";
import EditLockerModal from "./EditLockerModal";
import DeleteConfirmation from "../../../../commonComponents/modalScreens/DeleteConfirmation";
import DeleteSuccess from "../../../../commonComponents/modalScreens/DeleteSuccess";
import GymLockerInfoModal from "./GymLockerInfoModal";
import LockerHistory from "./LockerHistory";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../../../utils/Loader";
import { MembershipDropDown } from "../gymInformationScreens/GymInfoScreen";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";
import DuplicateLockers from "./DuplicateLockers";
import $ from "jquery";

function GymLockerSetting({ showLock }) {
  const [activeTab, setActiveTab] = useState();

  const [addLocker, setaddLocker] = useState(false);
  const [editLocker, seteditLocker] = useState(false);
  const [lockerInformation, setlockerInformation] = useState(false);
  const [duplicateLocker, setduplicateLocker] = useState(false);
  const [alreadyResgisteredLocker, setalreadyResgisteredLocker] =
    useState(false);
  const [deleteLocker, setdeleteLocker] = useState(false);
  const [deleteSuccess, setdeleteSuccess] = useState(false);
  const [lockerHistory, setlockerHistory] = useState(false);
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [lockerList, setLockerList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [search, setSearch] = useState("");
  const [gymText, setGymText] = useState({ str: "Gym locker setting", val: 0 });
  const [showDropDown, setshowDropDown] = useState(false);
  const [lockerData, setLockerData] = useState();
  const [lockerId, setLockerId] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [lockerType, setLockerType] = useState({ text: "All", key: "all" });
  const [orderValue, setOrderValue] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [infoData, setInfoData] = useState();
  // console.log("------INFODATA",infoData)

  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  const statusButtonsStyle = {
    "in-use": {
      style: { border: "1px solid #CBFF8A" },
      className:
        "w-[150px] h-[28px] rounded-full bg-[#CBFF8A] bg-opacity-20 text-[#CBFF8A] text-lg font-bold",
      text: strings.In_Use,
    },
    expired: {
      style: { border: "1px solid #FFB9B9" },
      className:
        "w-[150px] h-[28px] rounded-full bg-[#FFB9B9] bg-opacity-20  text-[#FFB9B9] text-lg font-bold",
      text: strings.EXPIRED,
    },
    empty: {
      style: { border: "1px solid #C5C5C5" },
      className:
        "w-[150px] h-[28px] rounded-full bg-[#C5C5C5] bg-opacity-40  text-lg font-bold text-[#C5C5C5]",
      text: strings.EMPTY,
    },
    fixed: {
      style: { border: "1px solid #00C2FF" },
      className:
        "w-[150px] h-[28px] rounded-full bg-[#00C2FF] bg-opacity-20  text-[#00C2FF] text-lg font-bold",
      text: strings.FIXED,
    },
  };

  function GetStatusButton(status) {
    const statusButton = statusButtonsStyle[status] || statusButtonsStyle.fixed;
    return (
      <button style={statusButton?.style} className={statusButton?.className}>
        {statusButton?.text}
      </button>
    );
  }
  useEffect(() => {
    // handleDelete();

    (!addLocker || !deleteSuccess || !editLocker) && lockerListHandler();
  }, [offset, addLocker, deleteSuccess, editLocker]);

  useEffect(() => {
    lockerListHandler("", "", offset);
  }, [lockerType]);

  //   (!addLocker || !editLocker || !deleteSuccess) && lockerListHandler();
  // }, [offset, addLocker, editLocker, deleteSuccess]);
  async function lockerListHandler(order, key, page) {
    setIsLoader(true);
    await GymSettingSublyApi.fetchLockerList(
      page ? page : offset,
      token,
      search,
      order,
      key,
      lockerType.key
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setLockerList(response.data.lockers);
          setPage(response.data.count / 13);
          page && setOffset(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePageClick = async (data) => {
    setOffset(data.selected + 1);
  };

  // ==========Search Locker handler==========
  async function searchHandle() {
    setOffset(1);
    setIsLoader(true);
    await GymSettingSublyApi.fetchLockerList(
      offset,
      token,
      search,
      "",
      "",
      lockerType.key
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setLockerList(response.data.lockers);
          setPage(response.data.count / 13);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ==========Delete Locker handler==========
  async function handleDelete() {
    await GymSettingSublyApi.deleteLocker(token, lockerId).then((response) => {
      if (response.statusCode == 200) {
        setdeleteSuccess(true);
        setIsUpdate(true);
        setdeleteLocker(false);
        setlockerInformation(false);
      } else {
        setOpenModal(true);
        seterrorMessage(response.data.message);
      }
    });
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      seteditLocker(false);
      setaddLocker(false);
      setlockerInformation(false);
      setlockerHistory(false);
      setLockerId("");
      setshowDropDown(false);
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`p-0 relative ${
              addLocker ||
              editLocker ||
              lockerInformation ||
              lockerInformation ||
              lockerHistory
                ? "overflow-hidden"
                : "overflow-auto"
            }`}
          >
            <Header text={strings.GYM_SETTING_GYM} />
            <div className="mx-6  ">
              {" "}
              <div className="flex items-center justify-between gap-3 py-3 flex-wrap">
                <div className="flex items-center gap-3 flex-wrap max-sm:w-full">
                  <label
                    onClick={() => {
                      setshowDropDown(!showDropDown);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                      {lockerType.text}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={showDropDown ? 2 : 4}
                    />
                    {showDropDown && (
                      <LockerDropDown setLockerType={setLockerType} />
                    )}
                  </label>
                </div>
                <div className="flex items-center flex-wrap gap-3 max-sm:w-full">
                  <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Keywords"
                      className="bg-transparent w-full text-white text-lg font-bold"
                    />
                  </label>
                  <button
                    onClick={() => {
                      searchHandle();
                    }}
                    className="hover:text-black font-[archivo] max-sm:w-full hover:bg-[#CCFF00] text-lg font-bold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="my-4 text-white font-extrabold text-2xl">
                  {strings.GYM_LOCKER_SETTING}
                </h1>
                <div>
                  <button
                    onClick={() => setaddLocker(true)}
                    className={`${"text-base font-bold h-[50px] w-[120px]  rounded-[28px] hover:text-black hover:bg-[#CCFF00] text-white kakaoClass"}`}
                  >
                    {strings.ADD_LOCKER}
                  </button>
                  {/* <button
                    className={`${"text-base font-bold h-[50px] w-[120px]  rounded-[28px] hover:text-black hover:bg-[#CCFF00] text-white kakaoClass ms-2.5"}`}
                  >
                    {strings.UPDATE}
                  </button> */}
                </div>
              </div>
              {lockerList.length > 0 ? (
                <div className="overflow-auto 2xl:max-h-[calc(100vh_-_210px)] ">
                  <div className="max-2xl:w-[1600px] staffListClass">
                    <Table className="w-full !bg-transparent">
                      <thead className="bg-[#353638] sticky top-[-1px] z-1">
                        <th
                          className="!text-center text-lg font-semibold text-white w-[22%] "
                          onClick={() => {
                            setActiveTab(activeTab == 1 ? null : 1);
                            let sort = activeTab == 1 ? "DESC" : "ASC";
                            lockerListHandler(sort, "lockerNumber");
                            setSortKey(sort);
                            setOrderValue("lockerNumber");
                          }}
                        >
                          <div
                            className={`${
                              activeTab == 1
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.LOCKER_NUMBER1}
                            <Icon
                              icon={`${
                                activeTab == 1
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 1 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th
                          className="!text-start text-lg  font-semibold text-white  w-[18%]"
                          onClick={() => {
                            setActiveTab(activeTab == 2 ? null : 2);
                            let sort = activeTab == 2 ? "DESC" : "ASC";
                            lockerListHandler(sort, "lockerPassword");
                            setSortKey(sort);
                            setOrderValue("lockerPassword");
                          }}
                        >
                          <div
                            className={`${
                              activeTab == 2
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.PASSWORD}
                            <Icon
                              icon={`${
                                activeTab == 2
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 2 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th
                          className="!text-center text-lg font-semibold text-white  w-[15%]"
                          onClick={() => {
                            setActiveTab(activeTab == 3 ? null : 3);
                            let sort = activeTab == 3 ? "DESC" : "ASC";
                            lockerListHandler(sort, "current");
                            setSortKey(sort);
                            setOrderValue("current");
                          }}
                        >
                          <div
                            className={`${
                              activeTab == 3
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.CURRENT}
                            <Icon
                              icon={`${
                                activeTab == 3
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 3 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th
                          className="!text-center text-lg font-semibold text-white  w-[15%]"
                          onClick={() => {
                            setActiveTab(activeTab == 4 ? null : 4);
                            let sort = activeTab == 4 ? "DESC" : "ASC";
                            lockerListHandler(sort, "endDate");
                            setSortKey(sort);
                            setOrderValue("endDate");
                          }}
                        >
                          <div
                            className={`${
                              activeTab == 4
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.TILL}
                            <Icon
                              icon={`${
                                activeTab == 4
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 4 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th
                          className="!text-center text-lg font-semibold text-white  w-[20%] "
                          onClick={() => {
                            setActiveTab(activeTab == 5 ? null : 5);
                            let sort = activeTab == 5 ? "DESC" : "ASC";
                            lockerListHandler(sort, "status");
                            setSortKey(sort);
                            setOrderValue("status");
                          }}
                        >
                          <div
                            className={`${
                              activeTab == 5
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.STATUS}
                            <Icon
                              icon={`${
                                activeTab == 5
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 5 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th className="w-[10%]"> </th>
                      </thead>
                      <tbody>
                        {lockerList?.length &&
                          lockerList?.map((lockerData) => {
                            // console.log("-------lockerData", lockerData);
                            return (
                              <tr
                                className={`${"cursor-pointer"} ${
                                  lockerId == lockerData.id
                                    ? "border-[1px] border-[#CCFF00]"
                                    : ""
                                }`}
                                onClick={() => {
                                  setlockerInformation(true);
                                  setLockerData(lockerData);
                                  setLockerId(lockerData.id);
                                }}
                              >
                                <td
                                  className={`${"text-white font-bold text-lg relative p-0 text-center"} ${
                                    lockerId == lockerData.id
                                      ? "border-[0px]"
                                      : ""
                                  }`}
                                >
                                  {lockerData?.lockerNumber || "--"}
                                </td>
                                <td className="!text-center text-[18px] p-0 font-bold text-white ">
                                  {lockerData?.lockerPassword || "--"}
                                </td>
                                <td className="!text-center text-[18px] font-bold text-white ">
                                  {lockerData?.name ? lockerData?.name : "--"}
                                </td>
                                <td className="!text-center text-[18px] font-bold text-white pl-3">
                                  {lockerData?.endDate
                                    ? "~" +
                                      moment(lockerData?.endDate).format(
                                        "YYYY.MM.DD"
                                      )
                                    : "--"}
                                </td>
                                <td className="!text-center text-[18px] font-bold text-white ">
                                  {GetStatusButton(lockerData?.status)}
                                </td>{" "}
                                <td
                                  className={
                                    lockerId == lockerData.id
                                      ? "border-e-[1px] border-[#CCFF00]"
                                      : ""
                                  }
                                >
                                  <div className="flex items-center justify-center">
                                    {showLock ? (
                                      <Icon
                                        icon="bxs:edit"
                                        width="23"
                                        height="23"
                                        className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                                        // style={{
                                        //   color: "#CCFF00",
                                        //   cursor: "pointer",
                                        // }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          seteditLocker(true);
                                          setLockerData(lockerData);
                                          setLockerId(lockerData.id);
                                        }}
                                      />
                                    ) : (
                                      <Icon
                                        icon="bxs:edit"
                                        width="23"
                                        height="23"
                                        className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          seteditLocker(true);
                                          setLockerData(lockerData);
                                          setLockerId(lockerData.id);
                                        }}
                                      />
                                    )}
                                    <Icon
                                      className="ms-2 text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                                      icon="mdi:trash-can-outline"
                                      width="24"
                                      height="24"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setLockerId(lockerData.id);
                                        setdeleteLocker(true);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              {page && page > 1 && (
                <div className=" border-b-2 border-[#444444] pt-[10px] pb-0">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    forcePage={offset == 1 ? 0 : offset - 1}
                    pageCount={page}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              )}
              {addLocker && (
                <div>
                  {" "}
                  <AddLockersModal
                    setaddLocker={setaddLocker}
                    setIsLoader={setIsLoader}
                    classNameValue={`${
                      addLocker ? "block_notify" : "hideNotify"
                    }`}
                  />
                </div>
              )}
              {editLocker && (
                <div>
                  {" "}
                  <EditLockerModal
                    seteditLocker={seteditLocker}
                    lockerData={lockerData}
                    setLockerId={setLockerId}
                    classNameValue={`${
                      editLocker ? "block_notify" : "hideNotify"
                    }`}
                    setIsLoading={setIsLoader}
                  />
                </div>
              )}
              {lockerInformation && (
                <div>
                  <GymLockerInfoModal
                    onClose={setlockerInformation}
                    lockerData={lockerData}
                    openHistory={setlockerHistory}
                    seteditLocker={seteditLocker}
                    setLockerId={setLockerId}
                    setdeleteLocker={setdeleteLocker}
                    classNameValue={`${
                      lockerInformation ? "block_notify" : "hideNotify"
                    }`}
                  />
                </div>
              )}
              {lockerHistory && (
                <LockerHistory
                  onClose={setlockerHistory}
                  lockerId={lockerId}
                  setIsLoader={setIsLoader}
                  setLockerId={setLockerId}
                  classNameValue={`${
                    lockerHistory ? "block_notify" : "hideNotify"
                  }`}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {deleteLocker && (
        <DeleteConfirmation
          textHead="Delete Locker"
          deleteMemo={deleteLocker}
          setDeleteMemo={setdeleteLocker}
          btnText="Delete"
          text={`Are you sure you want to permanently delete this Locker?`}
          clickBtn={() => handleDelete()}
          setLockerId={setLockerId}
          classNameValue={`${
            editLocker ||
            addLocker ||
            lockerInformation ||
            lockerHistory ||
            deleteLocker
              ? "block_notify"
              : "hideNotify"
          }`}
        />
      )}
      <DeleteSuccess
        text={"Locker deleted successfully"}
        deleteSuccess={deleteSuccess}
        setDeleteSuccess={setdeleteSuccess}
        setIsUpdate={setIsUpdate}
        isDelete
        buttonKey={true}
        classNameValue={`${
          editLocker || addLocker || lockerInformation || lockerHistory
            ? "block_notify"
            : "hideNotify"
        }`}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={`${
          editLocker || addLocker || lockerInformation || lockerHistory
            ? "block_notify"
            : "hideNotify"
        }`}
      />
    </section>
  );
}

export default GymLockerSetting;

function LockerDropDown({ setLockerType }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[270px] right-4 top-14 max-h-[250px] overflow-auto z-20 px-3">
      <div
        onClick={() => {
          setLockerType({ text: strings.ALL, key: "all" });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      <div
        onClick={() => {
          setLockerType({ text: strings.EMPLTY_LOCKERS, key: "empty" });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.EMPLTY_LOCKERS}</span>
      </div>
      <div
        onClick={() => {
          setLockerType({ text: strings.IN_USE_LOCKER, key: "in-use" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.IN_USE_LOCKER}</span>
      </div>
      <div
        onClick={() => {
          setLockerType({ text: strings.EXPIRED_LOCKERS, key: "expired" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">
          {strings.EXPIRED_LOCKERS}
        </span>
      </div>
    </div>
  );
}
