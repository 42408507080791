import React from "react";
import { Modal } from "react-bootstrap";
import logo from "../assests/attendanceIcons/logo-icon.svg";
import { strings } from "../utils/String";

function ConfirmationModal({
  headText,
  subText,
  openModal,
  setOpenModal,
  forgotPasswordHandle,
}) {
  return (
    <>
      <Modal
        className={`${"modalBoxClass attendnaceModalClass"}`}
        show={openModal}
        onHide={() => {
          setOpenModal(false);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-14 px-8">
          <div className="w-[80%] max-lg:w-[90%] m-auto">
            <img src={logo} alt="logo" className="mx-auto" />
            <p className="mt-4 mb-3 text-[30px] font-extrabold">{headText}</p>
            <p className="text-[24px] font-bold pb-2 text-center">{subText}</p>
            <div className="flex justify-center items-center pt-3 gap-4">
              <button
                onClick={() => {
                  setOpenModal(false);
                }}
                className="text-[20px] h-[55px] font-extrabold flex justify-center items-center gap-1.5 kakaoClass text-white w-[155px] rounded-[28px]"
              >
                {strings.CLOSE}
              </button>
              <button
                onClick={() => {
                  forgotPasswordHandle();
                }}
                className="text-[20px] h-[55px] font-extrabold flex justify-center items-center gap-1.5 bg-[#CCFF00] text-black w-[155px] rounded-[28px]"
              >
                {strings.CONFIRM}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
