import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class ManagementSublyApi {
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${BASE_URL}${endpoint}`;
    const headers = { ...header };
    try {
      const result = await axios({ url, method, data, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  //   ===============API for fetching event list============
  static async fetchEventList(token, type, page, key, search) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `events?eventType=${type}&page=${page}&pageSize=${20}&status=${key}&search=${search}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for register event============
  static async registerEvent(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`events`, data, "post", header);
    return res;
  }

  //   ===============API for event detail============
  static async eventDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`events/${id}`, undefined, "get", header);
    return res;
  }

  //   ===============API for edit detail============
  static async EditDetail(token, id, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`events/${id}`, data, "put", header);
    return res;
  }

  //   ===============API for delete event============
  static async deleteEvent(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`events/${id}`, undefined, "delete", header);
    return res;
  }

  //   ===============API for delete event============
  static async getProduct(token, type, page, key, search) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `products/list?productType=${type}&page=${page}&pageSize=${20}&status=${key}&search=${search}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for register Product============
  static async registerProduct(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`products`, data, "post", header);
    return res;
  }
  //   ===============holding reqest list============
  static async holdingRequestList(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`events/${id}`, undefined, "delete", header);
    return res;
  }

  //   ===============API for detail Product============
  static async productDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`products/${id}`, undefined, "get", header);
    return res;
  }

  //   ===============API for edit Product============
  static async editProduct(token, data, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`products/${id}`, data, "put", header);
    return res;
  }

  //   ===============API for delete Product============
  static async productDelete(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`products/${id}`, undefined, "delete", header);
    return res;
  }

  //   ===============API for update Product============
  static async productStatusUpdate(token, data, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `products/update-status/${id}`,
      data,
      "put",
      header
    );
    return res;
  }

  //   ===============API for update event============
  static async eventStatusUpdate(token, data, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `events/update-status/${id}`,
      data,
      "post",
      header
    );
    return res;
  }

  //   ===============API for fetch holding list============
  static async fetchHoldingList(
    token,
    search,
    type,
    startDate,
    endDate,
    key,
    order,
    page
  ) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/pause?search=${search}&type=${type}&startDate=${startDate}&endDate=${endDate}&sortingKey=${order}&sortingOrder=${key}&pageSize=${10}&page=${page}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for detail holding============
  static async holdingDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/pause/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for action for holding request============
  static async holdingRequest(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/pause/accept-reject`,
      data,
      "post",
      header
    );
    return res;
  }

  //   ===============API for fetch change trainer request list============
  static async changeTrainerList(token, order, key, page) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/change-trainer?sortingKey=${key}&sortingOrder=${order}&pageSize=${10}&page=${page}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for fetch change trainer request detail============
  static async changeTrainerDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/change-trainer/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for processinng action for trainer change request============
  static async requestProcessingHandle(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/change-trainer/process/${id}`,
      undefined,
      "post",
      header
    );
    return res;
  }

  //   ===============API for holding and complete action for trainer change request============
  static async changeTrainerRequestHandle(token, id, status) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/change-trainer/complete/${id}?isComplete=${status}`,
      undefined,
      "post",
      header
    );
    return res;
  }

  //   ===============API for edit trainer change request response============
  static async editChangeTrainerReq(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`request/change-trainer`, data, "put", header);
    return res;
  }

  //   ===============API for fetch refund request list============
  static async fetchRefundList(
    token,
    order,
    key,
    page,
    search,
    type,
    startDate,
    endDate,
    status
  ) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/refund?sortingKey=${key}&sortingOrder=${order}&pageSize=${10}&page=${page}&search=${search}&type=${type}&startDate=${startDate}&endDate=${endDate}&status=${status}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for fetch refund request detail============
  static async fetchRefundDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/refund/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for edit refund request response============
  static async editRefundReq(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `request/refund/accept-reject`,
      data,
      "post",
      header
    );
    return res;
  }

  //   ===============API for getting refund requested member chat id============
  static async getChatId(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `chat/get-refund-conversation`,
      data,
      "post",
      header
    );
    return res;
  }

  //   ===============API for getting refund requested member chat detail============
  static async getChatDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `chat/v2/messages/${id}?pageSize=10000`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for send message============
  static async sendMessage(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`chat/v2/send-message`, data, "post", header);
    return res;
  }

  //   ===============API for getting chat list============
  static async getChatList(token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `chat/web-conversation-list`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for getting member chat id============
  static async generateChatId(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`chat/get-conversation`, data, "post", header);
    return res;
  }
}

export default ManagementSublyApi;
