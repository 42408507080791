import React from "react";
import { Modal } from "react-bootstrap";
import "../../../assests/CommonStyle.css";
import { Icon } from "@iconify-icon/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getToken,
  getUserDetails,
  selectYourGymHandle,
} from "../../../Store/slices/AuthSlice";

function SuccessSigned({ successModal, setSuccessModal, email, toSelectGym }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        className="modalBoxClass"
        show={successModal}
        onHide={() => {
          setSuccessModal(false);
          if (toSelectGym?.gymDetails.length > 1) {
            dispatch(getUserDetails(toSelectGym));
            dispatch(getToken(toSelectGym?.token));
            navigate("/Select-your-gym");
          } else if (toSelectGym?.gymDetails.length == 1) {
            dispatch(selectYourGymHandle(toSelectGym));
            dispatch(getToken(toSelectGym?.token));
            navigate("/Member-search");
          }
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-16 px-12">
          <p className="rounded-full border-[35px]  border-[#A6FF3466] w-36 h-36 m-auto flex items-center justify-center max-xl:border-[20px] max-xl:w-28 max-xl:h-28">
            <Icon
              icon="icon-park-solid:check-one"
              width="100"
              height="100"
              style={{ color: "#A6FF34" }}
              className="max-xl:hidden"
            />
            <Icon
              icon="icon-park-solid:check-one"
              width="80"
              height="80"
              style={{ color: "#A6FF34" }}
              className="hidden max-xl:block"
            />
          </p>
          <p className="text-[40px] max-xl:text-[28px] font-extrabold mt-10 mb-8">
            You have signed up with {email || ""},
          </p>
          <p className="text-[40px] max-xl:text-[28px] font-bold">
            using Kakao account.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SuccessSigned;
