import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import demoP from "../../../assests/images/demoProfile.svg";
import ManagementSublyApi from "../../../helper/ManagementApi";
import { useSelector } from "react-redux";
import moment from "moment";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";

function HoldingModal({
  setshowModal,
  setProductId,
  productId,
  setIsLoader,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [detailValue, setDetailValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  // =============API for fetching holding detail===============
  useEffect(() => {
    getHoldingDetails();
  }, []);
  async function getHoldingDetails() {
    setIsLoader(true);
    await ManagementSublyApi.holdingDetail(token, productId)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setDetailValue(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ==============API for handle holding request action==================
  async function handleHoldingRequest() {
    const requestData = { requestId: detailValue?.id, isAccepted: true };
    await ManagementSublyApi.holdingRequest(token, requestData)
      .then((response) => {
        if (response.statusCode == 200) {
          getHoldingDetails();
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[70%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.HOLDING_FOR} {detailValue?.productName}
            </p>
          </div>
          <h3 className="font-extrabold text-[34px] text-center my-8">
            {detailValue?.memberName}
          </h3>
          <div className="flex flex-wrap items-center justify-center gap-x-8 gap-y-3">
            <div className="flex justify-between items-center">
              <Icon
                icon="lets-icons:date-today"
                width="30px"
                height="30px"
                style={{ color: "#CCFF00" }}
              />

              <span className="text-2xl font-extrabold ms-2">
                {moment(detailValue?.requestDate).format("YYYY.MM.DD")}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <Icon
                icon="heroicons:clock"
                width="30px"
                height="30px"
                style={{ color: "#CCFF00" }}
              />

              <span className="text-xl font-extrabold ms-2">
                {moment(detailValue?.requestDate).format("hh:mm")}
                <span className="text-lg font-bold">
                  {" "}
                  {moment(detailValue?.requestDate).format("A")}
                </span>{" "}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <Icon
                icon="lucide:eye"
                width="30px"
                height="30px"
                style={{ color: "#CCFF00" }}
              />
              <span className="text-2xl font-extrabold ms-2">{detailValue?.views}</span>
            </div>
          </div>
          <div className={` ${"mt-7 pb-4"}`}>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-2xl font-bold">
                  {strings.REQUEST_HOLDING_FOR + " :"}{" "}
                  <span className="font-extrabold text-2xl">
                    {detailValue?.productName}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-2xl font-bold">
                  {strings.PURCHASED_DATE}{" "}
                  <span className="font-extrabold text-2xl">
                    {moment(detailValue?.purchaseDate).format("YYYY.MM.DD")}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-2xl font-bold">
                  {strings.POSSIBLE_HOLDING_COUNT}{" "}
                  <span className="font-extrabold text-2xl">
                    {detailValue?.pauseCount} Count
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl max-sm:flex-wrap font-bold">
                  <span> {strings.POSSIBLE_HOLDING_DAYS} </span>
                  <span className="font-extrabold text-2xl">
                    {detailValue?.maximumPauseDays}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl max-sm:flex-wrap font-bold">
                  <span> {strings.REQUEST_HOLDING_DAYS} </span>
                  <span className="font-extrabold text-2xl">
                    {detailValue?.holdingRequestDays}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* {detailValue?.status == 1 && (
            <>
              <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
                <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3    w-full h-full">
                  <p className="mb-0 flex items-center gap-2 text-xl font-bold">
                    {strings.APPROVE_DATE}{" "}
                    <div className="font-extrabold text-2xl">
                      <div className="flex flex-wrap items-center justify-center gap-x-8 gap-y-3">
                        <div className="flex justify-between items-center">
                          <Icon
                            icon="lets-icons:date-today"
                            width="30"
                            height="30"
                            style={{ color: "white" }}
                          />

                          <span className="text-2xl font-extrabold ms-2">
                            {moment(detailValue?.resFeedbackDate).format(
                              "YYYY.MM.DD"
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <Icon
                            icon="heroicons:clock"
                            width="30px"
                            height="30px"
                            style={{ color: "white" }}
                          />

                          <span className="text-2xl font-extrabold ms-2">
                            {moment(detailValue?.resFeedbackDate).format(
                              "hh:mm"
                            )}
                            <span className="text-lg font-bold">
                              {" "}
                              {moment(detailValue?.resFeedbackDate).format("A")}
                            </span>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2.5 max-lg:flex-wrap ">
                <div className="flex items-start gap-x-3  max-sm:flex-wrap rounded-[10px]    w-full h-full">
                  <p className="mb-0 flex items-center gap-2 text-xl font-bold">
                    {strings.APPROVE_STAFF}{" "}
                    <div className="flex items-center">
                      <img
                        className="size-[34px] rounded-full"
                        src={detailValue?.mProfileImg || demoP}
                        alt=""
                      />
                      <span className="ms-2">{detailValue?.staffName}</span>
                    </div>
                  </p>
                </div>
              </div>
            </>
          )} */}
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-3 mb-4"}`}
          >
            <button
              onClick={() => {
                setshowModal(false);
                setProductId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
            {detailValue?.status !== 1 && detailValue?.status == 0 && (
              <button
                onClick={() => {
                  handleHoldingRequest();
                }}
                className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {strings.APPROVE}
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HoldingModal;
