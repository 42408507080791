import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import trainer from "../../../assests/icons/trainerIcon.svg";
import complete from "../../../assests/icons/complete.svg";
import incomplete from "../../../assests/icons/incomplete.svg";
import WorkoutCard from "../../commonComponents/WorkoutCard";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import ReactPaginate from "react-paginate";
import whiteTrainer from "../../../assests/icons/trainerIcon-White.svg";
import memberIcon from "../../../assests/icons/member-group.svg";
import dumble from "../../../assests/icons/dumble.svg";
import whiteTrainerBlack from "../../../assests/icons/trainerIcon-black.svg";
import memberIconBlack from "../../../assests/icons/member-group-black.svg";
import dumbleBlack from "../../../assests/icons/dumble-black.svg";
import WorkoutLogDetail from "./WorkoutLogDetail";
import { useLocation } from "react-router-dom";
import { alphaOnly, numberOnly } from "../../../utils/commonUtils";
import $ from "jquery";

function WorkoutLog() {
  const { token } = useSelector((state) => state.user);
  const [workoutData, setWorkoutData] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [active, setActive] = useState("active");
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [trainerList, setTrainerList] = useState("");
  const [openTrainer, setOpenTrainer] = useState(false);
  const [trainerValue, setTrainerValue] = useState({ id: "", name: "All" });
  const [createdBy, setCreatedBy] = useState(false);
  const [createByValue, setCreateByValue] = useState({
    id: 1,
    name: "All",
    image: dumble,
    value: "",
  });
  const [isComplete, setIsComplete] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [logId, setLogId] = useState({
    id: "",
    date: "",
    name: "",
    workout: "",
    isConfirmed: "",
    sessionStatus: "",
  });
  const location = useLocation();

  // ============Fetch workout log list============
  useEffect(() => {
    workoutListHandle();
  }, [offset]);

  useEffect(() => {
    workoutListHandle(1);
  }, [trainerValue, createByValue]);

  async function workoutListHandle(page, status) {
    setIsLoader(true);
    await PT_InformationSublyApi.workoutLogList(
      token,
      page ? page : offset,
      name,
      phone,
      trainerValue.id,
      status ? status : isComplete,
      createByValue.value
    ).then(async (response) => {
      setIsLoader(false);
      if (response.statusCode === 200) {
        page && setOffset(1);
        setWorkoutData(response.data.workoutData);
        setPage(response.data.count / 12);
        setTrainerList(response.data.trainerList);
      }
    });
  }

  //--------function for handling name input filed------
  $("#nameId").bind("keypress", alphaOnly);

  //--------function for handling contact input filed------
  $("#numberId").bind("keypress", numberOnly);
  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  useEffect(() => {
    if (location?.state?.id) {
      setOpenDetails(true);
    }
  }, []);

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setOpenDetails(false);
      setCreatedBy(false);
      setOpenTrainer(false);
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`${
              openDetails ? "!overflow-hidden h-screen" : "!overflow-auto"
            } ${"p-0 relative"}`}
          >
            <Header text={strings.PT_INFORMATION_WORKOUT_LOG} />
            <div className="mx-6 ">
              <div className="flex items-center gap-3 flex-wrap my-4 justify-between">
                <div className="flex items-center gap-3 flex-wrap">
                  <label
                    htmlFor="nameId"
                    className="bg-[#2C2C2E] w-[230px] max-sm:w-full h-[50px] px-3 flex items-center gap-2 rounded-[30px]"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#A1A1AA] font-bold text-lg whitespace-nowrap">
                      <Icon
                        icon="majesticons:user-line"
                        width="24"
                        height="24"
                        style={{ color: "white" }}
                      />
                    </p>
                    <input
                      type="text"
                      className="bg-[#2C2C2E] text-white font-bold w-full"
                      id="nameId"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      placeholder={placeholder.NAME}
                      autoComplete="off"
                    />
                  </label>
                  <label
                    htmlFor="numberId"
                    className="bg-[#2C2C2E] w-[230px] max-sm:w-full h-[50px] px-3 flex items-center gap-2 rounded-[30px] numberFieldClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#A1A1AA] font-bold text-lg whitespace-nowrap">
                      <Icon
                        icon="ph:phone"
                        width="24"
                        height="24"
                        style={{ color: "white" }}
                      />
                    </p>
                    <input
                      type="number"
                      className="bg-[#2C2C2E] text-white font-bold w-full numberField"
                      id="numberId"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      placeholder={placeholder.CELL_PHONE}
                      autoComplete="off"
                    />
                  </label>
                  <label
                    onClick={() => {
                      setOpenTrainer(!openTrainer);
                      setCreatedBy(false);
                    }}
                    className="relative bg-[#2C2C2E] w-[230px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] font-bold text-lg whitespace-nowrap">
                      <img src={trainer} className="w-[22px] h-[22px]" />
                      {trainerValue.name}{" "}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={openTrainer ? 2 : 4}
                    />
                    {openTrainer && (
                      <TrainerDropdown
                        trainerList={trainerList}
                        setTrainerValue={setTrainerValue}
                      />
                    )}
                  </label>
                  <button
                    onClick={() => {
                      workoutListHandle(1);
                      setOffset(1);
                    }}
                    className="hover:text-black hover:bg-[#CCFF00] text-lg font-extrabold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                  >
                    {strings.SEARCH}
                  </button>
                  <label
                    onClick={() => {
                      setCreatedBy(!createdBy);
                      setOpenTrainer(false);
                    }}
                    className="bg-[#2C2C2E] min-w-[310px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] relative dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] font-bold text-lg whitespace-nowrap">
                      {createByValue.image && (
                        <img
                          src={createByValue.image}
                          className="w-[22px] h-[22px]"
                        />
                      )}
                      {createByValue.name}{" "}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9" }}
                      rotate={createdBy ? 2 : 4}
                    />
                    {createdBy && (
                      <CreatedByDropdown setCreateByValue={setCreateByValue} />
                    )}
                  </label>
                </div>
                <div className="flex items-center gap-x-4 max-sm:flex-wrap gap-y-3">
                  <div
                    onClick={() => {
                      setIsComplete("complete");
                      workoutListHandle(1, "complete");
                      setCreateByValue({
                        id: 1,
                        name: "All",
                        image: dumble,
                        value: "",
                      });
                    }}
                    className={` ${
                      isComplete == "complete"
                        ? "kakaoClass bg-[#CCFF0066]"
                        : "bg-[#2C2C2E]"
                    } ${" rounded-[10px]  w-[160px] h-[43px] flex items-center justify-center gap-3 cursor-pointer"}`}
                  >
                    <img src={complete} />
                    <p className="mb-0 text-base font-bold">
                      {strings.COMPLETE}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setIsComplete("incomplete");
                      workoutListHandle(1, "incomplete");
                      setCreateByValue({
                        id: 1,
                        name: "All",
                        image: dumble,
                        value: "",
                      });
                    }}
                    className={` ${
                      isComplete == "incomplete"
                        ? "kakaoClass bg-[#CCFF0066]"
                        : "bg-[#2C2C2E]"
                    } ${"rounded-[10px]  w-[160px] h-[45px] flex items-center justify-center gap-3 cursor-pointer"}`}
                  >
                    <img src={incomplete} />
                    <p className="mb-0 text-base font-bold">
                      {strings.INCOMPLETE}
                    </p>
                  </div>
                </div>
              </div>
              {workoutData?.length == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-10">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              <Row className="overflow-auto max-h-[calc(100vh_-_300px)] !gap-y-3">
                {workoutData?.length > 0 &&
                  workoutData?.map((item) => (
                    <Col
                      xl={6}
                      xxl={4}
                      className="my-2.5"
                      onClick={() => {
                        item?.exercisesDetails &&
                          setLogId({
                            id: item?.id,
                            date: item?.date,
                            name: item?.memberDetails?.name,
                            workout: item?.exercisesDetails[0],
                            isConfirmed: item?.confirmed,
                            sessionStatus: item?.sessionStatus,
                          });
                      }}
                    >
                      <WorkoutCard
                        item={item}
                        openDetails={openDetails}
                        setOpenDetails={setOpenDetails}
                      />
                    </Col>
                  ))}
              </Row>

              {page && page > 1 && (
                <div className="mt-6 border-t-2 border-[#444444] pt-8">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    forcePage={offset == 1 ? 0 : offset - 1}
                    pageCount={page}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={active}
                    onClick={() => setActive("active")}
                  />
                </div>
              )}
            </div>
            {openDetails && (
              <WorkoutLogDetail
                setOpenDetails={setOpenDetails}
                logId={logId.id ? logId : location?.state}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  openDetails ? "block_notify" : "hideNotify"
                }`}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WorkoutLog;

function TrainerDropdown({ trainerList, setTrainerValue }) {
  return (
    <div
      style={{ border: "1px solid #686868" }}
      className="absolute rounded-[10px] bg-[#353638] min-w-[160px] right-4 top-10 max-h-[200px] px-2 overflow-auto z-10"
    >
      <div className="text-white text-lg font-bold py-2 trainerList">
        <p
          onClick={() => {
            setTrainerValue({ id: "", name: "All" });
          }}
          className="hover:bg-[#686868] py-1 mb-0 flex items-center gap-0.5 px-2"
        >
          {/* <Icon
            icon="radix-icons:dot-filled"
            width="20"
            height="20"
            style={{ color: "white" }}
          /> */}
          {strings.ALL}
        </p>
        {trainerList?.length > 0 &&
          trainerList?.map((item, index) => (
            <p
              key={index}
              onClick={() => {
                setTrainerValue({ id: item.id, name: item.name });
              }}
              className="hover:bg-[#686868] py-1 mb-0 flex items-center gap-0.5 px-2"
            >
              {/* <Icon
                icon="radix-icons:dot-filled"
                width="20"
                height="20"
                style={{ color: "white" }}
              /> */}
              {item.name}
            </p>
          ))}
      </div>
    </div>
  );
}

function CreatedByDropdown({ setCreateByValue }) {
  const [isHover, setIsHover] = useState(0);
  return (
    <div
      style={{ border: "1px solid #686868" }}
      className="absolute rounded-[10px] bg-[#353638] min-w-[160px] right-4 top-10 max-h-[200px] overflow-auto z-10"
    >
      <div className="text-white text-lg font-bold py-2 trainerList">
        <p
          className="hover:bg-[#CCFF00] hover:text-black py-1.5 mb-0 flex items-center gap-1 px-2"
          onClick={() => {
            setCreateByValue({
              id: 1,
              name: "All",
              image: dumble,
              value: "",
            });
          }}
          onMouseOver={() => setIsHover(1)}
          onMouseLeave={() => setIsHover(0)}
        >
          {isHover !== 1 ? <img src={dumble} /> : <img src={dumbleBlack} />}
          {strings.ALL}
        </p>
        <p
          className="hover:bg-[#CCFF00] hover:text-black py-1.5 mb-0 flex items-center gap-1 px-2"
          onClick={() => {
            setCreateByValue({
              id: 1,
              name: "Logs created by trainer",
              image: whiteTrainer,
              value: "shared-by-trainer",
            });
          }}
          onMouseOver={() => setIsHover(2)}
          onMouseLeave={() => setIsHover(0)}
        >
          {isHover !== 2 ? (
            <img src={whiteTrainer} />
          ) : (
            <img src={whiteTrainerBlack} />
          )}
          Logs created by trainer
        </p>
        <p
          onClick={() => {
            setCreateByValue({
              id: 1,
              name: "Logs shared by member",
              image: memberIcon,
              value: "shared-by-member",
            });
          }}
          onMouseOver={() => setIsHover(3)}
          onMouseLeave={() => setIsHover(0)}
          className="hover:bg-[#CCFF00] hover:text-black hover:demo py-1.5 mb-0 flex items-center gap-1 px-2"
        >
          {isHover !== 3 ? (
            <img src={memberIcon} />
          ) : (
            <img src={memberIconBlack} />
          )}
          Logs shared by member
        </p>
        <p
          onClick={() => {
            setCreateByValue({
              id: 1,
              name: "Member’s personal logs",
              image: memberIcon,
              value: "individual-workout",
            });
          }}
          className="hover:bg-[#CCFF00] hover:text-black py-1.5 mb-0 flex items-center gap-1 px-2"
        >
          <Icon icon="fa6-solid:user" width="15" height="15" />
          Member’s personal logs
        </p>
      </div>
    </div>
  );
}
