import { Modal, ModalBody } from "react-bootstrap";
import { strings } from "../../../utils/String";
import calendar from "../../../assests/icons/sent-message-icons/calendar.svg";
import clock from "../../../assests/icons/sent-message-icons/clock.svg";
import group from "../../../assests/icons/sent-message-icons/group.svg";
import center from "../../../assests/icons/sent-message-icons/center-icon.svg";
import message from "../../../assests/icons/sent-message-icons/messageicon.svg";
import "../../../assests/CommonStyle.css";
import moment from "moment";
import { CHAT_TYPES } from "../../appComponents/ptInformation/SentMessageLIst";
import { Icon } from "@iconify-icon/react/dist/iconify.js";

function SentMessage({
  openModal,
  setOpenModal,
  sentItem,
  setSentItem,
  classNameValue,
}) {

  return (
    <>
      <Modal
        className={`${"modalBoxClass"} ${classNameValue}`}
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setSentItem("");
        }}
      >
        <ModalBody className="text-white bg-box_bg text-center py-8 px-8">
          <h3 className="flex items-center justify-center gap-3 max-lg:text-[28px] pb-4 text-[40px] font-extrabold w-[80%] m-auto mb-2">
            {strings.SENT_MESSAGE}
          </h3>
          <div className="flex items-center justify-center gap-10  border-b-2 border-[#555555] pb-4">
            <div className="flex items-center gap-3">
              <img
                width="25"
                height="24"
                color="#CCFF00"
                src={calendar}
                alt="calendar"
              />
              <p className="text-[22px] font-extrabold mb-0">
                {moment(sentItem?.regDate).format("YYYY.MM.DD")}
              </p>{" "}
            </div>

            <div className="flex items-center gap-3">
              <img
                width="26"
                height="26"
                color="#CCFF00"
                src={clock}
                alt="clock"
              />
              <p className="text-[22px] font-extrabold mb-0">
                {moment(sentItem?.regDate).format("HH:MM A")}
              </p>
            </div>

            <div className="flex items-center gap-3">
              {sentItem?.chatTypeValue == "SYSTEM" ? (
                <img
                  width="30"
                  height="21"
                  color="#CCFF00"
                  src={group}
                  alt="group"
                />
              ) : sentItem?.chatTypeValue == "CENTER" ? (
                <img
                  width="30"
                  height="21"
                  color="#CCFF00"
                  src={center}
                  alt="group"
                />
              ) : sentItem?.chatTypeValue == "ADMIN" ? (
                <Icon
                  icon="solar:user-circle-outline"
                  width="30px"
                  height="30px"
                  style={{ color: " #ccff00" }}
                />
              ) : (
                <img
                  width="30"
                  height="21"
                  color="#CCFF00"
                  src={group}
                  alt="group"
                />
              )}
              <p className="text-[22px] font-extrabold mb-0">
                {CHAT_TYPES[sentItem?.chatTypeValue]}
              </p>
            </div>
          </div>
          <div className="flex items-start gap-3 mt-4 px-5">
            <img
              src={message}
              alt="message"
              className="w-[30px] h-[30px] mt-2"
            />
            <div className="flex-grow">
              <p className="text-left text-[22px] font-bold mt-0 mb-2">
                {sentItem?.message}
              </p>
            </div>
          </div>
          <div className="flex justify-center pt-8 gap-4">
            <button
              onClick={() => {
                setOpenModal(false);
                setSentItem("");
              }}
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default SentMessage;
