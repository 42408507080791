import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import calender from "../../../assests/icons/calenderWithTick.svg";
import rightTick from "../../../assests/icons/rightTickBatch.svg";
import ScheduleStatistics from "./ScheduleStatistics";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../utils/Loader";
import ScheduleDetails from "./ScheduleDetails";
import { scrollToTopFun } from "../gymSettings/StaffSetting";
import $, { event } from "jquery";
import InfoModal from "./InfoModal";
import holidayIcon from "../../../assests/icons/schedule-icon/holidayIcon.svg";
import WeekModal from "./WeekModal";
import MonthModal from "./MonthModal";

function ScheduleScreen() {
  const { token } = useSelector((state) => state.user);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [scheduleData, setScheduleData] = useState("");
  const [statisticsData, setStatisticsData] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [stateFilter, setStateFilter] = useState("day");
  const [calenderFilter, setCalenderFilter] = useState("all");
  const [trainerStateData, setTrainerStateData] = useState("");
  const [eventDate, setEvenetDate] = useState("");
  const [detailedSchedule, setDetailedSchedule] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [infoDate, setInfoDate] = useState();
  const [selectDay, setSelectDay] = useState("");
  const [openWeekModal, setOpenWeekModal] = useState(false);
  const [openMonthModal, setOpenMonthModal] = useState(false);
  // ======================Method and function for design calender===========================
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const getlastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDay();
  };
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const previousMonth = currentDate.getMonth() - 1;
  const daysInMonth = getDaysInMonth(year, month);
  const lastMonthDays = getDaysInMonth(year, previousMonth);
  const firstDayOfMonth = getFirstDayOfMonth(2024, month);
  const lastDay = getlastDayOfMonth(year, month);

  // ================Method to get next month remaining date================
  const nextDays = [...Array(6 - lastDay).keys()].map((day) => day + 1);

  // ================Method to get last month remaining date================
  // const lastDays = [...Array(lastMonthDays).keys()]
  //   .map((day) => day + 1)
  //   .slice(-(6 - firstDayOfMonth));

  const lastDays = [...Array(firstDayOfMonth).keys()].map(
    (day) => lastMonthDays - firstDayOfMonth + day + 1
  );

  // ================Method to get current month date================
  const daysArray = [...Array(daysInMonth).keys()].map((day) => day + 1);
  // =====================================================================================================

  // ==============Calling API for fetching schedule data================
  useEffect(() => {
    fetchStatisticsData();
    scheduleDataHandle();
  }, [calenderFilter, year, month, stateFilter, eventDate]);
  async function scheduleDataHandle() {
    setIsLoader(true);
    await PT_InformationSublyApi.fetchScheduleData(
      token,
      month + 1,
      year,
      calenderFilter
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setScheduleData(response?.data?.sessionDates);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ================Fetch schedule statistics data API====================
  // useEffect(() => {
  //   fetchStatisticsData();
  // }, [stateFilter, calenderFilter, eventDate]);
  async function fetchStatisticsData(date) {
    setIsLoader(true);
    await PT_InformationSublyApi.scheduleStatistics(
      token,
      stateFilter,
      date || eventDate,
      month + 1,
      year
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setStatisticsData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ================Fetch schedule statistics data API====================
  useEffect(() => {
    calenderFilter == "trainer" && statisticsTrainerData();
  }, [stateFilter, calenderFilter, eventDate]);

  async function statisticsTrainerData(date) {
    setIsLoader(true);
    await PT_InformationSublyApi.scheduleStatisticsTrainer(
      token,
      stateFilter,
      date || eventDate,
      month + 1,
      year
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setTrainerStateData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0
    ) {
      if (detailedSchedule || openWeekModal || openMonthModal) {
        setDetailedSchedule(false);
        setOpenWeekModal(false);
        setOpenMonthModal(false);
        // setEvenetDate("");
      }
    }
  });

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => {
      const prevMonth = prevDate.getMonth() - 1;
      setEvenetDate(
        moment()
          .month(new Date(prevDate.getFullYear(), prevMonth, 1).getMonth())
          .year(new Date(prevDate.getFullYear(), prevMonth, 1).getFullYear())
          .date(selectDay)
          .format("YYYY-MM-DD")
      );
      return new Date(prevDate.getFullYear(), prevMonth, 1);
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const nextMonth = prevDate.getMonth() + 1;
      setEvenetDate(
        moment()
          .month(new Date(prevDate.getFullYear(), nextMonth, 1).getMonth())
          .year(new Date(prevDate.getFullYear(), nextMonth, 1).getFullYear())
          .date(selectDay)
          .format("YYYY-MM-DD")
      );
      return new Date(prevDate.getFullYear(), nextMonth, 1);
    });
  };

  console.log("calenderFilter", eventDate);

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`${
              detailedSchedule || openMonthModal || openWeekModal
                ? "!overflow-hidden  h-screen"
                : "!overflow-auto "
            } ${"p-0 relative "}`}
          >
            <Header text={strings.PT_INFORMATION_SCHEDULE} />
            <div className="mx-6 ">
              <Row>
                <Col xl={8}>
                  <div>
                    <div className="flex items-center justify-between gap-3 flex-wrap  my-[12px]">
                      <div className="flex items-center gap-1.5">
                        {/* <Icon
                          icon="ion:menu"
                          width="48"
                          height="48"
                          style={{ color: "#fff" }}
                        /> */}
                        <p className="mb-0 text-2xl font-medium font-[archivo]">
                          <div className="flex items-center flex-wrap text-white gap-3 sm:gap-7 justify-center  mt-3 mb-2">
                            <div
                              onClick={() => {
                                // setStateFilter("month");
                                handlePrevMonth();
                                fetchStatisticsData();
                                if (calenderFilter == "trainer") {
                                  setEvenetDate("");
                                  setStateFilter("day");
                                }
                                // setEvenetDate("");
                              }}
                              className="size-10 bg-[#2C2C2E] rounded-full flex items-center justify-center cursor-pointer"
                            >
                              <Icon
                                icon="tabler:chevron-left"
                                width="25px"
                                height="25px"
                                style={{ color: "white" }}
                              />
                            </div>
                            <span className="font-medium text-2xl">
                              {year}.
                              {`${+month + 1}`.length == 1
                                ? "0" + (month + 1)
                                : month + 1}{" "}
                            </span>
                            <div
                              onClick={() => {
                                // setStateFilter("month");
                                handleNextMonth();
                                fetchStatisticsData();
                                if (calenderFilter == "trainer") {
                                  setEvenetDate("");
                                  setStateFilter("day");
                                }
                                // setEvenetDate("");
                              }}
                              className="size-10 bg-[#2C2C2E] rounded-full flex items-center justify-center cursor-pointer"
                            >
                              <Icon
                                icon="tabler:chevron-right"
                                width="25px"
                                height="25px"
                                style={{ color: "white" }}
                              />
                            </div>
                          </div>
                        </p>
                      </div>
                      <div className="flex items-center gap-3 justify-center">
                        <button
                          onClick={() => {
                            setCalenderFilter("all");
                            setEvenetDate("");
                          }}
                          className={`${
                            calenderFilter == "all"
                              ? "bg-[#CCFF00] text-black"
                              : "text-white kakaoClass"
                          } ${"hover:bg-[#CCFF00] hover:text-black text-base font-bold h-[40px] w-[120px] rounded-[28px]"}`}
                        >
                          {strings.ALL}
                        </button>
                        <button
                          onClick={() => {
                            setCalenderFilter("trainer");
                            setEvenetDate("");
                          }}
                          className={`${
                            calenderFilter == "trainer"
                              ? "bg-[#CCFF00] text-black"
                              : "text-white kakaoClass"
                          } ${"hover:bg-[#CCFF00] hover:text-black text-base font-bold h-[40px] w-[120px] rounded-[28px]"}`}
                        >
                          {strings.TRAINER}
                        </button>{" "}
                      </div>
                    </div>
                    {calenderFilter == "trainer" ? (
                      <div className="overflow-auto">
                        <div className="flex mb-4 max-sm:w-[950px]">
                          <div
                            style={{ border: "1px solid #505050" }}
                            className="w-full bg-[#2C2C2E] rounded-[10px]"
                          >
                            <div className="grid-cols-7 grid">
                              {weekdays.map((day, index) => (
                                <div
                                  key={index.toString()}
                                  className={`${
                                    eventDate &&
                                    moment(eventDate).format("ddd") == day
                                      ? "text-[#CCFF00]"
                                      : "text-[#6E7781]"
                                  } ${"border-r border-[#505050] last:border-r-0 py-3 ctr font-bold text-base text-center"}`}
                                >
                                  {day}
                                </div>
                              ))}
                            </div>
                            <div className="grid-cols-7 grid">
                              {lastDays?.map((i, n) => (
                                <div
                                  key={n.toString()}
                                  onClick={() => {
                                    setEvenetDate("");
                                  }}
                                  style={{ border: "1px solid #505050" }}
                                  className="h-[170px] items-start font-bold text-lg flex justify-center text-[#666666] py-1"
                                >
                                  {i}
                                </div>
                              ))}
                              {daysArray?.map((date, index) => {
                                let dateFound =
                                  scheduleData?.length > 0 &&
                                  scheduleData?.find((val) => {
                                    return moment(val.date).format("D") == date;
                                  });
                                let newDate = moment()
                                  .month(month)
                                  .year(year)
                                  .date(date)
                                  .format("YYYY-MM-DD");
                                return (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      if (
                                        calenderFilter == "trainer" &&
                                        dateFound &&
                                        !dateFound?.isHoliday
                                      ) {
                                        if (stateFilter == "day") {
                                          setDetailedSchedule(true);
                                        } else if (stateFilter == "week") {
                                          setOpenWeekModal(true);
                                        } else if (stateFilter == "month") {
                                          setOpenMonthModal(true);
                                        }
                                        scrollToTopFun();
                                        setEvenetDate(dateFound?.date);
                                        dateFound &&
                                          statisticsTrainerData(
                                            dateFound?.date
                                          );
                                      } else if (
                                        calenderFilter == "all" &&
                                        !dateFound?.isHoliday
                                      ) {
                                        setEvenetDate(dateFound?.date);
                                        // setDetailedSchedule(true);
                                        scrollToTopFun();
                                        dateFound &&
                                          fetchStatisticsData(dateFound?.date);
                                      } else {
                                        setEvenetDate("");
                                      }
                                      if (dateFound?.isHoliday) {
                                        setInfoModal(true);
                                        setInfoDate(dateFound?.date);
                                      }
                                    }}
                                    // style={{
                                    //   border:
                                    //     eventDate ==
                                    //     moment()
                                    //       .month(month)
                                    //       .year(year)
                                    //       .date(date)
                                    //       .format("YYYY-MM-DD")
                                    //       ? "3px solid #CCFF00"
                                    //       : "1px solid #505050",
                                    // }}
                                    style={{
                                      border:
                                        stateFilter === "day"
                                          ? eventDate ===
                                            moment()
                                              .month(month)
                                              .year(year)
                                              .date(date)
                                              .format("YYYY-MM-DD")
                                            ? "3px solid #CCFF00"
                                            : "1px solid #505050"
                                          : stateFilter === "week" &&
                                            moment
                                              .utc(newDate)
                                              .isBetween(
                                                moment.utc(
                                                  trainerStateData?.currentStartDate
                                                ),
                                                moment.utc(
                                                  trainerStateData?.currentEndDate
                                                ),
                                                "days",
                                                "[]"
                                              )
                                          ? "3px solid #ccff00"
                                          : "1px solid #505050",
                                    }}
                                    className={`${
                                      dateFound?.isHoliday
                                        ? "bg-[#FFAFAF99]"
                                        : ""
                                    } ${`h-[170px] flex justify-start py-1 text-[#fff] relative flex-col items-center`}`}
                                  >
                                    {date} <br />
                                    {calenderFilter == "all" ? (
                                      <div className="p-1">
                                        <div
                                          className={`${
                                            dateFound?.booked > 0
                                              ? "bg-[#ABA9FF33]"
                                              : "h-[30px] bg-transparent"
                                          } ${"flex justify-center  gap-2.5 my-2  items-center rounded-[5px] py-0.5 sm:px-3 px-0.5"}`}
                                        >
                                          {dateFound?.booked > 0 && (
                                            <>
                                              <img
                                                src={calender}
                                                alt="calendar"
                                                className="w-[20px] h-[20px]"
                                              />
                                              <p className="mb-0 mt-1 text-base font-medium text-[#B3B1FF] font-[poppins]">
                                                {dateFound?.booked}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        <div
                                          className={`${
                                            dateFound?.rejected > 0
                                              ? "bg-[#FF919133]"
                                              : "h-[30px] bg-transparent"
                                          } ${"flex rounded-[5px] py-0.5 px-3 gap-2.5 my-2 items-center"}`}
                                        >
                                          {dateFound?.rejected > 0 && (
                                            <>
                                              <Icon
                                                icon="fontisto:close"
                                                width="20"
                                                height="20"
                                                style={{ color: "#FF9191" }}
                                              />{" "}
                                              <p className="mb-0 text-base font-medium text-[#FF9191] font-[poppins]">
                                                {dateFound?.rejected}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        <div
                                          className={`${
                                            dateFound?.completed > 0
                                              ? "bg-[#CBFF8A33]"
                                              : "h-[30px] bg-transparent"
                                          } ${"flex rounded-[5px] py-0.5 px-3 gap-2.5 my-2 items-center bg-[#CBFF8A33]"}`}
                                        >
                                          {dateFound?.completed > 0 && (
                                            <>
                                              <img
                                                src={rightTick}
                                                alt="right-tick"
                                                className="w-[20px] h-[20px]"
                                              />
                                              <p className="mb-0 text-base font-medium text-[#CBFF8A] font-[poppins]">
                                                {dateFound?.completed}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="p-1">
                                        {dateFound?.gymStaffInfos &&
                                          dateFound?.gymStaffInfos[0] && (
                                            <div
                                              className={`${"bg-[#ABA9FF33]"} ${"flex  gap-2.5 my-2  items-center rounded-[5px] py-0.5 px-3 max-sm:px-1"}`}
                                            >
                                              <p className="mb-0 mt-1 text-base font-medium text-[#B3B1FF] font-[poppins] textOverflowClass">
                                                {
                                                  dateFound?.gymStaffInfos[0]
                                                    ?.gymStaffName
                                                }{" "}
                                                {`(${dateFound?.gymStaffInfos[0]?.count})`}
                                              </p>
                                            </div>
                                          )}
                                        {dateFound?.gymStaffInfos &&
                                          dateFound?.gymStaffInfos[1] && (
                                            <div
                                              className={`${"bg-[#FF919133]"} ${"flex  gap-2.5 my-2  items-center rounded-[5px] py-0.5 px-3 max-sm:px-1"}`}
                                            >
                                              <p className="mb-0 mt-1 text-base font-medium text-[#FF9191] font-[poppins] textOverflowClass">
                                                {
                                                  dateFound?.gymStaffInfos[1]
                                                    ?.gymStaffName
                                                }{" "}
                                                {`(${dateFound?.gymStaffInfos[1]?.count})`}
                                              </p>
                                            </div>
                                          )}
                                        {dateFound?.gymStaffInfos &&
                                          dateFound?.gymStaffInfos[2] && (
                                            <div
                                              className={`${"bg-[#CBFF8A33]"} ${"flex  gap-2.5 my-2  items-center rounded-[5px] py-0.5 px-3 max-sm:px-1"}`}
                                            >
                                              <p className="mb-0 mt-1 text-base font-medium text-[#CBFF8A] font-[poppins] textOverflowClass">
                                                {
                                                  dateFound?.gymStaffInfos[2]
                                                    ?.gymStaffName
                                                }{" "}
                                                {`(${dateFound?.gymStaffInfos[2]?.count})`}
                                              </p>
                                            </div>
                                          )}
                                        {dateFound?.isHoliday && (
                                          <div className="">
                                            <img
                                              className="mx-auto w-8 h-8"
                                              src={holidayIcon}
                                            />
                                            <p className="!mb-0 text-2xl font-bold mt-3">
                                              {strings.DAY_OFF}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                              {nextDays?.map((i, n) => (
                                <div
                                  onClick={() => {
                                    setEvenetDate("");
                                  }}
                                  key={n.toString()}
                                  style={{ border: "1px solid #505050" }}
                                  className="h-[170px] items-start flex justify-center text-[#666666] py-1"
                                >
                                  {i}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="overflow-auto">
                        <div className="flex mb-4 max-sm:w-[950px]">
                          <div
                            style={{ border: "1px solid #505050" }}
                            className="w-full bg-[#2C2C2E] rounded-[10px]"
                          >
                            <div className="grid-cols-7 grid">
                              {weekdays.map((day, index) => (
                                <div
                                  key={index.toString()}
                                  className={`${
                                    eventDate &&
                                    moment(eventDate).format("ddd") == day
                                      ? "text-[#CCFF00]"
                                      : "text-[#6E7781]"
                                  } ${"border-r border-[#505050] last:border-r-0 py-3 ctr font-bold text-base text-center"}`}
                                >
                                  {day}
                                </div>
                              ))}
                            </div>
                            <div className="grid-cols-7 grid">
                              {lastDays?.map((i, n) => (
                                <div
                                  key={n.toString()}
                                  onClick={() => {
                                    setEvenetDate("");
                                  }}
                                  style={{ border: "1px solid #505050" }}
                                  className="h-[170px] items-start font-bold text-lg flex justify-center text-[#666666] py-1"
                                >
                                  {i}
                                </div>
                              ))}
                              {daysArray?.map((date, index) => {
                                let dateFound =
                                  scheduleData?.length > 0 &&
                                  scheduleData?.find((val) => {
                                    return moment(val.date).format("D") == date;
                                  });
                                let newDate = moment()
                                  .month(month)
                                  .year(year)
                                  .date(date)
                                  .format("YYYY-MM-DD");
                                return (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      if (
                                        calenderFilter == "all" &&
                                        !dateFound?.isHoliday
                                      ) {
                                        setEvenetDate(
                                          moment()
                                            .month(month)
                                            .year(year)
                                            .date(date)
                                            .format("YYYY-MM-DD")
                                        );
                                        setSelectDay(date);
                                        // setDetailedSchedule(true);
                                        scrollToTopFun();
                                        // dateFound &&
                                        // fetchStatisticsData();
                                        // setStateFilter("day");
                                      } else {
                                        setEvenetDate("");
                                      }
                                      if (dateFound?.isHoliday) {
                                        setInfoModal(true);
                                        setInfoDate(dateFound?.date);
                                      }
                                    }}
                                    style={{
                                      border:
                                        stateFilter === "day"
                                          ? eventDate ===
                                            moment()
                                              .month(month)
                                              .year(year)
                                              .date(date)
                                              .format("YYYY-MM-DD")
                                            ? "3px solid #CCFF00"
                                            : "1px solid #505050"
                                          : stateFilter == "week" &&
                                            moment
                                              .utc(newDate)
                                              .isBetween(
                                                moment.utc(
                                                  statisticsData?.currentCount
                                                    ?.startDate
                                                ),
                                                moment.utc(
                                                  statisticsData?.currentCount
                                                    ?.endDate
                                                ),
                                                "days",
                                                "[]"
                                              )
                                          ? "3px solid #CCFF00"
                                          : "1px solid #505050",
                                    }}
                                    className={`${
                                      dateFound?.isHoliday
                                        ? "bg-[#FFAFAF99]"
                                        : ""
                                    } ${"h-[170px] flex justify-start py-1 text-[#fff] relative flex-col items-center"}`}
                                  >
                                    {date} <br />
                                    {calenderFilter == "all" ? (
                                      <div className="p-1 ">
                                        <div
                                          className={`${
                                            dateFound?.booked > 0
                                              ? "bg-[#ABA9FF33]"
                                              : "h-[30px] bg-transparent"
                                          } ${"flex justify-center  gap-2.5 my-2  items-center rounded-[5px] py-0.5 sm:px-3 px-0.5"}`}
                                        >
                                          {dateFound?.booked > 0 && (
                                            <>
                                              <img
                                                src={calender}
                                                alt="calendar"
                                                className="w-[20px] h-[20px]"
                                              />
                                              <p className="mb-0 mt-1 text-base font-medium text-[#B3B1FF] font-[poppins]">
                                                {dateFound?.booked}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        <div
                                          className={`${
                                            dateFound?.rejected > 0
                                              ? "bg-[#FF919133]"
                                              : "h-[30px] bg-transparent"
                                          } ${"flex rounded-[5px] py-0.5 px-3 gap-2.5 my-2 items-center"}`}
                                        >
                                          {dateFound?.isHoliday && (
                                            <div className="">
                                              <img
                                                className="mx-auto w-8 h-8"
                                                src={holidayIcon}
                                              />
                                              <p className="!mb-0 text-2xl font-bold mt-3">
                                                {strings.DAY_OFF}
                                              </p>
                                            </div>
                                          )}
                                          {dateFound?.rejected > 0 && (
                                            <>
                                              <Icon
                                                icon="fontisto:close"
                                                width="20"
                                                height="20"
                                                style={{ color: "#FF9191" }}
                                              />{" "}
                                              <p className="mb-0 text-base font-medium text-[#FF9191] font-[poppins]">
                                                {dateFound?.rejected}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        <div
                                          className={`${
                                            dateFound?.completed > 0
                                              ? "bg-[#CBFF8A33]"
                                              : "h-[30px] bg-transparent"
                                          } ${"flex rounded-[5px] py-0.5 px-3 gap-2.5 my-2 items-center bg-[#CBFF8A33]"}`}
                                        >
                                          {dateFound?.completed > 0 && (
                                            <>
                                              <img
                                                src={rightTick}
                                                alt="right-tick"
                                                className="w-[20px] h-[20px]"
                                              />
                                              <p className="mb-0 text-base font-medium text-[#CBFF8A] font-[poppins]">
                                                {dateFound?.completed}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="p-1">
                                        {dateFound?.gymStaffInfos &&
                                          dateFound?.gymStaffInfos[0] && (
                                            <div
                                              className={`${"bg-[#ABA9FF33]"} ${"flex  gap-2.5 my-2  items-center rounded-[5px] py-0.5 px-3 max-sm:px-1"}`}
                                            >
                                              <p className="mb-0 mt-1 text-base font-medium text-[#B3B1FF] font-[poppins] textOverflowClass">
                                                {
                                                  dateFound?.gymStaffInfos[0]
                                                    ?.gymStaffName
                                                }{" "}
                                                {`(${dateFound?.gymStaffInfos[0]?.count})`}
                                              </p>
                                            </div>
                                          )}
                                        {dateFound?.gymStaffInfos &&
                                          dateFound?.gymStaffInfos[1] && (
                                            <div
                                              className={`${"bg-[#FF919133]"} ${"flex  gap-2.5 my-2  items-center rounded-[5px] py-0.5 px-3 max-sm:px-1"}`}
                                            >
                                              <p className="mb-0 mt-1 text-base font-medium text-[#FF9191] font-[poppins] textOverflowClass">
                                                {
                                                  dateFound?.gymStaffInfos[1]
                                                    ?.gymStaffName
                                                }{" "}
                                                {`(${dateFound?.gymStaffInfos[1]?.count})`}
                                              </p>
                                            </div>
                                          )}
                                        {dateFound?.gymStaffInfos &&
                                          dateFound?.gymStaffInfos[2] && (
                                            <div
                                              className={`${"bg-[#CBFF8A33]"} ${"flex  gap-2.5 my-2  items-center rounded-[5px] py-0.5 px-3 max-sm:px-1"}`}
                                            >
                                              <p className="mb-0 mt-1 text-base font-medium text-[#CBFF8A] font-[poppins] textOverflowClass">
                                                {
                                                  dateFound?.gymStaffInfos[2]
                                                    ?.gymStaffName
                                                }{" "}
                                                {`(${dateFound?.gymStaffInfos[2]?.count})`}
                                              </p>
                                            </div>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                              {nextDays?.map((i, n) => (
                                <div
                                  onClick={() => {
                                    setEvenetDate("");
                                  }}
                                  key={n.toString()}
                                  style={{ border: "1px solid #505050" }}
                                  className="h-[170px] items-start flex justify-center text-[#666666] py-1"
                                >
                                  {i}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={4}>
                  <div className="flex items-center gap-3 justify-end my-4">
                    <button
                      onClick={() => {
                        setStateFilter("day");
                      }}
                      className={`${
                        stateFilter == "day"
                          ? "bg-[#CCFF00] text-black"
                          : "text-white kakaoClass"
                      } ${" hover:bg-[#CCFF00] hover:text-black text-base font-bold h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {strings.DAY}
                    </button>
                    <button
                      onClick={() => {
                        setStateFilter("week");
                        !eventDate &&
                          setEvenetDate(
                            moment()
                              .month(month)
                              .year(year)
                              .date(new Date().getDate())
                              .format("YYYY-MM-DD")
                          );
                      }}
                      className={`${
                        stateFilter == "week"
                          ? "bg-[#CCFF00] text-black"
                          : "text-white kakaoClass"
                      } ${"hover:bg-[#CCFF00] hover:text-black text-base font-bold h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {strings.WEEK}
                    </button>
                    <button
                      onClick={() => {
                        setStateFilter("month");
                        // setEvenetDate("");
                      }}
                      className={`${
                        stateFilter == "month"
                          ? "bg-[#CCFF00] text-black"
                          : "text-white kakaoClass"
                      } ${" hover:bg-[#CCFF00] hover:text-black text-base font-bold h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {strings.MONTH}
                    </button>
                  </div>
                  <ScheduleStatistics
                    statisticsData={statisticsData}
                    calenderFilter={calenderFilter}
                    trainerStateData={trainerStateData}
                    stateFilter={stateFilter}
                  />
                </Col>
              </Row>
            </div>
            {detailedSchedule && (
              <ScheduleDetails
                setDetailedSchedule={setDetailedSchedule}
                eventDate={eventDate}
                setEvenetDate={setEvenetDate}
                classNameValue={`${
                  detailedSchedule ? "block_notify" : "hideNotify"
                }`}
              />
            )}

            {openWeekModal && (
              <WeekModal
                setDetailedSchedule={setOpenWeekModal}
                eventDate={eventDate}
                setEvenetDate={setEvenetDate}
                classNameValue={`${
                  openWeekModal ? "block_notify" : "hideNotify"
                }`}
                setIsLoader={setIsLoader}
              />
            )}
            {openMonthModal && (
              <MonthModal
                setDetailedSchedule={setOpenMonthModal}
                eventDate={eventDate}
                setEvenetDate={setEvenetDate}
                classNameValue={`${
                  openMonthModal ? "block_notify" : "hideNotify"
                }`}
                setIsLoader={setIsLoader}
              />
            )}
            {infoModal && (
              <InfoModal
                infoModal={infoModal}
                setInfoModal={setInfoModal}
                infoDate={infoDate}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ScheduleScreen;
