import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import { PRODUCT_TYPES_LIST } from "../../../utils/CommonData";
import ReactDatePicker from "react-datepicker";
import ManagementSublyApi from "../../../helper/ManagementApi";
import RefundTable from "./RefundTable";
import RefundModal from "./RefundModal";
import ReactPaginate from "react-paginate";
import moment from "moment";
import $ from "jquery";
import { DatePicker } from "rsuite";

const RefundRequest = () => {
  const { token } = useSelector((state) => state.user);
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [monthValue, setMonthValue] = useState({ text: "All", value: "" });
  const [showModal, setshowModal] = useState(false);
  const [refundListValue, setrefundListValue] = useState("");
  const [openType, setOpenType] = useState(false);
  const [typeValue, setTypeValue] = useState({ text: "All", value: "" });
  const [activeIndex, setactiveIndex] = useState("");
  const [orderValue, setOrderValue] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [activeTab, setActiveTab] = useState("");
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [currentEndMonth, setCurrentEndMonth] = useState(
    moment().format("YYYY.MM.DD")
  );
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  // ==============API fo fetch holding request list==================
  useEffect(() => {
    !showModal && getRefundList("", "", 1);
    setActiveTab("");
  }, [showModal, monthValue.value, typeValue.value]);

  useEffect(() => {
    getRefundList();
  }, [offset]);

  async function getRefundList(order, key, page) {
    setIsLoader(true);
    await ManagementSublyApi.fetchRefundList(
      token,
      order || "",
      key || "",
      page ? page : offset,
      search,
      monthValue.value,
      startDate,
      endDate,
      typeValue.value
    ).then((response) => {
      setIsLoader(false);
      if (response.statusCode == 200) {
        setrefundListValue(response.data?.refundList);
        setPage(response.data?.count / 10);
        page && setOffset(1);
      }
    });
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setshowModal(false);
      setactiveIndex("");
      setOpenMonth(false);
      setOpenType(false);
    }
  });

  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonth).getMonth();
    return condition;
  };
  const filterEndMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentEndMonth).getMonth();
    return condition;
  };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
  }, [isCalenderOpen, currentMonth]);

  return (
    <div>
      <section>
        {isLoader ? <Loader /> : ""}
        <Container fluid>
          <Row>
            <Col xl={2} className="p-0">
              <SideBar />
            </Col>
            <Col xl={10} className="p-0 overflow-auto h-screen relative">
              <Header text={strings.Management_Refund_Request} />
              <div className="text-white px-7 ">
                <div className="mt-4 mb-7 flex items-center justify-between gap-3 flex-wrap">
                  <div className="flex items-center gap-4 max-sm:w-full">
                    <label
                      onClick={() => {
                        setOpenMonth(!openMonth);
                        setOpenType(false);
                      }}
                      className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                    >
                      <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                        {monthValue.text}{" "}
                      </p>
                      <Icon
                        icon="icon-park-solid:down-one"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9", cursor: "pointer" }}
                        rotate={openMonth ? 2 : 4}
                      />
                      {openMonth && (
                        <MonthFilter setMonthValue={setMonthValue} />
                      )}{" "}
                    </label>
                    <label
                      onClick={() => {
                        setOpenType(!openType);
                        setOpenMonth(false);
                      }}
                      className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                    >
                      <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                        {typeValue?.text}{" "}
                      </p>
                      <Icon
                        icon="icon-park-solid:down-one"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9", cursor: "pointer" }}
                        rotate={openType ? 2 : 4}
                      />
                      {openType && <TypeFilter setTypeValue={setTypeValue} />}{" "}
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3 gap-y-3 max-sm:flex-wrap">
                    <div className="flex items-center gap-x-3 gap-y-3 flex-wrap">
                      <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                        <input
                          type="text"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          placeholder="Keywords"
                          className="bg-transparent w-full text-white text-lg font-bold"
                        />
                      </label>
                      <div className="flex items-center gap-2 dateInputClass pl-3 max-[390px]:-ml-4 ">
                        <Icon
                          icon="lets-icons:date-today"
                          width="30"
                          height="30"
                          style={{ color: "#CCFF00" }}
                        />
                        <div className="w-full flex flex-col md:flex-row gap-2 ">
                          <div className="newDatePickerClass">
                            <DatePicker
                              oneTap
                              editable={false}
                              id="datepicker-1"
                              placeholder="From-"
                              format="yyyy.MM.dd"
                              block
                              appearance="subtle"
                              className="custom-datepicker"
                              value={startDate}
                              onChange={(date) => setStartDate(date)}
                              onChangeCalendarDate={(e) => {
                                setCurrentMonth(e);
                              }}
                              onNextMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onPrevMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onOpen={() => {
                                setIsCalenderOpen(true);
                                setCurrentMonth(
                                  startDate ? new Date(startDate) : new Date()
                                );
                                setDatePickerCheck(1);
                              }}
                              onClose={() => {
                                setIsCalenderOpen(false);
                                setCurrentMonth(
                                  startDate ? new Date(startDate) : new Date()
                                );
                                setDatePickerCheck("");
                              }}
                              disabledDate={(date) => {
                                const maxDate = new Date();
                                return date > maxDate;
                              }}
                            />
                          </div>
                          <div className="newDatePickerClass">
                            <DatePicker
                              oneTap
                              editable={false}
                              id="datepicker-2"
                              placeholder="To-"
                              format="yyyy.MM.dd"
                              block
                              appearance="subtle"
                              className="custom-datepicker"
                              onChange={(date) => setEndDate(date)}
                              onChangeCalendarDate={(e) => {
                                setCurrentMonth(e);
                              }}
                              value={endDate}
                              onNextMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onPrevMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onOpen={() => {
                                setIsCalenderOpen(true);
                                setCurrentMonth(
                                  endDate ? new Date(endDate) : new Date()
                                );
                                setDatePickerCheck(2);
                              }}
                              onClose={() => {
                                setIsCalenderOpen(false);
                                setDatePickerCheck("");
                                setCurrentMonth(
                                  endDate ? new Date(endDate) : new Date()
                                );
                              }}
                              disabledDate={(date) => {
                                const minDate = new Date(startDate);
                                const maxDate = new Date();
                                return date < minDate || date > maxDate;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setOffset(1);
                        getRefundList();
                      }}
                      className="hover:text-black ml-10 hover:bg-[#CCFF00] text-lg font-extrabold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                    >
                      {strings.SEARCH}
                    </button>
                  </div>
                </div>

                <div className="">
                  <div className="font-extrabold text-3xl mb-[25px]">
                    {strings.REFUND_REQUEST}
                  </div>
                  {refundListValue?.length == 0 && (
                    <p className="text-2xl font-extrabold text-[#444444] text-center my-4">
                      {strings.NO_RECORD_FOUND}
                    </p>
                  )}
                  {refundListValue?.length > 0 && (
                    <RefundTable
                      refundListValue={refundListValue}
                      setshowModal={setshowModal}
                      setactiveIndex={setactiveIndex}
                      activeIndex={activeIndex}
                      fetchRequestList={getRefundList}
                      setSortKey={setSortKey}
                      setOrderValue={setOrderValue}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}
                </div>
                {page && page > 1 ? (
                  <div className="w-full">
                    <div className="mt-3">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        forcePage={offset == 1 ? 0 : offset - 1}
                        pageCount={page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* <div className="bottom-14 right-10 fixed">
                  <div className="w-[90px] relative h-[90px] rounded-full bg-[#CCFF00]  flex justify-center items-center">
                    <img
                      src={messageIcon}
                      width="50"
                      height="50"
                      onClick={() => {
                        setMessageScreen(!messageScreen);
                      }}
                    />
                    {messageScreen && (
                      <ChatScreen
                        opneMessage={setChatScreen}
                        onClose={setMessageScreen}
                        openModal={messageScreen}
                      />
                    )}
                  </div>
                </div>*/}
                {showModal && (
                  <RefundModal
                    id={activeIndex}
                    setshowModal={setshowModal}
                    setactiveIndex={setactiveIndex}
                    setIsLoader={setIsLoader}
                    classNameValue={`${
                      showModal ? "block_notify" : "hideNotify"
                    }`}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default RefundRequest;

const statusList = [
  { text: "All", key: "" },
  { text: "Requesting", key: 0 },
  { text: "On going", key: 3 },
  { text: "Finished", key: 2 },
];

function MonthFilter({ setMonthValue }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] w-[250px] right-4 top-12 max-h-[250px] overflow-auto z-20 px-3">
      <div
        onClick={() => {
          setMonthValue({ text: "All", value: "" });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      {PRODUCT_TYPES_LIST.map((val, index) => (
        <div
          key={index}
          onClick={() => {
            setMonthValue({ text: val, value: index });
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
        >
          <span className="mb-0 text-lg font-bold">{val}</span>
        </div>
      ))}
    </div>
  );
}

function TypeFilter({ setTypeValue }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-12 max-h-[250px] overflow-auto z-20 px-3">
      {statusList?.map((val, index) => (
        <div
          key={index}
          onClick={() => {
            setTypeValue({ text: val.text, value: val.key });
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
        >
          <span className="mb-0 text-lg font-bold">{val.text}</span>
        </div>
      ))}
    </div>
  );
}
