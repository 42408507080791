import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import ReactDatePicker from "react-datepicker";
import BoardSublyApi from "../../../helper/BoardApi";
import { useSelector } from "react-redux";
import GymSettingSublyApi from "../../../helper/GymSettingApi";
import Loader from "../../../utils/Loader";
import profile from "../../../assests/images/User-60.svg";
import emptyImg from "../../../assests/images/gallery.png";
import "../../../assests/CommonStyle.css";
import dumbelIcon from "../../../assests/icons/dumbel-Icon.svg";
import status from "../../../assests/icons/status-icon.svg";
import moment from "moment";
import hireIcon from "../../../assests/icons/hire-icon.svg";
import employe from "../../../assests/icons/employed-icon.svg";
import { extractUrlsToArray } from "../../../utils/commonUtils";
import { Tooltip } from "react-tooltip";

const historyData = [
  {
    title: "NCAS Trainer 1st",
  },
  {
    title: "NTT Nutrition 2nd",
  },
  {
    title: "Hankook university, sports – body building",
  },
];

function StaffInformation({
  setRegisterModal,
  openEdit,
  closeMOdal,
  staffId,
  getStaffData,
  setStaffDataValue,
  setIsLoader,
  setStaffId,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState("");
  const [requestedData, setRequestedData] = useState("");
  const [staffData, setStaffData] = useState();
  const [contentData, setContentData] = useState("");

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  // ===============function for handling remove selected media=============
  async function removeProduct(url, index) {
    const images = [...imagePreview];
    let toDeploy = [...requestedData];
    images.splice(index, 1);
    setImagePreview(images);
    toDeploy.splice(index, 1);
    setRequestedData(toDeploy);
  }
  useEffect(() => {
    staffDetails();
    getStaffData();
  }, []);
  const [mainProfile, ...restImages] = extractUrlsToArray(
    staffData?.staffDetails
  );
  async function staffDetails() {
    setIsLoader(true);
    await GymSettingSublyApi.getStaffDetails(token, staffId)
      .then((response) => {
        setIsLoader(false);
        setStaffData(response.data);
      })
      .catch((error) => console.log(error));
  }

  console.log("contentData", contentData);

  return (
    <section
      className={` ${`absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20`}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-lg:w-[90%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[80%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.STAFF_INFORMATION}
            </p>
          </div>

          <div className="grid justify-center items-center border-b-[1px] border-[#505050] pb-3">
            {!mainProfile ? (
              <img
                src={profile}
                className="mx-auto my-3 rounded-full w-[83px] h-[83px] object-cover"
              />
            ) : (
              <img
                src={mainProfile?.compressed || mainProfile?.original}
                className="mx-auto my-3 rounded-full w-[83px] h-[83px] object-cover"
              />
            )}
            <p className="text-2xl font-extrabold	text-center">
              {staffData?.staffDetails?.staffName}{" "}
              {`(#${staffData?.staffDetails?.userCode})`}
            </p>
            <p className="text-base text-[16px] font-bold text-center border-b-[1px] border-[#505050]  pb-2">
              {strings.NICK_NAME}:{" "}
              <span className="text-xl text-[20px] font-bold">
                {" "}
                {staffData?.staffDetails?.staffNickName}
              </span>
            </p>
            <div className="flex gap-2">
              <div className="flex items-center  gap-2">
                <Icon
                  icon="ph:phone-light"
                  width="24"
                  height="24"
                  style={{ color: "#CCFF00" }}
                />
                <p className="text-xl text-[20px]	font-extrabold !mb-0">
                  {" "}
                  {staffData?.staffDetails?.cellphone}
                </p>
              </div>
              <div className="flex items-center  gap-2 border-l-[1px] border-[#5A5858] pl-2">
                <Icon
                  icon="lets-icons:message-light"
                  width="24"
                  height="24"
                  style={{ color: "#CCFF00" }}
                />
                <p className="text-xl	text-[20px] font-extrabold !mb-0">
                  {staffData?.staffDetails?.email}
                </p>
              </div>
            </div>
            <div className="flex item-center justify-center my-2 bg-[#414142] p-2 rounded-[10px] px-2 ">
              <div className="flex items-center gap-2">
                <Icon
                  icon="uit:bag"
                  width="24"
                  height="24"
                  style={{ color: "white" }}
                />
                <p className="text-lg font-bold text-[18px] !mb-0">
                  {strings.JOB}:
                </p>
                <p className="text-xl text-[20px] font-extrabold !mb-0 pr-2 capitalize mt-0">
                  {" "}
                  {staffData?.staffDetails?.staffTypeValue}
                </p>
              </div>
              <div className="flex gap-2 items-center border-l-[1px] border-[#5A5858] pl-2">
                <img width="24" height="24" src={hireIcon} alt="icon" />
                <p className="text-lg text-[18px] font-bold !mb-0 ">
                  {strings.HIRE_TYPE}:
                </p>
                <p className="text-cl text-[20px] font-bold !mb-0 capitalize mt-0">
                  {staffData?.staffDetails?.hireValue}
                </p>
              </div>
            </div>
          </div>
          {staffData?.workingHours && (
            <>
              <div className="flex gap-2 item-center my-3">
                <Icon
                  icon="ri:time-line"
                  width="24"
                  height="24"
                  style={{ color: "#CBFE00" }}
                />
                <p className="text-xl text-[20px] font-bold">Working Time</p>
              </div>
              <div className="border-b-[1px] border-[#505050] pb-3">
                <WorkingDay workingData={staffData?.workingHours} />
              </div>
            </>
          )}
          {staffData?.history?.length > 0 && (
            <>
              {" "}
              <div className="flex gap-2 item-center my-3">
                <Icon
                  icon="material-symbols:history"
                  width="24"
                  height="24"
                  style={{ color: "#CBFE00" }}
                />
                <p className="text-xl text-[20px] font-bold">History </p>
              </div>
              <div className="border-b-[1px] border-[#505050] pb-3">
                {staffData?.history?.map((item) => {
                  return (
                    <div
                      data-tooltip-id="my-tooltip"
                      onMouseOverCapture={() => {
                        setContentData(item.content);
                      }}
                      className="flex bg-[#414142] rounded-[10px] items-center my-2 cursor-pointer w-max pl-2 pr-4 py-1"
                    >
                      <Icon
                        icon="radix-icons:dot-filled"
                        width="18"
                        height="18"
                        style={{ color: "white" }}
                      />
                      <p className="!mb-0 text-lg text-[18px] font-bold ">
                        {" "}
                        {item.title}{" "}
                      </p>
                    </div>
                  );
                })}
                {contentData && (
                  <Tooltip
                    id="my-tooltip"
                    className="bg-[#5C5C5C] rounded-[10px]"
                    place="bottom-start"
                    opacity="1"
                    // isOpen={true}
                    classNameArrow="arrowClass"
                  >
                    <div className="bg-[#5C5C5C]">
                      <p className="text-base font-medium max-w-[320px] text-start mb-2">
                        {contentData}
                      </p>
                    </div>
                  </Tooltip>
                )}
              </div>
            </>
          )}
          <div className="bg-[#414142] p-3 rounded-[10px] my-3">
            <p className="text-[18px]">
              I can you make better now. Can we going up to best! Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat. Nemo enim ipsam voluptatem quia voluptas
              sit aspernatur aut odit aut fugit.
            </p>
          </div>
          <div className="flex gap-3 border-b-[1px] pb-3 border-[#505050]">
            {restImages?.length
              ? restImages?.map((data) => {
                  return (
                    <div className="bg-[#949494] w-44 h-44 rounded-[10px] flex items-center justify-center">
                      <img
                        src={data?.compressed || data?.compressed}
                        alt="data"
                        className="w-44 h-44 rounded-[10px]  object-cover"
                      />
                    </div>
                  );
                })
              : ""}
          </div>
          <div className="flex gap-2 item-center my-3">
            <img src={status} alt="status" />
            <p className="text-xl font-bold text-[20px] !mb-0">Status</p>
          </div>
          <div className="flex gap-2  bg-[#414142] items-center py-2 px-4 rounded-[10px] w-max">
            <p className="text-lg text-[18px] !mb-0">
              {strings.CURRENT_STATUS}
            </p>
            <img src={employe} alt="employee" />
            <p className="text-lg text-[18px] font-bold !mb-0">
              {staffData?.currentStatusValue}
            </p>
          </div>
          <div className="flex justify-center items-center mb-4 mt-5">
            <button
              className="border-[#CCFF00] rounded-[30px] border-[1px] px-5 py-2.5  hover:bg-common_color hover:text-black text-white disabled:bg-[#ccff0094] "
              onClick={() => {
                closeMOdal(false);
                setStaffDataValue("");
                setStaffId("");
              }}
            >
              <sapn className=" text-2xl	font-extrabold !mb-0 ">
                {strings.CLOSE}
              </sapn>
            </button>
            <button
              className="bg-[#CCFF00] rounded-[30px] px-5 py-2.5 text-2xl	font-extra-bold ml-2"
              onClick={() => {
                setRegisterModal(true);
                closeMOdal(false);
                openEdit(true);
              }}
            >
              <p className="text-black text-2xl	font-extrabold !mb-0">
                {strings.EDIT}
              </p>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StaffInformation;

function WorkingDay({ workingData }) {
  if (!workingData || typeof workingData !== "object") {
    return null;
  }

  return (
    <div className="flex gap-2 items-center flex-wrap">
      {Object.keys(workingData).map((time) => {
        const timeData = workingData[time];
        const isHoliday = timeData[0]?.isHoliday;
        const hasTime = timeData.some((val) => val.startTime && val.endTime);

        if (!hasTime) return null;

        return (
          <div
            key={time}
            className={`bg-[#414142] p-3 rounded-[10px] text-[18px] ${
              isHoliday ? "border-[#FF9191] border-[1px]" : ""
            }`}
          >
            <p
              className={`text-lg text-[18px] capitalize ${
                isHoliday ? "text-[#C85E5E]" : "text-white"
              }`}
            >
              {time}
            </p>
            {timeData.map((val, index) =>
              !val.isHoliday && val.startTime && val.endTime ? (
                <div key={index} className="flex gap-2 items-center">
                  <Icon
                    icon="ri:time-line"
                    width="24"
                    height="24"
                    style={{ color: "white" }}
                  />
                  <p className="text-lg text-[18px] font-bold !mb-0">
                    {moment(val.startTime, "hh:mm A").format("ha")}-
                    {moment(val.endTime, "hh:mm A").format("ha")}
                  </p>
                </div>
              ) : null
            )}

            {isHoliday && (
              <div className="flex gap-2 items-center mt-2">
                <img width={24} height={24} src={dumbelIcon} alt="dumbbell" />
                <p className="text-[#FF5C5C] text-lg font-bold !mb-0">
                  {strings.HOLIDAY}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
