import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import ReactDatePicker from "react-datepicker";
import MembersSublyApi from "../../../helper/MemberApi";
import { useSelector } from "react-redux";
import moment from "moment";
import $ from "jquery";
import { HiCalendar } from "react-icons/hi2";
import { DatePicker } from "rsuite";

function HoldingModal({
  classNameValue,
  holdingList,
  setHoldingList,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  memberProductId,
  clickBtn,
  leftDays,
}) {
  const { token } = useSelector((state) => state.user);
  const [holdingValue, setHoldingValue] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  const [maxHolding, setMaxHolding] = useState();

  // ===============API for fetching holding list=================
  useEffect(() => {
    holdingList && getHoldingList();
  }, [holdingList]);
  async function getHoldingList() {
    setIsLoader(true);
    await MembersSublyApi.productHoldingList(
      token,
      memberProductId.memberId,
      memberProductId.productId
    ).then((response) => {
      if (response.statusCode == 200) {
        setHoldingValue(response.data);
        setIsLoader(false);
        setMaxHolding(response?.data?.maximumPauseDays);
      }
    });
  }

  // =============Get date of maximun days==============
  const resultDate = new Date().setDate(
    new Date(startDate).getDate() + holdingValue?.maximumPauseDays
  );

  // ===============API for delete holding request or data==============
  async function deleteHolding(id) {
    await MembersSublyApi.deleteProductHolding(token, id)
      .then((response) => {
        if (response.statusCode == 200) {
          getHoldingList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }

    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass classForMargin"}`}
        show={holdingList}
        onHide={() => {
          setHoldingList(false);
          setStartDate("");
          setEndDate("");
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          {isLoader && (
            <span className="absolute w-full h-full flex  mt-5 items-start justify-center">
              <Icon
                icon="eos-icons:bubble-loading"
                width="60"
                height="60"
                style={{ color: "#CCFF00" }}
                className="loader-icon "
              />
            </span>
          )}
          <p className="border-b-2 border-[#444444] pb-3 mb-0 text-[40px] max-lg:text-[28px] font-extrabold">
            {strings.HOLDING}{" "}
            <span className="text-lg font-bold text-white">{`( ${holdingValue?.holdingLeft} Holding | ${holdingValue?.maximumPauseDays} days ${strings.LEFT} )`}</span>
          </p>

          <div className="w-[70%] m-auto max-lg:w-full">
            {holdingValue?.maximumPauseDays > 0 &&
              holdingValue?.holdingLeft > 0 && (
                <div className="flex items-center gap-6 pt-8 pb-6">
                  <div>
                    <p className="rounded-full flex items-center justify-center text-xl max-lg:text-[18px] font-extrabold mb-0">
                      {`${strings.DATE}`}
                    </p>
                  </div>
                  <div className="flex items-center gap-3 text-[#8D8D8D]">
                    <label
                      htmlFor="date"
                      className="bg-[#1F2022] w-full h-[50px] px-3 flex items-center gap-2 rounded-[10px] newDatePickerClass modalDatePicker"
                    >
                      <Icon
                        icon="lets-icons:date-today"
                        width="22"
                        height="22"
                        style={{ color: "#CCFF00" }}
                      />
                      <DatePicker
                        oneTap
                        caretAs={HiCalendar}
                        id="datepicker-1"
                        placeholder={strings.START_DATE}
                        format="yyyy.MM.dd"
                        block
                        editable={false}
                        appearance="subtle"
                        className="custom-datepicker bg-transparent border-0 w-full px-0"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        onChangeCalendarDate={(e) => {
                          setCurrentMonth(e);
                        }}
                        onNextMonth={(e) => {
                          setCurrentMonth(e);
                        }}
                        onPrevMonth={(e) => {
                          setCurrentMonth(e);
                        }}
                        onOpen={() => {
                          setIsCalenderOpen(true);
                          setCurrentMonth(
                            new Date(startDate ? startDate : new Date())
                          );
                          setDatePickerCheck(1);
                        }}
                        onClose={() => {
                          setIsCalenderOpen(false);
                          setCurrentMonth(
                            new Date(startDate ? startDate : new Date())
                          );
                          setDatePickerCheck("");
                        }}
                        disabledDate={(date) => {
                          const maxDate = new Date(
                            Date.now() - 24 * 60 * 60 * 1000
                          );
                          return date < maxDate;
                        }}
                      />
                    </label>
                    <span className="text-[20px] font-bold max-lg:text-[18px]">
                      to
                    </span>
                    <label
                      htmlFor="date"
                      className="bg-[#1F2022] w-full h-[50px] px-3 flex items-center gap-2 rounded-[10px] newDatePickerClass modalDatePicker"
                    >
                      <Icon
                        icon="lets-icons:date-today"
                        width="22"
                        height="22"
                        style={{ color: "#CCFF00" }}
                      />
                      <DatePicker
                        oneTap
                        caretAs={HiCalendar}
                        id="datepicker-2"
                        placeholder={strings.END_DATE}
                        format="yyyy.MM.dd"
                        block
                        editable={false}
                        appearance="subtle"
                        className="custom-datepicker bg-transparent border-0 w-full px-0"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        onChangeCalendarDate={(e) => {
                          setCurrentMonth(e);
                        }}
                        onNextMonth={(e) => {
                          setCurrentMonth(e);
                        }}
                        onPrevMonth={(e) => {
                          setCurrentMonth(e);
                        }}
                        onOpen={() => {
                          setIsCalenderOpen(true);
                          setCurrentMonth(
                            new Date(endDate ? endDate : new Date())
                          );
                          setDatePickerCheck(2);
                        }}
                        onClose={() => {
                          setIsCalenderOpen(false);
                          setCurrentMonth(
                            new Date(endDate ? endDate : new Date())
                          );
                          setDatePickerCheck("");
                        }}
                        disabledDate={(date) => {
                          const minDate = new Date(startDate);
                          const maxDate = new Date(
                            minDate.getTime() + maxHolding * 24 * 60 * 60 * 1000
                          );

                          return date < minDate || date > maxDate;
                        }}
                        disabled={startDate ? false : true}
                      />
                    </label>
                  </div>
                </div>
              )}
            {holdingValue?.holdingLeft == 0 && (
              <p className="!mb-0 mt-3  text-[25px] max-lg:text-[28px] font-extrabold text-[#C25E5F]">
                {" "}
                {strings.DO_NOT_HAVE_DAYS_FOR_HOLDING}
              </p>
            )}
            <div>
              <div className="list-decimal mb-3 pt-1.5">
                {holdingValue?.holdingRequests?.length > 0 &&
                  holdingValue?.holdingRequests?.map((item, index) =>
                    item.status !== 3 ? (
                      <div
                        key={index}
                        className="text-[20px] max-lg:text-[16px] font-bold mt-2.5"
                      >
                        <p className="mb-0 text-start flex items-center justify-between">
                          {index + 1}.{" "}
                          {moment(item.startDate).format("YYYY.MM.DD")}~
                          {moment(item.endDate).format("YYYY.MM.DD")} holding
                          <span
                            onClick={() => {
                              deleteHolding(item?.id);
                            }}
                            className="w-[36px] h-[30px] bg-[#505050] text-sm font-semibold rounded-[4px] flex items-center justify-center cursor-pointer"
                          >
                            <Icon
                              icon="material-symbols:delete-outline"
                              width="23"
                              height="23"
                              className="text-[#fff] hover:text-[#CCFF00] cursor-pointer"
                            />
                          </span>
                        </p>
                      </div>
                    ) : (
                      <del
                        key={index}
                        className="text-[20px] max-lg:text-[16px] text-[#C25E5F] font-bold"
                      >
                        <p className="mb-0 text-start text-[#C25E5F] flex items-center justify-between mt-2.5">
                          {index + 1}.{" "}
                          {moment(item.startDate).format("YYYY.MM.DD")}~
                          {moment(item.endDate).format("YYYY.MM.DD")} holding
                        </p>
                      </del>
                    )
                  )}
              </div>
            </div>
          </div>
          <div className="flex justify-center pt-8 gap-4">
            {holdingValue?.holdingLeft == 0 ? (
              <button
                onClick={() => {
                  setHoldingList(false);
                  setStartDate("");
                  setEndDate("");
                }}
                className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.CLOSE}
              </button>
            ) : (
              <button
                disabled={startDate && endDate ? false : true}
                onClick={() => {
                  clickBtn();
                }}
                className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px] disabled:bg-[#ccff0094]"
              >
                {strings.UPDATE}
              </button>
            )}

            {/* {leftDays && (
             
            )} */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HoldingModal;
