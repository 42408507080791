import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../../utils/Loader";
import { placeholder, strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import ReactDatePicker from "react-datepicker";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";
import moment from "moment";
import $ from "jquery";
import { HiCalendar } from "react-icons/hi2";
import { DatePicker } from "rsuite";

function RegisterHoliday({
  onClose,
  setIsLoader,
  edit,
  onSuccess,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState("");
  const [duration, setDuration] = useState({ str: "", val: 0 });
  const [selectJob, setSelectJob] = useState(false);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openDuration, setOpenDuration] = useState(false);
  const [name, setName] = useState("");
  const [selectType, setSelectType] = useState(false);
  const [typeText, setTpyeText] = useState({ str: "", val: 0 });
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [openCalender, setOpenCalender] = useState(false);
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");

  async function holidays() {
    setIsLoader(true);
    const holidayData = {
      date: startDate,
      name: name,
      description: description,
      endDate: endDate,
      type: typeText.str,
      duration: duration.str,
    };
    await GymSettingSublyApi.postHoliday(token, holidayData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          onClose(false);
          onSuccess(holidayData);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setSelectType(false);
      setOpenDuration(false);
    }
  });

  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonth).getMonth();
    return condition;
  };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={` ${classNameValue} ${
          imagePreview.length > 0 ? "max-h-[88%] mt-16" : "max-h-[82%] mt-20"
        } ${"bg-[#353638] rounded-[10px] w-[70%]  max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[80%] m-auto border-b border-[#505050]">
            {
              <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
                {strings.REGISTER_HOLIDAYS}
              </p>
            }
          </div>
          <div className="pt-8 pb-3 w-[95%] m-auto">
            <div className="flex items-center gap-3 max-lg:flex-wrap my-2 ">
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.NAME}:
                </p>
                <label className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 w-full">
                  <input
                    type="text"
                    className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white font-bold w-full placeholder:font-normal"
                    id="nameId"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder={placeholder.ENTER_NAME}
                  />
                  <Icon
                    icon="fluent:search-12-filled"
                    width="23"
                    height="23"
                    style={{ color: "#D9D9D9" }}
                  />
                </label>
              </div>
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.TYPE}:
                </p>
                <label className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 w-full relative dropdownClass">
                  <input
                    type="text"
                    className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full caret-transparent cursor-pointer font-bold placeholder:font-normal "
                    id="nameId"
                    autoComplete="off"
                    value={typeText.str}
                    placeholder={"Select holiday type"}
                    onClick={() => {
                      setSelectType(!selectType);
                      setOpenDuration(false);
                    }}
                  />
                  <Icon
                    icon="flowbite:caret-down-solid"
                    width="20"
                    height="20"
                    style={{ color: "#BEBEBE" }}
                    rotate={selectType ? 2 : 4}
                  />
                  {selectType && <TypeDropDown setTpyeText={setTpyeText} />}
                </label>
              </div>
            </div>
            <div className="flex items-center gap-3 max-lg:flex-wrap my-2">
              <div className="w-full flex gap-3">
                <div className="w-full">
                  <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                    {strings.START_DATE}:
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 newDateInputClass">
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-1"
                      placeholder={strings.SELECT_DATE}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setIsCalenderOpen(true);
                        setDatePickerCheck(1);
                        setCurrentMonth(
                          startDate ? new Date(startDate) : new Date()
                        );
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          startDate ? new Date(startDate) : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < maxDate;
                      }}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                    {strings.END_DATE}:
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 newDateInputClass">
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-1"
                      placeholder={strings.SELECT_DATE}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setIsCalenderOpen(true);
                        setDatePickerCheck(1);
                        setCurrentMonth(
                          startDate ? new Date(startDate) : new Date()
                        );
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          startDate ? new Date(startDate) : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < maxDate;
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.DURATION}:
                </p>
                <div
                  onClick={() => {
                    setOpenDuration(!openDuration);
                    setSelectType(false);
                  }}
                  className="flex items-center gap-2 cursor-pointer justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative dropdownClass"
                >
                  <input
                    type="text"
                    className="bg-[#414142] rounded-[10px] cursor-pointer h-[45px] px-2 text-white w-full caret-transparent font-bold placeholder:font-normal "
                    id="nameId"
                    autoComplete="off"
                    placeholder={"Duration"}
                    value={duration.str}
                    onChange={(e) => {
                      setDuration(e.target.value);
                    }}
                  />
                  <Icon
                    icon="flowbite:caret-down-solid"
                    width="20"
                    height="20"
                    style={{ color: "#BEBEBE" }}
                    rotate={openDuration ? 2 : 4}
                  />
                  {openDuration && (
                    <HolidayDuration setDuration={setDuration} />
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3 max-lg:flex-wrap my-2">
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.DESCRIPTION}:
                </p>
                <div className="  dateInputClass">
                  <textarea
                    placeholder="Description"
                    className="bg-[#414142] rounded-[10px]  p-2 text-white w-full h-48 font-bold placeholder:font-normal "
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={` ${"flex-wrap flex items-center justify-center gap-3 mt-6"}`}
            >
              <button
                onClick={() => {
                  onClose(false);
                }}
                className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] sm:h-[60px] disabled:bg-[#ccff0094] py-0.5"
              >
                {strings.CLOSE}
              </button>

              <button
                className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black kakaoClass hover:text-black bg-common_color w-[160px] sm:h-[60px] disabled:bg-[#ccff0094] py-0.5"
                onClick={() => {
                  holidays();
                }}
                disabled={
                  name && description && startDate && typeText && duration
                    ? false
                    : true
                }
              >
                {strings.REGISTER}
              </button>
              {/* <button
                onClick={() => {
                  onClose(false);
                }}
                className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] sm:h-[60px] disabled:bg-[#ccff0094] py-0.5"
              >
                {strings.CLOSE}
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default RegisterHoliday;

function TypeDropDown({ setTpyeText }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[350px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setTpyeText({ str: strings.NATIONAL_PUBLIC_HOLIDAYS, val: 0 });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">
          {strings.NATIONAL_PUBLIC_HOLIDAYS}
        </span>
      </div>
      <div
        onClick={() => {
          setTpyeText({ str: strings.TRADITIONAL_HOLIDAYS, val: 1 });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">
          {strings.TRADITIONAL_HOLIDAYS}
        </span>
      </div>
      <div
        onClick={() => {
          setTpyeText({ str: strings.CENTER_CLOSURE_DAYS, val: 2 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">
          {strings.CENTER_CLOSURE_DAYS}
        </span>
      </div>
    </div>
  );
}
function HolidayDuration({ setDuration }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[350px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setDuration({ str: strings.ALL_DAY, val: 0 });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ALL_DAY}</span>
      </div>
      <div
        onClick={() => {
          setDuration({ str: strings.AM, val: 1 });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.AM}</span>
      </div>
      <div
        onClick={() => {
          setDuration({ str: strings.PM, val: 2 });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.PM}</span>
      </div>
    </div>
  );
}
