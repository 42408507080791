import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import moment from "moment";
import trainerBlack from "../../../assests/icons/trainerIcon-black.svg";
import trainer from "../../../assests/icons/boldTrainer.svg";
import StatusMemberTable from "./StatusMemberTable";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import ReactPaginate from "react-paginate";
import StatusTrainerTable from "./StatusTrainerTable";
import PtSessionHistory from "./StatusPT_SessionHistory";
import SentMessageList from "./SentMessageLIst";
import trainerWhite from "../../../assests/icons/trainerIcon.svg";
import SentMessage from "../../commonComponents/modalScreens/SentMessageModal";
import $ from "jquery";
import WorkoutLogDetail from "./WorkoutLogDetail";

function StatusScreen() {
  const { token } = useSelector((state) => state.user);
  const [selectType, setSelectType] = useState(true);
  const [memberList, setMemberList] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [active, setActive] = useState("active");
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [activeTab, setActiveTab] = useState(null);
  const [type, setType] = useState(false);
  const [memberCount, setMemberCount] = useState("");
  const [sorting, setSorting] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [trainerList, setTrainerList] = useState("");
  const [ptHistory, setPtHistory] = useState(false);
  const [trainerId, setTrainerId] = useState("");
  const [trainerPage, setTrainerPage] = useState("");
  const [messageList, setMessageList] = useState(false);
  const [memberId, setmemberId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [sentItem, setSentItem] = useState("");
  const [logId, setLogId] = useState({
    id: "",
  });
  const [openDetails, setOpenDetails] = useState(false);

  // ============API for fetch status member list============
  useEffect(() => {
    statusMemberHandle(undefined);
  }, [offset, type]);
  async function statusMemberHandle(sort, page) {
    setIsLoader(true);
    const requestData = sort
      ? { sort: sort, search: searchKey, isCompleted: type }
      : { sort: sorting, search: searchKey, isCompleted: type };
    await PT_InformationSublyApi.statusMemberList(
      token,
      requestData,
      page ? page : offset
    ).then((response) => {
      setIsLoader(false);
      if (response.statusCode == 200) {
        setMemberList(response.data.memberList);
        setPage(response.data?.count / 20);
        page && setOffset(1);
        setMemberCount(response?.data?.memberCounts);
      }
    });
  }

  // ============API for fetch status trainer list============
  useEffect(() => {
    statusTrainerHandle();
  }, [offset, type]);
  async function statusTrainerHandle(page) {
    setIsLoader(true);
    const requestData = { search: searchKey, isCompleted: type };
    await PT_InformationSublyApi.statusTrainerList(
      token,
      requestData,
      page ? page : offset
    ).then((response) => {
      setIsLoader(false);
      if (response.statusCode == 200) {
        setTrainerList(response.data?.trainerList);
        setTrainerPage(response.data?.count / 2);
        page && setOffset(1);
      }
    });
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0
    ) {
      if (openDetails) {
        setOpenDetails(false);
        setPtHistory(true);
      } else {
        setPtHistory(false);
      }
      setMessageList(false);
      setTrainerId("");
    }
  });


  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`${
              messageList || ptHistory
                ? "!overflow-hidden h-screen"
                : "!overflow-auto"
            } ${"p-0 relative"}`}
          >
            <Header text={strings.PT_INFORMATION_STATUS} />
            <div className="mx-6 ">
              <div className="flex items-center gap-x-3 gap-y-3 flex-wrap my-4 justify-between">
                <div className="flex items-center gap-x-4 gap-y-3 flex-wrap">
                  <div className="flex items-center gap-x-4 gap-y-3 flex-wrap">
                    <div className="flex items-center gap-2">
                      <Icon
                        icon="lets-icons:date-today"
                        width="25"
                        height="25"
                        style={{ color: "#CCFF00" }}
                      />
                      <p className="mb-0 text-[20px] font-bold">
                        {strings.TODAY}:{" "}
                        {moment(new Date()).format("YYYY.MM.DD")}
                      </p>
                    </div>
                    <div className=" flex items-center justify-center gap-1 rounded-[29.5px] bg-[#353638] kakaoClass h-[50px] w-[320px] max-[470px]:w-full">
                      <div
                        onClick={() => {
                          setSelectType(true);
                          setActiveTab(null);
                          setSearchKey("");
                          setOffset(1);
                          statusTrainerHandle();
                        }}
                        className={`${
                          selectType
                            ? "rounded-[29.5px] bg-[#CCFF00] !text-black h-full"
                            : "!text-white"
                        } ${"flex items-center gap-2 w-1/2 justify-center px-3 cursor-pointer"}`}
                      >
                        {selectType ? (
                          <img
                            src={trainerBlack}
                            className="w-[25px] h-[27px]"
                          />
                        ) : (
                          <img src={trainer} className="w-[25px] h-[27px]" />
                        )}
                        <p
                          className={`${
                            selectType ? "!text-black" : "!text-white"
                          } ${"mb-0 text-[18px] font-extrabold"}`}
                        >
                          {strings.TRAINERS}
                        </p>
                      </div>{" "}
                      <div
                        onClick={() => {
                          setSelectType(false);
                          statusMemberHandle(undefined, 1);
                        }}
                        className={`${
                          !selectType
                            ? "rounded-[29.5px] bg-[#CCFF00] !text-black h-full"
                            : "!text-white"
                        } ${"flex items-center gap-2 w-1/2 justify-center px-3 cursor-pointer"}`}
                      >
                        {!selectType ? (
                          <Icon
                            icon="heroicons:user-16-solid"
                            width="28"
                            height="28"
                            style={{ color: "black" }}
                          />
                        ) : (
                          <Icon
                            icon="heroicons:user-16-solid"
                            width="28"
                            height="28"
                            style={{ color: "#CCFF00" }}
                          />
                        )}
                        <p
                          className={`${
                            !selectType ? "!text-black" : "!text-white"
                          } ${"mb-0 text-[18px] font-extrabold"}`}
                        >
                          {strings.MEMBER}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-3 gap-y-3 flex-wrap">
                    <label className="relative bg-[#2C2C2E] w-[310px] text-[#fff] font-bold text-lg max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                      {!selectType ? (
                        <Icon
                          icon="fa6-regular:user"
                          width="20"
                          height="20"
                          style={{ color: "white" }}
                        />
                      ) : (
                        <img src={trainerWhite} className="w-[22px] h-[22px]" />
                      )}
                      <input
                        type="text"
                        placeholder={
                          selectType
                            ? placeholder.SEARCH_TRAINER
                            : strings.SEARCH_MEMBER
                        }
                        onChange={(e) => {
                          setSearchKey(e.target.value);
                        }}
                        className="w-full bg-transparent"
                      />
                    </label>
                    <button
                      onClick={() => {
                        if (!selectType) {
                          statusMemberHandle(undefined, 1);
                        } else {
                          statusTrainerHandle(1);
                        }
                      }}
                      className="text-black font-[Archivo] bg-[#CCFF00] text-lg font-bold h-[50px] w-[120px] rounded-[28px]"
                    >
                      {strings.SEARCH}
                    </button>
                  </div>
                </div>
                <div className="flex items-center gap-x-3 max-sm:flex-wrap gap-y-3">
                  <button
                    onClick={() => {
                      setType(false);
                      setOffset(1);
                    }}
                    className={`${
                      !type
                        ? "text-black bg-[#CCFF00]"
                        : "text-white kakaoClass"
                    } ${"text-base font-bold h-[50px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.CURRENT}
                  </button>
                  <button
                    onClick={() => {
                      setType(true);
                      setOffset(1);
                    }}
                    className={`${
                      type ? "text-black bg-[#CCFF00]" : "text-white kakaoClass"
                    } ${" text-base font-bold h-[50px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.COMPLETED}
                  </button>
                </div>
              </div>
              {(selectType
                ? trainerList?.length == 0
                : memberList?.length == 0) && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-10">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              <div>
                {selectType ? (
                  <>
                    {trainerList?.length > 0 &&
                      trainerList.map((item, index) => (
                        <StatusTrainerTable
                          item={item}
                          index={index}
                          setTrainerId={setTrainerId}
                          setPtHistory={setPtHistory}
                          trainerId={trainerId}
                          setmemberId={setmemberId}
                          setMessageList={setMessageList}
                        />
                      ))}
                  </>
                ) : (
                  memberList?.length !== 0 && (
                    <StatusMemberTable
                      memberList={memberList}
                      statusMemberHandle={statusMemberHandle}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      memberCount={memberCount}
                      setSorting={setSorting}
                      setMessageList={setMessageList}
                      setmemberId={setmemberId}
                    />
                  )
                )}
              </div>
              {((!selectType && page && page > 1) ||
                (selectType && trainerPage && trainerPage > 1)) && (
                <div className="mt-6 mb-6">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    forcePage={offset == 1 ? 0 : offset - 1}
                    pageCount={selectType ? trainerPage : page}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={active}
                    onClick={() => setActive("active")}
                  />
                </div>
              )}
            </div>
            {ptHistory && (
              <PtSessionHistory
                setPtHistory={setPtHistory}
                id={trainerId}
                setTrainerId={setTrainerId}
                setIsLoader={setIsLoader}
                classNameValue={`${ptHistory ? "block_notify" : "hideNotify"}`}
                setLogIdValue={setLogId}
                setOpenDetails={setOpenDetails}
                openDetails={openDetails}
              />
            )}
            {openDetails && (
              <WorkoutLogDetail
                setOpenDetails={setOpenDetails}
                logId={logId}
                status={true}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  openDetails ? "block_notify" : "hideNotify"
                }`}
                setPtHistory={setPtHistory}
              />
            )}
            {messageList && (
              <SentMessageList
                setMessageList={setMessageList}
                memberId={memberId}
                setIsLoader={setIsLoader}
                setOpenModal={setOpenModal}
                sentItem={sentItem}
                setSentItem={setSentItem}
                classNameValue={`${
                  messageList ? "block_notify" : "hideNotify"
                }`}
              />
            )}
          </Col>
        </Row>
      </Container>
      <SentMessage
        openModal={openModal}
        setOpenModal={setOpenModal}
        sentItem={sentItem}
        setSentItem={setSentItem}
        classNameValue={`${messageList ? "block_notify" : "hideNotify"}`}
      />
    </section>
  );
}

export default StatusScreen;
