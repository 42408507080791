import React from "react";
import unConfirmSession from "../../../assests/icons/statistics PT session/unconfirmSession.svg";
import confirmSession from "../../../assests/icons/statistics PT session/confirmSession.svg";
import avgDiet from "../../../assests/icons/statistics PT session/avgDiet.svg";
import avgWorkout from "../../../assests/icons/statistics PT session/avgWorkout.svg";
import remainSession from "../../../assests/icons/statistics PT session/remainSession.svg";
import star from "../../../assests/icons/statistics PT session/grayStar.svg";
import profile from "../../../assests/images/demoProfile.svg";
import demoProfile from "../../../assests/images/demoProfile.svg";
import StatisticCards from "../../commonComponents/StatisticCards";
import { Col, Row } from "react-bootstrap";
import male from "../../../assests/icons/maleUser.svg";
import female from "../../../assests/icons/femaleUser.svg";
import ageIcon from "../../../assests/icons/ageIcon.svg";
import { strings } from "../../../utils/String";

function PtTrainerScreen({
  item,
  index,
  setTrainerId,
  trainerGraph,
  setOpenGraph,
  setOpenAgeGraph,
  setAgeGraphValue,
  setGraphText,
}) {
  return (
    <div className="pt-3 pb-2.5" key={index}>
      <p className="text-[20px] font-bold mb-3 flex items-center gap-2">
        {" "}
        <img
          src={item.trainerDetails?.profile?.prof_img1_min || profile}
          alt="tariner"
          className="w-[55px] h-[55px] rounded-full"
        />
        {item.trainerDetails?.name}
      </p>
      <div className="gap-2 flex items-center flex-wrap pb-2 scrollingClass">
        <div className="min-w-[265px] max-sm:max-w-full max-sm:min-w-full max-w-[265px]">
          <StatisticCards
            isMember={true}
            image={unConfirmSession}
            unconfirmStyle={true}
            data={item.unConfirmed}
            subText={item.totalSessions}
            text={strings.UNCONFIRMED_SESSIONS}
            unconfirmSession={true}
            clickBtn={() => {
              trainerGraph("unconfirmedSessions", item?.trainerDetails?.id);
              setOpenGraph(true);
            }}
            setGraphText={setGraphText}
          />
        </div>
        <div className="min-w-[265px] max-sm:max-w-full max-sm:min-w-full max-w-[265px]">
          <StatisticCards
            isMember={true}
            image={confirmSession}
            data={item.completed}
            subText={item.totalSessions}
            text={strings.COMPLETED_SESSIONS}
            confirmSession={true}
            clickBtn={() => {
              trainerGraph("completedSessions", item?.trainerDetails?.id);
              setOpenGraph(true);
            }}
            setGraphText={setGraphText}
            // addStyle={true}
          />
        </div>
        <div className="min-w-[265px] max-sm:max-w-full max-sm:min-w-full max-w-[265px]">
          <StatisticCards
            isMember={true}
            image={male}
            // addStyle={true}
            data={
              (item.maleCount > 0 || item.malePercentage > 0) &&
              `${item.maleCount} / ${item.malePercentage}%`
            }
            text={strings.MALE}
            clickBtn={() => {
              trainerGraph("maleCount", item?.trainerDetails?.id);
              setOpenGraph(true);
            }}
            setGraphText={setGraphText}
          />
        </div>
        <div className="min-w-[265px] max-sm:max-w-full max-sm:min-w-full max-w-[265px]">
          <StatisticCards
            isMember={true}
            image={female}
            // addStyle={true}
            data={
              (item.femaleCount > 0 || item.femalePercentage > 0) &&
              `${item.femaleCount} / ${item.femalePercentage}%`
            }
            text={strings.FEMALE}
            clickBtn={() => {
              trainerGraph("femaleCount", item?.trainerDetails?.id);
              setOpenGraph(true);
            }}
            setGraphText={setGraphText}
          />
        </div>
        <div className="min-w-[265px] max-sm:max-w-full max-sm:min-w-full max-w-[265px]">
          <StatisticCards
            isMember={true}
            image={remainSession}
            // addStyle={true}
            data={`${item?.remainedSessions || 0}/${item?.totalSessions || 0}`}
            text={strings.REMAINED_SESSIONS}
            clickBtn={() => {
              trainerGraph("remainedSessions", item?.trainerDetails?.id);
              setOpenGraph(true);
            }}
            setGraphText={setGraphText}
          />
        </div>

        <div className="min-w-[265px] max-sm:max-w-full max-sm:min-w-full max-w-[265px]">
          <StatisticCards
            isMember={true}
            image={star}
            data={item?.avgPerformance}
            // addStyle={true}
            text={strings.AVERAGE_PERFORMANCE_SCORE}
            clickBtn={() => {
              trainerGraph("avgPerformance", item?.trainerDetails?.id);
              setOpenGraph(true);
            }}
            setGraphText={setGraphText}
          />
        </div>
        <div className="min-w-[265px] max-sm:max-w-full max-sm:min-w-full max-w-[265px]">
          <StatisticCards
            isMember={true}
            image={avgWorkout}
            data={`${item.avgWorkoutLog.toFixed(1)}`}
            text={strings.AVERAGE_WORKOUT_LOG_ENTRIES}
            subText={strings.WORKOUT_LOG}
            clickBtn={() => {
              trainerGraph("avgWorkoutLogs", item?.trainerDetails?.id);
              setOpenGraph(true);
            }}
            setGraphText={setGraphText}
          />
        </div>
        <div className="min-w-[265px] max-sm:max-w-full max-sm:min-w-full max-w-[265px]">
          <StatisticCards
            isMember={true}
            image={avgDiet}
            data={item.avgDietLog}
            text={strings.AVERAGE_DIET_LOG_ENTRIES}
            subText={strings.DIET_LOG}
            clickBtn={() => {
              trainerGraph("avgDietLogs", item?.trainerDetails?.id);
              setOpenGraph(true);
            }}
            setGraphText={setGraphText}
          />
        </div>
        <div className="min-w-[265px] max-sm:min-w-full ">
          <StatisticCards
            isMember={true}
            image={ageIcon}
            text={strings.AGE_DISTRIBUTION}
            data={item?.ageRange}
            isAge={true}
            clickBtn={() => {
              setTrainerId(item?.trainerDetails?.id);
              setOpenAgeGraph(true);
              setAgeGraphValue(item?.ageRange);
            }}
            setGraphText={setGraphText}
          />
        </div>
      </div>
    </div>
  );
}

export default PtTrainerScreen;
