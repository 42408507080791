import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import demoP from "../../../assests/images/demoProfile.svg";
import { useSelector } from "react-redux";
import ManagementSublyApi from "../../../helper/ManagementApi";
import moment from "moment";
import tickIcon from "../../../assests/icons/circule-tick-icon.svg";

function TarinerReqModal({
  setshowModal,
  setProductId,
  setIsLoader,
  productId,
  detailValue,
  setDetailValue,
  setEditModal,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);

  // =============API for fetching holding detail===============
  useEffect(() => {
    getHoldingDetails();
  }, []);
  async function getHoldingDetails() {
    setIsLoader(true);
    await ManagementSublyApi.changeTrainerDetail(token, productId)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setDetailValue(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ==============API for proceesing action================
  async function processActionHandle() {
    await ManagementSublyApi.requestProcessingHandle(token, productId)
      .then((response) => {
        if (response.statusCode == 200) {
          getHoldingDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // ==============API for holding and complete action================
  async function requestActionHandle(status) {
    await ManagementSublyApi.changeTrainerRequestHandle(
      token,
      productId,
      status
    )
      .then((response) => {
        if (response.statusCode == 200) {
          getHoldingDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[70%] max-xl:w-[90%] max-lg:w-[100%] m-auto ">
          <div className=" m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {detailValue?.resFeedbackTitle ||
                strings.REQUEST_FOR_CHANGING_TRAINER}
            </p>
          </div>

          <h3 className="font-extrabold text-[34px] text-center my-7">
            {detailValue?.memberInfo?.memberName}
          </h3>
          <div className="flex flex-wrap items-center justify-center gap-x-8 gap-y-3">
            <div className="flex justify-between items-center">
              <Icon
                icon="lets-icons:date-today"
                width="30"
                height="30"
                style={{ color: "#CCFF00" }}
              />
              <span className="text-2xl font-extrabold ms-2">
                {moment(detailValue?.requestDate).format("YYYY.MM.DD")}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <Icon
                icon="heroicons:clock"
                width="30px"
                height="30px"
                style={{ color: "#CCFF00" }}
              />

              <span className="text-2xl font-extrabold ms-2">
                {moment(detailValue?.requestDate).format("hh:mm")}{" "}
                <span className="text-lg font-bold">
                  {" "}
                  {moment(detailValue?.requestDate).format("A")}
                </span>{" "}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <Icon
                icon="lucide:eye"
                width="30px"
                height="30px"
                style={{ color: "#CCFF00" }}
              />
              <span className="text-2xl font-extrabold ms-2">
                {detailValue?.views}
              </span>
            </div>
          </div>
          <div
            className={`${
              detailValue.status !== 0 ? "border-b-4 border-[#414142]" : ""
            } ${"mt-7 pb-4"}`}
          >
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-extrabold text-[#FFFFFF]">
                  {strings.REQUESTED_BY_THE_MEMBER} :{" "}
                </p>
                <p className="mb-0 mt-0 flex items-center gap-2 text-2xl text-[#FFFFFFBF] font-bold">
                  {detailValue?.status == 3
                    ? strings.PROCESSING
                    : detailValue?.status == 1
                    ? strings.COMPLETED
                    : detailValue?.status == 0
                    ? strings.REQUESTING
                    : ""}{" "}
                  {detailValue?.nextTrainerName && (
                    <span className="font-bold text-2xl text-white">
                      ({detailValue?.nextTrainerName})
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex text-[#FFFFFF] max-sm:flex-wrap items-center gap-2 text-2xl font-bold">
                  PT session information :
                  <span className="font-bold text-2xl text-[#FFFFFFBF] text-white">
                    {detailValue?.sessionCompleted}
                    <span className="text-lg">
                      /{detailValue?.totalSession}
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-2xl font-bold text-[#FFFFFF]">
                  Left session counts :
                  <span className="font-bold text-2xl text-[#FFFFFFBF] text-white">
                    {" "}
                    {detailValue?.totalSession -
                      detailValue?.sessionCompleted}{" "}
                    session
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-2xl font-bold">
                  <span className="text-[#FFFFFF]"> Current Trainer :</span>
                  <span className="font-extrabold text-2xl">
                    {detailValue?.currentTrainer?.name}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-2xl font-bold">
                  <span className="text-[#FFFFFF"> Change trainer to: </span>
                  <p className="mb-0 flex items-center">
                    {" "}
                    {detailValue?.reqTrainers?.map((val, index) => (
                      <span className="font-bold text-2xl" key={index}>
                        {`${index !== 0 ? "," : ""}`} {val?.gymStaffName}
                      </span>
                    ))}
                  </p>
                </p>
              </div>
            </div>
          </div>
          {detailValue?.status !== 0 && (
            <>
              {" "}
              <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
                <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3    w-full h-full">
                  <div className="mb-0 flex items-center max-sm:flex-wrap gap-2 text-xl font-bold">
                    {detailValue?.status == 3 ? (
                      <p className="mb-0 flex items-center gap-2 text-xl font-bold">
                        <Icon
                          icon="tdesign:refresh"
                          width="22px"
                          height="22px"
                          style={{ color: "#E16B16" }}
                        />
                        Process date:
                      </p>
                    ) : (
                      <p className="mb-0 flex items-center gap-2 text-xl font-bold">
                        <img src={tickIcon} />
                        Completed date:
                      </p>
                    )}
                    <div className="font-extrabold text-2xl">
                      <div className="flex flex-wrap items-center justify-center gap-x-8 gap-y-3">
                        <div className="flex justify-between items-center">
                          <Icon
                            icon="lets-icons:date-today"
                            width="30"
                            height="30"
                            style={{ color: "white" }}
                          />

                          <span className="text-2xl font-extrabold ms-2">
                            {moment(detailValue?.resFeedbackDate).format(
                              "YYYY.MM.DD"
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <Icon
                            icon="heroicons:clock"
                            width="30px"
                            height="30px"
                            style={{ color: "white" }}
                          />

                          <span className="text-2xl font-extrabold ms-2">
                            {moment(detailValue?.resFeedbackDate).format(
                              "hh:mm"
                            )}{" "}
                            <span className="text-lg font-bold">
                              {" "}
                              {moment(detailValue?.resFeedbackDate).format("A")}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2.5 max-lg:flex-wrap pb-3">
                <div className="flex items-center gap-x-3  max-sm:flex-wrap rounded-[10px]    w-full h-full">
                  {detailValue?.status == 3 ? (
                    <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-xl font-bold">
                      <Icon
                        icon="tdesign:refresh"
                        width="22px"
                        height="22px"
                        style={{ color: "#E16B16" }}
                      />{" "}
                      Process staff:
                    </p>
                  ) : (
                    <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-xl font-bold">
                      <img src={tickIcon} />
                      {strings.COMPLETE_STAFF}:
                    </p>
                  )}
                  <div className="flex items-center">
                    <img
                      className="size-[34px] rounded-full"
                      src={
                        detailValue?.staffInfo?.mProfileImg
                          ? detailValue?.staffInfo?.mProfileImg.prof_img1_min
                          : demoP
                      }
                      alt=""
                    />
                    <span className="ms-2 text-xl font-bold">
                      {detailValue?.staffInfo?.name}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-4 mb-4"}`}
          >
            <button
              onClick={() => {
                setshowModal(false);
                setProductId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
            <button
              onClick={() => {
                setEditModal(true);
                setshowModal(false);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.EDIT}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TarinerReqModal;
