import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import profile from "../../../assests/images/demoProfile.svg";
import sessionCard from "../../../assests/icons/statistics PT session/pt-session icon colored.svg";
import { Icon } from "@iconify-icon/react";
import moment from "moment";
import ReactPaginate from "react-paginate";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { scrollToTopFun } from "../gymSettings/StaffSetting";

function PtSessionHistory({
  id,
  setTrainerId,
  setPtHistory,
  setIsLoader,
  classNameValue,
  setLogIdValue,
  setOpenDetails,
  openDetails,
}) {
  const { token } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState(null);
  const [page, setPage] = useState("");
  const [historyData, setHistoryData] = useState("");
  const [offset, setOffset] = useState(1);
  const [logId, setLogId] = useState("");
  const navigate = useNavigate();

  //   ==========API for fetching PR-session history===============
  useEffect(() => {
    fetchptSessionHistory();
  }, [offset]);

  async function fetchptSessionHistory(order, key) {
    setIsLoader(true);
    await PT_InformationSublyApi.ptSessionHistory(token, id, offset, order, key)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setPage(Math.ceil(response.data.count / 10));
          setHistoryData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  return (
    <section
      className={`${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[90%] max-h-[90%] py-3 px-7 mt-10 z-20 overflow-auto"}`}
      >
        <div className="w-full m-auto">
          <h2 className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold text-white font-[archivo]">
            {strings.PT_SESSION_HISTORY}
          </h2>
          <div className="text-center m-auto my-2">
            <p className="w-[60px] h-[60px] rounded-full m-auto">
              <img
                src={
                  historyData?.memberDetails?.mProfileImg?.prof_img1_min
                    ? historyData?.memberDetails?.mProfileImg?.prof_img1_min
                    : profile
                }
                className="rounded-full w-full h-full object-cover"
              />
            </p>
            <p className="mb-0 text-2xl font-[archivo] font-bold my-1 ">
              {historyData?.memberDetails?.name}{" "}
            </p>
            <div className="flex items-center justify-center gap-3 my-2">
              <img src={sessionCard} />
              <p className="mb-0 text-lg font-bold font-[archivo]">
                {strings.CAP_SESSION}:{" "}
                {historyData?.memberDetails?.sessionLeft >= 0
                  ? historyData?.memberDetails?.sessionLeft
                  : 0}{" "}
                {strings.LEFT}
              </p>
            </div>
            <button
              onClick={() => {
                navigate("/Member-search", {
                  state: { id: historyData?.memberDetails?.id },
                });
              }}
              className={`${"text-white kakaoClass bg-[#2C2C2E]"} ${"text-base font-bold h-[45px] w-[160px] rounded-[28px] my-1 font-[archivo]"}`}
            >
              {strings.MEMBER_INFO}
            </button>
            <div className="flex items-center gap-3 justify-center my-3 flex-wrap">
              <p className="mb-0 px-3 py-2 bg-[#414142] rounded-[10px] text-lg font-medium !font-[archivo]">
                {historyData?.memberDetails?.productName}
              </p>{" "}
              <p className="mb-0 px-3 py-2 bg-[#414142] rounded-[10px] text-lg font-medium !font-[archivo] mt-0">
                {historyData?.memberDetails?.sessionCount || 0} sessions
              </p>{" "}
              <p className="mb-0 px-3 py-2 bg-[#414142] rounded-[10px] flex items-center gap-2 text-lg font-medium !font-[archivo] mt-0">
                <Icon
                  icon="lets-icons:date-today"
                  width="22"
                  height="21"
                  style={{ color: "#CCFF00" }}
                />
                {moment(historyData?.memberDetails?.membershipStartDate).format(
                  "YY.MM.DD"
                )}
              </p>
            </div>
          </div>

          {historyData?.sessionData?.length == 0 && (
            <p className="text-2xl font-extrabold text-[#7e7e7e] text-center my-10">
              {strings.NO_RECORD_FOUND}
            </p>
          )}
          {historyData?.sessionData?.length > 0 && (
            <div className="overflow-auto scrollingClass my-4">
              <div className="max-2xl:w-[1400px] w-[95%] m-auto">
                <div className="flex justify-around items-center bg-[#474749] min-h-[50px] border-b border-[#353638]">
                  <th
                    onClick={() => {
                      setActiveTab(activeTab == 0 ? null : 0);
                      let sort =
                        activeTab == 0 ? ["name", "DESC"] : ["name", "ASC"];
                      fetchptSessionHistory(
                        activeTab == 0 ? "DESC" : "ASC",
                        "date"
                      );
                    }}
                    className={`${
                      activeTab == 0
                        ? "kakaoClass"
                        : "border-l-2 border-[#444444]"
                    } ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-3 py-1 w-[40%]  min-h-[50px] flex justify-around items-center"}`}
                  >
                    <p className="mb-0 flex items-center gap-2">
                      <Icon
                        icon="lets-icons:date-today"
                        width="22"
                        height="22"
                        style={{ color: "#C5C5C5" }}
                      />
                      {strings.DATE}
                    </p>
                    {activeTab == 0 ? (
                      <Icon
                        icon="ant-design:caret-up-filled"
                        width="20"
                        height="20"
                        style={{ color: "#CCFF00" }}
                      />
                    ) : (
                      <Icon
                        icon="ant-design:caret-down-filled"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9" }}
                      />
                    )}
                  </th>
                  <th
                    onClick={() => {
                      setActiveTab(activeTab == 1 ? null : 1);
                      fetchptSessionHistory(
                        activeTab == 1 ? "DESC" : "ASC",
                        "time"
                      );
                    }}
                    className={`${
                      activeTab == 1
                        ? "kakaoClass"
                        : "border-x-2 border-[#444444]"
                    } ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[50px] flex justify-around items-center"}`}
                  >
                    <p className="mb-0 flex items-center gap-2">
                      <Icon
                        icon="ion:time-outline"
                        width="22"
                        height="22"
                        style={{ color: "#C5C5C5" }}
                      />
                      {strings.TIME}
                    </p>
                    {activeTab == 1 ? (
                      <Icon
                        icon="ant-design:caret-up-filled"
                        width="20"
                        height="20"
                        style={{ color: "#CCFF00" }}
                      />
                    ) : (
                      <Icon
                        icon="ant-design:caret-down-filled"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9" }}
                      />
                    )}
                  </th>
                  <th
                    onClick={() => {
                      setActiveTab(activeTab == 2 ? null : 2);
                      fetchptSessionHistory(
                        activeTab == 2 ? "DESC" : "ASC",
                        "workoutExercises"
                      );
                    }}
                    className={`${
                      activeTab == 2 ? "kakaoClass" : ""
                    } ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[45%] min-h-[50px] flex justify-around items-center"}`}
                  >
                    {strings.WORKOUT}
                    {activeTab == 2 ? (
                      <Icon
                        icon="ant-design:caret-up-filled"
                        width="20"
                        height="20"
                        style={{ color: "#CCFF00" }}
                      />
                    ) : (
                      <Icon
                        icon="ant-design:caret-down-filled"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9" }}
                      />
                    )}
                  </th>
                  <th
                    onClick={() => {
                      setActiveTab(activeTab == 3 ? null : 3);
                      fetchptSessionHistory(
                        activeTab == 3 ? "DESC" : "ASC",
                        "totalSets"
                      );
                    }}
                    className={`${
                      activeTab == 3
                        ? "kakaoClass"
                        : "border-x-2 border-[#444444]"
                    } ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[20%]  min-h-[50px] flex justify-around items-center "}`}
                  >
                    {strings.SET}
                    {activeTab == 3 ? (
                      <Icon
                        icon="ant-design:caret-up-filled"
                        width="20"
                        height="20"
                        style={{ color: "#CCFF00" }}
                      />
                    ) : (
                      <Icon
                        icon="ant-design:caret-down-filled"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9" }}
                      />
                    )}
                  </th>
                  <th
                    onClick={() => {
                      setActiveTab(activeTab == 4 ? null : 4);
                      fetchptSessionHistory(
                        activeTab == 4 ? "DESC" : "ASC",
                        "totalExercises"
                      );
                    }}
                    className={`${
                      activeTab == 4 ? "kakaoClass" : ""
                    } ${"text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 text-start py-1 w-[30%] flex justify-around items-center min-h-[50px]"}`}
                  >
                    {strings.EXERCISE}
                    {activeTab == 4 ? (
                      <Icon
                        icon="ant-design:caret-up-filled"
                        width="20"
                        height="20"
                        style={{ color: "#CCFF00" }}
                      />
                    ) : (
                      <Icon
                        icon="ant-design:caret-down-filled"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9" }}
                      />
                    )}
                  </th>
                  <th
                    onClick={() => {
                      setActiveTab(activeTab == 5 ? null : 5);
                      fetchptSessionHistory(
                        activeTab == 5 ? "DESC" : "ASC",
                        "statusValue"
                      );
                    }}
                    className={`${
                      activeTab == 5
                        ? "kakaoClass"
                        : "border-x-2 border-[#444444]"
                    } ${"!text-start text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-around items-center min-h-[50px]"}`}
                  >
                    {strings.STATUS}
                    {activeTab == 5 ? (
                      <Icon
                        icon="ant-design:caret-up-filled"
                        width="20"
                        height="20"
                        style={{ color: "#CCFF00" }}
                      />
                    ) : (
                      <Icon
                        icon="ant-design:caret-down-filled"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9" }}
                      />
                    )}
                  </th>
                  <th className="!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[20%] flex justify-center items-center border-x-2 border-[#444444] min-h-[50px]">
                    {strings.LOGS}
                  </th>
                </div>
                <div className="border-b-2 border-[#444444] overflow-auto max-h-[calc(100vh_-_460px)] ">
                  {historyData?.sessionData &&
                    historyData?.sessionData?.map((item, index) => (
                      <div
                        key={index}
                        className={`${
                          logId == item.workoutLogId &&
                          openDetails &&
                          "kakaoClass"
                        } ${"flex justify-around items-center cursor-pointer bg-[#333333]"}`}
                      >
                        <th className="!text-start text-base font-bold text-white px-3 py-1 w-[40%] border-l-2 border-[#444444] min-h-[45px] flex justify-start items-center gap-4">
                          <p className="mb-0 text-lg font-bold w-[30px] text-center">
                            {index + 1}.
                          </p>
                          <p className="mb-0 text-lg font-bold flex items-center gap-2">
                            {moment(item?.date).format("YY.MM.DD")}
                          </p>
                        </th>
                        <th className="!text-center text-base font-semibold text-white px-2 py-1 w-[25%] border-x-2 border-[#444444] min-h-[45px] flex justify-center items-center">
                          <p className="mb-0 text-lg font-bold flex items-center gap-2.5">
                            {moment(item?.date).format("h:mm")}
                          </p>{" "}
                        </th>
                        <th className="!text-center text-lg font-bold text-white px-2 py-1 w-[45%] min-h-[45px] flex justify-center items-center">
                          {item?.workoutExercises.length > 0
                            ? item?.workoutExercises
                                ?.toString()
                                .replace(",", ", ")
                            : "- -"}
                        </th>
                        <th className="!text-center text-white px-2 w-[20%] border-x-2 border-[#444444] min-h-[45px] flex justify-center items-center ">
                          <p className="mb-0 text-sm font-bold rounded-[20px] px-3 py-1.5 bg-[#414142]">
                            {item?.totalSets} {strings?.SET}
                          </p>
                        </th>
                        <th className="text-base font-semibold text-white px-2 text-start w-[30%] flex justify-center items-center min-h-[45px]">
                          <p className="mb-0 text-sm font-bold rounded-[20px] px-3 py-1.5 bg-[#414142]">
                            {item?.totalExercises}{" "}
                            {strings.EXERCISES.toLowerCase()}
                          </p>
                        </th>
                        <th className="!text-start px-2 py-1 w-[25%] flex text-white text-base font-bold justify-center items-center border-x-2 border-[#444444] min-h-[45px]">
                          <p
                            style={{
                              border:
                                item.statusValue == "completed"
                                  ? "1px solid #CCFF0033"
                                  : item.statusValue == "req_reservation"
                                  ? "1px solid #FEBA694D"
                                  : "1px solid #C0C0C04D",
                            }}
                            className={`${
                              item.statusValue == "completed"
                                ? "text-[#CCFF00] bg-[#ccff001c]"
                                : item.statusValue == "req_reservation"
                                ? "text-[#FEBA69] bg-[#FEBA691A]"
                                : "text-[#C0C0C0] bg-[#C0C0C01A]"
                            } ${"mb-0 text-sm font-bold rounded-[20px] px-3 py-1.5"}`}
                          >
                            {item.statusValue == "completed"
                              ? strings.CONFIRMED
                              : item.statusValue == "req_reservation"
                              ? strings.REQUESTED
                              : strings.NO_SHOW}{" "}
                          </p>
                        </th>
                        <th className="!text-center text-base font-semibold text-white px-2 py-1 w-[20%] flex justify-center items-center border-r-2 border-[#444444] min-h-[45px]">
                          {item?.workoutExercises.length > 0 ? (
                            <button
                              onClick={() => {
                                setLogId(item.workoutLogId);
                                setLogIdValue({ id: item.workoutLogId });
                                setOpenDetails(true);
                                // navigate("/PT-information/Workout-log", {
                                //   state: { id: item.workoutLogId },
                                // });
                              }}
                              className={`${
                                logId == item.workoutLogId && openDetails
                                  ? "text-black bg-[#CCFF00]"
                                  : "text-white bg-[#414142] kakaoClass"
                              } ${"text-base font-bold h-[28px] w-[70px] rounded-[28px] hover:text-black hover:bg-[#CCFF00]"}`}
                            >
                              {strings.LOG}
                            </button>
                          ) : (
                            "- -"
                          )}
                        </th>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {historyData?.sessionData?.length > 0 && page && page > 1 && (
            <div className="w-full">
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  forcePage={offset == 1 ? 0 : offset - 1}
                  pageCount={page}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
          <div className="flex items-center justify-center mt-4">
            <button
              onClick={() => {
                scrollToTopFun();
                setPtHistory(false);
                setTrainerId("");
                setLogId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PtSessionHistory;
