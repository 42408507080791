import React from "react";
// import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";

function AlertModal({
  updateStaffHandle,
  openAlert,
  setOpenAlert,
  modifiedStaff,
  setUpdatedValue,
}) {
  return (
    <>
      <Modal
        className={`${"modalBoxClass modalWidthClass"}`}
        show={openAlert}
        onHide={() => {
          setOpenAlert(false);
          //   setUpdatedValue("");
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center pb-8 px-8">
          <div className="flex items-center justify-between border-b-2 border-[#444444] pb-2">
            <div></div>
            <div className="flex items-center gap-3">
              <Icon
                icon="ep:warning-filled"
                width="40"
                height="40"
                style={{ color: "#FF5C5C" }}
              />
              <p className="!mb-0 text-[40px] max-lg:text-[28px] font-extrabold ">
                {/* {strings.UPDATE_STAFF_AUTHORITY}{" "} */}
                Alert
              </p>
            </div>
            <Icon
              icon="maki:cross"
              width="32"
              height="32"
              style={{ color: "white" }}
              onClick={() => {
                setOpenAlert(false);
              }}
              className="cursor-pointer"
            />
            {/* <Icon
              icon="ep:warning-filled"
              width="40"
              height="40"
              style={{ color: "#FF5C5C" }}
            /> */}
          </div>
          {/* <p className="text-[30px] max-lg:text-[18px] m-auto w-4/5 max-lg:w-full font-bold">
            {strings.UPDATE_STAFF_SUB_TEXT}
          </p>
          <p className="text-[30px] max-lg:text-[18px] m-auto w-4/5 max-lg:w-full font-bold pb-3">
            {strings.PLEASE_CHECK_THE_BELOW}
          </p> */}
          <p className="mb-0 text-[36px] max-lg:text-[22px] m-auto w-4/5 max-lg:w-full font-bold pb-2 pt-2">
            {strings.REVIEW_CAN_ONLY_BE_VIEWD_BY_THE_OWNER}{" "}
            {/* {modifiedStaff?.length > 0 &&
              modifiedStaff?.map((item, index) => (
                <span
                  key={index}
                  className="text-[36px] max-lg:text-[22px] text-white"
                >
                  {item.name}
                  {index !== modifiedStaff?.length - 1 && ", "}
                </span>
              ))} */}
          </p>
          <div className="flex justify-center pt-8 gap-4">
            <button
              onClick={() => {
                // setUpdatedValue("");
                setOpenAlert(false);
              }}
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CANCEL}
            </button>
            {/* <button
              onClick={() => updateStaffHandle()}
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CONFIRM}
            </button> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AlertModal;
