import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import TrainerReqTable from "./TrainerReqTable";
import TarinerReqModal from "./TarinerReqModal";
import DeleteConfirmation from "../../commonComponents/modalScreens/DeleteConfirmation";
import ManagementSublyApi from "../../../helper/ManagementApi";
import ReactPaginate from "react-paginate";
import EditTrainerReqModal from "./EditTrainerReqActionModal";
import $ from "jquery";

const ChangeTrainerRequest = () => {
  const { token } = useSelector((state) => state.user);
  const [isLoader, setIsLoader] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [requestList, setRequestList] = useState("");
  const [orderValue, setOrderValue] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [productId, setProductId] = useState("");
  const [activeTab, setActiveTab] = useState();
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [detailValue, setDetailValue] = useState("");
  const [editModal, setEditModal] = useState(false);

  // ================API for fetchig change trainer request list===============
  useEffect(() => {
    fetchRequestList();
  }, [editModal, offset]);

  async function fetchRequestList(sort, order) {
    setIsLoader(true);
    await ManagementSublyApi.changeTrainerList(
      token,
      sort ? sort : sortKey,
      order ? order : orderValue,
      offset
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setRequestList(response.data?.requestList);
          setPage(response.data?.count / 10);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0
    ) {
      setshowModal(false);
      setEditModal(false);
      setProductId("");
    }
  });

  return (
    <div>
      <section>
        {isLoader ? <Loader /> : ""}
        <Container fluid>
          <Row>
            <Col xl={2} className="p-0">
              <SideBar />
            </Col>
            <Col xl={10} className="p-0 overflow-auto h-screen relative">
              <Header text={strings.MANAGEMENT_CHANGE_TRAINER_REQUEST} />
              <div className="text-white px-7 ">
                <div className="mt-4">
                  <div className="font-extrabold text-3xl mb-[25px]">
                    {strings.Change_Trainer_Request}
                  </div>
                  {requestList?.length == 0 && (
                    <p className="text-2xl font-extrabold text-[#444444] text-center my-4">
                      {strings.NO_RECORD_FOUND}
                    </p>
                  )}
                  {requestList?.length > 0 && (
                    <TrainerReqTable
                      fetchRequestList={fetchRequestList}
                      setOrderValue={setOrderValue}
                      setSortKey={setSortKey}
                      setProductId={setProductId}
                      productId={productId}
                      setActiveTab={setActiveTab}
                      activeTab={activeTab}
                      requestList={requestList}
                      setshowModal={setshowModal}
                    />
                  )}
                </div>
                {requestList?.length > 0 && page && page > 1 && (
                  <div className="w-full">
                    <div className="mt-3">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        forcePage={offset == 1 ? 0 : offset - 1}
                        pageCount={page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
                {showModal && (
                  <TarinerReqModal
                    setshowModal={setshowModal}
                    setProductId={setProductId}
                    setIsLoader={setIsLoader}
                    productId={productId}
                    setDetailValue={setDetailValue}
                    detailValue={detailValue}
                    setEditModal={setEditModal}
                    classNameValue={`${
                      showModal ? "block_notify" : "hideNotify"
                    }`}
                  />
                )}
                {editModal && (
                  <EditTrainerReqModal
                    detailValue={detailValue}
                    setEditModal={setEditModal}
                    setProductId={setProductId}
                    setIsLoader={setIsLoader}
                    classNameValue={`${
                      editModal ? "block_notify" : "hideNotify"
                    }`}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <DeleteConfirmation
        textHead={"Change trainer"}
        text={"Are you sure you are completing the trainer change request?"}
        deleteMemo={deleteModal}
        setDeleteMemo={setdeleteModal}
        btnText={"Complete"}
      />
    </div>
  );
};
export default ChangeTrainerRequest;
