import React, { useState } from "react";
import logo from "../../../assests/icons/Logo.svg";
import { strings } from "../../../utils/String";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import OtpInput from "react-otp-input";
import SuccessSigned from "../../commonComponents/modalScreens/SuccessSigned";
import AuthSublyApi from "../../../helper/AuthApi";
import Loader from "../../../utils/Loader";
import {
  getToken,
  getUserDetails,
  selectYourGymHandle,
} from "../../../Store/slices/AuthSlice";
import { useDispatch } from "react-redux";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";

function Verification() {
  const [otp, setOtp] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [toSelectGym, setToSelectGym] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  // ================API FOR VERIFY OTP================
  async function verifyHandle() {
    setIsLoader(true);
    const requestData = {
      email: state?.email,
      cellphone: state?.phone,
      otp: otp,
    };
    await AuthSublyApi.verifyOTP(requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setSuccessModal(true);
          setToSelectGym(response?.data);
          if (response.data.gymDetails.length > 1) {
            setTimeout(async () => {
              await dispatch(getUserDetails(response.data));
              await dispatch(getToken(response.data.token));
              navigate("/Select-your-gym");
            }, 1500);
          } else if (response.data.gymDetails.length == 1) {
            setTimeout(async () => {
              await dispatch(selectYourGymHandle(response.data));
              dispatch(getToken(response.data.token));
              navigate("/Member-search");
            }, 1500);
          }
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
      {isLoader ? <Loader /> : ""}
      <Container className="flex items-center justify-center h-screen">
        <div className="bg-box_bg w-[70%] rounded-lg max-lg:w-[100%] py-16 px-6">
          <div className="w-[60%] m-auto max-lg:w-full text-center">
            <div>
              <img
                src={logo}
                alt="logo"
                className="w-32 h-16 max-md:w-30 max-md:h-14 mx-auto mb-4"
              />
            </div>
            <p className="text-3xl font-bold text-white mb-4 pt-3 max-md:text-lg max-md:pt-1">
              {strings.VERIFICATION_CODE}
            </p>
            <div className="py-3">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={8}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                containerStyle={"justify-center gap-1"}
                inputStyle={
                  "!w-14 !h-20  bg-[#1F2022] rounded-[15px] text-center text-3xl max-md:text-xl max-md:!w-6 max-md:!h-10 max-md:rounded-[8px] text-white"
                }
              />
            </div>
            <button
              disabled={otp ? false : true}
              onClick={() => verifyHandle()}
              className="bg-common_color disabled:bg-[#ccff0094] text-2xl font-extrabold text-black rounded-[30px] h-[60px] w-[180px] mt-6 max-md:h-[45px] max-md:text-lg max-md:w-[150px]"
            >
              {strings.CONFIRM}
            </button>
          </div>
        </div>
      </Container>
      <SuccessSigned
        successModal={successModal}
        setSuccessModal={setSuccessModal}
        email={state?.email}
        toSelectGym={toSelectGym}
      />
    </section>
  );
}

export default Verification;
