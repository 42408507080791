import React, { useEffect, useState } from "react";
import { strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import BoardSublyApi from "../../../../../helper/BoardApi";
import { useSelector } from "react-redux";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import { FormMedia } from "../../../boardScreens/RegistrationForm";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";

function AddMachineInformation({
  setShowAddMachineInfo,
  setDefult,
  defult,
  setIsLoader,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState("");
  const [innerLoader, setInnerLoader] = useState(false);
  const [error, setError] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [requestedData, setRequestedData] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  useEffect(() => {
    if (defult) {
      setTitle(defult?.title);
      setDescription(defult?.description);
      setImagePreview(defult?.files);
      setRequestedData(defult?.files);
    }
  }, []);
  async function addMachineEnquipment() {
    setIsLoader(true);
    const machineData = {
      title: title,
      files: requestedData || [],
      equipmentType: 1,
      description: description,
    };
    await GymSettingSublyApi.addMachine(token, machineData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setShowAddMachineInfo(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function updateMachine() {
    setIsLoader(true);
    const machineData = {
      title: title,
      files: requestedData,
      equipmentType: 1,
      description: description,
    };
    await GymSettingSublyApi.editMachine(token, machineData, defult.id)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setShowAddMachineInfo(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getVideoThumbnail(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    setError("");
    if (file?.type.match("video")) {
      if (file?.size / 1000000 <= 200) {
        var image = "";
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = async function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              setInnerLoader(true);
              const requestData = new FormData();
              requestData.append("file", file);
              await BoardSublyApi.assestUpload(requestData, token)
                .then((response) => {
                  setInnerLoader(false);
                  if (response.statusCode == 200) {
                    let media = [...imagePreview];
                    const toDeploy = [...requestedData];
                    media.push({
                      url: response.data.compressed,
                      type: "video",
                      view: image,
                      original: response.data.original,
                    });
                    toDeploy.push({
                      url: response.data.compressed,
                      type: "video",
                      original: response.data.original,
                    });
                    setRequestedData(toDeploy);
                    setImagePreview(media);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              const base64String = image; // Base64 string
              const binaryString = atob(base64String.split(",")[1]); // Binary data string
              const blob = new Blob([binaryString], { type: "image/png" });
              // setPassThumbnail(blob);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setError("Video size should be less than 200 MB");
      }
    } else {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result) {
            setInnerLoader(true);
            const requestData = new FormData();
            requestData.append("file", file);
            await BoardSublyApi.assestUpload(requestData, token)
              .then((response) => {
                setInnerLoader(false);
                if (response.statusCode == 200) {
                  let media = [...imagePreview];
                  let toDeploy = [...requestedData];
                  media.push({
                    url: response.data.compressed,
                    type: "image",
                    view: result,
                    original: response.data.original,
                  });
                  toDeploy.push({
                    url: response.data.compressed,
                    type: "image",
                    original: response.data.original,
                  });
                  setRequestedData(toDeploy);
                  setImagePreview(media);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  // -------- REMOVE PRODUCT --------
  async function removeProduct(url, index) {
   
          const images = [...imagePreview];
          let toDeploy = [...requestedData];
          images.splice(index, 1);
          setImagePreview(images);
          toDeploy.splice(index, 1);
          setRequestedData(toDeploy);
        }
    

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex items-center justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#2C2C2E] rounded-[10px] w-[70%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-[40px] pb-4  z-20 "}`}
      >
        <div className="w-[100%] max-lg:w-[100%] m-auto">
          <div className="sm:w-[70%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-[32px]">
              {defult
                ? strings.EDIT_MACHINE_INFORMATION
                : strings.ADD_MACHINE_INFORMATION}
            </p>
          </div>

          <div className="text-white  2xl:px-32 my-8 ">
            <div className="space-y-7">
              <div>
                <label className="text-[16px]" htmlFor="">
                  {strings.TITLE}:
                </label>
                <input
                  className="  w-full bg-[#414142] rounded-[10px] min-h-[40px] text-white font-bold placeholder:font-normal p-2"
                  type="text"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div>
                <label className="text-[16px]" htmlFor="">
                  {strings.DESCRIPTION}:
                </label>
                <textarea
                  className="resize-none h-[120px] w-full bg-[#414142] text-white font-bold placeholder:font-normal rounded-[10px] min-h-[100px] p-2"
                  type="text"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              // style={{ border: "1px solid #5C5C5C" }}
              className={`  flex flex-col justify-evenly  ${
                imagePreview &&
                imagePreview.length > 0 &&
                imagePreview.length < 4
                  ? "justify-start"
                  : innerLoader
                  ? "justify-start"
                  : "justify-center items-center  border-[#414142]"
              } ${imagePreview.length > 0 ? "h-[170px]" : "h-[220px]"}`}
            >
              <input
                type="file"
                accept="image/*, video/*"
                className="hidden"
                id="uploadMedia"
                onChange={(e) => getVideoThumbnail(e)}
              />
              <div className="flex items-center gap-2 flex-wrap">
                {imagePreview &&
                  imagePreview?.map((item, index) => (
                    <div
                      className="w-[150px] h-[150px] rounded-[10px] bg-[#6F6F6F] relative flex"
                      key={index}
                    >
                      <img
                        src={item.url || item.view}
                        className="w-[150px] h-[150px] object-cover rounded-[10px]"
                      />
                      <span
                        onClick={() => {
                          removeProduct(item.url, index);
                        }}
                        className="absolute top-2 w-[30px] h-[30px] right-2 flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                      >
                        <Icon
                          icon="iconamoon:close-bold"
                          width="25"
                          height="25"
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      </span>

                      {item?.type == "video" && (
                        <span
                          onClick={() => {
                            setVideoPreview(true);
                            setuploadedMedia(item);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                {((imagePreview?.length > 0 && imagePreview?.length !== 5) ||
                  innerLoader) && (
                  <label
                    htmlFor="uploadMedia"
                    className="w-[150px] h-[150px] rounded-[10px] bg-[#6F6F6F] flex items-center justify-center relative"
                  >
                    {!innerLoader ? (
                      <Icon
                        icon="bitcoin-icons:plus-filled"
                        width="100"
                        height="100"
                        style={{ color: "white" }}
                        className="bg-[#6F6F6F] rounded-[10px] w-[113px] h-[113px] flex items-center justify-center "
                      />
                    ) : (
                      <span className="absolute w-full h-full flex items-center justify-center">
                        <Icon
                          icon="eos-icons:bubble-loading"
                          width="60"
                          height="60"
                          style={{ color: "#CCFF00" }}
                          className="loader-icon "
                        />
                      </span>
                    )}
                  </label>
                )}
              </div>

              {(!imagePreview || imagePreview.length == 0) && (
                <div
                  className={`${
                    innerLoader && "hidden"
                  } flex flex-col items-center justify-center gap-2.5 -mt-24`}
                >
                
                  <label
                    htmlFor="uploadMedia"
                    style={{ border: "2px solid #4B4B4B" }}
                    className={`${
                      innerLoader && "hidden"
                    } ${"flex items-center gap-3 rounded-[10px] py-2.5 px-6 cursor-pointer -mt-14"}`}
                  >
                    <Icon
                      icon="humbleicons:upload"
                      width="48"
                      height="48"
                      style={{ color: "white" }}
                    />
                    <p className="mb-0 text-[24px] font-bold">
                      {strings.UPLOAD_MEDIA_FILES}
                    </p>
                  </label>
                </div>
              )}
            </div>
          </div>

          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-[44px] mb-4"}`}
          >
            <button
              onClick={() => {
                setShowAddMachineInfo(false);
                setDefult(null);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
            {defult ? (
              <button
                disabled={!innerLoader ? false : true}
                onClick={() => {
                  updateMachine();
                }}
                className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black kakaoClass hover:text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {strings.UPDATE}
              </button>
            ) : (
              <button
                disabled={title && description && !innerLoader ? false : true}
                onClick={() => {
                  addMachineEnquipment();
                }}
                className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black  bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {strings.ADD}
              </button>
            )}
          </div>
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default AddMachineInformation;
