import React, { useEffect, useState } from "react";
import EquipmentInformation from "./EquipmentInformation";
import { strings } from "../../../../../utils/String";
import Header from "../../../../commonComponents/Header";
import SideBar from "../../../../commonComponents/SideBar";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import GymLockerSetting from "../gymLockerSetting/GymLockerSetting";
import AddMachineInformation from "./AddMachineInformation";
import MachineInformation from "./MachineInformation";
import DeleteConfirmation from "../../../../commonComponents/modalScreens/DeleteConfirmation";
import DeleteSuccess from "../../../../commonComponents/modalScreens/DeleteSuccess";
import SportEuipmentInfo from "./SportEuipmentInfo";
import AddInformation from "./AddInformation";
import Holidays from "../gymInformationScreens/Holidays";
import { MembershipDropDown } from "../gymInformationScreens/GymInfoScreen";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import Loader from "../../../../../utils/Loader";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";
import $ from "jquery";

function GymSettingsScreen() {
  const { token } = useSelector((state) => state.user);
  const [showAddMachineInfo, setShowAddMachineInfo] = useState(false);
  const [showMachineInfo, setshowMachineInfo] = useState(false);
  const [deleteMachineInfo, setdeleteMachineInfo] = useState(false);
  const [deleteSucess, setdeleteSucess] = useState(false);
  const [sportEuipmentInfo, setsportEuipmentInfo] = useState(false);
  const [addInformation, setaddInformation] = useState(false);
  const [showHolidays, setshowHolidays] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [equpmentData, setEqupmentData] = useState();
  const [gymText, setGymText] = useState({
    str: "Sports equipment information",
    val: 0,
  });
  const [showDropDown, setshowDropDown] = useState(false);
  const [machineId, setMachineId] = useState();
  const [defult, setDefult] = useState();
  const [freeWeightData, setFreeWeightData] = useState();
  const [equipmentType, setEquipmentType] = useState("All");
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  async function deleteMachine() {
    await GymSettingSublyApi.deleteEquipment(token, machineId)
      .then((response) => {
        if (response.statusCode == 200) {
          setdeleteMachineInfo(false);
          setshowMachineInfo(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    (!sportEuipmentInfo ||
      !deleteMachineInfo ||
      !showAddMachineInfo ||
      !addInformation) &&
      getGymEqupment();
  }, [
    deleteMachineInfo,
    showAddMachineInfo,
    addInformation,
    sportEuipmentInfo,
  ]);
  async function getGymEqupment() {
    setIsLoader(true);
    await GymSettingSublyApi.getEqupmentDetails(token, search)
      .then((response) => {
        setEqupmentData(response.data.equipments);
        setIsLoader(false);
      })
      .catch((err) => console.log(err));
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setShowAddMachineInfo(false);
      setshowMachineInfo(false);
      setsportEuipmentInfo(false);
      setaddInformation(false);
      setshowHolidays(false);
      setDefult("");
      setshowDropDown(false)
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`p-0 relative  ${
              addInformation ||
              showAddMachineInfo ||
              showMachineInfo ||
              sportEuipmentInfo ||
              showHolidays
                ? "overflow-hidden h-screen"
                : "overflow-auto"
            } `}
          >
            <Header text={strings.GYM_SETTING_GYM} />
            <div className="mx-6 ">
              {" "}
              <div className="flex items-center justify-between gap-3 py-3 flex-wrap">
                <div className="flex items-center gap-3 flex-wrap max-sm:w-full">
                  <label
                    onClick={() => {
                      setshowDropDown(!showDropDown);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                      {equipmentType}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={showDropDown ? 2 : 4}
                    />
                    {showDropDown && (
                      <EquipmentInfo setEquipmentType={setEquipmentType} />
                    )}
                  </label>
                </div>
                <div className="flex items-center flex-wrap gap-3 max-sm:w-full">
                  <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Keywords"
                      className="bg-transparent w-full text-white text-lg font-bold"
                    />
                  </label>
                  <button
                    onClick={() => {
                      // setOffset(1);
                      getGymEqupment();
                    }}
                    className="hover:text-black font-[archivo] max-sm:w-full hover:bg-[#CCFF00] text-lg font-bold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              <EquipmentInformation
                setshowMachineInfo={setshowMachineInfo}
                setMachineId={setMachineId}
                setaddInformation={setaddInformation}
                setShowAddMachineInfo={setShowAddMachineInfo}
                setsportEuipmentInfo={setsportEuipmentInfo}
                freeWeightData={setFreeWeightData}
                equpmentData={equpmentData}
                equipmentType={equipmentType}
              />
            </div>
            {showAddMachineInfo && (
              <AddMachineInformation
                setShowAddMachineInfo={setShowAddMachineInfo}
                machineId={machineId}
                defult={defult}
                setDefult={setDefult}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  showAddMachineInfo ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            {showMachineInfo && (
              <MachineInformation
                setshowMachineInfo={setshowMachineInfo}
                machineId={machineId}
                setDeleteMemo={setdeleteMachineInfo}
                setShowAddMachineInfo={setShowAddMachineInfo}
                setDefult={setDefult}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  showMachineInfo ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            {sportEuipmentInfo && (
              <SportEuipmentInfo
                setsportEuipmentInfo={setsportEuipmentInfo}
                freeWeightData={freeWeightData}
                setFreeWeightData={setFreeWeightData}
                setIsLoader={setIsLoader}
                setaddInformation={setaddInformation}
                classNameValue={`${
                  sportEuipmentInfo ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            {addInformation && (
              <AddInformation
                setaddInformation={setaddInformation}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  addInformation ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            {showHolidays && (
              <Holidays
                classNameValue={`${
                  showHolidays ? "block_notify" : "hideNotify"
                }`}
              />
            )}
          </Col>
        </Row>
      </Container>

      <DeleteConfirmation
        deleteMemo={deleteMachineInfo}
        setDeleteMemo={setdeleteMachineInfo}
        textHead={"Delete Machine Information"}
        text={"Are you sure you want to permanently delete this Machine?"}
        btnText={"Delete"}
        machineId={machineId}
        clickBtn={deleteMachine}
        classNameValue={`${
          showAddMachineInfo ||
          showMachineInfo ||
          sportEuipmentInfo ||
          addInformation ||
          showHolidays
            ? "block_notify"
            : "hideNotify"
        }`}
      />
      <DeleteSuccess
        deleteSuccess={deleteSucess}
        setDeleteSuccess={setdeleteSucess}
        isDelete={true}
        text={"Locker deleted successfully"}
        classNameValue={`${
          showAddMachineInfo ||
          showMachineInfo ||
          sportEuipmentInfo ||
          addInformation ||
          showHolidays
            ? "block_notify"
            : "hideNotify"
        }`}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={`${
          showAddMachineInfo ||
          showMachineInfo ||
          sportEuipmentInfo ||
          addInformation ||
          showHolidays
            ? "block_notify"
            : "hideNotify"
        }`}
      />
    </section>
  );
}

export default GymSettingsScreen;

function EquipmentInfo({ setEquipmentType }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[270px] right-4 top-14 max-h-[250px] overflow-auto z-20 px-3">
      <div
        onClick={() => {
          setEquipmentType(strings.ALL);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      <div
        onClick={() => {
          setEquipmentType(strings.MACHINES);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.MACHINES}</span>
      </div>
      <div
        onClick={() => {
          setEquipmentType(strings.SPORTS_EQUIPMENT_INFORMATION);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">
          {strings.SPORTS_EQUIPMENT_INFORMATION}
        </span>
      </div>
    </div>
  );
}
