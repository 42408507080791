import { useEffect, useState } from "react";

export function getPaginatedRecord(data, page, pageSize) {
  // console.log(page, pageSize);
  // console.log("data", data);
  const offset = (page - 1) * pageSize;
  return data.slice(offset, offset + pageSize);
}

export function extractUrlsToArray(data) {
  const urlsArray = [];

  for (const key in data?.mProfileImg) {
    if (key.endsWith("_min")) {
      const originalKey = key.replace("_min", "_org");
      urlsArray.push({
        compressed: (data?.mProfileImg ?? {})[key] || "",
        original: (data?.mProfileImg ?? {})[originalKey] || "",
      });
    }
  }
  return urlsArray;
}

export function splitTime(times) {
  // Split the times string into two parts: morning and evening
  const [morning, evening] = times.split("-");
  // console.log("🚀 ~ splitTime ~ morning, evening:", morning, evening);

  // Split morning and evening parts into hours and minutes
  const [morningHour, morningMin] = morning.split(":").map(Number);
  const [eveningHour, eveningMin] = evening.split(":").map(Number);

  // Return an object with the parsed values
  return {
    morningHour: morningHour || 0,
    morningMin: morningMin || 0,
    eveningHour: eveningHour || 0,
    eveningMin: eveningMin || 0,
  };
}

export function mergeTime(curr) {
  return {
    startTime: `${curr?.morningHour || 0}:${curr?.morningMin} ${
      curr?.startMeridiemValue
    }`,
    endTime: `${curr?.eveningHour || 0}:${curr?.eveningMin} ${
      curr?.endMeridiemValue
    }`,
  };
}

// Example usage
// const times = "8:30-18:45";
// const parsedTimes = splitTime(times);
// Output: { morningHour:

export const prepareWorkTime = (time = []) => {
  return time?.map((item) => {
    const data = {
      day: item?.days
        ?.map((day) => day?.split("")?.slice(0, 3)?.join(""))
        ?.join("-"),

      times: item?.timeBlocks?.reduce((acc, curr) => {
        const times = curr.startTime + "-" + curr.endTime;
        return [...acc, times];
      }, []),
    };
    return data;
  });
};

export const tempPrepareWorkTime = (time = []) => {
  return time?.map((item) => {
    const data = {
      day: item?.days
        ?.map((day) => day?.split("")?.slice(0, 3)?.join(""))
        ?.join("-"),

      times: item?.timeBlocks?.reduce((acc, curr) => {
        const times = curr.startTime + "-" + curr.endTime;
        return [...acc, times];
      }, []),
    };
    return data;
  });
};

export const createDayName = (day) => {
  return day?.split("").slice(0, 3).join("");
};

export const prepareBody = (data = {}) => {
  const body = {};
  Object.keys(data)?.map((item) => {
    if (typeof data[item] === undefined || data[item] === "") {
      return;
    }
    if (Object.keys(data[item])?.length) {
      body[item] = prepareBody(data[item]);
      return;
    }
    body[item] = data[item];
  });
  return body;
};

//--------function for handling name input filed------
export function alphaOnly(event) {
  var value = String.fromCharCode(event.which);
  var pattern = new RegExp(/[a-zA-Z\s]/i);
  return pattern.test(value);
}

//--------function for handling contact input filed------

export function numberOnly(event) {
  var value = String.fromCharCode(event.which);
  var pattern = new RegExp(/[0-9]/i);
  return pattern.test(value);
}
export function formatPrice(price) {
  if (isNaN(price) || price === null) {
    return 0;
  }
  // Convert the price to a number and format it
  return Number(price).toLocaleString("en-US");
}

export function parseFormattedPrice(formattedPrice) {
  if (typeof +formattedPrice === "number" && !isNaN(+formattedPrice)) {
    return +formattedPrice;
  }
  if (typeof formattedPrice !== "string") {
    return 0;
  }

  // Remove commas and convert to a number
  const parsedPrice = Number(formattedPrice.replace(/,/g, ""));

  if (isNaN(parsedPrice)) {
    return 0;
  }

  return parsedPrice;
}

export function validateEmail(email) {
  var re = /^(?!.*\s)[a-zA-Z0-9._%+-가-힣]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
}

export function numberValidate(number) {
  const cleanedNumber = number.replace(/\D/g, "");
  return cleanedNumber.length === 11;
}

export function formatCustomNumber(numberValue) {
  // Check if the number is not valid (NaN) or null
  const number = `${numberValue || ""}`.replace(/-/g, "");
  // if (isNaN(number) || number === null) {
  //   return "Invalid Number";
  // }

  // Convert the number to a string
  const numberStr = String(number);

  // Add a hyphen after the first 3 digits and after every 4 digits
  const formattedNumber = numberStr
    .replace(/(\d{3})(\d{4})/g, "$1-$2")
    .replace(/(\d{4})(?=\d)/g, "$1-");

  return formattedNumber;
}

export const trimPhone = (string) => string.replace(/-/g, "").trim();

export const InactivityDetector = ({ timeout = 5000, onInactivity }) => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    
    let inactivityTimer;

    // Function to reset the inactivity timer
    const resetTimer = () => {
      setIsActive(true);
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        setIsActive(false);
        onInactivity();
      }, timeout);
    };

    // Add event listeners
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);
    window.addEventListener("click", resetTimer);

    // Initialize the timer
    resetTimer();

    // Clean up event listeners on component unmount
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);
      window.removeEventListener("click", resetTimer);
    };
  }, [timeout, onInactivity]);
};
