import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import React, { useState } from "react";
import logo from "../assests/attendanceIcons/logo-icon.svg";
import { strings } from "../utils/String";
import { useNavigate } from "react-router-dom";

function ResetYourPassword() {
  const navigate = useNavigate()
  const [password, setPassword] = useState("");
  const [newPassWord, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
  });

  return (
    <section className=" flex items-center justify-center h-screen">
      {" "}
      <div className="bg-box_bg w-[60%] max-lg:w-[80%] rounded-lg max-md:w-[90%] py-12 px-6">
        <div className="w-[80%] m-auto max-lg:w-full text-center">
          <div>
            <img src={logo} alt="logo" className="mx-auto" />
            <h2 className="font-extrabold text-white text-[30px] mt-4 max-lg:mt-6 mb-6">
              {strings.RESET_YOUR_PASSWORD}
            </h2>
          </div>
          <div className="mt-6 mb-10 w-[85%] mx-auto max-lg:w-full">
            <label htmlFor="phone" className="mt-3 w-full">
              <p className="!mb-1 flex items-center gap-2 text-white font-bold text-lg whitespace-nowrap">
                {strings.NEW_PASSWORD}
              </p>
              <p className="mb-0 relative">
                <input
                  type={showPassword.newPassword ? "text" : "password"}
                  autoComplete="off"
                  className={`${"h-[50px] px-3 flex items-center gap-2 rounded-[30px] bg-[#2C2C2E] text-white w-full "}`}
                  id="phone"
                  placeholder={strings.PASSWORD}
                  value={newPassWord}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
                {!showPassword.newPassword ? (
                  <Icon
                    icon="ant-design:eye-invisible-outlined"
                    width="23"
                    height="23"
                    onClick={() => {
                      setShowPassword({ ...showPassword, newPassword: true });
                    }}
                    style={{ color: "#B6B6B6" }}
                    className="absolute right-4 top-3.5 cursor-pointer"
                  />
                ) : (
                  <Icon
                    icon="ant-design:eye-outlined"
                    width="23"
                    height="23"
                    onClick={() => {
                      setShowPassword({ ...showPassword, newPassword: false });
                    }}
                    style={{ color: "#B6B6B6" }}
                    className="absolute right-4 top-3.5 cursor-pointer"
                  />
                )}
              </p>
            </label>
            <label htmlFor="phone" className="mt-3 w-full">
              <p className="!mb-1 flex items-center gap-2 text-white font-bold text-lg whitespace-nowrap">
                {strings.PASSWORD}
              </p>
              <p className="mb-0 relative">
                <input
                  type={showPassword.password ? "text" : "password"}
                  autoComplete="off"
                  className={` ${"h-[50px] px-3 flex items-center gap-2 rounded-[30px] bg-[#2C2C2E] text-white w-full "}`}
                  id="phone"
                  placeholder={"Re-enter new password "}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {!showPassword.password ? (
                  <Icon
                    icon="ant-design:eye-invisible-outlined"
                    width="23"
                    height="23"
                    onClick={() => {
                      setShowPassword({ ...showPassword, password: true });
                    }}
                    style={{ color: "#B6B6B6" }}
                    className="absolute right-4 top-3.5 cursor-pointer"
                  />
                ) : (
                  <Icon
                    icon="ant-design:eye-outlined"
                    width="23"
                    height="23"
                    onClick={() => {
                      setShowPassword({ ...showPassword, password: false });
                    }}
                    style={{ color: "#B6B6B6" }}
                    className="absolute right-4 top-3.5 cursor-pointer"
                  />
                )}
              </p>
            </label>
            <div className="flex items-center my-1.5 justify-between">
              <p
                // onClick={() => {
                //   setOpenModal(true);
                // }}
                className="mb-0 text-lg font-bold text-[#787878]"
              >
                {strings.BOTH_PASSWORD_MUST_MATCH}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center gap-4">
            <button
                onClick={() => {
                  navigate("/Attendance/Member");
                }}
              className="bg-common_color disabled:bg-[#ccff0094] text-xl font-extrabold text-black rounded-[30px] h-[50px] w-[155px]"
            >
              {strings.CONFIRM}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetYourPassword;
