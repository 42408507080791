import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import ReactPaginate from "react-paginate";
import { Table } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import moment from "moment";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";

export const CHAT_TYPES = {
  WEB: "Web",
  ADMIN: "Admin",
  CENTER: "Center",
  SYSTEM: "System",
  MOBILE: "Mobile",
};

function SentMessageList({
  setMessageList,
  memberId,
  setIsLoader,
  setOpenModal,
  sentItem,
  setSentItem,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState(null);
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [messageList, setMesageList] = useState("");
  const [chatStatus, setChatStatus] = useState("all");

  // ===========APi for fetch sent message list===========
  useEffect(() => {
    SentMessageListHandle("", "", 1);
  }, [chatStatus]);

  useEffect(() => {
    SentMessageListHandle();
  }, [offset]);

  async function SentMessageListHandle(order, key, page) {
    setIsLoader(true);
    await PT_InformationSublyApi.fetchSentMessage(
      token,
      memberId,
      page ? page : offset,
      chatStatus,
      order,
      key
    )
      .then((response) => {
        setIsLoader(false);
        if (response) {
          setPage(response.data.count / 10);
          setMesageList(response?.data?.messageList);
          page && setOffset(1);
        }
      })
      .catch((error) => console.log(error));
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  return (
    <section
      className={`${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[90%] max-h-[90%] py-3 px-7 mt-10 z-20 overflow-auto"}`}
      >
        <div className="w-full m-auto">
          <h2 className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold text-white font-[archivo] pb-3 border-b border-[#595959]">
            {strings.SENT_MESSAGE_LIST}
          </h2>
          <div className="flex items-center gap-3 justify-center my-4">
            <button
              onClick={() => {
                setChatStatus("all");
              }}
              className={`${
                chatStatus == "all"
                  ? "bg-[#CCFF00] text-black"
                  : "text-white kakaoClass"
              } ${" hover:bg-[#CCFF00] hover:text-black"} ${"text-lg font-extrabold h-[45px] w-[120px] rounded-[28px]"}`}
            >
              {strings.ALL}
            </button>{" "}
            <button
              onClick={() => {
                setChatStatus(3);
              }}
              className={`${
                chatStatus == 3
                  ? "bg-[#CCFF00] text-black"
                  : "text-white kakaoClass"
              } ${" hover:bg-[#CCFF00] hover:text-black"} ${"text-lg font-extrabold h-[45px] w-[120px] rounded-[28px]"}`}
            >
              {strings.SYSTEM}
            </button>{" "}
            <button
              onClick={() => {
                setChatStatus(2);
              }}
              className={`${
                chatStatus == 2
                  ? "bg-[#CCFF00] text-black"
                  : "text-white kakaoClass "
              } ${"hover:bg-[#CCFF00] hover:text-black"} ${"text-lg font-extrabold h-[45px] w-[120px] rounded-[28px]"}`}
            >
              {strings.CENTER}
            </button>{" "}
            <button
              onClick={() => {
                setChatStatus(1);
              }}
              className={`${
                chatStatus == 1
                  ? "bg-[#CCFF00] text-black"
                  : "text-white kakaoClass"
              } ${" hover:bg-[#CCFF00] hover:text-black"} ${"text-lg font-extrabold h-[45px] w-[120px] rounded-[28px]"}`}
            >
              {strings.ADMIN}
            </button>
          </div>
          {messageList?.length == 0 && (
            <p className="text-2xl font-extrabold text-[#7b7a7a] text-center my-10">
              {strings.NO_RECORD_FOUND}
            </p>
          )}
          {messageList?.length > 0 && (
            <div className="overflow-auto">
              <div className="max-2xl:w-[1300px] suggestiontableClass overflow-auto max-h-[calc(100vh_-_240px)] relative">
                <Table className="w-full !bg-transparent">
                  <thead className="bg-[#474749] sticky top-[-1px] z-10">
                    <th
                      onClick={() => {
                        setActiveTab(activeTab == 0 ? null : 0);
                        SentMessageListHandle(
                          activeTab == 0 ? "DESC" : "ASC",
                          "date"
                        );
                      }}
                      className="!text-center text-lg !py-1 font-semibold text-white w-[14%] "
                    >
                      <div
                        className={`${
                          activeTab == 0 ? "kakaoClass cursor-pointer" : ""
                        } ${"flex items-center justify-center gap-4"}`}
                      >
                        <p className="mb-0 flex items-center gap-2">
                          {/* <Icon
                          icon="lets-icons:date-today"
                          width="22"
                          height="22"
                          style={{ color: "#C5C5C5" }}
                        /> */}
                          {strings.DATE}
                        </p>
                        {activeTab == 0 ? (
                          <Icon
                            icon="ant-design:caret-up-filled"
                            width="20"
                            height="20"
                            style={{ color: "#CCFF00" }}
                          />
                        ) : (
                          <Icon
                            icon="ant-design:caret-down-filled"
                            width="20"
                            height="20"
                            style={{ color: "#D9D9D9" }}
                          />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => {
                        setActiveTab(activeTab == 1 ? null : 1);
                        SentMessageListHandle(
                          activeTab == 1 ? "DESC" : "ASC",
                          "time"
                        );
                      }}
                      className="!text-center text-lg !py-0 font-semibold text-white w-[13%] "
                    >
                      <div
                        className={`${
                          activeTab == 1 ? "kakaoClass cursor-pointer" : ""
                        } ${"flex items-center justify-center gap-4"}`}
                      >
                        <p className="mb-0 flex items-center gap-2">
                          {/* <Icon
                          icon="ion:time-outline"
                          width="22"
                          height="22"
                          style={{ color: "#C5C5C5" }}
                        /> */}
                          {strings.TIME}
                        </p>
                        {activeTab == 1 ? (
                          <Icon
                            icon="ant-design:caret-up-filled"
                            width="20"
                            height="20"
                            style={{ color: "#CCFF00" }}
                          />
                        ) : (
                          <Icon
                            icon="ant-design:caret-down-filled"
                            width="20"
                            height="20"
                            style={{ color: "#D9D9D9" }}
                          />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => {
                        setActiveTab(activeTab == 2 ? null : 2);
                        SentMessageListHandle(
                          activeTab == 2 ? "DESC" : "ASC",
                          "messageType"
                        );
                      }}
                      className="!text-center text-lg !py-1 font-semibold text-white w-[16%] "
                    >
                      <div
                        className={`${
                          activeTab == 2 ? "kakaoClass cursor-pointer" : ""
                        } ${"flex items-center justify-center gap-4"}`}
                      >
                        <p className="mb-0">{strings.MESSAGE_TYPE}</p>
                        {activeTab == 2 ? (
                          <Icon
                            icon="ant-design:caret-up-filled"
                            width="20"
                            height="20"
                            style={{ color: "#CCFF00" }}
                          />
                        ) : (
                          <Icon
                            icon="ant-design:caret-down-filled"
                            width="20"
                            height="20"
                            style={{ color: "#D9D9D9" }}
                          />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => {
                        setActiveTab(activeTab == 3 ? null : 3);
                        SentMessageListHandle(
                          activeTab == 3 ? "DESC" : "ASC",
                          "message"
                        );
                      }}
                      className="!text-center text-lg !py-1 font-semibold text-white w-[57%]  "
                    >
                      <div
                        className={`${
                          activeTab == 3 ? "kakaoClass cursor-pointer" : ""
                        } ${"flex items-center justify-center gap-4"}`}
                      >
                        <p className="mb-0 flex items-center gap-2">
                          {/* <Icon
                          icon="lucide:message-square-more"
                          width="25"
                          height="22"
                          style={{ color: "#C5C5C5" }}
                        /> */}
                          {strings.MESSAGES}
                        </p>
                        {activeTab == 3 ? (
                          <Icon
                            icon="ant-design:caret-up-filled"
                            width="20"
                            height="20"
                            style={{ color: "#CCFF00" }}
                          />
                        ) : (
                          <Icon
                            icon="ant-design:caret-down-filled"
                            width="20"
                            height="20"
                            style={{ color: "#D9D9D9" }}
                          />
                        )}
                      </div>
                    </th>
                  </thead>
                  <tbody className="">
                    {messageList?.length > 0 &&
                      messageList?.map((items, index) => (
                        <tr
                          onClick={() => {
                            setSentItem(items);
                            setOpenModal(true);
                          }}
                          key={index}
                          className={`${"cursor-pointer"}`}
                        >
                          <td
                            className={` ${
                              sentItem.id == items.id
                                ? "!border-r-0 border-[#CCFF00] bg-[#353638]"
                                : ""
                            } 
                          ${"text-white font-bold text-lg relative "}`}
                          >
                            <div className="flex items-center gap-[2rem] px-3">
                              <p className="mb-0 text-lg font-bold w-[30px] text-center">
                                {index + 1}.
                              </p>
                              <p className="mb-0 text-lg font-bold flex items-center gap-2">
                                {moment(items?.regDate).format("YY.MM.DD")}
                              </p>
                            </div>
                          </td>
                          <td
                            className={`${
                              sentItem.id == items.id
                                ? "!border-x-0 border-[#CCFF00] bg-[#353638]"
                                : ""
                            }  ${"!text-center text-[18px] px-4 font-bold text-white "}`}
                          >
                            {moment(items?.regDate).format("h:mm")}
                          </td>
                          <td
                            className={`${
                              sentItem.id == items.id
                                ? "!border-x-0 border-[#CCFF00] bg-[#353638]"
                                : ""
                            }  ${"!text-center text-[18px] font-bold text-white "}`}
                          >
                            {CHAT_TYPES[items?.chatTypeValue]}
                          </td>
                          <td
                            className={`${
                              sentItem.id == items.id
                                ? "!border-l-0 border-[#CCFF00] bg-[#353638]"
                                : ""
                            }  ${"!text-center text-[18px] font-bold text-white "}`}
                          >
                            <p className="mb-0 text-lg font-bold text-start px-3">
                              {items?.message}
                            </p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}

          {page && page > 1 && (
            <div className="w-full">
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  forcePage={offset == 1 ? 0 : offset - 1}
                  pageCount={5}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
          <div className="flex items-center justify-center mt-7 mb-3">
            <button
              onClick={() => {
                setMessageList(false);
              }}
              className={`${"text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px]"}`}
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SentMessageList;
