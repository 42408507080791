import React, { useState, useEffect } from "react";
import { strings } from "../../../../../utils/String";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import Loader from "../../../../../utils/Loader";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";

function SportEuipmentInfo({
  setsportEuipmentInfo,
  freeWeightData,
  setFreeWeightData,
  setaddInformation,
  classNameValue,
  setIsLoader,
}) {
  const { token } = useSelector((state) => state.user);
  const [isEdit, setIsEdit] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [tempData, setTempData] = useState([...freeWeightData]); // Temporary state for editing
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  const handleEdit = (quantity, id) => {
    setIsEdit(true);
    const newData = [...editedData];
    const present = newData?.find((data) => data?.id == id);
    if (present) {
      present.quantity = +quantity;
    } else {
      newData.push({
        id: id,
        quantity: +quantity,
      });
    }
    setEditedData(newData);

    // Update the tempData with the new quantity
    const updatedTempData = tempData.map((item) =>
      item.id === id ? { ...item, quantity: quantity } : item
    );
    setTempData(updatedTempData);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setsportEuipmentInfo(false);
    setTempData([...freeWeightData]); // Reset to original data
  };

  async function editMachine() {
    setIsLoader(true);
    const updateded = {
      equipments: editedData,
    };

    await GymSettingSublyApi.editFreeWeight(token, updateded)
      .then((response) => {
        if (response.statusCode == 200) {
          setFreeWeightData(tempData); // Update the main state with tempData
          setsportEuipmentInfo(false);
          setIsLoader(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <section className="absolute top-0 left-auto right-0 !w-full !h-full flex items-center justify-center bg-[#000000c2] z-20">
      <div
        className={`${classNameValue} bg-[#2C2C2E] rounded-[10px] w-[70%] max-h-[90%] max-lg:p-4 max-lg:py-8 overflow-auto p-[60px] pb-4 z-20`}
      >
        <div className="w-[100%] max-lg:w-[100%] m-auto">
          <div className="sm:w-[70%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-[30px]">
              {strings.SPORTS_EQUIPMENT_INFORMATION}
            </p>
          </div>

          <div className="text-white my-[30px] 2xl:px-40 min-w-full max-w-[600px] overflow-x-auto">
            <table className="w-[1000px] 2xl:w-full table-fixed">
              <thead className="h-[50px]">
                <tr className="bg-[#353638]">
                  <th className="text-[20px] font-bold text-center w-[60%]">
                    Title
                  </th>
                  <th className="text-[20px] font-bold text-center w-[20%]">
                    Quantity
                  </th>
                  <th className="text-[20px] font-bold text-center w-[20%]">
                    Unit
                  </th>
                </tr>
              </thead>
              <tbody>
                {tempData?.map((items, index) => (
                  <tr
                    key={index}
                    style={{ border: "1px solid #444444" }}
                    className="border-t-0 h-[42px]"
                  >
                    <td className="ps-[25px] text-[18px] font-bold">
                      {items?.title}
                    </td>
                    {isEdit ? (
                      <input
                        className="border-[1px] border-[#4E4E4E] rounded-[10px] bg-[#2C2C2E] text-center w-28 m-2 p-2 text-white font-bold"
                        value={items?.quantity}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            handleEdit(value, items.id);
                          }
                        }}
                      />
                    ) : (
                      <td
                        style={{ border: "1px solid #444444" }}
                        className="text-center border-y-0"
                      >
                        {items?.quantity}
                      </td>
                    )}
                    <td className="text-center">
                      {items?.unit == null || undefined ? "-" : items.unit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-[50px] mb-4">
            <button
              onClick={handleCancel}
              className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
            {isEdit && (
              <button
                className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                onClick={editMachine}
              >
                {strings.UPDATE}
              </button>
            )}
            {!isEdit && (
              <>
                <button
                  className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                  onClick={() => setIsEdit(true)}
                >
                  {strings.EDIT}
                </button>
                {/* <button
                  className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black kakaoClass hover:text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                  onClick={() => {
                    setaddInformation(true);
                    setsportEuipmentInfo(false);
                  }}
                >
                  {strings.ADD}
                </button> */}
              </>
            )}
          </div>
        </div>
      </div>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default SportEuipmentInfo;
