import React, { useState } from "react";
import { strings } from "../../../utils/String";
import { InputGroup, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Icon } from "@iconify-icon/react";
import { useSelector } from "react-redux";
import MembersSublyApi from "../../../helper/MemberApi";
import Moment from "react-moment";
import Loader from "../../../utils/Loader";

function MemberTakeOver({
  classNameValue,
  openTakeOver,
  setOpenTakeOver,
  userCode,
  setIsLoader,
  setIsUpdate,
  isUpdate,
  id,
}) {
  const { token } = useSelector((state) => state.user);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [userData, setUserData] = useState("");
  const [productId, setProductId] = useState([]);

  // ============API for getting member code details============
  async function fetchMemberCodehandle() {
    setIsLoader(true);
    await MembersSublyApi.fetchMemberCode(otp, token, id)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          if (response.data?.memberOwnProducts) {
            setUserData(response.data);
            setError("");
          } else {
            setError(strings.NO_USER_FOUND);
            setUserData("");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ==============API for take over product or membership============
  async function takeOverHandle() {
    setIsLoader(true);
    const requestData = {
      takeOverFrom: userData?.userCode,
      takeOverTo: userCode,
      memberOwnProductIds: productId,
    };
    await MembersSublyApi.takeOver(requestData, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setIsUpdate(!isUpdate);
          setOpenTakeOver(false);
          setUserData("");
          setOtp("");
          setError("");
          setProductId([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass classForMargin"}`}
        show={openTakeOver}
        onHide={() => {
          setOpenTakeOver(false);
          setUserData("");
          setOtp("");
          setError("");
          setProductId([]);
          setIsUpdate(!isUpdate);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8 overflow-auto max-h-[calc(100vh_-_150px)]">
          <div className="flex items-center justify-between border-b-2 border-[#444444]">
            <p></p>
            <p className=" !mb-0 text-[40px] max-lg:text-[32px] font-extrabold pb-3">
              {strings.TAKE_OVER}
            </p>
            <Icon
              icon="maki:cross"
              width="24"
              height="24"
              style={{ color: "white" }}
              onClick={() => {
                setOpenTakeOver(false);
                setUserData("");
                setOtp("");
                setError("");
                setProductId([]);
                setIsUpdate(!isUpdate);
              }}
              className="cursor-pointer"
            />
          </div>
          {/* <div className="flex justify-center mt-3">
            <div>
              {userData?.memberOwnProducts
                ? Object.keys(userData?.memberOwnProducts).map(
                    (items, index) => (
                      <div
                        className="flex justify-start items-center gap-1"
                        key={index}
                      >
                        <InputGroup className="checkboxClass">
                          <InputGroup.Checkbox
                            className="w-[31px] h-[31px] bg-[#CECECE80]"
                            onChange={(e) => {
                              if (e.target.checked) {
                                const id = [...productId];
                                id.push(userData?.memberOwnProducts[items].id);
                                setProductId(id);
                              } else {
                                const id = [...productId];
                                id.splice(index, 1);
                                setProductId(id);
                              }
                            }}
                          />
                        </InputGroup>

                        {items !== "PT" ? (
                          <p
                            className={`text-[22px] max-lg:text-base text-start font-bold mb-0 flex items-center gap-2`}
                          >
                            {userData?.memberOwnProducts[items].productName}
                            <Icon
                              icon="lets-icons:date-today"
                              width="25"
                              height="25"
                              style={{ color: "#CCFF00" }}
                            />
                            <Moment format="YY.MM.DD">
                              {
                                userData?.memberOwnProducts[items]
                                  ?.membershipStartDate
                              }
                            </Moment>{" "}
                            {"~"}{" "}
                            <Moment format="YY.MM.DD">
                              {
                                userData?.memberOwnProducts[items]
                                  ?.membershipEndDate
                              }
                            </Moment>
                          </p>
                        ) : (
                          <p className="text-[22px] max-lg:text-base font-bold mb-0 flex items-center gap-2">
                            {userData?.memberOwnProducts[items].productName} ,{" "}
                            {`(${
                              userData?.memberOwnProducts[items].sessionCount -
                              userData?.memberOwnProducts[items]
                                .sessionCompleted
                            } sessions left)`}
                          </p>
                        )}
                      </div>
                    )
                  )
                : ""}
            </div>
          </div> */}
          <p className="text-[30px] max-lg:text-[20px] font-bold my-6">
            {strings.TAKE_OVER_TO_MEMBER_CODE}
          </p>
          <div className=" bg-[#1F2022] rounded-[15px] text-[30px] w-[400px] m-auto font-bold flex items-center justify-center gap-4 py-2">
            #
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              containerStyle={"justify-center gap-4"}
              inputStyle={
                "text-center text-3xl bg-[#1F2022] border-b border-[#444444] text-white"
              }
            />
          </div>
          <div className="flex justify-center my-4">
            <button
              disabled={otp ? false : true}
              onClick={() => {
                fetchMemberCodehandle();
              }}
              className="bg-common_color rounded-[28px] h-[40px] w-[104px] text-black text-sm font-extrabold disabled:bg-[#ccff0094]"
            >
              {strings.SEARCH}
            </button>
          </div>
          <div className="flex justify-center mt-3 mb-3">
            {/* <div>
              {userData?.productsList
                ? Object.keys(userData?.productsList).map((items, index) => (
                    <div
                      className="flex justify-start items-center gap-1"
                      key={index}
                    >
                      <InputGroup className="checkboxClass">
                        <InputGroup.Checkbox
                          className="w-[31px] h-[31px] bg-[#CECECE80]"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const id = [...productId];
                              id.push(userData?.productsList[items].id);
                              setProductId(id);
                            } else {
                              const id = [...productId];
                              id.splice(index, 1);
                              setProductId(id);
                            }
                          }}
                        />
                      </InputGroup>

                      {items !== "PT" ? (
                        <p
                          className={`text-[22px] max-lg:text-base text-start font-bold mb-0 flex items-center gap-2`}
                        >
                          {userData?.memberOwnProducts[items].productName}
                          <Icon
                            icon="lets-icons:date-today"
                            width="25"
                            height="25"
                            style={{ color: "#CCFF00" }}
                          />
                          <Moment format="YY.MM.DD">
                            {
                              userData?.memberOwnProducts[items]
                                ?.membershipStartDate
                            }
                          </Moment>{" "}
                          {"~"}{" "}
                          <Moment format="YY.MM.DD">
                            {
                              userData?.memberOwnProducts[items]
                                ?.membershipEndDate
                            }
                          </Moment>
                        </p>
                      ) : (
                        <p className="text-[22px] max-lg:text-base font-bold mb-0 flex items-center gap-2">
                          {userData?.productsList[items].productName} ,{" "}
                          {`(${
                            userData?.productsList[items].sessionCount -
                            userData?.productsList[items].sessionCompleted
                          } sessions left)`}
                        </p>
                      )}
                    </div>
                  ))
                : ""}
            </div> */}
            <div>
              {userData?.productsList
                ? Object.values(userData?.productsList)
                    .flat(2)
                    .map((items, index) => (
                      <div
                        className="flex justify-start items-center gap-1"
                        key={index}
                      >
                        <InputGroup className="checkboxClass">
                          <InputGroup.Checkbox
                            className="w-[31px] h-[31px] bg-[#CECECE80]"
                            onChange={(e) => {
                              if (e.target.checked) {
                                const id = [...productId];
                                id.push(items?.id);
                                setProductId(id);
                              } else {
                                const id = [...productId];
                                id.splice(index, 1);
                                setProductId(id);
                              }
                            }}
                          />
                        </InputGroup>

                        {items?.category !== "PT" ? (
                          <p
                            className={`text-[22px] max-lg:text-base text-start font-bold mb-0 flex items-center gap-2`}
                          >
                            {items.productName}
                            <Icon
                              icon="lets-icons:date-today"
                              width="25"
                              height="25"
                              style={{ color: "#CCFF00" }}
                            />
                            <Moment format="YY.MM.DD">
                              {items?.membershipStartDate}
                            </Moment>{" "}
                            {"~"}{" "}
                            <Moment format="YY.MM.DD">
                              {items?.membershipEndDate}
                            </Moment>
                          </p>
                        ) : (
                          <p className="text-[22px] max-lg:text-base font-bold mb-0 flex items-center gap-2">
                            {items.productName} ,{" "}
                            {`(${
                              items.sessionCount - items.sessionCompleted
                            } sessions left)`}
                          </p>
                        )}
                      </div>
                    ))
                : ""}
            </div>
          </div>
          {error && (
            <p className="text-[30px] max-lg:text-lg font-extrabold text-[#FFB0B0] my-5">
              {error}
            </p>
          )}
          {userData && (
            <div className="bg-[#414142] w-[400px] m-auto rounded-[10px] p-4">
              <div className="flex items-center justify-between mb-3">
                <p className="mb-0 text-lg font-bold">{strings.MEMBER_NAME}</p>
                <div className="flex itmes-center gap-3">
                  <Icon
                    icon="lucide:user-round"
                    width="24"
                    height="24"
                    style={{ color: "#CCFF00" }}
                  />
                  <p className="text-xl font-extrabold mb-0 max-lg:text-base">
                    {userData?.name}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p className="mb-0 text-lg font-bold">{strings.CELL_PHONE}</p>
                <div className="flex itmes-center gap-3">
                  <Icon
                    icon="heroicons-outline:phone"
                    width="24"
                    height="24"
                    style={{ color: "#CCFF00" }}
                  />
                  <p className="text-xl max-lg:text-base font-extrabold mb-0">
                    {userData?.cellphone}
                  </p>
                </div>
              </div>
            </div>
          )}
          {productId.length > 0 && (
            <div className="flex justify-center mt-6">
              <button
                disabled={productId.length > 0 ? false : true}
                onClick={() => {
                  takeOverHandle();
                }}
                className="text-lg font-extrabold text-white kakaoClass h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.CONFIRM}
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MemberTakeOver;
