import React from "react";
import { Modal } from "react-bootstrap";
import { CloseButton } from "../AuthButton";
import "../../../assests/CommonStyle.css";
import { Icon } from "@iconify-icon/react";
import { getToken, selectYourGymHandle } from "../../../Store/slices/AuthSlice";
import { useDispatch } from "react-redux";

function WelcomeModal({ showModal, setShowModal, userName, gymData }) {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        className="modalBoxClass"
        show={showModal}
        onHide={() => {
          setShowModal(false);
          dispatch(selectYourGymHandle(gymData));
          dispatch(getToken(gymData?.token));
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-16 px-12">
          <p className="rounded-full border-[35px] border-[#A6FF3466] w-36 h-36 m-auto flex items-center justify-center max-xl:border-[20px] max-xl:w-28 max-xl:h-28">
            <Icon
              icon="icon-park-solid:check-one"
              width="100"
              height="100"
              style={{ color: "#A6FF34" }}
              className="max-xl:hidden"
            />
            <Icon
              icon="icon-park-solid:check-one"
              width="80"
              height="80"
              style={{ color: "#A6FF34" }}
              className="hidden max-xl:block"
            />
          </p>
          <p className="text-[40px] max-xl:text-[28px] font-bold mt-10 mb-10">
            Welcome {userName || ""}! <br /> Have a Nice day!
          </p>
          <div className="flex justify-center">
            <CloseButton
              clickFun={() => {
                setShowModal(false);
                dispatch(selectYourGymHandle(gymData));
                dispatch(getToken(gymData?.token));
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WelcomeModal;
