import React from "react";
import { Modal } from "react-bootstrap";
// import logo from "../assests/attendanceIcons/logo-icon.svg";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { strings } from "../../../../utils/String";
import logo from "../../../../assests/icons/auth-confirm.svg";

function AuthModal({
  authModal,
  setAuthModal,
  subText,
  headText,
  classNameValue,
}) {
  return (
    <Modal
      className={`${classNameValue} ${"modalBoxClass"}`}
      show={authModal}
      onHide={() => {
        setAuthModal(false);
      }}
    >
      <Modal.Body className="text-white bg-box_bg text-center py-14 px-14">
        <img src={logo} alt="logo" className="mx-auto" />

        <p className=" my-5 text-[36px] font-extrabold leading-tight">
          {strings.AUTHENTICATION_SENT_TO_EMAIL}{" "}
        </p>
        <div className="flex justify-center items-center pt-2 gap-4">
          <button
            onClick={() => {
              setAuthModal(false);
            }}
            className="text-[20px] h-[55px] font-extrabold flex justify-center items-center gap-1.5 bg-[#CCFF00] text-black w-[155px] rounded-[28px]"
          >
            {strings.CONFIRM}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AuthModal;
