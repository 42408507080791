import React, { useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import handIcon from "../../../assests/icons/service-icon.svg";
import infoAdd from "../../../assests/icons/infoAdd-icon.svg";
import logo from "../../../assests/icons/Logo.svg";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { useDaumPostcodePopup } from "react-daum-postcode";
import AuthSublyApi from "../../../helper/AuthApi";
import Loader from "../../../utils/Loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function MemberNotFound() {
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [centerName, setCenterName] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // =============Code for fetch address through zip code============
  const open = useDaumPostcodePopup(
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );
  const handleClick = () => {
    open({ onComplete: handleComplete, width: "400px", height: "400px" });
  };

  const handleComplete = (data) => {
    let fullAddress = data.addressEnglish;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setAddressOne(fullAddress);
  };

  //   ==============API for saving center handler=================

  async function saveCenterHandler() {
    setIsLoader(true);
    const requestData = {
      name: "The fitness hub",
      address: addressOne,
      ...(addressTwo && { address2: addressTwo }),
    };
    await AuthSublyApi.saveCenter(token, requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      {isLoader ? <Loader /> : ""}
      <div className="flex items-center justify-center mt-14">
        <img src={logo} className="w-[135px] h-[65px]" />
      </div>
      <h3 className="text-center text-[48px] font-extrabold my-9 text-white">
        Lorem ipsum dolor sit amet
      </h3>
      <div className="w-[35%] max-lg:w-[60%] max-sm:w-[80%] m-auto">
        <label
          htmlFor="nameId"
          className="bg-[#414142] w-full h-[60px] px-4 py-4 flex items-center gap-2 rounded-[30px] mx-auto"
        >
          <input
            type="text"
            value={centerName}
            onChange={(e) => {
              setCenterName(e.target.value);
            }}
            className="bg-[#414142] text-white w-full text-xl font-bold placeholder:text-[#949393] placeholder:text-lg"
            id="name"
            placeholder={`Enter center's name`}
            autoComplete="off"
          />
        </label>
        <label
          onClick={handleClick}
          htmlFor="zipCode"
          className="bg-[#414142] w-full h-[60px] pr-5 pl-5 py-4 my-[24px] flex items-center gap-2 rounded-[30px] mx-auto"
        >
          <input
            autoComplete="off"
            type="text"
            value={addressOne}
            className="bg-[#414142] text-white w-full caret-transparent cursor-pointer text-xl font-bold placeholder:text-[#949393] placeholder:text-lg "
            id="zipCode"
            placeholder={`${placeholder.ADDRESS} 1:`}
          />
          <Icon
            icon="lucide:search"
            width="32"
            height="32"
            style={{ color: "white", cursor: "pointer" }}
          />
        </label>
        <label
          htmlFor="nameId"
          className="bg-[#414142] w-full h-[60px] px-4 py-4 flex items-center gap-2 rounded-[30px] mx-auto"
        >
          <input
            type="text"
            value={addressTwo}
            onChange={(e) => {
              setAddressTwo(e.target.value);
            }}
            className="bg-[#414142] text-white w-full text-xl font-bold placeholder:text-[#949393] placeholder:text-lg "
            id="name"
            placeholder={`${placeholder.ADDRESS} 2:`}
            autoComplete="off"
          />
        </label>
      </div>
      <div className="w-[55%] max-lg:w-[70%] max-sm:w-full mx-auto md:mx-5 mt-4 md:flex gap-4">
        <div className="bg-[#353638] rounded-[10px] p-2 pt-4 my-3">
          <img src={handIcon} className="mx-auto" />
          <p className="text-center text-[26px] font-bold mt-3">
            You can use this service if your center utilizes Thejal. If there is
            a delay in processing at the center, the guidance message may be
            delayed.
          </p>
        </div>
        <div className="bg-[#353638] rounded-[10px] p-2 pt-4 my-3">
          <img src={infoAdd} className="mx-auto" />
          <p className="text-center text-[26px] font-bold mt-3">
            You can use this service if your center utilizes Thejal. If there is
            a delay in processing at the center, the guidance message may be
            delayed.
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-3 flex-wrap mt-4">
        <button
          onClick={() => {
            navigate("/");
          }}
          className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
        >
          {strings.CLOSE}
        </button>
        <button
          onClick={() => {
            saveCenterHandler();
          }}
          disabled={centerName && addressOne ? false : true}
          className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
        >
          {strings.CONFIRM}
        </button>
      </div>
    </div>
  );
}

export default MemberNotFound;
