import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import won from "../../../assests/icons/won-currancy gray.svg";
import wonWhite from "../../../assests/icons/won-currancy white.svg";
import starCalender from "../../../assests/icons/event-detail-icons/StarCalenderIcon.svg";
import addTime from "../../../assests/icons/event-detail-icons/addTimeIcon.svg";
import tickCaleder from "../../../assests/icons/event-detail-icons/rightCalenderIcon.svg";
import period from "../../../assests/icons/event-detail-icons/periodIcon.svg";
import ManagementSublyApi from "../../../helper/ManagementApi";
import { useSelector } from "react-redux";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import DeleteConfirmation from "../../commonComponents/modalScreens/DeleteConfirmation";
import { trim } from "jquery";
import { EVENT_TYPES } from "../../../utils/CommonData";
import DeleteSuccess from "../../commonComponents/modalScreens/DeleteSuccess";
import { FormMedia } from "../boardScreens/RegistrationForm";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import { formatPrice } from "../../../utils/commonUtils";

function EventDetail({
  setOpenDetail,
  id,
  setProductId,
  setIsLoader,
  setDefaultValue,
  setOpenRegister,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [detailValue, setDetailValue] = useState("");
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [videoPreview, setVideoPreview] = useState(false);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  // =============Fetch event detail API===============
  useEffect(() => {
    async function fetchEventDetail() {
      setIsLoader(true);
      await ManagementSublyApi.eventDetail(token, id)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setDetailValue(response.data);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchEventDetail();
  }, []);

  // =================API for delete event=================
  async function deleteEventHandle() {
    setIsLoader(true);
    await ManagementSublyApi.deleteEvent(token, id)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setDeleteAlert(false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[70%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.EVENT_INFORMATION}
            </p>
          </div>
          <div className="mt-5">
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-2.5  my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-bold">
                  <img
                    src={starCalender}
                    alt="start-calendar"
                    className="w-[25px] h-[25px]"
                  />
                  {strings.NAME_}
                </p>
                <p className="mb-0 mt-1 text-2xl font-extrabold leading-7 !line-clamp-2 textOverflowClass !break-normal mt-0 sm:w-[85%] max-sm:w-full sm:ml-2">
                  {detailValue?.eventName}
                </p>
              </div>
              <div className="flex items-center gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-bold">
                  <img
                    src={tickCaleder}
                    alt="tick-calendar"
                    className="w-[25px] h-[25px]"
                  />
                  {strings.TYPE}:
                </p>
                <p className="mb-0 text-2xl font-extrabold leading-7 !line-clamp-2 textOverflowClass !break-normal w-[85%] mt-0">
                  {EVENT_TYPES[detailValue?.eventTypeValue]}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-bold">
                  <img
                    src={period}
                    alt="period"
                    className="w-[25px] h-[25px]"
                  />
                  {strings.PERIOD}:{" "}
                </p>
                <p className="mt-1 ml-2 mb-0 text-2xl font-extrabold leading-7 !line-clamp-2 textOverflowClass !break-normal w-[85%]">
                  {detailValue?.periodMonth} Months{" "}
                  {detailValue?.periodDays &&
                    ` + ${detailValue?.periodDays}
                  Days`}
                </p>
              </div>
              <div className="flex items-center gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-bold">
                  <img
                    src={addTime}
                    alt="add-Time"
                    className="w-[25px] h-[25px]"
                  />
                  {strings.ADD}:
                </p>
                <p className="mt-0 mb-0 text-2xl font-extrabold leading-7 !line-clamp-2 textOverflowClass !break-normal w-[85%] mt-1">
                  {detailValue?.addonMonth} Months{" "}
                  {detailValue?.addonDays
                    ? ` + ${detailValue?.addonDays}
                  Days`
                    : ""}
                </p>
              </div>{" "}
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-bold">
                  <img
                    src={period}
                    alt="period"
                    className="w-[25px] h-[25px]"
                  />
                  {strings.COUNT}:
                </p>
                <p className="mt-1 ml-3 mb-0 text-2xl font-extrabold leading-7 !line-clamp-2 textOverflowClass !break-normal w-[85%]">
                  {detailValue?.sessionCount} Sessions
                </p>
              </div>
              <div className="flex items-center gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-bold">
                  <img
                    src={addTime}
                    alt="add-time"
                    className="w-[25px] h-[25px]"
                  />
                  {strings.ADD}:
                </p>
                <p className="mb-0 text-2xl font-extrabold leading-7 !line-clamp-2 textOverflowClass !break-normal w-[85%] mt-0">
                  {detailValue?.addonCount} Session
                </p>
              </div>{" "}
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-bold">
                  <img src={won} alt="won" className="w-[25px] h-[25px]" />
                  {strings.PRICE}:
                </p>
                <p className="mt-1 mb-0 text-2xl flex items-center gap-1 font-extrabold leading-7">
                  <img
                    src={wonWhite}
                    alt="won-white"
                    className="w-[28px] h-[28px]"
                  />
                 {formatPrice(detailValue?.regularPrice) || 0}{" "}
                  {detailValue?.discountPrice ? "-" : ""}{" "}
                  {detailValue?.discountPrice ? (
                    <div className="flex items-center">
                      <img
                        src={wonWhite}
                        className="w-[28px] h-[28px]"
                        alt="Won Symbol"
                      />
                      {formatPrice(detailValue.discountPrice)}
                    </div>
                  ) : null}
                </p>
              </div>
              <div className="flex items-center gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl font-bold">
                  <img src={won} alt="alt" className="w-[25px] h-[25px]" />
                  {strings.FINAL_PRICE}:
                </p>
                <p className="mb-0 mt-1 text-2xl flex items-center gap-1 font-extrabold leading-7">
                  <img
                    src={wonWhite}
                    alt="won-white"
                    className="w-[28px] h-[28px]"
                  />
                  {formatPrice(detailValue?.finalPrice)}{" "}
                </p>
              </div>{" "}
            </div>
          </div>
          <p className="text-2xl font-bold whitespace-pre-wrap break-words my-3.5">
            {detailValue?.eventDescription}
          </p>
          <div className="flex items-center gap-x-3 gap-y-2.5">
            {detailValue?.eventImages?.map((item, index) => (
              <div
                key={index}
                className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] relative"
              >
                <img
                  src={item?.url}
                  alt="item"
                  className="w-full h-full object-cover rounded-[10px]"
                />
                {item?.type == "video" && (
                  <span
                    onClick={() => {
                      setuploadedMedia(item);
                      setVideoPreview(true);
                    }}
                    className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                  >
                    <Icon
                      icon="ooui:play"
                      width="70"
                      height="70"
                      style={{ color: "white" }}
                    />
                  </span>
                )}
              </div>
            ))}
          </div>
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-20 mb-4"}`}
          >
            <button
              onClick={() => {
                setDeleteAlert(true);
              }}
              className={`${"max-sm:w-full text-white kakaoClass hover:text-black hover:bg-common_color "} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
            >
              {strings.DELETE}
            </button>
            <button
              onClick={() => {
                setDefaultValue(detailValue);
                setOpenDetail(false);
                setOpenRegister(true);
                setProductId("");
              }}
              className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.EDIT}
            </button>
            <button
              onClick={() => {
                setOpenDetail(false);
                setProductId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
      <DeleteConfirmation
        textHead={strings.DELETE_EVENT}
        text={strings.DO_YOU_WANT_TO_DELETE_EVENT}
        deleteMemo={deleteAlert}
        setDeleteMemo={setDeleteAlert}
        clickBtn={deleteEventHandle}
        btnText={strings.DELETE}
        classNameValue={classNameValue}
      />
      <DeleteSuccess
        text={strings.EVENT_DELETED_SUCCESSFULLY}
        deleteSuccess={success}
        setDeleteSuccess={setSuccess}
        isDelete={true}
        setIsUpdate={setOpenDetail}
        classNameValue={classNameValue}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default EventDetail;
