import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { InputGroup } from "react-bootstrap";
import BoardSublyApi from "../../../helper/BoardApi";
import { useSelector } from "react-redux";
import won from "../../../assests/icons/won-currancy gray.svg";
import ReactDatePicker from "react-datepicker";
import ManagementSublyApi from "../../../helper/ManagementApi";
import { EVENT_TYPES } from "../../../utils/CommonData";
import { FormMedia } from "../boardScreens/RegistrationForm";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import { formatPrice, parseFormattedPrice } from "../../../utils/commonUtils";
import moment from "moment";
import $ from "jquery";
import { HiCalendar } from "react-icons/hi2";
import { DatePicker } from "rsuite";

function EventRegister({
  setOpenRegister,
  setIsLoader,
  defaultValue,
  setDefaultValue,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [name, setName] = useState("");
  const [isError, setIsError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [innerLoader, setInnerLoader] = useState(false);
  const [requestedData, setRequestedData] = useState("");
  const [error, setError] = useState("");
  const [discription, setDiscription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventType, setEventType] = useState({ text: "", type: null });
  const [openType, setOpenType] = useState(false);
  const [regularPrice, setRegularPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [finalPrice, setFinalPrice] = useState(
    regularPrice
      ? regularPrice && regularPrice - discountPrice > 0
        ? regularPrice - discountPrice
        : 0
      : ""
  );
  const [isAlways, setisAlways] = useState(false);
  const [periodMonth, setperiodMonth] = useState({ month: null, day: null });
  const [periodAddMonth, setperiodAddMonth] = useState({
    month: null,
    day: null,
  });
  const [sessionCount, setSessionsCount] = useState({
    count: null,
    addOn: null,
  });
  const [time, setTime] = useState("");
  const [openPeriod, setOpenPeriod] = useState({ month: false, day: false });
  const [openAddPeriod, setOpenAddPeriod] = useState({
    month: false,
    day: false,
  });
  const [timeDropdown, setTimeDropdown] = useState(false);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [participant, setParticipant] = useState("");
  const [errorHandler, setErrorHandler] = useState({
    name: "",
    eventType: "",
    startDate: "",
    endDate: "",
    discription: "",
    regularPrice: "",
    discountPrice: "",
    Month: "",
    day: "",
    AddMonth: "",
    Addday: "",
    time: "",
    count: "",
    addOn: "",
    participant: "",
  });
  const [openCalender, setOpenCalender] = useState(false);
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [currentEndMonth, setCurrentEndMonth] = useState(
    moment().format("YYYY.MM.DD")
  );
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");

  // ==============In edit case set default value============
  useEffect(() => {
    if (defaultValue) {
      setName(defaultValue?.eventName);
      setDiscription(defaultValue?.eventDescription);
      setImagePreview(defaultValue?.eventImages);
      setRequestedData(defaultValue?.eventImages);
      setRegularPrice(defaultValue?.regularPrice);
      setDiscountPrice(defaultValue?.discountPrice);
      setStartDate(
        defaultValue?.isAlwaysAvailable ? "" : new Date(defaultValue?.startDate)
      );
      setEndDate(
        defaultValue?.isAlwaysAvailable ? "" : new Date(defaultValue?.endDate)
      );
      setEventType({
        text: EVENT_TYPES[defaultValue?.eventTypeValue],
        type: defaultValue?.eventType,
      });
      setisAlways(defaultValue?.isAlwaysAvailable);
      setperiodMonth({
        month: defaultValue?.periodMonth,
        day: defaultValue?.periodDays,
      });
      setperiodAddMonth({
        month: defaultValue?.addonMonth,
        day: defaultValue?.addonDays,
      });
      setSessionsCount({
        count: defaultValue?.sessionCount,
        addOn: defaultValue?.addonCount,
      });
      setTime(defaultValue?.sessionTimeLimit);
      setIsUnlimited(defaultValue?.isUnlimited);
      !defaultValue?.isUnlimited &&
        setParticipant(defaultValue?.participantsCount);
    }
  }, []);

  // ==============FUNCTION TO GET THUMNAIL FO VIDEO=================
  function getVideoThumbnail(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    setError("");
    if (file?.type.match("video")) {
      if (file?.size / 1000000 <= 200) {
        var image = "";
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = async function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              setInnerLoader(true);
              const requestData = new FormData();
              requestData.append("file", file);
              await BoardSublyApi.assestUpload(requestData, token)
                .then((response) => {
                  setInnerLoader(false);
                  if (response.statusCode == 200) {
                    let media = [...imagePreview];
                    const toDeploy = [...requestedData];
                    media.push({
                      url: response.data.compressed,
                      type: "video",
                      view: image,
                      original: response.data.original,
                    });
                    toDeploy.push({
                      url: response.data.compressed,
                      type: "video",
                      original: response.data.original,
                    });
                    setRequestedData(toDeploy);
                    setImagePreview(media);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              const base64String = image; // Base64 string
              const binaryString = atob(base64String.split(",")[1]); // Binary data string
              const blob = new Blob([binaryString], { type: "image/png" });
              // setPassThumbnail(blob);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setError("Video size should be less than 200 MB");
      }
    } else {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result) {
            setInnerLoader(true);
            const requestData = new FormData();
            requestData.append("file", file);
            await BoardSublyApi.assestUpload(requestData, token)
              .then((response) => {
                setInnerLoader(false);
                if (response.statusCode == 200) {
                  let media = [...imagePreview];
                  let toDeploy = [...requestedData];
                  media.push({
                    url: response.data.compressed,
                    type: "image",
                    view: result,
                    original: response.data.original,
                  });
                  toDeploy.push({
                    url: response.data.compressed,
                    type: "image",
                    original: response.data.original,
                  });
                  setRequestedData(toDeploy);
                  setImagePreview(media);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  // ===============function for handling remove selected media=============
  async function removeProduct(url, index) {
    const images = [...imagePreview];
    let toDeploy = [...requestedData];
    images.splice(index, 1);
    setImagePreview(images);
    toDeploy.splice(index, 1);
    setRequestedData(toDeploy);
  } 

  // ================Function for register event API=====================
  async function registerHandle() {
    if (
      !name ||
      eventType.type == null ||
      !discription ||
      (!isAlways && (!startDate || !endDate)) ||
      // !regularPrice ||
      !periodMonth.month ||
      !periodMonth.day ||
      // !periodAddMonth.month ||
      // !periodAddMonth.day ||
      // sessionCount.count === undefined ||
      // sessionCount.count === null ||
      // sessionCount.addOn === undefined ||
      // sessionCount.addOn === null ||
      // !time ||
      (!isUnlimited && !participant)
    ) {
      const errorHandle = {
        ...(name ? { name: "" } : { name: "Please enter Event Name" }),
        ...(eventType && eventType.type !== null
          ? { eventType: "" }
          : { eventType: "Please select Event Type" }),
        ...(discription
          ? { discription: "" }
          : { discription: "Please enter description" }),
        // ...(regularPrice
        //   ? { regularPrice: "" }
        //   : { regularPrice: "Please enter regular price" }),
        ...(!isAlways && !startDate
          ? { startDate: "Please select start date" }
          : { startDate: "" }),
        ...(!isAlways && !endDate
          ? { endDate: "Please select end date" }
          : { endDate: "" }),
        ...(periodMonth.month != null
          ? { month: "" }
          : { month: "Please select Month" }),
        ...(periodMonth.day != null
          ? { day: "" }
          : { day: "Please select day" }),
        // ...(periodAddMonth.month != null
        //   ? { AddMonth: "" }
        //   : { AddMonth: "Please select Month" }),
        // ...(periodAddMonth.day != null
        //   ? { Addday: "" }
        //   : { Addday: "Please select day" }),
        // ...(sessionCount.count != null
        //   ? { count: "" }
        //   : { count: "Please enter the value of session count" }),
        // ...(sessionCount.addOn != null
        //   ? { addOn: "" }
        //   : { addOn: "Please enter the value of session" }),
        // ...(time ? { time: "" } : { time: "Please select time" }),
        ...(!isUnlimited && !participant
          ? { participant: "Please enter the value of participant" }
          : { participant: "" }),
      };
      setErrorHandler(errorHandle);
      return;
    }

    setIsLoader(true);
    const requestData = {
      eventName: name?.trim(),
      eventDescription: discription?.trim(),
      ...(!isAlways && { startDate: startDate, endDate: endDate }),
      eventType: eventType.type,
      regularPrice: regularPrice || 0,
      discountPrice: discountPrice || 0,
      finalPrice: finalPrice || regularPrice - discountPrice,
      eventImages: requestedData || [],
      isAlwaysAvailable: isAlways,
      periodMonth: periodMonth.month,
      periodDays: periodMonth.day,
      addonMonth: periodAddMonth.month || 0,
      addonDays: periodAddMonth.day || 0,
      sessionCount: sessionCount.count || 0,
      addonCount: sessionCount.addOn || 0,
      sessionTimeLimit: time || 0,
      ...(!isUnlimited && { participantsCount: participant }),
      isUnlimited: isUnlimited,
    };

    try {
      const response = await ManagementSublyApi.registerEvent(
        token,
        requestData
      );
      setIsLoader(false);
      if (response.statusCode === 200) {
        setOpenRegister(false);
      } else {
        setOpenModal(true);
        seterrorMessage(response.data.message);
      }
    } catch (error) {
      setIsLoader(false);
      console.error(error);
    }
  }

  // ================Function for update event API=====================
  async function editHandle() {
    if (
      !name ||
      eventType.type == null ||
      !discription ||
      (!isAlways && (!startDate || !endDate)) ||
      // !regularPrice ||
      !periodMonth.month ||
      !periodMonth.day ||
      // !periodAddMonth.month ||
      // !periodAddMonth.day ||
      // sessionCount.count === undefined ||
      // sessionCount.count === null ||
      // sessionCount.addOn === undefined ||
      // sessionCount.addOn === null ||
      // !time ||
      (!isUnlimited && !participant)
    ) {
      const errorHandle = {
        ...(name ? { name: "" } : { name: "Please enter Event Name" }),
        ...(eventType && eventType.type !== null
          ? { eventType: "" }
          : { eventType: "Please select Event Type" }),
        ...(discription
          ? { discription: "" }
          : { discription: "Please enter description" }),
        // ...(regularPrice
        //   ? { regularPrice: "" }
        //   : { regularPrice: "Please enter regular price" }),
        ...(!isAlways && !startDate
          ? { startDate: "Please select start date" }
          : { startDate: "" }),
        ...(!isAlways && !endDate
          ? { endDate: "Please select end date" }
          : { endDate: "" }),
        ...(periodMonth.month != null
          ? { month: "" }
          : { month: "Please select Month" }),
        ...(periodMonth.day != null
          ? { day: "" }
          : { day: "Please select day" }),
        // ...(periodAddMonth.month != null
        //   ? { AddMonth: "" }
        //   : { AddMonth: "Please select Month" }),
        // ...(periodAddMonth.day != null
        //   ? { Addday: "" }
        //   : { Addday: "Please select day" }),
        // ...(sessionCount.count != null
        //   ? { count: "" }
        //   : { count: "Please enter the value of session count" }),
        // ...(sessionCount.addOn != null
        //   ? { addOn: "" }
        //   : { addOn: "Please enter the value of session" }),
        // ...(time ? { time: "" } : { time: "Please select time" }),
        ...(!isUnlimited && !participant
          ? { participant: "Please enter the value of participant" }
          : { participant: "" }),
      };
      setErrorHandler(errorHandle);
      return;
    }
    setIsLoader(true);
    const requestData = {
      eventName: name?.trim(),
      eventDescription: discription?.trim(),
      ...(!isAlways && { startDate: startDate, endDate: endDate }),
      eventType: eventType.type,
      ...(!isUnlimited && { participantsCount: participant }),
      regularPrice: regularPrice || 0,
      discountPrice: discountPrice || 0,
      finalPrice: finalPrice ? finalPrice : regularPrice - discountPrice,
      eventImages: requestedData || [],
      isAlwaysAvailable: isAlways,
      periodMonth: periodMonth.month,
      periodDays: periodMonth.day,
      addonMonth: periodAddMonth.month || 0,
      addonDays: periodAddMonth.day || 0,
      sessionCount: sessionCount.count || 0,
      addonCount: sessionCount.addOn || 0,
      sessionTimeLimit: time || 0,
      isUnlimited: isUnlimited,
    };
    await ManagementSublyApi.EditDetail(token, defaultValue?.id, requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setOpenRegister(false);
          setDefaultValue("");
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .then((error) => {
        console.log(error);
      });
  }

  //error handling logic for description
  const handleDescriptionChange = (e) => {
    setDiscription(e.target.value);

    // if (e.target.value === "") {
    //   setError("Description cannot be empty.");
    //   setErrorHandler({ discription: true });
    // } else {
    //   setError("");
    //   setErrorHandler({ discription: false });
    // }
  };

  //logic for date validation
  const validateDates = (startDate, endDate) => {
    let startError = "";
    let endError = "";

    if (!isAlways) {
      if (!startDate) {
        startError = "Start date is required";
      }
      if (!endDate) {
        endError = "End date is required";
      }
      if (startDate && endDate && startDate > endDate) {
        endError = "End date must be after start date";
      }
    }

    setError({ startDate: startError, endDate: endError });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setOpenCalender({ startDuration: !openCalender.startDuration });
    validateDates(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setOpenCalender({ endDuration: !openCalender.endDuration });
    validateDates(startDate, date);
  };

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });
  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonth).getMonth();
    return condition;
  };
  const filterEndMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentEndMonth).getMonth();
    return condition;
  };

  // logic for session count chnage
  const handleSessionCountChange = (e, field) => {
    const value = e.target.value;
    let error = "";

    // Validate the session count value
    if (value < 0) {
      error = "Session count cannot be negative.";
    } else if (value > 100000) {
      error = "Session count cannot exceed 1000.";
    }

    setSessionsCount((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    setErrorHandler((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setOpenType(false);
      setOpenPeriod({ month: false, day: false });
      setOpenAddPeriod({ month: false, day: false });
      setTimeDropdown(false);
    }
  });

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }

    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);
  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[70%] max-lg:w-[90%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[80%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {!defaultValue
                ? strings.REGISTER_EVENT
                : strings.EDIT_EVENT_INFORMATION}
            </p>
          </div>
          <div className="pt-8 pb-3 w-[95%] m-auto">
            <div className="flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap">
              <label
                htmlFor="nameId"
                className={`w-full mb-2.5 ${
                  errorHandler?.name && !name ? "inputErrorClass" : ""
                }`}
              >
                <span
                  className={`text-base font-normal ml-2 ${
                    errorHandler?.name && !name
                      ? "text-[#F87171]"
                      : "text-[#ffffff]"
                  }`}
                >
                  {strings.TITLE}*
                </span>
                <input
                  type="text"
                  className={`bg-[#414142] font-bold placeholder:font-normal rounded-[10px] h-[45px] px-3 text-white w-full ${
                    errorHandler?.name && !name ? "errorClass" : ""
                  }`}
                  id="nameId"
                  autoComplete="off"
                  value={name}
                  onChange={(e) => {
                    const value = e.target.value;
                    // const regex = /^[a-zA-Z가-힣- ]*$/;
                    // if (regex.test(value) || value === "") {
                    setName(value);
                    // }
                  }}
                  placeholder={placeholder.EVENT_NAME}
                />
                {errorHandler?.name && !name && (
                  <p className="text-base text-[#F87171] mb-0">
                    {errorHandler?.name}
                  </p>
                )}
              </label>

              <label
                htmlFor="nameId"
                onClick={() => {
                  setOpenType(!openType);
                  setOpenAddPeriod({ month: false, day: false });
                  setOpenPeriod({ month: false, day: false });
                  setTimeDropdown(false);
                }}
                className={`${
                  isError && !eventType.type ? "inputErrorClass" : ""
                } w-full mb-2.5 relative dropdownClass`}
              >
                <span
                  className={`text-base font-normal ml-2 ${
                    errorHandler?.month && eventType?.type == null
                      ? "text-[#F87171]"
                      : "text-[#ffffff]"
                  }`}
                >
                  {strings.TYPE}*
                </span>
                <div
                  className={`flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 ${
                    errorHandler?.eventType && eventType?.type == null
                      ? "errorClass"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    className="text-white bg-[#414142] font-bold placeholder:font-normal !border-0"
                    id="nameId"
                    value={eventType.text}
                    disabled
                    placeholder={placeholder.SELECT_EVENT_TYPE}
                  />
                  {openType ? (
                    <Icon
                      icon="flowbite:caret-up-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                    />
                  ) : (
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                    />
                  )}
                </div>
                {openType && (
                  <ProductFilter
                    setEventType={setEventType}
                    setErrorHandler={setErrorHandler}
                    errorHandler={errorHandler}
                    classValue={openType ? "dropdownClass" : "hideNotify"}
                  />
                )}
                {errorHandler?.eventType && eventType?.type == null && (
                  <p className="text-base text-[#F87171] mb-0">
                    {errorHandler?.eventType}
                  </p>
                )}
              </label>
            </div>
            <div className="flex mb-3">
              <div
                className={`${
                  isUnlimited ? "opacity-[0.3]" : ""
                } ${"flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap w-[510px]"}`}
              >
                <div className="w-full">
                  {" "}
                  <label htmlFor="nameId" className="w-full numberFieldClass">
                    <span
                      className={`${
                        errorHandler?.participant && !participant
                          ? "text-[#F87171]"
                          : "text-white"
                      } ${"text-base font-normal ml-2"}`}
                    >
                      {placeholder.PARTICIPANTS}
                    </span>
                    <input
                      type="number"
                      className={`${
                        errorHandler?.participant && !participant
                          ? "errorClass placeholder:text-[#F87171]"
                          : ""
                      } ${"bg-[#414142] font-bold placeholder:font-normal rounded-[10px] h-[45px] px-3 text-white w-full numberField"}`}
                      id="nameId"
                      autoComplete="off"
                      disabled={isUnlimited}
                      onChange={(e) => setParticipant(e.target.value)}
                      value={participant}
                      placeholder={placeholder.PARTICIPANTS}
                    />
                  </label>
                  {errorHandler?.participant && !participant && (
                    <p className="text-base text-[#F87171] mb-0">
                      {errorHandler?.month}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center ml-5 -mb-4 ">
                <InputGroup className="checkboxClass paddingManage">
                  <InputGroup.Checkbox
                    className="w-[31px] h-[31px] bg-[#CECECE80]"
                    checked={isUnlimited}
                    onChange={() => {
                      setIsUnlimited(!isUnlimited);
                      setParticipant("");
                      setErrorHandler({ ...errorHandler, participant: "" });
                    }}
                  />
                </InputGroup>
                <p className="text-base font-bold font-large -mb-2">
                  {strings.UNLIMITED}
                </p>
              </div>
            </div>

            <div>
              <p className="mb-0 text-base font-normal text-[#ffffff] ">
                {strings.EVENT_SALE}
              </p>
              <div className="flex items-center gap-3">
                <div className="flex items-center ">
                  <InputGroup className="checkboxClass paddingManage">
                    <InputGroup.Checkbox
                      className="w-[31px] h-[31px] bg-[#CECECE80]"
                      checked={isAlways}
                      onChange={() => {
                        setStartDate("");
                        setEndDate("");
                        setisAlways(true);
                        setErrorHandler((prevState) => ({
                          ...prevState,
                          startDate: "",
                          endDate: "",
                        }));
                      }}
                    />
                  </InputGroup>
                  <p className="mb-0 text-base font-bold">
                    {strings.ALWAYS_AVAILABLE}
                  </p>
                </div>
                <div className="flex items-center ">
                  <InputGroup className="checkboxClass paddingManage">
                    <InputGroup.Checkbox
                      className="w-[31px] h-[31px] bg-[#CECECE80]"
                      checked={!isAlways}
                      onChange={() => {
                        setisAlways(false);
                        setStartDate("");
                        setEndDate("");
                        setErrorHandler((prevState) => ({
                          ...prevState,
                          startDate: "",
                          endDate: "",
                        }));
                      }}
                    />
                  </InputGroup>
                  <p className="mb-0 text-base font-bold">
                    {strings.SELECT_PERIODL}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap justify-between">
              <div className="mt-1.5 w-full">
                <p
                  className={`text-base font-normal ml-2 mb-0 ${
                    errorHandler?.month && !periodMonth.month
                      ? "text-[#F87171]"
                      : "text-[#ffffff]"
                  }`}
                >
                  {strings.PERIOD}
                </p>
                <div className="flex items-center gap-x-3 gap-y-3 max-lg:flex-wrap w-full ">
                  <div className="relative w-full">
                    <div
                      onClick={() => {
                        setOpenPeriod({
                          month: !openPeriod.month,
                          day: false,
                        });
                        setOpenType(false);
                        setOpenAddPeriod({ month: false, day: false });
                        setTimeDropdown(false);
                      }}
                      className={`cursor-pointer flex items-center gap-2 w-full justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass ${
                        errorHandler?.month && !periodMonth.month
                          ? "errorClass"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        className="text-white w-[50px] font-bold placeholder:font-normal bg-[#414142] "
                        id="nameId"
                        disabled
                        value={periodMonth.month ? `${periodMonth.month}M` : ""}
                        placeholder={strings.MONTH}
                      />
                      {openPeriod.month ? (
                        <Icon
                          icon="flowbite:caret-up-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      ) : (
                        <Icon
                          icon="flowbite:caret-down-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      )}
                      {openPeriod.month && (
                        <PeriodTime
                          period={true}
                          setperiodMonth={setperiodMonth}
                          periodMonth={periodMonth}
                          setErrorHandler={setErrorHandler}
                          errorHandler={errorHandler}
                          classValue={
                            openPeriod.month ? "dropdownClass" : "hideNotify"
                          }
                        />
                      )}
                    </div>
                    {errorHandler?.month && !periodMonth.month && (
                      <p className="text-base text-[#F87171] mb-0">
                        {errorHandler?.month}
                      </p>
                    )}
                  </div>
                  <div className="relative w-full ">
                    <div
                      onClick={() => {
                        setOpenPeriod({
                          month: false,
                          day: !openPeriod.day,
                        });

                        setOpenType(false);
                        setOpenAddPeriod({ month: false, day: false });
                        setTimeDropdown(false);
                      }}
                      className={`flex cursor-pointer items-center gap-2 w-full justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass ${
                        errorHandler?.day && !periodMonth.day
                          ? "errorClass"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        className="text-white font-bold placeholder:font-normal w-[50px] bg-[#414142]"
                        id="nameId"
                        disabled
                        value={periodMonth.day ? `${periodMonth.day}d` : ""}
                        placeholder={strings.DAYS}
                      />
                      {openPeriod.day ? (
                        <Icon
                          icon="flowbite:caret-up-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      ) : (
                        <Icon
                          icon="flowbite:caret-down-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      )}
                      {openPeriod.day && (
                        <PeriodTime
                          setperiodMonth={setperiodMonth}
                          periodMonth={periodMonth}
                          setErrorHandler={setErrorHandler}
                          errorHandler={errorHandler}
                          classValue={
                            openPeriod.day ? "dropdownClass" : "hideNotify"
                          }
                        />
                      )}
                    </div>
                    {errorHandler?.day && !periodMonth.day && (
                      <p className="text-base text-[#F87171] mb-0">
                        {errorHandler?.day}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-1.5 w-full">
                <p
                  className={`text-base font-normal ml-2 mb-0 ${
                    errorHandler?.AddMonth && !periodAddMonth.month
                      ? "text-[#F87171]"
                      : "text-[#ffffff]"
                  }`}
                >
                  {strings.ADD}
                </p>
                <div className="flex items-center gap-x-3 gap-y-3 max-lg:flex-wrap w-full px-2">
                  <div className="relative w-full ">
                    <div
                      onClick={() => {
                        setOpenAddPeriod({
                          month: !openAddPeriod.month,
                          day: false,
                        });
                        setOpenPeriod({
                          month: false,
                          day: false,
                        });

                        setOpenType(false);
                        setTimeDropdown(false);
                      }}
                      className={`flex cursor-pointer items-center gap-2 w-full justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass ${
                        errorHandler?.AddMonth && !periodAddMonth.month
                          ? "errorClass"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        className="text-white font-bold placeholder:font-normal w-[50px] bg-[#414142]"
                        id="nameId"
                        value={
                          periodAddMonth.month
                            ? `+${periodAddMonth.month}M`
                            : ""
                        }
                        disabled
                        placeholder={strings.MONTH}
                      />
                      {openAddPeriod.month ? (
                        <Icon
                          icon="flowbite:caret-up-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      ) : (
                        <Icon
                          icon="flowbite:caret-down-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      )}
                      {openAddPeriod.month && (
                        <AddOnPeriodTime
                          period={true}
                          periodAddMonth={periodAddMonth}
                          setperiodAddMonth={setperiodAddMonth}
                          setErrorHandler={setErrorHandler}
                          errorHandler={errorHandler}
                          classValue={
                            openAddPeriod.month ? "dropdownClass" : "hideNotify"
                          }
                        />
                      )}
                    </div>
                    {errorHandler?.AddMonth && !periodAddMonth.month && (
                      <p className="text-base text-[#F87171] mb-0">
                        {errorHandler?.AddMonth}
                      </p>
                    )}
                  </div>
                  <div className="relative w-full ">
                    <div
                      onClick={() => {
                        setOpenAddPeriod({
                          month: false,
                          day: !openAddPeriod.day,
                        });
                        setOpenPeriod({
                          month: false,
                          day: false,
                        });

                        setOpenType(false);
                        setTimeDropdown(false);
                      }}
                      className={`flex cursor-pointer items-center gap-2 w-full justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass ${
                        errorHandler?.Addday && !periodAddMonth.day
                          ? "errorClass"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        className="text-white font-bold placeholder:font-normal w-[50px] bg-[#414142]"
                        id="nameId"
                        value={
                          periodAddMonth.day ? `+${periodAddMonth.day}d` : ""
                        }
                        disabled
                        placeholder={strings.DAYS}
                      />
                      {openAddPeriod.day ? (
                        <Icon
                          icon="flowbite:caret-up-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      ) : (
                        <Icon
                          icon="flowbite:caret-down-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      )}
                      {openAddPeriod.day && (
                        <AddOnPeriodTime
                          periodAddMonth={periodAddMonth}
                          setperiodAddMonth={setperiodAddMonth}
                          setErrorHandler={setErrorHandler}
                          errorHandler={errorHandler}
                          classValue={
                            openAddPeriod.day ? "dropdownClass" : "hideNotify"
                          }
                        />
                      )}
                    </div>
                    {errorHandler?.Addday && !periodAddMonth.day && (
                      <p className="text-base text-[#F87171] mb-0">
                        {errorHandler?.Addday}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={`${isAlways ? "opacity-[0.3]" : ""} my-1.5`}>
              <div>
                <div className="flex items-center gap-x-3 gap-y-3 max-lg:flex-wrap w-1/2 max-lg:w-full">
                  <div className="w-full">
                    <p
                      className={`text-base ml-2 mb-0 ${
                        isAlways
                          ? "text-[#ffffff]"
                          : (errorHandler?.startDate ||
                              errorHandler?.endDate) &&
                            (!startDate || !endDate)
                          ? "text-[#F87171]"
                          : "text-[#ffffff]"
                      } font-normal`}
                    >
                      {strings.DATE}
                    </p>
                    <div className="relative w-full">
                      <div
                        onclick={handleStartDateChange}
                        className={`flex items-center justify-between bg-[#414142] w-full rounded-[10px] h-[45px] px-3 newDateInputClass max-lg:w-full ${
                          errorHandler?.startDate && !startDate
                            ? "errorClass"
                            : ""
                        }`}
                      >
                        <DatePicker
                          oneTap
                          caretAs={HiCalendar}
                          id="datepicker-1"
                          placeholder={strings.START_DATE_SMALL}
                          format="yyyy.MM.dd"
                          block
                          editable={false}
                          appearance="subtle"
                          className="custom-datepicker bg-transparent border-0 w-full px-0"
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          onNextMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onChangeCalendarDate={(e) => {
                            setCurrentMonth(e);
                          }}
                          onPrevMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onOpen={() => {
                            setIsCalenderOpen(true);
                            setCurrentMonth(
                              startDate ? new Date(startDate) : new Date()
                            );
                            setDatePickerCheck(1);
                          }}
                          onClose={() => {
                            setIsCalenderOpen(false);
                            setCurrentMonth(
                              startDate ? new Date(startDate) : new Date()
                            );
                            setDatePickerCheck("");
                          }}
                          disabledDate={(date) => {
                            const maxDate = new Date(
                              Date.now() - 24 * 60 * 60 * 1000
                            );
                            return date < maxDate;
                          }}
                          disabled={isAlways ? true : false}
                        />
                      </div>
                      {errorHandler?.startDate && !startDate && (
                        <p className="text-base text-[#F87171] mb-0">
                          {errorHandler?.startDate}
                        </p>
                      )}
                    </div>
                  </div>{" "}
                  <div className={`${"w-full lg:mt-6"}`}>
                    <div className="relative w-full">
                      <div
                        onclick={handleEndDateChange}
                        className={`flex items-center justify-between bg-[#414142] w-full rounded-[10px] h-[45px] px-3 newDateInputClass max-lg:w-full  ${
                          errorHandler?.endDate && !endDate ? "errorClass" : ""
                        }`}
                      >
                        <DatePicker
                          oneTap
                          caretAs={HiCalendar}
                          id="datepicker-2"
                          placeholder={strings.END_DATE}
                          format="yyyy.MM.dd"
                          block
                          editable={false}
                          appearance="subtle"
                          className="custom-datepicker bg-transparent border-0 w-full px-0"
                          value={endDate}
                          onChange={(date) => setEndDate(date)}
                          onChangeCalendarDate={(e) => {
                            setCurrentMonth(e);
                          }}
                          onNextMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onPrevMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onOpen={() => {
                            setIsCalenderOpen(true);
                            setCurrentMonth(
                              endDate ? new Date(endDate) : new Date()
                            );
                            setDatePickerCheck(2);
                          }}
                          onClose={() => {
                            setIsCalenderOpen(false);
                            setCurrentMonth(
                              endDate ? new Date(endDate) : new Date()
                            );
                            setDatePickerCheck("");
                          }}
                          disabledDate={(date) => {
                            const minDate = new Date(startDate);
                            const maxDate = new Date(
                              Date.now() - 24 * 60 * 60 * 1000
                            );
                            return date < minDate || date < maxDate;
                          }}
                          disabled={isAlways ? true : false}
                        />
                      </div>
                      {errorHandler?.endDate && !endDate && (
                        <p className="text-base text-[#F87171] mb-0">
                          {errorHandler?.endDate}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-1.5 flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap">
              <div className="w-full">
                <p
                  className={`text-base font-normal ml-2 mb-0 ${
                    errorHandler?.count && !sessionCount.count
                      ? "text-[#F87171]"
                      : "text-[#ffffff]"
                  }`}
                >
                  {strings.SESSION_COUNT}
                </p>
                <div
                  className={`flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass ${
                    errorHandler?.count && !sessionCount.count
                      ? "errorClass"
                      : ""
                  }`}
                >
                  <input
                    autoComplete="off"
                    type="number"
                    className="text-white font-bold placeholder:font-normal w-full bg-[#414142] numberField"
                    id="nameId"
                    value={sessionCount.count}
                    onChange={(e) => handleSessionCountChange(e, "count")}
                    // onChange={(e) => {
                    //   setSessionsCount({
                    //     count: e.target.value,
                    //     addOn: sessionCount.addOn,
                    //   });
                    // }}
                    placeholder={"Session"}
                  />
                </div>
                {errorHandler?.count && !sessionCount.count && (
                  <p className="text-base text-[#F87171] mb-0">
                    {errorHandler?.count}
                  </p>
                )}
              </div>
              <div className="w-full">
                <p
                  className={`text-base font-normal ml-2 mb-0 ${
                    errorHandler?.addOn && !sessionCount.addOn
                      ? "text-[#F87171]"
                      : "text-[#ffffff]"
                  }`}
                >
                  {strings.ADD}
                </p>
                <div
                  className={`flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass ${
                    errorHandler?.addOn && !sessionCount.addOn
                      ? "errorClass"
                      : ""
                  }  `}
                >
                  <input
                    autoComplete="off"
                    type="number"
                    value={sessionCount.addOn}
                    onChange={(e) => handleSessionCountChange(e, "addOn")}
                    // onChange={(e) => {
                    //   setSessionsCount({
                    //     count: sessionCount.count,
                    //     addOn: e.target.value,
                    //   });
                    // }}
                    className="text-white font-bold placeholder:font-normal w-full bg-[#414142] numberField"
                    id="nameId"
                    placeholder={"Session"}
                  />
                </div>
                {errorHandler?.addOn && !sessionCount.addOn && (
                  <p className="text-base text-[#F87171] mb-0">
                    {errorHandler?.addOn}
                  </p>
                )}
              </div>
              <div className="w-full">
                <p
                  className={`text-base font-normal ml-2 mb-0 ${
                    errorHandler?.time && !time
                      ? "text-[#F87171]"
                      : "text-[#ffffff]"
                  }`}
                >
                  {strings.TIME}
                </p>
                <div className="relative w-full">
                  <div
                    onClick={() => {
                      setTimeDropdown(!timeDropdown);
                      setOpenAddPeriod({
                        month: false,
                        day: false,
                      });
                      setOpenPeriod({
                        month: false,
                        day: false,
                      });

                      setOpenType(false);
                    }}
                    className={`flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass ${
                      errorHandler?.time && !time ? "errorClass" : ""
                    }`}
                  >
                    <input
                      type="text"
                      autoComplete="off"
                      className="text-white font-bold placeholder:font-normal  w-full bg-[#414142] cursor-pointer caret-transparent"
                      id="nameId"
                      // disabled
                      value={time ? `${time} Minutes` : ""}
                      placeholder={strings.TIME}
                    />
                    {timeDropdown ? (
                      <Icon
                        icon="flowbite:caret-up-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE", cursor: "pointer" }}
                      />
                    ) : (
                      <Icon
                        icon="flowbite:caret-down-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE", cursor: "pointer" }}
                      />
                    )}
                    {timeDropdown && (
                      <TimeList
                        setTime={setTime}
                        setErrorHandler={setErrorHandler}
                        errorHandler={errorHandler}
                        classValue={
                          timeDropdown ? "dropdownClass" : "hideNotify"
                        }
                      />
                    )}
                  </div>
                  {errorHandler?.time && !time && (
                    <p className="text-base text-[#F87171] mb-0">
                      {errorHandler?.time}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="my-1.5 flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap">
              <div className="w-full">
                <p
                  className={`${
                    errorHandler?.regularPrice && !regularPrice
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"mb-0 ml-2 text-base font-normal "}`}
                >
                  {strings.PRICE}
                </p>
                <div
                  className={`${
                    errorHandler?.regularPrice && !regularPrice
                      ? "errorClass"
                      : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass"}`}
                >
                  <input
                    type="text"
                    autoComplete="off"
                    className="text-white font-bold placeholder:font-normal w-full bg-[#414142] numberField"
                    id="nameId"
                    placeholder={"MSRP"}
                    value={formatPrice(regularPrice)}
                    onChange={(e) => {
                      setRegularPrice(parseFormattedPrice(e.target.value));
                    }}
                  />
                  <img src={won} className="w-[25px] h-[25px]" />
                </div>
                {/* {errorHandler?.regularPrice && !regularPrice && (
                  <p className="text-base text-[#F87171] mb-0">
                    {errorHandler?.regularPrice}
                  </p>
                )} */}
              </div>
              <div className="w-full">
                <p className="mb-0 ml-2 text-base font-normal text-[#ffffff] ">
                  {strings.DISCOUNT}
                </p>
                <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass">
                  <input
                    autoComplete="off"
                    type="text"
                    className="text-white font-bold placeholder:font-normal w-full bg-[#414142] numberField"
                    id="nameId"
                    placeholder={strings.DISCOUNT}
                    value={formatPrice(discountPrice)}
                    onChange={(e) => {
                      const price =
                        +e.target.value > +regularPrice
                          ? regularPrice
                          : e.target.value;
                      setDiscountPrice(parseFormattedPrice(price));
                    }}
                  />
                  <img src={won} className="w-[25px] h-[25px]" />
                </div>
              </div>
              <div className="w-full">
                <p className="mb-0 ml-2 text-base font-normal text-[#ffffff] ">
                  {strings.FINAL_PRICE}
                </p>
                <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3">
                  <input
                    autoComplete="off"
                    type="text"
                    className="text-white font-bold placeholder:font-normal w-full bg-[#414142]"
                    id="nameId"
                    disabled
                    value={formatPrice(
                      finalPrice
                        ? finalPrice
                        : regularPrice && regularPrice - discountPrice > 0
                        ? regularPrice - discountPrice
                        : 0
                    )}
                    placeholder={strings.FINAL_PRICE}
                  />
                  <img src={won} className="w-[25px] h-[25px]" />
                </div>
              </div>
            </div>
            <div>
              <textarea
                placeholder={placeholder.WRITE_CONTENT}
                value={discription}
                onChange={handleDescriptionChange}
                // onChange={(e) => {
                //   setDiscription(e.target.value);
                // }}
                className={`bg-[#414142] w-full h-[150px] my-3 px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal placeholder:text-[#D1D1D1] ${
                  errorHandler?.discription && !discription
                    ? "errorClass placeholder:text-[#F87171]"
                    : ""
                }`}
              />
              {errorHandler?.discription && !discription && (
                <p className="text-sm -mt-5 text-[#F87171]">
                  {errorHandler?.discription}
                </p>
              )}
            </div>

            <div
              className={`${
                imagePreview &&
                imagePreview.length > 0 &&
                imagePreview.length < 4
                  ? "justify-start"
                  : innerLoader
                  ? "justify-start"
                  : "justify-center"
              } flex items-center`}
            >
              <div
                className={`${
                  !imagePreview || imagePreview.length == 0
                    ? innerLoader
                      ? "justify-start"
                      : "justify-center"
                    : "justify-start"
                } ${"flex items-center my-6 gap-2.5 flex-wrap"}`}
              >
                <input
                  type="file"
                  accept="image/*, video/*"
                  className="hidden"
                  id="uploadMedia"
                  onChange={(e) => getVideoThumbnail(e)}
                />
                {(!imagePreview || imagePreview.length == 0) && (
                  <label
                    htmlFor="uploadMedia"
                    style={{ border: "2px solid #4B4B4B" }}
                    className={`${
                      innerLoader && "hidden"
                    } ${"flex items-center gap-3 rounded-[10px] py-2.5 px-6 cursor-pointer"}`}
                  >
                    <Icon
                      icon="humbleicons:upload"
                      width="48"
                      height="48"
                      style={{ color: "white" }}
                    />
                    <p className="mb-0 text-[24px] font-bold">
                      {strings.UPLOAD_MEDIA_FILES}
                    </p>
                  </label>
                )}
                {imagePreview &&
                  imagePreview?.map((item, index) => (
                    <div
                      className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] relative"
                      key={index}
                    >
                      <img
                        src={item.url || item.view}
                        className="w-full h-full object-cover rounded-[10px]"
                      />
                      <span
                        onClick={() => {
                          removeProduct(item.url, index);
                        }}
                        className="absolute top-2 w-[30px] h-[30px] right-2 flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                      >
                        <Icon
                          icon="iconamoon:close-bold"
                          width="25"
                          height="25"
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      </span>

                      {item?.type == "video" && (
                        <span
                          onClick={() => {
                            setVideoPreview(true);
                            setuploadedMedia(item);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                {((imagePreview?.length > 0 && imagePreview?.length !== 5) ||
                  innerLoader) && (
                  <label
                    htmlFor="uploadMedia"
                    className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] flex items-center justify-center relative"
                  >
                    {!innerLoader ? (
                      <Icon
                        icon="bitcoin-icons:plus-filled"
                        width="100"
                        height="100"
                        style={{ color: "white" }}
                      />
                    ) : (
                      <span className="absolute w-full h-full flex items-center justify-center">
                        <Icon
                          icon="eos-icons:bubble-loading"
                          width="60"
                          height="60"
                          style={{ color: "#CCFF00" }}
                          className="loader-icon "
                        />
                      </span>
                    )}
                  </label>
                )}
              </div>
            </div>
            {/* {error && (
              <p className="mt-12 mb-4 text-[20px] font-extrabold text-[#FFB0B0] text-center">
                {error}
              </p>
            )} */}
            <div
              className={` ${"flex-wrap flex items-center justify-center gap-3 mt-6"}`}
            >
              <button
                onClick={() => {
                  setOpenRegister(false);
                }}
                className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
              >
                {strings.CANCEL}
              </button>
              {defaultValue ? (
                <button
                  disable={!innerLoader ? false : true}
                  onClick={() => {
                    editHandle();
                  }}
                  className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                >
                  {strings.UPDATE}
                </button>
              ) : (
                <button
                  disable={!innerLoader ? false : true}
                  onClick={() => {
                    registerHandle();
                  }}
                  className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                >
                  {strings.REGISTER}
                </button>
              )}
            </div>
          </div>
          <ErrorModal
            openModal={openModal}
            closeModal={setOpenModal}
            errorMessage={errorMessage}
            classNameValue={classNameValue}
          />
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default EventRegister;

// ===============PRODUCT TYPE==============
function ProductFilter({ setEventType, classValue }) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[250px] right-0 top-18 max-h-[280px] overflow-auto z-10 px-4"}`}
    >
      <div
        onClick={() => {
          setEventType({ text: strings.CONTINUOUS, type: 0 });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.CONTINUOUS}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.LIMITED_TIME, type: 1 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.LIMITED_TIME}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.FIRST_COME_FIRST_SERVED, type: 2 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">
          {strings.FIRST_COME_FIRST_SERVED}
        </span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.RE_PURCHASE, type: 3 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.RE_PURCHASE}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.NEW, type: 4 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer"
      >
        <span className="mb-0 text-lg font-bold">{strings.NEW}</span>
      </div>
    </div>
  );
}

const periodMonthArry = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "23",
  "24",
];
const daysArray = [...Array(31).keys()].map((day) => day + 1);
const timeMint = [20, 30, 40, 50, 60];

// ==============PERIOD TIME AND ADD ON TIME ===============
function PeriodTime({
  period,
  setperiodMonth,
  periodMonth,
  setErrorHandler,
  errorHandle,
  classValue,
}) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[150px] right-0 top-[50px] max-h-[200px] overflow-auto z-10 px-3"}`}
    >
      {period ? (
        <div>
          <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodMonth({ month: "", day: periodMonth.day });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p>
          {periodMonthArry.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setperiodMonth({ month: item, day: periodMonth.day });
              }}
              className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
            >
              {/* <Icon icon="bi:dot" width="25" height="25" /> */}
              <span className="mb-0 text-lg font-bold">{item}M</span>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodMonth({ month: periodMonth.month, day: "" });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p>
          {daysArray.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setperiodMonth({ month: periodMonth.month, day: item });
              }}
              className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
            >
              {/* <Icon icon="bi:dot" width="25" height="25" /> */}
              <span className="mb-0 text-lg font-bold">{item}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

// ==============PERIOD TIME AND ADD ON TIME ===============
function AddOnPeriodTime({
  period,
  periodAddMonth,
  setperiodAddMonth,
  errorHandle,
  setErrorHandler,
  classValue,
}) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[150px] right-0 top-[50px] max-h-[200px] overflow-auto z-10 px-3"}`}
    >
      {period ? (
        <div>
          <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodAddMonth({ month: "", day: periodAddMonth.day });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p>
          {periodMonthArry.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setperiodAddMonth({ month: item, day: periodAddMonth.day });
              }}
              className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
            >
              {/* <Icon icon="bi:dot" width="25" height="25" /> */}
              <span className="mb-0 text-lg font-bold">+{item}M</span>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodAddMonth({
                month: periodAddMonth.month,
                day: "",
              });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p>
          {daysArray.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setperiodAddMonth({ month: periodAddMonth.month, day: item });
              }}
              className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
            >
              {/* <Icon icon="bi:dot" width="25" height="25" /> */}
              <span className="mb-0 text-lg font-bold">+{item}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

// ==============TIME LIST DROPDOWN===============
function TimeList({ setTime, errorHandle, setErrorHandler, classValue }) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-0 top-[50px] max-h-[200px] overflow-auto z-10 px-3"}`}
    >
      <p
        className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
        onClick={() => {
          setTime("");
        }}
      >
        --{strings.NOT_SELECTED}--
      </p>
      {timeMint.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setTime(item);
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
        >
          {/* <Icon icon="bi:dot" width="25" height="25" /> */}
          <span className="mb-0 text-lg font-bold">{item} Minutes</span>
        </div>
      ))}
    </div>
  );
}
