import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import { InputGroup, Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import MembersSublyApi from "../../../helper/MemberApi";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { formatPrice } from "../../../utils/commonUtils";
import moment from "moment";

function ListingModal({
  classNameValue,
  listType,
  setListType,
  isList,
  id,
  setIsList,
  setIsLoader,
  holding,
  setMemberProductId,
  memberDetail,
  memberProductId,
  setHoldingList,
}) {
  const { token } = useSelector((state) => state.user);
  const [listValue, setListValue] = useState("");
  const [trainerDetails, setTrainerDetails] = useState(null);
  const [currentTrainer, setCurrentTrainer] = useState(null);

  // ==========API for fetching product LIST=============
  useEffect(() => {
    async function fetchProductList() {
      setIsLoader(true);
      await MembersSublyApi.productList(id, isList.type, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            if (holding) {
              let list = response.data.rows?.filter(
                (elements) => !elements?.isExpired
              );
              setListValue(list);
            } else {
              setListValue(response.data.rows);
            }
            setTrainerDetails(response.data.trainerDetails);
            setCurrentTrainer(response.data.currentTrainer);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (isList.status) {
      fetchProductList();
    }
  }, [isList]);

  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass classForMargin"}`}
        show={isList.status}
        onHide={() => {
          setIsList({ status: false, typeof: 0 });
          setMemberProductId({
            memberProductId: "",
            memberId: "",
          });
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8 overflow-auto max-h-[calc(100vh_-_150px)]">
          <p className="border-b-2 border-[#444444] pb-3 mb-0 text-[40px] max-lg:text-[32px] font-extrabold">
            {listType.name} {holding && strings.HOLDING}
          </p>
          <div>
            {" "}
            {listValue && listValue.length > 0
              ? listValue.map((item, index) => {
                  return (
                    <div className="w-[90%] m-auto">
                      <div className="flex items-start gap-8 border-b-2 border-[#444444] py-4 justify-center">
                        <div>
                          <p className="rounded-full bg-[#444444] w-[40px] h-[40px] max-lg:w-[32px] max-lg:h-[32px] max-lg:text-sm flex items-center justify-center text-lg font-extrabold mb-0">
                            {index + 1}
                          </p>
                        </div>
                        {holding && (
                          <InputGroup className="checkboxClass holdingClass">
                            <InputGroup.Checkbox
                              className="w-[31px] h-[31px] bg-[#CECECE80]"
                              checked={memberProductId.productId === item.id}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setMemberProductId({
                                    productId: item.id,
                                    memberId: memberDetail?.memberId,
                                  });
                                } else {
                                  setMemberProductId({
                                    memberProductId: "",
                                    memberId: "",
                                  });
                                }
                              }}
                            />
                          </InputGroup>
                        )}
                        <div className="flex items-start gap-3 w-full">
                          <Icon
                            icon="lets-icons:date-today"
                            width="32"
                            height="32"
                            style={{ color: "#CCFF00" }}
                          />
                          <div className="text-start w-[90%]">
                            <p className="mb-1 text-[22px] max-lg:text-base font-bold">
                              -{" "}
                              <Moment format="YY.MM.DD">
                                {item.membershipStartDate}
                              </Moment>{" "}
                              {"~"}{" "}
                              <Moment format="YY.MM.DD">
                                {item.membershipEndDate}
                              </Moment>{" "}
                              {!item.isTaken &&
                                `(${item.holdingCount} holding, ${item.holdingDays} days)`}{" "}
                              {/* {strings.START_DATE}{" "}
                            <Moment format="YY.MM.DD">
                              {item.membershipStartDate}
                            </Moment> */}
                            </p>
                            {/* <div className="flex"> */}
                            <p className="mb-2 font-bold max-lg:text-lg text-[24px]">
                              {item.productName}{" "}
                              {item.isTaken &&
                                `(${item.holdingCount} holding, ${item.holdingDays} days)`}
                            </p>
                            {/* <p className="font-bold max-lg:text-lg text-[24px]">
                            {item.isTaken &&
                              `(${item.holdingCount} holding, ${item.holdingDays} days)`}
                          </p>
                          </div> */}
                            {/* <p className="mb-1 font-bold max-lg:text-lg text-[24px]">
                            - MSRP: ₩{formatPrice(item.price)} ,{" "}
                            {strings.DISCOUNT}: ₩ {formatPrice(item.discount1)}{" "}
                            , {strings.DISCOUNT} 2 : ₩
                            {formatPrice(item.discount2)}
                          </p> */}
                            {/* {item?.isTaken && item?.isGiven && ( */}
                            {/* <div className="flex items-center gap-2 bg-[#414142] rounded-[10px] px-3 py-2"> */}
                            {item?.isTaken && !item?.isGiven && (
                              <p className="mb-1 font-bold max-lg:text-lg text-[24px]">
                                Received from {item?.takenFrom} (
                                {moment(item?.membershipStartDate).format(
                                  "YY.MM.DD"
                                )}
                                )
                              </p>
                            )}
                            {item?.isGiven && (
                              <div className="flex items-center gap-2 mb-1 ">
                                <p className="mb-1 font-bold max-lg:text-lg text-[24px]">
                                  Transferred to {item?.givenTo} (
                                  {moment(
                                    item?.takeOverFromDate ||
                                      item?.takeOverToDate
                                  ).format("YY.MM.DD")}
                                  )
                                </p>
                              </div>
                            )}

                            {/* <p className="mb-1 text-[22px] max-lg:text-base ">
                            {item.isTaken &&
                              `(${item.holdingCount} holding, ${item.holdingDays} days)`}
                          </p> */}

                            {item?.holdingRequests && (
                              <p className="mb-1 font-bold max-lg:text-lg text-[24px]">
                                Holding :{" "}
                                {item?.holdingRequests?.requestPauseDays} days (
                                {moment(
                                  item?.holdingRequests?.startDate
                                ).format("YY.MM.DD")}
                                )
                              </p>
                            )}
                            {trainerDetails && trainerDetails.length > 0 && (
                              <div className="flex flex-col gap-2 mb-2 max-h-[120px] overflow-y-auto">
                                {trainerDetails.map((trainer, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center gap-2"
                                  >
                                    {trainer.previousTrainer && (
                                      <p className="font-bold max-lg:text-lg text-[24px] mb-0">
                                        {trainer.previousTrainer}
                                      </p>
                                    )}
                                    {trainer.previousTrainer && (
                                      <span className="font-bold">{"-->"}</span>
                                    )}
                                    {trainer.current && (
                                      <p className="font-bold max-lg:text-lg mt-0 text-[24px] mb-0">
                                        {trainer.current}
                                      </p>
                                    )}
                                    {trainer.resFeedbackDate && (
                                      <p className="font-bold max-lg:text-lg mt-0 text-[24px] mb-0">
                                        |{" "}
                                        {moment(trainer.resFeedbackDate).format(
                                          "YYYY.MM.DD"
                                        )}
                                      </p>
                                    )}
                                    {/* Conditionally add comma after date if not the last object */}
                                    {/* {trainer.resFeedbackDate &&
                                      index < trainerDetails.length - 1 && (
                                        <span className="font-bold">, </span>
                                      )} */}
                                  </div>
                                ))}
                              </div>
                            )}

                            {currentTrainer && trainerDetails.length == 0 && (
                              <p className="font-bold max-lg:text-lg mt-0 text-[24px] mb-0">
                                {currentTrainer}
                              </p>
                            )}
                            {item?.refundDate && (
                              <p className="mb-1 font-bold max-lg:text-lg text-[24px]">
                                Refund Amount : ₩
                                {parseInt(item?.refundAmount, 10)} (
                                {moment(item?.refundDate).format("YY.MM.DD")})
                              </p>
                            )}
                            <p className="mb-1 font-bold max-lg:text-lg text-[24px]">
                              MSRP: ₩{formatPrice(item.price)} ,{" "}
                              {strings.DISCOUNT}: ₩{formatPrice(item.discount1)}{" "}
                              , {strings.DISCOUNT} 2 : ₩
                              {formatPrice(item.discount2)}
                            </p>
                            {/* </div> */}
                            {/* )} */}
                            {/* <p className="mb-0 text-[22px] max-lg:text-base font-bold">{`(hold) 2023.07.30 - 2023.08.31(62 days)`}</p> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  );
                })
              : ""}
          </div>
          <div
            className={`${`flex mt-6`} ${
              holding ? "justify-around" : "justify-center"
            }`}
          >
            <button
              onClick={() => {
                setIsList(false);
                setMemberProductId({
                  memberProductId: "",
                  memberId: "",
                });
              }}
              className="text-lg font-extrabold hover:text-black hover:bg-common_color text-whiite kakaoClass h-[60px] max-lg:h-[45px] w-[160px] rounded-[28px]"
            >
              {strings.CLOSE}
            </button>
            {holding && (
              <button
                disabled={memberProductId.productId == undefined ? true : false}
                onClick={() => {
                  setIsList(false);
                  setHoldingList(true);
                }}
                className={`${
                  memberProductId.productId !== undefined
                    ? "text-black bg-common_color"
                    : "text-whiite kakaoClass"
                } ${"text-lg font-extrabold h-[60px] max-lg:h-[45px] w-[160px] rounded-[28px]"}`}
              >
                {strings.CONFIRM}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ListingModal;
