import React from "react";
import { strings } from "../../utils/String";
import { Icon } from "@iconify-icon/react";

function AuthButton({ btnStyle, icon, clickFun }) {
  return (
    <>
      <button
        onClick={() => {
          clickFun && clickFun();
        }}
        className={`${btnStyle} flex justify-center gap-3 items-center w-full h-[55px] text-2xl font-bold text-white rounded-[30px] px-2 max-lg:text-lg max-lg:!p-1.5`}
      >
        {strings.LOGIN_WITH}
        <img src={icon} alt="icon" className="w-7 h-7" />
      </button>
    </>
  );
}

export default AuthButton;

export function CloseButton({ clickFun }) {
  return (
    <>
      <button
        onClick={() => {
          clickFun();
        }}
        className={`flex justify-center gap-2 items-center w-[170px] text-2xl font-extrabold text-black rounded-[30px] p-3 bg-common_color max-lg:text-lg max-lg:!p-1.5`}
      >
        {strings.CLOSE}
        <Icon
          icon="gg:timer"
          width="25"
          height="25"
          style={{ color: "black" }}
        />
      </button>
    </>
  );
}
