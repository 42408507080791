import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { InputGroup, Table } from "react-bootstrap";
import demoImg from "../../../../assests/images/demoProfile.svg";
import GymSettingSublyApi from "../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import moment from "moment";
import info from "../../../../assests/icons/message-setting-icons/infoAlertIcon.svg";

function SearchMemberModal({
  classNameValue,
  setIsLoader,
  setOpenModal,
  setCheckedMembers,
  setOpenRegister,
  checkedMembers,
}) {
  const { token } = useSelector((state) => state.user);
  const [searchName, setSearchName] = useState("");
  const [memberList, setMemberList] = useState("");
  const [checked, setChecked] = useState(checkedMembers);

  //   =============Calling API for fetching member list API================
  useEffect(() => {
    getMemberList("");
  }, []);
  async function getMemberList() {
    setIsLoader(true);
    await GymSettingSublyApi.fetchMemberList(token, searchName)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode === 200) {
          setMemberList(response.data);
        }
      })
      .catch((err) => console.log(err));
  }

  console.log(checked);

  return (
    <section
      className={`${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-h-[95%] max-lg:p-4 max-lg:py-8  p-10 pb-6 mt-6 z-20 overflow-auto"}`}
      >
        <div className="w-[90%] max-2xl:w-[100%] m-auto">
          <h2 className="text-white text-[40px] max-lg:text-[32px] font-extrabold text-center border-b border-[#686868] pb-4">
            {strings.MEMBER_SEARCH}
          </h2>
          <div className="flex items-center gap-3 my-4 justify-center w-3/5 m-auto">
            <label className="rounded-[30px] w-full bg-[#1F2022] flex items-center h-[50px] px-4 gap-2">
              <Icon
                icon="uil:user"
                width="24"
                height="24"
                style={{ color: "white" }}
              />
              <input
                type="text"
                value={searchName}
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
                className="w-full h-[45px] bg-transparent text-white font-bold placeholder:text-[#A1A1AA] placeholder:font-normal placeholder:text-lg"
                placeholder={`${placeholder.NAME}:`}
              />
            </label>
            <button
              onClick={() => {
                getMemberList();
              }}
              className="w-[130px] h-[48px] kakaoClass text-white text-lg font-extrabold rounded-[28px] hover:bg-common_color hover:text-black"
            >
              {strings.SEARCH}
            </button>
          </div>
          <div className="overflow-auto">
            <div className="max-2xl:w-[1300px] suggestiontableClass overflow-auto max-h-[calc(100vh_-_400px)] relative">
              <Table className="w-full !bg-transparent">
                <thead className="bg-[#414142] sticky top-[-2px] z-[1000]">
                  <th className="!text-center text-[20px] font-semibold text-white w-[35%]  ">
                    {strings.MEMBER_NAMES}
                  </th>
                  <th className="!text-center text-[20px] px-4 font-semibold text-white w-[25%] ">
                    {strings.ASSIGNED_TRAINER}
                  </th>
                  <th className="!text-center text-[20px] font-semibold text-white w-[20%] ">
                    {strings.BIRTHDAY}
                  </th>
                  <th className="!text-center text-[20px] font-semibold text-white w-[20%] ">
                    {strings.USER_CODE}
                  </th>
                </thead>
                <tbody className="relative z-[10]">
                  {memberList?.membersList &&
                    memberList?.membersList?.map((items, index) => (
                      <tr
                        //   onClick={() => {
                        //     setBoardId({ id: items.id });
                        //     setOpenDetail(true);
                        //   }}
                        key={index}
                        className={`${"cursor-pointer"}`}
                      >
                        <td
                          align="center"
                          className={` 
                          ${"text-white font-bold text-lg py-0"}`}
                        >
                          <div className="flex items-center gap-0.5 px-2 ">
                            <InputGroup className="checkboxClass paddingManage !p-0">
                              <InputGroup.Checkbox
                                className="w-[28px] h-[28px] !bg-[#353638] !p-0 mb-1"
                                checked={
                                  !!checked.find(
                                    (member) =>
                                      member.memberId == items.memberId
                                  )
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    const member = [...checked];
                                    member.push(items);
                                    setChecked(member);
                                  } else {
                                    const member = checked?.filter(
                                      (e) => e.memberId !== items.memberId
                                    );
                                    setChecked(member);
                                  }
                                }}
                              />
                            </InputGroup>
                            <div className="flex items-center gap-2">
                              <img
                                src={items?.profile?.prof_img1_min || demoImg}
                                alt="profile"
                                className="w-[35px] h-[35px] rounded-full"
                              />
                              <p className="mb-0 text-lg font-bold">
                                {items?.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="!text-center text-[18px] px-4 font-bold text-white py-0">
                          <p className="mb-0 py-2.5">
                            {items?.trainerName || "--"}
                          </p>
                        </td>
                        <td className="!text-center text-[18px] font-bold text-white py-0">
                          <p className="mb-0 py-2.5">
                            {" "}
                            {items?.birth
                              ? moment(items?.birth, "DD-MM-YY").format("MM.DD")
                              : "--"}
                          </p>
                        </td>
                        <td className="!text-center text-[18px] font-bold text-white py-0">
                          <p className="mb-0 py-2.5">{items?.userCode}</p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
          {memberList?.searched && (
            <p className="text-[#FF9191] text-[40px] font-extrabold flex items-center justify-center gap-4 mb-0 my-4">
              <img src={info} alt="info-icon" />
              {strings.NO_SEARCH_RESULTS_FOUND}
            </p>
          )}
          <div className="flex items-center justify-center mb-2 pt-10 gap-3">
            <button
              onClick={() => {
                setOpenModal(false);
                setChecked([]);
              }}
              className={`${"text-white kakaoClass hover:bg-[#CCFF00] hover:text-black"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px]"}`}
            >
              {strings.CLOSE}
            </button>
            <button
              disabled={checked.length > 0 ? false : true}
              onClick={() => {
                setCheckedMembers(checked);
                setOpenRegister(true);
                setOpenModal(false);
              }}
              className={`${"text-black bg-common_color"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px] disabled:bg-[#ccff0094]"}`}
            >
              {strings.CONFIRM}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SearchMemberModal;
