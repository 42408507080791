import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import moment from "moment";
import medal from "../../../../assests/icons/medal.svg";
import $ from "jquery";
import { useSelector } from "react-redux";
import BoardSublyApi from "../../../../helper/BoardApi";
import demoImg from "../../../../assests/images/demoProfile.svg";
import close from "../../../../assests/icons/message-setting-icons/closeIcon.svg";
import GymSettingSublyApi from "../../../../helper/GymSettingApi";
import ErrorModal from "../../../commonComponents/modalScreens/ErrorModal";
import { FormMedia } from "../../boardScreens/RegistrationForm";
import { sendingTarget, writingTypes } from "./MessageSetting";
import FundsModal from "./FundsModal";
import MessageConfirmModal from "./MessageConfirm";

function MessageRegister({
  classNameValue,
  setOpenModal,
  setOpenRegister,
  checkedMembers,
  setCheckedMembers,
  setIsLoader,
  defaultValue,
  id,
  setMessageId,
}) {
  const { token } = useSelector((state) => state.user);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [requestedData, setRequestedData] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [innerLoader, setInnerLoader] = useState(false);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [content, setContent] = useState("");
  const [dropDownState, setDropDownState] = useState(false);
  const [targetType, setTargetType] = useState(false);
  const [targetTypeValue, setTargetTypeValue] = useState({
    value: "",
    key: "",
  });
  const [writingTypeValue, setWritingTypeValue] = useState({
    value: "",
    key: "",
  });
  const [title, setTitle] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [fundsError, setFundsError] = useState(false);
  const [amount, setAmount] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);

  // ======Set default value while editing=========
  useEffect(() => {
    if (defaultValue) {
      setContent(defaultValue?.content);
      setTitle(defaultValue?.title);
      setRequestedData(defaultValue?.files || []);
      setImagePreview(defaultValue?.files || []);
      setTargetTypeValue({
        value: sendingTarget[defaultValue?.targetType],
        key: defaultValue?.targetType,
      });
      setWritingTypeValue({
        value: writingTypes[defaultValue?.writingType],
        key: defaultValue?.writingType,
      });
      setCheckedMembers(defaultValue?.targetMembers);
    }
  }, []);

  function getVideoThumbnail(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    setError("");
    if (file?.type.match("video")) {
      if (file?.size / 1000000 <= 200) {
        var image = "";
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = async function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              setInnerLoader(true);
              const requestData = new FormData();
              requestData.append("file", file);
              await BoardSublyApi.assestUpload(requestData, token)
                .then((response) => {
                  setInnerLoader(false);
                  if (response.statusCode == 200) {
                    let media = [...imagePreview];
                    const toDeploy = [...requestedData];
                    media.push({
                      url: response.data.compressed,
                      type: "video",
                      view: image,
                      original: response.data.original,
                    });
                    toDeploy.push({
                      url: response.data.compressed,
                      type: "video",
                      original: response.data.original,
                    });
                    setRequestedData(toDeploy);
                    setImagePreview(media);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              const base64String = image; // Base64 string
              const binaryString = atob(base64String.split(",")[1]); // Binary data string
              const blob = new Blob([binaryString], { type: "image/png" });
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setError("Video size should be less than 200 MB");
      }
    } else {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result) {
            setInnerLoader(true);
            const requestData = new FormData();
            requestData.append("file", file);
            await BoardSublyApi.assestUpload(requestData, token)
              .then((response) => {
                setInnerLoader(false);
                if (response.statusCode == 200) {
                  let media = [...imagePreview];
                  let toDeploy = [...requestedData];
                  media.push({
                    url: response.data.original,
                    type: "image",
                    view: result,
                  });
                  toDeploy.push({
                    url: response.data.compressed,
                    type: "image",
                    original: response.data.original,
                  });
                  setRequestedData(toDeploy);
                  setImagePreview(media);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setDropDownState(false);
    }
  });

  // ===============function for handling remove selected media=============
  async function removeProduct(url, index) {
    const images = [...imagePreview];
    let toDeploy = [...requestedData];
    images.splice(index, 1);
    setImagePreview(images);
    toDeploy.splice(index, 1);
    setRequestedData(toDeploy);
  }

  // ===========Register message API handler=============
  const handleRegisterMessage = async () => {
    setIsLoader(true);
    const requestData = {
      title: title,
      content: content,
      writingType: writingTypeValue.key,
      targetType: targetTypeValue.key,
      targetMembers: checkedMembers.map((member) => member.memberId),
      files: requestedData || [],
    };
    await GymSettingSublyApi.messageRegister(token, requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          if (response.data.status) {
            setAmount(response?.data?.amount);
            setOpenRegister(false);
          } else {
            setFundsError(true);
          }
        } else {
          setOpenErrorModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  // ===========edit message API handler=============
  const editMessageHandler = async () => {
    setIsLoader(true);
    const requestData = {
      title: title,
      content: content,
      writingType: writingTypeValue.key,
      targetType: targetTypeValue.key,
      targetMembers: checkedMembers.map((member) => member.memberId),
      files: requestedData || [],
    };
    await GymSettingSublyApi.editMessageDetail(token, id, requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setOpenRegister(false);
          setMessageId("");
        } else {
          setOpenErrorModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setDropDownState(false);
      setTargetType(false);
    }
  });

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center items-center bg-[#000000c2] z-10"}`}
    >
      <ErrorModal
        openModal={openErrorModal}
        closeModal={setOpenErrorModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-lg:w-[96%] max-h-[95%] p-6 max-[500px]:p-3 pb-14 overflow-auto z-10"}`}
      >
        <div className="w-[85%] max-lg:w-[95%] m-auto">
          <div className="border-b border-[#595959] pb-6 pt-2 m-auto">
            <h3 className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold text-white font-[archivo]">
              {defaultValue ? strings.EDIT_MESSAGE : strings.WRITE_MESSAGE}
            </h3>
          </div>
          <div className="flex items-center justify-center gap-2 mt-8 mb-6">
            <div
              onClick={() => {
                setDropDownState(!dropDownState);
                setTargetType(false);
              }}
              className="bg-[#414142] cursor-pointer w-full px-3 py-[20px] rounded-[10px] flex items-center relative dropdownClass"
            >
              <input
                type="text"
                value={writingTypeValue.value}
                placeholder={"Select writing type"}
                className="bg-[#414142] cursor-pointer w-full h-full text-white text-lg font-bold placeholder:font-normal caret-transparent"
              />
              {dropDownState ? (
                <Icon
                  icon="flowbite:caret-up-solid"
                  width="20"
                  height="20"
                  style={{ color: "#BEBEBE" }}
                />
              ) : (
                <Icon
                  icon="flowbite:caret-down-solid"
                  width="20"
                  height="20"
                  style={{ color: "#BEBEBE" }}
                />
              )}
              {dropDownState && (
                <WritingTypeModal
                  setWritingTypeValue={setWritingTypeValue}
                  setCheckedMembers={setCheckedMembers}
                  setTargetTypeValue={setTargetTypeValue}
                />
              )}
            </div>
            <div
              onClick={() => {
                setTargetType(!targetType);
                setDropDownState(false);
              }}
              className="bg-[#414142] cursor-pointer w-full px-3 py-[20px] rounded-[10px] flex items-center relative dropdownClass"
            >
              <input
                type="text"
                value={targetTypeValue.value}
                placeholder={"Sending Target"}
                className="bg-[#414142] cursor-pointer w-full h-full text-white text-lg font-bold placeholder:font-normal caret-transparent"
              />
              {targetType ? (
                <Icon
                  icon="flowbite:caret-up-solid"
                  width="20"
                  height="20"
                  style={{ color: "#BEBEBE" }}
                />
              ) : (
                <Icon
                  icon="flowbite:caret-down-solid"
                  width="20"
                  height="20"
                  style={{ color: "#BEBEBE" }}
                />
              )}
              {targetType && (
                <SendingTargetModal
                  setTargetTypeValue={setTargetTypeValue}
                  setOpenModal={setOpenModal}
                  writingTypeValue={writingTypeValue}
                  setCheckedMembers={setCheckedMembers}
                />
              )}
            </div>
          </div>
          <div className="border-b border-[#595959] pb-6">
            {writingTypeValue.value == "Center notification" ? (
              <p className=" mb-0 text-center text-[#FF9191] font-bold text-[32px] w-[90%] m-auto">
                {strings?.ALERT_MESSAGE_FOR_ALL_MEMBER_SENT}
              </p>
            ) : writingTypeValue.value == "Event notice" ? (
              <p className=" mb-0 text-center text-[#FF9191] font-bold text-[32px] w-[80%] m-auto">
                {strings?.ALERT_MESSAGE_FOR_MEMBER_SENT_MESSAGE}
              </p>
            ) : (
              ""
            )}

            {checkedMembers?.length > 0 && (
              <div className="flex items-center gap-2 mt-3 flex-wrap">
                {checkedMembers?.map((val, index) => (
                  <div
                    key={index}
                    style={{ border: "1px solid #A1A1AA4F" }}
                    className="relative flex items-center gap-2 bg-[#414142] px-3 py-2 rounded-[6px] min-w-[180px]"
                  >
                    <img
                      src={val?.profile?.prof_img1_min || demoImg}
                      alt="profile"
                      className="w-[35px] h-[35px] rounded-full"
                    />
                    <p className="mb-0 text-lg font-bold">{val?.name}</p>
                    <img
                      src={close}
                      alt="close-icon"
                      className="absolute right-1.5 top-1 w-5 cursor-pointer"
                      onClick={() => {
                        const member = checkedMembers?.filter(
                          (e) => e.memberId !== val.memberId
                        );
                        setCheckedMembers(member);
                      }}
                    />
                  </div>
                ))}
                <div
                  style={{ border: "1px solid #A1A1AA4F" }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  className="flex items-center justify-center gap-2 bg-[#414142] px-3 py-[11.5px] rounded-[6px] min-w-[180px] cursor-pointer"
                >
                  <p className="mb-0 text-lg font-bold text-[#ccff00]">
                    {strings.ADD_MORE}
                  </p>
                  <Icon
                    icon="mdi:add"
                    width="28px"
                    height="28px"
                    style={{ color: "#ccff00" }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="bg-[#414142] w-full h-[60px] px-3 rounded-[10px] flex items-center">
              <input
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                placeholder={placeholder.ADD_TITLE}
                className="bg-[#414142] w-full text-white text-lg font-bold placeholder:font-normal"
              />
            </label>
            <div className="flex items-center justify-between gap-2 my-4 flex-wrap">
              <div className="flex items-center gap-2.5">
                <Icon
                  icon="uil:user"
                  width="30"
                  height="30"
                  style={{ color: "#C5C5C5" }}
                />
                <div className="flex items-center gap-2">
                  <p className="mb-0 font-extrabold text-[24px]">{"test"}</p>
                  <img src={medal} alt="medal" />
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2 ">
                  <Icon
                    icon="lets-icons:date-today"
                    width="30"
                    height="30"
                    style={{ color: "#C5C5C5" }}
                  />
                  <p className="mb-0 text-[22px] font-extrabold border-r border-[#444444] pr-8 max-sm:pr-3">
                    {moment(new Date()).format("YYYY.MM.DD")}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <Icon
                    icon="ion:time-outline"
                    width="30"
                    height="30"
                    style={{ color: "#C5C5C5" }}
                  />
                  <p className="mb-0 text-[22px] font-extrabold">
                    {moment().format("h:mm")}
                    <span className="text-white text-lg font-bold">
                      {moment().format("A")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <textarea
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              placeholder={placeholder.WRITE_CONTENT}
              className="bg-[#414142] w-full h-[200px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
            />
            <div
              className={`${
                imagePreview &&
                imagePreview.length > 0 &&
                imagePreview.length < 4
                  ? "justify-start"
                  : innerLoader
                  ? "justify-start"
                  : "justify-center"
              } flex items-center`}
            >
              <div
                className={`${
                  !imagePreview || imagePreview.length == 0
                    ? innerLoader
                      ? "justify-start"
                      : "justify-center"
                    : "justify-start"
                } ${"flex items-center my-8 gap-4 flex-wrap"}`}
              >
                <input
                  type="file"
                  accept="image/*, video/*"
                  className="hidden"
                  id="uploadMedia"
                  onChange={(e) => getVideoThumbnail(e)}
                />
                {(!imagePreview || imagePreview.length == 0) && (
                  <label
                    htmlFor="uploadMedia"
                    style={{ border: "2px solid #4B4B4B" }}
                    className={`${
                      innerLoader && "hidden"
                    } ${"flex items-center gap-3 rounded-[10px] py-2.5 px-6 cursor-pointer"}`}
                  >
                    <Icon
                      icon="humbleicons:upload"
                      width="48"
                      height="48"
                      style={{ color: "white" }}
                    />
                    <p className="mb-0 text-[24px] font-bold">
                      {strings.UPLOAD_MEDIA_FILES}
                    </p>
                  </label>
                )}
                {imagePreview &&
                  imagePreview?.map((item, index) => (
                    <div
                      className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] relative"
                      key={index}
                    >
                      <img
                        src={item.url || item.view}
                        className="w-full h-full object-cover rounded-[10px]"
                      />
                      <span
                        onClick={() => {
                          removeProduct(item.url, index);
                        }}
                        className="absolute top-2 w-[30px] h-[30px] right-2 flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                      >
                        <Icon
                          icon="iconamoon:close-bold"
                          width="25"
                          height="25"
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      </span>

                      {item?.type == "video" && (
                        <span
                          onClick={() => {
                            setVideoPreview(true);
                            setuploadedMedia(item);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                {((imagePreview?.length > 0 && imagePreview?.length !== 10) ||
                  innerLoader) && (
                  <label
                    htmlFor="uploadMedia"
                    className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] flex items-center justify-center relative"
                  >
                    {!innerLoader ? (
                      <Icon
                        icon="bitcoin-icons:plus-filled"
                        width="100"
                        height="100"
                        style={{ color: "white" }}
                      />
                    ) : (
                      <span className="absolute w-full h-full flex items-center justify-center">
                        <Icon
                          icon="eos-icons:bubble-loading"
                          width="60"
                          height="60"
                          style={{ color: "#CCFF00" }}
                          className="loader-icon "
                        />
                      </span>
                    )}
                  </label>
                )}
              </div>
            </div>
            {error && (
              <p className="mt-12 mb-6 text-[20px] font-extrabold text-[#FFB0B0] text-center">
                {error}
              </p>
            )}
            <div
              className={`${
                error ? "mt-24" : "mt-6"
              } ${"flex items-center flex-wrap justify-center gap-3"}`}
            >
              <button
                onClick={() => {
                  setOpenRegister(false);
                }}
                className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
              >
                {strings.CANCEL}
              </button>
              <button
                disabled={
                  title &&
                  content &&
                  !innerLoader &&
                  writingTypeValue.value &&
                  targetTypeValue.value
                    ? false
                    : true
                }
                onClick={() => {
                  setIsConfirm(true);
                }}
                className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {defaultValue ? strings?.UPDATE : strings.REGISTER}
              </button>
            </div>
          </div>
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
      {fundsError && (
        <FundsModal
          fundsError={fundsError}
          setFundsError={setFundsError}
          amount={amount}
        />
      )}
      <MessageConfirmModal
        classNameValue={classNameValue}
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
        clickEventHandle={() => {
          defaultValue ? editMessageHandler() : handleRegisterMessage();
        }}
      />
    </section>
  );
}

export default MessageRegister;

const writingType = [
  "Center notification",
  "Event notice",
  "individual dispatch",
];

const sendingTargetArr = [
  "Memberships",
  "PT Members",
  "All Members",
  "Member Search (Individual)",
];

function WritingTypeModal({
  setWritingTypeValue,
  setCheckedMembers,
  setTargetTypeValue,
}) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-0 top-16 max-h-[200px] overflow-auto z-10 px-4 py-2">
      {writingType.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setWritingTypeValue({ value: item, key: index });
            if (index == 0) {
              setCheckedMembers([]);
              setTargetTypeValue({ value: "", key: "" });
            }
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480] "
        >
          <span className="mb-0 text-lg font-bold pr-2">{item}</span>
        </div>
      ))}
    </div>
  );
}

function SendingTargetModal({
  setTargetTypeValue,
  setOpenModal,
  writingTypeValue,
  setCheckedMembers,
}) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-0 top-16 max-h-[200px] overflow-auto z-10 px-4 py-2">
      {sendingTargetArr.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              setTargetTypeValue({ value: item, key: index });
              index == 3 &&
                writingTypeValue.value !== "Center notification" &&
                setOpenModal(true);
              if (writingTypeValue.key === 0) {
                setCheckedMembers([]);
              }
            }}
            className={`${
              writingTypeValue.value == "Center notification" && index == 3
                ? "hidden"
                : "border-b"
            } ${"flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-[#44444480] "}`}
          >
            <span className="mb-0 text-lg font-bold pr-2">
              {writingTypeValue.value == "Center notification" && index == 3
                ? ""
                : item}
            </span>
          </div>
        );
      })}
    </div>
  );
}
