import React, { useEffect, useRef, useState } from "react";
import profile from "../../../assests/images/User-60.svg";
import EmojiPicker from "emoji-picker-react";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { chatIdHandle, messageCount } from "../../../Store/slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import ManagementSublyApi from "../../../helper/ManagementApi";
import moment from "moment";
import io from "socket.io-client";
import { strings } from "../../../utils/String";
import BoardSublyApi from "../../../helper/BoardApi";
import { FormMedia } from "../boardScreens/RegistrationForm";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import WarningModal from "../gymSettings/gymSettingsScreens/gymInformationScreens/WarningModal";
export const ROLES = {
  0: "staff",
  1: "trainer",
  2: "owner",
  3: "user",
  4: "admin",
};

const ChatScreen = ({
  onClose,
  setChatScreen,
  classNameValue,
  fetchStaffpermission,
}) => {
  const { user, role } = useSelector((state) => state.user.selectedGym);
  const { token, selectedGym } = useSelector((state) => state.user);
  const [chatList, setchatList] = useState();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);

  // =======Get refund requested chat detail========
  useEffect(() => {
    let socketValue = io(process.env.REACT_APP_BASE_URL);
    setSocket(socketValue);
    socket?.emit("setup", { id: selectedGym?.staffId });
    socket?.on("message received", (newMessageReceived) => {
      // Handling List
      const chatFound =
        chatList &&
        chatList?.map((data) => {
          if (data?.id && data?.id === newMessageReceived?.conversationId) {
            return {
              staffId: data?.staffId,
              name: data?.name,
              profile: data?.profile,
              conversationId: newMessageReceived?.conversationId,
              lastMessage: newMessageReceived?.message || "",
              lastMessageAt: newMessageReceived?.regDate,
              unreadMessageCount: (data?.unreadMessageCount || 0) + 1,
            };
          } else {
            return data;
          }
        });
      chatFound && setchatList(chatFound);
    });
    fetchChatDetail();
  }, []);
  async function fetchChatDetail() {
    await ManagementSublyApi.getChatList(token)
      .then((respo) => {
        if (respo.statusCode == 200) {
          setchatList(respo.data);
        }
      })
      .catch((err) => console.log(err));
  }

  // ===========APi for generaating chat id===============
  async function generateChatIdHandle(id) {
    const requestData = { receiverId: id, receiverType: 0, type: 0 };
    await ManagementSublyApi.generateChatId(token, requestData)
      .then((respo) => {
        if (respo.statusCode == 200) {
          setChatScreen(true);
          dispatch(chatIdHandle({ response: respo.data, status: true }));
          onClose(false);
        }
      })
      .catch((err) => console.log(err));
  }

  function timeAgoFromNow(date) {
    const now = moment();
    const past = moment(date);
    const diffInSeconds = now.diff(past, "seconds");
    const diffInMinutes = now.diff(past, "minutes");
    const diffInHours = now.diff(past, "hours");

    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec ago`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else {
      return past.fromNow(); // e.g., '2 days ago'
    }
  }

  return (
    <div
      className={` ${classNameValue} ${"absolute rounded-[10px] border-[#CCFF00] border-1 bg-[#353638] min-w-[493px] max-[700px]:right-20 right-28 bottom-0 z-50 p-3 max-[650px]:min-w-[400px] max-[650px]:right-4 max-[650px]:bottom-20 max-[460px]:min-w-[350px] max-[460px]:right-0 max-[460px]:bottom-20"}`}
    >
      <>
        <div className="flex items-center justify-between border-b-[1px] pb-3 border-[#444444] z-50">
          <div className="flex gap-2 items-center">
            <img
              width="35"
              height="35"
              className="rounded-full"
              src={profile}
            />
            <p className="text-lg font-bold !mb-0">
              {user?.name} {`(${ROLES[role]})`}
            </p>
          </div>
          <Icon
            icon="radix-icons:cross-2"
            width="24"
            height="24"
            style={{ color: "white" }}
            onClick={() => {
              onClose(false);
            }}
          />
        </div>
        {!chatList && (
          <span className="absolute w-full h-full flex  mt-5 items-start justify-center">
            <Icon
              icon="eos-icons:bubble-loading"
              width="60"
              height="60"
              style={{ color: "#CCFF00" }}
              className="loader-icon "
            />
          </span>
        )}
        <div className="max-h-[calc(100vh_-_320px)] min-h-[400px] overflow-auto pr-2">
          {chatList
            ?.sort(
              (a, b) => new Date(b.lastMessageAt) - new Date(a.lastMessageAt)
            )
            .map((val, index) => {
              return (
                <div key={index}>
                  <div
                    className="flex justify-between items-center border-b-[1px] cursor-pointer border-[#444444]"
                    onClick={() => {
                      generateChatIdHandle(val.staffId);
                      fetchStaffpermission();
                    }}
                  >
                    <div className="flex gap-2 items-cnenter my-3 cursor-pointer w-[80%]">
                      <div className="w-14 h-14 rounded-full">
                        <img
                          src={val?.profile?.prof_img1_min || profile}
                          className="rounded-full w-full h-full object-cover"
                        />
                      </div>
                      <div className="flex flex-col justify-center w-[70%]">
                        <p className="text-xl font-extrabold !mb-0">
                          {val?.name}
                        </p>
                        <p className="!mb-0 text-lg font-bold text-[#ADB5BD] textOverflowClass">
                          {val?.lastMessage}
                        </p>
                      </div>
                    </div>
                    {val?.unreadMessageCount > 0 ? (
                      <div className="flex items-end justify-center flex-col">
                        <p className="text-sm !mb-0 font-medium text-[#FFFFFF]">
                          {timeAgoFromNow(val?.lastMessageAt)}
                        </p>
                        <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#CCFF00] my-2">
                          <p className="text-sm font-bold text-black !mb-0">
                            {val?.unreadMessageCount}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </>
    </div>
  );
};

export default ChatScreen;

export function Chat({
  setSelectEmoji,
  selectEmoji,
  setEmoji,
  chatInput,
  setChatInput,
  setChatScreen,
  setMessageScreen,
  classNameValue,
}) {
  const { token, selectedGym, staffAuthRespo } = useSelector(
    (state) => state.user
  );
  const { id } = useSelector((state) => state.user.chatIdResponse);
  const { staffId } = useSelector((state) => state.user.selectedGym);
  const dispatch = useDispatch();
  const [chatData, setChatData] = useState();
  const [gymDetails, setgymDetails] = useState();
  const scrollBottom = useRef();
  const [error, setError] = useState();
  const [imagePreview, setImagePreview] = useState("");
  const [requestedData, setRequestedData] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [innerLoader, setInnerLoader] = useState(false);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [liveMessage, setLiveMessage] = useState([]);
  let currentDate = moment().format("MMMM DD, yyyy");
  const [socket, setSocket] = useState(null);
  const bottomRef = useRef(null);

  const handleEmojiClick = (emojiData) => {
    const emoji = emojiData.emoji;
    setChatInput((prevInput) => prevInput + emoji);
    setChatScreen(true);
  };

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BASE_URL); // Ensure you initialize socket properly
    setSocket(newSocket);

    newSocket?.emit("join chat", id?.id);
    newSocket?.emit("setup", { id: selectedGym?.staffId });

    newSocket?.on("message received", (newMessageReceived) => {
      if (id?.id === newMessageReceived?.conversationId) {
        if (selectedGym?.staffId != newMessageReceived?.senderId) {
          newSocket?.emit("message read", {
            conversationId: id?.id,
            messageId: newMessageReceived?.id || null,
          });
        }
        setLiveMessage((prevLiveMessage) => {
          const updatedMessages = [...prevLiveMessage, newMessageReceived];
          groupMessagesByDate(updatedMessages);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
          return updatedMessages;
        });
      }
    });

    return () => {
      newSocket?.disconnect();
    };
  }, [id?.id, selectedGym?.staffId]);

  useEffect(() => {
    fetchChatDetail();
  }, []);

  async function fetchChatDetail() {
    await ManagementSublyApi.getChatDetail(token, id.id)
      .then((respo) => {
        if (respo.statusCode == 200) {
          dispatch(messageCount(respo.data.totalUnreadCount));
          setChatData(respo.data);
          setgymDetails(respo.data.gymDetails);
          setChatInput("");
          setLiveMessage(respo.data.messages);
          groupMessagesByDate(respo.data.messages);
        }
      })
      .catch((err) => console.log(err));
  }
  // =======API for send message========
  async function sendMessageHandle() {
    const requestData = {
      message: chatInput,
      conversationId: id.id,
      token: token,
      senderId: staffId,
      files: requestedData || [],
      senderDetails: id?.senderDetails,
    };
    if (socket?.connected) {
      if (chatData.length === 0) {
        socket?.emit("joinChannel", id?.id);
      }
      socket?.emit("sendMessage", requestData);
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setSelectEmoji(false);
    }
    setChatInput("");
    setRequestedData("");
    setImagePreview("");
  }

  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};

    messages.forEach((message) => {
      const messageDate = new Date(message.regDate).toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      );
      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }
      groupedMessages[messageDate].push(message);
    });

    setChatData(groupedMessages);
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBottom = () => {
    if (scrollBottom.current) {
      scrollBottom.current.scrollTop = scrollBottom.current.scrollHeight;
    }
  };

  const handleKeyDown = (event) => {
    if (chatInput || imagePreview.length > 0) {
      if (event.key === "Enter") {
        sendMessageHandle(); // Call the function
        event.preventDefault(); // Prevent default behavior if necessary
      }
    }
  };

  // ==============FUNCTION MANAGE IMAGE OR VIDEO FOR UPLOADING=================
  function mediaHandle(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    setError("");
    if (file?.type.match("video")) {
      if (file?.size / 1000000 <= 200) {
        var image = "";
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = async function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              setInnerLoader(true);
              const requestData = new FormData();
              requestData.append("file", file);
              await BoardSublyApi.assestUpload(requestData, token)
                .then((response) => {
                  setInnerLoader(false);
                  if (response.statusCode == 200) {
                    let media = [...imagePreview];
                    const toDeploy = [...requestedData];
                    media.push({
                      compressed: response.data.compressed,
                      type: "video",
                      view: image,
                      original: response.data.original,
                    });
                    toDeploy.push({
                      compressed: response.data.compressed,
                      type: "video",
                      original: response.data.original,
                    });
                    setRequestedData(toDeploy);
                    setImagePreview(media);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              const base64String = image; // Base64 string
              const binaryString = atob(base64String.split(",")[1]); // Binary data string
              const blob = new Blob([binaryString], { type: "image/png" });
              // setPassThumbnail(blob);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setError("Video size should be less than 200 MB");
      }
    } else {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result) {
            setInnerLoader(true);
            const requestData = new FormData();
            requestData.append("file", file);
            await BoardSublyApi.assestUpload(requestData, token)
              .then((response) => {
                setInnerLoader(false);
                if (response.statusCode == 200) {
                  let media = [...imagePreview];
                  let toDeploy = [...requestedData];
                  media.push({
                    compressed: response.data.compressed,
                    type: "image",
                    view: result,
                    original: response.data.original,
                  });
                  toDeploy.push({
                    compressed: response.data.compressed,
                    type: "image",
                    original: response.data.original,
                  });
                  setRequestedData(toDeploy);
                  setImagePreview(media);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  // ===============function for handling remove selected media=============
  async function removeProduct(url, index) {
    await BoardSublyApi.assestDelete(url, token)
      .then((response) => {
        if (response.statusCode == 200) {
          const images = [...imagePreview];
          let toDeploy = [...requestedData];
          images.splice(index, 1);
          setImagePreview(images);
          toDeploy.splice(index, 1);
          setRequestedData(toDeploy);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  useEffect(() => {
    const timer = setTimeout(() => {
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, [chatData]);

  return (
    <div
      className={`${classNameValue} ${"absolute min-w-[493px] rounded-[10px] bg-[#353638] max-[700px]:right-20 right-28 bottom-0 z-50  max-[650px]:min-w-[400px] max-[650px]:right-4 max-[650px]:bottom-20 max-[460px]:min-w-[350px] max-[460px]:right-0 max-[460px]:bottom-20"}`}
    >
      <div className=" relative border-1 border-[#CCFF00] rounded-md">
        <div className="p-3">
          <div className="flex items-center justify-between border-b-[1px] pb-3 border-[#444444]">
            <div className="flex gap-2 items-center">
              <img
                width="35"
                height="35"
                className="rounded-full"
                src={profile}
              />
              <p className="text-lg font-bold !mb-0">
                {gymDetails?.gymName} {`(${id?.otherUserDetails?.name})`}{" "}
              </p>
            </div>
            <Icon
              icon="radix-icons:cross-2"
              width="24"
              height="24"
              style={{ color: "white" }}
              onClick={() => {
                setChatScreen(false);
                !id && setMessageScreen(true);
                dispatch(chatIdHandle({ response: "", status: false }));
                setSelectEmoji(false);
              }}
            />
          </div>
          {!chatData && (
            <span className="absolute w-full h-full flex  mt-5 items-start justify-center">
              <Icon
                icon="eos-icons:bubble-loading"
                width="60"
                height="60"
                style={{ color: "#CCFF00" }}
                className="loader-icon "
              />
            </span>
          )}
          <div
            className="max-h-[calc(100vh_-_420px)] min-h-[300px] overflow-auto pr-3 "
            ref={scrollBottom}
          >
            {chatData
              ? Object.keys(chatData)?.map((val, indexValue) => (
                  <div key={indexValue}>
                    <p className="text-center my-3">{val}</p>
                    {chatData[val]?.map((item, index) => {
                      const nextMessage = chatData[val][index + 1];
                      const isLastMessage =
                        nextMessage && nextMessage.senderId === item?.senderId;
                      return (
                        <div key={index}>
                          {item.senderId !== selectedGym?.staffId ? (
                            <div className="flex items-end gap-2 my-2">
                              <div className="w-[40px] h-[40px] mb-[20px]">
                                <img
                                  src={
                                    item?.senderDetails?.profile
                                      ?.prof_img1_min || profile
                                  }
                                  className="rounded-full w-full h-full"
                                />
                              </div>
                              <div className="w-[90%]">
                                {item?.files?.length > 0 && (
                                  <div className="mr-auto">
                                    <div className="flex items-center gap-2">
                                      {item?.files?.map((val, indexvalue) => (
                                        <div
                                          key={indexvalue}
                                          className="bg-[#414142] p-2 my-2 rounded-r-[20px] rounded-tl-[20px] relative max-w-[50%] max-h-[50%] min-w-[120px]"
                                        >
                                          <img
                                            src={val?.original}
                                            alt="media"
                                            className="w-full h-full rounded-[20px] object-cover"
                                          />
                                          {val?.type === "video" && (
                                            <span
                                              onClick={() => {
                                                setVideoPreview(true);
                                                setuploadedMedia(val);
                                              }}
                                              className="absolute w-full h-full top-0 right-0 flex items-center justify-center rounded-[5px] p-1"
                                            >
                                              <Icon
                                                icon="ooui:play"
                                                width="70"
                                                height="70"
                                                className="cursor-pointer"
                                                style={{ color: "white" }}
                                              />
                                            </span>
                                          )}
                                        </div>
                                      ))}
                                      {!isLastMessage &&
                                        val ===
                                          moment().format("MMMM DD, YYYY") &&
                                        chatData?.unreadCount > 0 && (
                                          <span className="w-[20px] h-[20px] rounded-full bg-[#CCFF00] text-sm font-extrabold flex items-center justify-center font-[archivo]">
                                            {chatData?.unreadCount}
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                )}
                                {item?.message && (
                                  <div className="max-w-[87%]">
                                    <div className="flex items-center gap-2 mr-auto">
                                      <div className="bg-[#414142] p-3 rounded-r-[32px] rounded-tl-[32px] max-w-max">
                                        <p className="text-sm !mb-0">
                                          {item?.message}
                                        </p>
                                      </div>
                                      {!isLastMessage &&
                                        val ===
                                          moment().format("MMMM DD, yyyy") &&
                                        chatData?.unreadCount > 0 && (
                                          <span className="w-[20px] h-[20px] rounded-full bg-[#CCFF00] text-sm font-extrabold flex items-center justify-center font-[archivo]">
                                            {chatData?.unreadCount}
                                          </span>
                                        )}
                                    </div>
                                    {!isLastMessage && (
                                      <p className="!mb-0 text-sm mt-1">
                                        {id?.otherUserDetails?.name},{" "}
                                        {moment(item?.regDate).format(
                                          "hh:mm A"
                                        )}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="text-end flex items-center justify-between my-2">
                              <div></div>
                              <div className="w-[90%]">
                                {item?.files?.length > 0 && (
                                  <div className="max-w-[50%] max-h-[50%] min-w-[120px] ml-auto my-2">
                                    {item?.files?.map((val, index) => (
                                      <div
                                        key={index}
                                        className="bg-[#414142] p-2 my-2 rounded-l-[20px] rounded-tr-[20px] relative"
                                      >
                                        <img
                                          src={val?.original}
                                          alt="media"
                                          className="w-full h-full rounded-[20px] object-cover"
                                        />
                                        {val?.type === "video" && (
                                          <span
                                            onClick={() => {
                                              setVideoPreview(true);
                                              setuploadedMedia(val);
                                            }}
                                            className="absolute w-full h-full top-0 right-0 flex items-center justify-center rounded-[5px] p-1"
                                          >
                                            <Icon
                                              icon="ooui:play"
                                              width="70"
                                              height="70"
                                              className="cursor-pointer"
                                              style={{ color: "white" }}
                                            />
                                          </span>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {item?.message && (
                                  <div className="w-full flex justify-end">
                                    <div className="bg-[#414142] p-3 rounded-l-[32px] rounded-tr-[32px] max-w-[80%] w-auto break-words text-left">
                                      <p className="text-sm !mb-0">
                                        {item?.message}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {!isLastMessage && (
                                  <p className="!mb-0 text-sm mt-1 my-2">
                                    {item?.senderDetails?.name},{" "}
                                    {moment(item?.regDate).format("hh:mm A")}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))
              : ""}
            <div id="bottom" ref={bottomRef} />
          </div>
          {chatData?.length == 0 && (
            <p className="text-2xl font-extrabold text-[#444444] text-center my-4">
              {strings.NO_RECORD_FOUND}
            </p>
          )}
        </div>
        {((imagePreview && imagePreview.length > 0) || innerLoader) && (
          <div className={`justify-start flex items-center`}>
            <div
              className={`${
                !imagePreview || imagePreview.length == 0
                  ? innerLoader
                    ? "justify-start"
                    : "justify-center"
                  : "justify-start"
              } ${"flex items-center mt-2 mx-2 gap-2 flex-wrap"}`}
            >
              {imagePreview &&
                imagePreview?.map((item, index) => (
                  <div
                    className="w-[100px] h-[100px] rounded-t-[10px] bg-[#6F6F6F] relative"
                    key={index}
                  >
                    <img
                      src={item.url || item.view}
                      className="w-full h-full object-cover rounded-t-[10px]"
                    />
                    <span
                      onClick={() => {
                        removeProduct(item.url, index);
                      }}
                      className="absolute top-2 w-[25px] h-[25px] right-2 flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                    >
                      <Icon
                        icon="iconamoon:close-bold"
                        width="20"
                        height="20"
                        style={{ color: "white", cursor: "pointer" }}
                      />
                    </span>

                    {item?.type == "video" && (
                      <span
                        onClick={() => {
                          setVideoPreview(true);
                          setuploadedMedia(item);
                        }}
                        className="absolute top-[44%] right-[38%] w-[10px] h-[10px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                      >
                        <Icon
                          icon="ooui:play"
                          width="40"
                          height="40"
                          style={{ color: "white" }}
                        />
                      </span>
                    )}
                  </div>
                ))}
              {innerLoader && (
                <label
                  htmlFor="uploadMedia"
                  className="w-[100px] h-[100px] rounded-t-[10px] bg-[#6F6F6F] flex items-center justify-center relative"
                >
                  {innerLoader && (
                    <span className="absolute w-full h-full flex items-center justify-center">
                      <Icon
                        icon="eos-icons:bubble-loading"
                        width="40"
                        height="40"
                        style={{ color: "#CCFF00" }}
                        className="loader-icon "
                      />
                    </span>
                  )}
                </label>
              )}
            </div>
          </div>
        )}
        <div className="flex bg-[#414142] rounded-b-lg  p-3 h-16 items-center gap-2 ">
          <div className="flex items-center gap-2">
            <Icon
              icon="mdi:emoji"
              width="28"
              height="28"
              className="cursor-pointer"
              style={{ color: "#d1d1d0" }}
              onClick={() => {
                setSelectEmoji(!selectEmoji);
              }}
            />
            <label htmlFor="upload" className="flex items-center">
              <Icon
                icon="ci:paperclip-attechment-tilt"
                width="28px"
                height="28px"
                className="cursor-pointer"
                style={{ color: "#d1d1d0" }}
                rotate={90}
              />
              <input
                type="file"
                className="hidden"
                id="upload"
                disabled={innerLoader ? true : false}
                onChange={(e) => {
                  mediaHandle(e);
                }}
              />
            </label>
          </div>
          <input
            placeholder="Type your message here ..."
            className="w-[73%] bg-transparent text-white "
            type="text"
            value={chatInput} // Bind the value of the input field to the state
            onChange={(e) => setChatInput(e.target.value)}
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
          />
          <button
            disabled={chatInput || imagePreview.length > 0 ? false : true}
            onClick={() => {
              if (staffAuthRespo.messageSetting) {
                sendMessageHandle();
              } else {
                setShowWarning(true);
              }
              //  if(staffAuthRespo.messageSetting ){setShowWarning(true)} else{ sendMessageHandle()}
            }}
            className="w-[40px] h-[40px] flex  items-center justify-center rounded-full cursor-pointer bg-common_color disabled:bg-[#ccff0066]"
          >
            <Icon
              icon="material-symbols:send"
              width="26"
              height="26"
              style={{ color: "black" }}
            />
          </button>
        </div>
        <div>
          {selectEmoji && (
            <EmojiPicker
              height={300}
              searchDisabled
              skinTonesDisabled
              onEmojiClick={handleEmojiClick}
              width="100%"
            />
          )}
        </div>
        {showWarning && (
          <div className="absolute bottom-[210px] left-[-65px] z-50">
            <WarningModal
              headText={strings.UNABLE_TO_CHAT}
              text={strings.YOU_MAY_NEED_PERMISSION}
              subText={strings.PLESASE_CONATC_TO_AUTHORISED}
              setIsWarning={setShowWarning}
            />
          </div>
        )}
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
    </div>
  );
}
