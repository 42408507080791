import React, { useState } from "react";
import { placeholder, strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import ReactDatePicker from "react-datepicker";
import timeImg from "../../../../../assests/icons/time-img.svg";
import { useSelector } from "react-redux";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";

function AddInformation({ setaddInformation, setIsLoader, classNameValue }) {
  const { token } = useSelector((state) => state.user);
  const [isError, setIsError] = useState("");

  const [startDate, setStartDate] = useState("");
  const [unit, setUnit] = useState("");
  const [openType, setOpenType] = useState(false);
  const [isAlways, setisAlways] = useState(false);
  const [title, setTitle] = useState("");
  const [quantity, setQuantity] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  async function addMachineEnquipment() {
    setIsLoader(true);
    const machineData = {
      title: title,
      quantity: quantity,
      equipmentType: 0,
      unit: unit,
    };
    await GymSettingSublyApi.addMachine(token, machineData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setaddInformation(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  }

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });
  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex py-8  xl:items-center justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[70%] max-lg:w-[90%] h-auto max-lg:p-4 max-lg:py-8 overflow-auto p-10 pb-4 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[78%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.SPORT_EQUIPMENT_INFO}
            </p>
          </div>
          <div className="px-32 max-md:px-0 mt-4">
            <label
              htmlFor="nameId"
              // onClick={() => {
              //   setOpenType(!openType);
              // }}
              className={`
                ${isError && !unit.type ? "inputErrorClass" : ""} 
                ${" w-full mb-2.5 relative"}`}
            >
              <span className="text-base font-normal text-[#fff] ml-2 spanClass">
                {strings.TITLE}
              </span>
              <div className="flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 errorBorder">
                <input
                  type="text"
                  className="text-white bg-[#414142] w-full !border-0 font-bold placeholder:font-normal"
                  id="nameId"
                  autoComplete="off"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  // value={eventType.text}
                  // disabled
                  placeholder={placeholder.ADD_TITLE}
                />
              </div>
            </label>
            <div className=" flex flex-wrap justify-center gap-3 mt-2">
              <div className="w-full sm:flex-1 ">
                <div className=" w-full sm:flex-1">
                  <label
                    htmlFor="nameId"
                    className={`
                ${isError && !unit.type ? "inputErrorClass" : " "} 
                ${" w-full mb-2.5 relative"}`}
                  >
                    <span className="text-base font-normal text-[#fff] ml-2 spanClass">
                      {strings.QUANTITY}
                    </span>
                    <div className="flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 errorBorder numberFieldClass">
                      <input
                        type="number"
                        className="text-white bg-[#414142] font-bold placeholder:font-normal border-none w-full numberField"
                        id="nameId"
                        autoComplete="off"
                        value={quantity}
                        onChange={(e) => {
                          setQuantity(e.target.value);
                        }}
                        // value={eventType.text}
                        // disabled
                        placeholder={placeholder.Add_EUPIPMENT_QUNTITY}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="w-full sm:flex-1">
                <div
                  className={`
                ${isError && !unit.type ? "inputErrorClass" : ""} 
                ${" w-full mb-2.5 relative"}`}
                >
                  <span className="text-base font-normal text-[#fff] ml-2 spanClass">
                    {strings.UNIT}
                  </span>
                  <div
                    className="flex items-center cursor-pointer gap-2 relative justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 errorBorder"
                    onClick={() => {
                      setOpenType(!openType);
                    }}
                  >
                    <input
                      type="text"
                      className="text-white bg-[#414142] !border-0 !caret-transparent font-bold placeholder:font-bold"
                      value={unit}
                      placeholder={placeholder.SELECT_UNIT}
                    />
                    {openType ? (
                      <Icon
                        icon="flowbite:caret-up-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE" }}
                      />
                    ) : (
                      <Icon
                        icon="flowbite:caret-down-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE" }}
                      />
                    )}
                    {openType && <ProductFilter setUnit={setUnit} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 border-[1px] border-[#5C5C5C] rounded-[10px] mx-auto py-10 my-4">
              <img className="mx-auto" src={timeImg} />
              <p className="text-center">{strings.WAIT_FOR_IMAGE}</p>
            </div>
          </div>
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 my-10"}`}
          >
            <button
              onClick={() => {
                addMachineEnquipment();
              }}
              disabled={!unit || !title || !quantity}
              className={`max-sm:w-full text-2xl font-extrabold rounded-[30px] kakaoClass text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094] `}
            >
              {strings.ADD}
            </button>
            <button
              onClick={() => {
                setaddInformation(false);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
      </div>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default AddInformation;

function ProductFilter({ setUnit }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[270px] right-0 top-12 max-h-[280px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setUnit(strings.KG);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.KG}</span>
      </div>
      <div
        onClick={() => {
          setUnit(strings.TONES);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.TONES}</span>
      </div>
    </div>
  );
}
