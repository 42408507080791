import React from "react";
import { Modal } from "react-bootstrap";
import logo from "../assests/attendanceIcons/logo-icon.svg";
import { strings } from "../utils/String";
import { useNavigate } from "react-router-dom";

function CheckYourEmailModal({
  subTextLineTwo,
  checkEmail,
  setCheckEmail,
  headText,
  subText,
}) {
  const navigate = useNavigate();
  return (
    <Modal
      className={`${"modalBoxClass attendnaceModalClass"}`}
      show={checkEmail}
      onHide={() => {
        setCheckEmail(false);
      }}
    >
      <Modal.Body className="text-white bg-box_bg text-center py-14 px-8">
        <div className="w-[80%] m-auto">
          <img src={logo} alt="logo" className="mx-auto" />
          <p className="mt-4 mb-2 text-[30px] font-extrabold">{headText}</p>
          <p className="text-[24px] font-bold pb-1 mb-0 text-center">
            {subText}
          </p>
          <p className="text-[24px] font-bold pb-1 mb-4 text-center">
            {subTextLineTwo}
          </p>
          <div className="flex justify-center items-center pt-3 gap-4">
            <button
              onClick={() => {
                setCheckEmail(false);
                navigate("/Attendance/login");
              }}
              className="text-[20px] h-[55px] font-extrabold flex justify-center items-center gap-1.5 bg-[#CCFF00] text-black w-[155px] rounded-[28px]"
            >
              {strings.CONFIRM}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CheckYourEmailModal;
