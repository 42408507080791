import React, { useEffect } from "react";
import logo from "../assests/attendanceIcons/logo-icon.svg";
import { Icon } from "@iconify-icon/react/dist/iconify.js";
import { useDispatch } from "react-redux";
import { adminPanelHandle, attendanceLogout } from "../Store/slices/AuthSlice";
import { useNavigate } from "react-router-dom";

function AttendanceHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   =========Logout function ============
  function logoutHandle() {
    dispatch(attendanceLogout());
    navigate("/Attendance/Login");
  }

  useEffect(() => {
    dispatch(adminPanelHandle(false));
  }, []);

  return (
    <div className="flex justify-between items-center pt-4 px-5">
      <div>
        <img src={logo} className="m-auto w-[100px]" />{" "}
      </div>
      <div
        onClick={() => {
          logoutHandle();
        }}
        className="kakaoClass rounded-[28px] text-white hover:text-black hover:bg-[#CCFF00] py-2 px-3 font-bold flex items-center gap-1 cursor-pointer"
      >
        <Icon icon="material-symbols:logout" width="22px" height="22px" />
        <span>Logout</span>
      </div>
    </div>
  );
}

export default AttendanceHeader;
