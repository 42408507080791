import "./App.css";
import Router from "./routes/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import messageIcon from "./assests/icons/message-Icon.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatScreen, {
  Chat,
} from "./components/appComponents/management/ChatScreen";
import {
  chatIdHandle,
  deleteUserDetail,
  fetchStaffAuth,
  messageCount,
} from "./Store/slices/AuthSlice";
import $ from "jquery";
import MembersSublyApi from "./helper/MemberApi";
import { io } from "socket.io-client";
import { InactivityDetector } from "./utils/commonUtils";

function App() {
  const dispatch = useDispatch();
  const { selectedGym, totalUnreadMessage, isAdminPanel } = useSelector(
    (state) => state.user
  );
  const { token, staffId } = useSelector((state) => state.user.selectedGym);
  const { status } = useSelector((state) => state.user.chatIdResponse);
  const [chatScreen, setChatScreen] = useState(false);
  const [messageScreen, setMessageScreen] = useState(false);
  const [selectEmoji, setSelectEmoji] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const [emoji, setEmoji] = useState();

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0
    ) {
      setChatScreen(false);
      setMessageScreen(false);
      dispatch(chatIdHandle({ response: "", status: false }));
      setSelectEmoji(false);
    }
  });

  async function fetchStaffpermission() {
    await MembersSublyApi.staffPermission(token, staffId)
      .then((response) => {
        if (response.statusCode == 200) {
          dispatch(fetchStaffAuth(response.data.authority));
          dispatch(messageCount(response.data.unreadMessageCount));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BASE_URL);
    newSocket.on(
      `unread_msg_${selectedGym?.staffId}_${selectedGym?.role}`,
      (unreadCount) => {
        dispatch(messageCount(unreadCount));
      }
    );
  }, []);

  // ===============Logout function===============
  function Logout() {
    dispatch(deleteUserDetail());
  }

  function LogoutAdminPanel() {
    dispatch(deleteUserDetail());
  }

  return (
    <div>
      <Router />
      {isAdminPanel ? (
        <InactivityDetector
          timeout={21600000}
          onInactivity={LogoutAdminPanel}
        />
      ) : (
        <InactivityDetector timeout={21600000} onInactivity={Logout} />
      )}
      {selectedGym.gymId && isAdminPanel ? (
        <div className="bottom-12 right-10 fixed z-50 max-[700px]:bottom-5 max-[700px]:right-8">
          <div
            className={`${
              totalUnreadMessage > 0 ? "shadowClass" : "shadowHoverClass"
            } ${"w-[90px] h-[90px] max-[700px]:w-[70px] max-[700px]:h-[70px] rounded-full bg-[#CCFF00] flex justify-center items-center relative cursor-pointer"}`}
          >
            <div
              onClick={() => {
                setMessageScreen(!messageScreen);
                setChatScreen(false);
                dispatch(chatIdHandle({ response: "", status: false }));
                fetchStaffpermission();
              }}
              className={`${"w-full h-full rounded-full bg-[#CCFF00] flex justify-center items-center p-3 relative cursor-pointer"}`}
            >
              <img src={messageIcon} className="closedNotify w-full h-full" />
              {totalUnreadMessage > 0 && (
                <span className="w-[35px] h-[35px] bg-[#FF0000] flex items-center justify-center rounded-full absolute left-[60px] bottom-[65px] text-white text-xl font-extrabold">
                  {totalUnreadMessage}
                </span>
              )}
            </div>
            {messageScreen && (
              <ChatScreen
                opneMessage={setChatScreen}
                onClose={setMessageScreen}
                openModal={messageScreen}
                setChatScreen={setChatScreen}
                classNameValue={`${
                  messageScreen ? "block_notify" : "hideNotify"
                }`}
                fetchStaffpermission={fetchStaffpermission}
              />
            )}
            {(chatScreen || status) && (
              <Chat
                setChatInput={setChatInput}
                setChatScreen={setChatScreen}
                setEmoji={setEmoji}
                selectEmoji={selectEmoji}
                setSelectEmoji={setSelectEmoji}
                chatInput={chatInput}
                setMessageScreen={setMessageScreen}
                classNameValue={`${
                  chatScreen || status ? "block_notify" : "hideNotify"
                }`}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default App;
