import React, { useEffect, useState } from "react";
import { strings } from "../../../../utils/String";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import MembersSublyApi from "../../../../helper/MemberApi";
import { Icon } from "@iconify-icon/react";
import LockerEditModal from "../../../commonComponents/modalScreens/LockerEditModal";
import DeleteConfirmation from "../../../commonComponents/modalScreens/DeleteConfirmation";
import DeleteSuccess from "../../../commonComponents/modalScreens/DeleteSuccess";
import { useSelector } from "react-redux";

function LockerListModule({
  setLockerList,
  classNameValue,
  lockerValue,
  setLockerValue,
  setIsLoader,
}) {
  const { token } = useSelector((state) => state.user);
  const [lockerStatus, setLockerStatus] = useState("empty");
  const [active, setActive] = useState("active");
  const [lockerListData, setLockerListData] = useState("");
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [editLocker, setEditLocker] = useState(false);
  const [lockerId, setLockerId] = useState({
    id: "",
    number: "",
    password: "",
  });
  const [deleteLocker, setDeleteLocker] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  // =========Fetching locker list API============
  useEffect(() => {
    async function lockerListHandle() {
      setIsLoader(true);
      await MembersSublyApi.lockerList(offset, lockerStatus, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setLockerListData(response.data.lockers);
            setPage(response.data.count / 10);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    lockerListHandle();
  }, [offset, lockerStatus, isUpdate]);

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // ==============Delete locker API==============
  async function deleteLockerHandle() {
    await MembersSublyApi.deleteLocker(lockerId.id, token)
      .then((response) => {
        if (response.statusCode == 200) {
          setDeleteSuccess(true);
          setIsUpdate(true);
          setDeleteLocker(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#2C2C2E] rounded-[10px] w-[95%] max-h-[92%] max-lg:p-4 max-lg:py-8  p-10 pb-6 mt-10 z-20 overflow-auto"}`}
      >
        <div className="w-[75%] max-lg:w-[100%] m-auto">
          <h3 className="text-[40px] max-lg:text-[32px] font-extrabold text-white border-b border-[#444444] pb-8 text-center">
            {strings.LIST_OF_LOCKERS}
          </h3>
          <div className="flex items-center justify-center gap-3 mt-10 mb-7">
            <button
              onClick={() => {
                setLockerStatus("empty");
                setOffset(1);
              }}
              className={`${
                lockerStatus == "empty"
                  ? "text-black bg-common_color"
                  : "text-white kakaoClass"
              } ${"text-lg max-lg:text-sm font-extrabold h-[52px] max-lg:h-[40px] max-lg:w-[150px] w-[170px] rounded-[28px]"}`}
            >
              {strings.EMPLTY_LOCKERS}
            </button>
            <button
              onClick={() => {
                setLockerStatus("in-use");
                setOffset(1);
              }}
              className={`${
                lockerStatus == "in-use"
                  ? "text-black bg-common_color"
                  : "text-white kakaoClass"
              } ${"text-lg max-lg:text-sm font-extrabold h-[52px] max-lg:h-[40px] max-lg:w-[150px] w-[170px] rounded-[28px]"}`}
            >
              {strings.IN_USE_LOCKER}
            </button>
            <button
              onClick={() => {
                setLockerStatus("expired");
                setOffset(1);
              }}
              className={`${
                lockerStatus == "expired"
                  ? "text-black bg-common_color"
                  : "text-white kakaoClass"
              } ${"text-lg max-lg:text-sm font-extrabold h-[52px] max-lg:h-[40px] max-lg:w-[150px] w-[170px] rounded-[28px]"}`}
            >
              {strings.EXPIRED_LOCKERS}
            </button>
          </div>
          <div className="max-h-[574px] overflow-auto">
            <div className="flex justify-around items-center bg-[#353638] h-[60px]">
              <th className="!text-center text-[20px] font-bold text-white ">
                {strings.LOCKER_NUMBER}
              </th>
              <th className="!text-start w-[160px] text-[20px] font-bold text-white ">
                {strings.PASSWORD}:
              </th>
            </div>
            <div className="border-b border-x border-[#444444]">
              {lockerListData && lockerListData.length > 0
                ? lockerListData.map((items, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          setLockerValue({
                            name: items.lockerNumber,
                            password: items.lockerPassword,
                            id: items.id,
                          })
                        }
                        className={`${
                          lockerValue.id == items.id
                            ? "activeBorder bg-[#353638]"
                            : "border-b border-[#444444]"
                        } ${"hover:bg-[#353638] bg-[#2C2C2E] flex justify-around items-center cursor-pointer"}`}
                      >
                        <td
                          align="center"
                          className={`${
                            lockerValue.id == items.id ? "" : "border-r"
                          } ${"text-white font-bold text-lg  border-[#444444] w-full h-[45px] flex justify-center items-center"}`}
                        >
                          {items.lockerNumber}
                        </td>
                        <td
                          align="center"
                          className="text-white font-bold text-lg w-full h-[45px] flex justify-between items-center"
                        >
                          <span className="w-[90%]">
                            {items.lockerPassword == ""
                              ? "--"
                              : items.lockerPassword}
                          </span>
                          <span className="flex items-center justify-center gap-2 w-[20%] pr-4">
                            {lockerId.id == items.id ? (
                              <Icon
                                icon="bxs:edit"
                                width="23"
                                height="23"
                                className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                              />
                            ) : (
                              <Icon
                                icon="bxs:edit"
                                width="23"
                                height="23"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditLocker(true);
                                  setLockerId({
                                    id: items.id,
                                    number: items.lockerNumber,
                                    password: items.lockerPassword,
                                  });
                                  setLockerValue({
                                    name: "",
                                    password: "",
                                    id: "",
                                  });
                                }}
                                className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                              />
                            )}
                            <Icon
                              icon="mdi:trash-can-outline"
                              width="24"
                              height="24"
                              onClick={() => {
                                setDeleteLocker(true);
                                setLockerId({
                                  id: items.id,
                                  number: "",
                                  password: "",
                                });
                              }}
                              className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer"
                            />
                          </span>
                        </td>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
          <div className="mt-6">
            {" "}
            {page && page > 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                // pageRangeDisplayed={5}
                forcePage={offset == 1 ? 0 : offset - 1}
                pageCount={page}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={active}
                onClick={() => setActive("active")}
              />
            )}
          </div>
        </div>
        {/* {lockerListData && lockerListData.length > 0 && ( */}
        <div className="flex-wrap flex items-center justify-center mt-8 mb-6 pt-8 gap-3 border-t border-[#444444]">
          <button
            onClick={() => {
              // setLockerValue({
              //   name: "",
              //   password: "",
              //   id: "",
              // });
              setLockerList(false);
            }}
            className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px]"}`}
          >
            {strings.CLOSE}
          </button>
          <button
            disabled={lockerValue.id ? false : true}
            onClick={() => setLockerList(false)}
            className={`${"max-sm:w-full text-black bg-common_color"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px] disabled:bg-[#ccff0094]"}`}
          >
            {strings.CONFIRM}
          </button>
        </div>
        {/* )} */}
      </div>
      <LockerEditModal
        classNameValue={classNameValue}
        editLocker={editLocker}
        setEditLocker={setEditLocker}
        lockerId={lockerId}
        setLockerId={setLockerId}
        setIsUpdate={setIsUpdate}
      />
      <DeleteConfirmation
        classNameValue={classNameValue}
        textHead={strings.DELETE_LOCKER}
        text={strings.ARE_YOU_SURE_YOU_WANT_TO_DELETE_LOCKER}
        deleteMemo={deleteLocker}
        setDeleteMemo={setDeleteLocker}
        clickBtn={deleteLockerHandle}
        btnText={strings.DELETE}
      />
      <DeleteSuccess
        classNameValue={classNameValue}
        text={strings.MEMO_DELETED_SUCCESSFULLY}
        deleteSuccess={deleteSuccess}
        setDeleteSuccess={setDeleteSuccess}
        setIsUpdate={setIsUpdate}
        isDelete={true}
      />
    </section>
  );
}

export default LockerListModule;
