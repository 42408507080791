import React, { useState, useRef, useEffect } from "react";
import profile from "../../assests/images/demoProfile.svg";
import { Icon } from "@iconify-icon/react";
import trainer from "../../assests/icons/boldTrainer.svg";
import messageTick from "../../assests/icons/messageTick.svg";
import { strings } from "../../utils/String";
import lifter from "../../assests/icons/lifter.svg";
import playIcon from "../../assests/icons/play icon.svg";
import imgPlace from "../../assests/icons/imgPlace.svg";
import trainerReview from "../../assests/icons/dietlog-detail-icons/trainer comment.svg";
import moment from "moment";
import { WORKOUT_LISTS_TITLES } from "../../utils/CommonData";
import { scrollToTopFun } from "../appComponents/gymSettings/StaffSetting";
import $ from "jquery";
import legs from "../../assests/icons/workout-icon/Leg.png";
import chest from "../../assests/icons/workout-icon/Chest.png";
import back from "../../assests/icons/workout-icon/Back.png";
import shoulder from "../../assests/icons/workout-icon/Shoulder.png";
import biceps from "../../assests/icons/workout-icon/biceps.png";
import abdominal from "../../assests/icons/workout-icon/abdominal.png";
import arm from "../../assests/icons/workout-icon/Arm.png";
import hip from "../../assests/icons/workout-icon/Hip_icon.png";
import claves from "../../assests/icons/workout-icon/claves_icon.png";
import cardio from "../../assests/icons/workout-icon/Cardio.png";

const workoutIcon = {
  leg: { type: "leg", icon: legs },
  back: { type: "back", icon: back },
  chest: { type: "chest", icon: chest },
  shoulders: { type: "shoulders", icon: shoulder },
  arms: { type: "arms", icon: arm },
  core: { type: "core", icon: abdominal },
  claves: { type: "claves", icon: claves },
  hip: { type: "hip", icon: hip },
  cardio: { type: "cardio", icon: cardio },
};
function WorkoutCard({ item, openDetails, setOpenDetails }) {
  const [isOpen, setIsOpen] = useState({ id: "", status: false });
  // console.log(isOpen)
  const modalRef = useRef(null);
  const partName = item?.exercisesDetails?.map((item) => {
    return "" + item.part;
  });

  const toggleModal = () => {
    setIsOpen({
      id: item.date + item.memberDetails?.memberId,
      status: !isOpen.status,
    });
  };

  // const handleClickOutside = (event) => {
  //   setIsOpen({ id: "", status: false });
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => document.removeEventListener("mousedown", handleClickOutside);
  // }, []);

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setIsOpen({ id: "", status: false });
    }
  });

  return (
    <div className="relative">
      <div
        className={`${
          item?.exercisesDetails ? " cursor-pointer" : " "
        } ${"bg-[#353638] rounded-[10px] px-3 py-3 h-full"}`}
        onClick={() => {
          item?.exercisesDetails && setOpenDetails(!openDetails);
          scrollToTopFun();
          setIsOpen({ id: "", status: false });
        }}
      >
        <div className="flex items-start justify-between border-b border-[#505050] pb-3 flex-wrap gap-y-4">
          <div className="flex items-center gap-x-4 w-[55%] flex-wrap gap-y-2">
            <div className="flex items-center gap-x-3 !gap-y-2 flex-wrap">
              <img
                src={
                  item.memberDetails?.profile?.prof_img1_min
                    ? item.memberDetails?.profile?.prof_img1_min
                    : profile
                }
                alt="profile-img"
                className="rounded-full w-[60px] h-[60px]"
              />
              <div>
                <div className="mb-0 text-2xl font-extrabold text-white flex items-center gap-0.5">
                  <p className="mb-0 max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {item.memberDetails?.name}
                  </p>
                  {item?.sessionDetails && (
                    <>
                      {" "}
                      {`(${item?.sessionDetails?.completedSessions}`}
                      <span className="text-white text-xl">{`/${item?.sessionDetails?.totalSession}`}</span>
                      {")"}
                    </>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <Icon
                    icon="lets-icons:date-today"
                    width="30"
                    height="30"
                    style={{ color: "#D9D9D9" }}
                  />
                  <p className="mb-0 text-lg font-bold">
                    {moment(item?.date).format("YY.MM.DD")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              item?.confirmed ? "justify-between" : "justify-end"
            } ${"flex items-center flex-wrap gap-y-3 w-[45%]"}`}
          >
            {item?.confirmed ? (
              <p
                style={{ border: "1px solid #CCFF0033" }}
                className="mb-0 bg-[#CCFF001A] text-common_color text-base font-extrabold h-[35px] px-4 flex items-center justify-center rounded-2xl"
              >
                {strings.CONFIRM}
              </p>
            ) : (
              ""
            )}
            {item?.avgRating ? (
              <p
                className={`${
                  item.sessionStatus == WORKOUT_LISTS_TITLES.TRAINER_CREATED
                    ? "text-[#CCFF00]"
                    : item.sessionStatus == WORKOUT_LISTS_TITLES.PERSONAL
                    ? "text-[#FF6E00]"
                    : item.sessionStatus ==
                      WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                    ? "text-[#00FAE0]"
                    : ""
                } ${"mb-0 text-2xl font-extrabold flex items-center gap-2"}`}
              >
                <Icon
                  icon="tabler:star-filled"
                  width="30"
                  height="30"
                  style={
                    item.sessionStatus == WORKOUT_LISTS_TITLES.TRAINER_CREATED
                      ? { color: "#CCFF00" }
                      : item.sessionStatus == WORKOUT_LISTS_TITLES.PERSONAL
                      ? { color: "#FF6E00" }
                      : item.sessionStatus ==
                        WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                      ? { color: "#00FAE0" }
                      : ""
                  }
                />
                {item?.avgRating}
              </p>
            ) : (
              <div
                className="flex items-center gap-x-1 gap-y-2 bg-[#2C2C2E] rounded-[5px] w-max px-2 py-1"
                style={{ border: "1px solid #CCFF00" }}
              >
                <img
                  src={trainer}
                  alt="trainer"
                  className="w-[22px] h-[23px]"
                />
                <span className="text-2xl font-bold text-[#CCCDCD]">
                  {strings.TRAINER}:
                </span>
                <p className="mb-0 text-2xl font-bold max-w-[90px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {item.trainerDetails?.name}
                </p>
              </div>
            )}
          </div>
        </div>
        {partName ? (
          <div className="mt-3 flex items-center justify-between">
            <div className="flex items-center gap-x-3 gap-y-2 flex-wrap">
              <div className="rounded-full bg-[#616163] w-[80px] h-[80px] flex items-center justify-center">
                <img
                  src={workoutIcon[item?.majorExercisePart]?.icon || lifter}
                  alt="lifter"
                  className="w-[45px] h-[45px]"
                />
              </div>
              <div className="flex items-center gap-2">
                <span
                  className={`${
                    item.sessionStatus == WORKOUT_LISTS_TITLES.TRAINER_CREATED
                      ? "text-[#CCFF00]"
                      : item.sessionStatus == WORKOUT_LISTS_TITLES.PERSONAL
                      ? "text-[#FF6E00]"
                      : item.sessionStatus ==
                        WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                      ? "text-[#00FAE0]"
                      : ""
                  } ${" font-bold text-[22px]"}`}
                >
                  {strings.EXERCISES}:
                </span>
                <p className="mb-0 text-[22px] font-bold max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap">
                  <span>{partName && partName.toString()}</span>
                </p>
              </div>
            </div>
            <div className="dropdownClass">
              <img
                src={messageTick}
                alt="tick"
                onClick={(e) => {
                  toggleModal();
                  e.stopPropagation();
                }}
              />

              {isOpen.status && <div ref={modalRef}></div>}
            </div>
          </div>
        ) : (
          <div className="mt-3 flex items-center justify-between">
            <div className="flex items-center gap-x-3 gap-y-2 flex-wrap">
              <p className="mb-0 text-2xl font-bold text-[#FFB0B0] w-full py-4">
                {strings.NO_EXCERCISE}
              </p>
            </div>
          </div>
        )}
      </div>
      {isOpen.id == item.date + item.memberDetails?.memberId &&
        isOpen.status && (
          <WorkoutMoreInfo val={item} dropdownClass={"dropdownClass"} />
        )}
    </div>
  );
}

export default WorkoutCard;

function WorkoutMoreInfo({ val, dropdownClass }) {
  return (
    <div
      className={`${dropdownClass} ${"bg-[#474749] p-4 w-full absolute rounded-b-[10px] right-0 z-50 testClass top-[200px]"}`}
      style={{ boxShadow: "0px 4px 20px 0px #00000059" }}
    >
      <div className="flex items-center justify-between gap-2">
        {" "}
        <div
          className="flex items-center gap-2 bg-[#2C2C2E] rounded-[5px] w-max px-2 py-1"
          style={{ border: "1px solid #CCFF00" }}
        >
          <img src={trainer} alt="trainer" className="w-[22px] h-[23px]" />
          <span className="text-2xl font-bold text-[#CCCDCD]">
            {strings.TRAINER}:
          </span>
          <p className="mb-0 text-2xl font-bold max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
            {val?.trainerDetails?.name}
          </p>
        </div>
        <div>
          {val?.sessionStatus == WORKOUT_LISTS_TITLES.PERSONAL ||
          val?.sessionStatus == WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER ? (
            <p
              className={`${
                val?.sessionStatus == WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                  ? "bg-[#00dec81d] text-[#00FAE0]"
                  : "bg-[#ff6f0025] text-[#FF6E00]"
              } ${"mb-0 text-base font-extrabold py-2 px-3 rounded-3xl"}`}
            >
              {"Log Shared by member"}
            </p>
          ) : (
            <p
              className={`${
                val?.sessionStatus == "Logs created by trainer"
                  ? "bg-[#CCFF001A] text-[#CCFF00]"
                  : ""
              } ${"mb-0 text-base font-extrabold py-2 px-3 rounded-3xl"}`}
            >
              {"Log created by trainer"}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between gap-2 py-3 mt-2 border-b-2 border-[#505050] flex-wrap">
        <div>
          {val?.exercisesDetails?.length > 0
            ? val?.exercisesDetails?.map((item, index) => (
                <p
                  key={index}
                  className="mb-0 text-[25px] font-extrabold border-b-2 last:border-b-0 border-[#505050] pb-2"
                >
                  {item.part}: {item.setCount} {strings.SET},{" "}
                  {item.exerciseCount} {strings.EXERCISES?.toLowerCase()}
                </p>
              ))
            : ""}
        </div>
        <div className="flex items-center gap-x-8 justify-between ">
          <span className="relative">
            <img src={playIcon} alt="play-icon" />
            <span className="rounded-full w-6 h-6 flex items-start justify-center bg-[#FF5C5C] text-sm font-bold text-white absolute bottom-9 pt-0.5 left-8">
              {val?.videosCount}
            </span>
          </span>
          <div className="relative">
            <img src={imgPlace} alt="img-place" />
            <span className="rounded-full w-6 h-6 flex items-start justify-center bg-[#FF5C5C] text-sm font-bold text-white absolute bottom-9 pt-0.5 left-8">
              {val?.imagesCount}
            </span>
          </div>
        </div>
      </div>
      <div className="pt-3 flex items-center justify-between max-sm:flex-wrap gap-y-4">
        <div className="flex items-center gap-2">
          <p className="mb-0 font-bold text-[22px]">{strings.COMMENTS}:</p>
          <p className="mb-0 bg-[#2C2C2E] flex items-center gap-2 text-[30px] font-bold p-2 rounded-[5px]">
            <img
              src={trainerReview}
              alt="trainer-review"
              className="w-[30px] h-[30px]"
            />
            {val.comments}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <Icon
            icon="lets-icons:date-today"
            width="30"
            height="30"
            style={{ color: "#D9D9D9" }}
          />
          <p className="mb-0 text-[22px] font-bold">
            {moment(val?.date).format("YY.MM.DD")}
          </p>
        </div>
      </div>
    </div>
  );
}
