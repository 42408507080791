import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import calendar from "../../../assests/icons/schedule-icon/calendar.svg";
import fingureup from "../../../assests/icons/schedule-icon/fingureup.svg";
import medal from "../../../assests/icons/schedule-icon/medal.svg";
import cancel from "../../../assests/icons/schedule-icon/cancel.svg";
import noShow from "../../../assests/icons/schedule-icon/noShow.svg";
import dummy from "../../../assests/images/demoProfile.svg";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import moment from "moment";
import { Table } from "react-bootstrap";

function WeekModal({
  setDetailedSchedule,
  eventDate,
  setEvenetDate,
  classNameValue,
  setIsLoader,
}) {
  const { token } = useSelector((state) => state.user);
  const [detailValue, setDetailValue] = useState("");
  const [currentCount, setCurrentCount] = useState("bookedCount");
  const [bgColor, setBgColor] = useState("#ABA9FF33");
  const [iconSrc, setIconSrc] = useState(calendar);
  const [textColor, setTextColor] = useState("#B3B1FF");
  const [selectedButton, setSelectedButton] = useState("bookedCount");

  const handleButtonClick = (countType) => {
    setSelectedButton(countType);
    setCurrentCount(countType);
    if (countType === "completedCount") {
      setBgColor("#CBFF8A33");
      setIconSrc(medal);
      setTextColor("#CBFF8A");
    } else if (countType === "rejectedCount") {
      setBgColor("#FF919133");
      setIconSrc(cancel);
      setTextColor("#FF9191");
    } else if (countType === "requestCount") {
      setBgColor(" #FFE50033");
      setIconSrc(fingureup);
      setTextColor("#FFE500");
    } else if (countType === "bookedCount") {
      setBgColor("#ABA9FF33");
      setIconSrc(calendar);
      setTextColor("#B3B1FF");
    } else if (countType === "noshowcount") {
      setBgColor("#C0C0C033");
      setIconSrc(noShow);
      setTextColor("#E9E9E9");
    }
  };

  useEffect(() => {
    async function fetchScheduleDetailWeek() {
      setIsLoader(true);
      await PT_InformationSublyApi.scheduleDetailWeek(
        token,
        eventDate,
        currentCount,
        "week"
      )
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setDetailValue(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchScheduleDetailWeek();
  }, [currentCount]);

  // Extract the first and last date from sessionData
  const firstDate = detailValue?.sessionData?.[0]?.[0];
  const lastDate =
    detailValue?.sessionData?.[detailValue.sessionData.length - 1]?.[0];

  // Format the dates using moment
  const formattedFirstDate = moment(firstDate, "YY.MM.DD").format("YYYY.MM.DD");
  const formattedLastDate = moment(lastDate, "YY.MM.DD").format("YYYY.MM.DD");

  const sessionData = detailValue?.sessionData;
  if (!sessionData || sessionData.length === 0) return null;

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-50"}`}
    >
      <div
        className={`${classNameValue} ${"rounded-[10px] w-[80%] max-h-[78%] max-lg:p-4 max-lg:py-8 overflow-auto mt-24 mb-10 z-20  bg-[#2C2C2D] OverviewClass"}`}
      >
        <div className="w-[100%]">
          <div className=" m-auto border-b border-[#2C2C2D] text-white">
            <div className="mb-0 text-[30px] font-extrabold bg-[#353638] px-9 py-3 flex-wrap gap-y-2  flex items-center justify-between">
              <div>
                <span>
                  {formattedFirstDate} - {formattedLastDate}
                </span>{" "}
                <span>{strings.SCHEDULE}</span>
              </div>
              <div className="flex items-center gap-x-3 flex-wrap gap-y-2">
                <button
                  className={`rounded-[6px] flex items-center justify-evenly w-[108px] h-[40px] bg-[#B3B1FF] bg-opacity-20 ${
                    selectedButton === "bookedCount"
                      ? "border-2 border-[#ccff0094]"
                      : ""
                  }`}
                  onClick={() => handleButtonClick("bookedCount")}
                >
                  <img src={calendar} alt="calendar" />
                  <span className="text-[16px] text-[#B3B1FF]">
                    {strings.BOOKED}
                  </span>
                </button>
                <button
                  className={`rounded-[6px] flex items-center justify-evenly w-[142px] h-[40px] bg-[#FFE500] bg-opacity-20 ${
                    selectedButton === "requestCount"
                      ? "border-2 border-[#ccff0094]"
                      : ""
                  }`}
                  onClick={() => handleButtonClick("requestCount")}
                >
                  <img src={fingureup} alt="img" />
                  <span className="text-[16px] text-[#FFE500]">
                    {strings.RESERVATIONS}
                  </span>
                </button>
                <button
                  className={`rounded-[6px] flex items-center justify-evenly w-[142px] h-[40px] bg-[#CBFF8A] bg-opacity-20 ${
                    selectedButton === "completedCount"
                      ? "border-2 border-[#ccff0094]"
                      : ""
                  }`}
                  onClick={() => handleButtonClick("completedCount")}
                >
                  <img src={medal} alt="medal" />
                  <span className="text-[16px] text-[#CBFF8A]">
                    {strings.COMPLETED}
                  </span>
                </button>
                <button
                  className={`rounded-[6px] flex items-center justify-evenly w-[105px] h-[40px] bg-[#FF9191] bg-opacity-20 ${
                    selectedButton === "rejectedCount"
                      ? "border-2 border-[#ccff0094]"
                      : ""
                  }`}
                  onClick={() => handleButtonClick("rejectedCount")}
                >
                  <img src={cancel} alt="cancel" />
                  <span className="text-[16px] text-[#FF9191]">
                    {" "}
                    {strings.CANCEL}
                  </span>
                </button>
                <button
                  className={`rounded-[6px] flex items-center justify-evenly w-[121px] h-[40px] bg-[#E9E9E9] bg-opacity-20 ${
                    selectedButton === "noShowCount"
                      ? "border-2 border-[#ccff0094]"
                      : ""
                  }`}
                  onClick={() => handleButtonClick("noShowCount")}
                >
                  <img src={noShow} alt="noshow" />
                  <span className="text-[16px] text-[#E9E9E9]">
                    {strings.NO_SHOWS}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="overflow-auto OverviewClass">
            {" "}
            <Table
              responsive
              className="bg-[#2C2C2D] text-white w-max max-lg:w-[1338] h-[470px] border-collapse mt-14 ml-4"
              style={{ border: "1px solid #505050" }}
            >
              <thead>
                <tr className="border-b-2 border-[#505050] bg-[#2C2C2D] text-white">
                  <th className="border-l-2 border-[#505050] bg-[#2C2C2E] w-[117px] h-[56px] text-white text-center p-0 relative"></th>
                  {detailValue?.heading?.map((item) => (
                    <th
                      key={item.id}
                      className="border-l-2 border-[#505050] bg-[#2C2C2E] w-[117px] h-[66px] text-white text-center p-0 relative"
                    >
                      <div className="flex flex-col h-full justify-between">
                        <span className="text-center m-3">
                          {item.gymStaffName}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {sessionData.map((session, index) => {
                  const [date, ...count] = session;
                  return (
                    <tr key={index} className="border-b-2 border-[#505050] p-0">
                      <td className="py-3 border-l-2 border-[#505050] bg-[#2C2C2E] max-w-[20%] w-max h-full text-white text-center font-bold px-2">
                        {moment(date, "YY.MM.DD").format("YYYY.MM.DD")}
                      </td>
                      {count.map((val, index) => (
                        <td className="p-0 border-l-2 border-[#505050] bg-[#2C2C2E] max-w-[20%] w-max h-[56px] text-white text-center px-2">
                          {val !== 0 ? (
                            <div
                              key={index}
                              className={`p-2.5 w-[70px] h-[70%] mt-2  rounded-md inline-flex items-center justify-center gap-2.5 border-r-2 border-[#505050]`}
                              style={{ backgroundColor: bgColor }}
                            >
                              <img
                                src={iconSrc}
                                alt="icon"
                                className="w-6 h-6"
                              />
                              <span
                                className={`font-bold text-xl`}
                                style={{ color: textColor }}
                              >
                                {val}
                              </span>
                            </div>
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className="bg-[#353638] px-9 py-3 z-50 mt-[20px] lg:sticky w-full bottom-0">
            {/* <p className="font-extrabold text-[30px] sm:text-[25px] md:text-[30px]">
              Weekly Total sessions: {detailValue?.counts?.totalCount}
            </p> */}
            <div className="flex items-center flex-wrap gap-y-2 justify-between">
              <div className="flex items-center gap-x-1 flex-wrap gap-y-2 -ml-4">
                {currentCount === "bookedCount" && (
                  <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20 ">
                    <img
                      src={calendar}
                      alt="calendar"
                      className="sm:w-8 sm:h-8"
                    />
                    <span className="font-extrabold text-[30px] sm:text-[25px] md:text-[30px] text-white">
                      Weekly Booked Total sessions:{" "}
                      <span>{detailValue?.counts?.bookedCount}</span>
                    </span>
                  </button>
                )}
                {currentCount === "requestCount" && (
                  <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                    <img src={fingureup} alt="img" className=" sm:w-8 sm:h-8" />
                    <span className="font-extrabold text-[30px] sm:text-[25px] md:text-[30px] text-white">
                      Weekly Reservations Total sessions:{" "}
                      <span>{detailValue?.counts?.requestCount}</span>
                    </span>
                  </button>
                )}
                {currentCount === "completedCount" && (
                  <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                    <img src={medal} alt="medal" className=" sm:w-8 sm:h-8" />
                    <span className="font-extrabold text-[30px] sm:text-[25px] md:text-[30px] text-white">
                      Weekly Completed Total sessions:{" "}
                      <span>{detailValue?.counts?.completedCount}</span>
                    </span>
                  </button>
                )}
                {currentCount === "rejectedCount" && (
                  <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                    <img src={cancel} alt="cancel" className=" sm:w-8 sm:h-8" />
                    <span className="font-extrabold text-[30px] sm:text-[25px] md:text-[30px] text-white">
                      Weekly Cancel Total sessions:{" "}
                      <span>{detailValue?.counts?.rejectedCount}</span>
                    </span>
                  </button>
                )}
                {currentCount === "noShowCount" && (
                  <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                    <img src={noShow} alt="noshow" className="sm:w-8 sm:h-8" />
                    <span className="font-extrabold text-[30px] sm:text-[25px] md:text-[30px] text-white">
                      Weekly Noshow Total sessions:{" "}
                      <span>{detailValue?.counts?.noShowCount}</span>
                    </span>
                  </button>
                )}
              </div>
              <button
                onClick={() => {
                  setDetailedSchedule(false);
                  setEvenetDate("");
                }}
                className="w-full sm:w-[160px] h-[50px] sm:h-[60px] text-xl sm:text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color disabled:bg-[#ccff0094]"
              >
                {strings.CLOSE}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WeekModal;
