import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import medal from "../../../assests/icons/medal.svg";
import moment from "moment";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import BoardSublyApi from "../../../helper/BoardApi";
import { useSelector } from "react-redux";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import $ from "jquery";

function RegistrationForm({
  setOpenForm,
  setIsLoader,
  isEdit,
  setIsEdit,
  id,
  editedValue,
  setBoardId,
  type,
  suggestionDetails,
  classNameValue,
  head,
}) {
  const { token } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user.selectedGym);
  const [imagePreview, setImagePreview] = useState("");
  const [requestedData, setRequestedData] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [error, setError] = useState("");
  const [innerLoader, setInnerLoader] = useState(false);
  const [dropDownState, setDropDownState] = useState(false);
  const [suggestiontype, setSuggestionType] = useState({ text: "", value: "" });
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  // ============Function for showing fields prefield=============
  useEffect(() => {
    if (isEdit) {
      editedValue?.title && setTitle(editedValue?.title);
      editedValue?.content && setContent(editedValue?.content);
      editedValue?.file && setRequestedData(editedValue?.file);
      editedValue?.file && setImagePreview(editedValue?.file);
      editedValue?.type && setSuggestionType({ text: editedValue?.type });
    }
  }, []);

  function getVideoThumbnail(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    setError("");
    if (file?.type.match("video")) {
      if (file?.size / 1000000 <= 200) {
        var image = "";
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = async function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL();
            // var blockCrop = image.split(";");
            // // Get the content type of the image
            // // get the real base64 content of the file
            // var blockRealData = blockCrop[1].split(",")[1];
            // var binaryString = window.atob(blockRealData);
            // var bytes = new Uint8Array(binaryString.length);
            // for (var i = 0; i < binaryString.length; i++) {
            //   bytes[i] = binaryString.charCodeAt(i);
            // }
            // console.log(bytes.buffer);
            var success = image.length > 100000;
            if (success) {
              setInnerLoader(true);
              const requestData = new FormData();
              requestData.append("file", file);
              await BoardSublyApi.assestUpload(requestData, token)
                .then((response) => {
                  setInnerLoader(false);
                  if (response.statusCode == 200) {
                    let media = [...imagePreview];
                    const toDeploy = [...requestedData];
                    media.push({
                      url: response.data.compressed,
                      type: "video",
                      view: image,
                      original: response.data.original,
                    });
                    toDeploy.push({
                      url: response.data.compressed,
                      type: "video",
                      original: response.data.original,
                    });
                    setRequestedData(toDeploy);
                    setImagePreview(media);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              const base64String = image; // Base64 string
              const binaryString = atob(base64String.split(",")[1]); // Binary data string
              const blob = new Blob([binaryString], { type: "image/png" });
              // setPassThumbnail(blob);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setError("Video size should be less than 200 MB");
      }
    } else {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result) {
            setInnerLoader(true);
            const requestData = new FormData();
            requestData.append("file", file);
            await BoardSublyApi.assestUpload(requestData, token)
              .then((response) => {
                setInnerLoader(false);
                if (response.statusCode == 200) {
                  let media = [...imagePreview];
                  let toDeploy = [...requestedData];
                  media.push({
                    url: response.data.original,
                    type: "image",
                    view: result,
                  });
                  toDeploy.push({
                    url: response.data.compressed,
                    type: "image",
                    original: response.data.original,
                  });
                  setRequestedData(toDeploy);
                  setImagePreview(media);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setDropDownState(false);
    }
  });

  // ===============function for handling remove selected media=============
  async function removeProduct(url, index) {
    const images = [...imagePreview];
    let toDeploy = [...requestedData];
    images.splice(index, 1);
    setImagePreview(images);
    toDeploy.splice(index, 1);
    setRequestedData(toDeploy);
  }

  // ===========API for registration general board=============
  async function registrationHandle() {
    setIsLoader(true);
    const requestData = requestedData
      ? {
          title: title,
          content: content,
          files: requestedData,
          type: type,
          ...(suggestiontype.text && {
            suggestionType: suggestiontype.text,
          }),
        }
      : {
          title: title,
          content: content,
          type: type,
          ...(suggestiontype.text && {
            suggestionType: suggestiontype.text,
          }),
        };
    await BoardSublyApi.registerGeneral(requestData, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setOpenForm(false);
          setTitle("");
          setContent("");
          setImagePreview([]);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ==============API for edit general board=============
  async function editHandle() {
    setIsLoader(true);
    const requestData = requestedData
      ? {
          title: title,
          content: content,
          files: requestedData,
          type: type,
          ...(suggestiontype.text && {
            suggestionType: suggestiontype.text,
          }),
        }
      : {
          title: title,
          content: content,
          type: type,
          ...(suggestiontype.text && {
            suggestionType: suggestiontype.text,
          }),
        };
    await BoardSublyApi.editBoard(id, requestData, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setOpenForm(false);
          setTitle("");
          setContent("");
          setImagePreview([]);
          setBoardId({ id: "" });
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center items-center bg-[#000000c2] z-10"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-lg:w-[96%] max-h-[95%] p-6 max-[500px]:p-3 pb-14 overflow-auto z-10"}`}
      >
        <div className="w-[85%] max-lg:w-[95%] m-auto">
          <div className="border-b border-[#595959] pb-6 pt-2 m-auto w-[95%]">
            <h3 className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold text-white font-[archivo]">
              {head}
            </h3>
          </div>
          <div className="mt-7 mb-4">
            {!suggestionDetails ? (
              <label className="bg-[#414142] w-full h-[60px] px-3 rounded-[10px] flex items-center">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  placeholder={placeholder.ADD_TITLE}
                  className="bg-[#414142] w-full text-white text-lg font-bold placeholder:font-normal"
                />
              </label>
            ) : (
              <div className="flex gap-2 mt-7">
                <label className="bg-[#414142] w-full h-[60px] px-3 rounded-[10px] flex items-center">
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    placeholder={placeholder.ADD_TITLE}
                    className="bg-[#414142] w-full text-white text-lg font-bold placeholder:font-normal"
                  />
                </label>
                <div
                  onClick={() => {
                    setDropDownState(!dropDownState);
                  }}
                  className="bg-[#414142] cursor-pointer w-full px-3 rounded-[10px] flex items-center relative dropdownClass"
                >
                  <input
                    type="text"
                    value={suggestiontype.text}
                    placeholder={strings.CATEGORY}
                    className="bg-[#414142] cursor-pointer w-full h-full text-white text-lg font-bold placeholder:font-normal caret-transparent"
                  />
                  {dropDownState ? (
                    <Icon
                      icon="flowbite:caret-up-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                    />
                  ) : (
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                    />
                  )}
                  {dropDownState && (
                    <DropDownCategory setSuggestionType={setSuggestionType} />
                  )}
                </div>
              </div>
            )}
            <div className="flex items-center justify-between gap-2 my-4 flex-wrap">
              <div className="flex items-center gap-2.5">
                <Icon
                  icon="uil:user"
                  width="30"
                  height="30"
                  style={{ color: "#C5C5C5" }}
                />
                <div className="flex items-center gap-2">
                  <p className="mb-0 font-extrabold text-[24px]">{user.name}</p>
                  <img src={medal} alt="medal" />
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2 ">
                  <Icon
                    icon="lets-icons:date-today"
                    width="30"
                    height="30"
                    style={{ color: "#C5C5C5" }}
                  />
                  <p className="mb-0 text-[22px] font-extrabold border-r border-[#444444] pr-8 max-sm:pr-3">
                    {moment(new Date()).format("YYYY.MM.DD")}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <Icon
                    icon="ion:time-outline"
                    width="30"
                    height="30"
                    style={{ color: "#C5C5C5" }}
                  />
                  <p className="mb-0 text-[22px] font-extrabold">
                    {moment().format("h:mm")}
                    <span className="text-white text-lg font-bold">
                      {moment().format("A")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <textarea
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              placeholder={placeholder.WRITE_CONTENT}
              className="bg-[#414142] w-full h-[200px] px-3 py-3 rounded-[10px] text-white text-lg font-bold resize-none placeholder:font-normal"
            />
            <div
              className={`${
                imagePreview &&
                imagePreview.length > 0 &&
                imagePreview.length < 4
                  ? "justify-start"
                  : innerLoader
                  ? "justify-start"
                  : "justify-center"
              } flex items-center`}
            >
              <div
                className={`${
                  !imagePreview || imagePreview.length == 0
                    ? innerLoader
                      ? "justify-start"
                      : "justify-center"
                    : "justify-start"
                } ${"flex items-center my-8 gap-4 flex-wrap"}`}
              >
                <input
                  type="file"
                  accept="image/*, video/*"
                  className="hidden"
                  id="uploadMedia"
                  onChange={(e) => getVideoThumbnail(e)}
                />
                {(!imagePreview || imagePreview.length == 0) && (
                  <label
                    htmlFor="uploadMedia"
                    style={{ border: "2px solid #4B4B4B" }}
                    className={`${
                      innerLoader && "hidden"
                    } ${"flex items-center gap-3 rounded-[10px] py-2.5 px-6 cursor-pointer"}`}
                  >
                    <Icon
                      icon="humbleicons:upload"
                      width="48"
                      height="48"
                      style={{ color: "white" }}
                    />
                    <p className="mb-0 text-[24px] font-bold">
                      {strings.UPLOAD_MEDIA_FILES}
                    </p>
                  </label>
                )}
                {imagePreview &&
                  imagePreview?.map((item, index) => (
                    <div
                      className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] relative"
                      key={index}
                    >
                      <img
                        src={item.url || item.view}
                        className="w-full h-full object-cover rounded-[10px]"
                      />
                      <span
                        onClick={() => {
                          removeProduct(item.url, index);
                        }}
                        className="absolute top-2 w-[30px] h-[30px] right-2 flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                      >
                        <Icon
                          icon="iconamoon:close-bold"
                          width="25"
                          height="25"
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      </span>

                      {item?.type == "video" && (
                        <span
                          onClick={() => {
                            setVideoPreview(true);
                            setuploadedMedia(item);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                {((imagePreview?.length > 0 && imagePreview?.length !== 5) ||
                  innerLoader) && (
                  <label
                    htmlFor="uploadMedia"
                    className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] flex items-center justify-center relative"
                  >
                    {!innerLoader ? (
                      <Icon
                        icon="bitcoin-icons:plus-filled"
                        width="100"
                        height="100"
                        style={{ color: "white" }}
                      />
                    ) : (
                      <span className="absolute w-full h-full flex items-center justify-center">
                        <Icon
                          icon="eos-icons:bubble-loading"
                          width="60"
                          height="60"
                          style={{ color: "#CCFF00" }}
                          className="loader-icon "
                        />
                      </span>
                    )}
                  </label>
                )}
              </div>
            </div>
            {error && (
              <p className="mt-12 mb-6 text-[20px] font-extrabold text-[#FFB0B0] text-center">
                {error}
              </p>
            )}
            <div
              className={`${
                !error && "mt-24"
              } ${"flex items-center flex-wrap justify-center gap-3"}`}
            >
              <button
                onClick={() => {
                  setOpenForm(false);
                  setTitle("");
                  setContent("");
                  setImagePreview([]);
                  setError("");
                  setIsEdit && setIsEdit(false);
                  setBoardId({ id: "" });
                }}
                className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
              >
                {strings.CANCEL}
              </button>
              <button
                disabled={title && content && !innerLoader ? false : true}
                onClick={() => {
                  isEdit ? editHandle() : registrationHandle();
                }}
                className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {isEdit ? strings.UPDATE : strings.REGISTER}
              </button>
            </div>
          </div>
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default RegistrationForm;

export function FormMedia({
  setVideoPreview,
  videoPreview,
  uploadedMedia,
  classNameValue,
}) {
  return (
    <Modal
      className={`${classNameValue ? classNameValue : ""} ${"classForMargin"}`}
      show={videoPreview}
      onHide={() => {
        setVideoPreview(false);
      }}
    >
      <Modal.Body className="bg-box_bg p-0">
        <div className="w-full rounded-[5px]">
          <ReactPlayer url={uploadedMedia?.original} playing={true} controls />
        </div>
      </Modal.Body>
    </Modal>
  );
}
function DropDownCategory({ setSuggestionType }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-0 top-16 max-h-[200px] overflow-auto z-10 px-4">
      <div
        onClick={() => {
          setSuggestionType({ text: strings.CLEANING });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480] "
      >
        <span className="mb-0 text-lg font-bold pr-2">{strings.CLEANING}</span>
      </div>
      <div
        onClick={() => {
          setSuggestionType({
            text: strings.EQUIPMENT_AND_MACHINE_REPAIR,
          });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold pr-2">
          {strings.EQUIPMENT_AND_MACHINE_REPAIR}
        </span>
      </div>
      <div
        onClick={() => {
          setSuggestionType({
            text: strings.ETC,
          });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer "
      >
        <span className="mb-0 text-lg font-bold pr-2">{strings.ETC}</span>
      </div>
    </div>
  );
}
