import React from "react";
import { strings } from "../../utils/String";
import { Icon } from "@iconify-icon/react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserDetail } from "../../Store/slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ROLES } from "../../utils/CommonData";
import gymImg from "../../assests/images/image 65.png";

function Header({ text, currentTime }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { centerDetail } = useSelector((state) => state.user);

  // ===============Logout function===============
  function Logout() {
    dispatch(deleteUserDetail());
    navigate("/");
  }
  return (
    <section className="border-b border-[#444444]">
      <div className="flex justify-between items-center py-3 px-6 flex-wrap">
        <div className="flex items-center gap-3">
          <Icon
            icon="eva:menu-2-outline"
            width="50"
            height="50"
            style={{ color: "#CCFF00" }}
          />{" "}
          <p className="text-xl font-bold text-white mb-0">{text && text}</p>
        </div>
        <div className="flex items-center gap-3 flex-wrap">
          {currentTime && (
            <div className="flex items-center gap-3 flex-wrap">
              <div className="flex items-center gap-2">
                <Icon
                  icon="lets-icons:date-today"
                  width="30"
                  height="30"
                  style={{ color: "#CCFF00" }}
                />
                <p className="mb-0 text-[22px] font-extrabold border-r-2 border-[#444444] pr-4">
                  {moment(new Date()).format("YYYY.MM.DD")}
                </p>
              </div>
              <div className="flex items-center gap-2 pr-1">
                <p className="mb-0 text-[22px] font-extrabold">
                  {strings.CURRENT_TIME}:
                </p>
                <Icon
                  icon="ion:time-outline"
                  width="30"
                  height="30"
                  style={{ color: "#CCFF00" }}
                />
                <p className="mb-0 text-[22px] font-extrabold border-r-2 border-[#444444] pr-4">
                  {moment().format("h: mm: ss")}
                </p>
              </div>
            </div>
          )}
          <div className="flex items-center gap-2 pr-1">
            <img
              src={(centerDetail?.image || [])[0]?.original || gymImg}
              alt="profile"
              className="w-[50px] h-[50px] rounded-full object-cover"
            />
            <p className="mb-0 text-lg font-bold border-r-2 border-[#444444] pr-4 h-[30px] text-[#CCFF00]">
              {centerDetail?.centerName}{" "}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {!centerDetail?.userProfile ? (
              <Icon
                icon="healthicons:ui-user-profile"
                width="50"
                height="50"
                style={{ color: "#BEBEBE" }}
              />
            ) : (
              <img
                src={centerDetail?.userProfile}
                alt="profile"
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
            )}
            <p className="mb-0 text-lg font-bold text-white">
              {centerDetail?.name}
              <p className="mb-0 text-base font-bold text-[#BEBEBE]">{`(${
                ROLES[centerDetail?.role]
              })`}</p>
            </p>
          </div>
          <p
            onClick={() => {
              Logout();
            }}
            className="mb-0 text-base font-bold text-white flex items-center gap-1 cursor-pointer  border-l-2 border-[#444444] pl-4 h-[30px]"
          >
            <Icon
              icon="line-md:logout"
              width="25"
              height="25"
              style={{ color: "#BEBEBE" }}
            />{" "}
            {strings.LOGOUT}
          </p>
        </div>
      </div>
    </section>
  );
}

export default Header;
