import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { InputGroup } from "react-bootstrap";
import BoardSublyApi from "../../../helper/BoardApi";
import { useSelector } from "react-redux";
import won from "../../../assests/icons/won-currancy gray.svg";
import ReactDatePicker from "react-datepicker";
import ManagementSublyApi from "../../../helper/ManagementApi";
import {
  ETC_PRODUCT_NAME,
  MEMBER_PRODUCT_NAME,
  MEMBER_PT_PRODUCT_NAME,
  PRODUCT_TYPES,
  PRODUCT_TYPES_LIST,
  REGISTER_PRODUCT_NAME,
  REGISTER_PRODUCT_TYPES,
  SESSION_PRODUCT,
  SESSION_PRODUCT_NAME,
} from "../../../utils/CommonData";
import { FormMedia } from "../boardScreens/RegistrationForm";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import { formatPrice, parseFormattedPrice } from "../../../utils/commonUtils";
import moment from "moment";
import LockerListModule from "../memberSearch/memberInfoDetail/LockerListModule";
import $ from "jquery";
import { DatePicker } from "rsuite";
import { HiCalendar } from "react-icons/hi2";

function ProductRegister({
  setOpenRegister,
  setIsLoader,
  defaultValue,
  classNameValue,
  setDefaultValue,
  lockerList,
  setLockerList,
}) {
  const { token } = useSelector((state) => state.user);
  const [name, setName] = useState("");
  const [isError, setIsError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [innerLoader, setInnerLoader] = useState(false);
  const [requestedData, setRequestedData] = useState("");
  const [error, setError] = useState("");
  const [periodMonth, setperiodMonth] = useState({ month: "", day: "" });
  const [periodAddMonth, setperiodAddMonth] = useState({ month: "", day: "" });
  const [sessionCount, setSessionsCount] = useState({ count: "", addOn: "" });
  const [time, setTime] = useState("");
  const [openPeriod, setOpenPeriod] = useState({ month: false, day: false });
  const [openAddPeriod, setOpenAddPeriod] = useState({
    month: false,
    day: false,
  });
  const [timeDropdown, setTimeDropdown] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [productType, setProductType] = useState({
    text: "",
    key: null,
  });
  const [isAlways, setIsAlways] = useState(productType.key == 3 ? true : false);
  const [regularPrice, setRegularPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [finalPrice, setFinalPrice] = useState(
    regularPrice
      ? regularPrice && regularPrice - discountPrice > 0
        ? regularPrice - discountPrice
        : 0
      : ""
  );
  const [discription, setDiscription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [holdingCount, setHoldingCount] = useState("");
  const [holdingDays, setHoldingDays] = useState("");
  const [productNameModal, setProductNameModal] = useState(false);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [openCalender, setOpenCalender] = useState(false);
  const [startDuration, setStartDuration] = useState("");
  const [endDuration, setEndDuration] = useState("");
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [currentEndMonth, setCurrentEndMonth] = useState(
    moment().format("YYYY.MM.DD")
  );
  const [currentMonthDuration, setCurrentMonthDuration] = useState(
    moment().format("YYYY.MM.DD")
  );
  const [currentEndMonthDuration, setCurrentEndMonthDuration] = useState(
    moment().format("YYYY.MM.DD")
  );
  const [lockerValue, setLockerValue] = useState({
    name: "",
    password: "",
    id: "",
  });
  const [errorHandler, setErrorHandler] = useState({});
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");

  // ==============In edit case set default value============
  useEffect(() => {
    if (defaultValue) {
      setName(defaultValue?.productName);
      setDiscription(defaultValue?.productDescription);
      setImagePreview(defaultValue?.productImages);
      setRequestedData(defaultValue?.productImages);
      setRegularPrice(defaultValue?.regularPrice);
      setDiscountPrice(defaultValue?.discountPrice);
      if (SESSION_PRODUCT[defaultValue?.productTypeValue]) {
        setStartDuration(
          defaultValue?.isAlwaysAvailable == 1
            ? ""
            : new Date(defaultValue?.membershipStartDate)
        );
        setEndDuration(
          defaultValue?.isAlwaysAvailable == 1
            ? ""
            : new Date(defaultValue?.membershipEndDate)
        );
        setStartDate("");
        setEndDate("");
      } else {
        setStartDate(
          defaultValue?.isAlwaysAvailable == 1
            ? ""
            : new Date(defaultValue?.membershipStartDate)
        );
        setEndDate(
          defaultValue?.isAlwaysAvailable == 1
            ? ""
            : new Date(defaultValue?.membershipEndDate)
        );
        setStartDuration("");
        setEndDuration("");
      }
      setProductType({
        text: REGISTER_PRODUCT_TYPES[defaultValue?.productTypeValue],
        key: defaultValue?.productType,
      });
      setIsAlways(defaultValue?.isAlwaysAvailable == 1 ? true : false);
      setperiodMonth({
        month: defaultValue?.periodMonths,
        day: defaultValue?.periodDays,
      });
      setperiodAddMonth({
        month: defaultValue?.addonMonths,
        day: defaultValue?.addonDays,
      });
      setSessionsCount({
        count: defaultValue?.sessionCount || "",
        addOn: defaultValue?.sessionAddonCount || "",
      });
      setTime(defaultValue?.sessionTimeLimit);
      setHoldingCount(defaultValue?.membershipPauseCount || "");
      setHoldingDays(defaultValue?.maximumPauseDays || "");
    }
  }, []);

  // ==============FUNCTION TO GET THUMNAIL FO VIDEO=================
  function getVideoThumbnail(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    setError("");
    if (file?.type.match("video")) {
      if (file?.size / 1000000 <= 200) {
        var image = "";
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = async function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              setInnerLoader(true);
              const requestData = new FormData();
              requestData.append("file", file);
              await BoardSublyApi.assestUpload(requestData, token)
                .then((response) => {
                  setInnerLoader(false);
                  if (response.statusCode == 200) {
                    let media = [...imagePreview];
                    const toDeploy = [...requestedData];
                    media.push({
                      url: response.data.compressed,
                      type: "video",
                      view: image,
                      original: response.data.original,
                    });
                    toDeploy.push({
                      url: response.data.compressed,
                      type: "video",
                      original: response.data.original,
                    });
                    setRequestedData(toDeploy);
                    setImagePreview(media);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              const base64String = image; // Base64 string
              const binaryString = atob(base64String.split(",")[1]); // Binary data string
              const blob = new Blob([binaryString], { type: "image/png" });
              // setPassThumbnail(blob);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setError("Video size should be less than 200 MB");
      }
    } else {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result) {
            setInnerLoader(true);
            const requestData = new FormData();
            requestData.append("file", file);
            await BoardSublyApi.assestUpload(requestData, token)
              .then((response) => {
                setInnerLoader(false);
                if (response.statusCode == 200) {
                  let media = [...imagePreview];
                  let toDeploy = [...requestedData];
                  media.push({
                    url: response.data.compressed,
                    type: "image",
                    view: result,
                    original: response.data.original,
                  });
                  toDeploy.push({
                    url: response.data.compressed,
                    type: "image",
                    original: response.data.original,
                  });
                  setRequestedData(toDeploy);
                  setImagePreview(media);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  // ===============function for handling remove selected media=============
  async function removeProduct(url, index) {
    const images = [...imagePreview];
    let toDeploy = [...requestedData];
    images.splice(index, 1);
    setImagePreview(images);
    toDeploy.splice(index, 1);
    setRequestedData(toDeploy);
  }

  // ====function for disable value controlling through scrolling====
  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  // ================Function for register product API=====================
  async function registerHandle() {
    if (productType.key !== null) {
      if (
        name?.trim() &&
        discription?.trim() &&
        regularPrice &&
        (productType.key == 3
          ? !isAlways
            ? startDuration && endDuration
            : true
          : // &&
          //   periodMonth.day &&
          //   periodMonth.month
          productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11
          ? sessionCount.count &&
            // sessionCount.addOn &&
            time &&
            (!isAlways ? startDuration && endDuration : true)
          : // &&
          // holdingCount &&
          // holdingDays &&
          // periodAddMonth.day &&
          // periodAddMonth.month &&
          // periodMonth.day &&
          // periodMonth.month
          productType.key == 7
          ? holdingDays &&
            periodMonth.day &&
            periodMonth.month &&
            (!isAlways ? startDate && endDate : true) &&
            sessionCount.count &&
            time &&
            holdingCount &&
            holdingDays
          : holdingCount &&
            holdingDays &&
            // periodAddMonth.day &&
            // periodAddMonth.month &&
            // periodMonth.day &&
            // periodMonth.month &&
            (!isAlways ? startDate && endDate : true))
      ) {
        setIsLoader(true);
        const requestData = {
          productName: name?.trim(),
          productDescription: discription?.trim(),
          productType: productType.key, //  'membership','PT','golf','locker','uniform','gx',
          regularPrice: regularPrice,
          discountPrice: discountPrice || 0,
          sessionCount: sessionCount.count || 0,
          sessionAddonCount: sessionCount.addOn || 0,
          ...(!isAlways &&
            (startDate || endDate) && {
              membershipStartDate: startDate,
              membershipEndDate: endDate,
            }),
          ...(!isAlways &&
            (startDuration || endDuration) && {
              membershipStartDate: startDuration,
              membershipEndDate: endDuration,
            }),
          membershipPauseCount: holdingCount || 0,
          maximumPauseDays: holdingDays || 0,
          periodDays: periodMonth.day || 0,
          periodMonths: periodMonth.month || 0,
          isAlwaysAvailable: isAlways,
          productImages: requestedData || [],
          addonDays: periodAddMonth.day || 0,
          addonMonths: periodAddMonth.month || 0,
          sessionTimeLimit: time || 0,
        };
        await ManagementSublyApi.registerProduct(token, requestData)
          .then((response) => {
            setIsLoader(false);
            if (response.statusCode == 200) {
              setOpenRegister(false);
            } else {
              setOpenModal(true);
              seterrorMessage(response.data.message);
            }
          })
          .then((error) => {
            console.log(error);
          });
      } else {
        const errorObject = {
          ...(!name
            ? {
                name: "Please enter the value of name",
              }
            : { name: "" }),
          ...(!discription
            ? {
                discription: "Please enter the value of content",
              }
            : { discription: "" }),
          ...(!regularPrice
            ? {
                regularPrice: "Please enter the value of price",
              }
            : { regularPrice: "" }),
          ...(!periodMonth.day
            ? {
                periodDay: "Please select the period day",
              }
            : { periodDay: "" }),
          ...(!periodMonth.month
            ? {
                periodMonth: "Please select the period month",
              }
            : { periodMonth: "" }),
          // ...(productType.key !== 3 && !periodAddMonth.day
          //   ? {
          //       addDay: "Please select the add day",
          //     }
          //   : { addDay: "" }),
          // ...(productType.key !== 3 && !periodAddMonth.month
          //   ? {
          //       addMonth: "Please select the add month",
          //     }
          //   : { addMonth: "" }),
          ...(!(
            productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3
          ) ||
          ((productType.key == 7 || productType.key == 12) &&
            !isAlways &&
            !startDate)
            ? {
                startDate: "Please select the start date",
              }
            : { startDate: "" }),
          ...(!(
            productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3
          ) ||
          ((productType.key == 7 || productType.key == 12) &&
            !isAlways &&
            !endDate)
            ? {
                endDate: "Please select the end date",
              }
            : { endDate: "" }),
          ...((productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3) &&
          !isAlways &&
          !startDuration
            ? {
                startDuration: "Please select the start duration",
              }
            : { startDuration: "" }),
          ...((productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3) &&
          !isAlways &&
          !endDuration
            ? {
                endDuration: "Please select the end duration",
              }
            : { endDuration: "" }),
          ...((productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 7) &&
          productType.key !== 3 &&
          !sessionCount.count
            ? {
                sessionCount: "Please enter the value of add count",
              }
            : { sessionCount: "" }),
          // ...((productType.key == 1 ||
          //   productType.key == 9 ||
          //   productType.key == 10 ||
          //   productType.key == 11) &&
          // productType.key !== 3 &&
          // !sessionCount.addOn
          //   ? {
          //       sessionAddOn: "Please enter the value of add session count",
          //     }
          //   : { sessionAddOn: "" }),
          ...((productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 7) &&
          productType.key !== 3 &&
          !time
            ? {
                time: "Please select the duration time",
              }
            : { time: "" }),
          ...(productType.key !== 3 &&
          !holdingCount &&
          !(
            productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3
          )
            ? {
                holdingCount: "Please enter the value of holding count",
              }
            : { holdingCount: "" }),
          ...(productType.key !== 3 &&
          !holdingDays &&
          !(
            productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3
          )
            ? {
                holdingDays: "Please enter the value of holding days",
              }
            : { holdingDays: "" }),
        };
        setErrorHandler(errorObject);
      }
    } else {
      setIsError(true);
      setErrorHandler({
        ...errorHandler,
        type: "Please select the product type",
      });
    }
  }

  // ============Function for clearing the all the state while selecting product type============
  function initialState() {
    setName("");
    setDiscription("");
    setRegularPrice("");
    setDiscountPrice("");
    setSessionsCount({ count: "", addOn: "" });
    setTime("");
    setStartDate(null);
    setEndDate(null);
    setStartDuration(null);
    setEndDuration(null);
    setHoldingCount("");
    setHoldingDays("");
    setperiodMonth({ day: "", month: "" });
    setperiodAddMonth({ day: "", month: "" });
    setRequestedData([]);
    setErrorHandler({});
    setIsError(false);
    setIsAlways(false);
    setDefaultValue(null);
  }

  // ================Function for register product API=====================

  async function editProductHandle() {
    if (productType.key !== null) {
      if (
        name?.trim() &&
        discription?.trim() &&
        regularPrice &&
        (productType.key == 3
          ? !isAlways
            ? startDuration && endDuration
            : true
          : // &&
          //   periodMonth.day &&
          //   periodMonth.month
          productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11
          ? sessionCount.count &&
            // sessionCount.addOn &&
            time &&
            (!isAlways ? startDuration && endDuration : true) // && // holdingCount &&
          : // holdingDays &&
          // periodAddMonth.day &&
          // periodAddMonth.month &&
          // periodMonth.day &&
          // periodMonth.month
          productType.key == 7
          ? holdingDays &&
            periodMonth.day &&
            periodMonth.month &&
            (!isAlways ? startDate && endDate : true) &&
            sessionCount.count &&
            time &&
            holdingCount &&
            holdingDays
          : holdingCount &&
            holdingDays &&
            // periodAddMonth.day &&
            // periodAddMonth.month &&
            // periodMonth.day &&
            // periodMonth.month &&
            (!isAlways ? startDate && endDate : true))
      ) {
        setIsLoader(true);
        const requestData = {
          productName: name?.trim(),
          productDescription: discription?.trim(),
          productType: productType.key, //  'membership','PT','golf','locker','uniform','gx',
          regularPrice: regularPrice,
          discountPrice: discountPrice,
          sessionCount: sessionCount.count || 0,
          sessionAddonCount: sessionCount.addOn || 0,
          ...(!isAlways &&
            (startDate || endDate) && {
              membershipStartDate: startDate,
              membershipEndDate: endDate,
            }),
          ...(!isAlways &&
            (startDuration || endDuration) && {
              membershipStartDate: startDuration,
              membershipEndDate: endDuration,
            }),
          membershipPauseCount: holdingCount || 0,
          maximumPauseDays: holdingDays || 0,
          periodDays: periodMonth.day || 0,
          periodMonths: periodMonth.month || 0,
          isAlwaysAvailable: isAlways,
          productImages: requestedData || [],
          addonDays: periodAddMonth.day || 0,
          addonMonths: periodAddMonth.month || 0,
          sessionTimeLimit: time || 0,
        };
        await ManagementSublyApi.editProduct(
          token,
          requestData,
          defaultValue?.id
        )
          .then((response) => {
            setIsLoader(false);
            if (response.statusCode == 200) {
              setOpenRegister(false);
            } else {
              setOpenModal(true);
              seterrorMessage(response.data.message);
            }
          })
          .then((error) => {
            console.log(error);
          });
      } else {
        const errorObject = {
          ...(!name
            ? {
                name: "Please enter the value of name",
              }
            : { name: "" }),
          ...(!discription
            ? {
                discription: "Please enter the value of content",
              }
            : { discription: "" }),
          ...(!regularPrice
            ? {
                regularPrice: "Please enter the value of price",
              }
            : { regularPrice: "" }),
          ...(!periodMonth.day
            ? {
                periodDay: "Please select the period day",
              }
            : { periodDay: "" }),
          ...(!periodMonth.month
            ? {
                periodMonth: "Please select the period month",
              }
            : { periodMonth: "" }),
          // ...(productType.key !== 3 && !periodAddMonth.day
          //   ? {
          //       addDay: "Please select the add day",
          //     }
          //   : { addDay: "" }),
          // ...(productType.key !== 3 && !periodAddMonth.month
          //   ? {
          //       addMonth: "Please select the add month",
          //     }
          //   : { addMonth: "" }),
          ...(!(
            productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3
          ) ||
          ((productType.key == 7 || productType.key == 12) &&
            !isAlways &&
            !startDate)
            ? {
                startDate: "Please select the start date",
              }
            : { startDate: "" }),
          ...(!(
            productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3
          ) ||
          ((productType.key == 7 || productType.key == 12) &&
            !isAlways &&
            !endDate)
            ? {
                endDate: "Please select the end date",
              }
            : { endDate: "" }),
          ...((productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3) &&
          !isAlways &&
          !startDuration
            ? {
                startDuration: "Please select the start duration",
              }
            : { startDuration: "" }),
          ...((productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3) &&
          !isAlways &&
          !endDuration
            ? {
                endDuration: "Please select the end duration",
              }
            : { endDuration: "" }),
          ...((productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 7) &&
          productType.key !== 3 &&
          !sessionCount.count
            ? {
                sessionCount: "Please enter the value of add count",
              }
            : { sessionCount: "" }),
          // ...((productType.key == 1 ||
          //   productType.key == 9 ||
          //   productType.key == 10 ||
          //   productType.key == 11) &&
          // productType.key !== 3 &&
          // !sessionCount.addOn
          //   ? {
          //       sessionAddOn: "Please enter the value of add session",
          //     }
          //   : { sessionAddOn: "" }),
          ...((productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 7) &&
          productType.key !== 3 &&
          !time
            ? {
                time: "Please select the duration time",
              }
            : { time: "" }),
          ...(productType.key !== 3 &&
          !holdingCount &&
          !(
            productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3
          )
            ? {
                holdingCount: "Please enter the value of holding count",
              }
            : { holdingCount: "" }),
          ...(productType.key !== 3 &&
          !holdingDays &&
          !(
            productType.key == 1 ||
            productType.key == 9 ||
            productType.key == 10 ||
            productType.key == 11 ||
            productType.key == 3
          )
            ? {
                holdingDays: "Please enter the value of holding days",
              }
            : { holdingDays: "" }),
        };
        setErrorHandler(errorObject);
      }
    } else {
      setIsError(true);
      setErrorHandler({
        ...errorHandler,
        type: "Please select the product type",
      });
    }
  }
  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonth).getMonth();
    return condition;
  };
  const filterEndMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentEndMonth).getMonth();
    return condition;
  };
  const filterMonthDatesDuration = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonthDuration).getMonth();
    return condition;
  };
  const filterEndMonthDatesDuration = (date) => {
    const condition =
      new Date(date).getMonth() ===
      new Date(currentEndMonthDuration).getMonth();
    return condition;
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setProductModal(false);
      setOpenPeriod({ month: false, day: false });
      setOpenAddPeriod({ month: false, day: false });
      setTimeDropdown(false);
    }
  });

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : datePickerCheck == 3
        ? document.getElementById("datepicker-3-grid-label")
        : datePickerCheck == 4
        ? document.getElementById("datepicker-4-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
      <div
        className={` ${classNameValue} ${"bg-[#353638] rounded-[10px] w-[70%] max-lg:w-[90%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[80%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {!defaultValue
                ? strings.REGISTER_PRODUCT
                : strings.EDIT_PRODUCT_INFORMATION}
            </p>
          </div>
          <div className="pt-8 pb-3 w-[95%] m-auto">
            <div className="flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap">
              <div
                htmlFor="type"
                className={`
                ${" w-[60%] max-lg:w-full mb-2.5"}`}
              >
                <span
                  className={`${
                    isError && productType.key == null
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"text-base font-normal ml-2"}`}
                >
                  {strings.TYPE}*
                </span>
                <div
                  onClick={() => {
                    setProductModal(!productModal);
                    setProductNameModal(false);
                    setOpenPeriod({
                      month: false,
                      day: false,
                    });
                    setOpenAddPeriod({ month: false, day: false });
                    setTimeDropdown(false);
                  }}
                  className={`${
                    isError && productType.key == null ? "errorClass" : ""
                  } ${"flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass"}`}
                >
                  <input
                    type="text"
                    autoComplete="off"
                    className="text-white w-full bg-[#414142] font-bold placeholder:font-medium cursor-pointer caret-transparent"
                    id="type"
                    value={productType.text}
                    placeholder={placeholder.SELECT_PRODUCT_TYPE}
                  />
                  {productModal ? (
                    <Icon
                      icon="flowbite:caret-up-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                    />
                  ) : (
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                    />
                  )}
                  {productModal && (
                    <ProductType
                      setProductType={setProductType}
                      setName={setName}
                      setIsAlways={setIsAlways}
                      initialState={initialState}
                      classValue={productModal ? "dropdownClass" : "hideNotify"}
                    />
                  )}
                </div>
                {productType.key == null && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.type}
                  </p>
                )}
              </div>
              <div
                htmlFor="nameId"
                className={`${
                  productType.key || productType.key == 0 ? "" : "opacity-[0.3]"
                } ${"w-full mb-2.5"}`}
              >
                <span
                  className={`${
                    errorHandler.name && !name
                      ? "text-[#F87171]"
                      : "text-[#fff]"
                  } ${"text-base font-normal ml-2"}`}
                >
                  {strings.TITLE}*
                </span>
                <div
                  className={`${
                    errorHandler.name && !name ? "errorClass" : ""
                  } ${"flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative"}`}
                >
                  <input
                    type="text"
                    autoComplete="off"
                    className={`${"text-white font-bold placeholder:font-medium w-full bg-[#414142] "}`}
                    id="nameId"
                    value={name}
                    disabled={
                      productType.key == 0 || productType.key ? false : true
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setName(value);
                    }}
                    placeholder={placeholder.PRODUCT_NAME}
                  />
                </div>
                {!name && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.name}
                  </p>
                )}
              </div>
            </div>
            <div>
              <p className="mb-0 text-base font-normal text-[#fff] ">
                {strings.PRODUCT_SALE}
              </p>
              <div className="flex items-center gap-3">
                <div className="flex items-center ">
                  <InputGroup className="checkboxClass paddingManage">
                    <InputGroup.Checkbox
                      className="w-[31px] h-[31px] bg-[#CECECE80]"
                      checked={isAlways}
                      onChange={() => {
                        setIsAlways(true);
                        setStartDate("");
                        setEndDate("");
                        setStartDuration("");
                        setEndDuration("");
                      }}
                    />
                  </InputGroup>
                  <p className="mb-0 text-base font-bold">
                    {strings.ALWAYS_AVAILABLE}
                  </p>
                </div>
                <div className="flex items-center ">
                  <InputGroup className="checkboxClass paddingManage">
                    <InputGroup.Checkbox
                      className="w-[31px] h-[31px] bg-[#CECECE80]"
                      checked={!isAlways}
                      onChange={() => {
                        setIsAlways(false);
                      }}
                    />
                  </InputGroup>
                  <p className="mb-0 text-base font-bold">
                    {strings.SELECT_PERIODL}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`
                ${productType.text ? "" : ""}
               ${"flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap justify-between"}`}
            >
              <div className="mt-1.5 w-full">
                <p
                  className={`${
                    (errorHandler.periodMonth || errorHandler.periodDay) &&
                    (!periodMonth.day || !periodMonth.month)
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"mb-0 ml-2 text-base font-normal"}`}
                >
                  {strings.PERIOD}*
                </p>
                <div
                  className={`${"flex items-center gap-x-3 gap-y-3 max-lg:flex-wrap w-full"}`}
                >
                  <div className="w-full">
                    {" "}
                    <div
                      onClick={() => {
                        productType.text &&
                          // productType.key !== 3 &&
                          setOpenPeriod({
                            month: !openPeriod.month,
                            day: false,
                          });
                        setProductModal(false);
                        setProductNameModal(false);
                        setOpenAddPeriod({ month: false, day: false });
                        setTimeDropdown(false);
                      }}
                      className={`${
                        errorHandler.periodMonth && !periodMonth.month
                          ? "errorClass"
                          : ""
                      }  ${"flex items-center gap-2 w-full justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass"}`}
                    >
                      <input
                        type="text"
                        className="text-white w-[50px] font-bold placeholder:font-medium bg-[#414142]"
                        id="nameId"
                        disabled
                        value={periodMonth.month ? `${periodMonth.month}M` : ""}
                        placeholder={strings.MONTH}
                      />
                      {openPeriod.month ? (
                        <Icon
                          icon="flowbite:caret-up-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      ) : (
                        <Icon
                          icon="flowbite:caret-down-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      )}
                      {openPeriod.month && (
                        <PeriodTime
                          period={true}
                          setperiodMonth={setperiodMonth}
                          periodMonth={periodMonth}
                          classValue={
                            openPeriod.month ? "dropdownClass" : "hideNotify"
                          }
                        />
                      )}
                    </div>
                    {!periodMonth.month && (
                      <p className="mb-0 text-base text-[#F87171]">
                        {errorHandler?.periodMonth}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    {" "}
                    <div
                      onClick={() => {
                        productType.text &&
                          // productType.key !== 3 &&
                          setOpenPeriod({
                            month: false,
                            day: !openPeriod.day,
                          });
                        setProductModal(false);
                        setProductNameModal(false);
                        setOpenAddPeriod({ month: false, day: false });
                        setTimeDropdown(false);
                      }}
                      className={`${
                        errorHandler.periodDay && !periodMonth.day
                          ? "errorClass"
                          : ""
                      }  ${"flex items-center gap-2 w-full justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass"}`}
                    >
                      <input
                        type="text"
                        className="text-white w-[50px] font-bold placeholder:font-medium bg-[#414142]"
                        id="nameId"
                        disabled
                        value={periodMonth.day ? `${periodMonth.day}d` : ""}
                        placeholder={strings.DAYS}
                      />
                      {openPeriod.day ? (
                        <Icon
                          icon="flowbite:caret-up-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      ) : (
                        <Icon
                          icon="flowbite:caret-down-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      )}
                      {openPeriod.day && (
                        <PeriodTime
                          setperiodMonth={setperiodMonth}
                          periodMonth={periodMonth}
                          classValue={
                            openPeriod.day ? "dropdownClass" : "hideNotify"
                          }
                        />
                      )}
                    </div>
                    {!periodMonth.day && (
                      <p className="mb-0 text-base text-[#F87171]">
                        {errorHandler?.periodDay}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-1.5 w-full">
                <p
                  className={`${
                    errorHandler.addMonth &&
                    (!periodAddMonth.month || !periodAddMonth.day)
                      ? "text-[#F87171]"
                      : "text-white"
                  }  ${"mb-0 ml-2 text-base font-normal "}`}
                >
                  {strings.ADD}
                </p>
                <div className="flex items-center gap-x-3 gap-y-3 max-lg:flex-wrap w-full">
                  <div className="w-full">
                    {" "}
                    <div
                      onClick={() => {
                        productType.text &&
                          // productType.key !== 3 &&
                          setOpenAddPeriod({
                            month: !openAddPeriod.month,
                            day: false,
                          });
                        setOpenPeriod({
                          month: false,
                          day: false,
                        });
                        setProductModal(false);
                        setProductNameModal(false);
                        //  setOpenAddPeriod({ month: false, day: false });
                        setTimeDropdown(false);
                      }}
                      className={`${
                        errorHandler.addMonth && !periodAddMonth.month
                          ? "errorClass"
                          : ""
                      }  ${"flex items-center gap-2 w-full justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass"}`}
                    >
                      <input
                        type="text"
                        className="text-white w-[50px] font-bold placeholder:font-medium bg-[#414142]"
                        id="nameId"
                        disabled
                        value={
                          periodAddMonth.month
                            ? `+${periodAddMonth.month}M`
                            : ""
                        }
                        placeholder={strings.MONTH}
                      />
                      {openAddPeriod.month ? (
                        <Icon
                          icon="flowbite:caret-up-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      ) : (
                        <Icon
                          icon="flowbite:caret-down-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      )}
                      {openAddPeriod.month && (
                        <AddOnPeriodTime
                          period={true}
                          periodAddMonth={periodAddMonth}
                          setperiodAddMonth={setperiodAddMonth}
                          classValue={
                            openAddPeriod.month ? "dropdownClass" : "hideNotify"
                          }
                        />
                      )}
                    </div>
                    {!periodAddMonth.month && (
                      <p className="mb-0 text-base text-[#F87171]">
                        {errorHandler?.addMonth}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    {" "}
                    <div
                      onClick={() => {
                        productType.text &&
                          // productType.key !== 3 &&
                          setOpenAddPeriod({
                            month: false,
                            day: !openAddPeriod.day,
                          });
                        setOpenPeriod({
                          month: false,
                          day: false,
                        });
                        setProductModal(false);
                        setProductNameModal(false);
                        //  setOpenAddPeriod({ month: false, day: false });
                        setTimeDropdown(false);
                      }}
                      className={`${
                        errorHandler.addDay && !periodAddMonth.day
                          ? "errorClass"
                          : ""
                      }  ${"flex items-center gap-2 w-full justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass"}`}
                    >
                      <input
                        type="text"
                        className="text-white w-[50px] bg-[#414142] font-bold placeholder:font-medium"
                        id="nameId"
                        disabled
                        value={
                          periodAddMonth.day ? `+${periodAddMonth.day}d` : ""
                        }
                        placeholder={strings.DAYS}
                      />
                      {openAddPeriod.day ? (
                        <Icon
                          icon="flowbite:caret-up-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      ) : (
                        <Icon
                          icon="flowbite:caret-down-solid"
                          width="20"
                          height="20"
                          style={{ color: "#BEBEBE" }}
                        />
                      )}
                      {openAddPeriod.day && (
                        <AddOnPeriodTime
                          periodAddMonth={periodAddMonth}
                          setperiodAddMonth={setperiodAddMonth}
                          classValue={
                            openAddPeriod.day ? "dropdownClass" : "hideNotify"
                          }
                        />
                      )}{" "}
                    </div>
                    {!periodAddMonth.day && (
                      <p className="mb-0 text-base text-[#F87171]">
                        {errorHandler?.addDay}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-1.5">
              <div className="flex items-center gap-x-3 gap-y-3 max-lg:flex-wrap">
                <div
                  className={`${
                    (productType.text &&
                      (productType.key == 1 ||
                        productType.key == 9 ||
                        productType.key == 10 ||
                        productType.key == 11 ||
                        productType.key == 3)) ||
                    isAlways
                      ? "opacity-[0.3]"
                      : ""
                  } ${"w-full"}`}
                >
                  <p
                    className={`${
                      (errorHandler.startDate || errorHandler.endDate) &&
                      (!startDate || !endDate) &&
                      !isAlways
                        ? "text-[#F87171]"
                        : "text-white"
                    } ${"mb-0 ml-2 text-base font-normal"}`}
                  >
                    {strings.DATE}
                  </p>
                  <div
                    className={`${
                      errorHandler.startDate && !startDate && !isAlways
                        ? "errorClass"
                        : ""
                    } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 newDateInputClass"}`}
                  >
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-1"
                      placeholder={strings.START_DATE_SMALL}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setCurrentMonth(
                          startDate ? new Date(startDate) : new Date()
                        );
                        setIsCalenderOpen(true);
                        setDatePickerCheck(1);
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          startDate ? new Date(startDate) : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < maxDate;
                      }}
                      disabled={
                        (productType.text &&
                          (productType.key == 1 ||
                            productType.key == 9 ||
                            productType.key == 10 ||
                            productType.key == 11 ||
                            productType.key == 3)) ||
                        isAlways
                          ? true
                          : false
                      }
                    />
                  </div>
                  {!startDate && !isAlways && (
                    <p className="mb-0 text-base text-[#F87171]">
                      {errorHandler?.startDate}
                    </p>
                  )}
                </div>{" "}
                <div
                  className={`${
                    (productType.text &&
                      (productType.key == 1 ||
                        productType.key == 9 ||
                        productType.key == 10 ||
                        productType.key == 11 ||
                        productType.key == 3)) ||
                    isAlways
                      ? "opacity-[0.3]"
                      : ""
                  } ${"w-full lg:mt-6"}`}
                >
                  <div
                    className={`${
                      errorHandler.endDate && !endDate && !isAlways
                        ? "errorClass"
                        : ""
                    } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 newDateInputClass"}`}
                  >
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-2"
                      placeholder={strings.END_DATE}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setIsCalenderOpen(true);
                        setCurrentMonth(
                          endDate ? new Date(endDate) : new Date()
                        );
                        setDatePickerCheck(2);
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          endDate ? new Date(endDate) : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const minDate = new Date(startDate);
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < minDate || date < maxDate;
                      }}
                      disabled={
                        (productType.text &&
                          (productType.key == 1 ||
                            productType.key == 9 ||
                            productType.key == 10 ||
                            productType.key == 11 ||
                            productType.key == 3)) ||
                        isAlways
                          ? true
                          : false
                      }
                    />
                  </div>
                  {!endDate && !isAlways && (
                    <p className="mb-0 text-base text-[#F87171]">
                      {errorHandler?.endDate}
                    </p>
                  )}
                </div>
                <div
                  className={`${
                    productType.text && productType.key == 3
                      ? "opacity-[0.3]"
                      : ""
                  } ${"w-full"}`}
                >
                  <p
                    className={`${
                      errorHandler.holdingCount && !holdingCount
                        ? "text-[#F87171]"
                        : "text-white"
                    } ${"mb-0 ml-2 text-base font-normal "}`}
                  >
                    {strings.HOLDING_COUNT}
                  </p>
                  <div
                    className={`${
                      errorHandler.holdingCount && !holdingCount
                        ? "errorClass"
                        : ""
                    } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass"}`}
                  >
                    <input
                      type="number"
                      className="text-white w-full bg-[#414142] numberField font-bold placeholder:font-medium"
                      id="nameId"
                      autoComplete="off"
                      value={holdingCount}
                      disabled={
                        productType.key == 3 || productType.key == null
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setHoldingCount(e.target.value);
                      }}
                      placeholder={strings.COUNT}
                    />
                  </div>
                  {!holdingCount && (
                    <p className="mb-0 text-base text-[#F87171]">
                      {errorHandler?.holdingCount}
                    </p>
                  )}
                </div>{" "}
                <div
                  className={`${
                    productType.text && productType.key == 3
                      ? "opacity-[0.3]"
                      : ""
                  } ${"w-full"}`}
                >
                  <p
                    className={`${
                      errorHandler.holdingDays && !holdingDays
                        ? "text-[#F87171]"
                        : "text-white"
                    } ${"mb-0 ml-2 text-base font-normal"}`}
                  >
                    {strings.HOLDING_DAYS}
                  </p>
                  <div
                    className={`${
                      errorHandler.holdingDays && !holdingDays
                        ? "errorClass"
                        : ""
                    } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass"}`}
                  >
                    <input
                      type="number"
                      className="text-white w-full bg-[#414142] numberField font-bold placeholder:font-medium"
                      id="nameId"
                      autoComplete="off"
                      value={holdingDays}
                      disabled={
                        (productType.text && productType.key == 3) ||
                        productType.key == null
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setHoldingDays(e.target.value);
                      }}
                      placeholder={strings.DAYS}
                    />
                  </div>
                  {!holdingDays && (
                    <p className="mb-0 text-base text-[#F87171]">
                      {errorHandler?.holdingDays}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${
                productType.text &&
                productType.key !== 1 &&
                productType.key !== 9 &&
                productType.key !== 10 &&
                productType.key !== 11 &&
                productType.text &&
                productType.key !== 7
                  ? "opacity-[0.3]"
                  : ""
              } ${"my-1.5 flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap"}`}
            >
              <div className="w-full">
                <p
                  className={`${
                    errorHandler.sessionCount && !sessionCount.count
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"mb-0 ml-2 text-base font-normal"}`}
                >
                  {strings.SESSION_COUNT_PRODUCT}
                </p>
                <div
                  className={`${
                    errorHandler.sessionCount && !sessionCount.count
                      ? "errorClass"
                      : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass"}`}
                >
                  <input
                    type="number"
                    className="text-white w-full bg-[#414142] numberField font-bold placeholder:font-medium"
                    id="nameId"
                    autoComplete="off"
                    disabled={
                      (productType.text &&
                        productType.key !== 1 &&
                        productType.key !== 9 &&
                        productType.key !== 10 &&
                        productType.key !== 11 &&
                        productType.text &&
                        productType.key !== 7) ||
                      productType.key == null
                        ? true
                        : false
                    }
                    value={sessionCount.count}
                    onChange={(e) => {
                      setSessionsCount({
                        count: e.target.value,
                        addOn: sessionCount.addOn,
                      });
                    }}
                    placeholder={strings.SESSION_COUNT_PRODUCT}
                  />
                </div>
                {!sessionCount.count && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.sessionCount}
                  </p>
                )}
              </div>
              <div className="w-full">
                <p
                  className={`${
                    errorHandler.sessionAddOn && !sessionCount.addOn
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"mb-0 ml-2 text-base font-normal "}`}
                >
                  {strings.ADD_SESSION}
                </p>
                <div
                  className={`${
                    errorHandler.sessionAddOn && !sessionCount.addOn
                      ? "errorClass"
                      : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass"}`}
                >
                  <input
                    type="number"
                    className="text-white w-full bg-[#414142] numberField font-bold placeholder:font-medium"
                    id="nameId"
                    autoComplete="off"
                    disabled={
                      (productType.text &&
                        productType.key !== 1 &&
                        productType.key !== 9 &&
                        productType.key !== 10 &&
                        productType.key !== 11 &&
                        productType.text &&
                        productType.key !== 7) ||
                      productType.key == null
                        ? true
                        : false
                    }
                    value={sessionCount.addOn}
                    onChange={(e) => {
                      setSessionsCount({
                        count: sessionCount.count,
                        addOn: e.target.value,
                      });
                    }}
                    placeholder={strings.ADD_SESSION_COUNT_PRODUCT}
                  />
                </div>
                {!sessionCount.addOn && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.sessionAddOn}
                  </p>
                )}
              </div>
              <div className="w-full">
                <p
                  className={` ${
                    errorHandler.time && !time ? "text-[#F87171]" : "text-white"
                  } ${"mb-0 ml-2 text-base font-normal"}`}
                >
                  {strings.DURATION_TIME}
                </p>
                <div
                  onClick={() => {
                    productType.text &&
                      (productType.key == 1 ||
                        productType.key == 9 ||
                        productType.key == 10 ||
                        productType.key == 11 ||
                        productType.key == 7) &&
                      setTimeDropdown(!timeDropdown);
                    setOpenAddPeriod({
                      month: false,
                      day: false,
                    });
                    setOpenPeriod({
                      month: false,
                      day: false,
                    });
                    setProductModal(false);
                    setProductNameModal(false);
                  }}
                  className={`${
                    errorHandler.time && !time ? "errorClass" : ""
                  } ${"flex items-center gap-2 cursor-pointer justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 relative dropdownClass"}`}
                >
                  <input
                    type="text"
                    className="text-white w-full bg-[#414142] caret-transparent cursor-pointer font-bold placeholder:font-medium"
                    id="nameId"
                    autoComplete="off"
                    disabled={
                      productType.text &&
                      productType.key !== 1 &&
                      productType.key !== 9 &&
                      productType.key !== 10 &&
                      productType.key !== 11 &&
                      productType.text &&
                      productType.key !== 7
                        ? true
                        : false
                    }
                    value={time ? `${time} Minutes` : ""}
                    placeholder={placeholder.DURATION_TIME}
                  />
                  {timeDropdown ? (
                    <Icon
                      icon="flowbite:caret-up-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                    />
                  ) : (
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                    />
                  )}
                  {timeDropdown && (
                    <TimeList
                      setTime={setTime}
                      classValue={timeDropdown ? "dropdownClass" : "hideNotify"}
                    />
                  )}
                </div>
                {!time && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.time}
                  </p>
                )}
              </div>
            </div>
            <div className="my-1.5">
              <div className="flex items-center gap-x-3 gap-y-3 max-lg:flex-wrap">
                <div
                  className={`${
                    (productType.text &&
                      productType.key !== 1 &&
                      productType.key !== 3 &&
                      productType.key !== 9 &&
                      productType.key !== 10 &&
                      productType.key !== 11) ||
                    productType.key == 7 ||
                    productType.key == 12 ||
                    isAlways
                      ? "opacity-[0.3]"
                      : ""
                  } ${"lg:w-[60%] w-full"}`}
                >
                  <p
                    className={`${
                      (errorHandler.startDuration ||
                        errorHandler.endDuration) &&
                      (!startDuration || !endDuration) &&
                      !isAlways
                        ? "text-[#F87171]"
                        : "text-white"
                    } ${"mb-0 ml-2 text-base font-normal"}`}
                  >
                    {strings.FIXED_PERIOD}
                  </p>
                  <div
                    className={`${
                      errorHandler.startDuration && !startDuration && !isAlways
                        ? "errorClass"
                        : ""
                    } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 newDateInputClass"}`}
                  >
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-3"
                      placeholder={strings.START_DATE_SMALL}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={startDuration}
                      onChange={(date) => setStartDuration(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setIsCalenderOpen(true);
                        setCurrentMonth(
                          startDuration ? new Date(startDuration) : new Date()
                        );
                        setDatePickerCheck(3);
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          startDuration ? new Date(startDuration) : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < maxDate;
                      }}
                      disabled={
                        (productType.text &&
                          productType.key !== 1 &&
                          productType.key !== 3 &&
                          productType.key !== 9 &&
                          productType.key !== 10 &&
                          productType.key !== 11) ||
                        productType.key == 7 ||
                        productType.key == 12 ||
                        isAlways
                          ? true
                          : false
                      }
                    />
                  </div>
                  {!startDuration && !isAlways && (
                    <p className="mb-0 text-base text-[#F87171]">
                      {errorHandler?.startDuration}
                    </p>
                  )}
                </div>{" "}
                <div
                  className={`${
                    (productType.text &&
                      productType.key !== 1 &&
                      productType.key !== 3 &&
                      productType.key !== 9 &&
                      productType.key !== 10 &&
                      productType.key !== 11) ||
                    productType.key == 7 ||
                    productType.key == 12 ||
                    isAlways
                      ? "opacity-[0.3]"
                      : ""
                  } ${"lg:w-[60%] w-full lg:mt-6"}`}
                >
                  <div
                    className={`${
                      errorHandler.endDuration && !endDuration && !isAlways
                        ? "errorClass"
                        : ""
                    } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 newDateInputClass"}`}
                  >
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-4"
                      placeholder={strings.END_DATE}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={endDuration}
                      onChange={(date) => setEndDuration(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setIsCalenderOpen(true);
                        setCurrentMonth(
                          endDuration ? new Date(endDuration) : new Date()
                        );
                        setDatePickerCheck(4);
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          endDuration ? new Date(endDuration) : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const minDate = new Date(startDuration);
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < minDate || date < maxDate;
                      }}
                      disabled={
                        (productType.text &&
                          productType.key !== 1 &&
                          productType.key !== 3 &&
                          productType.key !== 9 &&
                          productType.key !== 10 &&
                          productType.key !== 11) ||
                        productType.key == 7 ||
                        productType.key == 12 ||
                        isAlways
                          ? true
                          : false
                      }
                    />
                  </div>
                  {!endDuration && !isAlways && (
                    <p className="mb-0 text-base text-[#F87171]">
                      {errorHandler?.endDuration}
                    </p>
                  )}
                </div>
                <div
                  className={`${
                    productType.text && productType.key !== 3
                      ? "opacity-[0.3]"
                      : ""
                  } ${"w-full"}`}
                >
                  <p className="mb-0 ml-2 text-base font-normal text-[#fff] ">
                    {strings.LOCKER}
                  </p>
                  <div className="flex items-center gap-3 w-full">
                    <label
                      htmlFor="nameId"
                      className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-3 rounded-[10px] relative"
                    >
                      <span className="w-full border-r-2 border-[#505050] text-addProductText flex items-center gap-2 whitespace-nowrap	">
                        {strings.LOCKER_NO}
                        <input
                          type="text"
                          disabled
                          value={lockerValue.name}
                          className="bg-[#414142] text-white font-bold placeholder:font-medium w-full"
                          id="nameId"
                        />
                      </span>
                      <span className="w-full text-addProductText flex items-center gap-2 whitespace-nowrap	">
                        {strings.PASSWORD}
                        <input
                          type="text"
                          disabled
                          value={`${
                            lockerValue.password && `(${lockerValue.password})`
                          }`}
                          className="bg-[#414142] text-white font-bold placeholder:font-medium w-full"
                          id="nameId"
                        />
                      </span>
                    </label>
                    <span className="w-[45px] h-[45px] rounded-[10px] bg-[#414142] flex items-center justify-center">
                      <Icon
                        icon="ph:lockers-bold"
                        width="30"
                        height="30"
                        style={{ color: "white", cursor: "pointer" }}
                        onClick={() => {
                          if (productType.key === 3) {
                            setLockerList(true);
                          }
                        }}
                      />
                    </span>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="my-1.5 flex items-center gap-x-3 gap-y-1 max-lg:flex-wrap">
              <div className="w-full">
                <p
                  className={`${
                    errorHandler.regularPrice && !regularPrice
                      ? "text-[#F87171]"
                      : "text-[#D1D1D1]"
                  } ${"mb-0 ml-2 text-base font-normal "}`}
                >
                  {strings.PRICE}
                </p>
                <div
                  className={`${
                    errorHandler.regularPrice && !regularPrice
                      ? "errorClass"
                      : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass"}`}
                >
                  <img src={won} className="w-[25px] h-[25px]" />
                  <input
                    type="text"
                    autoComplete="off"
                    className="text-white w-full bg-[#414142] numberField font-bold placeholder:font-medium"
                    id="nameId"
                    disabled={productType.key == null ? true : false}
                    placeholder={"MSRP"}
                    value={formatPrice(regularPrice)}
                    onChange={(e) => {
                      setRegularPrice(parseFormattedPrice(e.target.value));
                    }}
                  />
                </div>
                {!regularPrice && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.regularPrice}
                  </p>
                )}
              </div>
              <div className="w-full">
                <p className="mb-0 ml-2 text-base font-normal text-[#fff] ">
                  {strings.DISCOUNT}
                </p>
                <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 numberFieldClass">
                  <img src={won} className="w-[25px] h-[25px]" />
                  <input
                    type="text"
                    autoComplete="off"
                    className="text-white w-full bg-[#414142] numberField font-bold placeholder:font-medium"
                    id="nameId"
                    disabled={productType.key == null ? true : false}
                    placeholder={strings.DISCOUNT}
                    value={formatPrice(discountPrice)}
                    onChange={(e) => {
                      const price =
                        +e.target.value > +regularPrice
                          ? regularPrice
                          : e.target.value;
                      setDiscountPrice(parseFormattedPrice(price));
                    }}
                  />
                </div>
              </div>
              <div className="w-full">
                <p className="mb-0 ml-2 text-base font-normal text-[#fff] ">
                  {strings.FINAL_PRICE}
                </p>
                <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3">
                  <img src={won} className="w-[25px] h-[25px]" />
                  <input
                    type="text"
                    autoComplete="off"
                    className="text-white w-full bg-[#414142] caret-transparent font-bold placeholder:font-medium"
                    id="nameId"
                    value={formatPrice(
                      finalPrice
                        ? finalPrice
                        : regularPrice && regularPrice - discountPrice > 0
                        ? regularPrice - discountPrice
                        : 0
                    )}
                    placeholder={strings.FINAL_PRICE}
                  />
                </div>
              </div>
            </div>
            <textarea
              placeholder={placeholder.WRITE_CONTENT}
              value={discription}
              disabled={productType.key == null ? true : false}
              onChange={(e) => {
                setDiscription(e.target.value);
              }}
              className={`${
                errorHandler.discription && !discription
                  ? "errorClass placeholder:text-[#F87171] mt-3"
                  : "placeholder:text-[#D1D1D1] my-3"
              } ${"bg-[#414142] w-full h-[150px] px-3 py-3 rounded-[10px] text-white text-lg resize-none font-bold placeholder:font-medium "}`}
            />
            {!discription && (
              <p className="mb-3 text-base text-[#F87171]">
                {errorHandler?.discription}
              </p>
            )}
            <div
              className={`${
                imagePreview &&
                imagePreview.length > 0 &&
                imagePreview.length < 4
                  ? "justify-start"
                  : innerLoader
                  ? "justify-start"
                  : "justify-center"
              } flex items-center`}
            >
              <div
                className={`${
                  !imagePreview || imagePreview.length == 0
                    ? innerLoader
                      ? "justify-start"
                      : "justify-center"
                    : "justify-start"
                } ${"flex items-center my-6 gap-4 flex-wrap"}`}
              >
                <input
                  type="file"
                  accept="image/*, video/*"
                  className="hidden"
                  id="uploadMedia"
                  onChange={(e) => getVideoThumbnail(e)}
                />
                {(!imagePreview || imagePreview.length == 0) && (
                  <label
                    htmlFor="uploadMedia"
                    style={{ border: "2px solid #4B4B4B" }}
                    className={`${
                      innerLoader && "hidden"
                    } ${"flex items-center gap-3 rounded-[10px] py-2.5 px-6 cursor-pointer"}`}
                  >
                    <Icon
                      icon="humbleicons:upload"
                      width="48"
                      height="48"
                      style={{ color: "white" }}
                    />
                    <p className="mb-0 text-[24px] font-bold">
                      {strings.UPLOAD_MEDIA_FILES}
                    </p>
                  </label>
                )}
                {imagePreview &&
                  imagePreview?.map((item, index) => (
                    <div
                      className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] relative"
                      key={index}
                    >
                      <img
                        src={item.url || item.view}
                        className="w-full h-full object-cover rounded-[10px]"
                      />
                      <span
                        onClick={() => {
                          removeProduct(item.url, index);
                        }}
                        className="absolute top-2 w-[30px] h-[30px] right-2 flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                      >
                        <Icon
                          icon="iconamoon:close-bold"
                          width="25"
                          height="25"
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      </span>

                      {item?.type == "video" && (
                        <span
                          onClick={() => {
                            setVideoPreview(true);
                            setuploadedMedia(item);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                {((imagePreview?.length > 0 && imagePreview?.length !== 5) ||
                  innerLoader) && (
                  <label
                    htmlFor="uploadMedia"
                    className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] flex items-center justify-center relative"
                  >
                    {!innerLoader ? (
                      <Icon
                        icon="bitcoin-icons:plus-filled"
                        width="100"
                        height="100"
                        style={{ color: "white" }}
                      />
                    ) : (
                      <span className="absolute w-full h-full flex items-center justify-center">
                        <Icon
                          icon="eos-icons:bubble-loading"
                          width="60"
                          height="60"
                          style={{ color: "#CCFF00" }}
                          className="loader-icon "
                        />
                      </span>
                    )}
                  </label>
                )}
              </div>
            </div>
            {error && (
              <p className="mt-12 mb-4 text-[20px] font-extrabold text-[#FFB0B0] text-center">
                {error}
              </p>
            )}
            <div
              className={` ${"flex-wrap flex items-center justify-center gap-3 mt-6"}`}
            >
              <button
                onClick={() => {
                  setOpenRegister(false);
                }}
                className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
              >
                {strings.CANCEL}
              </button>
              {defaultValue ? (
                <button
                  disabled={!isError && !innerLoader ? false : true}
                  onClick={() => {
                    editProductHandle();
                  }}
                  className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                >
                  {strings.UPDATE}
                </button>
              ) : (
                <button
                  disabled={!isError && !innerLoader ? false : true}
                  onClick={() => {
                    registerHandle();
                  }}
                  className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                >
                  {strings.REGISTER}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
      {lockerList && (
        <LockerListModule
          setLockerList={setLockerList}
          lockerValue={lockerValue}
          setLockerValue={setLockerValue}
          setIsLoader={setIsLoader}
          classNameValue={classNameValue}
        />
      )}
    </section>
  );
}

export default ProductRegister;

const periodMonthArry = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "23",
  "24",
];
const daysArray = [...Array(31).keys()].map((day) => day + 1);
const timeMint = [20, 30, 40, 50, 60];

// ==============PERIOD TIME AND ADD ON TIME ===============
function ProductType({
  setProductType,
  setName,
  setIsAlways,
  initialState,
  classValue,
}) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[280px] right-0 top-12 max-h-[250px] overflow-auto z-10 px-3"}`}
    >
      {PRODUCT_TYPES_LIST.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setProductType({ text: item, key: index });
            initialState();
            // if (index == 0 || index == 1 || index == 7 || index == 12) {
            //   setName("");
            // } else if (index == 3) {
            //   setName(item);
            //   setIsAlways(true);
            // } else {
            //   setName(item);
            // }
            // if (index == 3) {
            //   setIsAlways(true);
            // }
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
        >
          {/* <Icon icon="bi:dot" width="35" height="35" /> */}
          <span className="mb-0 text-lg font-bold">{item}</span>
        </div>
      ))}
    </div>
  );
}

// ==============PERIOD TIME AND ADD ON TIME ===============
function ProductName({ setName, productType, classValue }) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[310px] right-0 top-12 max-h-[250px] overflow-auto z-10 px-2"}`}
    >
      {(productType.key == 0
        ? MEMBER_PRODUCT_NAME
        : productType.key == 1
        ? SESSION_PRODUCT_NAME
        : productType.key == 7
        ? MEMBER_PT_PRODUCT_NAME
        : []
      ).map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setName(item);
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
        >
          {/* <Icon icon="bi:dot" width="35" height="35" /> */}
          <span className="mb-0 text-lg font-bold">{item}</span>
        </div>
      ))}
    </div>
  );
}

// ==============PERIOD TIME AND ADD ON TIME ===============
function PeriodTime({ period, setperiodMonth, periodMonth, classValue }) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[150px] right-0 top-[50px] max-h-[200px] overflow-auto z-10 px-3"}`}
    >
      <div></div>
      {period ? (
        <div>
          <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodMonth({ month: "", day: periodMonth.day });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p>
          {periodMonthArry.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setperiodMonth({ month: item, day: periodMonth.day });
              }}
              className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
            >
              {/* <Icon icon="bi:dot" width="25" height="25" /> */}
              <span className="mb-0 text-lg font-bold text-center">
                {item}M
              </span>
            </div>
          ))}
          {/* <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodMonth({ month: "", day: periodMonth.day });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p> */}
        </div>
      ) : (
        <div>
          <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodMonth({ month: periodMonth.month, day: "" });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p>
          {daysArray.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setperiodMonth({ month: periodMonth.month, day: item });
              }}
              className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
            >
              {/* <Icon icon="bi:dot" width="25" height="25" /> */}
              <span className="mb-0 text-lg font-bold">{item}</span>
            </div>
          ))}
          {/* <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodMonth({ month: periodMonth.month, day: "" });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p> */}
        </div>
      )}
    </div>
  );
}

// ==============PERIOD TIME AND ADD ON TIME ===============
function AddOnPeriodTime({
  period,
  periodAddMonth,
  setperiodAddMonth,
  classValue,
}) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[150px] right-0 top-[50px] max-h-[200px] overflow-auto z-10 px-3"}`}
    >
      {period ? (
        <div>
          <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodAddMonth({ month: "", day: periodAddMonth.day });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p>
          {periodMonthArry.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setperiodAddMonth({ month: item, day: periodAddMonth.day });
              }}
              className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
            >
              {/* <Icon icon="bi:dot" width="25" height="25" /> */}
              <span className="mb-0 text-lg font-bold">+{item}M</span>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodAddMonth({
                month: periodAddMonth.month,
                day: "",
              });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p>
          {daysArray.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setperiodAddMonth({ month: periodAddMonth.month, day: item });
              }}
              className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
            >
              {/* <Icon icon="bi:dot" width="25" height="25" /> */}

              <span className="mb-0 text-lg font-bold">+{item}</span>
            </div>
          ))}
          {/* <p
            className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
            onClick={() => {
              setperiodAddMonth({ month: periodAddMonth.month, day: "" });
            }}
          >
            --{strings.NOT_SELECTED}--
          </p> */}
        </div>
      )}
    </div>
  );
}

// ==============TIME LIST DROPDOWN===============
function TimeList({ setTime, classValue }) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[150px] right-0 top-[50px] max-h-[200px] overflow-auto z-10 px-2"}`}
    >
      <p
        className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
        onClick={() => {
          setTime("");
        }}
      >
        --{strings.NOT_SELECTED}--
      </p>
      {timeMint.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setTime(item);
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
        >
          {/* <Icon icon="bi:dot" width="25" height="25" /> */}
          <span className="mb-0 text-lg font-bold">{item} Minutes</span>
        </div>
      ))}
    </div>
  );
}
