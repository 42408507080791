import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import moment from "moment";
import ReactPaginate from "react-paginate";
import EventRegister from "./EventRegister";
import EventDetail from "./EventDetail";
import { useSelector } from "react-redux";
import ManagementSublyApi from "../../../helper/ManagementApi";
import Loader from "../../../utils/Loader";
import { EVENT_TYPES } from "../../../utils/CommonData";
import StatusChanged from "../../commonComponents/modalScreens/StatusChanged";
import { scrollToTopFun } from "../gymSettings/StaffSetting";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import { formatPrice } from "../../../utils/commonUtils";
import $ from "jquery";
import ChangeProductStatus from "../../commonComponents/modalScreens/ChangeProductStatus";

function EventScreen() {
  const { token } = useSelector((state) => state.user);
  const [productId, setProductId] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [typeDropdown, setTypeDropdown] = useState(false);
  const [timeDropdown, setTimeDropdown] = useState(false);
  const [eventList, setEventList] = useState("");
  const [eventType, setEventType] = useState({ text: "All", type: "" });
  const [statusType, setStatusType] = useState({ text: "All", type: "" });
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [productStatus, setProductStatus] = useState({
    action: "",
    productType: "",
    id: "",
  });
  const [statusModal, setStatusModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [changeStatusValue, setChangeEventStatus] = useState(false);
  const [itemValue, setItemValue] = useState("");
  const [changeValue, setChangeValue] = useState(3);

  // ===============API for fetching event list==============
  useEffect(() => {
    if (!openRegister && !openDetail && !statusModal) {
      getEventList("", null);
    }
  }, [openRegister, offset, openDetail, statusModal]);

  async function getEventList(key, status, page) {
    const keyValue = key !== null && key !== undefined ? key : eventType.type;
    const statusValue = status !== null ? status : statusType.type;
    const pageValue = page || offset;
    setIsLoader(true);
    await ManagementSublyApi.fetchEventList(
      token,
      keyValue,
      pageValue ? pageValue : offset,
      statusValue,
      searchValue
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setEventList(response.data?.events);
          setPage(response.data?.count / 20);
          page && setOffset(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =================API for update event status=================
  async function updateStatusHandle(id, action, type) {
    setIsLoader(true);
    const requestData = { isActive: action };
    await ManagementSublyApi.eventStatusUpdate(token, requestData, id).then(
      (response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setProductStatus({ action: action, productType: type, id: id });
          setStatusModal(true);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      }
    );
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setOpenDetail(false);
      setOpenRegister(false);
      setProductId("");
      setDefaultValue("");
      setTimeDropdown(false);
      setTypeDropdown(false);
    }
  });
  // // =======Condition for close form while click outside the form=========
  // $(document).mouseup(function (e) {
  //   if (
  //     $(e.target).closest(".closedNotify").length == 0 &&
  //     $(e.target).closest(".dropdownClass").length == 0
  //   ) {
  //     setTimeDropdown(false);
  //     setTypeDropdown(false);
  //   }
  // });

  // ==============Calling API for chnage status=============
  async function changeStatus() {
    setIsLoader(true);
    const requestData = {
      membershipEndDate: new Date(),
      isAlwaysAvailable: false,
    };
    await ManagementSublyApi.EditDetail(token, itemValue?.id, requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setChangeEventStatus(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .then((error) => {
        console.log(error);
      });
  }

  return (
    <section>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`${
              openRegister || openDetail
                ? "!overflow-hidden h-screen"
                : "!overflow-auto"
            } ${"p-0 relative "}`}
          >
            <Header text={strings.MANAGEMENT_EVENT} />
            <div className="mx-6">
              <div className="flex items-center justify-between gap-3 py-3 flex-wrap">
                <div className="flex items-center gap-3 flex-wrap max-sm:w-full">
                  <label
                    onClick={() => {
                      setTypeDropdown(!typeDropdown);
                      setTimeDropdown(false);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                      {eventType.text}{" "}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={typeDropdown ? 2 : 4}
                    />
                    {typeDropdown && (
                      <ProductFilter
                        setEventType={setEventType}
                        getEventList={getEventList}
                        setStatusType={setStatusType}
                      />
                    )}
                  </label>
                  <label
                    onClick={() => {
                      setTimeDropdown(!timeDropdown);
                      setTypeDropdown(false);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-4 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                      {statusType?.text}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={timeDropdown ? 2 : 4}
                    />
                    {timeDropdown && (
                      <StatusFilter
                        setStatusType={setStatusType}
                        getEventList={getEventList}
                        setEventType={setEventType}
                        eventType={eventType}
                      />
                    )}
                  </label>
                </div>
                <div className="flex items-center gap-3 max-sm:w-full">
                  <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                    <input
                      type="text"
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      placeholder="Keywords"
                      className="bg-transparent w-full text-white text-lg font-bold"
                    />
                  </label>
                  <button
                    onClick={() => {
                      setOffset(1);
                      getEventList(eventType.type, statusType.type);
                      // setEventType({ text: "All", type: "" });
                      // setStatusType({ text: "All", type: "" });
                    }}
                    className="hover:text-black font-[archivo] hover:bg-[#CCFF00] text-lg font-bold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-between gap-3 pb-3 flex-wrap">
                <p className="mb-0 text-2xl font-extrabold">Event</p>
                <button
                  onClick={() => {
                    setOpenRegister(true);
                    scrollToTopFun();
                  }}
                  className="text-black font-[archivo] bg-[#CCFF00] text-lg font-bold rounded-[30px] h-[50px] w-[120px]"
                >
                  {strings.REGISTER}
                </button>
              </div>
              {eventList?.length == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-4">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              {eventList?.length > 0 && (
                <div className="overflow-auto scrollingClass mb-3">
                  <div className="max-2xl:w-[1600px]">
                    <div className="flex justify-around items-center bg-[#353638] min-h-[60px] border-b border-[#444444]">
                      <div className="flex justify-around items-center w-full ">
                        <th
                          className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-3 py-1 w-[40%]  min-h-[60px] flex justify-around items-center"}`}
                        >
                          {placeholder.NAME}
                        </th>
                        <th
                          className={`${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[60px] flex justify-around items-center"}`}
                        >
                          {strings.FINAL_PRICE}
                        </th>
                        <th
                          className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%]  min-h-[60px] flex justify-around items-center "}`}
                        >
                          {strings.EVENT_TYPE}
                        </th>
                        <th
                          className={` ${"text-[18px] font-semibold font-[Archivo] text-white px-2 text-center py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
                        >
                          {strings.START_DATE_SMALL}
                        </th>
                        <th
                          className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
                        >
                          {strings.END_DATE}
                        </th>
                        <th
                          className={`${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
                        >
                          {strings.REGISTER_DATE}
                        </th>
                        <th
                          className={`${"border-l border-[#444444] !text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
                        >
                          {strings.PARTICIPANTS}
                        </th>
                      </div>
                      <th className="!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[22%] flex justify-center items-center border-x border-[#444444] min-h-[60px]">
                        {strings.STATUS}
                      </th>
                    </div>
                    <div className="border-b border-[#444444] overflow-auto max-h-[calc(100vh_-_390px)] ">
                      {eventList?.length > 0 &&
                        eventList?.map((item, index) => (
                          <div className="flex justify-around items-center">
                            <div
                              key={index}
                              onClick={() => {
                                setProductId(item.id);
                                setOpenDetail(true);
                                scrollToTopFun();
                              }}
                              className={`${
                                item.id == productId &&
                                "kakaoClass bg-[#353638]"
                              } ${"flex justify-around items-center cursor-pointer w-full"}`}
                            >
                              <th
                                className={` ${
                                  productId == item.id
                                    ? ""
                                    : "border-x border-[#444444] "
                                } ${"!text-start text-base  font-semibold font-[Archivo] text-white px-3 py-1 w-[40%] min-h-[40px] flex justify-start items-center"}`}
                              >
                                {/* <Icon
                                  icon="bi:dot"
                                  width="25"
                                  height="25"
                                  style={{ color: "#fff" }}
                                /> */}
                                <p className="mb-0 textOverflowClass">
                                  {item.eventName || "- -"}
                                </p>
                              </th>
                              <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[40px] flex justify-center items-center">
                                {formatPrice(item.finalPrice)}
                              </th>
                              <th
                                className={`${
                                  productId == item.id
                                    ? ""
                                    : "border-x border-[#444444] "
                                } ${"!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[40px] flex justify-center items-center "}`}
                              >
                                <p className="textOverflowClass mb-0">
                                  {EVENT_TYPES[item?.eventTypeValue]}
                                </p>
                              </th>
                              <th className="text-base font-semibold font-[Archivo] text-white px-2 text-start py-1 w-[25%] flex justify-center items-center min-h-[40px]">
                                {!item?.isAlwaysAvailable
                                  ? item.startDate
                                    ? moment(item?.startDate).format("YY.MM.DD")
                                    : "- -"
                                  : strings.ALWAYS_AVAILABLE}{" "}
                              </th>
                              <th
                                className={`${
                                  productId == item.id
                                    ? ""
                                    : "border-x border-[#444444] "
                                }${"!text-start text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-center items-center min-h-[40px]"}`}
                              >
                                {item.endDate
                                  ? moment(item?.endDate).format("YY.MM.DD")
                                  : "- -"}{" "}
                              </th>
                              <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-center items-center min-h-[40px]">
                                {item.regDate
                                  ? moment(item?.regDate).format("YY.MM.DD")
                                  : "- -"}{" "}
                              </th>
                              <th className="border-l border-[#444444] !text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-center items-center min-h-[40px]">
                                <p className="mb-0">
                                  {item?.purchasedParticipants || 0}/
                                  {item?.participantsCount || 0}
                                </p>
                              </th>
                            </div>
                            <th className="!text-start text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[21.8%] flex justify-center border-x border-[#444444] items-center min-h-[40px]">
                              {item.statusValue == "expired" ? (
                                <button
                                  style={{ border: "1px solid #FFB9B9" }}
                                  className={`${"bg-[#FDC8C866] text-[#FFB9B9]"} ${"font-bold h-[30px] w-[63%] rounded-[28px] font-[Archivo]"}`}
                                >
                                  {strings.EXPIRED}
                                </button>
                              ) : item.statusValue == "on_sale" ? (
                                <button
                                  onClick={() => {
                                    setChangeEventStatus(!changeStatusValue);
                                    setItemValue(item);
                                  }}
                                  className={`${"bg-[#FFFFFF66] text-[#fff] border"} ${"font-bold h-[30px] w-[63%] rounded-[28px] font-[Archivo]"}`}
                                >
                                  {strings.ON_SALE}
                                </button>
                              ) : (
                                typeof item.isActive == "boolean" && (
                                  <div className="flex items-center justify-center gap-3">
                                    <button
                                      onClick={() => {
                                        updateStatusHandle(
                                          item.id,
                                          true,
                                          item.eventName
                                        );
                                      }}
                                      className={`${
                                        productStatus.productType &&
                                        productStatus.id == item.id
                                          ? productStatus.action
                                            ? "bg-[#CCFF00] text-black"
                                            : "kakaoClass text-white"
                                          : item.isActive
                                          ? "bg-[#CCFF00] text-black"
                                          : "kakaoClass text-white"
                                      } ${" font-bold w-[81px] h-[30px] rounded-[28px] font-[Archivo]"}`}
                                    >
                                      {strings.ON}
                                    </button>
                                    <button
                                      onClick={() => {
                                        updateStatusHandle(
                                          item.id,
                                          false,
                                          item.eventName
                                        );
                                      }}
                                      className={`${
                                        productStatus.productType &&
                                        productStatus.id == item.id
                                          ? !productStatus.action
                                            ? "bg-[#CCFF00] text-black"
                                            : "kakaoClass text-white"
                                          : !item.isActive
                                          ? "bg-[#CCFF00] text-black"
                                          : "kakaoClass text-white"
                                      } ${"font-bold w-[81px] h-[30px] rounded-[28px] font-[Archivo]"}`}
                                    >
                                      {strings.OFF}
                                    </button>
                                  </div>
                                )
                              )}
                            </th>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
              {page && page > 1 && (
                <div className="w-full">
                  <div className="mt-3">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      forcePage={offset == 1 ? 0 : offset - 1}
                      pageCount={page}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
            {openRegister && (
              <EventRegister
                setOpenRegister={setOpenRegister}
                setIsLoader={setIsLoader}
                defaultValue={defaultValue}
                setDefaultValue={setDefaultValue}
                classNameValue={`${
                  openRegister ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            {openDetail && (
              <EventDetail
                setOpenDetail={setOpenDetail}
                id={productId}
                setProductId={setProductId}
                setIsLoader={setIsLoader}
                setDefaultValue={setDefaultValue}
                setOpenRegister={setOpenRegister}
                classNameValue={`${openDetail ? "block_notify" : "hideNotify"}`}
              />
            )}
          </Col>
        </Row>
      </Container>
      <StatusChanged
        statusModal={statusModal}
        setStatusModal={setStatusModal}
        productStatus={productStatus}
      />
      <ChangeProductStatus
        setChangeStatus={setChangeEventStatus}
        changeStatus={changeStatusValue}
        head={strings.CHANGE_STATUS_FOR_EVENT}
        changeValue={changeValue}
        setChangeValue={setChangeValue}
        itemValue={itemValue}
        changeStatusHandle={changeStatus}
      />
    </section>
  );
}

export default EventScreen;

function ProductFilter({ setEventType, getEventList, setStatusType }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[250px] right-[5px] top-14 max-h-[280px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setEventType({ text: "All", type: "" });
          setStatusType({ text: "All", type: "" });
          getEventList("", "", 1);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/*  <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.CONTINUOUS, type: 0 });
          setStatusType({ text: "All", type: "" });
          getEventList(0, "", 1);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.CONTINUOUS}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.LIMITED_TIME, type: 1 });
          setStatusType({ text: "All", type: "" });
          getEventList(1, "", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.LIMITED_TIME}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.FIRST_COME_FIRST_SERVED, type: 2 });
          setStatusType({ text: "All", type: "" });
          getEventList(2, "", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">
          {strings.FIRST_COME_FIRST_SERVED}
        </span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.RE_PURCHASE, type: 3 });
          setStatusType({ text: "All", type: "" });
          getEventList(3, "", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.RE_PURCHASE}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.NEW, type: 4 });
          setStatusType({ text: "All", type: "" });
          getEventList(4, "", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.NEW}</span>
      </div>
    </div>
  );
}

function StatusFilter({
  setStatusType,
  getEventList,
  setEventType,
  eventType,
}) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[150px] right-4 top-14 max-h-[200px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setStatusType({ text: strings.ALL, type: "" });
          getEventList(eventType.type, "", 1);
          // setEventType({ text: "All" });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      <div
        onClick={() => {
          setStatusType({ text: strings.EXPIRED, type: 3 });
          getEventList(eventType.type, 3, 1);
          // setEventType({ text: "All" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.EXPIRED}</span>
      </div>
      <div
        onClick={() => {
          setStatusType({ text: strings.ON_SALE, type: 5 });
          getEventList(eventType.type, 5, 1);
          // setEventType({ text: "All" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ON_SALE}</span>
      </div>
    </div>
  );
}
