import React from "react";
import profile from "../../assests/images/demoProfile.svg";
import { Icon } from "@iconify-icon/react";
import plate from "../../assests/icons/plate icon.svg";
import drink from "../../assests/icons/drink icon.svg";
import imgPlace from "../../assests/icons/imgPlace.svg";
import protien from "../../assests/icons/protien.svg";
import carb from "../../assests/icons/carb icon.svg";
import fat from "../../assests/icons/fat icon.svg";
import fiber from "../../assests/icons/Fiber_icon.svg";
import { strings } from "../../utils/String";
import moment from "moment";

const ratings = [
  { nutrient: "Protien", rating: "--" },
  { nutrient: "Carb", rating: "--" },
  { nutrient: "Fat", rating: "--" },
  { nutrient: "Fiber", rating: "--" },
];

const iconValue = { Protien: protien, Carb: carb, Fat: fat, Fiber: fiber };

function DietCards({ itemValue }) {
  return (
    <div className="bg-[#353638] rounded-[10px] px-4 py-4">
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-x-4 flex-wrap gap-y-2">
          <div className="flex items-center gap-3">
            <img
              src={
                itemValue?.memberInfo?.mProfileImg?.prof_img1_min
                  ? itemValue?.memberInfo?.mProfileImg.prof_img1_min
                  : profile
              }
              alt="img"
              className="rounded-full w-[60px] h-[60px]"
            />
            <p className="mb-0 text-2xl font-extrabold  w-[80%] textOverflowClass">
              {itemValue.memberInfo.name}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon
              icon="lets-icons:date-today"
              width="30"
              height="30"
              style={{ color: "#D9D9D9" }}
            />
            <p className="mb-0 text-lg font-bold">
              {moment(itemValue.date).format("YY.MM.DD")}
            </p>
          </div>
        </div>
        <p className="mb-0 text-2xl font-extrabold flex items-center gap-2">
          <Icon
            icon="tabler:star-filled"
            width="30"
            height="30"
            style={{ color: "#CCFF00" }}
          />
          {itemValue.avgRating}
        </p>
      </div>
      <div className="my-3 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2 border-r border-[#676767] pr-4">
            <img src={plate} alt="plate" />
            <p className="mb-0 text-lg font-bold">
              {itemValue.mealsCount} {strings.MEALS}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <img src={drink} alt="drink" />
            <p className="mb-0 text-lg font-bold">
              {itemValue.snackCount} {strings.SNACK}
            </p>
          </div>
        </div>
        <div className="relative">
          <img src={imgPlace} alt="img-place" />
          <span className="rounded-full w-6 h-6 flex items-start justify-center bg-[#FF5C5C] text-sm font-bold text-white absolute bottom-9 pt-0.5 left-8">
            {itemValue?.imageCount}
          </span>
        </div>
      </div>
      <div className="flex items-center max-[1900px]:flex-wrap gap-2 pt-1 justify-center">
        {ratings.map((item, index) => {
          const prasent = itemValue.ratings?.find((ratings) => {
            return ratings?.nutrient == item.nutrient;
          });
          return (
            <div
              className="bg-[#2B2B2C] min-w-[110px] max-w-full rounded-[10px] px-2.5 py-3"
              key={index}
            >
              <div className="flex items-center gap-2 justify-center">
                <img src={iconValue[item.nutrient]} alt="icon" />
                <p className="text-base font-bold mb-0">
                  {prasent?.nutrient || item?.nutrient}
                </p>
              </div>
              <div className="flex items-start gap-2 pt-3 justify-center">
                {prasent && (
                  <Icon
                    icon="tabler:star-filled"
                    width="30"
                    height="30"
                    style={{ color: "#FAFAFA66" }}
                  />
                )}
                <p className="mb-0 text-xl font-bold">
                  {prasent?.rating || item?.rating}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DietCards;
