import React, { useState } from "react";
import { InputGroup } from "react-bootstrap";
import { strings } from "../../../utils/String";
import ManagementSublyApi from "../../../helper/ManagementApi";
import { useSelector } from "react-redux";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";

function EditTrainerReqModal({
  detailValue,
  setEditModal,
  setProductId,
  setIsLoader,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [trainerId, setTrainerId] = useState(detailValue?.nextTrainerId);
  const [changeValue, setChangeValue] = useState(detailValue?.status);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  // ===========API for change trainer or edit status===============
  async function changeTrainerStatus() {
    setIsLoader(true);
    const requestData = {
      requestId: detailValue?.id,
      status: Number(changeValue),
      trainerId: trainerId,
    };
    await ManagementSublyApi.editChangeTrainerReq(token, requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setEditModal(false);
          setProductId("");
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={` ${classNameValue} ${"!bg-[#353638] rounded-[10px] w-[80%] max-h-[75%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-28 z-20 "}`}
      >
        <div className="w-[70%] max-xl:w-[90%] max-lg:w-[100%] m-auto ">
          <div className=" m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center  font-bold pb-4">
              Edit request for Changing Trainer
            </p>
          </div>
          <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full mt-4">
            <p className="mb-0 text-[20px] text-[#FFFFFFBF] font-bold mt-2">
              {strings.REQUEST_FOR_CHANGING_TRAINER}:
            </p>
            <div className="flex items-center gap-x-2.5">
              <InputGroup className="checkboxClass !p-0">
                <InputGroup.Checkbox
                  className="w-[31px] h-[31px] !bg-[#353638] -mt-1"
                  value={3}
                  checked={changeValue == 3}
                  onChange={(e) => setChangeValue(e.target.value)}
                />
              </InputGroup>
              <p className="mb-0 text-[22px] font-bold -mt-1">
                {strings.PROCESSING}
              </p>
            </div>
            <div className="flex items-center gap-x-2.5">
              <InputGroup className="checkboxClass !p-0">
                <InputGroup.Checkbox
                  className="w-[31px] h-[31px] !bg-[#353638] -mt-1"
                  value={1}
                  checked={changeValue == 1}
                  onChange={(e) => setChangeValue(e.target.value)}
                />
              </InputGroup>
              <p className="mb-0 text-[22px] font-bold mt-0">
                {strings.COMPLETED}
              </p>
            </div>
          </div>

          <div
            className={`${
              detailValue.status !== 0 ? "border-b-4 border-[#414142]" : ""
            } ${"mt-7 pb-4"}`}
          >
            {" "}
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div
                className={`flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3 px-4 w-full h-full ${
                  setEditModal ? "" : "bg-[#414142]"
                }`}
              >
                <p className="mb-0 flex text-[#FFFFFFBF] max-sm:flex-wrap items-center gap-2 text-xl font-bold">
                  PT session information:
                  <span className="font-extrabold text-2xl text-white">
                    {detailValue?.sessionCompleted}
                    <span className="text-lg">
                      /{detailValue?.totalSession}
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div
                className={`flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3 px-4 w-full h-full ${
                  setEditModal ? "" : "bg-[#414142]"
                }`}
              >
                <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-xl font-bold text-[#FFFFFFBF]">
                  Left session counts:
                  <span className="font-extrabold text-2xl text-white">
                    {" "}
                    {detailValue?.totalSession -
                      detailValue?.sessionCompleted}{" "}
                    session
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div
                className={`flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3 px-4 w-full h-full ${
                  setEditModal ? "" : "bg-[#414142]"
                }`}
              >
                <p className="mb-0 flex items-center max-sm:flex-wrap gap-2 text-xl font-bold">
                  <span className="text-[#FFFFFFBF]"> Current Trainer:</span>
                  <span className="font-extrabold text-2xl">
                    {detailValue?.currentTrainer?.name}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-x-3 max-lg:flex-wrap text-xl font-bold">
                  <span className="text-[#FFFFFFBF]"> Change trainer to:</span>
                  <p className="mb-0 flex items-center flex-wrap gap-x-3 ">
                    {" "}
                    {detailValue?.reqTrainers?.map((val, index) => (
                      <span
                        className="font-extrabold text-2xl flex items-center textOverflowClass"
                        key={index}
                      >
                        <InputGroup className="checkboxClass paddingManage !p-0">
                          <InputGroup.Checkbox
                            className="w-[28px] h-[28px] !bg-[#353638] !p-0"
                            value={val.id}
                            checked={trainerId == val.id ? true : false}
                            onChange={(e) => {
                              setTrainerId(e.target.value);
                            }}
                          />
                        </InputGroup>
                        {`${index + 1}.`} {`${val?.gymStaffName} `}
                      </span>
                    ))}
                  </p>
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-5 ">
            <button
              onClick={() => {
                setEditModal(false);
                setProductId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
            <button
              onClick={() => {
                changeTrainerStatus();
              }}
              disabled={trainerId && changeValue ? false : true}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CONFIRM}
            </button>
          </div>
        </div>
      </div>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default EditTrainerReqModal;
