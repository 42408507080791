import React from "react";
import { strings } from "../../../../utils/String";
import { Modal } from "react-bootstrap";
import redalert from "../../../../assests/icons/Redalert.png";

function FundsModal({
  classNameValue,
  buttonKey,
  fundsError,
  setFundsError,
  amount,
}) {
  return (
    <>
      <Modal
        show={fundsError}
        className={`${classNameValue} ${"modalBoxClass"}`}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <div className="flex flex-col items-center w-full">
            <div className="flex items-center w-full justify-center ">
              <img src={redalert} className="mr-4 pb-2 w-[54px] h-[54px]" />
              <p className="text-[40px] max-lg:text-[28px] font-extrabold">
                Insufficient funds!
              </p>
            </div>
            <div className="border-b-2 border-[#444444] w-full"></div>
          </div>
          <p className="text-[30px] max-lg:text-[18px] font-bold pb-2 mb-0">
            <p className="mt-4">
              {amount} amount is insufficient. Please recharge to continue using
              the service.
            </p>
          </p>
          <div className="flex justify-center pt-8 gap-4">
            <button
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
              onClick={() => {
                setFundsError(false);
              }}
            >
              {strings.CLOSE}
            </button>
            <>
              <button className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold  kakaoClass bg-[#CCFF00] text-black  h-[60px] w-[160px] rounded-[28px]">
                {strings.CONFIRM}
              </button>
            </>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FundsModal;
