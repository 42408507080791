import React from "react";
import gymImg from "../../assests/images/image 65.png";
import { Icon } from "@iconify-icon/react";
import { strings } from "../../utils/String";

function GymCard({ item, gymId, setGymId }) {
  return (
    <>
      <div
        onClick={() => {
          setGymId(item);
        }}
        className={`${
          gymId.gymId == item.gymId &&
          item.staffId == gymId.staffId &&
          "kakaoClass"
        } ${"bg-box_bg rounded-lg w-[280px] h-full text-center px-2 py-4"}`}
      >
        <div className="pb-2 px-4">
          <img
            src={
              item?.gymBgImages?.length > 0
                ? (item?.gymBgImages || [])[0]?.url
                : gymImg
            }
            alt="gym-img"
            className="w-48 h-48 rounded-full mx-auto"
          />
        </div>
        <p className="text-xl text-white font-bold border-b-2 border-[#505050] overflow-hidden pb-2.5 mb-0 textOverflowClass w-[90%] m-auto leading-[2.5rem]">
          {item?.name}
        </p>
        {Object.keys(item?.workingHour || []).map((val, index) => {
          // console.log("-------ITEM", item);
          return (
            <div className="px-2 flex items-center gap-2 justify-between ">
              <div className="flex items-center gap-2 text-sm text-white font-bold">
                <Icon
                  icon="lets-icons:date-today"
                  width="20"
                  height="20"
                  style={{ color: "#fff" }}
                />
                <p className="!mb-0 textOverflowClass">{val}</p>
              </div>
              <span className="text-[#F1F1F180] text-xl">|</span>

              <div className="flex items-center gap-1 text-sm text-white font-bold">
                <Icon
                  icon="ion:time-outline"
                  width="20"
                  height="20"
                  style={{ color: "#fff" }}
                />
                <p className="!mb-0 textOverflowClass">
                  {item?.workingHour[val]}
                </p>
              </div>
            </div>
          );
        })}

        {/* <div className="px-2 flex items-center gap-2 justify-between pt-1 pb-2.5">
          <div className="flex items-center gap-2 text-sm text-white font-bold">
            <Icon
              icon="lets-icons:date-today"
              width="20"
              height="20"
              style={{ color: "#fff" }}
            />
            SAT - SUN
          </div>
          <span className="text-[#F1F1F180] text-xl">|</span>
          <div className="flex items-center gap-2 text-sm text-white font-bold">
            <Icon
              icon="ion:time-outline"
              width="20"
              height="20"
              style={{ color: "#fff" }}
            />
            {item?.workingHour && item?.workingHour["sat-sun"]}
          </div>
        </div> */}
        <p className="text-sm font-bold mb-0 text-[#FFFFFFB2] !text-start px-2 mt-1">
          <span className="text-[#FF7C7C] text-sm font-bold">
            {strings.CLOSED}
          </span>{" "}
          on public holidays
        </p>
      </div>
    </>
  );
}

export default GymCard;
