import { Icon } from "@iconify-icon/react";
import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import "../memberSearch/MemberSearch.css";
import { placeholder, strings } from "../../../utils/String";
import camera from "../../../assests/icons/camera-plus.svg";
import edit from "../../../assests/icons/editImage.svg";
import FormProductSection from "./FormProductSection";
import MembersSublyApi from "../../../helper/MemberApi";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import {
  alphaOnly,
  formatCustomNumber,
  numberOnly,
  numberValidate,
  trimPhone,
  validateEmail,
} from "../../../utils/commonUtils";
import $ from "jquery";
import { useDaumPostcodePopup } from "react-daum-postcode";
import DeleteConfirmation from "../../commonComponents/modalScreens/DeleteConfirmation";
import { DatePicker } from "rsuite";
import { HiCalendar } from "react-icons/hi2";

// ========function for getting list of years==========
export const years = Array.from(
  { length: 100 },
  (_, i) => new Date().getFullYear() - 99 + i
).reverse();

function MemberRegisterForm({
  classNameValue,
  setLockerList,
  setShowRegisterForm,
  lockerValue,
  setLockerValue,
  setTrainerModal,
  trainerValue,
  setTrainerValue,
  productModal,
  setProductModal,
  setIsLoader,
  id,
  productArr,
  setIsUpdate,
  setProductArr,
  addProduct,
  setAddProduct,
  takeOverProduct,
  setTakeOverProduct,
  setTrainerHeading,
}) {
  const { token } = useSelector((state) => state.user);
  const today = new Date().toISOString().substr(0, 10);
  const [showGender, setShowGender] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [name, setName] = useState("");
  const [registerDate, setRegisterDate] = useState(today);
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isCellNumber, setIsCellNumber] = useState(false);
  const [gender, setGender] = useState({
    placeholder: "",
    id: "",
  });
  const [zipCode, setZipCode] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [isError, setIsError] = useState(false);
  const [memberShipValue, setMemberShipValue] = useState({
    name: "MemberShip",
    price: "",
    id: "",
  });
  const [ptValue, setPtValue] = useState({ name: "PT", price: "", id: "" });
  const [golfValue, setGolfValue] = useState({
    name: "golf",
    price: "",
    id: "",
  });
  const [memoValue, setMemoValue] = useState("");
  const [memoList, setMemoList] = useState([]);
  const [startDateMemberShip, setStartDateMemberShip] = useState("");
  const [startDatePt, setStartDatePt] = useState("");
  const [startDateLocker, setStartDateLocker] = useState("");
  const [startDateGolf, setStartDateGolf] = useState("");
  const [userData, setUserData] = useState("");
  const [productId, setProductId] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [daumPostcode, setDaumPostcode] = useState(null);
  const [openCalender, setOpenCalender] = useState(false);
  const [showYear, setShowYear] = useState(false);
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [memoToDelete, setMemoToDelete] = useState(null);
  const [errorHandler, setErrorHandler] = useState({
    name: "",
    email: "",
    birthDate: "",
    cellPhone: "",
    gender: "",
  });
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  const labelRef = useRef(null);
  // ===========function for Image handling=============
  const onFileHandle = async (e, index) => {
    const file = e.target.files[0];
    setImage(file);
    if (e.target.files && e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const { result } = e.target;
        if (result) {
          await setImagePreview([result]);
        }
      };
      fileReader.readAsDataURL(file);
    }
  };
  const productIdArr = [];
  if (memberShipValue.id || startDateMemberShip) {
    productIdArr.push({
      id: memberShipValue.id,
      membershipStartDate: startDateMemberShip,
    });
  }
  if (ptValue.id || startDatePt) {
    productIdArr.push({
      id: ptValue.id,
      membershipStartDate: startDatePt,
    });
  }
  if (golfValue.id || startDateGolf) {
    productIdArr.push({
      id: golfValue.id,
      membershipStartDate: startDateGolf,
    });
  }

  // ===========Registration member API============
  async function registerHandle() {
    // if (!validateEmail(email)) {
    //   setIsEmailError(true);
    // }
    // if (!numberValidate(cellPhone)) {
    //   setIsCellNumber(true);
    // }
    if (name && email && cellPhone && birthDate && gender.id) {
      if (validateEmail(email) && numberValidate(cellPhone)) {
        setIsLoader(true);
        setErrorHandler({
          name: "",
          email: "",
          birthDate: "",
          cellPhone: "",
          gender: "",
        });
        setIsError(false);
        const requestData = new FormData();
        image && requestData.append("file", image);
        requestData.append("name", name);
        requestData.append("registrationDate", registerDate);
        requestData.append("email", email);
        requestData.append("cellphone", `${cellPhone.toString()}`);
        requestData.append("birth", moment(birthDate).format("DD-MM-yyyy"));
        requestData.append("gender", gender.id);
        zipCode && requestData.append("zipCode", zipCode);
        addressOne && requestData.append("address1", addressOne);
        addressTwo && requestData.append("address2", addressTwo);
        lockerValue.id && requestData.append("lockerId", lockerValue.id);
        startDateLocker &&
          requestData.append("lockerStartDate", startDateLocker);
        requestData.append("productIds", JSON.stringify(productArr));
        requestData.append("memo", JSON.stringify(memoList));
        trainerValue?.id && requestData.append("trainerId", trainerValue.id);
        requestData.append("memberOwnProductIds", JSON.stringify(productArr));
        requestData.append("takeOverCode", userData?.userCode);
        requestData.append(
          "takeOverProductIds",
          JSON.stringify(takeOverProduct)
        );

        await MembersSublyApi.registrationMember(requestData, token)
          .then((response) => {
            setIsLoader(false);
            if (response.statusCode == 200) {
              setShowRegisterForm(false);
              setMemoList([]);
              setLockerValue({ name: "", password: "", id: "" });
              setProductArr([]);
              setTakeOverProduct("");
              setTrainerValue({ name: "", id: "" });
            } else {
              setOpenModal(true);
              seterrorMessage(response.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const errorMessage = {
          ...(email && !validateEmail(email)
            ? { email: "Please enter valid email" }
            : { email: "" }),
          ...(cellPhone && !numberValidate(cellPhone)
            ? { cellPhone: "Cellphone must be 11 digits" }
            : { cellPhone: "" }),
        };
        setErrorHandler(errorMessage);
      }
    } else {
      setIsError(true);
      if (email || cellPhone) {
        setErrorHandler({
          ...errorHandler,
          name: "Please enter the name value",
          birthDate: "Please enter the birthdate value",
          gender: "Please select the gender",
        });
      } else {
        setErrorHandler({
          name: "Please enter the name value",
          email: "Please enter the email value",
          birthDate: "Please enter the birthdate value",
          cellPhone: "Please enter the value of cellPhone",
          gender: "Please select the gender",
        });
      }
    }
  }

  // ===========Update product API============
  async function updateHandle() {
    setIsLoader(true);
    const requestData = {
      productIds: productArr,
      memberId: addProduct?.memberId,
      ...(lockerValue.id && {
        lockerId: lockerValue.id,
      }),
      ...(trainerValue.id && {
        trainerId: trainerValue.id,
      }),
    };
    await MembersSublyApi.memberProductUpdate(requestData, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setShowRegisterForm(false);
          setMemoList([]);
          setLockerValue({ name: "", password: "", id: "" });
          setProductArr([]);
          setAddProduct("");
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setShowGender(false);
    }
  });

  // ===========function for add memo handling=========
  function handleMemo() {
    const memoArr = [...memoList];
    if (memoValue) {
      memoArr.push(memoValue.trim());
      setMemoList(memoArr);
      setMemoValue("");
    }
  }
  //--------function for handling name input filed------
  $("#name").bind("keypress", alphaOnly);

  // =============Code for fetch address through zip code============
  const open = useDaumPostcodePopup(
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );

  useEffect(() => {
    const storedMemos = JSON.parse(localStorage.getItem("memos")) || [];
    setMemoList(storedMemos);
  }, []);

  // Deleting a memo
  const deleteMemo = () => {
    const updatedMemos = memoList.filter((_, i) => i !== memoToDelete);
    setMemoList(updatedMemos);
    // localStorage.setItem("memos", JSON.stringify(updatedMemos));
    setIsDeleteModalOpen(false);
    setMemoToDelete(null);
  };

  // To handle delete button click
  const handleDeleteClick = (index) => {
    setMemoToDelete(index);
    setIsDeleteModalOpen(true);
  };

  const handleComplete = (data) => {
    let fullAddress = data.addressEnglish;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setZipCode(data?.zonecode);
    setAddressOne(fullAddress);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete, width: "400px", height: "400px" });
  };

  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonth).getMonth();
    return condition;
  };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
  }, [isCalenderOpen, currentMonth]);

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center items-center bg-[#000000c2] z-10"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#2C2C2E] rounded-[10px]  max-h-[95%] p-10 max-[500px]:p-3 pb-14 overflow-auto z-10"} ${
          addProduct ? "w-[75%]" : "w-[95%]"
        }`}
      >
        {addProduct && (
          <p className="text-[40px] font-bold placeholder:font-medium text-center">
            Add Product
          </p>
        )}
        <Row>
          {!addProduct && (
            <Col
              xxl={2}
              xl={3}
              className={`${addProduct ? "opacity-[0.3]" : " "}`}
            >
              <div className="uploadImageClass m-auto mb-3">
                <div className=" w-full h-full rounded-[5px] bg-[#949494] m-auto flex items-center justify-center relative">
                  {!imagePreview ? (
                    <label
                      htmlFor="uploadImg"
                      className="w-full h-full flex items-center justify-center"
                    >
                      <div className="text-center">
                        <img
                          src={camera}
                          alt="add-image"
                          className="cursor-pointer w-[70px] h-[60px] m-auto"
                        />
                        <p className="mb-0 text-base font-bold placeholder:font-medium text-[#A8A8A8]">
                          {strings.ATTACH_IMAGE}
                        </p>
                      </div>
                    </label>
                  ) : (
                    <>
                      <img
                        src={imagePreview}
                        className="w-full h-full rounded-[5px]"
                      />
                      <label
                        htmlFor="uploadImg"
                        className="absolute top-[162px] cursor-pointer"
                      >
                        <img src={edit} />
                      </label>
                    </>
                  )}
                </div>
                <input
                  autoComplete="off"
                  type="file"
                  className="hidden"
                  id="uploadImg"
                  onChange={(e) => onFileHandle(e)}
                />
              </div>
            </Col>
          )}
          <Col xxl={addProduct ? 12 : 10} xl={9} className="mx-auto">
            {!addProduct && (
              <Row
                className={`${addProduct ? "opacity-[0.3]" : " "} ${"gap-y-4"}`}
              >
                <Col xxl={6}>
                  <div>
                    <div className="w-full">
                      <p className="text-white font-bold text-sm mb-1 ml-2 -mt-2">
                        {strings.NAME}
                      </p>

                      <label
                        htmlFor="name"
                        className={`${
                          isError && !name ? "errorClass" : "mb-3"
                        } bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px]`}
                      >
                        <input
                          disabled={addProduct ? true : false}
                          autoComplete="off"
                          type="text"
                          className="bg-[#414142] text-white w-full font-bold placeholder:font-medium"
                          id="name"
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^[a-zA-Z가-힣- ]*$/;
                            if (regex.test(value)) {
                              setName(value.trim());
                            }
                          }}
                          placeholder={placeholder.NAME_STAR}
                        />
                      </label>

                      {isError && !name && (
                        <p className="text-base text-[#F87171] mb-3">
                          {errorHandler?.name}
                        </p>
                      )}
                    </div>

                    <div className="w-full">
                      <p className="text-white font-bold text-sm mb-1 ml-2 -mt-2">
                        {strings.EMAIL_}
                      </p>

                      <label
                        htmlFor="email"
                        className={`${
                          (!email || !validateEmail(email)) &&
                          errorHandler?.email
                            ? "errorClass"
                            : "mb-3"
                        } bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px]`}
                      >
                        <input
                          disabled={addProduct ? true : false}
                          type="text"
                          autoComplete="off"
                          value={email}
                          className="bg-[#414142] text-white w-full font-bold placeholder:font-medium"
                          id="email"
                          onChange={(e) => {
                            if (
                              e.target.value.match(
                                "^[\u1100-\u11FF\u3130-\u318F\uAC00-\uD7A3]+$"
                              )
                            ) {
                              return false;
                            } else {
                              setIsEmailError(false);
                              const trimmedValue = e.target.value.trim();
                              setEmail(trimmedValue);
                              if (
                                trimmedValue &&
                                !validateEmail(trimmedValue)
                              ) {
                                setErrorHandler({
                                  ...errorHandler,
                                  email: "Please enter a valid email",
                                });
                              }
                            }
                          }}
                          placeholder={`${placeholder.EMAIL}*`}
                        />
                      </label>

                      {(!email || !validateEmail(email)) &&
                        errorHandler?.email && (
                          <p className="text-base text-[#F87171] mb-3">
                            {errorHandler?.email}
                          </p>
                        )}
                    </div>

                    <div className="w-full">
                      <p className="text-white font-bold text-sm mb-1 ml-2 -mt-2">
                        {strings.CELL_PHONE}
                      </p>

                      <label
                        htmlFor="cell"
                        className={`${
                          (!cellPhone || !numberValidate(cellPhone)) &&
                          errorHandler?.cellPhone
                            ? "errorClass"
                            : "mb-3"
                        } bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px] numberFieldClass`}
                      >
                        <input
                          disabled={addProduct ? true : false}
                          autoComplete="off"
                          type="text"
                          className="bg-[#414142] text-white w-full numberField font-bold placeholder:font-medium"
                          id="cell"
                          value={formatCustomNumber(cellPhone)}
                          onChange={(e) => {
                            const trimmedValue = trimPhone(e.target.value);
                            if (
                              !isNaN(+trimmedValue) ||
                              !trimmedValue?.length
                            ) {
                              setIsCellNumber(false);
                              if (trimmedValue.length <= 11)
                                setCellPhone(trimmedValue);
                            }
                            if (trimmedValue && !numberValidate(trimmedValue)) {
                              setErrorHandler({
                                ...errorHandler,
                                cellPhone: "Cellphone must be 11 digits",
                              });
                            }
                          }}
                          placeholder={placeholder.CELL_PHONE_STAR}
                        />
                      </label>

                      {(!cellPhone || !numberValidate(cellPhone)) &&
                        errorHandler.cellPhone && (
                          <p className="text-base text-[#F87171] mb-3">
                            {errorHandler?.cellPhone}
                          </p>
                        )}
                    </div>
                    <div
                      className={`flex items-start gap-3 ${
                        isError && (!birthDate || !gender.id) ? "" : "mb-3"
                      }`}
                    >
                      <div className="w-full">
                        <p className="text-white font-bold text-sm mb-1 ml-2 -mt-2">
                          {strings.BIRTH}
                        </p>

                        <div
                          className={`${
                            errorHandler?.birthDate && !birthDate
                              ? "errorClass"
                              : ""
                          } bg-[#414142] w-full h-[45px] pl-3 pr-2 flex items-center gap-2 rounded-[10px] newDateInputClass`}
                        >
                          <DatePicker
                            oneTap
                            caretAs={HiCalendar}
                            id="datepicker-1"
                            placeholder={placeholder.BIRTH_STAR}
                            format="yyyy.MM.dd"
                            block
                            editable={false}
                            appearance="subtle"
                            className="custom-datepicker bg-transparent border-0 w-full px-0"
                            value={birthDate}
                            onChange={(date) => setBirthDate(date)}
                            onChangeCalendarDate={(e) => {
                              setCurrentMonth(e);
                            }}
                            onNextMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onPrevMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onOpen={() => {
                              setIsCalenderOpen(true);
                              setDatePickerCheck(1);
                              setCurrentMonth(
                                birthDate ? new Date(birthDate) : new Date()
                              );
                            }}
                            onClose={() => {
                              setIsCalenderOpen(false);
                              setCurrentMonth(
                                birthDate ? new Date(birthDate) : new Date()
                              );
                              setDatePickerCheck("");
                            }}
                            disabledDate={(date) => {
                              const maxDate = new Date();
                              return date > maxDate;
                            }}
                          />
                        </div>
                        {errorHandler?.birthDate && !birthDate && (
                          <p className="text-base text-[#F87171] mb-3">
                            {errorHandler?.birthDate}
                          </p>
                        )}
                      </div>
                      <div className="w-full">
                        <p className="text-white font-bold text-sm mb-1 ml-2 -mt-2">
                          {strings.SELECT_GENDER}
                        </p>
                        <label
                          htmlFor="nameId"
                          onClick={() => {
                            !addProduct && setShowGender(!showGender);
                          }}
                          className={`${
                            errorHandler?.gender && !gender.id
                              ? "errorClass"
                              : ""
                          } bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px] relative dropdownClass`}
                        >
                          <input
                            disabled={addProduct ? true : false}
                            autoComplete="off"
                            type="text"
                            value={gender.placeholder}
                            className="bg-[#414142] text-white font-bold placeholder:font-medium w-full caret-transparent cursor-pointer"
                            id="nameId"
                            placeholder={placeholder.SELECT_GENDER}
                          />
                          <Icon
                            icon="tabler:caret-down-filled"
                            width="20"
                            height="20"
                            style={{ color: "#D9D9D9", cursor: "pointer" }}
                            rotate={showGender ? 2 : 4}
                          />
                          {showGender && (
                            <GenderOptionBox
                              setGender={setGender}
                              setShowGender={setShowGender}
                            />
                          )}
                        </label>

                        {errorHandler?.gender && !gender.id && (
                          <p className="text-base text-[#F87171] mb-3">
                            {errorHandler?.gender}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="w-full">
                      <p className="text-white font-bold text-sm mb-1 ml-2 -mt-2">
                        {strings.ZIP_CODE}
                      </p>

                      <label
                        htmlFor="zipCode"
                        onClick={handleClick}
                        className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px] mb-3"
                      >
                        <input
                          disabled={addProduct ? true : false}
                          autoComplete="off"
                          type="text"
                          className="bg-[#414142] text-white font-bold placeholder:font-medium w-full caret-transparent cursor-pointer"
                          id="zipCode"
                          value={zipCode}
                          placeholder={placeholder.ZIP_CODE}
                        />
                        {/* <DaumPostcodeEmbed onComplete={handleComplete} /> */}
                        <Icon
                          icon="lucide:search"
                          width="25"
                          height="25"
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      </label>
                    </div>

                    <div className="w-full">
                      <p className="text-white font-bold text-sm mb-1 ml-2 -mt-2">
                        {strings.ADDRESS_ONE}
                      </p>

                      <label
                        htmlFor="addressOne"
                        onClick={handleClick}
                        className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px] mb-3"
                      >
                        <input
                          disabled={addProduct ? true : false}
                          autoComplete="off"
                          type="text"
                          className="bg-[#414142] text-white font-bold placeholder:font-medium w-full caret-transparent"
                          id="addressOne"
                          value={addressOne}
                          placeholder={`${placeholder.ADDRESS} 1`}
                        />
                      </label>
                    </div>

                    <div className="w-full">
                      <p className="text-white font-bold text-sm mb-1 ml-2 -mt-2">
                        {strings.ADDRESS_TWO}
                      </p>

                      <label
                        htmlFor="addressTwo"
                        className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px]"
                      >
                        <input
                          disabled={addProduct ? true : false}
                          autoComplete="off"
                          type="text"
                          className="bg-[#414142] text-white font-bold placeholder:font-medium w-full"
                          id="addressTwo"
                          value={addressTwo}
                          onChange={(e) => setAddressTwo(e.target.value)}
                          placeholder={`${placeholder.ADDRESS} 2`}
                        />
                      </label>
                    </div>
                  </div>
                </Col>
                <Col xxl={6}>
                  <div className="bg-[#414142] w-full max-h-[400px] max-xl:mt-3 p-3 rounded-[10px] text-white">
                    {memoList && memoList.length > 0 ? (
                      <ol className="w-full mt-2 list-decimal text-base font-bold placeholder:font-medium leading-8 max-h-[300px] overflow-auto">
                        {memoList &&
                          memoList.map((item, index) => (
                            <li key={index} className="relative">
                              {item}
                              <Icon
                                icon="mdi:trash-can-outline"
                                width="22"
                                height="22"
                                className="text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer absolute right-20"
                                onClick={() => handleDeleteClick(index)}
                              />
                            </li>
                          ))}
                        {isDeleteModalOpen && (
                          <DeleteConfirmation
                            classNameValue={classNameValue} // Replace with your actual class name if needed
                            textHead={strings.DELETE_MEMO}
                            text={strings.DO_YOU_WANT_TO_DELETE_MEMO}
                            deleteMemo={deleteMemo}
                            setDeleteMemo={setMemoToDelete}
                            clickBtn={deleteMemo}
                            btnText={strings.DELETE}
                          />
                        )}
                      </ol>
                    ) : (
                      ""
                    )}
                    <div className="flex items-center gap-2">
                      <label
                        style={{ border: "2px solid #4E4E4E" }}
                        className=" rounded-[10px] h-[35px] w-full px-3 flex items-center"
                      >
                        <input
                          disabled={addProduct ? true : false}
                          autoComplete="off"
                          type="text"
                          value={memoValue}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex =
                              /^[a-zA-Z가-힣0-9\s!@#$%^&*(),.?":{}|<>'/=+-_]*$/;
                            if (regex.test(value)) {
                              setMemoValue(value);
                            }
                          }}
                          className="bg-[#414142] text-white w-full"
                          placeholder={`${placeholder.ADD_MEMO}`}
                        />
                      </label>
                      <button
                        disabled={memoValue ? false : true}
                        onClick={() => {
                          handleMemo();
                        }}
                        className="bg-common_color text-black h-[25px] px-2 rounded-[5px] text-sm font-bold placeholder:font-medium disabled:bg-[#ccff0094]"
                      >
                        {strings.ADD}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <FormProductSection
              setLockerList={setLockerList}
              clickBtn={registerHandle}
              setShowRegisterForm={setShowRegisterForm}
              memberShipValue={memberShipValue}
              setMemberShipValue={setMemberShipValue}
              ptValue={ptValue}
              setPtValue={setPtValue}
              lockerValue={lockerValue}
              setLockerValue={setLockerValue}
              classNameValue={classNameValue}
              golfValue={golfValue}
              setGolfValue={setGolfValue}
              startDateMemberShip={startDateMemberShip}
              setStartDateMemberShip={setStartDateMemberShip}
              startDatePt={startDatePt}
              setStartDatePt={setStartDatePt}
              startDateLocker={startDateLocker}
              setStartDateLocker={setStartDateLocker}
              startDateGolf={startDateGolf}
              setStartDateGolf={setStartDateGolf}
              setTrainerModal={setTrainerModal}
              trainerValue={trainerValue}
              setTrainerValue={setTrainerValue}
              productModal={productModal}
              setProductModal={setProductModal}
              productArr={productArr}
              setProductArr={setProductArr}
              addProduct={addProduct}
              setAddProduct={setAddProduct}
              updateBtn={updateHandle}
              setUserData={setUserData}
              setProductId={setProductId}
              userData={userData}
              productId={productId}
              setTakeOverProduct={setTakeOverProduct}
              takeOverProduct={takeOverProduct}
              setTrainerHeading={setTrainerHeading}
            />
          </Col>
        </Row>

        <ErrorModal
          openModal={openModal}
          closeModal={setOpenModal}
          errorMessage={errorMessage}
          classNameValue={classNameValue}
        />
      </div>
    </section>
  );
}

export default MemberRegisterForm;

export function GenderOptionBox({ setGender, setShowGender }) {
  return (
    <div className="bg-[#5C5C5C] rounded-[10px] absolute right-5 top-7 py-2 z-10">
      <ul className="mb-1 max-h-[120px] mr-6 overflow-auto">
        <li
          onClick={() => {
            setGender({ placeholder: "Male", id: "m" });
            setShowGender(false);
          }}
          className="text-sm font-bold placeholder:font-medium text-white mb-3 cursor-pointer hover:text-[#CCFF00]"
        >
          {strings.MALE}
        </li>
        <li
          onClick={() => {
            setGender({ placeholder: "Female", id: "f" });
            setShowGender(false);
          }}
          className="text-sm font-bold placeholder:font-medium text-white cursor-pointer hover:text-[#CCFF00]"
        >
          {strings.FEMALE}
        </li>
      </ul>
    </div>
  );
}
