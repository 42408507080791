import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import demoProfile from "../../../assests/images/demoProfile.svg";
import { Icon } from "@iconify-icon/react";
import plate from "../../../assests/icons/plate icon.svg";
import drink from "../../../assests/icons/drink icon.svg";
import { Col, Row } from "react-bootstrap";
import DietDetailCard from "../../commonComponents/DietDetailCard";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import moment from "moment";

function DietLogDetail({
  setOpenDetail,
  detailsData,
  setIsLoader,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [detailInfo, setDetailInfo] = useState("");
  // ================DIET LOG DETAIL FETCH API===============
  useEffect(() => {
    async function fetchDietLogDetail() {
      setIsLoader(true);
      await PT_InformationSublyApi.dietLogDetail(
        token,
        detailsData?.id,
        detailsData?.date
      )
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setDetailInfo(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchDietLogDetail();
  }, []);

  return (
    <section
      className={`${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] max-w-[80%] max-h-[90%] py-3 px-7 mt-10 z-20 overflow-auto"}`}
      >
        <div className="w-full m-auto">
          <div className="flex justify-between items-center border-b border-[#444444] pb-3 max-sm:flex-wrap gap-y-3">
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-2 ">
                <span className="rounded-full w-[58px] h-[58px]">
                  <img
                    src={detailsData?.img?.prof_img1_min || demoProfile}
                    alt="demo-profile"
                    className="w-full h-full rounded-full"
                  />
                </span>
                <p className="mb-0 text-[28px] font-extrabold border-r-2 border-[#505050] pr-4 ">
                  {detailsData?.name}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Icon
                  icon="lets-icons:date-today"
                  width="30"
                  height="30"
                  style={{ color: "#D9D9D9" }}
                />
                <p className="mb-0 text-[22px] font-bold">
                  {moment(detailsData?.date).format("YY.MM.DD")}
                </p>
              </div>
            </div>
            <div>
              <p className="mb-0 text-[30px] font-extrabold flex items-center gap-2 text-common_color">
                <Icon
                  icon="tabler:star-filled"
                  width="32"
                  height="32"
                  style={{ color: "#CCFF00" }}
                />
                {detailsData?.rate}
              </p>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-3 py-3">
              <div className="flex items-center gap-2 border-r-2 border-[#505050] pr-4">
                <img src={plate} alt="plate" className="w-[47px] h-[41px]" />
                <p className="mb-0 text-[26px] font-bold">
                  {detailInfo?.mealsCount} {strings.MEALS}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <img src={drink} className="w-[47px] h-[41px]" />
                <p className="mb-0 text-[26px] font-bold">
                  {detailInfo?.snackCount} {strings.SNACK}
                </p>
              </div>
            </div>
            <div className="gap-y-3 flex items-start justify-start flex-wrap">
              {detailInfo &&
                detailInfo?.response?.map((item, index) => (
                  <div key={index} className="!pr-1.5 !pl-1.5">
                    <DietDetailCard val={item} />
                  </div>
                ))}
            </div>
          </div>
          <div className="flex items-center justify-center mt-7 mb-3">
            <button
              onClick={() => {
                setOpenDetail(false);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DietLogDetail;
