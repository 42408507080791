import React, { useEffect, useState } from "react";
import logo from "../../assests/icons/Logo.svg";
import { strings } from "../../utils/String";
import { Icon } from "@iconify-icon/react";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  boardSubOption,
  gymSettingSubOption,
  gymSettingsSubOption,
  managementSubOption,
  ptInformationSubOption,
  sideBarMenu,
  statisticSubOption,
} from "../../utils/SideBarData";
import { useDispatch, useSelector } from "react-redux";
import {
  adminPanelHandle,
  chatIdHandle,
  deleteUserDetail,
  fetchStaffAuth,
  messageCount,
} from "../../Store/slices/AuthSlice";
import MembersSublyApi from "../../helper/MemberApi";

function SideBar({ updateModal }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, staffId } = useSelector((state) => state.user.selectedGym);
  const [permissionKey, setPermissionKey] = useState("");
  const { staffAuthRespo } = useSelector((state) => state.user);
  const statisticCheck = location.pathname.match("Statistics");
  const ptInformationCheck = location.pathname.match("PT-information");
  const boardCheck = location.pathname.match("Board");
  const settingCheck = location.pathname.match("Gym-setting");
  const gymSettingCheck = location.pathname.match("Gym-settings");
  const managementCheck = location.pathname.match("Management");

  // ===============Logout function===============
  function Logout() {
    dispatch(deleteUserDetail());
    navigate("/");
  }
  // ============API for fetch permission who are authorized or not============
  useEffect(() => {
    async function fetchStaffpermission() {
      await MembersSublyApi.staffPermission(token, staffId)
        .then((response) => {
          if (response.statusCode == 200) {
            dispatch(fetchStaffAuth(response.data.authority));
            setPermissionKey(response.data.authority);
            dispatch(messageCount(response.data.unreadMessageCount));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchStaffpermission();
  }, [updateModal && updateModal]);

  useEffect(() => {
    dispatch(chatIdHandle({ response: "", status: false }));
    dispatch(adminPanelHandle(true));
  }, []);

  return (
    <>
      <div className=" max-[1200px]:hidden min-h-screen bg-box_bg  h-full max-lg:h-full relative">
        <div className="py-3 flex items-center gap-3 border-b border-[#444444] px-6">
          <img src={logo} className="w-32 h-[50px]" />
        </div>
        <div className="px-6 mt-8 manageActive my-4">
          {sideBarMenu.map((items, index) => (
            <>
              {" "}
              {(!items?.key || staffAuthRespo[items?.key]) && (
                <NavLink
                  className={`${
                    items.path.match(statisticCheck) ||
                    items.path.match(ptInformationCheck) ||
                    items.path.match(boardCheck) ||
                    items.path.match(managementCheck) ||
                    items.path.match(settingCheck)
                      ? "active"
                      : ""
                  } ${"no-underline"}`}
                  to={
                    !items?.key || staffAuthRespo[items?.key]
                      ? items.path
                      : items?.backUpPath
                  }
                  key={index}
                >
                  <div className="flex items-center justify-between gap-2 cursor-pointer">
                    <div className="flex items-center gap-2 my-3">
                      <img
                        src={items.activeIcon}
                        alt="active-icon"
                        className="w-[23px] h-[23px] hideClass"
                      />
                      <img
                        src={items.icon}
                        alt="icon"
                        className="w-[23px] h-[23px] activeClass"
                      />
                      <p className="mb-0 text-lg text-[#969697] font-bold">
                        {items.name}
                      </p>
                    </div>
                    {items.isOption && (
                      <>
                        <Icon
                          icon="iconamoon:arrow-up-2"
                          width="30"
                          height="30"
                          style={{ color: "#969697" }}
                          className="activeClass"
                        />
                        <Icon
                          icon="iconamoon:arrow-down-2"
                          width="30"
                          height="30"
                          style={{ color: "#fff" }}
                          className="hideClass"
                        />
                      </>
                    )}
                  </div>
                </NavLink>
              )}
              {items.isOption &&
                location.pathname.includes("Statistics") &&
                items.path.match(statisticCheck) && (
                  <div className="list-disc pl-0 mb-0 subOptionClass">
                    {statisticSubOption.map(
                      (val, index) =>
                        (!val?.key || staffAuthRespo[val?.key]) && (
                          <NavLink
                            className="no-underline"
                            to={val.path}
                            key={index}
                          >
                            <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                              <Icon
                                icon="codicon:debug-stackframe-dot"
                                width="25"
                                height="25"
                              />{" "}
                              {val.name}{" "}
                            </p>
                          </NavLink>
                        )
                    )}
                  </div>
                )}
              {items.isOption &&
                location.pathname.includes("PT-information") &&
                items.path.match(ptInformationCheck) && (
                  <div className="list-disc pl-0 mb-0 subOptionClass">
                    {ptInformationSubOption.map(
                      (val, index) =>
                        (!val?.key || staffAuthRespo[val?.key]) && (
                          <NavLink
                            className="no-underline"
                            to={val.path}
                            key={index}
                          >
                            <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                              <Icon
                                icon="codicon:debug-stackframe-dot"
                                width="25"
                                height="25"
                              />{" "}
                              {val.name}{" "}
                            </p>
                          </NavLink>
                        )
                    )}
                  </div>
                )}
              {items.isOption &&
                location.pathname.includes("Board") &&
                items.path.match(boardCheck) && (
                  <div className="list-disc pl-0 mb-0 subOptionClass">
                    {boardSubOption.map(
                      (val, index) =>
                        (!val?.key || staffAuthRespo[val?.key]) && (
                          <NavLink
                            className="no-underline"
                            to={val.path}
                            key={index}
                          >
                            <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                              <Icon
                                icon="codicon:debug-stackframe-dot"
                                width="25"
                                height="25"
                              />{" "}
                              {val.name}{" "}
                            </p>
                          </NavLink>
                        )
                    )}
                  </div>
                )}
              {items.isOption &&
                location.pathname.includes("Gym-setting") &&
                items.path.match(settingCheck) && (
                  <div className="list-disc pl-0 mb-0 subOptionClass">
                    {gymSettingSubOption.map(
                      (val, index) =>
                        (!val?.key || staffAuthRespo[val?.key]) && (
                          <>
                            {" "}
                            <NavLink
                              className={`${
                                val.path.match(gymSettingCheck) ? "active" : ""
                              } ${"no-underline"}`}
                              to={val.path}
                              key={index}
                            >
                              <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                                <Icon
                                  icon="codicon:debug-stackframe-dot"
                                  width="25"
                                  height="25"
                                />{" "}
                                {val.name}{" "}
                              </p>
                            </NavLink>
                            {location.pathname.includes("Gym-settings") && (
                              <div className="gymSettngOptionClass ml-auto mr-0 w-[85%] mt-1.5 mb-7">
                                {location.pathname.includes("Gym-settings") &&
                                  val.path.match(gymSettingCheck) &&
                                  gymSettingsSubOption.map((val, index) => (
                                    <NavLink
                                      className={` ${"no-underline"}`}
                                      to={val.path}
                                      key={index}
                                    >
                                      <p className="py-1.5 mb-0 pl-2 text-base text-white font-bold flex items-center gap-2">
                                        <Icon
                                          icon="carbon:triangle-right-solid"
                                          width="14"
                                          height="14"
                                        />{" "}
                                        {val.name}{" "}
                                      </p>
                                    </NavLink>
                                  ))}
                              </div>
                            )}
                          </>
                        )
                    )}
                  </div>
                )}
              {items.isOption &&
                location.pathname.includes("Management") &&
                items.path.match(managementCheck) && (
                  <div className="list-disc pl-0 mb-0 subOptionClass">
                    {managementSubOption.map(
                      (val, index) =>
                        (!val?.key || staffAuthRespo[val?.key]) && (
                          <NavLink
                            className="no-underline"
                            to={val.path}
                            key={index}
                          >
                            <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                              <Icon
                                icon="codicon:debug-stackframe-dot"
                                width="25"
                                height="25"
                              />{" "}
                              {val.name}{" "}
                            </p>
                          </NavLink>
                        )
                    )}
                  </div>
                )}
            </>
          ))}
          <p
            onClick={() => {
              Logout();
            }}
            className="mb-0 my-3 text-base font-bold text-white flex items-center gap-2 cursor-pointer"
          >
            <Icon
              icon="line-md:logout"
              width="25"
              height="25"
              style={{ color: "#BEBEBE" }}
            />{" "}
            {strings.LOGOUT}
          </p>
        </div>
        <p className="fixed bottom-0 left-5 font-bold text-[#909090]">Version: 0.0.7</p>
      </div>
      <Accordion className="max-[1200px]:block hidden">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="border-b border-[#444444]">
            <div className="py-3 flex items-center gap-3">
              <img src={logo} className="w-32 h-[50px]" />
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="px-6 manageActive my-3">
              {sideBarMenu.map((items, index) => (
                <>
                  {(!items?.key || staffAuthRespo[items?.key]) && (
                    <NavLink
                      className={`${
                        items.path.match(statisticCheck) ||
                        items.path.match(ptInformationCheck) ||
                        items.path.match(boardCheck) ||
                        items.path.match(managementCheck) ||
                        items.path.match(settingCheck)
                          ? "active"
                          : ""
                      } ${"no-underline"}`}
                      to={
                        !items?.key || staffAuthRespo[items?.key]
                          ? items.path
                          : items?.backUpPath
                      }
                      key={index}
                    >
                      <div className="flex items-center justify-between gap-2 cursor-pointer">
                        <div className="flex items-center gap-2 my-3">
                          <img
                            src={items.activeIcon}
                            alt="icon"
                            className="w-[23px] h-[23px] hideClass"
                          />
                          <img
                            src={items.icon}
                            alt="icon"
                            className="w-[23px] h-[23px] activeClass"
                          />
                          <p className="mb-0 text-lg text-[#969697] font-bold">
                            {items.name}
                          </p>
                        </div>
                        {items.isOption && (
                          <>
                            <Icon
                              icon="iconamoon:arrow-up-2"
                              width="30"
                              height="30"
                              style={{ color: "#969697" }}
                              className="activeClass"
                            />
                            <Icon
                              icon="iconamoon:arrow-down-2"
                              width="30"
                              height="30"
                              style={{ color: "#fff" }}
                              className="hideClass"
                            />
                          </>
                        )}
                      </div>
                    </NavLink>
                  )}
                  {items.isOption &&
                    location.pathname.includes("Statistics") &&
                    items.path.match(statisticCheck) && (
                      <div className="list-disc pl-0 mb-0 subOptionClass">
                        {statisticSubOption.map(
                          (val, index) =>
                            (!val?.key || staffAuthRespo[val?.key]) && (
                              <NavLink
                                className="no-underline"
                                to={val.path}
                                key={index}
                              >
                                <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                                  <Icon
                                    icon="codicon:debug-stackframe-dot"
                                    width="25"
                                    height="25"
                                  />{" "}
                                  {val.name}{" "}
                                </p>
                              </NavLink>
                            )
                        )}
                      </div>
                    )}
                  {items.isOption &&
                    location.pathname.includes("PT-information") &&
                    items.path.match(ptInformationCheck) && (
                      <div className="list-disc pl-0 mb-0 subOptionClass">
                        {ptInformationSubOption.map(
                          (val, index) =>
                            (!val?.key || staffAuthRespo[val?.key]) && (
                              <NavLink
                                className="no-underline"
                                to={val.path}
                                key={index}
                              >
                                <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                                  <Icon
                                    icon="codicon:debug-stackframe-dot"
                                    width="25"
                                    height="25"
                                  />{" "}
                                  {val.name}{" "}
                                </p>
                              </NavLink>
                            )
                        )}
                      </div>
                    )}
                  {items.isOption &&
                    location.pathname.includes("Board") &&
                    items.path.match(boardCheck) && (
                      <div className="list-disc pl-0 mb-0 subOptionClass">
                        {boardSubOption.map(
                          (val, index) =>
                            (!val?.key || staffAuthRespo[val?.key]) && (
                              <NavLink
                                className="no-underline"
                                to={val.path}
                                key={index}
                              >
                                <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                                  <Icon
                                    icon="codicon:debug-stackframe-dot"
                                    width="25"
                                    height="25"
                                  />{" "}
                                  {val.name}{" "}
                                </p>
                              </NavLink>
                            )
                        )}
                      </div>
                    )}
                  {items.isOption &&
                    location.pathname.includes("Gym-setting") &&
                    items.path.match(settingCheck) && (
                      <div className="list-disc pl-0 mb-0 subOptionClass">
                        {gymSettingSubOption.map(
                          (val, index) =>
                            (!val?.key || staffAuthRespo[val?.key]) && (
                              <>
                                {" "}
                                <NavLink
                                  className={`${
                                    val.path.match(gymSettingCheck)
                                      ? "active"
                                      : ""
                                  } ${"no-underline"}`}
                                  to={val.path}
                                  key={index}
                                >
                                  <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                                    <Icon
                                      icon="codicon:debug-stackframe-dot"
                                      width="25"
                                      height="25"
                                    />{" "}
                                    {val.name}{" "}
                                  </p>
                                </NavLink>
                                {location.pathname.includes("Gym-settings") && (
                                  <div className="gymSettngOptionClass ml-auto mr-0 w-[96%] mt-1.5 mb-7">
                                    {location.pathname.includes(
                                      "Gym-settings"
                                    ) &&
                                      val.path.match(gymSettingCheck) &&
                                      gymSettingsSubOption.map((val, index) => (
                                        <NavLink
                                          className={` ${"no-underline"}`}
                                          to={val.path}
                                          key={index}
                                        >
                                          <p className="py-1.5 mb-0 pl-2 text-base text-white font-bold flex items-center gap-2">
                                            <Icon
                                              icon="carbon:triangle-right-solid"
                                              width="14"
                                              height="14"
                                            />{" "}
                                            {val.name}{" "}
                                          </p>
                                        </NavLink>
                                      ))}
                                  </div>
                                )}
                              </>
                            )
                        )}
                      </div>
                    )}
                  {items.isOption &&
                    location.pathname.includes("Management") &&
                    items.path.match(managementCheck) && (
                      <div className="list-disc pl-0 mb-0 subOptionClass">
                        {managementSubOption.map(
                          (val, index) =>
                            (!val?.key || staffAuthRespo[val?.key]) && (
                              <NavLink
                                className="no-underline"
                                to={val.path}
                                key={index}
                              >
                                <p className="my-2 pl-2 text-base text-white font-bold flex items-center gap-2">
                                  <Icon
                                    icon="codicon:debug-stackframe-dot"
                                    width="25"
                                    height="25"
                                  />{" "}
                                  {val.name}{" "}
                                </p>
                              </NavLink>
                            )
                        )}
                      </div>
                    )}
                </>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default SideBar;
