import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import demoP from "../../../assests/images/demoProfile.svg";
import ManagementSublyApi from "../../../helper/ManagementApi";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { InputGroup } from "react-bootstrap";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import { formatPrice } from "../../../utils/commonUtils";
import { chatIdHandle } from "../../../Store/slices/AuthSlice";
import $ from "jquery";

function RefundModal({
  id,
  setshowModal,
  setactiveIndex,
  setIsLoader,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [isError, setIsError] = useState("");
  const [eventType, setEventType] = useState({
    text: "Will feedback in 1 day",
    type: "1",
  });
  const [openType, setOpenType] = useState(false);
  const [detailValue, setDetailValue] = useState("");
  const [chatBy, setChatBy] = useState({ text: "By chat", type: "1" });
  const [openChatBy, setopenChatBy] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [changeValue, setChangeValue] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const dispatch = useDispatch();

  // =======Api to get refund requested member id=======
  async function fetchChatId(memberId, id) {
    const requested = { memberId: memberId, refundRequestId: id };
    await ManagementSublyApi.getChatId(token, requested)
      .then((response) => {
        if (response.statusCode == 200) {
          dispatch(chatIdHandle({ response: response.data, status: true }));
        }
      })
      .catch((error) => console.log(error));
  }

  // ============API for fetching refund request detail============
  useEffect(() => {
    async function getRefundDetails() {
      setIsLoader(true);
      await ManagementSublyApi.fetchRefundDetail(token, id)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setDetailValue(response.data);
            if (response.data?.reqFeedbackType == 1) {
              setChatBy({ text: "By chat", type: "1" });
            } else {
              setChatBy({ text: "By cellphone", type: "0" });
            }
            if (response.data?.feedbackDays === 1) {
              setEventType({ text: "Will feedback in 1 day", type: "1" });
            } else if (response.data?.feedbackDays === 3) {
              setEventType({ text: "Will feedback in 3 days", type: "3" });
            } else {
              setEventType({ text: "Will feedback in 5 days", type: "5" });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getRefundDetails();
  }, []);

  // =============API for edit and update refund request response============
  async function updateRefund() {
    setIsLoader(true);
    const requestData = {
      requestId: id,
      status: changeValue,
      reqFeedbackType: chatBy.type,
      feedbackDays: eventType.type,
    };
    await ManagementSublyApi.editRefundReq(token, requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setshowModal(false);
          setactiveIndex("");
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setOpenType(false);
      setopenChatBy(false);
    }
  });

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[70%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-extrabold pb-4">
              {strings.REFUND_FOR} {detailValue?.itemName}
            </p>
          </div>
          <h3 className="font-bold text-[34px] text-center my-8">
            {detailValue?.userName}
          </h3>
          <div className="flex flex-wrap items-center justify-center gap-8">
            <div className="flex justify-between items-center">
              <Icon
                icon="lets-icons:date-today"
                width="30px"
                height="30px"
                style={{ color: "#CCFF00" }}
              />

              <span className="text-2xl font-extrabold ms-2">
                {moment(detailValue?.regDate).format("YYYY.MM.DD")}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <Icon
                icon="heroicons:clock"
                width="30px"
                height="30px"
                style={{ color: "#CCFF00" }}
              />
              <span className="text-2xl font-extrabold ms-2">
                {moment(detailValue?.regDate).format("h:MM")}{" "}
                <span className="text-lg font-bold">
                  {" "}
                  {moment(detailValue?.regDate).format("A")}
                </span>{" "}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <Icon
                icon="lucide:eye"
                width="30px"
                height="30px"
                style={{ color: "#CCFF00" }}
              />
              <span className="text-2xl font-extrabold ms-2">
                {detailValue?.views || 0}
              </span>
            </div>
          </div>
          <div className="mt-5  pb-4">
            {isEdit ? (
              <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
                <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                  <p className="mb-0 flex items-center flex-wrap gap-x-3 text-xl font-bold">
                    {strings.CHANGE_STATUS_OF}:
                    <div className="flex items-center gap-x-5 flex-wrap">
                      {" "}
                      <div className="flex items-center ">
                        {" "}
                        <InputGroup className="checkboxClass paddingManage !p-0">
                          <InputGroup.Checkbox
                            className="w-[31px] h-[31px] !bg-[#353638] !p-0"
                            value={0}
                            checked={changeValue == 0 ? true : false}
                            onChange={(e) => {
                              setChangeValue(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p className="mb-0 text-[22px] font-bold">
                          {strings.REQUESTING}
                        </p>
                      </div>
                      <div className="flex items-center gap-x-2.5 flex-wrap">
                        {" "}
                        <div className="flex items-center ">
                          <InputGroup className="checkboxClass paddingManage !p-0">
                            <InputGroup.Checkbox
                              className="w-[31px] h-[31px] !bg-[#353638] !p-0"
                              value={3}
                              checked={changeValue == 3 ? true : false}
                              onChange={(e) => {
                                setChangeValue(e.target.value);
                              }}
                            />
                          </InputGroup>
                          <p className="mb-0 text-[22px] font-bold">
                            {strings.ON_GOING}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2.5 flex-wrap">
                        {" "}
                        <div className="flex items-center ">
                          <InputGroup className="checkboxClass paddingManage !p-0">
                            <InputGroup.Checkbox
                              className="w-[31px] h-[31px] !bg-[#353638] !p-0"
                              value={2}
                              checked={changeValue == 2 ? true : false}
                              onChange={(e) => {
                                setChangeValue(e.target.value);
                              }}
                            />
                          </InputGroup>
                          <p className="mb-0 text-[22px] font-bold">
                            {strings.FINISHED}
                          </p>
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
                <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                  <p className="mb-0 flex items-center gap-2 text-2xl flex-wrap font-extrabold">
                    {strings.REQUESTED_BY_THE_MEMBER} :
                    <span className="font-bold text-2xl">
                      {detailValue?.status == 2
                        ? strings.FINISHED
                        : detailValue?.status == 3
                        ? strings.ON_GOING
                        : strings.REQUESTING}
                    </span>
                  </p>
                </div>
              </div>
            )}
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div
                className={`flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3 px-4 w-full h-full ${
                  !isEdit ? "bg-[#414142]" : ""
                }`}
              >
                <p className="mb-0 flex items-center gap-2 text-2xl flex-wrap font-extrabold">
                  {strings.PURCHASED_DATE}{" "}
                  <span className="font-bold text-2xl">
                    {moment(detailValue?.purchaseDate).format("YYYY.MM.DD")}
                  </span>
                  {/* {isEdit && (
                <div className="w-[3px] bg-[#ffffff] h-[30px] mr-[130px] rounded-lg ml-[20px]"></div>
              )} */}
                </p>
              </div>
              {isEdit && (
                <div className="w-[3px] bg-[#ffffff] h-[30px] mr-[130px] rounded-lg"></div>
              )}
              <div
                className={`flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3 px-4 w-full h-full ${
                  !isEdit ? "bg-[#414142]" : ""
                }`}
              >
                <p className="mb-0 flex items-center gap-2 text-2xl flex-wrap font-extrabold">
                  Left date :
                  <span className="font-bold text-2xl">
                    {detailValue?.dayLeft > 0
                      ? detailValue?.dayLeft
                      : strings.EXPIRED}{" "}
                    {detailValue?.dayLeft > 0 ? `days` : ""}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div
                className={`flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3 px-4 w-full h-full ${
                  !isEdit ? "bg-[#414142]" : ""
                }`}
              >
                <p className="mb-0 flex items-center gap-2 text-2xl flex-wrap font-bold">
                  {strings.PURCHASED_PRICE}{" "}
                  <span className="font-bold text-2xl">
                    ₩{formatPrice(detailValue?.price)}
                  </span>
                </p>
              </div>
            </div>

            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              <div
                className={`flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3 px-4 w-full h-full ${
                  !isEdit ? "bg-[#414142]" : ""
                }`}
              >
                <p className="mb-0 flex items-center gap-2 text-2xl flex-wrap font-extrabold">
                  <span>{strings.REQUESTED_FEEDBACK_METHOD}</span>
                  <span className="font-bold text-2xl">
                    {detailValue?.reqFeedbackType === 1 ? (
                      <>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            fetchChatId(detailValue?.memberId, detailValue?.id);
                          }}
                        >
                          <Icon
                            icon="fluent:chat-12-filled"
                            width="25px"
                            height="25px"
                            style={{
                              color: "#CCFF00",
                              marginLeft: "8px",
                              marginBottom: "-4px",
                            }}
                          />
                        </button>{" "}
                        Chat
                      </>
                    ) : (
                      <>
                        Cellphone ({" "}
                        <Icon
                          icon="ic:sharp-call"
                          width="25px"
                          height="25px"
                          style={{
                            color: "#CCFF00",
                            marginBottom: "-6px",
                            marginLeft: "-2px",
                            marginRight: "6px",
                          }}
                        />
                        {detailValue?.cellphone})
                      </>
                    )}
                  </span>
                </p>
              </div>
            </div>

            <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5">
              {detailValue?.feedbackDays ? (
                <div
                  className={`flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3 px-4 w-full h-full ${
                    !isEdit ? "bg-[#414142]" : ""
                  }`}
                >
                  {detailValue?.feedbackDays === 1 ||
                  detailValue?.feedbackDays === 3 ||
                  detailValue?.feedbackDays === 5 ? (
                    <p className="mb-0 flex items-center gap-2 text-2xl flex-wrap font-bold">
                      {strings.ACTION_STATUS} :
                      <span className="font-bold text-2xl">
                        {detailValue?.feedbackDays === 1
                          ? "Will feedback in 1 day"
                          : detailValue?.feedbackDays === 3
                          ? "Will feedback in 3 days"
                          : "Will feedback in 5 days"}
                      </span>
                    </p>
                  ) : null}
                </div>
              ) : (
                ""
              )}
            </div>

            {/* <div className="flex items-center gap-2.5 max-lg:flex-wrap my-2.5 pb-2.5">
              <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] bg-[#414142] py-3 px-4 w-full h-full">
                <p className="mb-0 flex items-center gap-2 text-2xl flex-wrap font-extrabold">
                  <span> {strings.REQUESTED_FEEDBACK_METHOD} </span>
                  <span className="font-bold text-2xl">
                    {detailValue?.reqFeedbackType == 1 ? "Chat" : "Cellphone"}
                  </span>
                </p>
              </div>
            </div> */}
            {detailValue?.status !== 0 && !isEdit && (
              <>
                {" "}
                <div className="flex items-center gap-2.5 max-lg:flex-wrap mt-2.5 mb-0 border-t-2 border-[#535354]">
                  <div className="flex items-start gap-x-3 gap-y-2.5 max-sm:flex-wrap rounded-[10px] py-3    w-full h-full">
                    <div className="mb-0 flex items-center max-sm:flex-wrap gap-2 text-xl font-bold">
                      {detailValue?.status == 3 ? (
                        <p className="mb-0 flex items-center gap-2 text-xl font-bold">
                          Process date :
                        </p>
                      ) : (
                        <p className="mb-0 flex items-center gap-2 text-xl font-bold">
                          Finished date :
                        </p>
                      )}
                      <div className="font-extrabold text-2xl">
                        <div className="flex flex-wrap items-center justify-center gap-x-8 gap-y-3">
                          <div className="flex justify-between items-center">
                            <Icon
                              icon="lets-icons:date-today"
                              width="30"
                              height="30"
                              style={{ color: "white" }}
                            />

                            <span className="text-2xl font-extrabold ms-2">
                              {moment(detailValue?.resFeedbackDate).format(
                                "YYYY.MM.DD"
                              )}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <Icon
                              icon="heroicons:clock"
                              width="30px"
                              height="30px"
                              style={{ color: "white" }}
                            />

                            <span className="text-2xl font-extrabold ms-2">
                              {moment(detailValue?.resFeedbackDate).format(
                                "hh:mm"
                              )}{" "}
                              <span className="text-lg font-bold">
                                {" "}
                                {moment(detailValue?.resFeedbackDate).format(
                                  "A"
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2.5 max-lg:flex-wrap pb-3">
                  <div className="flex items-center gap-x-3  max-sm:flex-wrap rounded-[10px]    w-full h-full">
                    {detailValue?.status == 3 ? (
                      <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-xl font-bold">
                        Process staff:
                      </p>
                    ) : (
                      <p className="mb-0 flex items-center gap-2 max-sm:flex-wrap text-xl font-bold">
                        Finished staff:
                      </p>
                    )}
                    <div className="flex items-center">
                      <img
                        className="size-[34px] rounded-full"
                        src={detailValue?.staffInfo?.mProfileImg || demoP}
                        alt=""
                      />
                      <span className="ms-2 text-xl font-bold">
                        {detailValue?.staffName}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {isEdit && (
              <div className=" border-t-2 border-[#535354]">
                <h1 className="text-[34px] font-extrabold mt-3 mb-3.5">
                  Action
                </h1>
                <div className="flex flex-wrap items-center gap-2.5">
                  <div className="w-full sm:flex-1">
                    <label
                      htmlFor="nameId"
                      onClick={() => {
                        setOpenType(!openType);
                        setopenChatBy(false);
                      }}
                      className={`text-[20px] font-bold text-white 
                ${isError && !eventType.type ? "inputErrorClass" : ""} 
                ${" w-full mb-2.5 relative dropdownClass"}`}
                    >
                      <div className="flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[60px] px-3 errorBorder">
                        <input
                          type="text"
                          className="text-white bg-[#414142] !border-0"
                          id="nameId"
                          value={eventType.text}
                          disabled
                          placeholder={placeholder.SELECT_EVENT_TYPE}
                        />
                        {openType ? (
                          <Icon
                            icon="flowbite:caret-up-solid"
                            width="20"
                            height="20"
                            style={{ color: "#BEBEBE" }}
                          />
                        ) : (
                          <Icon
                            icon="flowbite:caret-down-solid"
                            width="20"
                            height="20"
                            style={{ color: "#BEBEBE" }}
                          />
                        )}
                      </div>
                      {openType && (
                        <ProductFilter setEventType={setEventType} />
                      )}
                    </label>
                    <label htmlFor=""></label>
                  </div>
                  <div className="w-full sm:flex-1">
                    <label
                      htmlFor="nameId"
                      onClick={() => {
                        setopenChatBy(!openChatBy);
                        setOpenType(false);
                      }}
                      className={`text-[20px] font-bold text-white 
                ${isError && !eventType.type ? "inputErrorClass" : ""} 
                ${" w-full mb-2.5 relative dropdownClass"}`}
                    >
                      <div className="flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[60px] px-3 errorBorder">
                        <input
                          type="text"
                          className="text-white bg-[#414142] !border-0"
                          id="nameId"
                          value={chatBy.text}
                          disabled
                          placeholder={placeholder.SELECT_EVENT_TYPE}
                        />
                        {openChatBy ? (
                          <Icon
                            icon="flowbite:caret-up-solid"
                            width="20"
                            height="20"
                            style={{ color: "#BEBEBE" }}
                          />
                        ) : (
                          <Icon
                            icon="flowbite:caret-down-solid"
                            width="20"
                            height="20"
                            style={{ color: "#BEBEBE" }}
                          />
                        )}
                      </div>
                      {openChatBy && <ByChat setChatBy={setChatBy} />}
                    </label>
                    <label htmlFor=""></label>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-3 mb-4"}`}
          >
            <button
              onClick={() => {
                setshowModal(false);
                setactiveIndex("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
            {isEdit ? (
              <button
                onClick={() => {
                  updateRefund();
                }}
                className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {strings.CONFIRM}
              </button>
            ) : (
              <button
                onClick={() => {
                  setIsEdit(true);
                  setChangeValue(detailValue?.status);
                }}
                className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {strings.EDIT}
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default RefundModal;

const actionList = [
  {
    text: "Will feedback in 1 day",
    value: "1",
  },
  {
    text: "Will feedback in 3 day",
    value: "3",
  },
  {
    text: "Will feedback in 5 day",
    value: "5",
  },
];

const byList = [
  {
    text: "By chat",
    value: "1",
  },
  {
    text: "By cellphone",
    value: "0",
  },
];

function ProductFilter({ setEventType }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[280px] right-0 top-18 max-h-[280px] overflow-auto z-10 px-4 py-1">
      {actionList.map((val, index) => (
        <div
          key={index}
          onClick={() => {
            setEventType({ text: val.text, type: val.value });
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
        >
          <span className="mb-0 text-lg font-bold">{val.text}</span>
        </div>
      ))}
    </div>
  );
}

function ByChat({ setChatBy }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[170px] right-0 top-18 max-h-[280px] overflow-auto z-10 px-4 py-1">
      {byList.map((val, index) => (
        <div
          key={index}
          onClick={() => {
            setChatBy({ text: val.text, type: val.value });
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
        >
          <span className="mb-0 text-lg font-bold">{val.text}</span>
        </div>
      ))}
    </div>
  );
}
