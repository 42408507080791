import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import Header from "../../commonComponents/Header";
import SideBar from "../../commonComponents/SideBar";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import moment from "moment";
import ReactPaginate from "react-paginate";
import RegistrationForm from "./RegistrationForm";
import { useSelector } from "react-redux";
import BoardSublyApi from "../../../helper/BoardApi";
import Loader from "../../../utils/Loader";
import GeneralDetailScreen from "./GeneralDetailScreen";
import $ from "jquery";

function SuggestionsLog() {
  const type = 1;
  const { token } = useSelector((state) => state.user);
  const [isLoader, setIsLoader] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [monthValue, setMonthValue] = useState({ text: "All" });
  const [titleValue, setTitleValue] = useState({
    text: "Title",
    value: "title",
  });
  const [openForm, setOpenForm] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [boardId, setBoardId] = useState({ id: "" });
  const [openDetail, setOpenDetail] = useState(false);
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [noticeList, setNoticeList] = useState("");
  const [editedValue, setEditedValue] = useState("");

  useEffect(() => {
    if (!openForm && !openDetail) {
      boardListHandle();
    }
  }, [offset, openForm, openDetail]);
  async function boardListHandle(durationValue, page) {
    setIsLoader(true);
    await BoardSublyApi.fetchBoardList(
      type,
      token,
      page ? page : offset,
      titleValue.value,
      search,
      durationValue
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode === 200) {
          setNoticeList(response.data || []);
          setPage(response.data.count / 20);
          boardId({ id: "" });
          page && setOffset(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setOpenDetail(false);
      setOpenForm(false);
      setBoardId("");
      setEditedValue("");
      setOpenMonth(false);
      setOpenTitle(false);
      setIsEdit("");
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col xl={10} className="p-0 relative overflow-auto">
            <Header text={strings.GYM_BOARD_SUGGESTIONS} currentTime={true} />
            <div className="mx-6 ">
              <div className="mt-4 mb-7 flex items-center justify-between gap-3 flex-wrap">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <Icon
                      icon="lets-icons:date-today"
                      width="33"
                      height="33"
                      style={{ color: "#CCFF00" }}
                    />
                    <p className="mb-0 text-[24px] font-extrabold">
                      {moment(new Date()).format("YYYY.MM.DD")}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Icon
                      icon="ion:time-outline"
                      width="33"
                      height="33"
                      style={{ color: "#CCFF00" }}
                    />
                    <p className="mb-0 text-[24px] font-extrabold">
                      {moment().format("h:mm")}
                      <span className="text-white text-lg font-bold">
                        {moment().format("A")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-x-3 gap-y-3 flex-wrap">
                  <label
                    onClick={() => {
                      setOpenMonth(!openMonth);
                      setOpenTitle(false);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 cursor-pointer w-full text-[#fff] font-bold text-lg whitespace-nowrap">
                      {monthValue.text}{" "}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={openMonth ? 2 : 4}
                    />
                    {openMonth && (
                      <MonthFilter
                        setMonthValue={setMonthValue}
                        boardListHandle={boardListHandle}
                      />
                    )}
                  </label>
                  <label
                    onClick={() => {
                      setOpenTitle(!openTitle);
                      setOpenMonth(false);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                      {titleValue.text}{" "}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={openTitle ? 2 : 4}
                    />
                    {openTitle && <TitleFilter setTitleValue={setTitleValue} />}
                  </label>
                  <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                    <input
                      type="text"
                      placeholder="Keywords"
                      className="bg-transparent w-full text-white text-lg font-bold"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </label>
                  <button
                    className="hover:text-black hover:bg-[#CCFF00] text-lg font-extrabold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                    onClick={() => {
                      setOffset(1);
                      boardListHandle();
                    }}
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              {noticeList?.boardData == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              {noticeList?.boardData?.length > 0 && (
                <div className="overflow-auto">
                  <div className="max-2xl:w-[1300px] suggestiontableClass overflow-auto max-h-[calc(100vh_-_310px)] relative">
                    <Table className="w-full !bg-transparent">
                      <thead className="bg-[#353638] sticky top-[-1px]">
                        <th className="!text-center text-[20px] font-semibold text-white w-[10%] ">
                          {strings.ID}
                        </th>
                        <th className="!text-center text-[20px] px-4 font-semibold text-white w-[38%] ">
                          {strings.TITLE}
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[13%] ">
                          {strings.TYPE}
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[13%] ">
                          {placeholder.NAME}
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[13%] ">
                          {strings.DATE}
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[13%] ">
                          {strings.VIEWS}
                        </th>
                      </thead>

                      <tbody className="">
                        {noticeList?.boardData?.map((items, index) => (
                          <tr
                            onClick={() => {
                              setBoardId({ id: items.id });
                              setOpenDetail(true);
                            }}
                            key={index}
                            className={`${"cursor-pointer"}`}
                          >
                            <td
                              align="center"
                              className={` 
                          ${"text-white font-bold text-lg relative"}`}
                            >
                              {20 * (offset - 1) + (index + 1)}
                            </td>
                            <td
                              align="start"
                              className="!text-start text-[18px] px-4 font-bold text-white "
                            >
                              {items.title}{" "}
                              <span className="text-white font-xl font-extrabold">
                                {items?.commentCounts > 0 &&
                                  `(${items?.commentCounts})`}
                              </span>
                              {items?.lastComment && (
                                <div className="flex items-start gap-2">
                                  <Icon
                                    icon="f7:arrowshape-turn-up-right-fill"
                                    width="20"
                                    height="20"
                                    style={{ color: "#DBDBDB" }}
                                  />

                                  <div className="flex items-start gap-3">
                                    <p className="mb-0 text-base font-extrabold text-[#CCFF00]">
                                      {strings.REPLY}
                                    </p>
                                    <p className="mb-0 text-lg font-bold mt-0">
                                      {items.lastComment}{" "}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="!text-center text-[18px] font-bold text-white ">
                              {items.suggestionType}
                            </td>
                            <td className="!text-center text-[18px] font-bold text-white ">
                              {items?.gymStaffInfo?.gymStaffName}
                            </td>
                            <td className="!text-center text-[18px] font-bold text-white ">
                              <p className="mb-0 text-lg font-bold">
                                {moment(items.regDate).format("YY.MM.DD")}
                              </p>
                            </td>
                            <td className="!text-center text-[18px] font-bold text-white ">
                              {items.views || 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
              <div className="flex items-center gap-1 mt-2 mb-2 py-3.5 border-b border-[#444444]">
                <div className="w-full">
                  {page && page > 1 && (
                    <div className="mt-3">
                      {" "}
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        forcePage={offset == 1 ? 0 : offset - 1}
                        pageCount={page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
                <button
                  onClick={() => {
                    setOpenForm(true);
                  }}
                  className="text-black bg-[#CCFF00] text-lg font-extrabold rounded-[30px] h-[50px] w-[160px]"
                >
                  {strings.WRITE}
                </button>
              </div>
            </div>
            {openForm && (
              <RegistrationForm
                setOpenForm={setOpenForm}
                setIsLoader={setIsLoader}
                isEdit={isEdit}
                id={boardId.id}
                setIsEdit={setIsEdit}
                editedValue={editedValue}
                setBoardId={setBoardId}
                type={type}
                suggestionDetails={true}
                head={`${isEdit ? "Edit Suggestion" : "Write Suggestion"}`}
                classNameValue={`${openForm ? "block_notify" : "hideNotify"}`}
              />
            )}
            {openDetail && (
              <GeneralDetailScreen
                setIsLoader={setIsLoader}
                setOpenDetail={setOpenDetail}
                id={boardId.id}
                setBoardId={setBoardId}
                setOpenForm={setOpenForm}
                setIsEdit={setIsEdit}
                setEditedValue={setEditedValue}
                type={type}
                classNameValue={`${openDetail ? "block_notify" : "hideNotify"}`}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SuggestionsLog;

function MonthFilter({ setMonthValue, boardListHandle }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setMonthValue({ text: "All" });
          boardListHandle("all", 1);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      <div
        onClick={() => {
          setMonthValue({ text: "1 week" });
          boardListHandle("week", 1);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ONE_WEEK}</span>
      </div>
      <div
        onClick={() => {
          setMonthValue({ text: "1 month" });
          boardListHandle("month", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ONE_MONTH}</span>
      </div>
      <div
        onClick={() => {
          setMonthValue({ text: "6 month" });
          boardListHandle("half_month", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.SIX_MONTH}</span>
      </div>
      <div
        onClick={() => {
          setMonthValue({ text: "1 year" });
          boardListHandle("year", 1);
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ONE_YEAR}</span>
      </div>
    </div>
  );
}

function TitleFilter({ setTitleValue }) {
  return (
    <div
      // style={{ border: "1px solid #686868" }}
      className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-14 max-h-[200px] overflow-auto z-10 px-3"
    >
      <div
        onClick={() => {
          setTitleValue({ text: "Title", value: "title" });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.TITLE}</span>
      </div>
      <div
        onClick={() => {
          setTitleValue({ text: "Content", value: "content" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.CONTENT}</span>
      </div>
      <div
        onClick={() => {
          setTitleValue({ text: "Title + Content", value: "title_content" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">
          {strings.TITLE} + {strings.CONTENT}
        </span>
      </div>
      <div
        onClick={() => {
          setTitleValue({ text: "Writer", value: "writer" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.WRITER}</span>
      </div>
    </div>
  );
}
