import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import memberIconGray from "../../../assests/icons/member-group gray.svg";
import { Icon } from "@iconify-icon/react";
import moment from "moment";
import { Table } from "react-bootstrap";
import { PRODUCT_TYPES } from "../../../utils/CommonData";
function HoldingTable({
  activeTab,
  setActiveTab,
  holdingValue,
  setOrderValue,
  setSortKey,
  fetchHoldingList,
  setProductId,
  productId,
  setshowModal,
}) {
  return (
    <div className="overflow-auto 2xl:max-h-[calc(100vh_-_300px)]">
      <div className="max-2xl:w-[1600px] staffListClass">
        <Table className="w-full !bg-transparent">
          <thead className="bg-[#353638] sticky top-[-1px] z-10">
            <th
              onClick={() => {
                setActiveTab(activeTab == 1 ? null : 1);
                let sort = activeTab == 1 ? "DESC" : "ASC";
                fetchHoldingList(sort, "productName");
                setSortKey(sort);
                setOrderValue("productName");
              }}
              className={`${"!text-center text-lg cursor-pointer !p-0 font-semibold text-white w-[25%] "}`}
            >
              <div
                className={`${
                  activeTab == 1 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white flex items-center ps-4 border-none"}`}
              >
                {strings.PRODUCT_NAME}
                <Icon
                  icon={`${
                    activeTab == 1
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 1 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 2 ? null : 2);
                let sort = activeTab == 2 ? "DESC" : "ASC";
                fetchHoldingList(sort, "name");
                setSortKey(sort);
                setOrderValue("name");
              }}
              className={`${"!text-start text-lg cursor-pointer !p-0 font-semibold text-white  w-[20%]"}`}
            >
              <div
                className={`${
                  activeTab == 2 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white flex items-center ps-4 border-none"}`}
              >
                {strings.NAME}
                <Icon
                  icon={`${
                    activeTab == 2
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 2 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 3 ? null : 3);
                let sort = activeTab == 3 ? "DESC" : "ASC";
                fetchHoldingList(sort, "date");
                setSortKey(sort);
                setOrderValue("date");
              }}
              className={`${"!text-center text-lg cursor-pointer !p-0 font-semibold text-white  w-[15%]"}`}
            >
              <div
                className={`${
                  activeTab == 3 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white  flex items-center justify-center ps-4 border-none"}`}
              >
                {strings.DATE}
                <Icon
                  icon={`${
                    activeTab == 3
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 3 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 4 ? null : 4);
                let sort = activeTab == 4 ? "DESC" : "ASC";
                fetchHoldingList(sort, "type");
                setSortKey(sort);
                setOrderValue("type");
              }}
              className={`${"!text-center text-lg cursor-pointer !p-0 font-semibold text-white  w-[20%]"}`}
            >
              <div
                className={`${
                  activeTab == 4 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white  flex items-center justify-center ps-4 border-none"}`}
              >
                {strings.TYPE}
                <Icon
                  icon={`${
                    activeTab == 4
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 4 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
          </thead>
          <tbody>
            {holdingValue.length > 0 &&
              holdingValue?.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    setProductId(item.id);
                    setshowModal(true);
                  }}
                  className={`${
                    productId == item.id
                      ? "kakaoClass !border-t-2 border-[#CCFF00]"
                      : "border-y border-[#444444]"
                  } ${"cursor-pointer"}`}
                >
                  <td
                    className={`${productId == item.id ? "!border-0" : ""} 
                        ${"text-white font-bold text-lg relative p-0 "}`}
                  >
                    <div className="!text-center text-lg font-bold  text-white px-2 py-1 ps-4    flex flex-col  items-start ">
                      <p className=" m-0">{item.productName}</p>
                      {/* {item.status == 1 && (
                        <p className=" mt-1 m-0 flex items-center">
                          <Icon
                            icon="mdi:tick"
                            width="22px"
                            height="22px"
                            style={{ color: "#CCFF00" }}
                          />
                          <span className="ms-1 text-[22px] font-extrabold">
                            {"(Finished)"}
                          </span>{" "}
                        </p>
                      )} */}
                    </div>
                  </td>
                  <td
                    className={`${
                      productId == item.id ? "!border-0" : ""
                    }  ${"!text-center text-[18px] p-0 font-bold text-white "}`}
                  >
                    <div className="!text-center text-lg font-bold   text-white px-2 py-1 ps-4    flex flex-col  items-start ">
                      <p className=" m-0">{item?.memberName}</p>
                      {/* {item.status == 1 && (
                        <p className=" mt-1 m-0 flex items-center">
                          <Icon
                            icon="oi:arrow-right"
                            width="22px"
                            height="22px"
                            style={{ color: "#CCFF00" }}
                          />
                          <span className="ms-1">{item.staffName}</span>{" "}
                        </p>
                      )} */}
                    </div>
                  </td>
                  <td
                    className={`${
                      productId == item.id ? "!border-0" : ""
                    }  ${"!text-center text-[18px] font-bold text-white "}`}
                  >
                    <div className="!text-center text-lg font-bold   text-white px-2 py-1     ">
                      <p className=" m-0">
                        {moment(item?.requestDate).format("YYYY.MM.DD")}
                      </p>
                      {/* {item?.resFeedbackDate && (
                        <p className="mb-0">
                          {moment(item?.resFeedbackDate).format("YYYY.MM.DD")}
                        </p>
                      )} */}
                    </div>
                  </td>
                  <td
                    className={`${
                      productId == item.id ? "!border-0" : ""
                    }  ${"!text-center text-[18px] font-bold text-white pl-3"}`}
                  >
                    {PRODUCT_TYPES[item?.typeValue]}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default HoldingTable;
