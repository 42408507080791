import React from "react";
import user from "../../assests/images/User-60.svg";
import { strings } from "../../utils/String";
import { Icon } from "@iconify-icon/react";
import Moment from "react-moment";
import group from "../../assests/icons/member-list-icons/group.svg";
import golf from "../../assests/icons/member-list-icons/golf.svg";
import pilates from "../../assests/icons/member-list-icons/pilates.svg";
import groupRed from "../../assests/icons/member-list-icons/red-group.svg";
import golfRed from "../../assests/icons/member-list-icons/red-golf.svg";
import pilatesRed from "../../assests/icons/member-list-icons/red-pilates.svg";
import { formatCustomNumber } from "../../utils/commonUtils";

function MemberListItem({
  items,
  classNameValue,
  setShowMoreInfo,
  index,
  setMemberId,
}) {
  // console.log("-------TRAINER ",items)
  return (
    <>
      <div
        key={index}
        onClick={() => {
          setShowMoreInfo(true);
          setMemberId(items.id);
        }}
        className={`${"bg-box_bg rounded-[12px] px-3 justify-between py-4 flex items-center gap-4 mb-4 borderClassMember cursor-pointer"}`}
      >
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-3 w-[250px]">
            {items?.mProfileImg && items.mProfileImg.prof_img1_org ? (
              <img
                src={items.mProfileImg.prof_img1_org}
                className="w-[60px] h-[60px] rounded-full"
                alt="user"
              />
            ) : (
              <img
                src={user}
                className="w-[60px] h-[60px] rounded-full"
                alt="user"
              />
            )}
            <div className="font-bold text-white">
              <p className="text-lg mb-0  w-32 textOverflowClass">
                {items.name}
              </p>
              <p className="text-base mb-0">
                +{formatCustomNumber(items.cellphone)}
              </p>
            </div>
          </div>
          {items?.memberOwnProducts
            ? Object.keys(items?.memberOwnProducts).map(
                (val, index) =>
                  index < 3 && (
                    <div className="text-base text-white border-r-2 border-[#444444] last:border-r-0 px-3">
                      <div className="flex items-center gap-2">
                        {val == "membership" ? (
                          <Icon
                            icon="tdesign:cardmembership"
                            width="20"
                            height="20"
                            style={
                              !items.memberOwnProducts[val]?.isExpired
                                ? { color: "#CCFF00" }
                                : { color: "#C25E5F" }
                            }
                          />
                        ) : val == "PT" ? (
                          <Icon
                            icon="tdesign:cardmembership"
                            width="20"
                            height="20"
                            style={
                              !items.memberOwnProducts[val]?.isExpired ||
                              items.memberOwnProducts[val]?.isCompleted
                                ? { color: "#CCFF00" }
                                : { color: "#C25E5F" }
                            }
                          />
                        ) : val == "uniform" ? (
                          <Icon
                            icon="fluent-emoji-high-contrast:womans-clothes"
                            width="20"
                            height="20"
                            style={
                              !items.memberOwnProducts[val]?.isExpired
                                ? { color: "#CCFF00", marginBottom: "4px" }
                                : { color: "#C25E5F", marginBottom: "4px" }
                            }
                          />
                        ) : val == "gx" ? (
                          items.memberOwnProducts[val]?.isExpired ? (
                            <img src={groupRed} alt="item" />
                          ) : (
                            <img src={group} alt="item" />
                          )
                        ) : val == "golf" ? (
                          items.memberOwnProducts[val]?.isExpired ? (
                            <img src={golfRed} alt="item" />
                          ) : (
                            <img src={golf} alt="item" />
                          )
                        ) : val == "yoga" ? (
                          <Icon
                            icon="iconoir:yoga"
                            width="20"
                            height="20"
                            style={
                              !items.memberOwnProducts[val]?.isExpired
                                ? { color: "#CCFF00" }
                                : { color: "#C25E5F" }
                            }
                          />
                        ) : val == "pilates" ? (
                          items.memberOwnProducts[val]?.isExpired ? (
                            <img src={pilatesRed} alt="items" />
                          ) : (
                            <img src={pilates} alt="items" />
                          )
                        ) : val == "locker" ? (
                          <Icon
                            icon="ph:lockers-bold"
                            width="20"
                            height="20"
                            style={
                              !items.memberOwnProducts[val]?.isExpired
                                ? { color: "#CCFF00" }
                                : { color: "#C25E5F" }
                            }
                          />
                        ) : (
                          ""
                        )}
                        <p
                          className={`${
                            !items.memberOwnProducts[val]?.isExpired == 1 ||
                            items.memberOwnProducts[val]?.isCompleted
                              ? "text-white"
                              : "text-[#C25E5F]"
                          } font-bold mb-0 flex`}
                        >
                          {val}{" "}
                          {val == "PT" ? (
                            <span className="font-normal">
                              {" "}
                              {!items.memberOwnProducts[val]?.isExpired ? (
                                `(${
                                  items.memberOwnProducts[val]?.daysLeft
                                } ${"Days"} ${
                                  items.memberOwnProducts[val]?.sessionCount -
                                  items.memberOwnProducts[val]?.sessionCompleted
                                } ${strings.SESSION_LEFT})`
                              ) : (
                                <p
                                  className={`!mb-0 ${
                                    items.memberOwnProducts[val]?.isCompleted
                                      ? "text-[#CCFF00]"
                                      : "text-[#C25E5F]"
                                  }`}
                                >
                                  {" "}
                                  {items.memberOwnProducts[val]?.isCompleted
                                    ? "(Completed)"
                                    : items?.memberOwnProducts[val]?.transferred
                                    ? "(Transferred)"
                                    : "(Expired)"}
                                </p>
                              )}
                            </span>
                          ) : (
                            <span className="font-normal">
                              {" "}
                              {!items.memberOwnProducts[val]?.isExpired
                                ? `(${
                                    items.memberOwnProducts[val]?.daysLeft >
                                    1000
                                      ? `Over 1000 ${strings.DAY_LEFT}`
                                      : `${items.memberOwnProducts[val]?.daysLeft} ${strings.DAY_LEFT}`
                                  }   )`
                                : items?.memberOwnProducts[val]?.transferred
                                ? "(Transferred)"
                                : "(Expired)"}
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="flex items-center gap-2 mt-2.5">
                        <Icon
                          icon="lets-icons:date-today"
                          width="20"
                          height="20"
                          style={
                            !items.memberOwnProducts[val]?.isExpired == 1 ||
                            items.memberOwnProducts[val]?.isCompleted
                              ? { color: "#CCFF00" }
                              : { color: "#C25E5F" }
                          }
                        />
                        <p
                          className={`${
                            !items.memberOwnProducts[val]?.isExpired == 1 ||
                            items.memberOwnProducts[val]?.isCompleted
                              ? "text-white"
                              : "text-[#C25E5F]"
                          } text-[15px] font-bold mb-0`}
                        >
                          <Moment format="YY.MM.DD">
                            {items.memberOwnProducts[val]?.membershipStartDate}
                          </Moment>{" "}
                          {"~"}{" "}
                          <Moment format="YY.MM.DD">
                            {items.memberOwnProducts[val]?.membershipEndDate}
                          </Moment>
                        </p>
                      </div>
                    </div>
                  )
              )
            : ""}
        </div>
        <div className="w-[180px] flex justify-center">
          <button
            className={`${classNameValue}  ${"text-sm font-extrabold text-white kakaoClass rounded-[30px] h-[40px] w-[113px]"}`}
          >
            {strings.MORE_INFO}
          </button>
        </div>
      </div>
    </>
  );
}

export default MemberListItem;
