import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import calendar from "../../../assests/icons/schedule-icon/calendar.svg";
import fingureup from "../../../assests/icons/schedule-icon/fingureup.svg";
import medal from "../../../assests/icons/schedule-icon/medal.svg";
import cancel from "../../../assests/icons/schedule-icon/cancel.svg";
import noShow from "../../../assests/icons/schedule-icon/noShow.svg";
import dummy from "../../../assests/images/demoProfile.svg";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import sessionCard from "../../../assests/icons/Session icon.svg";
import Downarrow from "../../../assests/icons/Down arrow icon.png";
import $ from "jquery";

function ScheduleDetails({
  setDetailedSchedule,
  eventDate,
  setEvenetDate,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [detailValue, setDetailValue] = useState("");
  const [modalData, setmodalData] = useState();
  const [sessionId, setsessionId] = useState();
  const [isAccordion1Open, setIsAccordion1Open] = useState(false);
  const [isAccordion2Open, setIsAccordion2Open] = useState(false);
  const [sessionCount, setSessionCount] = useState("");
  const handleClose = (e) => {
    // Stop the event from bubbling up and potentially triggering other handlers
    setsessionId();
  };

  const toggleAccordion1 = () => {
    setIsAccordion1Open(!isAccordion1Open);
    setIsAccordion2Open(false);
  };

  const toggleAccordion2 = () => {
    setIsAccordion2Open(!isAccordion2Open);
    setIsAccordion1Open(false);
  };

  const timeSlots = [
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];

  const slots = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
  ];

  const getMinutes = (time) => {
    const [hourMinute, period] = time.split(" ");
    let [hour, minute] = hourMinute.split(":").map(Number);
    if (period === "PM" && hour !== 12) hour += 12;
    if (period === "AM" && hour === 12) hour = 0;
    return hour * 60 + minute;
  };

  const calculateSpan = (startTime, endTime) => {
    return (getMinutes(endTime) - getMinutes(startTime)) / 30;
  };

  const sessionColors = {
    completed: "#CBFF8A",
    booked: "#B3B1FF",
    req_reservation: "#FFE500",
    reject: "#FF9191",
    noshow: "#E9E9E9",
  };

  const iconObj = {
    booked: calendar,
    reject: cancel,
    req_reservation: fingureup,
    completed: medal,
    noshow: noShow,
  };

  const modalTextObj = {
    booked: "Booked",
    reject: "Cancelled",
    req_reservation: "Reserved",
    completed: "Completed",
    noshow: "NoShow",
  };

  // Close dropdown on click outside
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length === 0) {
      $(".dropdownClass").hide();
      setsessionId();
    }
  });

  $(document).ready(function () {
    $(".OverviewClass").scroll(function () {
      setsessionId();
    });
  });

  // =============API for fetching schedule detail API=================
  useEffect(() => {
    async function fetchScheduleDetail() {
      await PT_InformationSublyApi.scheduleDetail(token, eventDate)
        .then((response) => {
          if (response.statusCode == 200) {
            setDetailValue(response.data?.sessionList);
            setSessionCount(response.data.counts);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchScheduleDetail();
  }, []);

  function isTimeInSlot(timeSlot, dateTimeToCheck) {
    if (!timeSlot) {
      return false;
    }
    const slotStart = timeSlot === 1 ? "06:00" : "00:00";
    const slotEnd = timeSlot === 1 ? "24:00" : "06:00";
    // Parse the full date and time to check
    const timeToCheck = moment(dateTimeToCheck, "YYYY-MM-DD HH:mm");

    // Extract just the date from the time to check
    const datePart = timeToCheck.format("YYYY-MM-DD");
    // Construct full moments for start and end times using the same date
    const start = moment(`${datePart} ${slotStart}`, "YYYY-MM-DD HH:mm");
    const end = moment(`${datePart} ${slotEnd}`, "YYYY-MM-DD HH:mm");

    // Check if the timeToCheck is between start and end
    return timeToCheck.isBetween(start, end);
  }

  const sessionAre =
    detailValue && detailValue.map((i, n) => i.sessions).flat();
  const totalSession = sessionAre.length;

  const sessionCalc = (name, timeSlot) => {
    const namedSessions =
      sessionAre &&
      sessionAre.map((i, n) => {
        let inSlot = false;
        if (i?.sessionId) {
          if (!timeSlot) {
            inSlot = true;
          } else {
            inSlot = isTimeInSlot(timeSlot, i.bookedDate);
          }
        }
        return inSlot && i.statusValue;
      });

    const totalSession =
      namedSessions && namedSessions.filter((i, n) => i === name).length;
    return totalSession;
  };

  const popover = (
    <Popover id="popover-basic" className={`${classNameValue} dropdownClass`}>
      <div className="w-[375px] h-[175px] bg-[#474749] rounded-lg text-white max-[414px]:-ml-20 max-[375px]:-ml-30 lg:ml-0 max-[430px]:-ml-20  ">
        <Icon
          icon="maki:cross"
          width="20"
          height="20"
          style={{ color: "white" }}
          className="cursor-pointer float-right mr-2 mt-2"
          onClick={() => {
            handleClose();
          }}
        />
        <button
          style={{ border: "1px solid #5A5A5A" }}
          className=" flex items-center justify-center space-x-2 px-2  bg-opacity-20 w-[90%] mx-auto border-b border-x-0 border-t-0 py-3"
        >
          <img src={iconObj[modalData?.statusValue]} alt="icon" />
          <span className="text-[24px] font-bold text-white ms-4">
            {strings.SESSION} {modalTextObj[modalData?.statusValue]}
          </span>
        </button>
        <div className="w-[95%] mx-auto flex  items-center justify-around">
          <div className="flex flex-col items-center justify-between ">
            <img
              className="size-[44px] rounded-full object-center object-cover"
              src={
                modalData?.memberInfo?.mProfileImg?.prof_img1_min
                  ? modalData?.memberInfo?.mProfileImg?.prof_img1_min
                  : dummy
              }
              alt=""
            />
            <span className="text-[14px] font-bold">
              {modalData?.memberInfo?.name}
            </span>
          </div>
          <div className="flex items-center justify-between gap-1 my-3">
            <div className="w-[90px] h-[80px] bg-[#2B2B2C] rounded-md flex flex-col justify-center items-center">
              <span className="text-[18px] font-semibold mb-1">
                {moment(modalData?.bookedDate).format("MM.DD")}
              </span>
              <div className="flex justify-between">
                {" "}
                <Icon
                  icon="lets-icons:date-today"
                  width="16px"
                  height="16px"
                  style={{ color: "#C5C5C5" }}
                />
                <span className="font-semibold text-[13px] ms-2">Date</span>
              </div>
            </div>
            <div className="w-[90px] h-[80px] bg-[#2B2B2C] rounded-md flex flex-col justify-center items-center">
              <span className="text-[18px] font-semibold mb-1">
                {moment(modalData?.bookedDate).format("h.mm")}
                <span className="text-[14px] font-semibold">
                  {moment(modalData?.bookedDate).format("A")}
                </span>
              </span>
              <div className="flex  justify-between">
                {" "}
                <Icon
                  icon="ri:time-line"
                  width="18px"
                  height="18px"
                  style={{ color: "#C5C5C5" }}
                />
                <span className="font-semibold text-[13px] ms-2">Time</span>
              </div>
            </div>
            <div className="w-[90px] h-[80px] bg-[#2B2B2C] rounded-md flex flex-col justify-center items-center">
              <span className="text-[18px] font-semibold mb-1">
                {modalData?.sessionCompleted}/{modalData?.totalSessions}
              </span>
              <div className="flex items-center justify-between">
                {" "}
                <img src={sessionCard} alt="session card" className="w-5 h-5" />
                <span className="font-semibold text-[13px] ms-2">Session</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-50"}`}
    >
      <div
        className={`${classNameValue} ${"rounded-[10px] w-[80%] max-lg:p-4 max-lg:py-8 overflow-auto  mt-14 mb-10 z-20  bg-[#2C2C2D] OverviewClass"}`}
      >
        <div className="w-[100%] h-full relative">
          <div className=" m-auto border-b border-[#2C2C2D] text-white">
            <div className="mb-0 text-[30px] font-extrabold bg-[#353638] px-9 py-3 flex-wrap gap-y-2  flex items-center justify-between">
              <div>
                <span>{moment(eventDate).format("YYYY.MM.DD")} </span>
                <span>{strings.SCHEDULE}</span>
              </div>
              <div className="flex items-center gap-x-3 flex-wrap gap-y-2">
                <button className="rounded-[6px] flex items-center justify-evenly w-[108px] h-[40px] bg-[#B3B1FF] bg-opacity-20">
                  <img src={calendar} alt="calendar" />
                  <span className="text-[16px] text-[#B3B1FF]">
                    {strings.BOOKED}
                  </span>
                </button>
                <button className="rounded-[6px] flex items-center justify-evenly w-[142px] h-[40px] bg-[#FFE500] bg-opacity-20">
                  <img src={fingureup} alt="img" />
                  <span className="text-[16px] text-[#FFE500]">
                    {strings.RESERVATIONS}
                  </span>
                </button>
                <button className="rounded-[6px] flex items-center justify-evenly w-[142px] h-[40px] bg-[#CBFF8A] bg-opacity-20">
                  <img src={medal} alt="medal" />
                  <span className="text-[16px] text-[#CBFF8A]">
                    {strings.COMPLETED}
                  </span>
                </button>
                <button className="rounded-[6px] flex items-center justify-evenly w-[105px] h-[40px] bg-[#FF9191] bg-opacity-20">
                  <img src={cancel} alt="cancel" />
                  <span className="text-[16px] text-[#FF9191]">
                    {" "}
                    {strings.CANCEL}
                  </span>
                </button>
                <button className="rounded-[6px] flex items-center justify-evenly w-[121px] h-[40px] bg-[#E9E9E9] bg-opacity-20">
                  <img src={noShow} alt="noshow" />
                  <span className="text-[16px] text-[#E9E9E9]">
                    {strings.NO_SHOWS}
                  </span>
                </button>
              </div>
            </div>
          </div>

          {/* accordion starts here */}

          <div className="w-[98%] text-white ps-4 mt-10 ml-2  min-h-[600px]">
            {/* Accordion 1 */}
            <div>
              <div
                onClick={toggleAccordion1}
                className="cursor-pointer p-3 bg-[#2C2C2D] rounded-t-md flex w-[98%] justify-between items-center  border-b border-[#505050]"
              >
                <div className="flex items-center">
                  <img
                    src={Downarrow}
                    alt="Downarrow"
                    className={`transition-transform duration-500 ${
                      isAccordion1Open ? "rotate-180" : "rotate-0"
                    }`}
                    style={{ width: "40px", height: "40px" }}
                  />

                  <h3 className="text-lg font-bold ml-4 mt-2 text-[#CCFF00]">
                    6:00 to 24:00
                  </h3>
                  <div className="flex items-center gap-x-1 flex-wrap gap-y-2 ml-20">
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20 ">
                      {/* <img
                    src={calendar}
                    alt="calendar"
                    className=" sm:w-8 sm:h-8"
                  /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        Booked:{" "}
                        <span className="text-[#B3B1FF]">
                          {sessionCalc("booked", 1)}
                        </span>
                      </span>
                    </button>
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                      {/* <img src={fingureup} alt="img" className=" sm:w-8 sm:h-8" /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Reservations:{" "}
                        <span className="text-[#FFE500]">
                          {sessionCalc("req_reservation", 1)}
                        </span>
                      </span>
                    </button>
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                      {/* <img src={medal} alt="medal" className=" sm:w-8 sm:h-8" /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Completed:{" "}
                        <span className="text-[#CBFF8A]">
                          {sessionCalc("completed", 1)}
                        </span>
                      </span>
                    </button>
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                      {/* <img src={cancel} alt="cancel" className=" sm:w-8 sm:h-8" /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Cancel:{" "}
                        <span className="text-[#FF9191]">
                          {sessionCalc("reject", 1)}
                        </span>
                      </span>
                    </button>
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                      {/* <img src={noShow} alt="noshow" className="sm:w-8 sm:h-8" /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Noshow:{" "}
                        <span className="text-[#E9E9E9]">
                          {sessionCalc("noshow", 1)}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {isAccordion1Open && (
                <div className="pt-3  bg-[#2C2C2D] rounded-b-md w-[98%]">
                  <div className="overflow-auto OverviewClass">
                    <div className="w-full max-lg:w-[1000px] max-h-[calc(100vh_-_400px)]">
                      <div className="border-[#505050]">
                        <div className="flex w-full">
                          <div className="text-end w-[80px] h-[50px] gap-[2px] flex justify-center items-center rounded-[5px] me-3"></div>
                          {detailValue?.length > 0 &&
                            detailValue?.map((val, index) => (
                              <div
                                style={{ border: "1px solid #505050" }}
                                key={index}
                                className="w-[130px] h-[50px] rounded-[5px] flex items-center justify-center text-[16px] font-bold"
                              >
                                {val?.staffDetails?.gymStaffName}
                              </div>
                            ))}
                        </div>
                      </div>
                      {timeSlots.map((time, index) => (
                        <div
                          key={index}
                          className="flex h-[30px] gap-3 mb-[4px] w-full"
                        >
                          <div className="w-[120px]">
                            <div className="w-[80px] flex justify-end items-center text-base font-bold h-[18px] pt-7">
                              {time}
                            </div>
                          </div>
                          <div className="flex -ml-10">
                            {detailValue &&
                              detailValue?.map((trainer, tIndex) => {
                                const session = trainer.sessions.find(
                                  (session) =>
                                    getMinutes(
                                      moment(session.bookedDate).format(
                                        "h:mm A"
                                      )
                                    ) >= getMinutes(time) &&
                                    getMinutes(
                                      moment(session.bookedDate).format(
                                        "h:mm A"
                                      )
                                    ) <
                                      getMinutes(time) + 30
                                );
                                if (session) {
                                  const span = calculateSpan(
                                    moment(session.bookedDate).format("h:mm A"),
                                    moment(session.endDate).format("h:mm A")
                                  );
                                  const topPosition =
                                    ((getMinutes(
                                      moment(session.bookedDate).format(
                                        "h:mm A"
                                      )
                                    ) %
                                      30) /
                                      30) *
                                    100;
                                  return (
                                    <div
                                      style={{ border: "1px solid #505050" }}
                                      className="w-[130px] flex justify-center border-s-0 border-t-0 h-[33px] items-center"
                                    >
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        overlay={popover}
                                        show={sessionId === session.sessionId}
                                      >
                                        <div
                                          onClick={() => {
                                            setmodalData(session);
                                            setsessionId(session.sessionId);
                                            sessionId === session.sessionId &&
                                              setsessionId();
                                          }}
                                          key={tIndex}
                                          className={`bg-[${
                                            sessionColors[session.statusValue]
                                          }] rounded-md w-[122px] flex justify-center items-center text-white text-base font-bold cursor-pointer bg-opacity-30`}
                                          style={{
                                            height: `${span * 32}px`,
                                            top: `${topPosition}%`,
                                            marginTop: 33,
                                            zIndex: 20,
                                          }}
                                        >
                                          {session.memberInfo.name}
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      style={{ border: "1px solid #505050" }}
                                      key={tIndex}
                                      className="w-[130px] p-2 text-center h-full border-s-0 pt-4 border-t-0 "
                                    />
                                  );
                                }
                              })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Accordion 2 */}
            <div>
              <div
                onClick={toggleAccordion2}
                className="cursor-pointer p-3 bg-[#2C2C2D] rounded-t-md flex w-[98%] justify-between items-center mb-2  border-b border-[#505050]"
              >
                <div className="flex items-center">
                  <img
                    src={Downarrow}
                    alt="Downarrow"
                    className={`transition-transform duration-300 ${
                      isAccordion2Open ? "rotate-180" : "rotate-0"
                    }`}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <h3 className="text-lg font-bold text-[#CCFF00] ml-4 mt-2">
                    00:00 to 6:00
                  </h3>
                  <div className="flex items-center gap-x-1 flex-wrap gap-y-2 ml-20">
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20 ">
                      {/* <img
                    src={calendar}
                    alt="calendar"
                    className=" sm:w-8 sm:h-8"
                  /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Booked:{" "}
                        <span className="text-[#B3B1FF]">
                          {sessionCalc("booked", 2)}
                        </span>
                      </span>
                    </button>
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                      {/* <img src={fingureup} alt="img" className=" sm:w-8 sm:h-8" /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Reservations:{" "}
                        <span className="text-[#FFE500]">
                          {sessionCalc("req_reservation", 2)}
                        </span>
                      </span>
                    </button>
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                      {/* <img src={medal} alt="medal" className=" sm:w-8 sm:h-8" /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Completed:{" "}
                        <span className="text-[#CBFF8A]">
                          {sessionCalc("completed", 2)}
                        </span>
                      </span>
                    </button>
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                      {/* <img src={cancel} alt="cancel" className=" sm:w-8 sm:h-8" /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Cancel:{" "}
                        <span className="text-[#FF9191]">
                          {sessionCalc("reject", 2)}
                        </span>
                      </span>
                    </button>
                    <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                      {/* <img src={noShow} alt="noshow" className="sm:w-8 sm:h-8" /> */}
                      <span className="lg:text-lg sm:text-base font-bold text-white">
                        {" "}
                        Noshow:{" "}
                        <span className="text-[#E9E9E9]">
                          {sessionCalc("noshow", 2)}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {isAccordion2Open && (
                <div className="pt-3  bg-[#2C2C2D] rounded-b-md w-[98%]">
                  <div className="overflow-auto OverviewClass">
                    <div className="w-full max-lg:w-[1000px] max-h-[calc(100vh_-_400px)]">
                      {" "}
                      <div className="border-[#505050]">
                        <div className="flex">
                          <div className="text-end w-[80px] h-[50px] gap-[2px] flex justify-center items-center rounded-[5px] me-3"></div>
                          {detailValue?.length > 0 &&
                            detailValue?.map((val, index) => (
                              <div
                                style={{ border: "1px solid #505050" }}
                                key={index}
                                className="w-[130px] h-[50px] rounded-[5px] flex items-center justify-center text-[16px] font-bold"
                              >
                                {val?.staffDetails?.gymStaffName}
                              </div>
                            ))}
                        </div>
                      </div>
                      {slots.map((time, index) => (
                        <div
                          key={index}
                          className="flex h-[30px] gap-3 mb-[2px] w-full"
                        >
                          <div className="w-[120px]">
                            <div className="w-[80px] flex justify-end items-center text-base font-bold h-[18px] pt-7">
                              {time}
                            </div>
                          </div>
                          <div className="flex  -ml-10">
                            {detailValue &&
                              detailValue?.map((trainer, tIndex) => {
                                const session = trainer.sessions.find(
                                  (session) =>
                                    getMinutes(
                                      moment(session.bookedDate).format(
                                        "h:mm A"
                                      )
                                    ) >= getMinutes(time) &&
                                    getMinutes(
                                      moment(session.bookedDate).format(
                                        "h:mm A"
                                      )
                                    ) <
                                      getMinutes(time) + 30
                                );
                                if (session) {
                                  const span = calculateSpan(
                                    moment(session.bookedDate).format("h:mm A"),
                                    moment(session.endDate).format("h:mm A")
                                  );
                                  const topPosition =
                                    ((getMinutes(
                                      moment(session.bookedDate).format(
                                        "h:mm A"
                                      )
                                    ) %
                                      30) /
                                      30) *
                                    100;
                                  return (
                                    <div
                                      style={{ border: "1px solid #505050" }}
                                      className="w-[130px] flex justify-center border-s-0 border-t-0 h-[33px] items-center"
                                    >
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        overlay={popover}
                                        show={sessionId === session.sessionId}
                                      >
                                        <div
                                          onClick={() => {
                                            setmodalData(session);
                                            setsessionId(session.sessionId);
                                            sessionId === session.sessionId &&
                                              setsessionId();
                                          }}
                                          key={tIndex}
                                          className={`bg-[${
                                            sessionColors[session.statusValue]
                                          }] rounded-md w-[122px] flex justify-center items-center text-white text-base font-bold cursor-pointer bg-opacity-30`}
                                          style={{
                                            height: `${span * 32}px`,
                                            top: `${topPosition}%`,
                                            marginTop: 33,
                                            zIndex: 20,
                                          }}
                                        >
                                          {session.memberInfo.name}
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      style={{ border: "1px solid #505050" }}
                                      key={tIndex}
                                      className="w-[130px] p-2 text-center h-full border-s-0 pt-4 border-t-0"
                                    />
                                  );
                                }
                              })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* {accordion ends here} */}

          <div
            className={`${
              isAccordion2Open || isAccordion1Open
                ? "sticky w-full bottom-0"
                : "sticky w-full bottom-0"
            } ${"bg-[#353638] px-9 py-3 z-50"}`}
          >
            <p className="font-extrabold text-[30px] sm:text-[25px] md:text-[30px]">
              Today Total sessions: {sessionCount?.totalSessions}
            </p>
            <div className="flex items-center flex-wrap gap-y-2 justify-between">
              <div className="flex items-center gap-x-1 flex-wrap gap-y-2">
                <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20 ">
                  <img
                    src={calendar}
                    alt="calendar"
                    className=" sm:w-8 sm:h-8"
                  />
                  <span className="lg:text-[24px] sm:text-base font-bold text-white">
                    Booked:{" "}
                    <span className="text-[#B3B1FF]">
                      {sessionCalc("booked")}
                    </span>
                  </span>
                </button>
                <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                  <img src={fingureup} alt="img" className=" sm:w-8 sm:h-8" />
                  <span className="lg:text-[24px] sm:text-base font-bold text-white">
                    Reservations:{" "}
                    <span className="text-[#FFE500]">
                      {sessionCalc("req_reservation")}
                    </span>
                  </span>
                </button>
                <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                  <img src={medal} alt="medal" className=" sm:w-8 sm:h-8" />
                  <span className="lg:text-[24px] sm:text-base font-bold text-white">
                    Completed:{" "}
                    <span className="text-[#CBFF8A]">
                      {sessionCalc("completed")}
                    </span>
                  </span>
                </button>
                <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                  <img src={cancel} alt="cancel" className=" sm:w-8 sm:h-8" />
                  <span className="lg:text-[24px]  sm:text-base  font-bold text-white">
                    Cancel:{" "}
                    <span className="text-[#FF9191]">
                      {sessionCalc("reject")}
                    </span>
                  </span>
                </button>
                <button className="rounded-[6px] flex items-center justify-evenly space-x-2 px-2 py-1 bg-opacity-20">
                  <img src={noShow} alt="noshow" className="sm:w-8 sm:h-8" />
                  <span className="lg:text-[24px] sm:text-base  font-bold text-white">
                    Noshow:{" "}
                    <span className="text-[#E9E9E9]">
                      {sessionCalc("noshow")}
                    </span>
                  </span>
                </button>
              </div>
              <button
                onClick={() => {
                  setDetailedSchedule(false);
                  setEvenetDate("");
                }}
                className="w-full sm:w-[160px] h-[50px] sm:h-[60px] text-xl sm:text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color disabled:bg-[#ccff0094]"
              >
                {strings.CLOSE}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ScheduleDetails;
