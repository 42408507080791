import React, { useEffect, useState } from "react";
import { div, Container, Row, Col } from "react-bootstrap";
import logo from "../assests/attendanceIcons/logo-icon.svg";
import demoImg from "../assests/attendanceIcons/demoImage.svg";
import demoImg2 from "../assests/attendanceIcons/demoImageTwo.svg";
import calender from "../assests/attendanceIcons/attendanceCalender.svg";
import currency from "../assests/attendanceIcons/currencySign.svg";
import { strings } from "../utils/String";
import "./AttendanceStyle.css";
import { Icon } from "@iconify-icon/react/dist/iconify.js";
import OTPInput from "react-otp-input";
import clearBtn from "../assests/attendanceIcons/ClearBtn-icon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AttendanceSublyApi from "../helper/AttendanceApi";
import Loader from "../utils/Loader";
import NotValidInfoModal from "./NotValidInfoModal";
import imagePlaceholder from "../assests/images/no-image.png";
import { formatPrice } from "../utils/commonUtils";
import AttendanceHeader from "./AttendanceHeader";
import { FormMedia } from "../components/appComponents/boardScreens/RegistrationForm";

function MemberAttendance() {
  const navigate = useNavigate();
  const { token, gymName } = useSelector(
    (state) => state.user.attendanceDetail
  );
  const [otp, setOtp] = useState("");
  const [currentIndex, setCurrentIndex] = useState(otp.length);
  const [eventList, setEventList] = useState("");
  const [eventValue, setEventValue] = useState(
    eventList?.length > 0 ? eventList[0] : ""
  );
  const [isLoader, setIsLoader] = useState(false);
  const [isNotVaild, setIsNotValid] = useState(false);
  const [convertedOtp, setConvertedOtp] = useState("");
  const [eventIndex, setEventIndex] = useState(0);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);

  // =======Function handle OTP value========
  function fetchOtpHandle(keyValue) {
    const [value] = keyValue.split("").slice(-1);

    if (isNaN(value)) {
      setOtp(otp.slice(0, otp.length - 1));
      setConvertedOtp(convertedOtp.slice(0, convertedOtp.length - 1));
      return;
    } else {
      const newOtp = otp.split("");
      newOtp.length = 5;
      newOtp.splice(currentIndex + 1, 0, value);
      const newConverted = convertedOtp.split("");
      newConverted.length = 5;
      newConverted.splice(currentIndex + 1, 0, value);
      setConvertedOtp(newConverted.join(""));
      setOtp(newOtp.join(""));
      clearTimeout(timer);

      // Start a new timeout to convert to stars after 2 seconds
      var timer = setTimeout(() => {
        const starString = "*".repeat(otp.length + 1);
        setConvertedOtp(starString);
      }, 500);
      setCurrentIndex(otp.length);
    }
  }

  // ===============fetching event list===============
  useEffect(() => {
    async function getEventList() {
      setIsLoader(true);
      await AttendanceSublyApi.fetchEventList(token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setEventList(response.data.events);
            setEventValue(response.data.events[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getEventList();
  }, []);

  // useEffect(() => {
  //   // Function to handle keydown events and prevent their default behavior
  //   const handleKeydown = (event) => {
  //     event.preventDefault();
  //   };

  //   // Add the keydown event listener when the component mounts
  //   window.addEventListener("keydown", handleKeydown);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("keydown", handleKeydown);
  //   };
  // }, []);

  // ==============API for member attendance============
  async function memberAttendanceHandle() {
    setIsLoader(true);
    const requestedData = { gymLoginNumber: otp };
    await AttendanceSublyApi.memberAttendance(token, requestedData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          navigate("/Attendance/User-info", { state: response.data.memberId });
        } else {
          setIsNotValid(true);
          setOtp("");
          setConvertedOtp("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (isNotVaild) {
      setTimeout(() => {
        setIsNotValid(false);
      }, 3000);
    }
  }, [isNotVaild]);

  function eventIndexHandler(index) {
    setEventValue(eventList[index]);
    setEventIndex(index);
  }

  return (
    <section>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
      />
      {isLoader ? <Loader /> : ""}
      <AttendanceHeader />
      <Container fluid className="px-7 pb-8 pt-2">
        {eventList?.length > 0 ? (
          <div className="flex items-start flex-wrap justify-center max-xl:gap-2 xl:gap-8 pb-1 border-b-2 border-[#444444]">
            <div className="max-[1120px]:mb-5">
              <div className="m-auto mt-5">
                {/* <img src={logo} className="m-auto" /> */}
                <p className="text-[30px] my-3 font-extrabold text-center max-[1120px]:w-full w-64">
                  {gymName}
                </p>
                <div className="relative">
                  <div className="text-center flex items-center flex-col gap-3 h-[250px] overflow-auto pb-16 pr-2">
                    {eventList?.length > 0 &&
                      eventList?.map(
                        (val, index) =>
                          (!val.hasOwnProperty("isActive") || val.isActive) && (
                            <div className="h-[50px]">
                              <button
                                key={index}
                                onClick={() => {
                                  setEventValue(val);
                                  setEventIndex(index);
                                }}
                                className={`${
                                  eventValue.eventName == val.eventName
                                    ? "text-black bg-[#CCFF00]"
                                    : "text-white kakaoClass"
                                } ${" truncate hover:text-black hover:bg-[#CCFF00] text-xl font-bold h-full px-3 w-[245px] rounded-[28px] my-2.5"}`}
                              >
                                {val?.eventName}
                              </button>
                            </div>
                          )
                      )}
                  </div>
                  <div
                    style={{
                      background:
                        "linear-gradient(181.06deg, rgba(53, 54, 56, 0) -6.01%, #1F2022 77.72%)",
                    }}
                    className="absolute bottom-0 left-0 w-full h-[60px]"
                  ></div>
                </div>
              </div>
            </div>
            <div className="xl:max-w-[986px] 2xl:max-w-[1100px] attendanceRespoClass xl:max-h-[530px] w-full">
              <h3 className="text-[34px] font-extrabold text-white text-center mb-3">
                {eventValue.eventName || strings.MEMBERSHIP_EVENT}
              </h3>
              <div className="flex items-center gap-2">
                <div className="w-[60%] h-[332px]">
                  <div
                    style={{
                      backgroundImage: `url(${
                        (eventValue?.eventImages || [])[0]?.url || demoImg
                      })`,
                    }}
                    className="rounded-[10px] w-full h-full bg-no-repeat bg-cover flex items-center justify-center"
                  >
                    <div className="bg-[#353638] w-[94%] h-[90%] rounded-[10px] flex items-center justify-center backdrop-blur-sm">
                      <div className="p-2 ">
                        {/* {eventList.map((item, index) => {
                          return (
                            index < 4 && (
                              <div className="flex items-center gap-2">
                                <p className="mb-0 text-[28px] max-[840px]:text-[24px] font-extrabold flex items-center gap-2">
                                  <img
                                    src={calender}
                                    alt="calender"
                                    className="max-[840px]:w-6"
                                  />
                                  {item.periodMonth}+{item.addonMonth} months
                                </p>{" "}
                                <span className="text-[28px] max-[840px]:text-[24px] font-extrabold text-white">
                                  -
                                </span>
                                <p className="mb-0 text-[28px] max-[840px]:text-[24px] font-extrabold flex items-center gap-2">
                                  <img
                                    src={currency}
                                    alt="calender"
                                    className="max-[840px]:w-7"
                                  />
                                  {formatPrice(item.finalPrice)}
                                </p>{" "}
                              </div>
                            )
                          );
                        })} */}
                        <div className="flex items-center gap-2">
                          <p className="mb-0 text-[28px] max-[840px]:text-[24px] font-extrabold flex items-center gap-2">
                            <img
                              src={calender}
                              alt="calender"
                              className="max-[840px]:w-6"
                            />
                            {eventValue.periodMonth}+{eventValue.addonMonth}{" "}
                            months
                          </p>
                          <span className="text-[28px] max-[840px]:text-[24px] font-extrabold text-white">
                            -
                          </span>
                          <p className="mb-0 text-[28px] max-[840px]:text-[24px] font-extrabold flex items-center gap-2">
                            <img
                              src={currency}
                              alt="calender"
                              className="max-[840px]:w-7"
                            />
                            {formatPrice(eventValue.finalPrice)}
                          </p>{" "}
                          {/* {eventValue.periodMonth} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {eventValue?.eventImages?.length > 2 ? (
                  <div
                    className={`${
                      eventValue?.eventImages?.length > 3
                        ? " h-full"
                        : "h-[332px]"
                    } ${"w-[40%] "}`}
                  >
                    <div className="flex items-center gap-2 h-full">
                      {eventValue?.eventImages?.map(
                        (item, index) =>
                          index > 0 &&
                          index <= 2 && (
                            <div
                              key={index}
                              className={`${
                                eventValue?.eventImages?.length > 3
                                  ? "h-[159px]"
                                  : "h-full"
                              } ${"w-full relative"}`}
                            >
                              <img
                                src={item.url}
                                className="w-full rounded-[10px] h-full object-cover"
                              />
                              {item?.type == "video" && (
                                <span
                                  onClick={() => {
                                    setVideoPreview(true);
                                    setuploadedMedia(item);
                                  }}
                                  className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                                >
                                  <Icon
                                    icon="ooui:play"
                                    width="70"
                                    height="70"
                                    style={{ color: "white" }}
                                  />
                                </span>
                              )}
                            </div>
                          )
                      )}
                    </div>
                    <div className="flex items-center gap-2 mt-3 h-full">
                      {eventValue?.eventImages?.map(
                        (item, index) =>
                          index > 2 &&
                          index <= 4 && (
                            <div
                              key={index}
                              className={`${
                                eventValue?.eventImages?.length > 3
                                  ? "h-[159px]"
                                  : "h-full"
                              } ${"w-full relative"}`}
                            >
                              <img
                                src={item.url}
                                className="w-full rounded-[10px] h-full object-cover"
                              />
                              {item?.type == "video" && (
                                <span
                                  onClick={() => {
                                    setVideoPreview(true);
                                    setuploadedMedia(item);
                                  }}
                                  className="absolute top-[44%] right-[45%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                                >
                                  <Icon
                                    icon="ooui:play"
                                    width="70"
                                    height="70"
                                    style={{ color: "white" }}
                                  />
                                </span>
                              )}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="w-[40%] h-[332px]">
                    <div className="flex items-center gap-2 h-full">
                      <div className={`${"w-full h-full"}`}>
                        <img
                          src={imagePlaceholder}
                          className="w-full rounded-[10px] h-full object-cover"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center gap-2 justify-center my-3">
                <div
                  onClick={() => {
                    if (eventIndex !== 0) {
                      eventIndexHandler(eventIndex - 1);
                    }
                  }}
                  className="rounded-[27px] hover:bg-common_color kakaoClass hover:text-black text-white px-3 py-1.5 flex items-center justify-center"
                >
                  <Icon
                    icon="mingcute:arrow-left-line"
                    width="35"
                    height="35"
                  />
                </div>
                <div
                  onClick={() => {
                    if (eventIndex !== eventList.length - 1) {
                      eventIndexHandler(eventIndex + 1);
                    }
                  }}
                  className="rounded-[27px] hover:bg-common_color kakaoClass hover:text-black text-white px-3 py-1.5 flex items-center justify-center"
                >
                  <Icon
                    icon="mingcute:arrow-right-line"
                    width="35"
                    height="35"
                  />
                </div>{" "}
              </div>
            </div>
          </div>
        ) : (
          <div className="m-auto mt-2 !pb-4">
            <img src={logo} className="m-auto" />
            <p className="text-[30px] my-3 font-extrabold text-center">
              {gymName}
            </p>
            {/* <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
              {strings.NO_RECORD_FOUND}
            </p> */}
          </div>
        )}
        <div className="flex items-center flex-wrap gap-x-3 my-3 gap-y-10 pt-0.5">
          <div className="m-auto max-w-[627px] w-full">
            <h3 className="text-[40px] font-extrabold text-center text-white pb-2.5 w-[85%] m-auto">
              {strings.PLEASE_ENTER_YOUR_DIGIT_NUMBER}
            </h3>
            <div className="py-3">
              <OTPInput
                value={convertedOtp}
                numInputs={6}
                onChange={(e) => {
                  fetchOtpHandle(e);
                }}
                inputType="tel"
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                containerStyle={"justify-center gap-1"}
                inputStyle={
                  "!w-14 !h-20  bg-[#2C2C2E] rounded-[15px] font-bold text-center text-3xl text-white"
                }
              />
            </div>
            <div className="flex items-center justify-center gap-4 pt-2">
              <button
                onClick={() => {
                  memberAttendanceHandle();
                }}
                className="bg-common_color disabled:bg-[#ccff0094] text-xl font-extrabold text-black rounded-[30px] h-[50px] w-[155px]"
              >
                {strings.CONFIRM}
              </button>
            </div>
          </div>
          <div className="bg-[#353638] rounded-[15px] max-w-[664px] w-full m-auto py-3 px-4">
            <div className="flex items-center gap-x-2 gap-y-3">
              <button
                onClick={() => {
                  fetchOtpHandle("1");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                1
              </button>
              <button
                onClick={() => {
                  fetchOtpHandle("2");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                2
              </button>
              <button
                onClick={() => {
                  fetchOtpHandle("3");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                3
              </button>
            </div>
            <div className="flex items-center gap-x-2 gap-y-3 mt-3">
              <button
                onClick={() => {
                  fetchOtpHandle("4");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                4
              </button>
              <button
                onClick={() => {
                  fetchOtpHandle("5");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                5
              </button>
              <button
                onClick={() => {
                  fetchOtpHandle("6");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                6
              </button>
            </div>
            <div className="flex items-center gap-x-2 gap-y-3 mt-3">
              <button
                onClick={() => {
                  fetchOtpHandle("7");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                7
              </button>
              <button
                onClick={() => {
                  fetchOtpHandle("8");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                8
              </button>
              <button
                onClick={() => {
                  fetchOtpHandle("9");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                9
              </button>
            </div>
            <div className="flex items-center gap-x-2 gap-y-3 mt-3">
              <button
                onClick={() => {
                  setOtp(otp.slice(0, otp.length - 1));
                  setConvertedOtp(
                    convertedOtp.slice(0, convertedOtp.length - 1)
                  );
                }}
                className="h-[70px] w-[200px] flex items-center justify-center"
              >
                <img src={clearBtn} alt="clear-btn" />
              </button>
              <button
                onClick={() => {
                  fetchOtpHandle("0");
                }}
                className="bg-[#646464] text-[33px] font-extrabold text-white rounded-[10px] h-[70px] w-[200px]"
              >
                0
              </button>
              <button className="w-[200px] flex items-center justify-center">
                <div
                  onClick={() => {
                    memberAttendanceHandle();
                  }}
                  className="rounded-[10px] h-[68px] w-3/5 bg-common_color text-black flex items-center justify-center"
                >
                  <Icon
                    icon="mingcute:arrow-right-line"
                    width="35"
                    height="35"
                  />
                </div>{" "}
              </button>
            </div>
          </div>
        </div>
      </Container>
      <NotValidInfoModal
        setIsNotValid={setIsNotValid}
        isNotVaild={isNotVaild}
        subText={strings.CHECK_NUMBER_AGAIN}
        headText={strings.NOT_VALID_INFORMATION}
        setOtp={setOtp}
        setConvertedOtp={setConvertedOtp}
      />
    </section>
  );
}

export default MemberAttendance;
