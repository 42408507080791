import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class BoardSublyApi {
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${BASE_URL}${endpoint}`;
    const headers = { ...header };
    try {
      const result = await axios({ url, method, data, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  //   ===============API for fetching board general list============
  static async fetchBoardList(
    type,
    token,
    page,
    key,
    search,
    duration,
    startDate,
    endDate
  ) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `board?type=${type}&page=${page}&pageSize=${20}&key=${key}&search=${search}&duration=${duration}&startDate=${
        startDate || ""
      }&endDate=${endDate || ""}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for upload media============
  static async assestUpload(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`assets/direct-upload`, data, "post", header);
    return res;
  }

  //   ===============API for delete media============
  static async assestDelete(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `assets/delete?url=${data}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  //   ===============API for register general board============
  static async registerGeneral(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`board`, data, "post", header);
    return res;
  }

  //   ===============API for fetching board general detail============
  static async fetchBoardDetail(id, token, type) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `board/${id}?type=${type || ""}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for comment on general board============
  static async sendComment(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`board/comment`, data, "post", header);
    return res;
  }

  //   ===============API for reply on general board============
  static async commmetReply(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`board/comment-replay`, data, "post", header);
    return res;
  }

  //   ===============API for delete comment============
  static async commmetDelete(id, deleteCommentId, type, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `board/comment/${id}/${deleteCommentId}?type=${type}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  //   ===============API for delete general item============
  static async baordDelete(id, token, type) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `board/${id}?type=${type}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  //   ===============API for Edit general board============
  static async editBoard(id, data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`board/${id}`, data, "put", header);
    return res;
  }

  //   ===============API for Suggestions log board============

  static async editBoard(id, data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`board/${id}`, data, "put", header);
    return res;
  }
  //   ===============API for Tag Staff In Comment============

  static async getAllStaff(token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`staff/basic-info`, undefined, "get", header);
    return res;
  }
}

export default BoardSublyApi;
