import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import ReactDatePicker from "react-datepicker";
import BoardSublyApi from "../../../helper/BoardApi";
import { useDispatch, useSelector } from "react-redux";
import GymSettingSublyApi from "../../../helper/GymSettingApi";
import Loader from "../../../utils/Loader";
import {
  alphaOnly,
  extractUrlsToArray,
  formatCustomNumber,
  numberValidate,
  trimPhone,
  validateEmail,
} from "../../../utils/commonUtils";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import $ from "jquery";
import moment from "moment";
import { HiCalendar } from "react-icons/hi2";
import { DatePicker } from "rsuite";
import { centerHandler } from "../../../Store/slices/AuthSlice";

const rolesData = [
  {
    title: "Team manager",
    value: 5,
  },
  {
    title: "Staff",
    value: 0,
  },
  {
    title: "Trainer",
    value: 1,
  },
  {
    title: "Representative",
    value: 6,
  },
  {
    title: "Director",
    value: 7,
  },
];
const hireData = [
  {
    title: "Regular",
    value: 0,
  },
  {
    title: "Part-time",
    value: 2,
  },
  {
    title: "Temporary",
    value: 4,
  },
  {
    title: "Irregular",
    value: 5,
  },
  {
    title: "Affiliated company employees",
    value: 6,
  },
];
const statusData = [
  {
    title: "Employed",
    value: 0,
  },
  {
    title: "Resigned",
    value: 1,
  },
  {
    title: "Suspended",
    value: 2,
  },
  {
    title: "On Leave",
    value: 3,
  },
];

function StaffRegister({
  setRegisterModal,
  setEditStaff,
  defultValue,
  editStaff,
  setStaffData,
  setIsLoader,
  setStaffId,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const { role, staffId } = useSelector((state) => state.user.selectedGym);
  const { centerDetail } = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [innerLoader, setInnerLoader] = useState(false);
  const [requestedData, setRequestedData] = useState("");
  const [error, setError] = useState("");
  const [selectLevel, setSelectLevel] = useState(false);
  const [level, setLevel] = useState();
  const [selectJob, setSelectJob] = useState(false);
  const [selectHire, setSelectHire] = useState(false);
  const [selectstatus, setSelectstatus] = useState(false);
  const [status, setStatusType] = useState("");
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState("");
  const [jobType, setJobType] = useState({ value: null, title: "" });
  const [hireType, setHireType] = useState("");
  const [code, setCode] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [errorHandler, setErrorHandler] = useState({
    talenteLevel: "",
    name: "",
    nickname: "",
    cellPhone: "",
    email: "",
    startDate: "",
    job: "",
    hireType: "",
  });
  const [openCalender, setOpenCalender] = useState(false);
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (defultValue) {
      setName(defultValue?.staffDetails?.staffName);
      setNickName(defultValue?.staffDetails?.staffNickName);
      setCellPhone(defultValue?.staffDetails?.cellphone);
      setEmail(defultValue?.staffDetails?.email);
      setCode(defultValue?.staffDetails?.userCode);
      setLevel(defultValue?.staffDetails?.badge);
      setImagePreview(extractUrlsToArray(defultValue?.staffDetails));
      setRequestedData(extractUrlsToArray(defultValue?.staffDetails));
      setStartDate(
        defultValue?.staffDetails?.startDate
          ? new Date(defultValue?.staffDetails?.startDate)
          : ""
      );
      const defaultHireType = hireData.find(
        (item) => item.value === defultValue.staffDetails.hireType
      );
      if (defaultHireType) {
        setHireType(defaultHireType);
      }
      if (defultValue.staffDetails.staffType == 2) {
        setJobType({ value: 2, title: "Owner" });
      } else {
        const staffValue = rolesData.find(
          (item) => item.value === defultValue.staffDetails.staffType
        );
        if (staffValue) {
          setJobType(staffValue);
        }
      }
      const statusValue = statusData.find(
        (item) => item.value === defultValue.currentStatus
      );
      if (statusValue) {
        setStatusType(statusValue);
      }
    }
  }, [defultValue]);

  function getVideoThumbnail(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    setError("");
    if (file?.type.match("video")) {
      if (file?.size / 1000000 <= 200) {
        var image = "";
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = async function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              setInnerLoader(true);
              const requestData = new FormData();
              requestData.append("file", file);
              await BoardSublyApi.assestUpload(requestData, token)
                .then((response) => {
                  setInnerLoader(false);
                  if (response.statusCode == 200) {
                    let media = [...imagePreview];
                    const toDeploy = [...requestedData];
                    media.push({
                      original: response.data.original,
                      compressed: response.data.compressed,
                      type: "video",
                      view: image,
                    });

                    toDeploy.push({
                      original: response.data.original,
                      compressed: response.data.compressed,
                      type: "video",
                    });
                    setRequestedData(toDeploy);
                    setImagePreview(media);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              const base64String = image; // Base64 string
              const binaryString = atob(base64String.split(",")[1]); // Binary data string
              const blob = new Blob([binaryString], { type: "image/png" });
              // setPassThumbnail(blob);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setError("Video size should be less than 200 MB");
      }
    } else {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result) {
            setInnerLoader(true);
            const requestData = new FormData();
            requestData.append("file", file);
            await BoardSublyApi.assestUpload(requestData, token)
              .then((response) => {
                setInnerLoader(false);
                if (response.statusCode == 200) {
                  let media = [...imagePreview];
                  let toDeploy = [...requestedData];
                  media.push({
                    original: response.data.original,
                    compressed: response.data.compressed,
                    type: "image",
                    view: result,
                  });
                  toDeploy.push({
                    original: response.data.original,
                    compressed: response.data.compressed,
                    type: "image",
                  });
                  setRequestedData(toDeploy);
                  setImagePreview(media);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setSelectLevel(false);
      setSelectHire(false);
      setSelectJob(false);
      setSelectstatus(false);
    }
  });

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });
  $("#nameId").bind("keypress", alphaOnly);
  // ===============function for handling remove selected media=============
  async function removeProduct(url, index) {
    await BoardSublyApi.assestDelete(url, token)
      .then((response) => {
        if (response.statusCode == 200) {
          const images = [...imagePreview];
          let toDeploy = [...requestedData];
          images.splice(index, 1);
          setImagePreview(images);
          toDeploy.splice(index, 1);
          setRequestedData(toDeploy);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function staffRegistetion() {
    if (
      !level ||
      !name ||
      !(cellPhone && numberValidate(cellPhone)) ||
      !(email && validateEmail(email)) ||
      !nickName ||
      jobType?.value == null ||
      hireType.value == null ||
      !startDate
    ) {
      const errorHandle = {
        ...(!level
          ? {
              talenteLevel: "Please select talent level",
            }
          : { talenteLevel: "" }),
        ...(!name
          ? {
              name: "Please enter value of name",
            }
          : { name: "" }),
        ...(!nickName
          ? {
              nickname: "Please enter value of nickname",
            }
          : { nickname: "" }),
        ...(!startDate
          ? {
              startDate: "Please select start date",
            }
          : { startDate: "" }),
        ...(jobType.value == null
          ? {
              job: "Please select job type",
            }
          : { job: "" }),
        ...(hireType.value == null
          ? {
              hireType: "Please select hire type",
            }
          : { hireType: "" }),
        ...(!email
          ? !email
            ? {
                email: "Please enter value of email",
              }
            : {
                email: "",
              }
          : !validateEmail(email)
          ? {
              email: "Please enter the valid email",
            }
          : {
              email: "",
            }),
        ...(!cellPhone
          ? !cellPhone
            ? {
                cellPhone: "Please enter value of cell phone",
              }
            : { cellPhone: "" }
          : !numberValidate(cellPhone)
          ? {
              cellPhone: "There must be 11 digits",
            }
          : { cellPhone: "" }),
      };
      setErrorHandler(errorHandle);
    } else {
      setIsLoader(true);
      const data = new FormData();
      requestedData && data.append("files", JSON.stringify(requestedData));
      data.append("badge", level);
      // data.append("begde", code);
      data.append("name", name.trim());
      data.append("nickName", nickName.trim());
      data.append("cellphone", cellPhone);
      data.append("email", email);
      data.append("staffType", jobType.value);
      data.append("hireType", hireType.value);
      data.append("startDate", startDate);
      await GymSettingSublyApi.staffRegister(data, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setRegisterModal(false);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function EditStaffRegistetion() {
    if (
      !level ||
      !name ||
      !(cellPhone && numberValidate(cellPhone)) ||
      !(email && validateEmail(email)) ||
      !nickName ||
      hireType.value == null ||
      jobType.value == null ||
      status.value == null ||
      !startDate
    ) {
      const errorHandle = {
        ...(!level
          ? {
              talenteLevel: "Please select talent level",
            }
          : { talenteLevel: "" }),
        ...(!name
          ? {
              name: "Please enter value of name",
            }
          : { name: "" }),
        ...(!nickName
          ? {
              nickname: "Please enter value of nickname",
            }
          : { nickname: "" }),
        ...(!startDate
          ? {
              startDate: "Please select start date",
            }
          : { startDate: "" }),
        ...(jobType.value == null
          ? {
              job: "Please select job type",
            }
          : { job: "" }),
        ...(hireType.value == null
          ? {
              hireType: "Please select hire type",
            }
          : { hireType: "" }),
        ...(status.value == null
          ? {
              status: "Please select status",
            }
          : { status: "" }),
        ...(!email
          ? !email
            ? {
                email: "Please enter value of email",
              }
            : {
                email: "",
              }
          : !validateEmail(email)
          ? {
              email: "Please enter the valid email",
            }
          : {
              email: "",
            }),
        ...(!cellPhone
          ? !cellPhone
            ? {
                cellPhone: "Please enter value of cell phone",
              }
            : { cellPhone: "" }
          : !numberValidate(cellPhone)
          ? {
              cellPhone: "Number must be 11",
            }
          : { cellPhone: "" }),
      };
      setErrorHandler(errorHandle);
    } else {
      setIsLoader(true);
      const staffIdValue = defultValue?.staffDetails?.staffId;
      const data = new FormData();
      requestedData && data.append("files", JSON.stringify(requestedData));
      data.append("badge", level);
      // data.append("begde", code);
      data.append("name", name.trim());
      data.append("nickName", nickName.trim());
      data.append("cellphone", cellPhone);
      data.append("email", email);
      data.append("staffType", jobType.value);
      data.append("hireType", hireType.value);
      data.append("startDate", startDate);
      data.append("status", status.value);
      await GymSettingSublyApi.editStaffInfo(token, staffIdValue, data)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setRegisterModal(false);
            setStaffId("");
            if (defultValue?.staffDetails?.staffId == staffId) {
              dispatch(
                centerHandler({
                  ...centerDetail,
                  name: name,
                  role: jobType.value,
                  userProfile: (requestedData || [])[0]?.original,
                })
              );
            }
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // ============API for fetch user detail===========
  async function fetchdetailHandler() {
    await GymSettingSublyApi.fetchUserDetail(token, code)
      .then((response) => {
        if (response.statusCode == 200) {
          setName(response.data.name);
          setEmail(response.data.email);
          setCellPhone(response.data.cellphone);
          const errorHandle = {
            ...errorHandler,
            name: "",
            email: "",
            cellPhone: "",
          };
          setErrorHandler(errorHandle);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  }
  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonth).getMonth();
    return condition;
  };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  console.log("jobType", jobType);

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${
          imagePreview.length > 0 ? "max-h-[90%] mt-16" : "max-h-[88%] mt-20"
        } ${"bg-[#353638] rounded-[10px] w-[70%]  max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[80%] m-auto border-b border-[#505050]">
            {!editStaff ? (
              <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
                {editStaff
                  ? strings.EDIT_STAFF_INFORMATION
                  : strings.REGISTER_STAFF}
              </p>
            ) : (
              <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
                {strings.EDIT_STAFF_INFORMATION}
              </p>
            )}
          </div>
          <div className="pt-8 pb-3 w-[95%] m-auto">
            <div className="flex items-start gap-3 max-lg:flex-wrap my-2 ">
              <div className="w-full">
                <p className="text-base font-bold text-[16px] text-[#ffffff] ml-2 mb-0">
                  {strings.USER_CODE}:
                </p>
                <label className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 w-full">
                  <input
                    type="text"
                    className="bg-[#414142] text-[18px] placeholder:font-normal placeholder:text-[18px] font-bold rounded-[10px] h-[45px] px-2 text-white w-full"
                    // id="nameId"
                    autoComplete="off"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    placeholder={"#six code"}
                  />
                  <Icon
                    onClick={() => {
                      code && fetchdetailHandler();
                    }}
                    icon="fluent:search-12-filled"
                    width="23"
                    height="23"
                    style={{ color: "#D9D9D9", cursor: "pointer" }}
                  />
                </label>
              </div>
              <div className="w-full">
                <p
                  className={`${
                    !level && errorHandler?.talenteLevel
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"text-base font-bold text-[16px] ml-2 mb-0"}`}
                >
                  {strings.TALENT_LEVEL}
                </p>
                <div
                  className={`${
                    !level && errorHandler?.talenteLevel ? "errorClass" : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 w-full relative dropdownClass"}`}
                  onClick={() => {
                    setSelectLevel(!selectLevel);
                    setSelectHire(false);
                    setSelectJob(false);
                    setSelectstatus(false);
                  }}
                >
                  <input
                    type="text"
                    className={`${"bg-[#414142] rounded-[10px] text-[18px] font-bold placeholder:font-normal h-[42px] px-2 text-white w-full caret-transparent cursor-pointer"}`}
                    // id="nameId"
                    autoComplete="off"
                    value={level}
                    placeholder={"Select level"}
                  />
                  <Icon
                    icon="flowbite:caret-down-solid"
                    width="20"
                    height="20"
                    style={{ color: "#BEBEBE" }}
                    rotate={selectLevel ? 2 : 4}
                  />
                  {selectLevel && <Level setLevel={setLevel} />}
                </div>
                {!level && errorHandler?.talenteLevel && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.talenteLevel}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-start gap-3 max-lg:flex-wrap my-2">
              <div className="w-full">
                <p
                  className={`${
                    errorHandler?.name && !name
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"text-base text-[16px] font-bold ml-2 mb-0"}`}
                >
                  {strings.NAME_}
                </p>
                <label
                  className={`${
                    errorHandler?.name && !name ? "errorClass" : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2"}`}
                >
                  <input
                    type="text"
                    className="bg-[#414142] font-bold text-[18px] rounded-[10px] placeholder:font-normal h-[42px] px-2 text-white w-full"
                    id="nameId"
                    value={name}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[\u3131-\u318E\uAC00-\uD7A3a-zA-Z\s-]*$/;
                      if (regex.test(value)) {
                        setName(value);
                      }
                      // setName(e.target.value);
                    }}
                    placeholder={placeholder.ENTER_NAME}
                  />
                </label>
                {errorHandler?.name && !name && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.name}
                  </p>
                )}
              </div>
              <div className="w-full">
                <p
                  className={`${
                    errorHandler?.nickname && !nickName
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"text-base font-bold text-[16px] ml-2 mb-0"}`}
                >
                  {strings.NICK_NAME}:
                </p>
                <label
                  className={`${
                    errorHandler?.nickname && !nickName ? "errorClass" : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2"}`}
                >
                  <input
                    type="text"
                    className="bg-[#414142] font-bold text-[18px] placeholder:font-normal rounded-[10px] h-[42px] px-2 text-white w-full"
                    // id="nameId"
                    autoComplete="off"
                    value={nickName}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[\u3131-\u318E\uAC00-\uD7A3a-zA-Z\s-]*$/;
                      if (regex.test(value)) {
                        setNickName(value);
                      }
                      // setNickName(e.target.value);
                    }}
                    placeholder={placeholder.ENTER_NICK_NAME}
                  />
                </label>
                {errorHandler?.nickname && !nickName && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.nickname}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-start gap-3 max-lg:flex-wrap my-2">
              <div className="w-full">
                <p
                  className={`${
                    (!cellPhone || !numberValidate(cellPhone)) &&
                    errorHandler?.cellPhone
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"text-base font-bold text-[16px] ml-2 mb-0"}`}
                >
                  {strings.CELL_PHONE}:
                </p>
                <label
                  className={`${
                    errorHandler?.cellPhone &&
                    (!cellPhone || !numberValidate(cellPhone))
                      ? "errorClass"
                      : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 numberFieldClass"}`}
                >
                  <input
                    type="text"
                    className="bg-[#414142] font-bold text-[18px] placeholder:font-normal rounded-[10px] h-[42px] px-2 text-white w-full numberField"
                    // id="nameId"
                    value={formatCustomNumber(cellPhone)}
                    autoComplete="off"
                    onChange={(e) => {
                      const trimmedValue = trimPhone(e.target.value);
                      if (!isNaN(+trimmedValue) || !trimmedValue?.length) {
                        if (trimmedValue.length <= 11)
                          setCellPhone(trimmedValue);
                      }
                    }}
                    placeholder={placeholder.CELL_PHONE}
                  />
                </label>
                {errorHandler?.cellPhone &&
                  (!cellPhone || !numberValidate(cellPhone)) && (
                    <p className="mb-0 text-base text-[#F87171]">
                      {errorHandler?.cellPhone}
                    </p>
                  )}
              </div>
              <div className="w-full">
                <p
                  className={`${
                    errorHandler?.email && (!email || !validateEmail(email))
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"text-base font-bold text-[16px] ml-2 mb-0"}`}
                >
                  {strings.EMAIL_}:
                </p>
                <label
                  className={`${
                    errorHandler?.email && (!email || !validateEmail(email))
                      ? "errorClass"
                      : ""
                  } ${"flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2"}`}
                >
                  <input
                    type="text"
                    className="bg-[#414142] font-bold text-[18px] placeholder:font-normal rounded-[10px] h-[42px] px-2 text-white w-full"
                    // id="nameId"
                    value={email}
                    autoComplete="off"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder={placeholder.EMAIL}
                  />
                </label>
                {errorHandler?.email && (!email || !validateEmail(email)) && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.email}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-start gap-3 max-lg:flex-wrap text-[18px] my-2">
              <div className="w-full">
                <p
                  className={`${
                    errorHandler.startDate && !startDate
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"text-base text-[16px] font-bold ml-2 mb-0"}`}
                >
                  {strings.START_DATE}:
                </p>
                <div
                  className={`${
                    errorHandler?.startDate && !startDate ? "errorClass" : ""
                  } ${"flex items-center gap-2 text-[18px] font-bold justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 newDateInputClass"}`}
                >
                  <DatePicker
                    oneTap
                    caretAs={HiCalendar}
                    id="datepicker-1"
                    placeholder={strings.START_DATE_SMALL}
                    format="yyyy.MM.dd"
                    block
                    editable={false}
                    appearance="subtle"
                    className="custom-datepicker bg-transparent border-0 w-full px-0"
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    onChangeCalendarDate={(e) => {
                      setCurrentMonth(e);
                    }}
                    onNextMonth={(e) => {
                      setCurrentMonth(e);
                    }}
                    onPrevMonth={(e) => {
                      setCurrentMonth(e);
                    }}
                    onOpen={() => {
                      setIsCalenderOpen(true);
                      setDatePickerCheck(1);
                      setCurrentMonth(
                        startDate ? new Date(startDate) : new Date()
                      );
                    }}
                    onClose={() => {
                      setIsCalenderOpen(false);
                      setCurrentMonth(
                        startDate ? new Date(startDate) : new Date()
                      );
                      setDatePickerCheck("");
                    }}
                    disabledDate={(date) => {
                      const maxDate = new Date(
                        Date.now() - 24 * 60 * 60 * 1000
                      );
                      return date < maxDate;
                    }}
                  />
                </div>
                {errorHandler.startDate && !startDate && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.startDate}
                  </p>
                )}
              </div>
              <div className="w-full ">
                <p
                  className={`${
                    errorHandler.job && jobType?.value == null
                      ? "text-[#F87171]"
                      : "text-white"
                  } ${"text-base font-bold text-[16px] text- ml-2 mb-0"}`}
                >
                  {strings.JOB}:
                </p>
                <div
                  onClick={() => {
                    if (jobType.value !== 2) {
                      setSelectJob(!selectJob);
                      setSelectLevel(false);
                      setSelectHire(false);
                      setSelectstatus(false);
                    }
                  }}
                  className={`${
                    errorHandler.job && jobType?.value == null
                      ? "errorClass"
                      : ""
                  } ${"flex items-center gap-2 cursor-pointer justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative dropdownClass"}`}
                >
                  <input
                    type="text"
                    className="bg-[#414142] font-bold text-[18px] rounded-[10px] placeholder:font-normal cursor-pointer h-[42px] px-2 text-white w-full caret-transparent	"
                    // id="nameId"
                    autoComplete="off"
                    placeholder={strings.SELECT_JOB_TYPE}
                    value={jobType?.title}
                  />
                  <Icon
                    icon="flowbite:caret-down-solid"
                    width="20"
                    height="20"
                    style={{ color: "#BEBEBE" }}
                    rotate={selectJob ? 2 : 4}
                  />
                  {selectJob && (
                    <JobDropDown
                      setJobType={setJobType}
                      setErrorHandler={setErrorHandler}
                      errorHandler={errorHandler}
                    />
                  )}
                </div>
                {errorHandler.job && !jobType?.value && (
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.job}
                  </p>
                )}
              </div>
              {!editStaff && (
                <div className="w-full text-[18px]">
                  <p
                    className={`${
                      errorHandler?.hireType && hireType.value == null
                        ? "text-[#F87171]"
                        : "text-white"
                    } ${"text-base text-[16px] font-bold ml-2 mb-0"}`}
                  >
                    {strings.HIRE_TYPE}:
                  </p>
                  <div
                    onClick={() => {
                      setSelectLevel(false);
                      setSelectHire(!selectHire);
                      setSelectJob(false);
                      setSelectstatus(false);
                    }}
                    className={`${
                      errorHandler.hireType && hireType.value == null
                        ? "errorClass"
                        : ""
                    } ${"flex items-center gap-2 cursor-pointer justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative dropdownClass"}`}
                  >
                    <input
                      type="text"
                      className="bg-[#414142]
                    ml:10 rounded-[10px] h-[42px]  cursor-pointer px-2 text-white w-full caret-transparent	"
                      // id="nameId"
                      autoComplete="off"
                      placeholder={strings.TITLE}
                      value={hireType.title}
                    />
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                      rotate={selectHire ? 2 : 4}
                    />
                    {selectHire && (
                      <HireDropDown
                        hireValue={setHireType}
                        setErrorHandler={setErrorHandler}
                        errorHandler={errorHandler}
                      />
                    )}
                  </div>
                  {errorHandler.hireType && hireType.value == null && (
                    <p className="mb-0 text-base text-[#F87171]">
                      {errorHandler?.hireType}
                    </p>
                  )}
                </div>
              )}
            </div>
            {editStaff && (
              <div className="flex items-start gap-3 max-lg:flex-wrap my-2">
                <div className="w-full text-[18px]">
                  <p className="text-base text-[16px] text-bold  text-[#ffffff] ml-2 mb-0">
                    {strings.HIRE_TYPE}:
                  </p>
                  <div
                    onClick={() => {
                      setSelectLevel(false);
                      setSelectHire(!selectHire);
                      setSelectJob(false);
                      setSelectstatus(false);
                    }}
                    className="flex items-center gap-2 cursor-pointer justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative dropdownClass"
                  >
                    <input
                      type="text"
                      className="bg-[#414142]
                    ml:10 rounded-[10px] h-[45px] font-bold placeholder:font-normal text-[18px] cursor-pointer px-2 text-white w-full caret-transparent	"
                      // id="nameId"
                      autoComplete="off"
                      placeholder={strings.TITLE}
                      value={hireType.title}
                    />
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                      rotate={selectHire ? 2 : 4}
                    />
                    {selectHire && (
                      <HireDropDown
                        hireValue={setHireType}
                        setErrorHandler={setErrorHandler}
                        errorHandler={errorHandler}
                      />
                    )}
                  </div>
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.hireType}
                  </p>
                </div>
                <div className="w-full text-[18px] text-bold">
                  <p className="text-base text-[16px] text-[#ffffff] font-bold ml-2 mb-0">
                    {strings.STATUS}:
                  </p>
                  <div
                    onClick={() => {
                      setSelectstatus(!selectstatus);
                      setSelectLevel(false);
                      setSelectHire(false);
                      setSelectJob(false);
                    }}
                    className="flex items-center gap-2 cursor-pointer justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative dropdownClass"
                  >
                    <input
                      type="text"
                      className="bg-[#414142]
                    ml:10 rounded-[10px] font-bold text-[18px] h-[45px] cursor-pointer px-2 text-white w-full caret-transparent	"
                      // id="nameId"
                      autoComplete="off"
                      placeholder={strings.TITLE}
                      value={status.title}
                    />
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                      rotate={selectstatus ? 2 : 4}
                    />
                    {selectstatus && (
                      <StatusDropDown
                        statusValue={setStatusType}
                        setErrorHandler={setErrorHandler}
                        errorHandler={errorHandler}
                      />
                    )}
                  </div>
                  <p className="mb-0 text-base text-[#F87171]">
                    {errorHandler?.status}
                  </p>
                </div>
              </div>
            )}
            <div
              className={`${
                imagePreview &&
                imagePreview.length > 0 &&
                imagePreview.length < 4
                  ? "justify-start"
                  : innerLoader
                  ? "justify-start"
                  : "justify-center"
              } flex items-center `}
            >
              <div
                className={`$
                  !imagePreview || imagePreview.length == 0
                    ? innerLoader
                      ? "justify-start"
                      : "justify-center"
                    : "justify-start"
                } ${"flex items-center my-6 gap-4 flex-wrap"}`}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  id="uploadMedia"
                  onChange={(e) => getVideoThumbnail(e)}
                />
                {(!imagePreview || imagePreview.length == 0) && (
                  <label
                    htmlFor="uploadMedia"
                    style={{ border: "2px solid #4B4B4B" }}
                    className={`${
                      innerLoader && "hidden"
                    } ${"flex items-center gap-3 rounded-[10px] py-2.5 px-6 mt-5 cursor-pointer"}`}
                  >
                    <Icon
                      icon="humbleicons:upload"
                      width="48"
                      height="48"
                      style={{ color: "white" }}
                    />
                    <p className="mb-0 text-[24px] font-bold">
                      {strings.UPLOAD_MEDIA_FILES}
                    </p>
                  </label>
                )}
                {imagePreview &&
                  imagePreview?.map((item, index) => (
                    <div
                      className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] relative"
                      key={index}
                    >
                      <img
                        src={item.original || item.view}
                        alt="item"
                        className="w-full h-full object-cover rounded-[10px]"
                      />
                      {(role == 2 ||
                        staffId == defultValue?.staffDetails?.staffId) && (
                        <span
                          onClick={() => {
                            removeProduct(item.original, index);
                          }}
                          className="absolute top-2 w-[30px] h-[30px] right-2 flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="iconamoon:close-bold"
                            width="25"
                            height="25"
                            style={{ color: "white", cursor: "pointer" }}
                          />
                        </span>
                      )}

                      {item?.type == "video" && (
                        <span
                          onClick={() => {
                            setVideoPreview(true);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                {(role == 2 || staffId == defultValue?.staffDetails?.staffId) &&
                  ((imagePreview?.length > 0 && imagePreview?.length !== 5) ||
                    innerLoader) && (
                    <label
                      htmlFor="uploadMedia"
                      className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] flex items-center justify-center relative"
                    >
                      {!innerLoader ? (
                        <Icon
                          icon="bitcoin-icons:plus-filled"
                          width="100"
                          height="100"
                          style={{ color: "white" }}
                        />
                      ) : (
                        <span className="absolute w-full h-full flex items-center justify-center">
                          <Icon
                            icon="eos-icons:bubble-loading"
                            width="60"
                            height="60"
                            style={{ color: "#CCFF00" }}
                            className="loader-icon "
                          />
                        </span>
                      )}
                    </label>
                  )}
              </div>
            </div>
            {error && (
              <p className="mt-12 mb-4 text-[18px] font-extrabold text-[#FFB0B0] text-center">
                {error}
              </p>
            )}
            {editStaff && (
              <div>
                <p className="mb-0  text-[24px] text-center font-bold pb-4">
                  Trainer profiles can only be changed by the trainer in there
                  own app.
                </p>
              </div>
            )}
            <div
              className={` ${"flex-wrap flex items-center justify-center gap-3 mt-5"}`}
            >
              <button
                onClick={() => {
                  setRegisterModal(false);
                  setEditStaff(false);
                  setCode("");
                  setName("");
                  setNickName("");
                  setCellPhone("");
                  setEmail("");
                  setStartDate("");
                  setJobType("");
                  setHireType("");
                  setStaffData("");
                  setStaffId("");
                }}
                className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
              >
                {strings.CANCEL}
              </button>
              <button
                disabled={!innerLoader ? false : true}
                onClick={() => {
                  editStaff ? EditStaffRegistetion() : staffRegistetion();
                }}
                className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {editStaff ? strings.UPDATE : strings.REGISTER}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}
export default StaffRegister;

function Level({ setLevel }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-12 max-h-[200px] overflow-auto z-10 px-3">
      <div
        onClick={() => {
          setLevel("Team Leader");
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.TEAM_LEADER}</span>
      </div>
      <div
        onClick={() => {
          setLevel("Rising Star");
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.RISING_STAR}</span>
      </div>
      <div
        onClick={() => {
          setLevel("Professional Certificate Holder");
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">
          {strings.PROFESSIONAL_CERTIFICATE_HOLDER}
        </span>
      </div>
      <div
        onClick={() => {
          setLevel("Competition Experience");
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">
          {strings.COMPETITION_EXPERIENCE}
        </span>
      </div>
      <div
        onClick={() => {
          setLevel("Competition Winner");
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">
          {strings.COMPETITION_WINNER}
        </span>
      </div>
    </div>
  );
}

function JobDropDown({ setJobType, setErrorHandler, errorHandler }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-12 max-h-[200px] overflow-auto z-10 px-3">
      {rolesData?.map((role) => {
        return (
          <div
            onClick={() => {
              setJobType(role);
              const errorHandle = {
                ...errorHandler,
                job: "",
              };
              setErrorHandler(errorHandle);
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{role.title}</span>
          </div>
        );
      })}
    </div>
  );
}

function HireDropDown({ hireValue, setErrorHandler, errorHandler }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-12 max-h-[200px] overflow-auto z-10 px-3">
      {hireData.map((item) => {
        return (
          <div
            onClick={() => {
              // setTitleValue({ text: "All", value: "all" });
              // setStatusType({ text: strings.ALL, type: "" });
              hireValue(item);
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{item.title}</span>
          </div>
        );
      })}
    </div>
  );
}

function StatusDropDown({ statusValue, setErrorHandler, errorHandler }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-12 max-h-[200px] overflow-auto z-10 px-3">
      {statusData.map((item) => {
        return (
          <div
            onClick={() => {
              statusValue(item);
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{item.title}</span>
          </div>
        );
      })}
    </div>
  );
}
