import React, { useState } from "react";
import profile from "../../assests/images/demoProfile.svg";
import moment from "moment";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import trainer from "../../assests/icons/boldTrainer.svg";
import { strings } from "../../utils/String";
import sessionGray from "../../assests/icons/statistics PT session/sessionCardGray.svg";
import $ from "jquery";

const iconObj = {
  workoutSatisfaction: { type: "Workout satisfaction" },
  workoutIntensity: { type: "Workout intensity" },
  workoutDuration: { type: "Workout Duration" },
  workoutDirection: { type: "Workout direction" },
  workoutTypeSatisfaction: {
    type: "Workout type satisfaction",
  },
  communicationSatisfaction: {
    type: "Communication satisfaction",
  },
  feedbackTimingSatisfaction: {
    type: "Feedback timing satisfaction",
  },
  sessionPunctuality: { type: "Session Punctuality" },
};

const getKeys = (keys) => {
  const keyNames = keys?.map((key) => {
    return iconObj[key]?.type;
  });
  return keyNames?.toString();
};

function ReviewCard({ item, setOpenDetail }) {
  const [openTooltip, setOpenTooltip] = useState(null);
  const [commentIndex, setCommentIndex] = useState("");

  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setOpenTooltip(false);
    }
  });
  return (
    <div
      className="bg-[#353638] rounded-[10px] cursor-pointer"
      onClick={() => {
        setOpenDetail({ status: true, id: item.id });
      }}
    >
      <div className="bg-[#474749] rounded-[10px] px-3 py-3 cursor-pointer">
        <div className="flex items-center gap-x-3 flex-wrap gap-y-2">
          <div className="flex items-center gap-2">
            <img
              src={
                item?.userProfile?.mProfileImg?.prof_img1_min
                  ? item?.userProfile?.mProfileImg.prof_img1_min
                  : profile
              }
              alt="profile-img"
              className="rounded-full w-[47px] h-[47px]"
            />
            <p className="mb-0 text-2xl font-extrabold border-r border-[#7B7B7B] pr-4">
              {item?.userProfile?.name}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon
              icon="gridicons:calendar"
              width="25px"
              height="25px"
              style={{ color: " #C5C5C5" }}
            />
            <p className="mb-0 text-lg font-bold border-r border-[#7B7B7B] pr-4">
              {moment(item?.sessionDetails?.bookedDate).format("YY.MM.DD")}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <img src={sessionGray} alt="session-gray" />
            <p className="mb-0 text-xl font-bold">
              {item?.sessionDetails?.sessionCompleted}
              <span className="text-white text-sm">
                /{item?.sessionDetails?.totalCount}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 ">
        <div className="flex items-center justify-between gap-3 py-3 border-b border-[#505050]">
          <div
            className="flex items-center gap-x-1 gap-y-2 bg-[#2C2C2E] rounded-[5px] w-max px-2 py-1"
            style={{ border: "1px solid #ccff0073" }}
          >
            <img src={trainer} alt="trainer" className="w-[22px] h-[23px]" />
            <span className="text-2xl font-bold text-[#CCCDCD]">
              {strings.TRAINER}:
            </span>
            <p className="mb-0 text-2xl font-bold max-w-[90px] overflow-hidden text-ellipsis whitespace-nowrap">
              {item?.gymStaffInfo?.gymStaffName}
            </p>
          </div>
          <p className="mb-0 text-2xl font-extrabold flex items-center gap-2">
            <Icon
              icon="tabler:star-filled"
              width="30"
              height="30"
              style={{ color: "#CCFF00" }}
            />
            {item.avgRating.toFixed(1)}
          </p>
        </div>
        <div className="w-[96%] m-auto">
          {item?.ratings?.map((val, index) => {
            return (
              <div
                key={index}
                className={`${
                  index == 2 ? "border-b-0" : "border-b border-[#505050]"
                } ${"flex items-center gap-3  py-2 flex-wrap overflow-hidden text-ellipsis"}`}
              >
                <div className="flex items-center gap-1.5 ">
                  {[1, 2, 3, 4, 5].map((item) =>
                    item <= val.rating ? (
                      <Icon
                        icon="ph:star-fill"
                        width="35"
                        height="35"
                        style={{ color: "#F6EB61" }}
                      />
                    ) : (
                      <Icon
                        icon="ph:star-light"
                        width="35"
                        height="35"
                        style={{ color: "#616161" }}
                      />
                    )
                  )}
                </div>
                {val.rating <= 5 && val?.comment && (
                  <>
                    <div className="flex items-center gap-1.5 w-[55%] text-ellipsis">
                      <p className="!mb-0 mt-0 text-lg font-bold flex   whitespace-nowrap  text-ellipsis   ">
                        {getKeys(val.keys)}
                      </p>
                    </div>
                  </>

                  //   {/* <div
                  //     onClick={() => {
                  //       setOpenTooltip(openTooltip ? null : val.rating);
                  //       setCommentIndex(index);
                  //     }}
                  //     className="w-[40px] h-[40px] rounded-[10px] flex items-center justify-center p-3 bg-[#474749] relative dropdownClass"
                  //   >
                  //     <Icon
                  //       icon="mage:message-check"
                  //       width="30"
                  //       height="30"
                  //       style={{
                  //         color:
                  //           openTooltip && commentIndex == index
                  //             ? "#CCFF00"
                  //             : "white",
                  //       }}
                  //     />
                  //     {openTooltip &&
                  //       openTooltip <= 2 &&
                  //       val?.comment &&
                  //       commentIndex == index && (
                  //         <TooltipComponent contentValue={val?.comment} />
                  //       )}
                  //   </div> */}
                  // </div>
                )}{" "}
              </div>
            );
          })}
          <div className="flex items-center gap-2 pt-2 pb-3">
            <Icon
              icon="gridicons:calendar"
              width="26px"
              height="26px"
              style={{ color: " #fff" }}
            />
            <p className="mb-0 text-lg font-bold">
              {moment(item?.regDate).format("YY.MM.DD")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCard;

function TooltipComponent({ contentValue }) {
  return (
    <div className="rounded-[10px] w-[300px] h-auto absolute bg-[#636363] bottom-16 shadow-2xl right-0 z-10">
      <div className="relative w-full h-full p-3">
        <p className="mb-0 font-bold text-base">{contentValue}</p>
        <Icon
          icon="el:caret-down"
          width="30"
          height="30"
          style={{ color: "#636363" }}
          className="absolute right-2"
        />
      </div>
    </div>
  );
}
