import { Icon } from "@iconify-icon/react";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function WorkoutMedia({ images, isScrolling, setIsScrolling, classNameValue }) {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
  const videoExtensions = [
    "mp4",
    "avi",
    "mov",
    "wmv",
    "flv",
    "mkv",
    "webm",
    "m4v",
  ];

  const [imageIndex, setImageIndex] = useState(0);

  const nextHandler = () => {
    setImageIndex(imageIndex + 1);
  };

  const previousHandler = () => {
    setImageIndex(imageIndex - 1);
  };

  const extension = images[imageIndex]?.video.split(".").pop().toLowerCase();

  return (
    <Modal
      className={`${classNameValue} ${"classForMargin modalBoxClass"}`}
      show={isScrolling.status}
      onHide={() => {
        setIsScrolling({ status: false });
      }}
    >
      <Modal.Body className="bg-box_bg p-0">
        <div className="w-full rounded-[5px] relative flex items-center gap-2">
          <div
            onClick={() => {
              previousHandler();
            }}
            className={`${
              imageIndex == 0 && "hidden"
            } ${"absolute cursor-pointer left-4 z-10 rounded-full bg-[#cff82d82] w-[50px] h-[50px] flex items-center justify-center"}`}
          >
            <Icon
              icon="tabler:chevron-left"
              width={40}
              height={40}
              style={{ color: "#fff" }}
            />
          </div>

          <div className="flex justify-center items-center m-auto w-50 h-50 relative ">
            {imageExtensions.includes(extension) ? (
              <img
                className="rounded-lg object-contain w-full h-[550px]"
                src={images[imageIndex]?.video}
                alt=""
              />
            ) : (
              videoExtensions.includes(extension) && (
                <div className="w-full rounded-lg h-full">
                  <video controls className="w-full h-[550px]">
                    <source src={images[imageIndex]?.video} type="video/mp4" />
                  </video>
                </div>
              )
            )}
          </div>

          <div
            onClick={() => {
              nextHandler();
            }}
            className={`${
              images.length - 1 == imageIndex && "hidden"
            } ${"absolute cursor-pointer right-4 z-10 rounded-full bg-[#cff82d82] w-[50px] h-[50px] flex items-center justify-center"}`}
          >
            <Icon
              icon="tabler:chevron-right"
              width={40}
              height={40}
              style={{ color: "#fff" }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default WorkoutMedia;
