import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import MembersSublyApi from "../../../helper/MemberApi";
import { useSelector } from "react-redux";
import { elements } from "chart.js";
import Moment from "react-moment";
import { formatPrice } from "../../../utils/commonUtils";

function AddProductList({
  classNameValue,
  setProductModal,
  productArr,
  setProductArr,
  setIsLoader,
  takeOverProduct,
  memberId,
  setMemberId,
}) {
  const { token } = useSelector((state) => state.user);
  const [productList, setProductList] = useState("");
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");

  // ============GEt product list API============
  useEffect(() => {
    async function productListHandle() {
      setIsLoader(true);
      await MembersSublyApi.gymProductList(token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setProductList(response.data || []);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    productListHandle();
  }, []);

  // ===========ADD PRODUCT HANDLER============
  function productHandler() {
    const indexValue = productArr?.findIndex((val) => val.type == productType);
    // if (indexValue !== -1) {
    //   const images = [...productArr];
    //   images.splice(indexValue, 1);
    //   console.log(images);
    //   if (productName.id) {
    //     images.push({
    //       productName: productName.productName,
    //       id: productName.id,
    //       type: productType,
    //       membershipStartDate: productName.membershipStartDate,
    //       price: productName.finalPrice,
    //       discountPrice: 0,
    //     });
    //     setProductArr(images);
    //     setProductModal(false);
    //   }
    // } else {
    const product = [...productArr];
    if (productArr.length > 0 || productName.id) {
      if (productName.id) {
        const productPrasent = product.find((p) => p.id === productName.id);
        if (!productPrasent) {
          product.push({
            productName: productName.productName,
            id: productName.id,
            type: productType,
            membershipStartDate: productName.membershipStartDate,
            price: productName.finalPrice,
            discountPrice: 0,
            newProduct: true,
          });
          setProductArr(product);
          setProductModal(false);
        }
      } else {
        setProductModal(false);
      }
    }
    // }
  }


  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[95%] max-h-[92%] max-lg:p-4 max-lg:py-8  p-10 pb-6 mt-10 z-20 overflow-auto"}`}
      >
        <div className="w-[100%] m-auto">
          <h3 className="text-[40px] max-lg:text-[32px] font-extrabold text-white border-b border-[#444444] pb-8 text-center">
            {strings.ADD_PRODUCT}
          </h3>
          <div className="max-h-[574px] min-h-[300px] overflow-x-auto my-5">
            {" "}
            {Object.keys(productList) == 0 && (
              <p className="text-2xl font-extrabold text-[#747474] text-center my-6">
                {strings.NO_RECORD_FOUND}
              </p>
            )}
            {!Object.keys(productList).length == 0 && (
              <div className="max-2xl:w-[1350px] border-x border-[#444444] border-t">
                <div className="flex justify-around items-center bg-[#5C5C5C] h-[70px] border-b-[16px] border-[#353638]">
                  <th className="!text-center text-[20px] font-bold text-white w-[80%] ">
                    {strings.PRODUCT_TYPE}
                  </th>
                  <th className="!text-center text-[20px] font-bold text-white w-full border-x border-[#444444] h-[55px] flex justify-center items-center ">
                    {strings.PRODUCT_NAME}
                  </th>
                  <th className="!text-center text-[20px] font-bold text-white w-[80%] ">
                    {strings.PERIOD_COUNT}
                    <span className="text-white text-base ">
                      {strings.BONUS_SUB}
                    </span>
                  </th>
                  <th className="!text-center text-[20px] font-bold text-white w-[48%] border-x border-[#444444] h-[55px] flex justify-center items-center ">
                    {strings.PRICE}
                  </th>
                  <th className="!text-center text-[20px] font-bold text-white w-[48%] ">
                    {strings.DISCOUNT}
                  </th>
                  <th className="!text-center text-[20px] font-bold text-white w-[48%] border-x border-[#444444] h-[55px] flex justify-center items-center ">
                    {strings.FINAL_PRICE}
                  </th>
                  <th className="!text-center text-[20px] font-bold text-white w-[85%] ">
                    {strings.START_TO_END_DATE}
                  </th>
                </div>
                <div className="border-b border-[#444444]">
                  {productList
                    ? Object.keys(productList).map((items) => (
                        <div
                          key={items}
                          className={` ${"last:border-b-0 border-b-[16px] border-[#353638]"} ${"bg-[#2C2C2E] flex justify-around items-center cursor-pointer"}`}
                        >
                          <td
                            align="center"
                            className={` 
                          ${"text-white font-bold text-2xl border-r border-[#353638] relative w-[19.4%] h-full flex items-center justify-center"}`}
                          >
                            {items}
                          </td>
                          <td
                            align="center"
                            className="text-white font-normal  text-lg w-[100%] py-[12px] px-2"
                          >
                            {productList[items] &&
                              productList[items].map((item, index) => (
                                <div
                                  onClick={() => {
                                    const product = [...productArr];
                                    const productPrasent = product.find(
                                      (p) => p.id === item.id && p.newProduct
                                    );
                                    if (productPrasent ) {
                                      const filtered = product.filter(
                                        (p) => p.id !== item.id
                                      );
                                      setProductArr(filtered);
                                      setProductName("");
                                    } else {
                                      setProductType(items);
                                      setProductName(item);
                                    }
                                  }}
                                  key={index}
                                  className={` ${
                                    ((productArr.length > 0 ||
                                      takeOverProduct.length > 0) &&
                                      [...takeOverProduct, ...productArr].some(
                                        (value) => {
                                          if (
                                            value.id == item.id ||
                                            value.prodId == item.id
                                          ) {
                                            return true;
                                          }
                                        }
                                      )) ||
                                    (productName && productName.id == item.id)
                                      ? "activeBorder bg-[#353638]"
                                      : "border-b border-[#444444] last:border-b-0"
                                  } ${"flex items-center py-1.5"}`}
                                >
                                  {" "}
                                  <div className="w-[100%]">
                                    <p className="mb-0 text-lg font-bold text-start flex items-center gap-1">
                                      <Icon
                                        icon="bi:dot"
                                        width="25"
                                        height="25"
                                        style={{ color: "white" }}
                                      />
                                      {item.productName}
                                    </p>
                                  </div>
                                  <div className="w-[75%]">
                                    {items == "PT" || items == "golf" ? (
                                      <p className="mb-0 text-lg font-bold w-[60%] text-start">
                                        {item?.sessionCount}
                                        {item?.sessionAddonCount ?
                                          `(${item?.sessionAddonCount})`:""}
                                      </p>
                                    ) : (
                                      <p className="mb-0 text-lg font-bold w-[60%] text-start">
                                        {item?.periodMonths || 0} M
                                        {item?.periodDays &&
                                          `(+${item?.periodDays}d)`}
                                      </p>
                                    )}
                                  </div>
                                  <div className="w-[50%]">
                                    <p className="mb-0 text-lg font-bold">
                                      ₩{formatPrice(item.regularPrice)}
                                    </p>
                                  </div>
                                  <div className="w-[50%]">
                                    <p className="mb-0 text-lg font-bold">
                                      ₩{formatPrice(item.discountPrice)}
                                    </p>
                                  </div>
                                  <div className="w-[50%]">
                                    <p className="mb-0 text-lg font-bold">
                                      ₩{formatPrice(item.finalPrice)}
                                    </p>
                                  </div>
                                  <div className="w-[85%]">
                                    <p className="mb-0 text-lg font-bold">
                                      <Moment format="YY.MM.DD">
                                        {item?.membershipStartDate}
                                      </Moment>{" "}
                                      {"~"}{" "}
                                      <Moment format="YY.MM.DD">
                                        {item?.membershipEndDate}
                                      </Moment>
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </td>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            )}
          </div>
          <div className="flex-wrap flex items-center justify-center mt-8 mb-6 pt-8 gap-3">
            <button
              onClick={() => {
                setProductModal(false);
                setMemberId("");
              }}
              className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px]"}`}
            >
              {strings.CLOSE}
            </button>
            <button
              disabled={productName.id || productArr.length > 0 ? false : true}
              onClick={() => productHandler()}
              className={`${"max-sm:w-full text-black bg-common_color"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px] disabled:bg-[#ccff0094]"}`}
            >
              {strings.CONFIRM}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddProductList;
