import React, { useEffect, useState } from "react";
import { strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import gallary from "../../../../../assests/icons/gallary.svg";
import moment from "moment";
import { useSelector } from "react-redux";

import calender from "../../../../../assests/icons/calenderWithTick.svg";
import rightTick from "../../../../../assests/icons/rightTickBatch.svg";
import holidayCal from "../../../../../assests/icons/holidayCalendar.svg";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import RegisterHoliday from "./RegisterHoliday";
import Loader from "../../../../../utils/Loader";

function Holidays({
  onCLose,
  setRegisterHoliday,
  setIsLoader,
  registerHoliday,
  edit,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [scheduleData, setScheduleData] = useState("");
  const [holidaysList, setHolidaysList] = useState([]);
  const [eventDate, setEvenetDate] = useState("");
  const [detailedSchedule, setDetailedSchedule] = useState(false);

  // ======================Method and function for design calender===========================
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const getlastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDay();
  };
  const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const previousMonth = month === 0 ? 11 : month - 1;
  const previousMonthYear = month === 0 ? year - 1 : year;
  const daysInMonth = getDaysInMonth(year, month);
  const lastMonthDays = getDaysInMonth(year, previousMonth);
  const firstDayOfMonth = getFirstDayOfMonth(2024, month);
  const lastDay = getlastDayOfMonth(year, month);

  // ================Method to get next month remaining date================
  const nextDays = [...Array(6 - lastDay).keys()].map((day) => day + 1);

  // ================Method to get last month remaining date================

  const lastDays = [...Array(firstDayOfMonth).keys()].map(
    (day) => lastMonthDays - firstDayOfMonth + day + 1
  );

  // ================ Method to get current month date ================
  const daysArray = [...Array(daysInMonth).keys()].map((day) => day + 1);

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => {
      const prevMonth = prevDate.getMonth() - 1;
      return new Date(prevDate.getFullYear(), prevMonth, 1);
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const nextMonth = prevDate.getMonth() + 1;
      return new Date(prevDate.getFullYear(), nextMonth, 1);
    });
  };

  const getMonthInString = () => {
    return monthNames[month];
  };

  useEffect(() => {
    getHolidayDetails();
  }, [month, registerHoliday]);
  // =============Get Holiday Api ============

  async function getHolidayDetails() {
    // setIsLoader(true);
    await GymSettingSublyApi.getHoliday(token, month + 1, year)
      .then((response) => {
        if (response.statusCode == 200) {
          // setIsLoader(false);
          setHolidaysList(response?.data);
        }
      })
      .catch((err) => console.log(err));
  }
  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex md:items-center justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[41%] max-xl:w-[85%] max-h-[97%] max-lg:p-1 max-lg:py-2 px-[54px] pb-2 z-20 "}`}
      >
        <div className="w-[100%] max-lg:w-[100%] m-auto">
          <div className="w-[100%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold md:py-[20px] ">
              {strings.HOLIDAYS}
            </p>
          </div>
          <div className="flex flex-wrap text-white gap-3 sm:gap-7 justify-center  mt-3 mb-2">
            <div
              onClick={() => handlePrevMonth()}
              className="size-10 bg-[#2C2C2E] rounded-full flex items-center justify-center cursor-pointer"
            >
              <Icon
                icon="tabler:chevron-left"
                width="25px"
                height="25px"
                style={{ color: "white" }}
              />
            </div>
            <span className="font-bold text-[28px]">
              {year}.
              {`${+month + 1}`.length == 1 ? "0" + (month + 1) : month + 1}{" "}
            </span>
            <div
              onClick={() => handleNextMonth()}
              className="size-10 bg-[#2C2C2E] rounded-full flex items-center justify-center cursor-pointer"
            >
              <Icon
                icon="tabler:chevron-right"
                width="25px"
                height="25px"
                style={{ color: "white" }}
              />
            </div>
          </div>
          {/* ============== Calendar start from here ==================== */}

          <div className="overflow-auto">
            <div
              style={{ border: "1px solid #505050" }}
              className="flex mb-4 rounded-[10px]"
            >
              <div className="w-full bg-[#2C2C2E] rounded-[10px]">
                <div className="grid-cols-7 grid ">
                  {weekdays.map((day, index) => (
                    <div
                      key={index.toString()}
                      style={{ border: "1px solid #505050" }}
                      className={`${
                        eventDate && moment(eventDate).format("ddd") == day
                          ? "text-[#CCFF00]"
                          : "text-white"
                      } ${" py-3 ctr font-bold text-[12px] md:text-[18px] text-center first:rounded-tl-[10px] last:rounded-tr-[10px] "}`}
                    >
                      {day}
                    </div>
                  ))}
                </div>
                <div className="grid-cols-7 grid">
                  {lastDays?.map((i, n) => (
                    <div
                      key={n.toString()}
                      onClick={() => {
                        setEvenetDate("");
                      }}
                      style={{ border: "1px solid #505050" }}
                      className="sm:aspect-square h-full   items-start font-bold text-[12px] sm:text-[16px] xl:text-[18px] flex justify-center text-[#666666] py-1 "
                    >
                      {i}
                    </div>
                  ))}
                  {daysArray?.map((date, index) => {
                    let dateFound =
                      scheduleData?.length > 0 &&
                      scheduleData?.find((val) => {
                        return moment.utc(val.date).format("D") == date;
                      });

                    // Check if the current date (date, month + 1, year) is a holiday
                    const isHoliday = holidaysList.find((val) => {
                      return (
                        moment(val.date).format("YYYY-MM-DD") ===
                        moment(
                          `${year}-${(month + 1)
                            .toString()
                            .padStart(2, "0")}-${date
                            .toString()
                            .padStart(2, "0")}`
                        ).format("YYYY-MM-DD")
                      );
                    });
                    return (
                      <div
                        key={index.toString()}
                        style={{
                          border:
                            dateFound && eventDate == dateFound?.date
                              ? "1px solid #CCFF00"
                              : "1px solid #505050",
                        }}
                        className=""
                      >
                        <div
                          style={{
                            border: isHoliday ? "3px solid #FF9191" : "",
                          }}
                          className={`${
                            isHoliday ? "bg-[#FFAFAF] bg-opacity-30" : ""
                          } aspect-square text-[12px] sm:text-[12px] xl:text-[14px] font-bold  flex  flex-col items-center justify-start text-white`}
                        >
                          {date}

                          {isHoliday && (
                            <img
                              className="img-fluid w-[15px] md:w-[20px]  xl:w-[20px] hidden lg:block"
                              src={holidayCal}
                              alt=""
                            />
                          )}

                          {
                            isHoliday && (
                              // holidaysList.map((item) => {
                              // console.log("item", item);
                              // return (
                              <div className="text-[16px] sm:text-[14px] md:text-[16px] text-center">
                                {isHoliday.name}
                              </div>
                            )
                            // );
                            // })
                          }
                        </div>
                      </div>
                    );
                  })}
                  {nextDays?.map((i, n) => (
                    <div
                      onClick={() => {
                        setEvenetDate("");
                      }}
                      key={n.toString()}
                      style={{ border: "1px solid #505050" }}
                      className="aspect-square items-start flex justify-center text-[#666666] py-1 text-[12px] sm:text-[16px] xl:text-[18px] last:rounded-br-[10px] boder"
                    >
                      {i}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* ============== Calendar ends here ==================== */}
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-[24px] mb-4"}`}
          >
            <button
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] sm:h-[60px] disabled:bg-[#ccff0094] py-0.5"
              onClick={() => {
                onCLose(false);
              }}
            >
              {strings.CLOSE}
            </button>
            <button
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black kakaoClass hover:text-black bg-common_color w-[160px] sm:h-[60px] disabled:bg-[#ccff0094] py-0.5"
              onClick={() => {
                setRegisterHoliday(true);
              }}
            >
              {strings.REGISTER}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Holidays;
