import React, { useState } from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import deleteIcon from "../../../assests/icons/delete-success.svg";
import { Icon } from "@iconify-icon/react";

function DeleteSuccess({
  classNameValue,
  text,
  deleteSuccess,
  setDeleteSuccess,
  setIsUpdate,
  isDelete,
  buttonKey,
}) {
  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass"}`}
        show={deleteSuccess}
        onHide={() => {
          setDeleteSuccess(false);
          setIsUpdate && setIsUpdate(false);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-16 px-8">
          {isDelete ? (
            <img
              src={deleteIcon}
              alt="delete"
              className="w-[100px] h-[100px] max-lg:w-[70px] max-lg:h-[70px] m-auto"
            />
          ) : (
            <p className="rounded-full border-[35px]  border-[#A6FF3466] w-36 h-36 m-auto flex items-center justify-center max-xl:border-[20px] max-xl:w-28 max-xl:h-28">
              <Icon
                icon="icon-park-solid:check-one"
                width="100"
                height="100"
                style={{ color: "#A6FF34" }}
                className="max-xl:hidden"
              />
              <Icon
                icon="icon-park-solid:check-one"
                width="80"
                height="80"
                style={{ color: "#A6FF34" }}
                className="hidden max-xl:block"
              />
            </p>
          )}
          <p className="text-[30px] max-lg:text-[18px] font-bold pt-4">
            {text}
          </p>
          <div className="flex justify-center pt-8 gap-4">
            {buttonKey ? (
              <button
                onClick={() => {
                  setDeleteSuccess(false);
                  // setIsUpdate(false);
                }}
                className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.CLOSE}
              </button>
            ) : (
              <button
                onClick={() => {
                  setDeleteSuccess(false);
                  setIsUpdate && setIsUpdate(false);
                }}
                className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.CONFIRM}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteSuccess;
