import React, { useEffect, useState } from "react";
import { strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import gallary from "../../../../../assests/icons/gallary.svg";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import Loader from "../../../../../utils/Loader";
import { FormMedia } from "../../../boardScreens/RegistrationForm";

function MachineInformation({
  setshowMachineInfo,
  machineId,
  setDeleteMemo,
  setShowAddMachineInfo,
  setDefult,
  equipmentType,
  classNameValue,
  setIsLoader,
}) {
  const { token } = useSelector((state) => state.user);
  const [equipmentData, setEquipmentData] = useState();
  const [uploadedMedia, setuploadedMedia] = useState();
  const [videoPreview, setVideoPreview] = useState(false);

  useEffect(() => {
    getMachineInfo();
  }, []);
  async function getMachineInfo() {
    setIsLoader(true);
    await GymSettingSublyApi.getMachineDetails(token, machineId)
      .then((response) => {
        setEquipmentData(response.data);
        setDefult(response.data);
        setIsLoader(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex items-center justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[70%] max-h-[90%] max-lg:p-4 max-lg:py-8 overflow-auto p-[60px] pb-4  z-20 "}`}
      >
        <div className="w-[100%] max-lg:w-[100%] m-auto  ">
          <div className="w-[100%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-[30px]">
              {strings.MACHINE_INFORMATION}
            </p>
          </div>

          <div className="text-white   my-[30px]  md:px-10">
            <div className="flex flex-wrap items-center justify-betwee gap-7  ">
              <div className="!size-[266px] relative rounded-[10px] bg-[#949494] flex justify-center items-center mx-auto">
                <img
                  className="!size-[266px] rounded-[10px]"
                  src={equipmentData?.files[0]?.url}
                  alt=""
                />
                {equipmentData?.files[0]?.type == "video" && (
                  <span
                    onClick={() => {
                      setuploadedMedia(equipmentData?.files[0]);
                      setVideoPreview(true);
                    }}
                    className="absolute top-[44%] right-[38%] w-[50px] h-[50px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                  >
                    <Icon
                      icon="ooui:play"
                      width="100"
                      height="100"
                      style={{ color: "white" }}
                    />
                  </span>
                )}
              </div>
              <div className="lg:flex-1 w-full">
                <h1 className="font-extrabold text-[30px]">
                  {equipmentData?.title}
                </h1>
                <p className="text-[18px] font-bold text-justify leading-6">
                  {equipmentData?.description}
                </p>
              </div>
            </div>
            {equipmentData?.files?.length > 1 && (
              <div className="flex flex-wrap items-center justify-center lg:justify-normal gap-3 mt-4 ">
                {equipmentData.files.map((item, index) => {
                  if (index > 0) {
                    return (
                      <div
                        key={index}
                        className="flex flex-wrap items-center justify-center lg:justify-normal gap-3"
                      >
                        <div className="size-[150px] relative rounded-[10px] bg-[#949494] flex items-center justify-center">
                          <img
                            src={item?.url}
                            className="size-[150px] rounded-[10px]"
                            alt=""
                          />
                          {item?.type == "video" && (
                            <span
                              onClick={() => {
                                setuploadedMedia(item);
                                setVideoPreview(true);
                              }}
                              className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                            >
                              <Icon
                                icon="ooui:play"
                                width="70"
                                height="70"
                                style={{ color: "white" }}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            )}
          </div>

          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-[90px] mb-4"}`}
          >
            <button
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              onClick={() => {
                setDeleteMemo(true);
              }}
            >
              {strings.DELETE}
            </button>
            <button
              // onClick={() => {
              //   setOpenDetail(false);
              //   setProductId("");
              // }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              onClick={() => {
                setshowMachineInfo(false);
              }}
            >
              {strings.CLOSE}
            </button>
            <button
              onClick={() => {
                setShowAddMachineInfo(true);
                setshowMachineInfo(false);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-black kakaoClass hover:text-black bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.EDIT}
            </button>
          </div>
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default MachineInformation;
