import React, { useEffect, useState } from "react";
import Header from "../../commonComponents/Header";
import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import MemberListItem from "../../commonComponents/MemberListItem";
import SideBar from "../../commonComponents/SideBar";
import "./MemberSearch.css";
import MemberRegisterForm from "./MemberRegisterForm";
import $ from "jquery";
import MemberInfoDetail from "./memberInfoDetail/MemberInfoDetail";
import LockerListModule from "./memberInfoDetail/LockerListModule";
import MembersSublyApi from "../../../helper/MemberApi";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import TrainerList from "./TrainerList.js";
import AddProductList from "./AddProductList.js";
import Loader from "../../../utils/Loader.js";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal.js";
import { alphaOnly, numberOnly } from "../../../utils/commonUtils.js";

function MemberSearch() {
  const { token } = useSelector((state) => state.user);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [lockerList, setLockerList] = useState(false);
  const [memberListData, setMemberListData] = useState("");
  const [lockerValue, setLockerValue] = useState({
    name: "",
    password: "",
    id: "",
  });
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [active, setActive] = useState("active");
  const [memberId, setMemberId] = useState("");
  const [addProductModal, setAddProductModal] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [trainerModal, setTrainerModal] = useState(false);
  const [trainerHeading, setTrainerHeading] = useState(false);
  const [trainerValue, setTrainerValue] = useState({ name: "", id: "" });
  // console.log("------TRAINER VALUE",trainerValue)
  const [productModal, setProductModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [productArr, setProductArr] = useState([]);
  const [addProduct, setAddProduct] = useState("");
  const location = useLocation();
  const [takeOverProduct, setTakeOverProduct] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [trainerId, setTrainerId] = useState();
  const [staffId, setStaffId] = useState();
  const [changeTrainer, setChangeTrainer] = useState(false);

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0
    ) {
      if (productModal && !addProduct) {
        setProductModal(false);
      } else if (lockerList) {
        setLockerList(false);
      } else if ((trainerModal || changeTrainer) && !showMoreInfo) {
        setTrainerModal(false);
        setChangeTrainer(false);
      } else if ((trainerModal || changeTrainer) && showMoreInfo) {
        setTrainerModal(false);
        setChangeTrainer(false);
        setShowMoreInfo(true);
      } else if (showMoreInfo && (!trainerModal || !changeTrainer)) {
        setShowMoreInfo(false);
      } else if (
        !productModal &&
        !lockerList &&
        !trainerModal &&
        !showRegisterForm
      ) {
        // setAddProduct(false);
        // setProductArr([]);
        // setShowMoreInfo(false);
        setTrainerModal(false);
        setChangeTrainer(false);
        setMemberId("");
      }
      // setLockerValue({ name: "", password: "", id: "" });
      // setTrainerValue({ name: "", id: "" });
      // setProductArr([]);
    }
  });

  // ==========Fetch member list============
  useEffect(() => {
    async function memberListHandle() {
      if (!showMoreInfo && !showRegisterForm) {
        setIsLoader(true);
      }
      await MembersSublyApi.memberList(offset, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setMemberListData(response.data.response);
            setPage(response.data.count / 10);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    memberListHandle();
  }, [showRegisterForm, offset, showMoreInfo]);

  useEffect(() => {
    if (location?.state?.id) {
      setShowMoreInfo(true);
    }
  }, []);
  //--------function for handling name input filed------
  $("#nameId").bind("keypress", alphaOnly);

  //--------function for handling contact input filed------
  $("#numberId").bind("keypress", numberOnly);

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // ==========Search member handler==========
  async function searchHandle() {
    setIsLoader(true);
    await MembersSublyApi.memberList(1, token, name, number)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setMemberListData(response.data.response);
          setOffset(1);
          setPage(response.data.count / 10);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  return (
    <section>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue="block_notify"
      />
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col xl={10} className="p-0 relative overflow-auto">
            <Header text={strings.MEMBER_SEARCH} />
            <div className="px-6 ">
              <div className="flex items-center justify-between gap-4 mt-8 max-sm:flex-wrap">
                <div className="flex items-center justify-between gap-3 flex-wrap">
                  <div className="flex items-center justify-between gap-3 flex-wrap">
                    <label
                      htmlFor="nameId"
                      className="bg-[#2C2C2E] w-[320px] max-sm:w-full h-[50px] px-3 flex items-center gap-2 rounded-[30px]"
                    >
                      <p className="!mb-0 flex items-center gap-2 text-[#A1A1AA] font-bold text-lg max-sm:text-base whitespace-nowrap">
                        <Icon
                          icon="majesticons:user-line"
                          width="24"
                          height="24"
                          style={{ color: "white" }}
                        />
                      </p>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        className="bg-[#2C2C2E] text-white max-sm:w-full placeholder:text-[#A1A1AA] placeholder:font-bold placeholder:text-lg w-full"
                        id="nameId"
                        placeholder={strings.NAME_}
                        autoComplete="off"
                      />
                    </label>
                    <label
                      htmlFor="numberId"
                      className="bg-[#2C2C2E] max-sm:w-full w-[350px] h-[50px] px-3 flex items-center gap-2 rounded-[30px] numberFieldClass"
                    >
                      <p className="!mb-0 flex items-center gap-2 whitespace-nowrap">
                        <Icon
                          icon="ph:phone"
                          width="24"
                          height="24"
                          style={{ color: "white" }}
                        />
                      </p>
                      <input
                        type="number"
                        value={number}
                        placeholder={strings.CELL_PHONE_NO}
                        onChange={(e) => {
                          setNumber(e.target.value);
                        }}
                        className="bg-[#2C2C2E] text-white max-sm:w-full w-full numberField placeholder:text-[#A1A1AA] placeholder:font-bold placeholder:text-lg placeholder:max-sm:text-base"
                        id="numberId"
                        autoComplete="off"
                      />
                    </label>
                  </div>
                  <button
                    onClick={() => {
                      searchHandle();
                      setOffset(1);
                    }}
                    className="hover:text-black hover:bg-[#CCFF00] text-lg font-extrabold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
                <div className="max-sm:flex max-sm:justify-end max-sm:w-full">
                  <button
                    onClick={() => {
                      setShowRegisterForm(true);
                      setProductArr([]);
                      setAddProduct("");
                    }}
                    className="hover:bg-common_color text-lg font-extrabold hover:text-black rounded-[30px] h-[50px] w-[156px] closedNotify kakaoClass text-white"
                  >
                    {strings.REGISTER}
                  </button>
                </div>
              </div>
              <div className="mt-8 overflow-auto ">
                <div
                  className={`${
                    memberListData && memberListData.length > 0
                      ? "memberListClass h-[calc(100vh_-_280px)]"
                      : ""
                  } w-full `}
                >
                  {memberListData && memberListData.length > 0
                    ? memberListData.map((items, index) => (
                        <MemberListItem
                          items={items}
                          index={index}
                          setMemberId={setMemberId}
                          classNameValue={"closedNotify"}
                          setShowMoreInfo={setShowMoreInfo}
                          setTrainerId={setTrainerId}
                        />
                      ))
                    : ""}
                </div>
                {memberListData.length == 0 && (
                  <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                    {strings.NO_RECORD_FOUND}
                  </p>
                )}
              </div>
              <div className="mt-6">
                {" "}
                {page && page > 1 && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    forcePage={offset == 1 ? 0 : offset - 1}
                    pageCount={page}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={active}
                    onClick={() => setActive("active")}
                  />
                )}
              </div>
            </div>
            {showRegisterForm && (
              <MemberRegisterForm
                setLockerList={setLockerList}
                lockerValue={lockerValue}
                setShowRegisterForm={setShowRegisterForm}
                setLockerValue={setLockerValue}
                setTrainerModal={setTrainerModal}
                trainerValue={trainerValue}
                setTrainerValue={setTrainerValue}
                productModal={productModal}
                setProductModal={setProductModal}
                setIsLoader={setIsLoader}
                productArr={productArr}
                setProductArr={setProductArr}
                classNameValue={`${
                  showRegisterForm ? "block_notify" : "hideNotify"
                }`}
                addProduct={addProduct}
                setAddProduct={setAddProduct}
                setTakeOverProduct={setTakeOverProduct}
                takeOverProduct={takeOverProduct}
                setTrainerHeading={setTrainerHeading}
              />
            )}
            {showMoreInfo && (
              <MemberInfoDetail
                addProductModal={addProductModal}
                setAddProductModal={setAddProductModal}
                setShowMoreInfo={setShowMoreInfo}
                id={memberId ? memberId : location?.state?.id}
                setIsLoader={setIsLoader}
                toStatus={location?.state?.id ? true : false}
                classNameValue={`${
                  showMoreInfo ? "block_notify" : "hideNotify"
                }`}
                setAddProduct={setAddProduct}
                setShowRegisterForm={setShowRegisterForm}
                setTrainerModal={setTrainerModal}
                setTrainerValue={setTrainerValue}
                setTrainerId={setTrainerId}
                setStaffId={setStaffId}
                trainerModal={trainerModal}
                trainerValue={trainerValue}
                changeTrainer={changeTrainer}
                setChangeTrainer={setChangeTrainer}
              />
            )}
            {lockerList && (
              <LockerListModule
                setLockerList={setLockerList}
                lockerValue={lockerValue}
                setLockerValue={setLockerValue}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  showRegisterForm ? "block_notify" : "hideNotify"
                }`}
              />
            )}
            {trainerModal && (
              <TrainerList
                setTrainerModal={setTrainerModal}
                setTrainerValue={setTrainerValue}
                trainerValue={trainerValue}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  showRegisterForm || showMoreInfo || trainerModal
                    ? "block_notify"
                    : "hideNotify"
                }`}
                trainerId={trainerId}
                memberId={staffId}
                trainerHeading={trainerHeading}
              />
            )}
            {productModal && (
              <AddProductList
                productModal={productModal}
                setProductModal={setProductModal}
                setIsLoader={setIsLoader}
                productArr={productArr}
                setProductArr={setProductArr}
                takeOverProduct={takeOverProduct}
                classNameValue={`${
                  showRegisterForm || showMoreInfo
                    ? "block_notify"
                    : "hideNotify"
                }`}
                memberId={memberId}
                setMemberId={setMemberId}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default MemberSearch;
