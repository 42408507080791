import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
class AttendanceSublyApi {
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${BASE_URL}${endpoint}`;
    const headers = { ...header };
    try {
      const result = await axios({ url, method, data, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /* ------Login to Attendance screen API -----*/

  static async attendanceLogin(data) {
    let res = await this.request(
      `attendance/gym-login`,
      data,
      "post",
      undefined
    );
    return res;
  }

  //   ===============API for fetching event list============
  static async fetchEventList(token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `events?pageSize=${2000}&status=${5}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API for member attendance ============
  static async memberAttendance(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`attendance/member`, data, "post", header);
    return res;
  }

  //   ===============API for get gym owner detail============
  static async getOwnerDetail(id) {
    let res = await this.request(
      `attendance/gym-details/${id}`,
      undefined,
      "get",
      undefined
    );
    return res;
  }

  //   ===============API for send forgot password link============
  static async forgotPassword(data) {
    let res = await this.request(
      `attendance/forgot-password`,
      data,
      "post",
      undefined
    );
    return res;
  }

  //   ===============API for fetch user detail============
  static async fetchUserDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `attendance/member-products/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }
}

export default AttendanceSublyApi;
