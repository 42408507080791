import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import sessionIcon from "../../../assests/icons/Session icon.svg";
import phone from "../../../assests/icons/phone.svg";
import profile from "../../../assests/images/demoProfile.svg";
import { useSelector } from "react-redux";
import MembersSublyApi from "../../../helper/MemberApi";

function TrainerList({
  classNameValue,
  setTrainerModal,
  setTrainerValue,
  trainerValue,
  setIsLoader,
  trainerId,
  memberId,
  isChangeTrainer,
  trainerHeading,
}) {
  const { token } = useSelector((state) => state.user);
  const [trainerListData, setTrainerListData] = useState("");
  const [searchName, setSearchName] = useState("");
  const [newTrainer, setNewTrainer] = useState();

  // ===========Fetching trainer list API===============
  useEffect(() => {
    fetchTrainerList();
  }, []);
  async function fetchTrainerList() {
    setIsLoader(true);
    await MembersSublyApi.fetchTrainerList(searchName, token, trainerId)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setTrainerListData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function changeTrainer() {
    setIsLoader(true);
    await MembersSublyApi.changeTrainer(token, {
      gymStaffId: newTrainer,
      memberId,
    }).then((response) => {
      setIsLoader(false);
      if (response.statusCode == 200) {
        setTrainerModal(false);
      }
    });
  }
  return (
    <section
      className={`${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#2C2C2E] rounded-[10px] w-[90%] max-h-[90%] max-lg:p-4 max-lg:py-8  p-10 pb-6 mt-10 z-20 overflow-auto"}`}
      >
        <div className="w-[80%] max-2xl:w-[100%] m-auto">
          <h3 className="text-[40px] max-lg:text-[32px] font-extrabold text-white border-b border-[#444444] pb-8 text-center">
            {trainerHeading || trainerId == null
              ? strings.SELECT_TRAINER
              : strings.CHANGE_THE_TRAINER}
          </h3>
          <div className="flex items-center gap-3 my-4 justify-center w-3/5 m-auto">
            <label className="rounded-[30px] w-full bg-[#1F2022] flex items-center h-[50px] px-4 gap-2">
              <Icon
                icon="uil:user"
                width="28"
                height="28"
                style={{ color: "white" }}
              />
              <input
                type="text"
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
                className="w-full h-[45px] bg-transparent text-white placeholder:text-[#A1A1AA] placeholder:text-lg"
                placeholder={`${placeholder.NAME}:`}
              />
            </label>
            <button
              onClick={() => {
                fetchTrainerList();
              }}
              className="w-[120px] h-[50px] kakaoClass text-white text-lg font-extrabold rounded-[28px] hover:bg-common_color hover:text-black"
            >
              {strings.SEARCH}
            </button>
          </div>
          <div className="overflow-auto">
            <div className="w-full max-[1000px]:w-[900px] m-auto">
              <div className="bg-[#414142] h-[60px] py-3 px-3 flex items-center gap-2">
                <div className="flex items-center justify-center gap-2 border-r border-[#ffffff5c] w-[90%]">
                  <Icon
                    icon="teenyicons:user-circle-solid"
                    width="28"
                    height="28"
                    style={{ color: "#D5D5D5" }}
                  />
                  <p className="mb-0 text-xl font-bold">
                    {strings.TRAINER_NAME}
                  </p>
                </div>
                <div className="flex items-center justify-center gap-2 border-r border-[#ffffff5c] w-[80%]">
                  <img src={sessionIcon} />
                  <p className="mb-0 text-xl font-bold">
                    {strings.TOTAL_SESSIONS}
                  </p>
                </div>
                <div className="flex items-center justify-center gap-2 border-r border-[#ffffff5c] w-full">
                  <img src={phone} />
                  <p className="mb-0 text-xl font-bold">
                    {strings.CONTACT_NUMBER}
                  </p>
                </div>
                <div className="flex items-center justify-center gap-2 w-full">
                  <Icon
                    icon="mdi:email-outline"
                    width="28"
                    height="28"
                    style={{ color: "#D5D5D5" }}
                  />
                  <p className="mb-0 text-xl font-bold">{strings.EMAIL_}</p>
                </div>
              </div>
              {trainerListData?.length > 0
                ? trainerListData.map((item, index) => (
                    <div
                      onClick={() => {
                        setTrainerValue &&
                          setTrainerValue({
                            name: item.name,
                            id: item.trainerId,
                          });
                        setNewTrainer(item?.trainerId);
                      }}
                      className={`${
                        trainerValue &&
                        trainerValue?.id == item?.trainerId &&
                        "activeBorder"
                      } ${"h-[60px] py-3 px-3 flex items-center gap-2 mt-2 cursor-pointer"}`}
                      key={index}
                    >
                      <div className="flex items-center gap-2 w-[90%]">
                        <img
                          src={item?.profile?.prof_img1_min || profile}
                          className="w-[40px] h-[40px] rounded-full"
                        />
                        <p className="mb-0 text-lg font-bold">{item.name}</p>
                      </div>
                      <div className="w-[80%]">
                        <p className="mb-0 text-xl font-bold text-center">
                          {item.completedSession}/
                          <span className="text-base text-white">
                            {item.totalSession}
                          </span>
                        </p>
                      </div>
                      <div className="w-full">
                        <p className="mb-0 text-xl font-bold text-center">
                          {item.cellphone}
                        </p>
                      </div>
                      <div className="w-full">
                        <p className="mb-0 text-xl font-bold text-center">
                          {item.email}
                        </p>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
          {/* {trainerListData?.length > 0 && ( */}
          <div className="flex items-center justify-center mt-8 mb-6 pt-8 gap-3">
            <button
              onClick={() => setTrainerModal(false)}
              className={`${"text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px]"}`}
            >
              {strings.CLOSE}
            </button>
            <button
              disabled={trainerValue?.id ? false : true}
              onClick={() => {
                if (isChangeTrainer) {
                  changeTrainer();
                } else {
                  setTrainerModal(false);
                }
              }}
              className={`${"text-black bg-common_color"} ${"text-2xl font-extrabold h-[60px] w-[170px] rounded-[28px] disabled:bg-[#ccff0094]"}`}
            >
              {strings.CONFIRM}
            </button>
          </div>
          {/* )} */}
        </div>
      </div>
    </section>
  );
}

export default TrainerList;
