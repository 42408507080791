import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import demoProfile from "../../../assests/images/demoProfile.svg";
import { Icon } from "@iconify-icon/react";
import lifter from "../../../assests/icons/lifter.svg";
import file from "../../../assests/icons/fileIcon.svg";
import trainer from "../../../assests/icons/boldTrainer.svg";
import { Col, Row } from "react-bootstrap";
import WorkoutDetailCard from "../../commonComponents/WorkoutDetailCard";
import { useSelector } from "react-redux";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import moment from "moment";
import { WORKOUT_LISTS_TITLES } from "../../../utils/CommonData";
import { useNavigate } from "react-router-dom";
import legs from "../../../assests/icons/workout-icon/Leg.png";
import chest from "../../../assests/icons/workout-icon/Chest.png";
import back from "../../../assests/icons/workout-icon/Back.png";
import shoulder from "../../../assests/icons/workout-icon/Shoulder.png";
import biceps from "../../../assests/icons/workout-icon/biceps.png";
import abdominal from "../../../assests/icons/workout-icon/abdominal.png";
import arm from "../../../assests/icons/workout-icon/Arm.png";
import hip from "../../../assests/icons/workout-icon/Hip_icon.png";
import claves from "../../../assests/icons/workout-icon/claves_icon.png";
import cardio from "../../../assests/icons/workout-icon/Cardio.png";
import trainerMemo from "../../../assests/icons/dietlog-detail-icons/Trainer comment- icon.svg";
import memberMemoLime from "../../../assests/icons/dietlog-detail-icons/Member comment-icon-lime.svg";
import memberMemoOrange from "../../../assests/icons/dietlog-detail-icons/Personal-Member comment-icon-orange.svg";
import memberMemoBlue from "../../../assests/icons/dietlog-detail-icons/PT-Member comment-icon-blue.svg";

const workoutIcon = {
  leg: { type: "leg", icon: legs },
  back: { type: "back", icon: back },
  chest: { type: "chest", icon: chest },
  shoulders: { type: "shoulders", icon: shoulder },
  arms: { type: "arms", icon: arm },
  core: { type: "core", icon: abdominal },
  claves: { type: "claves", icon: claves },
  hip: { type: "hip", icon: hip },
  cardio: { type: "cardio", icon: cardio },
};

function WorkoutLogDetail({
  setOpenDetails,
  logId,
  setIsLoader,
  classNameValue,
  status,
  setPtHistory,
}) {
  const { token } = useSelector((state) => state.user);
  const [detailValue, setDetailValue] = useState("");
  const navigate = useNavigate();


  // ===========API for fetching workout-log detail=============
  useEffect(() => {
    async function fetchWorkoutDetail() {
      setIsLoader(true);
      await PT_InformationSublyApi.workoutLogDetail(token, logId.id)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setDetailValue(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchWorkoutDetail();
  }, []);

  return (
    <section
      className={`${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[90%] max-h-[90%] py-3 px-7 mt-10 z-20 overflow-auto"}`}
      >
        <div className="w-full m-auto">
          <div className="flex justify-between flex-wrap items-center border-b border-[#444444] pb-3">
            <div className="flex items-center gap-y-3 gap-x-4">
              <div className="flex items-center gap-2 ">
                <span className="rounded-full w-[58px] h-[58px]">
                  <img
                    src={
                      detailValue?.memberDetails?.mProfileImg?.prof_img1_min
                        ? detailValue?.memberDetails?.mProfileImg?.prof_img1_min
                        : demoProfile
                    }
                    className="w-full h-full rounded-full"
                  />
                </span>
                <p className="mb-0 text-[28px] font-extrabold border-r-2 border-[#505050] pr-4">
                  {detailValue?.memberDetails?.name}{" "}
                  {detailValue?.sessionStatus ==
                    WORKOUT_LISTS_TITLES.PERSONAL && (
                    <span className="text-[24px] font-bold text-[#FF6E00]">{`(${strings.PERSONAL_WORKOUT})`}</span>
                  )}
                </p>
              </div>
              <div className="flex items-center gap-2 flex-wrap">
                <Icon
                  icon="lets-icons:date-today"
                  width="30"
                  height="30"
                  style={{ color: "#D9D9D9" }}
                />
                <p className="mb-0 text-[22px] font-bold">
                  {moment(detailValue?.workoutTime).format("YY.MM.DD")}
                </p>
              </div>
              {detailValue?.isConfirmed && (
                <p className="mb-0 bg-[#CCFF0033] text-base font-extrabold text-common_color py-1.5 px-3 rounded-2xl">
                  {strings.CONFIRMED}
                </p>
              )}
              {detailValue?.sessionStatus == WORKOUT_LISTS_TITLES.PERSONAL ||
              detailValue?.sessionStatus ==
                WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER ? (
                <p
                  className={`${
                    detailValue?.sessionStatus ==
                    WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                      ? "bg-[#00DEC733] text-[#00FAE0]"
                      : "bg-[#FF6E0033] text-[#FF6E00]"
                  } ${"mb-0 text-base font-medium py-1.5 px-3 rounded-2xl"}`}
                >
                  {detailValue?.sessionStatus}
                </p>
              ) : (
                <p
                  className={`${
                    detailValue?.sessionStatus == "Logs created by trainer"
                      ? "bg-[#CCFF0033] text-[#CCFF00]"
                      : ""
                  } ${"mb-0 text-base font-medium py-1.5 px-3 rounded-2xl"}`}
                >
                  {detailValue?.sessionStatus}
                </p>
              )}
              <div
                className="flex items-center gap-x-1 gap-y-2 bg-[#2C2C2E] rounded-[5px] w-max px-2 py-1"
                style={{ border: "1px solid #ccff0073" }}
              >
                <img
                  src={trainer}
                  alt="trainer"
                  className="w-[22px] h-[23px]"
                />
                <span className="text-2xl font-bold text-[#CCCDCD]">
                  {strings.TRAINER}:
                </span>
                <p className="mb-0 text-2xl font-bold max-w-[90px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {detailValue?.trainerDetails?.gymStaffName}
                </p>
              </div>
            </div>

            <div>
              <p
                className={`${
                  detailValue?.sessionStatus ==
                  WORKOUT_LISTS_TITLES.TRAINER_CREATED
                    ? "text-[#CCFF00]"
                    : detailValue?.sessionStatus ==
                      WORKOUT_LISTS_TITLES.PERSONAL
                    ? "text-[#FF6E00]"
                    : detailValue?.sessionStatus ==
                      WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                    ? "text-[#00FAE0]"
                    : "text-[#CCFF00]"
                } ${"mb-0 text-[30px] font-extrabold flex items-center gap-2 "}`}
              >
                <Icon
                  icon="tabler:star-filled"
                  width="32"
                  height="32"
                  style={
                    detailValue?.sessionStatus ==
                    WORKOUT_LISTS_TITLES.TRAINER_CREATED
                      ? { color: "#CCFF00" }
                      : detailValue?.sessionStatus ==
                        WORKOUT_LISTS_TITLES.PERSONAL
                      ? { color: "#FF6E00" }
                      : detailValue?.sessionStatus ==
                        WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                      ? { color: "#00FAE0" }
                      : { color: "#CCFF00" }
                  }
                />
                {detailValue?.avgRating}
              </p>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-x-3 gap-y-2 flex-wrap py-3">
              <div className="rounded-full bg-[#616163] w-[70px] h-[70px] flex items-center justify-center">
                <img
                  src={
                    workoutIcon[detailValue?.majorExercisePart]?.icon || lifter
                  }
                  className="w-[45px] h-[45px]"
                />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-[#FFFFFFBF] font-bold text-[22px]">
                  {strings.EXERCISES}
                </span>
                <p className="mb-0 text-[22px] font-bold overflow-hidden text-ellipsis whitespace-nowrap mt-0">
                  {/* <span>{partName && partName.toString()}</span> */}
                  {detailValue?.exercisesDetails &&
                  detailValue.exercisesDetails.length > 0 ? (
                    <div className="flex">
                      {detailValue.exercisesDetails.map(
                        (exercise, index, array) => (
                          <div key={index}>
                            <span>{exercise.part}: </span>
                            <span>
                              {exercise.setCount} {strings.SET},{" "}
                            </span>
                            <span>
                              {exercise.exerciseCount}{" "}
                              {strings.EXERCISES.toLowerCase()}
                            </span>
                            {index < Math.min(array.length - 1, 3) ? ", " : ""}
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <p>No exercises details available.</p>
                  )}
                  {/* {detailValue?.exercisesDetails &&
                    detailValue?.exercisesDetails[0]?.part}
                  :{" "}
                  {detailValue?.exercisesDetails &&
                    detailValue?.exercisesDetails[0]?.setCount}{" "}
                  {strings.SET},{" "}
                  {detailValue?.exercisesDetails &&
                    detailValue?.exercisesDetails[0]?.exerciseCount}{" "}
                  {strings.EXERCISES.toLowerCase()} */}
                </p>
              </div>
            </div>
            {detailValue?.workoutSummaryNotes?.trainerNote && (
              <div className="py-2.5 flex items-center gap-2">
                <img src={trainerMemo} className="w-[30px] h-[30px]" />
                <p
                  className={`${
                    detailValue?.sessionStatus ==
                    WORKOUT_LISTS_TITLES.TRAINER_CREATED
                      ? "text-[#CCFF00]"
                      : detailValue?.sessionStatus ==
                        WORKOUT_LISTS_TITLES.PERSONAL
                      ? "text-[#FF6E00]"
                      : detailValue?.sessionStatus ==
                        WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                      ? "text-[#00FAE0]"
                      : ""
                  } ${"mb-0 text-2xl font-bold"}`}
                >
                  {strings.TRAINER_MEMO}:{" "}
                  <span className="text-white text-xl">
                    {detailValue?.workoutSummaryNotes?.trainerNote}
                  </span>
                </p>
              </div>
            )}
            {detailValue?.workoutSummaryNotes?.memberNote && (
              <div className="py-2.5 flex items-center gap-2">
                <img
                  src={
                    detailValue?.sessionStatus ==
                    WORKOUT_LISTS_TITLES.TRAINER_CREATED
                      ? memberMemoLime
                      : detailValue?.sessionStatus ==
                        WORKOUT_LISTS_TITLES.PERSONAL
                      ? memberMemoOrange
                      : detailValue?.sessionStatus ==
                        WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                      ? memberMemoBlue
                      : memberMemoLime
                  }
                  className="w-[30px] h-[30px]"
                />
                <p
                  className={`${
                    detailValue?.sessionStatus ==
                    WORKOUT_LISTS_TITLES.TRAINER_CREATED
                      ? "text-[#CCFF00]"
                      : detailValue?.sessionStatus ==
                        WORKOUT_LISTS_TITLES.PERSONAL
                      ? "text-[#FF6E00]"
                      : detailValue?.sessionStatus ==
                        WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                      ? "text-[#00FAE0]"
                      : ""
                  } ${"mb-0 text-2xl font-bold"}`}
                >
                  {strings.MEMBERS_MEMO}:{" "}
                  <span className="text-white text-xl">
                    {detailValue?.workoutSummaryNotes?.memberNote}
                  </span>
                </p>
              </div>
            )}
            <Row className="py-2.5 gap-y-4">
              {detailValue?.workoutLogFound &&
                detailValue?.workoutLogFound?.map((item, index) => (
                  <Col lg={6} xxl={4} key={index}>
                    <WorkoutDetailCard
                      item={item}
                      logId={detailValue}
                      classNameValue={classNameValue}
                    />
                  </Col>
                ))}
            </Row>
          </div>
          <div className="flex items-center justify-center mt-7 mb-3">
            <button
              onClick={() => {
                setOpenDetails(false);
                status && setPtHistory(true);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
        {/* <div
            className="flex items-center gap-x-1 gap-y-2 bg-[#2C2C2E] rounded-[5px] w-max px-2 py-1"
            style={{ border: "1px solid #ccff0073" }}
          >
            <img src={trainer} alt="trainer" className="w-[22px] h-[23px]" />
            <span className="text-2xl font-bold text-[#CCCDCD]">
              {strings.TRAINER}:
            </span>
            <p className="mb-0 text-2xl font-bold max-w-[90px] overflow-hidden text-ellipsis whitespace-nowrap">
              {item?.gymStaffInfo?.gymStaffName}
            </p>
          </div> */}
      </div>
    </section>
  );
}

export default WorkoutLogDetail;
