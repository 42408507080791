import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ProductRegister from "./ProductRegister";
import EventDetail from "./EventDetail";
import StatusChanged from "../../commonComponents/modalScreens/StatusChanged";
import { useSelector } from "react-redux";
import ManagementSublyApi from "../../../helper/ManagementApi";
import Loader from "../../../utils/Loader";
import ProductDetail from "./ProductDetail";
import { PRODUCT_TYPES_LIST } from "../../../utils/CommonData";
import { scrollToTopFun } from "../gymSettings/StaffSetting";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import { formatPrice } from "../../../utils/commonUtils";
import $ from "jquery";
import ChangeProductStatus from "../../commonComponents/modalScreens/ChangeProductStatus";

function Product() {
  const { token } = useSelector((state) => state.user);
  const [productId, setProductId] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [typeDropdown, setTypeDropdown] = useState(false);
  const [timeDropdown, setTimeDropdown] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [productData, setProductData] = useState();
  const [productType, setProductType] = useState({
    text: "Membership",
    key: 0,
  });
  const [statusType, setStatusType] = useState({ text: "All", type: "" });
  const [isLoader, setIsLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [defaultValue, setDefaultValue] = useState("");
  const [productStatus, setProductStatus] = useState({
    action: "",
    productType: "",
    id: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [changeStatusValue, setChangeProductStatus] = useState(false);
  const [itemValue, setItemValue] = useState("");
  const [changeValue, setChangeValue] = useState(3);
  const [lockerList, setLockerList] = useState(false);

  // =================Calling API for fetch product details================
  async function fetchProduct(key, status, page) {
    setIsLoader(true);
    await ManagementSublyApi.getProduct(
      token,
      productType.key,
      page ? page : offset,
      status || statusType.type,
      search
    ).then((response) => {
      setIsLoader(false);
      if (response.statusCode == 200) {
        setProductData(response.data.products);
        setPage(response.data?.count / 20);
        page && setOffset(1);
      }
    });
  }
  useEffect(() => {
    if (!openRegister && !openDetail && !statusModal && !changeStatusValue) {
      fetchProduct(productType.key);
    }
  }, [
    openRegister,
    openDetail,
    offset,
    statusModal,
    changeStatusValue,
    productType,
  ]);

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =================API for update product status=================
  async function updateStatusHandle(id, action, type) {
    setIsLoader(true);
    const requestData = { isActive: action };
    await ManagementSublyApi.productStatusUpdate(token, requestData, id).then(
      (response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setProductStatus({ action: action, productType: type, id: id });
          setStatusModal(true);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      }
    );
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setOpenDetail(false);
      setOpenRegister(false);
      setProductId("");
      setDefaultValue("");
      setTimeDropdown(false);
      setTypeDropdown(false);
    }
  });

  // ==============Calling API for chnage status=============
  async function changeStatus() {
    setIsLoader(true);
    const requestData = {
      membershipEndDate: new Date(),
      isAlwaysAvailable: false,
    };
    await ManagementSublyApi.editProduct(token, requestData, itemValue?.id)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setChangeProductStatus(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .then((error) => {
        console.log(error);
      });
  }

  return (
    <section>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={` ${
              openRegister || openDetail
                ? "!overflow-hidden h-screen"
                : "!overflow-auto"
            } ${"p-0 relative "}`}
          >
            <Header text={strings.MANAGEMENT_PRODUCT} currentTime={true} />
            <div className="mx-6">
              <div className="flex items-center justify-between gap-3 py-3 flex-wrap">
                <div className="flex items-center gap-3 flex-wrap max-sm:w-full">
                  <label
                    onClick={() => {
                      setTypeDropdown(!typeDropdown);
                      setTimeDropdown(false);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 py-1 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap ">
                      {productType?.text}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={typeDropdown ? 2 : 4}
                    />
                    {typeDropdown && (
                      <ProductFilter
                        setProductType={setProductType}
                        fetchProduct={fetchProduct}
                        statusType={statusType}
                        classValue={
                          typeDropdown ? "dropdownClass" : "hideNotify"
                        }
                      />
                    )}
                  </label>
                  <label
                    onClick={() => {
                      setTimeDropdown(!timeDropdown);
                      setTypeDropdown(false);
                    }}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                      {statusType.text}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9", cursor: "pointer" }}
                      rotate={timeDropdown ? 2 : 4}
                    />
                    {timeDropdown && (
                      <StatusFilter
                        setStatusType={setStatusType}
                        fetchProduct={fetchProduct}
                        productType={productType}
                        classValue={
                          timeDropdown ? "dropdownClass" : "hideNotify"
                        }
                      />
                    )}
                  </label>
                </div>
                <div className="flex items-center gap-3 flex-wrap max-sm:w-full">
                  <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Keywords"
                      className="bg-transparent w-full text-white text-lg font-bold"
                    />
                  </label>
                  <button
                    onClick={() => {
                      setOffset(1);
                      fetchProduct(productType.key, statusType.type);
                    }}
                    className="hover:text-black font-[archivo] max-sm:w-full hover:bg-[#CCFF00] text-lg font-bold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-between gap-3 pb-3 flex-wrap">
                <p className="mb-0 text-2xl font-extrabold">
                  {productType.text}
                </p>
                <button
                  onClick={() => {
                    setOpenRegister(true);
                    scrollToTopFun();
                  }}
                  className="text-black font-[archivo] bg-[#CCFF00] text-lg font-bold rounded-[30px] h-[50px] w-[120px]"
                >
                  {strings.REGISTER}
                </button>
              </div>
              {productData?.length == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-4">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              {productData?.length > 0 && (
                <div className="overflow-auto scrollingClass mb-3">
                  <div className="max-2xl:w-[1600px]">
                    <div className="flex justify-around items-center bg-[#353638] min-h-[60px] border-b border-[#444444]">
                      <div className="flex justify-around items-center w-full ">
                        <th
                          className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-3 py-1 w-[40%]  min-h-[60px] flex justify-around items-center"}`}
                        >
                          {placeholder.NAME}
                        </th>
                        <th
                          className={`${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[60px] flex justify-around items-center"}`}
                        >
                          {strings.PERIOD_BONUS}
                        </th>
                        <th
                          className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%]  min-h-[60px] flex justify-around items-center "}`}
                        >
                          {strings.FINAL_PRICE}
                        </th>
                        <th
                          className={` ${"text-[18px] font-semibold font-[Archivo] text-white px-2 text-center py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
                        >
                          {strings.START_DATE_SMALL}
                        </th>
                        <th
                          className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
                        >
                          {strings.END_DATE}
                        </th>
                        <th
                          className={`${"!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-around items-center min-h-[60px]"}`}
                        >
                          {strings.REGISTER_DATE}
                        </th>
                      </div>
                      <th className="!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[24%] flex justify-center items-center border-x border-[#444444] min-h-[60px]">
                        {strings.STATUS}
                      </th>
                    </div>
                    <div className="border-b border-[#444444] overflow-auto 2xl:max-h-[calc(100vh_-_365px)] max-2xl:max-h-[600px] ">
                      {productData?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-around items-center"
                          >
                            <div
                              // key={index}
                              onClick={() => {
                                setProductId(item.id);
                                setOpenDetail(true);
                                scrollToTopFun();
                              }}
                              className={`${
                                item == productId && "kakaoClass bg-[#353638]"
                              } ${"flex justify-around items-center cursor-pointer w-full"} ${
                                productId == item.id
                                  ? "border-[1px] border-[#CCFF00] bg-[#353638]"
                                  : ""
                              } `}
                            >
                              <th
                                className={` ${
                                  productId == item
                                    ? ""
                                    : "border-x border-[#444444] "
                                } ${"!text-start text-base  font-semibold font-[Archivo] text-white px-3 py-1 w-[40%] min-h-[40px] flex justify-start items-center"} ${
                                  productId == item.id ? "border-[0px]" : ""
                                }`}
                              >
                                {/* <Icon
                                  icon="bi:dot"
                                  width="25"
                                  height="25"
                                  style={{ color: "#fff" }}
                                /> */}
                                <p className="mb-0 textOverflowClass">
                                  {item.productName}
                                </p>
                              </th>
                              {item?.periodMonths ||
                              item?.addonMonths ||
                              item?.addonMonths ? (
                                <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[40px] flex justify-center items-center">
                                  {item?.periodMonths}{" "}
                                  {item?.periodMonths && "M"}{" "}
                                  {item?.addonMonths
                                    ? `(${
                                        item?.addonMonths
                                          ? `+${item?.addonMonths}m`
                                          : ""
                                      } ${
                                        item?.addonDays
                                          ? `+${item?.addonDays}d`
                                          : ""
                                      })`
                                    : ""}
                                </th>
                              ) : (
                                <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[40px] flex justify-center items-center">
                                  - -
                                </th>
                              )}
                              <th
                                className={`${
                                  productId == item
                                    ? ""
                                    : "border-x border-[#444444] "
                                } ${"!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] min-h-[40px] flex justify-center items-center "}`}
                              >
                                {formatPrice(item.finalPrice)}
                              </th>
                              <th className="text-base font-semibold font-[Archivo] text-white px-2 text-start py-1 w-[25%] flex justify-center items-center min-h-[40px]">
                                {!item?.isAlwaysAvailable
                                  ? item.membershipStartDate
                                    ? moment(item?.membershipStartDate).format(
                                        "YY.MM.DD"
                                      )
                                    : "- -"
                                  : strings.ALWAYS_AVAILABLE}{" "}
                              </th>
                              <th
                                className={`${
                                  productId == item
                                    ? ""
                                    : "border-x border-[#444444] "
                                }${"!text-start text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-center items-center min-h-[40px]"}`}
                              >
                                {item?.membershipEndDate
                                  ? moment(item?.membershipEndDate).format(
                                      "YY.MM.DD"
                                    )
                                  : "- -"}
                              </th>
                              <th
                                className={`!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[25%] flex justify-center items-center min-h-[40px] $`}
                              >
                                {moment(item?.regDate).format("YY.MM.DD")}
                              </th>
                            </div>
                            <th className="!text-start text-base font-semibold font-[Archivo] text-white px-2 py-1 w-[24%] flex justify-center border-x border-[#444444] items-center min-h-[40px]">
                              {item.statusValue == "expired" ? (
                                <button
                                  style={{ border: "1px solid #FFB9B9" }}
                                  className={`${"bg-[#FDC8C866] text-[#FFB9B9]"} ${"font-bold h-[30px] w-[63%] rounded-[28px] font-[Archivo]"}`}
                                >
                                  {strings.EXPIRED}
                                </button>
                              ) : item.statusValue == "on_sale" ? (
                                <button
                                  onClick={() => {
                                    setChangeProductStatus(!changeStatusValue);
                                    setItemValue(item);
                                  }}
                                  className={`${"bg-[#FFFFFF66] text-[#fff] border"} ${"font-bold h-[30px] w-[63%] rounded-[28px] font-[Archivo]"}`}
                                >
                                  {strings.ON_SALE}
                                </button>
                              ) : (
                                typeof item.isActive == "boolean" && (
                                  <div className="flex items-center justify-center gap-3">
                                    <button
                                      onClick={() => {
                                        updateStatusHandle(
                                          item.id,
                                          true,
                                          item.productName
                                        );
                                      }}
                                      className={`${
                                        productStatus.productType &&
                                        productStatus.id == item.id
                                          ? productStatus.action
                                            ? "bg-[#CCFF00] text-black"
                                            : "kakaoClass text-white"
                                          : item.isActive
                                          ? "bg-[#CCFF00] text-black"
                                          : "kakaoClass text-white"
                                      } ${" font-bold w-[81px] h-[30px] rounded-[28px] font-[Archivo]"}`}
                                    >
                                      {strings.ON}
                                    </button>
                                    <button
                                      onClick={() => {
                                        updateStatusHandle(
                                          item.id,
                                          false,
                                          item.productName
                                        );
                                      }}
                                      className={`${
                                        productStatus.productType &&
                                        productStatus.id == item.id
                                          ? !productStatus.action
                                            ? "bg-[#CCFF00] text-black"
                                            : "kakaoClass text-white"
                                          : !item.isActive
                                          ? "bg-[#CCFF00] text-black"
                                          : "kakaoClass text-white"
                                      } ${"font-bold w-[81px] h-[30px] rounded-[28px] font-[Archivo]"}`}
                                    >
                                      {strings.OFF}
                                    </button>
                                  </div>
                                )
                              )}
                            </th>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {page && page > 1 && (
                <div className="w-full">
                  <div className="mt-3">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      forcePage={offset == 1 ? 0 : offset - 1}
                      pageCount={page}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
            {openRegister && (
              <ProductRegister
                setOpenRegister={setOpenRegister}
                setIsLoader={setIsLoader}
                defaultValue={defaultValue}
                classNameValue={`${
                  openRegister ? "block_notify" : "hideNotify"
                }`}
                setDefaultValue={setDefaultValue}
                lockerList={lockerList}
                setLockerList={setLockerList}
              />
            )}
            {openDetail && (
              <ProductDetail
                setOpenDetail={setOpenDetail}
                id={productId}
                setProductId={setProductId}
                setIsLoader={setIsLoader}
                setDefaultValue={setDefaultValue}
                setOpenRegister={setOpenRegister}
                classNameValue={`${openDetail ? "block_notify" : "hideNotify"}`}
              />
            )}
          </Col>
        </Row>
      </Container>
      <StatusChanged
        statusModal={statusModal}
        setStatusModal={setStatusModal}
        productStatus={productStatus}
      />
      <ChangeProductStatus
        setChangeStatus={setChangeProductStatus}
        changeStatus={changeStatusValue}
        head={strings.CHANGE_STATUS_FOR_PRODUCT}
        changeValue={changeValue}
        setChangeValue={setChangeValue}
        itemValue={itemValue}
        changeStatusHandle={changeStatus}
      />
    </section>
  );
}

export default Product;

// function ProductFilter({ setProductType, boardListHandle }) {
function ProductFilter({
  setProductType,
  fetchProduct,
  statusType,
  classValue,
}) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] w-[250px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-3"}`}
    >
      {PRODUCT_TYPES_LIST.map((val, index) => (
        <div
          key={index}
          onClick={() => {
            setProductType({ text: val, key: index });
            fetchProduct(index, statusType.type, 1);
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
        >
          {/* <Icon icon="bi:dot" width="35" height="35" /> */}
          <span className="mb-0 text-lg font-bold">{val}</span>
        </div>
      ))}
    </div>
  );
}

function StatusFilter({
  setStatusType,
  fetchProduct,
  productType,
  classValue,
}) {
  return (
    <div
      className={`${classValue} ${"absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-14 max-h-[200px] overflow-auto z-10 px-3"}`}
    >
      <div
        onClick={() => {
          fetchProduct(productType.key, "all", 1);
          setStatusType({ text: strings.ALL, type: "all" });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      <div
        onClick={() => {
          fetchProduct(productType.key, "expired", 1);
          setStatusType({ text: strings.EXPIRED, type: "expired" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.EXPIRED}</span>
      </div>
      <div
        onClick={() => {
          fetchProduct(productType.key, "on_sale", 1);
          setStatusType({ text: strings.ON_SALE, type: "on_sale" });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        {/* <Icon icon="bi:dot" width="35" height="35" /> */}
        <span className="mb-0 text-lg font-bold">{strings.ON_SALE}</span>
      </div>
    </div>
  );
}
