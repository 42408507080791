import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import DeleteConfirmation from "./DeleteConfirmation";
import MembersSublyApi from "../../../helper/MemberApi";
import DeleteSuccess from "./DeleteSuccess";
import { useSelector } from "react-redux";

function LockerEditModal({
  classNameValue,
  editLocker,
  setEditLocker,
  lockerId,
  setLockerId,
  setIsUpdate,
}) {
  const { token } = useSelector((state) => state.user);
  const [editConfirm, setEditConfirm] = useState(false);
  const [lockerNumber, setLockerNumber] = useState("");
  const [lockerPassword, setLockerPassword] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setLockerPassword(lockerId.password);
    setLockerNumber(lockerId.number);
  }, [lockerId]);

  // ===============Locker edit API=============
  async function editLockerHandle() {
    const requestData = {
      lockerNumber: lockerNumber,
      lockerPassword: lockerPassword,
    };
    await MembersSublyApi.editLocker(lockerId.id, requestData, token)
      .then((response) => {
        if (response.statusCode == 200) {
          setEditConfirm(false);
          setIsUpdate(true);
          setEditLocker(false);
          setLockerNumber("");
          setLockerPassword("");
          setSuccess(true);
          setLockerId({
            id: "",
            number: "",
            password: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass"}`}
        show={editLocker}
        onHide={() => {
          setEditLocker(false);
          setLockerNumber("");
          setLockerPassword("");
          setLockerId({
            id: "",
            number: "",
            password: "",
          });
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <p className="border-b-2 border-[#444444] max-lg:text-[28px] pb-4 mb-6 text-[40px] font-extrabold">
            {strings.EDIT_LOCKER}
          </p>
          <div className="w-[75%] m-auto">
            <div>
              <p className="text-lg mb-0 font-normal text-start text-[#A1A1AA]">
                {strings.LOCKER_NO}
              </p>
              <label
                htmlFor="number"
                className="bg-[#353638] w-full h-[50px] px-3 flex items-center gap-2 rounded-[10px] lockerNumberField numberFieldClass"
              >
                <input
                  type="number"
                  id="number"
                  value={lockerNumber}
                  onChange={(e) => {
                    setLockerNumber(e.target.value);
                  }}
                  placeholder={lockerId.number}
                  className="w-full bg-[#353638] text-base font-bold  numberField"
                />
              </label>
            </div>
            <div className="mt-4">
              <p className="text-lg mb-0 font-normal text-[#A1A1AA] text-start">
                {strings.PASSWORD}
              </p>
              <label
                htmlFor="password"
                className="bg-[#353638] w-full h-[50px] px-3 flex items-center gap-2 rounded-[10px] lockerNumberField numberFieldClass"
              >
                <input
                  type="text"
                  id="password"
                  value={lockerPassword}
                  onChange={(e) => {
                    setLockerPassword(e.target.value);
                  }}
                  placeholder={lockerId.password}
                  className="w-full bg-[#353638] text-base font-bold  numberField"
                />
              </label>
            </div>
          </div>
          <div className="flex justify-center mt-6 gap-4 py-4">
            <button
              onClick={() => {
                setEditLocker(false);
                setLockerId({
                  id: "",
                  number: "",
                  password: "",
                });
              }}
              className=" max-lg:text-[18px] max-lg:h-[45px] text-[24px] font-extrabold kakaoClass h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CANCEL}
            </button>
            <button
              onClick={() => {
                setEditConfirm(true);
                setEditLocker(false);
              }}
              className="max-lg:text-[18px] max-lg:h-[45px] text-[24px] font-extrabold text-black bg-common_color h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.UPDATE}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <DeleteConfirmation
        classNameValue={classNameValue}
        textHead={strings.EDIT_LOCKER}
        text={strings.ARE_YOU_SURE_YOU_WANT_TO_EDIT_DETAILS}
        deleteMemo={editConfirm}
        setDeleteMemo={setEditConfirm}
        clickBtn={editLockerHandle}
        btnText={strings.EDIT}
      />
      <DeleteSuccess
        classNameValue={classNameValue}
        text={strings.LOCKER_EDITED_SUCCESSFULLY}
        deleteSuccess={success}
        setDeleteSuccess={setSuccess}
        isDelete={false}
        setIsUpdate={setIsUpdate}
      />
    </>
  );
}

export default LockerEditModal;
