import { Icon } from "@iconify-icon/react";
import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import MembersSublyApi from "../../../helper/MemberApi";
import TakeOverModal from "../../commonComponents/modalScreens/TakeOverModal";
import won from "../../../assests/icons/won-currancy white.svg";
import woncurrancy from "../../../assests/icons/won-currancy gray.svg";
import { useSelector } from "react-redux";
import { formatNumber } from "chart.js/helpers";
import { formatPrice, parseFormattedPrice } from "../../../utils/commonUtils";
import moment from "moment";
import $ from "jquery";
import { HiCalendar } from "react-icons/hi2";
import { DatePicker } from "rsuite";

function FormProductSection({
  setLockerList,
  clickBtn,
  setShowRegisterForm,
  lockerValue,
  setLockerValue,
  classNameValue,
  startDateMemberShip,
  startDateLocker,
  setTrainerModal,
  trainerValue,
  setTrainerValue,
  productModal,
  setProductModal,
  productArr,
  setProductArr,
  addProduct,
  setAddProduct,
  updateBtn,
  setUserData,
  setProductId,
  userData,
  productId,
  setTakeOverProduct,
  takeOverProduct,
  setTrainerHeading,
}) {
  const { token } = useSelector((state) => state.user);
  const [openTakeOver, setOpenTakeOver] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [userdetail, setUserdetail] = useState("");
  const [openCalender, setOpenCalender] = useState({});
  const [newTakeOver, setNewTakeOver] = useState(false);
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  const [idProduct, setIdProduct] = useState("");

  useEffect(() => {
    if (addProduct) {
      // const filterList =
      //   addProduct?.productsList &&
      //   Object.values(addProduct?.productsList)
      //     .flat(2)
      //     .filter((element) => !element.isExpired);
      // setProductArr(filterList);
      setTrainerValue({
        name: addProduct?.trainerDetails?.gymStaffName || "",
        id: addProduct?.trainerDetails?.id || "",
      });
      setLockerValue({
        name: addProduct?.lockerDetails?.lockerNumber || "",
        password: addProduct?.lockerDetails?.lockerPassword || "",
        id: addProduct?.lockerDetails?.id || "",
      });
    } else if (takeOverProduct) {
      setTrainerValue({
        name: userdetail?.trainerDetails?.gymStaffName || "",
        id: userdetail?.trainerDetails?.id || "",
      });
      setLockerValue({
        name: userdetail?.lockerDetails?.lockerNumber || "",
        password: userdetail?.lockerDetails?.lockerPassword || "",
        id: userdetail?.lockerDetails?.id || "",
      });
    }
  }, [openTakeOver]);

  // ===============function for handling remove selected product=============
  async function removeProduct(index, id) {
    if (id) {
      const products = [...takeOverProduct];
      products.splice(index, 1);
      setTakeOverProduct(products);
    } else {
      const products = [...productArr];
      products.splice(index, 1);
      setProductArr(products);
    }
  }
  // =============Getting final price after all the discount================
  const priceValue = productArr?.reduce((accu, curr) => {
    const price = curr?.price - (curr?.discountPrice ?? 0);
    return accu + (price > 0 ? price : 0);
  }, 0);

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById(`${idProduct}-grid-label`)
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }

    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  return (
    <>
      <div className="mt-8 mb-2">
        <div className="flex justify-between items-center mb-3 max-md:flex-wrap gap-y-4">
          <p className="mb-0 text-xl font-bold">{strings.PRODUCT}</p>
          {!addProduct && (
            <button
              onClick={() => {
                setOpenTakeOver(true);
                setTakeOverProduct("");
                setProductId("");
              }}
              className="text-sm font-extrabold rounded-[28px] text-[#1C1C1E] bg-common_color w-[130px] h-[40px]"
            >
              {strings.TAKE_OVER}
            </button>
          )}
        </div>
        {[...(newTakeOver ? takeOverProduct : []), ...productArr].length ==
          0 && (
          <div className="flex items-center gap-x-5 gap-y-4 max-md:flex-wrap my-2">
            <div className="w-full">
              <label
                htmlFor="nameId"
                className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px]"
              >
                <input
                  type="text"
                  disabled
                  className="bg-[#414142] text-base text-white w-full placeholder:font-medium font-bold"
                  id="nameId"
                  placeholder={strings.SELECT_PRODUCT}
                />
              </label>
            </div>
            <button
              onClick={() => setProductModal(true)}
              className="text-base font-extrabold rounded-[28px] text-white hover:text-[#1C1C1E] hover:bg-common_color w-[200px] h-[45px] kakaoClass"
            >
              {strings.SELECT_PRODUCT}
            </button>
          </div>
        )}
        {productArr?.length > 0 || takeOverProduct.length > 0
          ? [...(newTakeOver ? takeOverProduct : []), ...productArr].map(
              (item, index, arr) => (
                <>
                  {item?.type !== "locker" && (
                    <div className="flex items-center gap-x-3 gap-y-0.5 max-[1400px]:flex-wrap">
                      <div className="w-full">
                        {index === 0 && (
                          <p className="mb-1 text-sm font-bold ml-2">
                            {strings.PRODUCT_NAME}
                          </p>
                        )}
                        <label
                          htmlFor="nameId"
                          className="bg-[#414142] w-full h-[45px] text-[16px] px-3 flex items-center gap-2 rounded-[10px] mb-3 relative"
                        >
                          <input
                            type="text"
                            value={item.productName}
                            className={`bg-[#414142] ${"text-white"} w-full placeholder:font-medium font-bold`}
                            id="nameId"
                            placeholder={item?.productName}
                          />
                        </label>
                      </div>
                      <div className="w-full">
                        <div className="flex items-center gap-3 w-full">
                          {" "}
                          <div className="w-full">
                            {index === 0 && (
                              <p className="mb-1 text-sm font-bold">
                                {strings.DATE}
                              </p>
                            )}
                            <div className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px] mb-3 newDateInputClass">
                              <DatePicker
                                oneTap
                                caretAs={HiCalendar}
                                id={item.id}
                                placeholder={`${placeholder.START_DATE}:`}
                                format="yyyy.MM.dd"
                                block
                                editable={false}
                                appearance="subtle"
                                className="custom-datepicker bg-transparent border-0 w-full px-0"
                                value={item?.membershipStartDate}
                                preventOverflow
                                onChange={(date) => {
                                  if (item.newProduct) {
                                    const product = [...productArr];
                                    product[
                                      Math.abs(takeOverProduct.length - index)
                                    ].membershipStartDate = date;
                                    setProductArr(product);
                                  } else {
                                    const product = [...takeOverProduct];
                                    product[index].membershipStartDate = date;
                                    setTakeOverProduct(product);
                                  }
                                }}
                                onChangeCalendarDate={(e) => {
                                  setCurrentMonth(e);
                                }}
                                onNextMonth={(e) => {
                                  setCurrentMonth(e);
                                }}
                                onPrevMonth={(e) => {
                                  setCurrentMonth(e);
                                }}
                                onOpen={() => {
                                  setIdProduct(item.id);
                                  setCurrentMonth(
                                    item?.membershipStartDate
                                      ? new Date(item?.membershipStartDate)
                                      : new Date()
                                  );
                                  setIsCalenderOpen(true);
                                  setDatePickerCheck(2);
                                }}
                                onClose={() => {
                                  setIsCalenderOpen(false);
                                  setCurrentMonth(
                                    item?.membershipStartDate
                                      ? new Date(item?.membershipStartDate)
                                      : new Date()
                                  );
                                  setDatePickerCheck("");
                                }}
                                disabledDate={(date) => {
                                  const maxDate = new Date(
                                    Date.now() - 24 * 60 * 60 * 1000
                                  );
                                  return date < maxDate;
                                }}
                              />
                            </div>
                          </div>
                          <div className="w-full">
                            {index === 0 && (
                              <p className="mb-1 text-sm font-bold">
                                {strings.AMOUNT}
                              </p>
                            )}
                            <label
                              style={{ border: "1px solid #414142" }}
                              className="h-[45px] pl-3 pr-5 flex items-center gap-1 rounded-[10px] mb-3"
                            >
                              <p className="mb-0 text-base font-extrabold text-white whitespace-nowrap">
                                <span className="text-addProductText text-base font-bold">
                                  {strings.PRICE}:
                                </span>{" "}
                              </p>
                              <img
                                src={won}
                                className="w-[18px] h-[18px] mb-0"
                              />
                              {/* <input
                          className="text-white  font-bold bg-transparent caret-transparent"
                          value={formatNumber(item?.price)}
                        /> */}
                              <span className="text-white font-bold ">
                                {formatNumber(item?.price)}
                              </span>
                            </label>
                          </div>
                          <div className="w-full">
                            {index === 0 && (
                              <p className="mb-1 text-sm font-bold whitespace-nowrap">
                                {strings.ADDITIONAL_DISCOUNT}
                              </p>
                            )}
                            <label
                              htmlFor="birth"
                              className={`${
                                !takeOverProduct && item.prodId
                                  ? "opacity-[0.3]"
                                  : ""
                              }  ${"bg-[#414142] w-full h-[45px] px-3 flex items-center gap-1 justify-between rounded-[10px] mb-3 numberFieldClass"}`}
                            >
                              <img
                                src={woncurrancy}
                                className="w-[18px] h-[18px]"
                              />
                              <input
                                type="text"
                                disabled={
                                  !takeOverProduct && item.prodId ? true : false
                                }
                                className={`bg-[#414142] ${"text-white"} w-full text-[16px] text-right numberField placeholder:font-medium font-bold`}
                                placeholder={`${strings.DISCOUNT}:`}
                                value={formatPrice(item?.discountPrice)}
                                onChange={(e) => {
                                  const product = [...productArr];
                                  product[index].discountPrice =
                                    parseFormattedPrice(e.target.value) >
                                    item?.price
                                      ? item.price
                                      : parseFormattedPrice(e.target.value);
                                  setProductArr(product);
                                }}
                              />
                            </label>
                          </div>
                          {/* {takeOverProduct || !item.prodId ? ( */}
                          {/* {!trainerValue?.id && ( */}
                          {index !==
                          [...productArr, ...takeOverProduct].length - 1 ? (
                            <Icon
                              icon="material-symbols:delete-outline"
                              width="30"
                              height="30"
                              style={{ color: "#6B6B6B" }}
                              className={`${
                                !takeOverProduct && item.prodId
                                  ? "opacity-[0]"
                                  : ""
                              } ${
                                index == 0 ? "mt-2.5" : "mt-2"
                              } mb-2 text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer`}
                              onClick={() => {
                                (takeOverProduct || !item.prodId) &&
                                  removeProduct(index, item.prodId);
                              }}
                            />
                          ) : (
                            <span
                              onClick={() => {
                                setProductModal(true);
                              }}
                              style={{ border: "1px solid #FFFFFF1F" }}
                              className={`${
                                index == 0 ? "mt-4" : "mt-0"
                              } ${"bg-[#414142] rounded-[3px] w-[30px] h-[30px] flex items-center justify-center p-2 cursor-pointer  mb-3"}`}
                            >
                              <Icon
                                icon="fluent:add-24-filled"
                                width="25"
                                height="25"
                                style={{ color: "white" }}
                              />
                            </span>
                          )}
                          {/* // )} */}
                          {/* ) : null} */}
                        </div>
                      </div>
                    </div>
                  )}
                  {item.type == "PT" &&
                    [...takeOverProduct, ...productArr]?.findLast(
                      (val) => val.type == "PT"
                    ).id == item.id && (
                      <div className="flex items-center gap-x-3 gap-y-0.5 max-md:flex-wrap mb-3">
                        <div className="flex items-center gap-3 w-1/2 max-md:w-full">
                          <label
                            htmlFor="nameId"
                            className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-3 rounded-[10px] relative"
                          >
                            <input
                              type="text"
                              disabled
                              value={trainerValue.name}
                              placeholder={placeholder.SEARCH_TRAINER}
                              className="bg-[#414142] text-white w-full placeholder:font-medium font-bold"
                              id="nameId"
                            />
                          </label>
                          {/* {takeOverProduct || !item.prodId ? ( */}
                          {/* {!trainerValue?.id && ( */}
                          {!userdetail?.trainerDetails?.id &&
                          !addProduct?.trainerDetails?.id ? (
                            <span
                              onClick={() => {
                                setTrainerModal(true);
                                setTrainerHeading(true);
                              }}
                              className="w-[45px] h-[45px] rounded-[10px] bg-[#414142] flex items-center justify-center"
                            >
                              <Icon
                                icon="gravity-ui:magnifier"
                                width="25"
                                height="25"
                                className="text-gray-300 cursor-pointer"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                          {/* )} */}
                          {/* ) : null} */}
                        </div>
                      </div>
                    )}
                  {item.type == "locker" && (
                    <div className="flex items-center gap-x-3 gap-y-4 max-md:flex-wrap  mb-3">
                      <div className="w-full">
                        {index === 0 && (
                          <p className="mb-1 text-sm font-bold ml-2">
                            {strings.PRODUCT_NAME}
                          </p>
                        )}

                        <div className="flex items-center gap-3 w-full">
                          <label
                            htmlFor="nameId"
                            className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-3 rounded-[10px] relative"
                          >
                            <span className="w-full border-r-2 border-[#505050] text-addProductText flex items-center gap-2 whitespace-nowrap	">
                              {strings.LOCKER_NO}
                              <input
                                type="text"
                                disabled
                                value={lockerValue.name}
                                className="bg-[#414142] text-white w-full placeholder:font-medium font-bold"
                                id="nameId"
                              />
                            </span>
                            <span className="w-full text-addProductText flex items-center gap-2 whitespace-nowrap	">
                              {strings.PASSWORD}
                              <input
                                type="text"
                                disabled
                                value={`${
                                  lockerValue.password &&
                                  `(${lockerValue.password})`
                                }`}
                                className="bg-[#414142] text-white placeholder:font-medium font-bold w-full"
                                id="nameId"
                              />
                            </span>
                          </label>
                          {takeOverProduct || !item.prodId ? (
                            <span className="w-[45px] h-[45px] rounded-[10px] bg-[#414142] flex items-center justify-center">
                              <Icon
                                icon="ph:lockers-bold"
                                width="30"
                                height="30"
                                style={{ color: "white", cursor: "pointer" }}
                                onClick={() => setLockerList(true)}
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex items-center gap-2 w-full">
                        {" "}
                        <div className="w-full">
                          {index === 0 && (
                            <p className="mb-1 text-sm font-bold ml-2">
                              {strings.DATE}
                            </p>
                          )}
                          <div className="bg-[#414142] w-full h-[45px] px-3 flex items-center gap-2 rounded-[10px] newDateInputClass placeholderClass">
                            <DatePicker
                              oneTap
                              caretAs={HiCalendar}
                              id={item.id}
                              placeholder={`${placeholder.START_DATE}:`}
                              format="yyyy.MM.dd"
                              block
                              preventOverflow
                              editable={false}
                              appearance="subtle"
                              className="custom-datepicker bg-transparent border-0 w-full px-0"
                              value={item?.membershipStartDate}
                              onChange={(date) => {
                                if (item.newProduct) {
                                  const product = [...productArr];
                                  product[
                                    Math.abs(takeOverProduct.length - index)
                                  ].membershipStartDate = date;
                                  setProductArr(product);
                                } else {
                                  const product = [...takeOverProduct];
                                  product[index].membershipStartDate = date;
                                  setTakeOverProduct(product);
                                }
                              }}
                              onChangeCalendarDate={(e) => {
                                setCurrentMonth(e);
                              }}
                              onNextMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onPrevMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onOpen={() => {
                                setIdProduct(item.id);
                                setIsCalenderOpen(true);
                                setCurrentMonth(
                                  item?.membershipStartDate
                                    ? new Date(item?.membershipStartDate)
                                    : new Date()
                                );
                                setDatePickerCheck(2);
                              }}
                              onClose={() => {
                                setIsCalenderOpen(false);
                                setCurrentMonth(
                                  item?.membershipStartDate
                                    ? new Date(item?.membershipStartDate)
                                    : new Date()
                                );
                                setDatePickerCheck("");
                              }}
                              disabledDate={(date) => {
                                const maxDate = new Date(
                                  Date.now() - 24 * 60 * 60 * 1000
                                );
                                return date < maxDate;
                              }}
                            />
                          </div>
                        </div>
                        <div className=" w-full">
                          {index === 0 && (
                            <p className="mb-1 text-sm font-bold ml-2">
                              {strings.AMOUNT}
                            </p>
                          )}
                          <label
                            style={{ border: "1px solid #414142" }}
                            htmlFor="birth"
                            className="w-full h-[45px] px-3 flex items-center gap-1 rounded-[10px]"
                          >
                            <p className="mb-0 text-base font-extrabold text-white whitespace-nowrap">
                              <span className="text-addProductText text-base font-bold">
                                {strings.PRICE}:
                              </span>{" "}
                            </p>
                            <img src={won} className="w-[18px] h-[18px]" />
                            {/* <input
                          className="text-white bg-transparent font-bold caret-transparent"
                          value={formatNumber(item?.price)}
                        /> */}
                            <span className="text-white font-bold">
                              {formatNumber(item?.price)}
                            </span>
                          </label>
                        </div>
                        <div className="w-full">
                          {index === 0 && (
                            <p className="mb-1 text-sm font-bold ml-2">
                              {strings.ADDITIONAL_DISCOUNT}
                            </p>
                          )}
                          <label
                            htmlFor="birth"
                            className={`${
                              !takeOverProduct && item.prodId
                                ? "opacity-[0.3]"
                                : ""
                            } ${"bg-[#414142] w-full h-[45px] px-3 flex items-center gap-1 justify-between rounded-[10px] numberFieldClass"}`}
                          >
                            <img
                              src={woncurrancy}
                              className="w-[18px] h-[18px]"
                            />
                            <input
                              type="text"
                              disabled={
                                !takeOverProduct && item.prodId ? true : false
                              }
                              className={`bg-[#414142] ${"text-white"} w-full text-[16px] text-right numberField placeholder:font-medium font-bold`}
                              value={formatPrice(item?.discountPrice)}
                              onChange={(e) => {
                                const product = [...productArr];
                                product[index].discountPrice =
                                  parseFormattedPrice(e.target.value) >
                                  item.price
                                    ? item.price
                                    : parseFormattedPrice(e.target.value);
                                setProductArr(product);
                              }}
                              placeholder={`${strings.DISCOUNT}:`}
                            />
                          </label>
                        </div>
                        {index !==
                        [...productArr, ...takeOverProduct].length - 1 ? (
                          <Icon
                            icon="material-symbols:delete-outline"
                            width="30"
                            height="30"
                            style={{ color: "#6B6B6B" }}
                            className={`${
                              !takeOverProduct && item.prodId
                                ? "opacity-[0]"
                                : ""
                            } ${
                              index !== 0 ? "mt-1" : "mt-7"
                            } mb-1 text-[#6B6B6B] hover:text-[#CCFF00] cursor-pointer`}
                            onClick={() => {
                              (takeOverProduct || !item.prodId) &&
                                removeProduct(index, item.prodId);
                            }}
                          />
                        ) : (
                          <span
                            onClick={() => {
                              setProductModal(true);
                            }}
                            style={{ border: "1px solid #FFFFFF1F" }}
                            className={`${
                              index !== 0 ? "mt-1" : "mt-7"
                            } ${"bg-[#414142] rounded-[3px] w-[30px] h-[30px] flex items-center justify-center p-2 cursor-pointer mb-2"}`}
                          >
                            <Icon
                              icon="fluent:add-24-filled"
                              width="25"
                              height="25"
                              style={{ color: "white" }}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )
            )
          : ""}

        {productArr.length > 0 && (
          <p className="mb-0 text-xl font-extrabold">
            {strings.TOTAL_PRICE_} ₩ {formatPrice(priceValue)}
          </p>
        )}
        <div
          className={`${
            productArr.length > 0 ? "mt-3" : "mt-12"
          } ${"flex items-center justify-center gap-3 flex-wrap"}`}
        >
          <button
            onClick={() => {
              setShowRegisterForm(false);
              setLockerValue({ name: "", password: "", id: "" });
              setTrainerValue({ name: "", id: "" });
              setProductArr([]);
              setAddProduct("");
              setTakeOverProduct("");
              setProductId("");
            }}
            className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
          >
            {strings.CLOSE}
          </button>
          {addProduct ? (
            <button
              disabled={!productArr.length > 0 ? true : false}
              onClick={() => updateBtn()}
              className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.UPDATE}
            </button>
          ) : (
            <button
              onClick={() => clickBtn()}
              className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px]"
            >
              {strings.REGISTER}
            </button>
          )}
        </div>
        {openTakeOver && (
          <TakeOverModal
            classNameValue={classNameValue}
            openTakeOver={openTakeOver}
            setOpenTakeOver={setOpenTakeOver}
            setProductId={setProductId}
            setUserData={setUserData}
            userData={userData}
            error={error}
            setError={setError}
            otp={otp}
            setOtp={setOtp}
            productId={productId}
            setTakeOverProduct={setTakeOverProduct}
            takeOverProduct={takeOverProduct}
            setUserdetail={setUserdetail}
            setNewTakeOver={setNewTakeOver}
          />
        )}
      </div>
    </>
  );
}

export default FormProductSection;

function MembershipOption({
  productList,
  setMemberShipValue,
  setShowMembership,
  isMemberShip,
  setPtValue,
  isPt,
  setGolfValue,
}) {
  return (
    <div className="bg-[#5C5C5C] rounded-[10px] absolute right-5 top-7 py-3 px-3 z-10">
      {isMemberShip ? (
        <ul className="!pl-4  list-disc mb-1">
          {productList &&
          productList.membership &&
          productList.membership.length > 0
            ? productList.membership.map((item, index) => (
                <li
                  className="text-sm font-bold text-white mb-3 cursor-pointer"
                  key={index}
                  onClick={() => {
                    setMemberShipValue({
                      name: item.productName,
                      price: item.finalPrice,
                      id: item.id,
                    });
                    setShowMembership(false);
                  }}
                >
                  {item.productName}
                  {" - "}
                  <span className="text-base font-extrabold">
                    ₩{formatPrice(item.regularPrice)}
                  </span>
                </li>
              ))
            : ""}
        </ul>
      ) : isPt ? (
        <ul className="!pl-4  list-disc mb-1">
          {productList && productList.PT && productList.PT.length > 0
            ? productList.PT.map((item, index) => (
                <li
                  className="text-sm font-bold text-white mb-3 cursor-pointer"
                  key={index}
                  onClick={() => {
                    setPtValue({
                      name: item.productName,
                      price: item.finalPrice,
                      id: item.id,
                    });
                    setShowMembership(false);
                  }}
                >
                  {item.productName}
                  {" - "}
                  <span className="text-base font-extrabold">
                    ₩{formatPrice(item.regularPrice)}
                  </span>
                </li>
              ))
            : ""}
        </ul>
      ) : (
        <ul className="!pl-4  list-disc mb-1">
          {productList && productList.golf && productList.golf.length > 0
            ? productList.golf.map((item, index) => (
                <li
                  className="text-sm font-bold text-white mb-0 cursor-pointer"
                  key={index}
                  onClick={() => {
                    setGolfValue({
                      name: item.productName,
                      price: item.finalPrice,
                      id: item.id,
                    });
                    setShowMembership(false);
                  }}
                >
                  {item.productName}
                  {" - "}
                  <span className="text-base font-extrabold">
                    ₩{formatPrice(item.regularPrice)}
                  </span>
                </li>
              ))
            : ""}
        </ul>
      )}
    </div>
  );
}
