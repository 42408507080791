import React from "react";
import { Modal } from "react-bootstrap";
import { strings } from "../../../../utils/String";

const MessageConfirmModal = ({
  classNameValue,
  isConfirm,
  setIsConfirm,
  clickEventHandle,
}) => {
  return (
    <>
      <Modal
        show={isConfirm}
        onHide={() => {
          setIsConfirm(false);
        }}
        className={`${classNameValue} ${"modalBoxClass"}`}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <p className="border-b-2 border-[#444444] pb-3 mb-6 text-[40px] max-lg:text-[28px] font-extrabold">
            Message
          </p>
          <p className="text-[30px] max-lg:text-[18px] font-bold pb-2 mb-0">
            <p className="mt-4 w-[83%] m-auto">
              Messages will be processed after Thejal's confirmation and should
              be registered 2-3 business days in advance to allow for
              scheduling.
            </p>
          </p>
          <div className="flex justify-center pt-8 gap-4">
            <button
              onClick={() => {
                setIsConfirm(false);
              }}
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CLOSE}
            </button>
            <>
              <button
                onClick={() => {
                  clickEventHandle();
                }}
                className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-black kakaoClass bg-[#CCFF00] h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.CONFIRM}
              </button>
            </>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MessageConfirmModal;
