import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import { InputGroup, Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import ReactDatePicker from "react-datepicker";
import won from "../../../assests/icons/won-currancy.svg";
import MembersSublyApi from "../../../helper/MemberApi";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { formatPrice, parseFormattedPrice } from "../../../utils/commonUtils";
import moment from "moment";
import { HiCalendar } from "react-icons/hi2";
import { DatePicker } from "rsuite";
import $ from "jquery";

function PurchaseListModal({
  classNameValue,
  refundModal,
  setRefundModal,
  id,
  setIsLoader,
}) {
  const { token } = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState("");
  const [productList, setProductList] = useState("");
  const [productId, setProductId] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  const [openCalender, setOpenCalender] = useState({});
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  const [idProduct, setIdProduct] = useState("");

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  // ===========Product List API==============
  useEffect(() => {
    async function purchaseList() {
      setIsLoader(true);
      await MembersSublyApi.refundProductList(id, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setProductList(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    purchaseList();
  }, [refundModal]);

  // ===========API for refund product============
  async function refundProductHandle() {
    setIsLoader(true);
    const requestData = {
      memberOwnProductIds: productId,
    };
    await MembersSublyApi.refundProduct(requestData, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setRefundModal(false);
          setProductPrice("");
          setStartDate("");
          setProductId([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById(`${idProduct}-grid-label`)
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }

    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  const product = productId?.map((val) => {
    if (!(val?.refundPrice && val?.refundDate)) {
      return false;
    } else if (val?.refundPrice > 0 && val?.refundDate) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <>
      <Modal
        className={`${classNameValue} ${"modalBoxClass classForMargin"}`}
        show={refundModal}
        onHide={() => {
          setRefundModal(false);
          setProductPrice("");
          setStartDate("");
          setProductId([]);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8 overflow-auto max-h-[calc(100vh_-_150px)]">
          <div className="flex border-b-2 border-[#444444] pb-2 mb-2 items-center  justify-between">
            <p></p>
            <p className="!mb-0 text-[40px] max-lg:text-[32px] font-extrabold text-end">
              {strings.PURCHASED_ITEMS_LIST}
            </p>
            <Icon
              icon="maki:cross"
              width="24"
              height="24"
              style={{ color: "white" }}
              onClick={() => {
                setRefundModal(false);
                setProductPrice("");
                setStartDate("");
                setProductId([]);
              }}
            />
          </div>
          <div className=" w-[95%] m-auto">
            <div className=" ">
              <div className="mb-3">
                {productList && productList.length > 0 ? (
                  productList?.map((items, index) => (
                    <div
                      className="flex justify-start items-center gap-1 mb-0.5"
                      key={index}
                    >
                      <InputGroup className="checkboxClass">
                        <InputGroup.Checkbox
                          className="w-[31px] h-[31px] bg-[#CECECE80]"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const id = [...productId];
                              id.push(items);
                              setProductId(id);
                            } else {
                              const id = [...productId];
                              id.splice(index, 1);
                              setProductId(id);
                            }
                          }}
                        />
                      </InputGroup>

                      {items.type !== 1 ? (
                        <p
                          className={`text-[22px] max-lg:text-base font-bold mb-0 text-left flex items-center gap-2`}
                        >
                          {items.productName}
                          <Icon
                            icon="lets-icons:date-today"
                            width="25"
                            height="25"
                            style={{ color: "#CCFF00" }}
                          />
                          <Moment format="YY.MM.DD">
                            {items?.membershipStartDate}
                          </Moment>{" "}
                          {"~"}{" "}
                          <Moment format="YY.MM.DD">
                            {items?.membershipEndDate}
                          </Moment>
                        </p>
                      ) : (
                        <p className="text-[22px] max-lg:text-base font-bold mb-0 flex items-center gap-2">
                          {items.productName}{" "}
                          {`(${
                            items.sessionCount - items.completeCount
                          } sessions left)`}
                        </p>
                      )}
                    </div>
                  ))
                ) : (
                  <p className=" text-center text-lg font-bold text-[#C25E5F]">
                    Do not have any product to refund
                  </p>
                )}
                {/*  */}
              </div>
            </div>
            {productId.length > 0 && (
              <div className="bg-[#414142] rounded-[10px] p-8">
                <p className="text-[30px] max-lg:text-2xl font-bold border-b-2 border-[#707070] mb-4 pb-3 text-start">
                  {strings.REFUND_ITEM}
                </p>
                <p className="border-b-2 border-[#707070] pb-4 text-[24px] font-bold">
                  Please calculate the refund amount at the center and enter it
                  in the refund amount field. Deojal cannot calculate the refund
                  amount.
                </p>
                {productId?.length > 0 &&
                  productId.map((val, index) => (
                    <div key={index} className="flex items-start gap-2">
                      <p className="text-[24px] max-lg:text-lg font-bold mb-0 w-[90%] flex items-start gap-0.5 text-start justify-between">
                        {val?.productName}
                        <span className="text-[24px] max-lg:text-lg font-bold text-white">
                          :
                        </span>
                      </p>
                      <div className="w-full mb-2.5">
                        <div className="flex items-center gap-1 mb-2">
                          <p className="text-[20px] max-lg:text-base font-bold w-[80%] mb-0 ">
                            {strings.REFUND_DATE}
                          </p>
                          <div
                            htmlFor="date"
                            className="bg-[#1F2022] w-full h-[50px] px-3 flex items-center gap-2 rounded-[10px] newDatePickerClass modalDatePicker"
                          >
                            <Icon
                              icon="lets-icons:date-today"
                              width="22"
                              height="22"
                              style={{ color: "#CCFF00" }}
                              onClick={() => {
                                setOpenCalender({
                                  [val.id]: !openCalender[val.id],
                                });
                              }}
                            />
                            <DatePicker
                              oneTap
                              caretAs={HiCalendar}
                              id={val?.memberOwnProductId}
                              placeholder={"Refund date"}
                              format="yyyy.MM.dd"
                              block
                              editable={false}
                              appearance="subtle"
                              className="custom-datepicker bg-transparent border-0 w-full px-0"
                              value={val?.refundDate}
                              onChange={(date) => {
                                setStartDate(date);
                                const time = [...productId];
                                time[index].refundDate = date;
                                setProductId(time);
                              }}
                              onChangeCalendarDate={(e) => {
                                setCurrentMonth(e);
                              }}
                              onNextMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onPrevMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onOpen={() => {
                                setIdProduct(val?.memberOwnProductId);
                                setIsCalenderOpen(true);
                                setCurrentMonth(
                                  val?.refundDate
                                    ? new Date(val?.refundDate)
                                    : new Date()
                                );
                                setDatePickerCheck(2);
                              }}
                              onClose={() => {
                                setIsCalenderOpen(false);
                                setCurrentMonth(
                                  val?.refundDate
                                    ? new Date(val?.refundDate)
                                    : new Date()
                                );
                                setDatePickerCheck("");
                              }}
                              disabledDate={(date) => {
                                const maxDate = new Date(
                                  Date.now() - 24 * 60 * 60 * 1000
                                );
                                return date < maxDate;
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex items-center gap-1">
                          <p className="text-[20px] max-lg:text-base font-bold w-[80%] mb-0">
                            {strings.REFUND_PRICE}
                          </p>
                          <label
                            htmlFor="date"
                            className="bg-[#1F2022] w-full h-[50px] px-3 flex items-center gap-2 rounded-[10px] dateInputClass numberFieldClass"
                          >
                            <img src={won} alt="currany" />
                            <input
                              type="text"
                              value={formatPrice(productId[index].refundPrice)}
                              className="w-full bg-[#1F2022] text-base font-bold numberField"
                              onChange={(e) => {
                                let priceValue = parseFormattedPrice(
                                  e.target.value
                                );
                                setProductPrice(priceValue);
                                const price = [...productId];
                                price[index].refundPrice =
                                  parseFormattedPrice(e.target.value) >
                                  val.price
                                    ? val.price
                                    : parseFormattedPrice(e.target.value);
                                // price[index].refundPrice = priceValue;
                                setProductId(price);
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="flex justify-center mt-6">
            {productList.length > 0 ? (
              <button
                onClick={() => {
                  refundProductHandle();
                }}
                className="text-lg font-extrabold text-black bg-common_color h-[60px] w-[160px] rounded-[28px] disabled:bg-[#ccff0094]"
                disabled={
                  !productId.length > 0 ? true : product.includes(false)
                }
              >
                {strings.CONFIRM}
              </button>
            ) : (
              <button
                onClick={() => {
                  setRefundModal(false);
                }}
                className="text-lg font-extrabold text-black bg-common_color h-[60px] w-[160px] rounded-[28px]"
              >
                {strings.CANCEL}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PurchaseListModal;
