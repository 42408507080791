import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class AuthSublyApi {
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${BASE_URL}${endpoint}`;
    const headers = { ...header };
    try {
      const result = await axios({ url, method, data, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /* ------LOGIN WITH KAKAO API -----*/

  static async loginWithKakao(token) {
    let header = { Authorization: `bearer ${token}` };
    let res = await this.request(
      `auth/validate-kakao`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------LOGIN WITH NAVER API -----*/

  static async loginWithNaver(data) {
    let res = await this.request(`auth/naver-login`, data, "post");
    return res;
  }

  /* ------LOGIN WITH APPLE API -----*/

  static async loginWithApple(token) {
    let header = { Authorization: `bearer ${token}` };
    let res = await this.request(`auth/apple-login`, undefined, "post", header);
    return res;
  }

  /* ------FORGOT ACCOUNT API -----*/

  static async forgotAccount(data) {
    let res = await this.request(
      `auth/forget-account?web=${true}`,
      data,
      "post"
    );
    return res;
  }

  /* ------VERIFY OTP API -----*/

  static async verifyOTP(data) {
    let res = await this.request(`auth/verify-web-otp`, data, "post");
    return res;
  }

  /* ------SAVE CENTER API -----*/

  static async saveCenter(token, data) {
    let header = { Authorization: `bearer ${token}` };
    let res = await this.request(`thejal/save-center`, data, "post", header);
    return res;
  }
}

export default AuthSublyApi;
