import React, { useState } from "react";
import { placeholder, strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import demoP from "../../../../../assests/images/demoProfile.svg";
import ReactPaginate from "react-paginate";
import status from "../../../../../assests/icons/status.svg";
import moment from "moment";

function GymLockerInfoModal({
  onClose,
  lockerData,
  openHistory,
  seteditLocker,
  setLockerId,
  setdeleteLocker,
  classNameValue,
}) {
  // console.log("-----LOCKERDATA", lockerData);
  const [name, setName] = useState("");
  const [isError, setIsError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [innerLoader, setInnerLoader] = useState(false);
  const [requestedData, setRequestedData] = useState("");
  const [error, setError] = useState("");
  const [discription, setDiscription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventType, setEventType] = useState({ text: "", type: "" });
  const [openType, setOpenType] = useState(false);
  const [regularPrice, setRegularPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [finalPrice, setFinalPrice] = useState(
    regularPrice
      ? regularPrice && regularPrice - discountPrice > 0
        ? regularPrice - discountPrice
        : 0
      : ""
  );
  const [isAlways, setisAlways] = useState(false);
  const [periodMonth, setperiodMonth] = useState({ month: "", day: "" });
  const [periodAddMonth, setperiodAddMonth] = useState({ month: "", day: "" });
  const [sessionCount, setSessionsCount] = useState({ count: "", addOn: "" });
  const [time, setTime] = useState("");
  const [openPeriod, setOpenPeriod] = useState({ month: false, day: false });
  const [openAddPeriod, setOpenAddPeriod] = useState({
    month: false,
    day: false,
  });
  const [timeDropdown, setTimeDropdown] = useState(false);

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex items-center justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4  z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[90%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.LOCKER_INFORMATION}
            </p>
          </div>
          <div className="mt-4 flex flex-wrap justify-center 2xl:px-44 gap-y-6  lg:py-8">
            <div className="sm:px-5 border-e border-[#444444] flex-1 space-y-7">
              <div className="flex  items-center">
                <Icon
                  icon="ph:lockers-light"
                  width="24px"
                  height="24px"
                  style={{ color: "white" }}
                />
                <div className="flex items-center flex-wrap text-[18px] font-bold text-white ms-3">
                  <span className=" ">{strings.LOCKER_NUMBER}</span>
                  <span className="text-[22px] font-extrabold ms-2.5 flex items-baseline ">
                    {lockerData.lockerNumber}
                  </span>
                </div>
              </div>
              <div className="flex  items-center">
                <Icon
                  icon="ep:user"
                  width="24px"
                  height="24px"
                  style={{ color: "#C5C5C5" }}
                />
                <div className="flex flex-wrap items-center text-[18px] font-bold text-white ms-3">
                  <span>{strings.USER_NAME}</span>
                  <span className="text-[22px] ms-2.5 font-extrabold">
                    {lockerData.name == null ? "--" : lockerData.name}
                    {/* {lockerData.name} */}
                  </span>
                </div>
              </div>
              <div className="flex  items-center">
                <Icon
                  icon="lets-icons:date-today"
                  width="24px"
                  height="24px"
                  style={{ color: "#C5C5C5" }}
                />
                <div className="flex flex-wrap items-center text-[18px] font-bold text-white ms-3">
                  <span className="">{strings.START_DATE}:</span>
                  <span className="text-[22px] ms-2.5 font-extrabold">
                    {moment(lockerData.startDate).isValid()
                      ? moment(lockerData.startDate).format("YY.MM.DD")
                      : "--"}
                  </span>
                </div>
              </div>
              <div className="flex  items-center">
                <img src={status} alt="" />
                <div className="flex flex-wrap items-center text-[18px] font-bold text-white ms-3">
                  <span> {strings.STATUS}:</span>
                  <span className="text-[22px] ms-2.5 font-extrabold">
                    {lockerData.status}
                  </span>
                </div>
              </div>
            </div>
            <div className="sm:px-5  flex-1 space-y-7 ">
              <div className="flex  items-center">
                <Icon
                  icon="mdi:lock-outline"
                  width="24px"
                  height="24px"
                  style={{ color: "#C5C5C5" }}
                />
                <div className="flex flex-wrap items-center text-[18px] font-bold text-white ms-3">
                  <span> {strings.PASSWORD}: </span>
                  <span className="text-[22px] ms-2.5 font-extrabold">
                    {lockerData.lockerPassword}
                  </span>
                </div>
              </div>
              <div className="flex  items-center">
                <Icon
                  icon="lets-icons:date-today"
                  width="24px"
                  height="24px"
                  style={{ color: "#C5C5C5" }}
                />
                <div className="flex flex-wrap items-center text-[18px] font-bold text-white ms-3">
                  <span> {strings.REGISTER_DATE}: </span>
                  <span className="text-[22px] ms-2.5 font-extrabold">
                    {moment(lockerData.regDate).isValid()
                      ? moment(lockerData.regDate).format("YY.MM.DD")
                      : "--"}
                  </span>
                </div>
              </div>
              <div className="flex  items-center">
                <Icon
                  icon="lets-icons:date-today"
                  width="24px"
                  height="24px"
                  style={{ color: "#C5C5C5" }}
                />
                <div className="flex flex-wrap items-center text-[18px] font-bold text-white ms-3">
                  <span>{strings.EXPIRATION_DATE}:</span>
                  <span className="text-[22px] ms-2.5 font-extrabold">
                    {moment(lockerData.endDate).isValid()
                      ? moment(lockerData.endDate).format("YY.MM.DD")
                      : "--"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-5 gap-y-2.5 mt-3 mb-4"}`}
          >
            <button
              onClick={() => {
                setdeleteLocker(true);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[162px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.DELETE}
            </button>
            <button
              onClick={() => {
                seteditLocker(true);
                onClose(false);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[162px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.EDIT}
            </button>
            <button
              onClick={() => {
                onClose(false);
                openHistory(true);
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[162px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.HISTORY}
            </button>
            <button
              onClick={() => {
                onClose(false);
                setLockerId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[162px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GymLockerInfoModal;

function ProductFilter({ setEventType }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[310px] right-0 top-18 max-h-[280px] overflow-auto z-10 px-2">
      <div
        onClick={() => {
          setEventType({ text: strings.CONTINUOUS, type: 0 });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">{strings.CONTINUOUS}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.LIMITED_TIME, type: 1 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">{strings.LIMITED_TIME}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.FIRST_COME_FIRST_SERVED, type: 2 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">
          {strings.FIRST_COME_FIRST_SERVED}
        </span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.RE_PURCHASE, type: 3 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">{strings.RE_PURCHASE}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.NEW, type: 4 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer"
      >
        <Icon icon="bi:dot" width="35" height="35" />
        <span className="mb-0 text-lg font-bold">{strings.NEW}</span>
      </div>
    </div>
  );
}
