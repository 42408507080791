import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class StatisticsSublyApi {
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${BASE_URL}${endpoint}`;
    const headers = { ...header };
    try {
      const result = await axios({ url, method, data, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /* ------STATISTICS SALE LIST API -----*/

  static async statisticsSaleList(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    if (data.startDate || data.endDate) {
      let res = await this.request(
        `stats/sales?startDate=${data.startDate}&endDate=${
          data.endDate
        }&filter=${"total"}`,
        undefined,
        "get",
        header
      );
      return res;
    } else {
      let res = await this.request(
        `stats/sales?filter=${data.searchType}`,
        undefined,
        "get",
        header
      );
      return res;
    }
  }

  /* ------STATISTICS MEMBERS LIST API -----*/

  static async statisticsMemberList(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    if (data.startDate || data.endDate) {
      let res = await this.request(
        `stats/member?startDate=${data.startDate}&endDate=${
          data.endDate
        }&filter=${"total"}`,
        undefined,
        "get",
        header
      );
      return res;
    } else {
      let res = await this.request(
        `stats/member?filter=${data.searchType}`,
        undefined,
        "get",
        header
      );
      return res;
    }
  }

  /* ------STATISTICS MEMBERSHIP LIST API -----*/

  static async statisticsMemberShip(token, isPtSession) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `stats/products?type=${isPtSession}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------statistics PT session list-----*/

  static async statisticsPtList(data, token) {
    const header = { Authorization: `Bearer ${token}` };
    if (data.startDate || data.endDate) {
      let res = await this.request(
        `stats/pt?startDate=${data.startDate}&endDate=${data.endDate}&filter=${data.filter}`,
        undefined,
        "get",
        header
      );
      return res;
    } else {
      let res = await this.request(
        `stats/pt?filter=${data.searchType}`,
        undefined,
        "get",
        header
      );
      return res;
    }
  }

  /* ------statistics PT tranier list-----*/

  static async statisticsPtTrainer(data, token) {
    const header = { Authorization: `Bearer ${token}` };
    if (data.startDate || data.endDate) {
      let res = await this.request(
        `stats/pt-trainer?startDate=${data.startDate}&endDate=${
          data.endDate
        }&filter=${"total"}`,
        undefined,
        "get",
        header
      );
      return res;
    } else {
      let res = await this.request(
        `stats/pt-trainer?filter=${data.searchType}`,
        undefined,
        "get",
        header
      );
      return res;
    }
  }

  /* ------statistics fetch PT graph value-----*/

  static async ptGraph(token, key, filter, type) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `stats/pt-graph?key=${key}&type=${type}&filter=${filter}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------statistics fetch Trainer graph value-----*/

  static async trainerGraph(token, key, id, filter) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `stats/pt-trainer-graph?trainerId=${id}&key=${key}&filter=${filter}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------statistics fetch graph value-----*/

  static async statisticsGraph(token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `stats/graph?type=${0}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------statistics fetch sales graph value-----*/

  static async statisticsMemberGraph(token, type, filter, startDate, endDate) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `stats/member-graph?type=${type}&filter=${filter}&startDate=${startDate}&endDate=${endDate}  `,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------statistics fetch sales graph value-----*/

  static async statisticsSalesGraph(token, type, filter, startDate, endDate) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `stats/sales-graph?type=${type}&filter=${filter}&startDate=${startDate}&endDate=${endDate}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------statistics fetch sales membership graph value-----*/

  static async salesMetaGraph(token, key, type, filter, startDate, endDate) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `stats/sales-meta-graph?type=${type}&key=${key}&filter=${filter}&startDate=${startDate}&endDate=${endDate}`,
      undefined,
      "get",
      header
    );
    return res;
  }
  static async memberMetaGraph(token, key, type, filter, startDate, endDate) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `stats/member-meta-graph?type=${type}&key=${key}&filter=${filter}&startDate=${startDate}&endDate=${endDate}`,
      undefined,
      "get",
      header
    );
    return res;
  }
}

export default StatisticsSublyApi;
