import React, { useState } from "react";
import logo from "../../../assests/icons/Logo.svg";
import { strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import { useNavigate } from "react-router-dom";
import SentAccountInfo from "../../commonComponents/modalScreens/SentAccountInfo";
import AuthSublyApi from "../../../helper/AuthApi";
import Loader from "../../../utils/Loader";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";

function ForgotAccount() {
  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  // ====================API FOR FORGOT ACCOUNT=================
  async function forgotAccountHandle() {
    setIsLoader(true);
    const requestData = { email: emailValue, cellphone: phone };
    await AuthSublyApi.forgotAccount(requestData)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setOpenInfoModal(true);
          setError("");
          setTimeout(() => {
            navigate("/Verification", {
              state: { email: emailValue, phone: phone },
            });
          }, 3000);
        } else if (response.data.statusCode == 404) {
          setError(strings.THE_EMAIL_AND_PHONE_NUMBER_ERROR);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section className="flex items-center justify-center h-screen">
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
      {isLoader ? <Loader /> : ""}
      <div className="bg-box_bg w-[50%] max-lg:w-[70%] rounded-lg max-md:w-[90%]  py-16 px-6">
        <div className="w-[80%] m-auto max-lg:w-full text-center">
          <div>
            <img
              src={logo}
              alt="logo"
              className="w-32 h-16 max-lg:w-30 max-lg:h-14 mx-auto mb-4"
            />

            {!error ? (
              <p className="text-3xl max-lg:text-xl font-bold text-white">
                {strings.FIND_ACCOUNT_INFORMATION}
              </p>
            ) : (
              <p className="text-3xl max-lg:text-xl font-bold text-[#FF7C7C]">
                {error}
              </p>
            )}
          </div>
          <div className="mt-6 mb-10 w-[90%] mx-auto max-lg:w-full">
            <label
              htmlFor="email"
              className="bg-[#2C2C2E] py-2.5 px-3 flex items-center gap-2 rounded-[30px]"
            >
              <p className="!mb-0 flex items-center gap-2 text-[#B6B6B6] font-bold text-xl max-lg:text-sm whitespace-nowrap">
                <Icon
                  icon="ic:outline-mail"
                  width="24"
                  height="24"
                  style={{ color: "#FFFFFF" }}
                />
                {strings.EMAIL_}
              </p>
              <input
                type="text"
                autoComplete="off"
                className="p-2 bg-[#2C2C2E] text-white w-[80%]"
                id="email"
                onChange={(e) => {
                  setEmailValue(e.target.value);
                }}
              />
            </label>
            <label
              htmlFor="phone"
              className="bg-[#2C2C2E] py-2.5 px-3 flex items-center gap-2 rounded-[30px] mt-4"
            >
              <p className="!mb-0 flex items-center gap-2 text-[#B6B6B6] font-bold text-xl max-lg:text-sm whitespace-nowrap">
                <Icon
                  icon="grommet-icons:phone"
                  width="24"
                  height="24"
                  style={{ color: "#FFFFFF" }}
                />
                {strings.CELL_PHONE_NO}
              </p>
              <input
                type="text"
                autoComplete="off"
                className="p-2 bg-[#2C2C2E] text-white w-[80%]"
                id="phone"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="flex items-center justify-center gap-4">
            <button
              onClick={() => navigate(-1)}
              className="kakaoClass text-2xl max-lg:text-lg max-lg:h-[50px] font-extrabold flex items-center justify-center gap-1 text-white rounded-[30px] h-[60px] w-[180px]"
            >
              <Icon
                icon="iconamoon:arrow-left-2-light"
                width="30"
                height="30"
                style={{ color: "#FFFFFF" }}
              />
              {strings.BACK}
            </button>
            <button
              onClick={() => {
                forgotAccountHandle();
              }}
              disabled={emailValue || phone ? false : true}
              className="bg-common_color disabled:bg-[#ccff0094] text-2xl max-lg:text-lg max-lg:h-[50px] font-extrabold text-black rounded-[30px] h-[60px] w-[180px]"
            >
              {strings.CONFIRM}
            </button>
          </div>
        </div>
      </div>
      <SentAccountInfo
        openInfoModal={openInfoModal}
        setOpenInfoModal={setOpenInfoModal}
        emailValue={emailValue}
        phone={phone}
      />
    </section>
  );
}

export default ForgotAccount;
