export const strings = {
  ACTION_STATUS: "Action Status",
  FITNESS_GYM: "Fitness Gym",
  EDIT_INFORMATION_SETTING: "Edit Information Setting",
  LOGIN_WITH: "Login with",
  FORGOT_ACCOUNT: "Forgot account?",
  FOUND_AT_MULTIPLE_GYMS: "Found at multiple gyms:",
  CLOSED: "Closed -",
  LOGIN: "Login",
  AM: "AM",
  PM: "PM",
  CENTER_CLOSURE_DAYS: "Center Closure Holidays",
  LOGIN_FAILED: "Login failed.",
  TRY_IT_AGAIN_WITH_CORRECT: "Try it again with correct information.",
  CLOSE: "Close",
  FIND_ACCOUNT_INFORMATION: "Find account information",
  EMAIL_: "Email",
  CELL_PHONE_NO: "Cell Phone No:",
  NO: "No",
  BACK: "Back",
  CHAT: "Chat",
  QUANTITY: "Quantity",
  FINISHED: "Finished",
  REQUESTING_REFUND: "Requesting Refund",
  BROWSE_FILE: "Browse file",
  MACHINE_INFORMATION: "Makers of machines",
  REQUESTED_FEEDBACK_METHOD: "Requested Feedback method : ",
  CONFIRM: "Confirm",
  DESCRIPTION: "Description",
  ADD_INFORMATION: "Add information",
  PURCHASED_PRICE: "Purchased price :",
  NEXT_TRAINER: "Next Trainer",
  USER_NAME1: "User name",
  LOCKER_NUMBER1: "Locker number",
  NO_SHOWS: "No-Show",
  CURRENT_TRAINER: "Current Trainer",
  EXPIRATION_DATE: "Expiration date",
  FIXED: "Fixed",
  LOCKER_HISTORY: "Locker History",
  In_Use: "In-Use",
  SAVE: "Save",
  HOLIDAYS: "Holidays",
  THE_EMAIL_AND_PHONE_NUMBER_ERROR:
    "The email and phone number you entered did not match. Please try again!",
  SENT_ACCOUNT_INFORMATION_TO_YOUR_CELL:
    "Sent account information to your cell phone",
  CHECK_MESSAGE_IN_KAKAOTALK_OR_CELL:
    "Check message in Kakaotalk or cell phone MSG",
  VERIFICATION_CODE: "Verification Code",
  MEMBER_SEARCH: "Member search",
  LOGOUT: "Logout",
  REGISTER: "Register",
  HISTORY: "History",
  LOCKER_INFORMATION: "Locker Information",
  NAME_: "Name:",
  SEARCH: "Search",
  MORE_INFO: "More Info",
  MEMBERSHIP: "Membership",
  LOCKER: "Locker",
  FITNESS_APP: "Fitness App",
  STATISTICS: "Statistics",
  PT_INFORMATION: "PT information",
  BOARD: "Board",
  MANAGEMENT: "Management",
  GYM_SETTING: "Gym setting",
  ATTACH_IMAGE: "Attach Image",
  PRODUCT: "Product",
  TAKE_OVER: "Take over",
  TOTAL_PRICE_: "Total Price:",
  CANCEL: "Cancel",
  UPDATE: "Update",
  ADD_LOCKER: "Add Locker",
  ADD: "Add",
  MODIFY: "Modify",
  UNIFORM: "Uniform",
  LIST: "List",
  HOLDING: "Holding",
  GOLF: "Golf",
  ADD_PRODUCT: "Add Product",
  REFUND: "Refund",
  FROM_USER_CODE: "From (User Code):",
  NO_PRODUCT_FOUND: "No Product Found",
  REGISTRATION_DATE: "Registration Date:",
  KAKAO_TALK_ACCOUNT: "Kakao Talk Account",
  MALE: "Male",
  FEMALE: "Female",
  LIST_OF_LOCKERS: "List of Lockers",
  EMPLTY_LOCKERS: "Empty Lockers",
  EMPTY: "Empty",
  IN_USE_LOCKER: "In-use Lockers",
  EXPIRED_LOCKERS: "Expired Lockers",
  LOCKER_NUMBER: "Locker number:",
  PASSWORD: "Password",
  NO_RECORD_FOUND: "No Record Found",
  DAY_LEFT: "day left",
  SESSION_LEFT: "session left",
  TAKE_OVER_TO_MEMBER: "Take over to member",
  TAKE_OVER_TO_MEMBER_CODE: "Take-over to Member Code:",
  MEMBER_NAME: "Member Name:",
  CELL_PHONE: "Cell Phone",
  DATE: "Date",
  RESEND: "Resend",
  MEMO: "Memo",
  AUTHENTICATION: "Authentication",
  PHONE: "Phone",
  BIRTH: "Birth",
  ADDRESS: "Address",
  ADDRESS_ONE: "Address 1",
  ADDRESS_TWO: "Address 2",
  ADD_MEMO: "Add Memo",
  PURCHASED_ITEMS_LIST: "Purchased Items List",
  REFUND_ITEM: "Refund item",
  REFUND_DATE: "Refund Date",
  REFUND_PRICE: "Refund Price",
  EDIT_LOCKER: "Edit Locker",
  LOCKER_NO: "Locker No.",
  DELETE: "Delete",
  DELETE_MEMO: "Delete Memo",
  DO_YOU_WANT_TO_DELETE_MEMO: "Do you want to delete this memo?",
  MEMO_DELETED_SUCCESSFULLY: "Memo deleted successfully",
  ARE_YOU_SURE_YOU_WANT_TO_EDIT_DETAILS:
    "Are you sure you want to edit this details?",
  EDIT: "Edit",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_LOCKER:
    "Are you sure you want to permanently delete this item?",
  DELETE_LOCKER: "delete locker",
  LOCKER_EDITED_SUCCESSFULLY: "Locker Edited successfully",
  TYPE: "Type",
  PRICE: "Price",
  STATISTICS_SALES_DES: "Statistics - Sales",
  SALES: "Sales",
  MEMBERS: "Members",
  PT: "PT",
  TOTAL: "Total",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  ONE_WEEK: "1 Week",
  ONE_MONTH: "1 Month",
  LAST_MONTH: "Last Month",
  PT_SESSIONS: "PT Sessions",
  GOLF_SESSIONS: "GOLF sessions",
  GX: "GX",
  YOGA: "Yoga",
  GOLF_MEMBERSHIPS: "Golf Memberships",
  STATISTICS_MEMBERS_DES: "Statistics – Members",
  ALL_MEMBERS: "All Members",
  ALL: "All",
  REVIEW: "Review",
  WORKOUT_LOG: "Workout log",
  DIET_LOG: "Diet log",
  SCHEDULE: "Schedule",
  STATUS: "Status",
  PT_INFORMATION_DIET_LOG: "PT information – Diet log",
  GYM_LOCKER_SETTING: "Gym Locker setting",
  MEALS: "Meals",
  SNACK: "Snack",
  PROTEIN: "Protein",
  CARB: "Carb",
  FAT: "Fat",
  FIBER: "Fiber",
  SOLD_COUNT: "Sold count",
  REPURCHASE_COUNT: "Repurchase count",
  MOST_BUY_PRODUCT: "Most buy product",
  MOST_SALE: "Most Sales",
  BUYER_COUNT: "Buyer count",
  MOST_POPULAR: "Most popular",
  SESSION: "sessions",
  CHANGE_THE_TRAINER: "Change Trainer",
  SELECT_TRAINER: "Select Trainer",
  TRAINER_NAME: "Trainer Name",
  TOTAL_SESSIONS: "Total Sessions",
  CONTACT_NUMBER: "Contact Number",
  DISCOUNT: "Discount",
  LIST_OF_ADD_PRODUCT: "List of Add Product",
  SELECT_PRODUCT: "Select Product",
  ACTIVE_MEMBER: "Active Member",
  PEOPLE: "People",
  NEW_MEMBER: "New Member",
  DAYS: "Days",
  DAY: "Day",
  EXPIRE_IN_SEVEN_DAYS: "Expire in 7 days",
  EXPIRE_IN_THIRTY_DAYS: "Expire in 30 days",
  AVG_ATTENDING_DAYS: "Avg Attending days",
  AGE_DISTRIBUTION: "Age distribution",
  PT_INFORMATION_WORKOUT_LOG: "PT information – Workout log",
  COMPLETE: "Complete",
  INCOMPLETE: "Incomplete",
  EXERCISES: "Exercises",
  TRAINER: "Trainer",
  ARM: "Arm",
  COMMENTS: "Comments",
  NO_EXCERCISE: "No workout log. Have to write it.",
  SET: "set",
  CONFIRMED: "Confirmed",
  TRAINER_MEMO: "Trainer's Memo",
  PRODUCT_TYPE: "Product Type",
  PRODUCT_NAME: "Product Name",
  PERIOD_COUNT: "Period/Count",
  BONUS_SUB: "(bonus)",
  FINAL_PRICE: "Final Price",
  START_TO_END_DATE: "Start to End date",
  ONE_YEAR: "1 Year",
  STATISTICS_PT_SESSIONS: "Statistics – PT - Sessions",
  ARE_YOU_SURE_RESEND_THIS_MESSAGE:
    " Are you sure you want to resend this message ?",
  TRAINERS: "Trainers",
  PT_SESSION: "PT - Session",
  EXPIRE_IN_THREE: "Expire in 3 sessions",
  EXPIRE_IN_FIVE: "Expire in 5 sessions",
  AVG_EXERCISE: "Avg Exercises",
  REPURCHASE_MEMBER: "Repurchase member",
  CAP_SESSION: "Sessions",
  GENERAL: "General",
  SUGGESTIONS_LOG: "Suggestions log",
  NOTICE: "Notice",
  GYM_BOARD_GENERAL: "Gym Board - General",
  ID: "ID",
  TITLE: "Title",
  VIEWS: "Views",
  WRITE: "Write",
  SIX_MONTH: "6 month",
  CONTENT: "Content",
  WRITER: "Writer",
  UNCONFIRMED_SESSIONS: "Unconfirmed sessions",
  COMPLETED_SESSIONS: "Completed sessions",
  REMAINED_SESSIONS: "Remained sessions",
  AVERAGE_PERFORMANCE_SCORE: "Average Performance Score",
  AVERAGE_WORKOUT_LOG_ENTRIES: "Average Workout Log Entries",
  AVERAGE_DIET_LOG_ENTRIES: "Average Diet Log Entries",
  UPLOAD_MEDIA_FILES: "Upload media files",
  PERSONAL_WORKOUT: "Personal Workout",
  STAFF_SETTING: "Staff setting",
  MESSAGE_SETTING: "Message setting",
  GUM_SETTING_STAFF_SETTING: "Gym setting – Staff Setting",
  STAFF_LIST: "Staff List",
  WORKING_HOUR: "Working Hour",
  START_DATE: "Start Date",
  DEPARTURE_DATE: "Departure date",
  LEVEL: "Level",
  ON: "On",
  OFF: "Off",
  STAFF_AUTHORITY: "Staff Authority",
  STATISTICS_SALES: "Statistics sales",
  STATISTICS_MEMBERS: "Statistics members",
  STATISTICS_PT: "Statistics PT",
  GYM_STAFF_SETTING: "Gym setting - Staff setting",
  GYM_GYM_SETTING: "Gym setting - Gym setting",
  GYM_NOTICE: "Gym setting - Notice",
  PT_INFORMATION_REVIEW: "PT information - Review",
  PT_INFORMATION_STATUS: "PT information - Status",
  MENAGEMENT_R_H: "Management R, H",
  MENAGEMENT_P_E: "Management P, E",
  MEMBER: "Member",
  SEARCH_MEMBER: "Search member",
  CURRENT: "Current",
  TILL: "Till",
  COMPLETED: "Completed",
  MEMBER_LIST: "Member list",
  RECENT_SESSION: "Recent session",
  CANCEL_COUNT: "Cancel count",
  NOSHOW_COUNT: "Noshow count",
  PT_MEMBERSHIP_DATE: "PT membership date",
  REGISTER_DATE: "Register date",
  SENT_MSG: "Sent MSG",
  GYM_BOARD_SUGGESTIONS: "Gym Board - Suggestions",
  REPLY: "Reply",
  UPDATE_STAFF_AUTHORITY: "Update Staff Authority",
  UPDATE_STAFF_SUB_TEXT: "Do you want to change staff authority?",
  PLEASE_CHECK_THE_BELOW:
    " Please click below and you will be allowed to update. ",
  POST_COMMENT: "Post Comment",
  DELETE_COMMENT: "Delete comment",
  DO_YOU_WANT_DELETE_COMMENT: "Do you want to delete this comment?",
  DO_YOU_WANT_DELETE_GENERAL_BOARD: "Do you want to delete this genral board?",
  PT_SESSION_HISTORY: "PT Session history",
  MEMBER_INFO: "Member info",
  TIME: "Time",
  WORKOUT: "Workout",
  LOGS: "Logs",
  EXERCISE: "Exercise",
  LOG: "Log",
  LEFT: "Left",
  SENT_MESSAGE_LIST: "Sent Message List",
  SYSTEM: "System",
  CENTER: "Center",
  ADMIN: "Admin",
  MESSAGE_TYPE: "Message type",
  MESSAGES: "Messages",
  NUMBER_OF_PARTICIPANTS: "NUMBER OF PARTICIPANTS",
  REQUESTED: "Requested",
  NO_SHOW: "No show",
  MORE: "More",
  WRITTEN_ON: "Written on",
  EVENT: "Event",
  REFUND_REQUEST: "Refund request",
  HOLDING_REQUEST: "Holding Request",
  MANAGEMENT_PRODUCT: "Management – Product",
  CURRENT_TIME: "Current Time",
  REQUEST_FOR_CHANGING_TRAINER: "Request for Changing Trainer  ",
  PERIOD_BONUS: "Period(bonus)",
  END_DATE: "End Date",
  START_DATE_SMALL: "Start date",
  EXPIRED: "Expired",
  ON_SALE: "On Sale",
  SESSION_DATE: "Session date",
  REVIEW_DATE: "Review date",
  PT_INFORMATION_SCHEDULE: "PT information – Schedule",
  WEEK: "Week",
  MONTH: "Month",
  PREVIOUS_WEEK: "Previous week",
  BOOKED: "Booked",
  RESERVATIONS: "Reservations",
  TOTAL_COMPLETED: "Total Completed",
  TOTAL_MEMBERS: "Total members",
  NEW_MEMBERS: "New members",
  GYM_BOARD_NOTICE: "Gym Board - Notice",
  MANAGEMENT_EVENT: "Management – Event",
  MANAGEMENT_CHANGE_TRAINER_REQUEST: "Management – Change Trainer Request",
  Management_Refund_Request: "Management – Refund Request",
  MANAGEMENT_HOLDING_REQUEST: "Management – Holding Request",
  EVENT_TYPE: "Event Type",
  PARTICIPANTS: "Participants",
  REGISTER_EVENT: "Register Event",
  EVENT_SALE: "Event Sale",
  ALWAYS_AVAILABLE: "Always Available",
  SELECT_PERIODL: "Select Period",
  PERIOD: "Period",
  EVENT_INFORMATION: "Event information",
  SESSION_COUNT: "Session Count",
  COUNT: "Count",
  HOLDING_DAYS: "Holding Days",
  GYM_SETTING_MESSAGE_SETTING: "Gym Setting - Message Setting",
  HOLDING_COUNT: "Holding Count",
  FIXED_PERIOD: "Fixed Period",
  NUMBER: "Number",
  STATUS_CHANGED: "Status Changed",
  REGISTER_STAFF: "Register Staff",
  USER_CODE: "User code",
  NICK_NAME: "Nick name",
  USER_NAME: "User Name:",
  JOB: "Job",
  HIRE_TYPE: "Hire type",
  SELECT_JOB_TYPE: "Select job type",
  CONTINUOUS: "Continuous",
  LIMITED_TIME: "Limited Time",
  FIRST_COME_FIRST_SERVED: "First-come-first-served",
  RE_PURCHASE: "Re-purchase",
  NEW: "New",
  DO_YOU_WANT_TO_DELETE_EVENT: "Do you want to delete this event?",
  CLEANING: "Cleaning",
  EQUIPMENT_AND_MACHINE_REPAIR: "Equipment and Machine Repair",
  ETC: "Etc.",
  ADD_MACHINE_INFORMATION: "Add Machine information",
  GYM_SETTING_GYM: "Gym setting – Gym",
  MACHINES: "Machines",
  NAME: "Name",
  HOLDING_FOR: "Holding for",
  SPORTS_EQUIPMENT_INFORMATION: "Sports equipment information",
  TALENT_LEVEL: "Talent level:",
  SELECT_LEVEL: "Select level",
  TEAM_LEADER: "Team Leader",
  RISING_STAR: "Rising Star",
  PROFESSIONAL_CERTIFICATE_HOLDER: "Professional Certificate Holder",
  COMPETITION_EXPERIENCE: "Competition Experience",
  COMPETITION_WINNER: "Competition Winner",
  TEAM_MANAGER: "Team manager",
  STAFF: "Staff",
  REPRESENTATIVE: "Representative",
  DIRECTOR: "Director",
  REGULAR: "Regular",
  PART_TIME: "Part-time",
  TEMPORARY: "Temporary",
  IRREGULAR: "Irregular",
  AFFILIATED_COMPNY_EMPLOYEES: "Affiliated company employees",
  STAFF_INFORMATION: "Staff Information",
  CURRENT_STATUS: "Current Status:",
  EDIT_STAFF_INFORMATION: "Edit staff information",
  CATEGORY: "Category",
  SATFF_AUTHORITY_HAS_BEEN_CHANGE: "Staff authority has been changed for",
  REQUEST_HOLDING_FOR: "Request Holding for",
  YEAR_MEMBERSHIP: "1 – Year Membership information",
  PURCHASED_DATE: "Purchased date :",
  POSSIBLE_HOLDING_COUNT: "Possible holding count:",
  POSSIBLE_HOLDING_DAYS: "Possible holding days:",
  REQUEST_HOLDING_DAYS: "Request holding days: ",
  APPROVE_DATE: "Approve date:",
  APPROVE_STAFF: "Approve staff:",
  APPROVE: "Approve",
  Change_Trainer_Req: "Change Trainer Req.",
  Change_Trainer_Request: "Change Trainer Request",
  ADD_LOCKERS: "Add Lockers",
  PRODUCT_INFORMATION: "Product information",
  REGISTER_PRODUCT: "Register Product",
  EDIT_PRODUCT_INFORMATION: "Edit Product information",
  EDIT_EVENT_INFORMATION: "Edit Event information",
  DELETE_PRODUCT: "Delete Product",
  DELETE_EVENT: "Delete Event",
  DO_YOU_WANT_TO_DELETE_PRODUCT: "Do you want to delete this product?",
  PRODUCT_DELETED_SUCCESSFULLY: "Product deleted successfully",
  EVENT_DELETED_SUCCESSFULLY: "Event deleted successfully",
  HOLIDAY: "Holiday",
  GYM_INFORMATION: "Gym information",
  INFORMATION_SETTING: "Information setting",
  REGISTER_HOLIDAYS: "Register Holidays",
  NATIONAL_PUBLIC_HOLIDAYS: "National Public Holidays",
  TRADITIONAL_HOLIDAYS: "Traditional Holidays",
  DURATION: "Duration",
  REQUESTED_BY_THE_MEMBER: "Requested by the member",
  PROCESSING: "In progress",
  REQUESTING: "Requesting",
  PROCESS: "Process",
  SPORT_EQUIPMENT_INFO: "Sport equipment info",
  UNIT: "Unit:",
  EDIT_GYM_INFORMATION: "Edit Gym information",
  GYM_NAME: "Gym Name*",
  FOUNDED: "Founded",
  CEO: "CEO",
  PARKING_TIME: "Parking time",
  TELEPHONE: "Telephone",
  ADD_ANOTHER_SHIFT: "Add another shift",
  HOLIDAY_DETAILS: "Holiday Details:",
  ADD_HOLIDAY: "Add Holidays",
  SHOWERS: "Showers:",
  WOMEN: "Women",
  MEN: "Men",
  PROVIDES: "Provides",
  INSTAGRAM: "Instagram",
  PT_TRAINER: "PT Trainers",
  WORKING_DAYS: "Working Days",
  SELECT_WORKING_DAYS: "Select working days",
  ADD_WORKING_TIME: "Add working time",
  FROM: "From",
  TO: "To",
  POSSIBLE_CANCEL_TIME_OF_PT: "Possible cancel time of PT:",
  HOURS: "Hours",
  MINUTES: "Minutes",
  SET_CANCEL_TIME_OF_PT: "Set cancel time of PT",
  WAIT_FOR_IMAGE: "Wait for image",
  KG: "Kg",
  TONES: "Tones",
  SELECT_DATE: "Select Date",
  HALFDAY: "Half day",
  FULLDAY: "Full day",
  COMPLETE_STAFF: "Complete staff",
  ARPU: "ARPU",
  CALL: "Call",
  UNABLE_SELECT_SAME_DAY: "Unable to select same day",
  YOU_HAVE_ALREADY_SCHEDULE_SAME_DAY:
    "You have already entered a schedule for the same day, you need to delete it. ",
  REFUND_FOR: "Refund for",
  CHANGE_STATUS_OF: "Change status to",
  ON_GOING: "On going",
  AUTHENTICATION_SENT_TO_EMAIL:
    "Authentication has been sent to your email and phone",
  DO_NOT_HAVE_DAYS_FOR_HOLDING: " Do not left days for holding",
  ERROR: "Error!",
  SENT_MESSAGE: "Sent Message",
  DUPLICATELOCKERS: "Duplicate Lockers",
  ALREADY_LOCKER_REGISTER: "Already Registered Lockers",
  UNABLE_TO_CHAT: "Unable to chat",
  YOU_MAY_NEED_PERMISSION: "You may need permission to start chat.",
  PLESASE_CONATC_TO_AUTHORISED:
    "Please contact to authorized staff for more information.",
  EDIT_MACHINE_INFORMATION: "Edit Machine information",
  NO_USER_FOUND: "No User Found",
  DURATION_TIME: "Duration Time",
  NOT_SELECTED: "Not Selected",
  ADD_SESSION: "Add Session",
  ADD_COUNT: "Add Count",
  UNLIMITED: "Unlimited",
  PREVIOUS_DAY: "Previous day",
  PREVIOUS_MONTH: "Previous month",
  SESSION_COUNT_PRODUCT: "Session count",
  ADD_SESSION_COUNT_PRODUCT: "Add Session count",
  INFORMATION_NOT_FOUNDED: "Information not found!",
  DAY_OFF: " Day Off!",
  CHANGE_STATUS_FOR_PRODUCT: "Change Status for this Product",
  CHANGE_STATUS_FOR_EVENT: "Change Status for this Event",
  CHANGE_STATUS_TO: "Change status to:",
  AMOUNT: "Amount",
  DISCOUNT_AMOUNT: "Discount Amount",
  IN_USE: "In-Use",
  RECEIVED_FROM: "Received from",
  PRODUCT_SALE: "Product Sale",
  REVIEW_CAN_ONLY_BE_VIEWD_BY_THE_OWNER:
    "Review can only be viewed by the owner. Permission change are not possible.",
  ADDITIONAL_DISCOUNT: "Additional Discount",
  MEMBER_NAMES: "Member Names",
  ASSIGNED_TRAINER: "Assigned Trainer",
  BIRTHDAY: "Birthday",
  USER_CODE: "User code",
  WRITING_TYPE: "Writing type",
  AMOUNT_OF_CHARGED_CASH: "Amount of charged cash",
  MESSAGE_AVAILABLE: "No.of Messages Available",
  WRITE_MESSAGE: "Write Message",
  ALERT_MESSAGE_FOR_MEMBER_SENT_MESSAGE:
    "This message will only be sent to members who have consented to receive event messages.",
  ALERT_MESSAGE_FOR_ALL_MEMBER_SENT:
    "This will be sent to all members. Caution is needed. Incorrectly sending event (promotional) messages as center notifications could result in restrictions on the message sending functionality.",
  ADD_MORE: "Add More",
  MESSAGE_DETAIL: "Message Detail",
  WRITING_TYPE: "Writing Type:",
  DELETE_MESSAGE: "Delete Message",
  RESEND_MESSAGE: "Resend Message",
  ARE_YOU_SURE_DELETE_THIS_MESSAGE:
    "Are you sure you want to permanently delete this Message?",
  TARGET: "Target",
  EDIT_MESSAGE: "Edit Message",
  NO_SEARCH_RESULTS_FOUND: "No search results found",
  SENT: "Sent",
  FAILED: "Failed",
  ALL_DAY: "All Day",
  MEMBERS_MEMO: "Member's Memo",
  // ===============================ATTENDANCE SECTION=============================
  ATTENDANCE_SYSTEM: "Attendance System",
  GYM_ID: "Gym Id",
  REMEMBER_ME: "Remember Me",
  FORGOT_PASSWORD: "Forgot Password",
  NOT_VALID_INFO: "Not valid information.",
  CHECK_ID_PW_AND_TRY_AGAIN: "Check ID/PW and try again",
  PASSWORD_RESET: "Password Reset",
  WOULD_YOU_LIKE_TO_SEND:
    "Please perform password recovery and change on the website. You can log in with the account you used to register and make changes",
  CHECK_YOUR_EMAIL: "Check your email",
  NEW_PASSWORD: "New password",
  RESET_YOUR_PASSWORD: "Reset your password",
  BOTH_PASSWORD_MUST_MATCH: "Both password must match.",
  MEMBERSHIP_EVENT: "Membership Event",
  PLEASE_ENTER_YOUR_DIGIT_NUMBER: "Please enter your 6-digit user number",
  NOT_VALID_INFORMATION: "Not valid information.",
  CHECK_NUMBER_AGAIN: "Check number again Or Ask user number to Staff",
  CELL_PHONE: "Cell Phone",
  SELECT_GENDER: "Select Gender",
  ZIP_CODE: "Zip Code",
};

export const placeholder = {
  NAME_STAR: "Name*",
  CELL_PHONE_STAR: "Cell Phone*",
  SELECT_UNIT: "Select unit",
  BIRTH_STAR: "Birth*",
  ZIP_CODE: "Zip Code",
  ADDRESS: "Address",
  ADD_MEMO: "Add Memo:",
  NAME: "Name",
  BIRTH: "Birth",
  PARTICIPANTS: "Number of Participants",
  CELL_PHONE: "Cell Phone",
  EMAIL: "Email",
  SELECT_GENDER: "Select gender*",
  PRODUCT_NAME: "Product Name",
  SELECT_TYPE: "Select Type",
  START_DATE: "Start Date",
  SEARCH_TRAINER: "Search trainer",
  ADD_TITLE: "Add Title",
  WRITE_CONTENT: "Write content",
  ADD_A_REPLY: "Add a reply",
  EVENT_NAME: "Event Name",
  SELECT_EVENT_TYPE: "Select Event Type",
  ENTER_NAME: "Enter Name",
  ENTER_NICK_NAME: "Enter Nick name",
  MEMBERSHIP: "Membership",
  PT_SESSIONS: "PT sessions",
  Add_EUPIPMENT_QUNTITY: "Add equipment quantity",
  USER_NAME: "User Name",
  DURATION_TIME: "Duration Time",
  ADD_SESSION: "Add sessions",
  ADD_COUNT: "Add count",
  SELECT_PRODUCT_TYPE: "Select Product Type",
  ENTER_CENTER_NAME: "Enter center's name",
};
