import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class PT_InformationSublyApi {
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${BASE_URL}${endpoint}`;
    const headers = { ...header };
    try {
      const result = await axios({ url, method, data, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /* ------DIET LOG LIST API -----*/

  static async dietLogList(token, page, name, phone, trainerId) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/diet-logs?page=${page}&name=${name}&cellphone=${phone}&trainerId=${trainerId}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------DIET LOG DETAIL API -----*/

  static async dietLogDetail(token, id, date) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/diet-logs/${id}?date=${date}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------WORKOUT LOG LIST API -----*/

  static async workoutLogList(
    token,
    page,
    name,
    phone,
    trainerId,
    status,
    created
  ) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/workout-logs?page=${page}&name=${name}&cellphone=${phone}&trainerId=${trainerId}&status=${status}&createdBy=${created}&pageSize=12`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------WORKOUT LOG DETAIL API -----*/

  static async workoutLogDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/workout-logs/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------STATUS MEMBERS LIST API -----*/

  static async statusMemberList(token, data, page) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-member?pageSize=${20}&page=${page}`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ------STATUS TRAINER LIST API -----*/

  static async statusTrainerList(token, data, page) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-trainer?pageSize=${2}&page=${page}`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ------Trainer's PT-session history detail-----*/

  static async ptSessionHistory(token, id, page, order, key) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-session-history/${id}?page=${page}&pageSize=${10}&order=${order}&key=${key}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------Fetch Review List API-----*/

  static async fetchReviewList(token, page, search, id, phone, key) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-trainer-review?page=${page}&pageSize=${9}&search=${search}&trainerId=${id}&key=${key}&cellphone=${phone}&order=${"DESC"}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------Fetch Review Detail API-----*/

  static async fetchReviewDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-trainer-review/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------Fetch Schedule calender data API-----*/

  static async fetchScheduleData(token, month, year, isComplete) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-sessions?month=${month}&year=${year}&isCompleted=${isComplete}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------Fetch Schedule statistics data API-----*/

  static async scheduleStatistics(token, range, date, month, year) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-sessions-stats?range=${range}&date=${date}&month=${month}&year=${year}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------Fetch Schedule trainer statistics data API-----*/

  static async scheduleStatisticsTrainer(token, range, date, month, year) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-sessions-stats-trainer?range=${range}&date=${date}&month=${month}&year=${year}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ------------------fetch schedule detail API------------------
  static async scheduleDetail(token, date) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/pt-calender-detail?date=${date}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ------------------fetch schedule detail for week API------------------
  static async scheduleDetailWeek(token, date, key, filter) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `dashboard/session-count?date=${date}&countKey=${key}&filter=${filter}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------Sent message list API-----*/

  static async fetchSentMessage(token, id, page, type, order, key) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `chat/messages/list/${id}?chatType=${type}&page=${page}&pageSize=${10}&sortingOrder=${order}&sortingKey=${key}`,
      undefined,
      "get",
      header
    );
    return res;
  }
}

export default PT_InformationSublyApi;
