import React, { useEffect, useState } from "react";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import moment from "moment";
import { strings } from "../../../../utils/String";
import dummyImg from "../../../../assests/images/demoProfile.svg";
import DeleteConfirmation from "../../../commonComponents/modalScreens/DeleteConfirmation";
import GymSettingSublyApi from "../../../../helper/GymSettingApi";
import { sendingTarget, writingTypes } from "./MessageSetting";
import { FormMedia } from "../../boardScreens/RegistrationForm";
import ErrorModal from "../../../commonComponents/modalScreens/ErrorModal";

const checkedMembers = [
  {
    img: { dummyImg },
  },
  {
    img: { dummyImg },
  },
  {
    img: { dummyImg },
  },
];

function DetailMessageScreen({
  setDeatlScreen,
  classNameValue,
  id,
  setIsLoader,
  setOpenRegister,
  setDefaultValue,
  setMessageId,
}) {
  const { token } = useSelector((state) => state.user);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [detailValue, setDetailValue] = useState("");
  const [videoPreview, setVideoPreview] = useState(false);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [modalType, setModalType] = useState("delete"); // 'delete' or 'resend'
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resendMessage, setResendMessage] = useState(false);

  const handleDeleteClick = () => {
    setModalType("delete");
    setIsModalOpen(true);
  };

  const handleResendClick = () => {
    setModalType("resend");
    setIsModalOpen(true);
  };

  const messageHandler = () => {
    if (modalType === "delete") {
      messageDeleteHandler(); // Call delete function
    } else if (modalType === "resend") {
      messageResendHandler(); // Call resend function
    }
  };

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  // ===========Fetch message detail API============
  useEffect(() => {
    messageDetailHandler();
  }, []);

  async function messageDetailHandler() {
    setIsLoader(true);
    await GymSettingSublyApi.fetchMessageDetail(token, id)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setDetailValue(response.data);
        } else {
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ===========API handler for deleting message==============
  async function messageDeleteHandler() {
    setIsLoader(true);
    await GymSettingSublyApi.deleteMessage(token, id)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setDeatlScreen(false);
          setMessageId("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ===========API handler for resend message==============
  async function messageResendHandler() {
    setIsLoader(true);
    await GymSettingSublyApi.resendMessage(token, id)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setDeatlScreen(false);
          setMessageId("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section
      className={` ${`absolute top-0 left-auto right-0 !w-full !h-full flex justify-center items-center bg-[#000000c2] z-20`}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-lg:w-[90%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[90%] max-lg:w-[100%] m-auto ">
          <div className="w-[80%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.MESSAGE_DETAIL}
            </p>
          </div>
          <div className="lg:flex gap-3 mt-4 lg: m-auto ">
            <div className="py-3.5 px-4  m-auto bg-[#474749] rounded-[10px] w-full">
              <p className="mb-0 text-[22px] font-extrabold">
                <span className="text-[22px] text-[#FFFFFFBF] font-bold mt-0">
                  {strings.WRITING_TYPE}
                </span>{" "}
                {writingTypes[detailValue?.writingType]}
              </p>
            </div>
            <div className="py-3.5 px-4  m-auto bg-[#474749] rounded-[10px] w-full">
              <p className="mb-0 text-[22px] font-extrabold">
                <span className="text-[22px] text-[#FFFFFFBF] font-bold">
                  {strings.TARGET}:
                </span>{" "}
                {sendingTarget[detailValue?.targetType]}
              </p>
            </div>
            <div className="py-3.5 px-4  m-auto bg-[#474749] rounded-[10px] w-full">
              <p
                className={`${
                  detailValue?.status ? "text-white" : "text-[#FF9191]"
                } ${"mb-0 text-[22px] font-extrabold"}`}
              >
                <span className="text-[22px] text-[#FFFFFFBF] font-bold">
                  {strings.WRITING_TYPE}
                </span>{" "}
                {detailValue?.status ? strings.SENT : strings.FAILED}
              </p>
            </div>
          </div>
          {detailValue?.targetMembers?.length > 0 && (
            <div className="flex items-center gap-3 mt-3 flex-wrap  m-auto border-b border-[#505050] pb-3">
              {detailValue?.targetMembers?.map((val, index) => (
                <div
                  style={{ border: "1px solid #A1A1AA4F" }}
                  key={index}
                  className="relative flex items-center gap-2 bg-[#414142] px-3 py-2 rounded-[6px] min-w-[180px]"
                >
                  <img
                    src={val?.mProfileImg?.prof_img1_min || dummyImg}
                    alt="profile"
                    className="w-[35px] h-[35px] rounded-full"
                  />
                  <p className="mb-0 text-lg font-bold mt-1">{val?.name}</p>
                </div>
              ))}
            </div>
          )}
          <div className="py-2 px-4 bg-[#474749] rounded-[10px] mt-3  m-auto">
            <p className="mb-0 text-[30px] font-bold">{detailValue?.title}</p>
          </div>

          <div className="flex items-center justify-between py-4 flex-wrap gap-3   m-auto">
            <div>
              <div className="flex items-center gap-2">
                <Icon
                  icon="uil:user"
                  width="30"
                  height="30"
                  style={{ color: "#DBDBDB" }}
                />
                <p className="mb-0 text-[24px] font-extrabold">
                  {detailValue?.staffDetails?.name}
                </p>

                {/* {generalDetail?.gymStaffInfo?.badge && (
                  <img
                    src={getBadgeImage(generalDetail?.gymStaffInfo?.badge)}
                    alt="badge"
                    className="w-[37px] h-[15px]"
                  />
                )} */}
              </div>{" "}
            </div>
            <div className="flex items-center gap-x-4 gap-y-3 flex-wrap">
              <div className="flex items-center gap-2 ">
                <Icon
                  icon="lets-icons:date-today"
                  width="30"
                  height="30"
                  style={{ color: "#C5C5C5" }}
                />
                <p className="mb-0 text-[22px] font-extrabold border-r border-[#444444] pr-8 max-sm:pr-3">
                  {moment(detailValue?.sentDate).format("YYYY.MM.DD")}
                </p>
              </div>
              <div className="flex items-center gap-2  pr-8">
                <Icon
                  icon="ion:time-outline"
                  width="30"
                  height="30"
                  style={{ color: "#C5C5C5" }}
                />
                <p className="mb-0 text-[22px] font-extrabold">
                  {moment(detailValue?.sentDate).format("h:mm")}{" "}
                  <span className="text-white text-lg font-bold">
                    {moment(detailValue?.sentDate).format("A")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <p className=" text-xl font-bold  m-auto">{detailValue?.content}</p>
          <div className="flex items-center gap-x-3 gap-y-2.5  mt-4 m-auto border-b border-[#505050] pb-4">
            {detailValue?.files?.map((item, index) => (
              <div
                key={index}
                className="w-[180px] h-[180px] rounded-[10px] bg-[#6F6F6F] relative"
              >
                <img
                  src={item?.url}
                  className="w-full h-full object-cover rounded-[10px]"
                />
                {item?.type == "video" && (
                  <span
                    onClick={() => {
                      setuploadedMedia(item);
                      setVideoPreview(true);
                    }}
                    className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                  >
                    <Icon
                      icon="ooui:play"
                      width="70"
                      height="70"
                      style={{ color: "white" }}
                    />
                  </span>
                )}
              </div>
            ))}
          </div>
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 mt-10 mb-4"}`}
          >
            <button
              onClick={() => {
                setDeleteMessage(true);
                handleDeleteClick();
              }}
              className={`${"max-sm:w-full text-white kakaoClass hover:text-black hover:bg-common_color "} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
            >
              {strings.DELETE}
            </button>
            <button
              onClick={() => {
                setDefaultValue(detailValue);
                setDeatlScreen(false);
                setOpenRegister(true);
              }}
              className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.EDIT}
            </button>
            {!detailValue?.status && (
              <button
                onClick={() => {
                  handleResendClick();
                  setResendMessage(true);
                }}
                className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
              >
                {strings.RESEND}
              </button>
            )}

            <button
              onClick={() => {
                setDeatlScreen(false);
                setMessageId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>

          <FormMedia
            setVideoPreview={setVideoPreview}
            videoPreview={videoPreview}
            uploadedMedia={uploadedMedia}
            classNameValue={classNameValue}
          />
          {/* <DeleteConfirmation
            textHead={strings.DELETE_MESSAGE}
            text={strings.ARE_YOU_SURE_DELETE_THIS_MESSAGE}
            deleteMemo={deleteMessage}
            setDeleteMemo={setDeleteMessage}
            clickBtn={messageDeleteHandler}
            btnText={strings.DELETE}
            classNameValue={classNameValue}
          /> */}
          {isModalOpen && (
            <DeleteConfirmation
              textHead={
                modalType === "delete"
                  ? strings.DELETE_MESSAGE
                  : strings.RESEND_MESSAGE
              }
              text={
                modalType === "delete"
                  ? strings.ARE_YOU_SURE_DELETE_THIS_MESSAGE
                  : strings.ARE_YOU_SURE_RESEND_THIS_MESSAGE
              }
              deleteMemo={
                modalType === "delete" ? deleteMessage : resendMessage
              }
              setDeleteMemo={
                modalType === "delete" ? setDeleteMessage : setResendMessage
              }
              clickBtn={messageHandler}
              btnText={modalType === "delete" ? strings.DELETE : strings.RESEND}
              classNameValue={classNameValue}
            />
          )}
          <ErrorModal
            openModal={openModal}
            closeModal={setOpenModal}
            errorMessage={errorMessage}
            classNameValue={classNameValue}
          />
        </div>
      </div>
    </section>
  );
}

export default DetailMessageScreen;
