import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { placeholder, strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import ReactDatePicker from "react-datepicker";
import { InputGroup } from "react-bootstrap";
import moment from "moment";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import WarningModal from "./WarningModal";
import RegisterHoliday from "./RegisterHoliday";
import BoardSublyApi from "../../../../../helper/BoardApi";
import { FormMedia } from "../../../boardScreens/RegistrationForm";
import {
  createDayName,
  formatCustomNumber,
  mergeTime,
  numberOnly,
  numberValidate,
  prepareWorkTime,
  tempPrepareWorkTime,
  trimPhone,
} from "../../../../../utils/commonUtils";
import Holidays from "./Holidays";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";
import $ from "jquery";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { HiCalendar } from "react-icons/hi2";
import { DatePicker } from "rsuite";
import { centerHandler } from "../../../../../Store/slices/AuthSlice";

function EditGymInformation({
  onClose,
  gymData,
  setIsLoader,
  classNameValue,
  // setupdateHoliday,
}) {
  const { token } = useSelector((state) => state.user);
  const { centerDetail } = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState(
    gymData?.gymIntroduction?.regDate
      ? new Date(gymData?.gymIntroduction?.regDate)
      : ""
  );
  const [ownerName, setOwnerName] = useState(gymData?.ownerProfile?.name);
  const [gymName, setGymName] = useState(gymData?.name);
  const [phone, setPhone] = useState(gymData?.gymTelephone);
  const [menShower, setMenShower] = useState(false);
  const [womenShower, setwomenShower] = useState(false);
  const [updateHoliday, setupdateHoliday] = useState(false);

  const [femaleShower, setFemaleShower] = useState({
    str: gymData?.gymIntroduction?.womenShowerFacil,
    val: "",
  });
  const [insta, setInsta] = useState(gymData?.gymIntroduction?.instaId);
  const [selectType, setSelectType] = useState(false);
  const [address, setAddress] = useState(gymData?.gymIntroduction?.address1);
  const [addShift, setAddShift] = useState(false);
  const [ptSession, setPtSession] = useState(
    gymData?.gymIntroduction?.ptSessions
  );
  const [exercise, setExercise] = useState(
    gymData?.gymIntroduction?.programs || ""
  );
  const [provides, setProvides] = useState(
    gymData?.gymIntroduction?.provides || ""
  );
  const [manageHoliday, setManageHoliday] = useState(
    gymData?.gymIntroduction?.holidays
  );
  const [workTime, setWorkTime] = useState(
    prepareWorkTime(gymData?.workingHourSlots)
  );

  const [maleShower, setMaleShower] = useState({
    str: gymData?.gymIntroduction?.menShowerFacil,
    val: "",
  });
  const [parkHours, setParkHours] = useState({
    str: gymData?.gymIntroduction?.supportParkingHour,
    val: "",
  });
  const [parkMin, setParkMin] = useState({
    str: gymData?.gymIntroduction?.supportParkingMin,
    val: "",
  });
  const [openHours, setOpenHours] = useState(false);
  const [openMin, setOpenMin] = useState(false);
  const [femaleTrainer, setFemaleTrainer] = useState(
    gymData?.gymIntroduction?.femaleStaffs
  );
  const [maleTrainer, setMaleTrainer] = useState(
    gymData?.gymIntroduction?.maleStaffs
  );
  const [showTrainer, setShowTrainer] = useState(false);
  const [showMaleTrainer, setShowMaleTrainer] = useState(false);
  const [imagePreview, setImagePreview] = useState(gymData?.gymBgImages);
  const [videoPreview, setVideoPreview] = useState(false);
  const [innerLoader, setInnerLoader] = useState(false);
  const [requestedData, setRequestedData] = useState(gymData?.gymBgImages);
  const [uploadedMedia, setuploadedMedia] = useState("");
  const [error, setError] = useState("");
  const [openMorningHours, setOpenMorningHours] = useState(false);
  const [openMorningMin, setOpenMorningMin] = useState(false);
  const [morningMin, setMorningMin] = useState({ str: "00", val: "" });
  const [morningHours, setMorningHours] = useState({ str: "00", val: "" });
  const [openEveningHours, setOpenEveningHours] = useState(false);
  const [openEveningMin, setOpenEveningMin] = useState(false);
  const [eveningMin, setEveningMin] = useState({ str: "00", val: "" });
  const [eveningHours, setEveningHours] = useState({ str: "00", val: "" });
  const initalDays = [
    { day: "Monday", isSelected: false },
    { day: "Tuesday", isSelected: false },
    { day: "Wednesday", isSelected: false },
    { day: "Thursday", isSelected: false },
    { day: "Friday", isSelected: false },
    { day: "Saturday", isSelected: false },
    { day: "Sunday", isSelected: false },
  ];
  const [days, setSelectedDays] = useState(initalDays);
  const [startMeridiem, setStartMeridiem] = useState(false);
  const [startMeridiemValue, setSatrtMeridiemValue] = useState("AM");
  const [endMeridiemValue, setEndMeridiemValue] = useState("PM");
  const [endMeridiem, setEndMeridiem] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [openCalender, setOpenCalender] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  const dispatch = useDispatch();

  const handleCheck = (index) => {
    if (!isWarning) {
      const newDays = [...days];
      const alreadyChecked = workTime?.find((data) =>
        data?.day?.includes(createDayName(days[index]?.day))
      );
      if (alreadyChecked) {
        return setIsWarning(true);
      }
      newDays?.forEach((data, idx) => {
        if (idx === index) {
          data.isSelected = !data?.isSelected;
        }
      });
      setSelectedDays(newDays);
    }
  };
  const [timeBlocks, setTimeBlocks] = useState([]);
  const [daysAndTimes, setDaysAndTimes] = useState(
    gymData?.workingHourSlots || []
  );
  const [isWarning, setIsWarning] = useState(false);
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment(gymData?.gymIntroduction?.regDate).format("YYYY.MM.DD")
  // );
  const handleSave = () => {
    const selectedDays = days.filter((day) => day.isSelected);

    const daysArray = selectedDays.reduce(
      (acc, curr) => {
        if (curr?.isSelected && !acc?.includes(curr?.day)) {
          acc?.push(curr?.day);
        }
        return acc;
      },

      []
    );

    const daysAndTimesData = {
      days: daysArray,
      timeBlocks: timeBlocks?.map((curr) => mergeTime(curr)),
    };
    const test = [...daysAndTimes];
    test.push(daysAndTimesData);
    setDaysAndTimes(test);
    setDaysAndTimes([...daysAndTimes, daysAndTimesData]);
    setWorkTime([...workTime, ...tempPrepareWorkTime([daysAndTimesData])]);
    setSelectedDays(initalDays);
    setTimeBlocks([]);
  };

  // Function to add a new time block
  const addTimeBlock = () => {
    if (morningHours.str && eveningHours.str == "00") {
    } else {
      setTimeBlocks([
        ...timeBlocks,
        {
          morningHour: morningHours.str,
          morningMin: morningMin.str,
          eveningHour: eveningHours.str,
          eveningMin: eveningMin.str,
          startMeridiemValue: startMeridiemValue,
          endMeridiemValue: endMeridiemValue,
        },
      ]);
    }
  };

  // Function to remove a time block
  const removeTimeBlock = (index) => {
    setTimeBlocks(timeBlocks.filter((_, i) => i !== index));
  };

  // Function to remove an item from the array
  const sliceHolidayBlocks = (index) => {
    const newHolidays = [
      ...manageHoliday.slice(0, index),
      ...manageHoliday.slice(index + 1),
    ];
    setManageHoliday(newHolidays);
  };
  const sliceTimeBlocks = (index) => {
    const newWorkTime = [
      ...workTime.slice(0, index),
      ...workTime.slice(index + 1),
    ];
    const newDaysAndTimes = [...daysAndTimes];
    newDaysAndTimes.splice(index, 1);
    setDaysAndTimes(newDaysAndTimes);
    setWorkTime(newWorkTime);
  };
  async function updateInfo() {
    if (!numberValidate(phone)) {
      setPhoneError(true);
      return;
    }

    setIsLoader(true);

    const data = {
      // businessRegistrationNumber: "123",

      gymIntroduction: {
        ...(address && { address1: address }),
        ...(insta && { instaId: insta }),
        supportParkingHour: Number(parkHours.str),
        supportParkingMin: Number(parkMin.str),
        ...(maleShower && { menShowerFacil: maleShower.str }),
        ...(femaleShower && { womenShowerFacil: femaleShower.str }),
        ...(manageHoliday && { holidays: manageHoliday }),
        ...(femaleTrainer && { femaleStaffs: femaleTrainer }),
        ...(maleTrainer && { maleStaffs: maleTrainer }),
        ...(provides && { provides }),
        ...(exercise && { programs: exercise }),
        ...(ptSession && { ptSessions: ptSession }),
        ...(startDate && { establishDate: startDate }),
      },
      gymTelephone: phone,
      gymBgImages: requestedData,
      name: gymName.trim(),
      workingHour: daysAndTimes?.map((data) => {
        return {
          days: data?.days,
          timeBlocks: data?.timeBlocks,
        };
      }),
    };
    await GymSettingSublyApi.editGymInfo(token, data)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          onClose(false);
          dispatch(
            centerHandler({
              ...centerDetail,
              image: requestedData,
              centerName: gymName,
            })
          );
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  }

  $("#phone").bind("keypress", numberOnly);

  const handleComplete = (data) => {
    let fullAddress = data.addressEnglish;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setZipCode(data?.zonecode);
    setAddress(fullAddress);
  };

  // =============Code for fetch address through zip code============

  const open = useDaumPostcodePopup(
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );

  const handleClick = () => {
    open({ onComplete: handleComplete, width: "400px", height: "400px" });
  };

  // ==============FUNCTION TO GET THUMNAIL FO VIDEO=================
  function getVideoThumbnail(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    setError("");
    if (file?.type.match("video")) {
      if (file?.size / 1000000 <= 200) {
        var image = "";
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = async function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              setInnerLoader(true);
              const requestData = new FormData();
              requestData.append("file", file);
              await BoardSublyApi.assestUpload(requestData, token)
                .then((response) => {
                  setInnerLoader(false);
                  if (response.statusCode == 200) {
                    let media = [...imagePreview];
                    const toDeploy = [...requestedData];
                    media.push({
                      url: response.data.compressed,
                      type: "video",
                      view: image,
                      original: response.data.original,
                    });
                    toDeploy.push({
                      url: response.data.compressed,
                      type: "video",
                      original: response.data.original,
                    });
                    setRequestedData(toDeploy);
                    setImagePreview(media);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              const base64String = image; // Base64 string
              const binaryString = atob(base64String.split(",")[1]); // Binary data string
              const blob = new Blob([binaryString], { type: "image/png" });
              // setPassThumbnail(blob);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setError("Video size should be less than 200 MB");
      }
    } else {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result) {
            setInnerLoader(true);
            const requestData = new FormData();
            requestData.append("file", file);
            await BoardSublyApi.assestUpload(requestData, token)
              .then((response) => {
                setInnerLoader(false);
                if (response.statusCode == 200) {
                  let media = [...imagePreview];
                  let toDeploy = [...requestedData];
                  media.push({
                    url: response.data.compressed,
                    type: "image",
                    view: result,
                    original: response.data.original,
                  });
                  toDeploy.push({
                    url: response.data.compressed,
                    type: "image",
                    original: response.data.original,
                  });
                  setRequestedData(toDeploy);
                  setImagePreview(media);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setOpenHours(false);
      setOpenMin(false);
      setMenShower(false);
      setShowMaleTrainer(false);
      setShowTrainer(false);
      setwomenShower(false);
      setStartMeridiem(false);
      setEndMeridiem(false);
      setOpenMorningHours(false);
      setOpenMorningMin(false);
      setOpenEveningHours(false);
      setOpenEveningMin(false);
    }
  });

  // ===============function for handling remove selected media=============
  async function removeProduct(url, index) {
    const images = [...imagePreview];
    let toDeploy = [...requestedData];
    images.splice(index, 1);
    setImagePreview(images);
    toDeploy.splice(index, 1);
    setRequestedData(toDeploy);
  }

  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonth).getMonth();
    return condition;
  };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[70%] max-lg:w-[90%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[80%] m-auto border-b border-[#505050]">
            {
              <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
                {strings.EDIT_GYM_INFORMATION}
              </p>
            }
          </div>
          <div className="pt-8 pb-3 w-[80%] m-auto">
            <div className="flex items-center gap-3 max-lg:flex-wrap my-2 ">
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.GYM_NAME}:
                </p>
                <label className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 w-full">
                  <input
                    type="text"
                    className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full text-lg font-semibold"
                    id="nameId"
                    autoComplete="off"
                    value={gymName}
                    onChange={(e) => {
                      setGymName(e.target.value);
                    }}
                    placeholder={placeholder.ENTER_NAME}
                  />
                  <Icon
                    icon="fluent:search-12-filled"
                    width="23"
                    height="23"
                    style={{ color: "#D9D9D9" }}
                  />
                </label>
              </div>
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.ADDRESS}:
                </p>
                <div className="flex items-center gap-2">
                  <label
                    className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 w-full relative"
                    htmlFor="zipCode"
                    onClick={handleClick}
                  >
                    <input
                      type="text"
                      className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full text-lg font-semibold caret-transparent"
                      id="nameId"
                      autoComplete="off"
                      value={address}
                      // onChange={(e) => {
                      //   setAddress(e.target.value);
                      // }}
                      placeholder={"Address"}
                    />
                    {/* <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                      rotate={selectType ? 2 : 4}
                    /> */}
                    {/* {selectType && <TypeDropDown setTpyeText={setTpyeText} />} */}
                  </label>
                  <div
                    className="bg-[#414142] px-2 h-[45px] rounded-[5px] flex items-center justify-center"
                    htmlFor="zipCode"
                    onClick={handleClick}
                  >
                    <Icon
                      icon="mdi:address-marker-outline"
                      width="24"
                      height="24"
                      style={{ color: "white" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3 max-lg:flex-wrap my-2">
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.FOUNDED}:
                </p>
                <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 newDateInputClass">
                  <DatePicker
                    oneTap
                    caretAs={HiCalendar}
                    id="datepicker-1"
                    placeholder={strings.START_DATE_SMALL}
                    format="yyyy.MM.dd"
                    block
                    editable={false}
                    appearance="subtle"
                    className="custom-datepicker bg-transparent border-0 w-full px-0"
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    onChangeCalendarDate={(e) => {
                      setCurrentMonth(e);
                    }}
                    onNextMonth={(e) => {
                      setCurrentMonth(e);
                    }}
                    onPrevMonth={(e) => {
                      setCurrentMonth(e);
                    }}
                    onOpen={() => {
                      setIsCalenderOpen(true);
                      setDatePickerCheck(1);
                      setCurrentMonth(
                        startDate ? new Date(startDate) : new Date()
                      );
                    }}
                    onClose={() => {
                      setIsCalenderOpen(false);
                      setCurrentMonth(
                        startDate ? new Date(startDate) : new Date()
                      );
                      setDatePickerCheck("");
                    }}
                    // disabledDate={(date) => {
                    //   const maxDate= new Date(Date.now()-(24*60*60*1000));
                    //   return date < maxDate;
                    // }}
                  />
                </div>
              </div>
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.CEO}:
                </p>
                <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative">
                  <input
                    type="text"
                    className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full text-lg font-semibold"
                    id="nameId"
                    autoComplete="off"
                    placeholder={"CEO"}
                    value={ownerName}
                    onChange={(e) => {
                      setOwnerName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3 max-lg:flex-wrap my-2">
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.PARKING_TIME}:
                </p>
                <div className="flex gap-2">
                  <div
                    className="w-full flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 dateInputClass relative dropdownClass"
                    onClick={() => {
                      setOpenHours(!openHours);
                      setOpenMin(false);
                      setMenShower(false);
                      setShowMaleTrainer(false);
                      setShowTrainer(false);
                      setwomenShower(false);
                      setStartMeridiem(false);
                      setEndMeridiem(false);
                      setOpenMorningHours(false);
                      setOpenMorningMin(false);
                      setOpenEveningHours(false);
                      setOpenEveningMin(false);
                    }}
                  >
                    <input
                      type="text"
                      className="bg-[#414142] rounded-[10px] cursor-pointer h-[45px] px-2 text-white w-full caret-transparent	text-lg font-semibold"
                      placeholder="Hours"
                      value={parkHours.str}
                      // onChange={(e) => {
                      //   setParkHours(e.target.value);
                      // }}
                    />
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                      rotate={openHours ? 2 : 4}
                    />
                    {openHours && <TimeDropDown setParkHours={setParkHours} />}
                  </div>
                  <div
                    className="w-full flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 dateInputClass relative dropdownClass"
                    onClick={() => {
                      setOpenMin(!openMin);
                      setOpenHours(false);
                      setMenShower(false);
                      setShowMaleTrainer(false);
                      setShowTrainer(false);
                      setwomenShower(false);
                      setStartMeridiem(false);
                      setEndMeridiem(false);
                      setOpenMorningHours(false);
                      setOpenMorningMin(false);
                      setOpenEveningHours(false);
                      setOpenEveningMin(false);
                    }}
                  >
                    <input
                      type="text"
                      className="bg-[#414142] rounded-[10px] cursor-pointer h-[45px] px-2 text-white w-full caret-transparent	text-lg font-semibold"
                      placeholder="Minutes"
                      value={parkMin.str}
                      // onChange={(e)=>{
                      //   setParkMin(e.target.value)
                      // }}
                    />
                    <Icon
                      icon="flowbite:caret-down-solid"
                      width="20"
                      height="20"
                      style={{ color: "#BEBEBE" }}
                      rotate={openMin ? 2 : 4}
                    />
                    {openMin && <MinDropDown setParkMin={setParkMin} />}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                  {strings.TELEPHONE}:
                </p>
                <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative ">
                  <input
                    type="text"
                    className={`bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full	text-lg font-semibold numberField ${
                      phoneError ? " border-[1px] border-red-400" : ""
                    }`}
                    id="phone"
                    autoComplete="off"
                    placeholder={"Telephone"}
                    value={formatCustomNumber(phone)}
                    onChange={(e) => {
                      const trimmedValue = trimPhone(e.target.value);
                      // const trimmedValue = e.target.value.trim();
                      if (trimmedValue.length <= 11) setPhone(trimmedValue);
                      // setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                Working time
              </p>
              <div className="flex w-full gap-2 items-center flex-wrap">
                {workTime?.map((val, index) => {
                  return (
                    <div
                      className="bg-[#414142] rounded-[10px] cursor-pointer  px-2 py-2 text-white w-max flex  gap-10 items-center justify-between"
                      key={index}
                    >
                      {/* <p className="!mb-0 text-lg font-bold">
                        {val}:{" "}
                        {`${moment(gymData?.workingHour[val], "HH:mm").format(
                          "h:mm A"
                        )} - ${moment(
                          gymData?.workingHour[val],
                          "HH:mm"
                        ).format("h:mm A")}`}
                        {`${
                          index + 1 !== Object.keys(gymData?.workingHour).length
                            ? ""
                            : ""
                        }`}
                      </p> */}
                      <p className="!mb-0 text-lg font-bold">
                        {val?.day}:{" "}
                        {/* {`${moment(gymData?.workingHour[val], "HH:mm").format(
                          "h:mm A"
                        )} - ${moment( */}
                        {val?.times?.toString().replace(",", ", ")}
                        {/* "HH:mm"
                        ).format("h:mm A")}`}
                        {`${
                          index + 1 !== Object.keys(gymData?.workingHour).length
                            ? ""
                            : ""
                        }`} */}
                      </p>
                      <Icon
                        icon="maki:cross"
                        width="16"
                        height="16"
                        style={{ color: "white" }}
                        onClick={() => {
                          sliceTimeBlocks(index);
                        }}
                      />
                    </div>
                  );
                })}
                {/* {[1, 2, 3].map(() => {
                  return (
                    <div className="bg-[#414142] rounded-[10px] cursor-pointer  px-2 py-2 text-white w-max flex  gap-10 items-center justify-between">
                      <p className="!mb-0 text-lg font-bold">
                        Mon-Wed-Fri: 9AM-6PM
                      </p>
                      <Icon
                        icon="maki:cross"
                        width="16"
                        height="16"
                        style={{ color: "white" }}
                      />
                    </div>
                  );
                })} */}
              </div>
            </div>
            <div
              className={`flex gap-3 border-[#CCFF00] hover:bg-common_color hover:text-black text-white py-2 px-3 my-3 border-[1px] rounded-[10px] w-max cursor-pointer items-center ${
                addShift ? "bg-[#CCFF00] " : ""
              }`}
              onClick={() => {
                setAddShift(!addShift);
              }}
            >
              <div
                className={`${
                  addShift ? "bg-black " : "bg-[#6B6B6B]"
                } h-[23px] w-[23px] rounded-[3px] flex items-center justify-center`}
              >
                <Icon
                  icon="mingcute:add-fill"
                  width="16"
                  height="16"
                  style={{ color: "white" }}
                />
              </div>
              <span
                className={`!mb-0 font-bold text-lg ${
                  addShift ? "text-black " : ""
                }`}
              >
                {strings.ADD_ANOTHER_SHIFT}
              </span>
            </div>
            {addShift && (
              <div className="relative">
                <div className="border-l-[1px] pl-5">
                  <p className="!mb-0 text-[#fff]">{strings.WORKING_DAYS}:</p>
                  <div
                    div
                    className="flex flex-wrap items-center  bg-[#414142] border-[#fff4D] px-4 rounded-[10px] gap-3  "
                  >
                    <p className="!mb-0 text-lg font-bold">
                      {strings.SELECT_WORKING_DAYS}:
                    </p>
                    <div className="flex items-center gap-x-3 flex-wrap">
                      {days?.map((item, index) => {
                        return (
                          <div className="flex items-center ">
                            <InputGroup className="checkboxClass paddingManage">
                              <InputGroup.Checkbox
                                className="w-[31px] h-[31px] bg-[#CECECE80]"
                                checked={item?.isSelected}
                                onChange={() => {
                                  handleCheck(index);
                                }}
                              />
                            </InputGroup>
                            <p className="!mb-0 font-bold">
                              {item?.day?.split("").slice(0, 3).join("")}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="bg-[#414142] px-4 py-2 rounded-[10px] my-3">
                    <p className="text-lg font-bold !mb-0">
                      {strings.ADD_WORKING_TIME}
                    </p>
                    {timeBlocks.map((timeBlock, index) => {
                      return (
                        <div
                          className="flex flex-wrap items-center gap-x-10 my-3 "
                          key={index}
                        >
                          <div className="flex flex-wrap items-center gap-3">
                            <p className="!mb-0 text-[#fff]">{strings.FROM}:</p>
                            <div className="flex items-center h-[41px] bg-[#353638] border-[#fff4F] border-[1px] rounded-[10px] px-2 gap-4">
                              <p className="!mb-0 text-lg font-extrabold">
                                {/* {timeBlock.from.split(" ")[0].split(":")[0]} */}
                                {timeBlock.morningHour}
                              </p>
                              <p className="!mb-0 text-lg font-extrabold">:</p>
                              <p className="!mb-0 text-lg font-extrabold">
                                {/* {timeBlock.from.split(" ")[0].split(":")[1]} */}
                                {timeBlock.morningMin}
                              </p>
                            </div>
                            <p className="!mb-0 font-bold">
                              {/* {timeBlock.from.split(" ")[1]} */}
                              AM
                            </p>
                          </div>
                          <div className="flex flex-wrap items-center gap-4 lg:gap-2 lg:my-0 my-2">
                            <p className="!mb-0 text-[#fff]">{strings.TO}:</p>
                            <div className="flex items-center h-[41px] bg-[#353638] border-[#fff4F] border-[1px] rounded-[10px] px-2 gap-4">
                              <p className="!mb-0 text-lg font-extrabold">
                                {/* {timeBlock.to.split(" ")[0].split(":")[0]} */}
                                {timeBlock.eveningHour}
                              </p>
                              <p className="!mb-0 text-lg font-extrabold">:</p>
                              <p className="!mb-0 text-lg font-extrabold">
                                {/* {timeBlock.to.split(" ")[0].split(":")[1]} */}
                                {timeBlock.eveningMin}
                              </p>
                            </div>
                            <p className="!mb-0 font-bold">
                              {/* {timeBlock.to.split(" ")[1]} */}
                              PM
                            </p>
                            <Icon
                              icon="mingcute:delete-2-line"
                              width="24"
                              height="24"
                              style={{ color: "#6B6B6B" }}
                              className="ml-3 cursor-pointer"
                              onClick={() => removeTimeBlock(index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <div className="flex flex-wrap items-center gap-x-10  ">
                      <div className="flex flex-wrap items-center gap-3">
                        <p className="!mb-0 text-[#fff]">{strings.FROM}:</p>
                        <div className="flex items-center h-[41px] bg-[#353638] border-[#fff4F] border-[1px] rounded-[10px] px-2 gap-4 relative dropdownClass ">
                          <p
                            className="!mb-0 text-lg font-extrabold"
                            onClick={() => {
                              setOpenMorningHours(!openMorningHours);
                              setOpenMorningMin(false);
                              setOpenEveningHours(false);
                              setOpenEveningMin(false);
                              setStartMeridiem(false);
                              Meridiem(false);
                              setEndMeridiem(false);
                              setOpenHours(false);
                              setOpenMin(false);
                              setMenShower(false);
                              setShowMaleTrainer(false);
                              setShowTrainer(false);
                              setwomenShower(false);
                            }}
                          >
                            {morningHours.str}
                          </p>
                          {openMorningHours && (
                            <WorkingTimeDropDown
                              setMorningHours={setMorningHours}
                              onClose={setOpenMorningHours}
                              openMinDrop={setOpenMorningMin}
                            />
                          )}
                          <p className="!mb-0 text-lg font-extrabold">:</p>
                          <p
                            className="!mb-0 text-lg font-extrabold mt-0"
                            onClick={() => {
                              setOpenMorningMin(!openMorningMin);
                              setOpenMorningHours(false);
                              setOpenEveningHours(false);
                              setOpenEveningMin(false);
                              Meridiem(false);
                              setStartMeridiem(false);
                              setEndMeridiem(false);
                              setOpenHours(false);
                              setOpenMin(false);
                              setMenShower(false);
                              setShowMaleTrainer(false);
                              setShowTrainer(false);
                              setwomenShower(false);
                            }}
                          >
                            {morningMin.str}
                          </p>

                          {openMorningMin && (
                            <MinDropDown
                              setParkMin={setMorningMin}
                              isWorking={true}
                              onClose={setOpenMorningMin}
                              Meridiem={setStartMeridiem}
                            />
                          )}
                        </div>
                        <div className="relative dropdownClass">
                          <p
                            className="!mb-0 font-bold mt-0"
                            onClick={() => {
                              setStartMeridiem(!startMeridiem);
                              setOpenMorningHours(false);
                              setOpenMorningMin(false);
                              setOpenEveningHours(false);
                              setOpenEveningMin(false);
                              setEndMeridiem(false);
                              setOpenHours(false);
                              setOpenMin(false);
                              setMenShower(false);
                              setShowMaleTrainer(false);
                              setShowTrainer(false);
                              setwomenShower(false);
                            }}
                          >
                            {startMeridiemValue}
                          </p>
                          {startMeridiem && (
                            <Meridiem
                              MeridiemValue={setSatrtMeridiemValue}
                              Meridiem={setStartMeridiem}
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex items-center gap-4 lg:gap-2 lg:my-0 my-2">
                        <p className="!mb-0 text-[#fff]">{strings.TO}:</p>
                        <div className="flex items-center h-[41px] bg-[#353638] border-[#fff4F] border-[1px] rounded-[10px] px-2 gap-4 relative dropdownClass">
                          <p
                            className="!mb-0 text-lg font-extrabold"
                            onClick={() => {
                              setOpenEveningHours(!openEveningHours);
                              setOpenEveningMin(false);
                              setOpenMorningHours(false);
                              setOpenMorningMin(false);
                              Meridiem(false);
                              setStartMeridiem(false);
                              setEndMeridiem(false);
                              setOpenHours(false);
                              setOpenMin(false);
                              setMenShower(false);
                              setShowMaleTrainer(false);
                              setShowTrainer(false);
                              setwomenShower(false);
                            }}
                          >
                            {eveningHours.str}
                          </p>
                          {openEveningHours && (
                            <WorkingTimeDropDown
                              setMorningHours={setEveningHours}
                              onClose={setOpenEveningHours}
                              openMinDrop={setOpenEveningMin}
                            />
                          )}
                          <p className="!mb-0 text-lg font-extrabold">:</p>
                          <p
                            className="!mb-0 text-lg font-extrabold mt-0"
                            onClick={() => {
                              setOpenEveningHours(false);
                              setOpenEveningMin(!openEveningMin);
                              setOpenMorningHours(false);
                              setEndMeridiem(false);
                              setStartMeridiem(false);
                              setOpenHours(false);
                              setOpenMin(false);
                              setMenShower(false);
                              setShowMaleTrainer(false);
                              setShowTrainer(false);
                              setwomenShower(false);
                            }}
                          >
                            {eveningMin.str}
                          </p>

                          {openEveningMin && (
                            <MinDropDown
                              setParkMin={setEveningMin}
                              isWorking={true}
                              onClose={setOpenEveningMin}
                              Meridiem={setEndMeridiem}
                            />
                          )}
                        </div>
                        <div className="relative dropdownClass">
                          <p
                            className="!mb-0 font-bold "
                            onClick={() => {
                              setEndMeridiem(!endMeridiem);
                              setOpenEveningHours(false);
                              setOpenEveningMin(false);
                              setOpenMorningHours(false);
                              setOpenMorningMin(false);
                              setStartMeridiem(false);
                              setOpenHours(false);
                              setOpenMin(false);
                              setMenShower(false);
                              setShowMaleTrainer(false);
                              setShowTrainer(false);
                              setwomenShower(false);
                            }}
                          >
                            {endMeridiemValue}
                          </p>
                          {endMeridiem && (
                            <Meridiem
                              MeridiemValue={setEndMeridiemValue}
                              Meridiem={setEndMeridiem}
                            />
                          )}
                        </div>
                        <div
                          className="w-[22px] h-[22px] rounded-[3px] bg-[#6B6B6B] border-[1px] border-[#FFFFFF1F] flex items-center justify-center ml-3 cursor-pointer"
                          onClick={() => {
                            addTimeBlock();
                            setMorningHours({ str: "00" });
                            setMorningMin({ str: "00" });
                            setEveningHours({ str: "00" });
                            setEveningMin({ str: "00" });
                          }}
                          disabled={
                            morningHours.str && eveningHours.str == "00"
                          }
                        >
                          <Icon
                            icon="mi:add"
                            width="24"
                            height="24"
                            style={{ color: "white" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        disabled={timeBlocks.length == 0 ? true : false}
                        className="border-[#CCFF00] border-[1px] rounded-[30px] px-5 py-2 my-3 text-lg font-bold text-white disabled:bg-[#ccff0094] disabled:text-white hover:text-black hover:bg-common_color"
                        onClick={() => {
                          handleSave();
                        }}
                      >
                        {strings.SAVE}
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {isWarning && (
                    <WarningModal
                      setIsWarning={setIsWarning}
                      headText={strings.UNABLE_SELECT_SAME_DAY}
                      text={strings.YOU_HAVE_ALREADY_SCHEDULE_SAME_DAY}
                      classNameValue={classNameValue}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="w-full">
              <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                {strings.HOLIDAY_DETAILS}
              </p>
              <div className="flex w-full gap-x-3 gap-y-0 flex-wrap">
                {manageHoliday?.map((item, index) => {
                  return (
                    <div className="bg-[#FFAFAF4D] relative  rounded-[10px] cursor-pointer py-2 px-2 text-white w-full  lg:w-max flex gap-8  items-center border-[1px] border-[#FFAFAF4D] my-2 ">
                      <div className="flex items-center gap-2">
                        {" "}
                        <p className="!mb-0 text-lg font-bold">
                          {item?.name} -{" "}
                        </p>
                        <Icon
                          icon="lets-icons:date-today"
                          width="20"
                          height="20"
                          style={{ color: "#E99696" }}
                          classID="mx-5 "
                        />{" "}
                        <p className="!mb-0 text-lg font-bold">
                          {moment.utc(item?.date).format("DD-MM")}
                        </p>
                        {item.endDate && (
                          <>
                            <p className="!mb-0 !mt-0 text-lg font-bold ">to</p>
                            <p className="!mb-0 !mt-0 text-lg font-bold">
                              {moment.utc(item?.endDate).format("DD-MM")}
                            </p>
                          </>
                        )}
                      </div>
                      <Icon
                        icon="maki:cross"
                        width="16"
                        height="16"
                        style={{ color: "white" }}
                        className="lg:right-1.5 right-1.5 lg:relative absolute "
                        onClick={() => {
                          sliceHolidayBlocks(index);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                className="flex gap-3 border-[#CCFF00] hover:bg-common_color !text-white hover:!text-black py-2 px-3 my-3 border-[1px] rounded-[10px] w-max cursor-pointer items-center"
                onClick={() => {
                  // setRegisterHoliday(true);
                  // setupdateHoliday(true);
                  setupdateHoliday(true);
                  // onClose(false);
                }}
              >
                <div className="bg-[#6B6B6B] h-[23px] w-[23px] rounded-[3px] flex items-center justify-center">
                  <Icon
                    icon="mingcute:add-fill"
                    width="16"
                    height="16"
                    style={{ color: "white" }}
                  />
                </div>
                <span className="!mb-0 font-bold text-lg">
                  {strings.ADD_HOLIDAY}
                </span>
              </div>
              <div className="flex items-center gap-3 max-lg:flex-wrap my-2">
                <div className="w-full">
                  <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                    {placeholder.PT_SESSIONS}:
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative">
                    <input
                      type="text"
                      className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full	text-lg font-semibold"
                      id="nameId"
                      autoComplete="off"
                      placeholder={placeholder.PT_SESSIONS}
                      value={ptSession}
                      onChange={(e) => {
                        setPtSession(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                    {strings.EXERCISE}:
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative">
                    <input
                      type="text"
                      className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full 	text-lg font-semibold"
                      id="nameId"
                      autoComplete="off"
                      placeholder="Exercises"
                      value={exercise}
                      onChange={(e) => {
                        setExercise(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3 max-lg:flex-wrap my-2">
                <div className="w-full">
                  <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                    {strings.SHOWERS}
                  </p>
                  <div
                    className="flex max-lg:flex-wrap items-center gap-2 relative dropdownClass"
                    onClick={() => {
                      setMenShower(!menShower);
                      setOpenHours(false);
                      setOpenMin(false);
                      setShowMaleTrainer(false);
                      setShowTrainer(false);
                      setwomenShower(false);
                      setStartMeridiem(false);
                      setEndMeridiem(false);
                      setOpenEveningHours(false);
                      setOpenEveningMin(false);
                      setOpenMorningHours(false);
                      setOpenMorningMin(false);
                    }}
                  >
                    <p className="!mb-0">{strings.MEN}:</p>
                    <div className="w-full flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 dateInputClass">
                      <input
                        type="text"
                        className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full caret-transparent	text-lg font-semibold cursor-pointer"
                        placeholder="Men's Shower"
                        value={maleShower.str}
                        // onChange={(e) => {
                        //   setMaleShower(e.target.value);
                        // }}
                      />
                      <Icon
                        icon="flowbite:caret-down-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE" }}
                        rotate={menShower ? 2 : 4}
                      />
                    </div>
                    {menShower && <MenShower setMaleShower={setMaleShower} />}
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className="flex max-lg:flex-wrap items-center gap-2 lg:mt-4 relative dropdownClass"
                    onClick={() => {
                      setwomenShower(!womenShower);
                      setOpenMin(false);
                      setMenShower(false);
                      setOpenHours(false);
                      setShowMaleTrainer(false);
                      setShowTrainer(false);
                      setStartMeridiem(false);
                      setEndMeridiem(false);
                      setOpenEveningHours(false);
                      setOpenEveningMin(false);
                      setOpenMorningHours(false);
                      setOpenMorningMin(false);
                    }}
                  >
                    <p className="!mb-0 ">{strings.WOMEN}:</p>
                    <div className="w-full flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 dateInputClass ">
                      <input
                        type="text"
                        className="bg-[#414142] rounded-[10px] cursor-pointer h-[45px] px-2 text-white w-full caret-transparent	text-lg font-semibold"
                        placeholder="Women's Shower"
                        value={femaleShower.str}
                        // onChange={(e) => {
                        //   setwomenShower(e.target.value);
                        // }}
                      />
                      <Icon
                        icon="flowbite:caret-down-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE" }}
                        rotate={womenShower ? 2 : 4}
                      />
                    </div>
                    {womenShower && (
                      <WomenShower setFemaleShower={setFemaleShower} />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3 max-lg:flex-wrap my-2">
                <div className="w-full">
                  <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                    {strings.PROVIDES}:
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative">
                    <input
                      type="text"
                      className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full text-lg font-semibold"
                      id="nameId"
                      autoComplete="off"
                      placeholder={strings.PROVIDES}
                      value={provides}
                      onChange={(e) => {
                        setProvides(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                    {strings.INSTAGRAM}:
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 relative">
                    <input
                      type="text"
                      className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full	text-lg font-semibold"
                      id="nameId"
                      autoComplete="off"
                      placeholder="Instagram"
                      value={insta}
                      onChange={(e) => {
                        setInsta(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3 max-lg:flex-wrap my-2">
                <div className="w-full">
                  <p className="text-base font-normal text-[#fff] ml-2 mb-0">
                    {strings.PT_TRAINER}:
                  </p>
                  <div
                    onClick={() => {
                      setShowMaleTrainer(!showMaleTrainer);
                      setShowTrainer(false);
                      setOpenHours(false);
                      setMenShower(false);
                      setOpenMin(false);
                      setwomenShower(false);
                      setStartMeridiem(false);
                      setEndMeridiem(false);
                      setOpenEveningHours(false);
                      setOpenEveningMin(false);
                      setOpenMorningHours(false);
                      setOpenMorningMin(false);
                    }}
                    className="flex max-lg:flex-wrap items-center gap-2 relative dropdownClass"
                  >
                    <p className="!mb-0">{strings.MEN}:</p>
                    <div className="w-full flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 dateInputClass cursor-pointer">
                      <input
                        type="text"
                        className="bg-[#414142] rounded-[10px] h-[45px] px-2 text-white w-full caret-transparent	text-lg font-semibold cursor-pointer"
                        placeholder="Male Trainer"
                        value={maleTrainer}
                        readOnly
                      />
                      <Icon
                        icon="flowbite:caret-down-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE" }}
                        rotate={showMaleTrainer ? 2 : 4}
                      />
                    </div>
                    {showMaleTrainer && (
                      <MenTrainer setMaleTrainer={setMaleTrainer} />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div
                    onClick={() => {
                      setShowTrainer(!showTrainer);
                      setShowMaleTrainer(false);
                      setOpenHours(false);
                      setwomenShower(false);
                      setMenShower(false);
                      setOpenMin(false);
                      setStartMeridiem(false);
                      setEndMeridiem(false);
                      setOpenEveningHours(false);
                      setOpenEveningMin(false);
                      setOpenMorningHours(false);
                      setOpenMorningMin(false);
                    }}
                    className="flex max-lg:flex-wrap items-center gap-2 lg:mt-4 relative dropdownClass"
                  >
                    <p className="!mb-0 ">{strings.WOMEN}:</p>
                    <div className="w-full flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-2 dateInputClass ">
                      <input
                        type="text"
                        className="bg-[#414142] rounded-[10px] cursor-pointer h-[45px] px-2 text-white w-full caret-transparent	text-lg font-semibold"
                        placeholder="Female Trainer"
                        value={femaleTrainer}
                      />
                      <Icon
                        icon="flowbite:caret-down-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE" }}
                        rotate={showTrainer ? 2 : 4}
                      />
                    </div>
                    {/* <WomenShower setFemaleShower={setFemaleShower} /> */}
                    {showTrainer && (
                      <WomenTrainer setFemaleTrainer={setFemaleTrainer} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                imagePreview &&
                imagePreview.length > 0 &&
                imagePreview.length < 4
                  ? "justify-start"
                  : innerLoader
                  ? "justify-start"
                  : "justify-center"
              } flex items-center`}
            >
              <div
                className={`${
                  !imagePreview || imagePreview.length == 0
                    ? innerLoader
                      ? "justify-start"
                      : "justify-center"
                    : "justify-start"
                } ${"flex items-center my-6 gap-2.5 flex-wrap"}`}
              >
                <input
                  type="file"
                  accept="image/*, video/*"
                  className="hidden"
                  id="uploadMedia"
                  onChange={(e) => getVideoThumbnail(e)}
                />
                {(!imagePreview || imagePreview.length == 0) && (
                  <label
                    htmlFor="uploadMedia"
                    style={{ border: "2px solid #4B4B4B" }}
                    className={`${
                      innerLoader && "hidden"
                    } ${"flex items-center gap-3 rounded-[10px] py-2.5 px-6 cursor-pointer"}`}
                  >
                    <Icon
                      icon="humbleicons:upload"
                      width="48"
                      height="48"
                      style={{ color: "white" }}
                    />
                    <p className="mb-0 text-[24px] font-bold">
                      {strings.UPLOAD_MEDIA_FILES}
                    </p>
                  </label>
                )}
                {imagePreview &&
                  imagePreview?.map((item, index) => (
                    <div
                      className="w-[160px] h-[160px] rounded-[10px] bg-[#6F6F6F] relative"
                      key={index}
                    >
                      <img
                        src={item.url || item.view}
                        className="w-full h-full object-cover rounded-[10px]"
                      />
                      <span
                        onClick={() => {
                          removeProduct(item.url, index);
                        }}
                        className="absolute top-2 w-[30px] h-[30px] right-2 flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                      >
                        <Icon
                          icon="iconamoon:close-bold"
                          width="25"
                          height="25"
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      </span>

                      {item?.type == "video" && (
                        <span
                          onClick={() => {
                            setVideoPreview(true);
                            setuploadedMedia(item);
                          }}
                          className="absolute top-[44%] right-[38%] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] bg-[#6F6F6F] p-1"
                        >
                          <Icon
                            icon="ooui:play"
                            width="70"
                            height="70"
                            style={{ color: "white" }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                {((imagePreview?.length > 0 && imagePreview?.length !== 5) ||
                  innerLoader) && (
                  <label
                    htmlFor="uploadMedia"
                    className="w-[160px] h-[160px] rounded-[10px] bg-[#6F6F6F] flex items-center justify-center relative cursor-pointer"
                  >
                    {!innerLoader ? (
                      <Icon
                        icon="bitcoin-icons:plus-filled"
                        width="100"
                        height="100"
                        style={{ color: "white" }}
                      />
                    ) : (
                      <span className="absolute w-full h-full flex items-center justify-center">
                        <Icon
                          icon="eos-icons:bubble-loading"
                          width="60"
                          height="60"
                          style={{ color: "#CCFF00" }}
                          className="loader-icon "
                        />
                      </span>
                    )}
                  </label>
                )}
              </div>
            </div>
            <div
              className={` ${"flex-wrap flex items-center justify-center gap-3 mt-6"}`}
            >
              <button
                onClick={() => {
                  onClose(false);
                }}
                className={`${"max-sm:w-full text-white kakaoClass"} ${"text-2xl font-extrabold h-[60px] w-[160px] rounded-[28px]"}`}
              >
                {strings.CLOSE}
              </button>
              <button
                disabled={!innerLoader ? false : true}
                className="max-sm:w-full text-2xl font-extrabold rounded-[30px] text-[#1C1C1E] bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
                onClick={() => {
                  updateInfo();
                }}
              >
                {strings.UPDATE}
              </button>
              ;
            </div>
          </div>
        </div>
      </div>
      <FormMedia
        setVideoPreview={setVideoPreview}
        videoPreview={videoPreview}
        uploadedMedia={uploadedMedia}
        classNameValue={classNameValue}
      />
      {updateHoliday && (
        <RegisterHoliday
          setIsLoader={setIsLoader}
          edit={true}
          classNameValue={classNameValue}
          onClose={() => {
            setupdateHoliday(false);
            onClose(false);
          }}
          onSuccess={(obj) => {}}
        />
      )}
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default EditGymInformation;

const mensShowerData = [
  {
    qnty: 0,
    val: 0,
  },
  {
    qnty: 1,
    val: 1,
  },
  {
    qnty: 2,
    val: 2,
  },
  {
    qnty: 3,
    val: 3,
  },
  {
    qnty: 4,
    val: 4,
  },
  {
    qnty: 5,
    val: 5,
  },
  {
    qnty: 6,
    val: 6,
  },
  {
    qnty: 7,
    val: 7,
  },
  {
    qnty: 8,
    val: 8,
  },
];

function MenShower({ setMaleShower }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[60px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-4">
      {mensShowerData.map((items) => {
        return (
          <div
            onClick={() => {
              setMaleShower({ str: items.qnty, val: items.val });
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{items.qnty}</span>
          </div>
        );
      })}
    </div>
  );
}
function WomenShower({ setFemaleShower }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[60px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-4">
      {mensShowerData.map((items) => {
        return (
          <div
            onClick={() => {
              setFemaleShower({ str: items.qnty, val: items.val });
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{items.qnty}</span>
          </div>
        );
      })}
    </div>
  );
}
function WomenTrainer({ setFemaleTrainer }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[60px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-4">
      {mensShowerData.map((items) => {
        return (
          <div
            onClick={() => {
              setFemaleTrainer(items.qnty);
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{items.qnty}</span>
          </div>
        );
      })}
    </div>
  );
}
function MenTrainer({ setMaleTrainer }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[60px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-4">
      {mensShowerData.map((items) => {
        return (
          <div
            onClick={() => {
              setMaleTrainer(items.qnty);
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{items.qnty}</span>
          </div>
        );
      })}
    </div>
  );
}

export function TimeDropDown({ setParkHours }) {
  const timeData = [
    { hours: "01", val: 1 },
    { hours: "02", val: 2 },
    { hours: "03", val: 3 },
    { hours: "04", val: 4 },
    { hours: "05", val: 5 },
    { hours: "06", val: 6 },
    { hours: "07", val: 7 },
    { hours: "08", val: 8 },
    { hours: "09", val: 9 },
    { hours: "10", val: 10 },
    { hours: "11", val: 11 },
    { hours: "12", val: 12 },
    { hours: "13", val: 13 },
    { hours: "14", val: 14 },
    { hours: "15", val: 15 },
    { hours: "16", val: 16 },
    { hours: "17", val: 17 },
    { hours: "18", val: 18 },
    { hours: "19", val: 29 },
    { hours: "20", val: 20 },
    { hours: "21", val: 21 },
    { hours: "22", val: 22 },
    { hours: "23", val: 23 },
    { hours: "24", val: 24 },
  ];
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[100px] right-0 top-10 max-h-[250px] overflow-auto z-20 px-4">
      <p
        className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
        onClick={() => {
          setParkHours({ str: "00", val: null });
        }}
      >
        --{strings.NOT_SELECTED}--
      </p>
      {timeData.map((item) => {
        return (
          <div
            onClick={() => {
              setParkHours({ str: item.hours, val: item.val });
            }}
            className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0 text-lg font-bold">{item.hours} hr</span>
          </div>
        );
      })}
    </div>
  );
}
export function MinDropDown({ setParkMin, isWorking, onClose, Meridiem }) {
  const timeData = [
    // { min: "0", val: 0 },
    { min: "1", val: 1 },
    { min: "2", val: 2 },
    { min: "3", val: 3 },
    { min: "4", val: 4 },
    { min: "5", val: 5 },
    { min: "6", val: 6 },
    { min: "7", val: 7 },
    { min: "8", val: 8 },
    { min: "9", val: 9 },
    { min: "10", val: 10 },
    { min: "11", val: 11 },
    { min: "12", val: 12 },
    { min: "13", val: 13 },
    { min: "14", val: 14 },
    { min: "15", val: 15 },
    { min: "16", val: 16 },
    { min: "17", val: 17 },
    { min: "18", val: 18 },
    { min: "19", val: 29 },
    { min: "20", val: 20 },
    { min: "21", val: 21 },
    { min: "22", val: 22 },
    { min: "23", val: 23 },
    { min: "24", val: 24 },
    { min: "25", val: 25 },
    { min: "26", val: 26 },
    { min: "27", val: 27 },
    { min: "28", val: 28 },
    { min: "29", val: 29 },
    { min: "30", val: 30 },
    { min: "31", val: 31 },
    { min: "32", val: 32 },
    { min: "33", val: 33 },
    { min: "34", val: 34 },
    { min: "35", val: 35 },
    { min: "36", val: 36 },
    { min: "37", val: 37 },
    { min: "38", val: 38 },
    { min: "39", val: 39 },
    { min: "40", val: 40 },
    { min: "41", val: 41 },
    { min: "42", val: 42 },
    { min: "43", val: 43 },
    { min: "44", val: 44 },
    { min: "45", val: 45 },
    { min: "46", val: 46 },
    { min: "47", val: 47 },
    { min: "48", val: 48 },
    { min: "49", val: 49 },
    { min: "50", val: 50 },
    { min: "51", val: 51 },
    { min: "52", val: 52 },
    { min: "53", val: 53 },
    { min: "54", val: 54 },
    { min: "55", val: 55 },
    { min: "56", val: 56 },
    { min: "57", val: 57 },
    { min: "58", val: 58 },
    { min: "59", val: 59 },
  ];
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[60px] left-12 top-10 max-h-[250px] overflow-auto z-20 px-1.5">
      <p
        className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
        onClick={() => {
          setParkMin({ str: "00", val: null });
          onClose(false);
        }}
      >
        --{strings.NOT_SELECTED}--
      </p>
      {timeData.map((item) => {
        return (
          <div
            onClick={() => {
              setParkMin({ str: item.min, val: item.val });
              onClose(false);
              Meridiem(true);
            }}
            className="flex items-center justify-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            {!isWorking}
            <span className="mb-0 text-lg font-bold text-left">
              {item.min}
              {isWorking ? "" : " min"}{" "}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export function WorkingTimeDropDown({ setMorningHours, onClose, openMinDrop }) {
  const timeData = [
    { hours: "1", val: 1 },
    { hours: "2", val: 2 },
    { hours: "3", val: 3 },
    { hours: "4", val: 4 },
    { hours: "5", val: 5 },
    { hours: "6", val: 6 },
    { hours: "7", val: 7 },
    { hours: "8", val: 8 },
    { hours: "9", val: 9 },
    { hours: "10", val: 10 },
    { hours: "11", val: 11 },
    { hours: "12", val: 12 },
  ];
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[60px] right-12 top-10 max-h-[250px] overflow-auto z-20 px-1.5">
      <p
        className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
        onClick={() => {
          setMorningHours({ str: "00", val: "" });
          onClose(false);
        }}
      >
        --{strings.NOT_SELECTED}--
      </p>
      {timeData.map((item) => {
        return (
          <div
            onClick={() => {
              setMorningHours({ str: item.hours, val: item.val });
              onClose(false);
              openMinDrop(true);
            }}
            className="flex items-center justify-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
          >
            <span className="mb-0  font-bold text-lg">{item.hours}</span>
          </div>
        );
      })}
      {/* <p
        className="!mb-0 text-white font-bold py-2 text-center cursor-pointer"
        onClick={() => {
          setMorningHours({ str: "", val: "" });
        }}
      >
        --{strings.NOT_SELECTED}--
      </p> */}
    </div>
  );
}

export function Meridiem({ MeridiemValue, Meridiem, setEndMeridiem }) {
  const data = [
    {
      text: "AM",
    },
    {
      text: "PM",
    },
  ];
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[60px] right-0  top-8 max-h-[250px] overflow-auto z-20 px-1.5">
      {data.map((data) => {
        return (
          <div
            className="flex items-center justify-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
            onClick={() => {
              MeridiemValue(data.text);
              Meridiem(false);
            }}
          >
            <span className="mb-0  font-bold text-sm">{data.text}</span>
          </div>
        );
      })}
    </div>
  );
}
