import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { strings } from "../../../utils/String";
import ReactDatePicker from "react-datepicker";
import { Icon } from "@iconify-icon/react";
import trainerBlack from "../../../assests/icons/trainerIcon-black.svg";
import trainer from "../../../assests/icons/boldTrainer.svg";
import ptSesstion from "../../../assests/icons/statistics PT session/pt-session icon colored.svg";
import ptSesstionBlack from "../../../assests/icons/statistics PT session/pt-session icon.svg";
import ptSesstionGray from "../../../assests/icons/statistics PT session/pt-session icon gray.svg";
import activeMember from "../../../assests/icons/active member.svg";
import newMember from "../../../assests/icons/new member.svg";
import expire from "../../../assests/icons/expireIcon.svg";
import ageIcon from "../../../assests/icons/ageIcon.svg";
import StatisticCards from "../../commonComponents/StatisticCards";
import male from "../../../assests/icons/maleUser.svg";
import female from "../../../assests/icons/femaleUser.svg";
import avgIcon from "../../../assests/icons/avgIcon.svg";
import lifter from "../../../assests/icons/statistics PT session/lifter icon.svg";
import repurchase from "../../../assests/icons/statistics PT session/repurchase.svg";
import PtTrainerScreen from "./PtTrainerScreen";
import { useSelector } from "react-redux";
import StatisticsSublyApi from "../../../helper/StatisticsApi";
import Loader from "../../../utils/Loader";
import SaleGraph from "../../commonComponents/modalScreens/SaleGraph";
import AgeGraph from "../../commonComponents/modalScreens/AgeGraph";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import moment from "moment";
import { DatePicker } from "rsuite";

function PtScreen() {
  const { token } = useSelector((state) => state.user);
  const [searchType, setSearchType] = useState("month");
  const [isLoader, setIsLoader] = useState(false);
  const [selectType, setSelectType] = useState(false);
  const [genderType, setGenderType] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ptList, setPtList] = useState("");
  const [sessionList, setSessionList] = useState("");
  const [ptTrainer, setPtTrainer] = useState("");
  const [openGraph, setOpenGraph] = useState(false);
  const [openAgeGraph, setOpenAgeGraph] = useState(false);
  const [graphValue, setGraphValue] = useState();
  const [ageGraphValue, setAgeGraphValue] = useState();
  const [trainerId, setTrainerId] = useState();
  const [graphgLoader, setGraphLoader] = useState(false);
  const [graphText, setGraphText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [currentEndMonth, setCurrentEndMonth] = useState(
    moment().format("YYYY.MM.DD")
  );
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");

  // ==============Fetch statistics pt session and pt trainer list==============
  useEffect(() => {
    if (searchType !== "") {
      ptSessionHandle();
      ptTrainerHandle();
    }
  }, [searchType]);

  async function ptSessionHandle(event) {
    setIsLoader(true);
    if (event) {
      const requestData = {
        searchType: searchType,
        startDate: startDate,
        endDate: endDate,
      };
      await StatisticsSublyApi.statisticsPtList(requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            if (startDate || endDate) {
              setSearchType("");
            }
            setPtList(response.data);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const requestData = {
        searchType: searchType,
        startDate: "",
        endDate: "",
      };
      await StatisticsSublyApi.statisticsPtList(requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setEndDate("");
            setStartDate("");
            setPtList(response.data);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function ptTrainerHandle(event) {
    setIsLoader(true);
    if (event) {
      const requestData = {
        searchType: searchType,
        startDate: startDate,
        endDate: endDate,
      };
      await StatisticsSublyApi.statisticsPtTrainer(requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            if (startDate || endDate) {
              setSearchType("");
            }
            setPtTrainer(response.data);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const requestData = {
        searchType: searchType,
        startDate: "",
        endDate: "",
      };
      await StatisticsSublyApi.statisticsPtTrainer(requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setEndDate("");
            setStartDate("");
            setPtTrainer(response.data);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function ptGraph(key, type, filter) {
    setGraphLoader(true);
    await StatisticsSublyApi.ptGraph(token, key, filter, type || "").then(
      (response) => {
        // console.log("--------response",response)
        if (response.statusCode == 200) {
          setGraphValue(response.data);
          setGraphLoader(false);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      }
    );
  }

  async function trainerGraph(key, Id) {
    setGraphLoader(true);
    await StatisticsSublyApi.trainerGraph(token, key, Id, searchType).then(
      (response) => {
        if (response.statusCode == 200) {
          setGraphLoader(false);
          setGraphValue(response.data);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      }
    );
  }

  // ================API for fetching membership list================
  useEffect(() => {
    ptListHandle();
  }, [genderType]);

  async function ptListHandle() {
    setIsLoader(true);
    await StatisticsSublyApi.statisticsMemberShip(token, 1)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setSessionList(response.data);
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // const filterMonthDates = (date) => {
  //   const condition =
  //     new Date(date).getMonth() === new Date(currentMonth).getMonth();
  //   return condition;
  // };
  // const filterEndMonthDates = (date) => {
  //   const condition =
  //     new Date(date).getMonth() === new Date(currentEndMonth).getMonth();
  //   return condition;
  // };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
  }, [isCalenderOpen, currentMonth]);
  return (
    <section>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col xl={10} className="p-0 relative overflow-auto max-h-screen">
            <Header text={strings.STATISTICS_PT_SESSIONS} />
            <div className="mx-6 ">
              {selectType ? (
                <div className="my-4 flex items-center gap-3 flex-wrap">
                  <div className="flex items-center gap-2 flex-wrap">
                    <button
                      onClick={() => {
                        setSearchType("today");
                      }}
                      className={`${
                        searchType == "today"
                          ? "text-black bg-[#CCFF00]"
                          : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                      } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {" "}
                      {strings.TODAY}
                    </button>
                    <button
                      onClick={() => {
                        setSearchType("yesterday");
                      }}
                      className={`${
                        searchType == "yesterday"
                          ? "text-black bg-[#CCFF00]"
                          : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                      } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {" "}
                      {strings.YESTERDAY}
                    </button>
                    <button
                      onClick={() => {
                        setSearchType("week");
                      }}
                      className={`${
                        searchType == "week"
                          ? "text-black bg-[#CCFF00]"
                          : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                      } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {strings.ONE_WEEK}
                    </button>
                    <button
                      onClick={() => {
                        setSearchType("month");
                      }}
                      className={`${
                        searchType == "month"
                          ? "text-black bg-[#CCFF00]"
                          : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                      } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {strings.ONE_MONTH}
                    </button>
                    <button
                      onClick={() => {
                        setSearchType("year");
                      }}
                      className={`${
                        searchType == "year"
                          ? "text-black bg-[#CCFF00]"
                          : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                      } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {strings.ONE_YEAR}
                    </button>
                  </div>
                  <div className="flex items-center gap-2 max-sm:flex-wrap">
                    <div className="flex items-center gap-2 dateInputClass pl-3 max-[390px]:-ml-6 max-[430px]:-ml-4 max-[360px]:-ml-8">
                      <Icon
                        icon="lets-icons:date-today"
                        width="30"
                        height="30"
                        style={{ color: "#CCFF00" }}
                      />
                      <div className="w-full flex flex-col md:flex-row gap-2 ">
                        <div className="newDatePickerClass">
                          <DatePicker
                            oneTap
                            editable={false}
                            id="datepicker-1"
                            placeholder="From-"
                            format="yyyy.MM.dd"
                            block
                            appearance="subtle"
                            className="custom-datepicker"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            onChangeCalendarDate={(e) => {
                              setCurrentMonth(e);
                            }}
                            onNextMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onPrevMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onOpen={() => {
                              setIsCalenderOpen(true);
                              setCurrentMonth(
                                startDate ? new Date(startDate) : new Date()
                              );
                              setDatePickerCheck(1);
                            }}
                            onClose={() => {
                              setIsCalenderOpen(false);
                              setCurrentMonth(
                                startDate ? new Date(startDate) : new Date()
                              );
                              setDatePickerCheck("");
                            }}
                            disabledDate={(date) => {
                              const maxDate = new Date();
                              return date > maxDate;
                            }}
                          />
                        </div>
                        <div className="newDatePickerClass">
                          <DatePicker
                            oneTap
                            editable={false}
                            id="datepicker-2"
                            placeholder="To-"
                            format="yyyy.MM.dd"
                            block
                            appearance="subtle"
                            className="custom-datepicker"
                            onChange={(date) => setEndDate(date)}
                            onChangeCalendarDate={(e) => {
                              setCurrentMonth(e);
                            }}
                            value={endDate}
                            onNextMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onPrevMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onOpen={() => {
                              setIsCalenderOpen(true);
                              setCurrentMonth(
                                endDate ? new Date(endDate) : new Date()
                              );
                              setDatePickerCheck(2);
                            }}
                            onClose={() => {
                              setIsCalenderOpen(false);
                              setDatePickerCheck("");
                              setCurrentMonth(
                                endDate ? new Date(endDate) : new Date()
                              );
                            }}
                            disabledDate={(date) => {
                              const minDate = new Date(startDate);
                              const maxDate = new Date();
                              return date < minDate || date > maxDate;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        ptTrainerHandle(true);
                      }}
                      className="text-black bg-[#CCFF00] text-base font-bold h-[50px] w-[120px] rounded-[28px]"
                    >
                      {strings.SEARCH}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="my-4 flex items-center justify-between gap-3 flex-wrap">
                  <div className="flex items-center gap-2 flex-wrap">
                    <button
                      onClick={() => {
                        setSearchType("week");
                      }}
                      className={`${
                        searchType == "week"
                          ? "text-black bg-[#CCFF00]"
                          : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                      } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {" "}
                      {strings.ONE_WEEK}
                    </button>
                    <button
                      onClick={() => {
                        setSearchType("month");
                      }}
                      className={`${
                        searchType == "month"
                          ? "text-black bg-[#CCFF00]"
                          : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                      } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {" "}
                      {strings.ONE_MONTH}
                    </button>
                    <button
                      onClick={() => {
                        setSearchType("year");
                      }}
                      className={`${
                        searchType == "year"
                          ? "text-black bg-[#CCFF00]"
                          : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                      } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                    >
                      {strings.ONE_YEAR}
                    </button>
                  </div>
                  <div className="flex items-center gap-2 max-sm:flex-wrap ">
                    <div className="flex items-center gap-2 ml-10 dateInputClass pl-3">
                      <Icon
                        icon="lets-icons:date-today"
                        width="30"
                        height="30"
                        style={{ color: "#CCFF00" }}
                      />
                      <div className="w-full flex flex-col md:flex-row gap-2 ">
                        <div className="newDatePickerClass">
                          <DatePicker
                            oneTap
                            editable={false}
                            id="datepicker-1"
                            placeholder="From-"
                            format="yyyy.MM.dd"
                            block
                            appearance="subtle"
                            className="custom-datepicker"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            onChangeCalendarDate={(e) => {
                              setCurrentMonth(e);
                            }}
                            onNextMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onPrevMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onOpen={() => {
                              setIsCalenderOpen(true);
                              setCurrentMonth(
                                startDate ? new Date(startDate) : new Date()
                              );
                              setDatePickerCheck(1);
                            }}
                            onClose={() => {
                              setIsCalenderOpen(false);
                              setCurrentMonth(
                                startDate ? new Date(startDate) : new Date()
                              );
                              setDatePickerCheck("");
                            }}
                            disabledDate={(date) => {
                              const maxDate = new Date();
                              return date > maxDate;
                            }}
                          />
                        </div>
                        <div className="newDatePickerClass">
                          <DatePicker
                            oneTap
                            editable={false}
                            id="datepicker-2"
                            placeholder="To-"
                            format="yyyy.MM.dd"
                            block
                            appearance="subtle"
                            className="custom-datepicker"
                            onChange={(date) => setEndDate(date)}
                            onChangeCalendarDate={(e) => {
                              setCurrentMonth(e);
                            }}
                            value={endDate}
                            onNextMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onPrevMonth={(e) => {
                              setCurrentMonth(e);
                            }}
                            onOpen={() => {
                              setIsCalenderOpen(true);
                              setCurrentMonth(
                                endDate ? new Date(endDate) : new Date()
                              );
                              setDatePickerCheck(2);
                            }}
                            onClose={() => {
                              setIsCalenderOpen(false);
                              setDatePickerCheck("");
                              setCurrentMonth(
                                endDate ? new Date(endDate) : new Date()
                              );
                            }}
                            disabledDate={(date) => {
                              const minDate = new Date(startDate);
                              const maxDate = new Date();
                              return date < minDate || date > maxDate;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        ptSessionHandle(true);
                      }}
                      className="text-black bg-[#CCFF00] text-base font-bold h-[50px] w-[120px] rounded-[28px]"
                    >
                      {strings.SEARCH}
                    </button>
                  </div>
                </div>
              )}
              <div className="my-4 flex items-center justify-center gap-2 rounded-[29.5px] bg-[#353638] kakaoClass h-[60px] w-[440px] max-[470px]:w-full">
                <div
                  onClick={() => {
                    setSelectType(false);
                    ptSessionHandle();
                    setSearchType("total");
                    setStartDate("");
                    setEndDate("");
                  }}
                  className={`${
                    !selectType
                      ? "rounded-[29.5px] bg-[#CCFF00] !text-black h-full"
                      : "!text-white"
                  } ${"flex items-center gap-3 w-1/2 justify-center px-3 cursor-pointer"}`}
                >
                  {!selectType ? (
                    <img src={ptSesstionBlack} alt="pt-session" />
                  ) : (
                    <img src={ptSesstion} alt="pt-session" />
                  )}
                  <p
                    className={`${
                      !selectType ? "!text-black" : "!text-white"
                    } ${"mb-0 text-[20px] font-extrabold"}`}
                  >
                    {strings.PT_SESSION}
                  </p>
                </div>
                <div
                  onClick={() => {
                    setSelectType(true);
                    ptTrainerHandle();
                    setSearchType("month");
                    setStartDate("");
                    setEndDate("");
                  }}
                  className={`${
                    selectType
                      ? "rounded-[29.5px] bg-[#CCFF00] !text-black h-full"
                      : "!text-white"
                  } ${"flex items-center gap-3 w-1/2 justify-center px-3 cursor-pointer"}`}
                >
                  {selectType ? (
                    <img
                      src={trainerBlack}
                      alt="trainer-black"
                      className="w-[31px] h-[34px]"
                    />
                  ) : (
                    <img
                      src={trainer}
                      alt="trainer"
                      className="w-[31px] h-[34px]"
                    />
                  )}
                  <p
                    className={`${
                      selectType ? "!text-black" : "!text-white"
                    } ${"mb-0 text-[20px] font-extrabold"}`}
                  >
                    {strings.TRAINERS}
                  </p>
                </div>
              </div>
              {selectType && ptTrainer?.trainerList?.length == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              {selectType ? (
                ptTrainer?.trainerList?.map((item, index) => (
                  <PtTrainerScreen
                    item={item}
                    index={index}
                    setTrainerId={setTrainerId}
                    trainerGraph={trainerGraph}
                    setOpenGraph={setOpenGraph}
                    setGraphValue={setGraphValue}
                    setOpenAgeGraph={setOpenAgeGraph}
                    setAgeGraphValue={setAgeGraphValue}
                    setGraphText={setGraphText}
                  />
                ))
              ) : (
                <>
                  <div className="bg-[#353638] px-3 rounded-[10px]">
                    {" "}
                    <div className="pt-3 pb-2.5 ">
                      <p className="text-[26px] font-extrabold mb-2">
                        {strings.ALL_MEMBERS}
                      </p>
                      <div className="gap-2 flex items-center flex-wrap overflow-auto pb-4 scrollingClass">
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={ptSesstionGray}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("totalSessions", "", searchType);
                            }}
                            data={ptList?.totalSessions}
                            text={strings.TOTAL_SESSIONS}
                            subText={strings.SESSION}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={activeMember}
                            data={ptList?.activeMembers}
                            text={strings.ACTIVE_MEMBER}
                            subText={strings.PEOPLE.toLowerCase()}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("activeMembers", "", searchType);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={newMember}
                            data={ptList?.newMembers}
                            text={strings.NEW_MEMBER}
                            subText={strings.PEOPLE.toLowerCase()}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("newMembers", "", searchType);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={expire}
                            data={ptList?.remaining3Sessions || 0}
                            text={strings.EXPIRE_IN_THREE}
                            subText={strings.SESSION}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={expire}
                            data={ptList?.remaining5Sessions || 0}
                            text={strings.EXPIRE_IN_FIVE}
                            subText={strings.SESSION}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:min-w-full ">
                          <StatisticCards
                            isMember={true}
                            image={ageIcon}
                            data={ptList?.ageRange?.totalCounts}
                            text={strings.AGE_DISTRIBUTION}
                            isAge={true}
                            clickBtn={() => {
                              ptList?.ageRange?.totalCounts.length > 0
                                ? setOpenAgeGraph(true)
                                : setOpenAgeGraph(false);
                              setAgeGraphValue(ptList?.ageRange?.totalCounts);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-3 pb-2.5">
                      <p className="text-[20px] font-extrabold mb-3 flex items-center gap-2">
                        <img src={male} alt="male" />
                        {strings.MALE}
                      </p>
                      <div className="gap-2 flex items-center flex-wrap overflow-auto pb-4 scrollingClass">
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={activeMember}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("activeMembers", "male", searchType);
                            }}
                            isPercent={`${(
                              (ptList?.activeMaleCount /
                                ptList?.activeMembers || 0) * 100
                            ).toFixed(1)}%`}
                            data={ptList?.activeMaleCount}
                            text={strings.ACTIVE_MEMBER}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={avgIcon}
                            data={ptList?.maleAvgAttending}
                            text={strings.AVG_ATTENDING_DAYS}
                            subText={strings.DAYS.toLowerCase()}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("avgAttendingDays", "male", searchType);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={lifter}
                            data={ptList?.maleAvgExercises}
                            text={strings.AVG_EXERCISE}
                            subText={strings.EXERCISES.toLowerCase()}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("avgExercises", "male", searchType);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={repurchase}
                            isPercent={`${(
                              (ptList?.totalMaleRepurchaseCount /
                                ptList?.totalRepurchaseCount || 0) * 100
                            ).toFixed(1)}%`}
                            data={ptList?.totalMaleRepurchaseCount}
                            text={strings.REPURCHASE_MEMBER}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("repurchaseCount", "male", searchType);
                            }}
                            addStyle={true}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:min-w-full ">
                          <StatisticCards
                            isMember={true}
                            image={ageIcon}
                            data={ptList?.ageRange?.male}
                            text={strings.AGE_DISTRIBUTION}
                            isAge={true}
                            clickBtn={() => {
                              ptList?.ageRange?.male.length > 0
                                ? setOpenAgeGraph(true)
                                : setOpenAgeGraph(false);
                              setAgeGraphValue(ptList?.ageRange?.male);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-3 pb-2.5">
                      <p className="text-[20px] font-bold mb-3 flex items-center gap-2">
                        {" "}
                        <img src={female} alt="female" />
                        {strings.FEMALE}
                      </p>
                      <div className="gap-2 flex items-center flex-wrap overflow-auto pb-4 scrollingClass">
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={activeMember}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("activeMembers", "female", searchType);
                            }}
                            isPercent={`${(
                              (ptList?.activeFemaleCount /
                                ptList?.activeMembers || 0) * 100
                            ).toFixed(1)}%`}
                            data={ptList?.activeFemaleCount}
                            text={strings.ACTIVE_MEMBER}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={avgIcon}
                            data={ptList?.femaleAvgAttending}
                            text={strings.AVG_ATTENDING_DAYS}
                            subText={strings.DAYS.toLowerCase()}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("avgAttendingDays", "female", searchType);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={lifter}
                            data={ptList?.femaleAvgExercises}
                            text={strings.AVG_EXERCISE}
                            subText={strings.EXERCISES.toLowerCase()}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("avgExercises", "female", searchType);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:max-w-full max-sm:min-w-full max-w-[250px] ">
                          <StatisticCards
                            isMember={true}
                            image={repurchase}
                            isPercent={`${(
                              (ptList?.totalFemaleRepurchaseCount /
                                ptList?.totalRepurchaseCount || 0) * 100
                            ).toFixed(1)}%`}
                            data={ptList?.totalFemaleRepurchaseCount}
                            text={strings.REPURCHASE_MEMBER}
                            clickBtn={() => {
                              setOpenGraph(true);
                              // setGraphValue(ptList?.totalSessions);
                              ptGraph("repurchaseCount", "female", searchType);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                            addStyle={true}
                          />
                        </div>
                        <div className="min-w-[250px] max-sm:min-w-full ">
                          <StatisticCards
                            isMember={true}
                            image={ageIcon}
                            data={ptList?.ageRange?.female}
                            text={strings.AGE_DISTRIBUTION}
                            subText={strings.SESSION}
                            isAge={true}
                            clickBtn={() => {
                              ptList?.ageRange?.female.length > 0
                                ? setOpenAgeGraph(true)
                                : setOpenAgeGraph(false);
                              setAgeGraphValue(ptList?.ageRange?.female);
                            }}
                            graphgLoader={graphgLoader}
                            setGraphText={setGraphText}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {(sessionList?.male?.length > 0 ||
                    sessionList?.female?.length > 0) && (
                    <div className="bg-[#353638] px-3 rounded-[10px] my-4">
                      {" "}
                      <div className="pt-3 pb-2.5">
                        <div className="flex items-center flex-wrap gap-2 pb-3">
                          <p className="text-[26px] font-extrabold mb-2 mr-5">
                            {strings.CAP_SESSION}
                          </p>
                          <div className="flex items-center gap-x-2 gap-y-3 max-sm:flex-wrap">
                            <button
                              onClick={() => {
                                setGenderType("all");
                              }}
                              className={` ${
                                genderType == "all"
                                  ? "text-black bg-[#CCFF00]"
                                  : "text-white kakaoClass"
                              } ${"hover:text-black hover:bg-[#CCFF00] text-base font-bold  h-[40px] w-[120px] rounded-[28px]"}`}
                            >
                              {strings.ALL}
                            </button>
                            <button
                              onClick={() => {
                                setGenderType("male");
                              }}
                              className={` ${
                                genderType == "male"
                                  ? "text-black bg-[#CCFF00]"
                                  : "text-white kakaoClass"
                              } ${"hover:text-black hover:bg-[#CCFF00] text-base font-bold  h-[40px] w-[120px] rounded-[28px]"}`}
                            >
                              {strings.MALE}
                            </button>
                            <button
                              onClick={() => {
                                setGenderType("female");
                              }}
                              className={` ${
                                genderType == "female"
                                  ? "text-black bg-[#CCFF00]"
                                  : "text-white kakaoClass"
                              } ${"hover:text-black hover:bg-[#CCFF00] text-base font-bold  h-[40px] w-[120px] rounded-[28px]"}`}
                            >
                              {strings.FEMALE}
                            </button>
                          </div>
                        </div>
                        {sessionList?.male?.length > 0 &&
                          (genderType == "all" || genderType == "male") && (
                            <div className="pt-3 pb-2.5">
                              <p className="text-[20px] font-bold mb-3 flex items-center gap-2">
                                {" "}
                                <img src={male} alt="male" />
                                {strings.MALE}
                              </p>
                              <div
                                className="grid grid-cols-6 max-[1850px]:grid-cols-5 max-[1650px]:grid-cols-4 max-[1350px]:grid-cols-3
                          max-[900px]:grid-cols-2 max-[600px]:grid-cols-1 gap-2 overflow-auto pb-4 scrollingClass"
                              >
                                {sessionList?.male?.map((items, index) => (
                                  <div key={index}>
                                    <StatisticCards
                                      isMember={true}
                                      image={ptSesstionGray}
                                      isPercent={`${items?.percentage?.toFixed(
                                        1
                                      )}%`}
                                      data={items?.count}
                                      text={items?.productName}
                                      graphgLoader={graphgLoader}
                                      setGraphText={setGraphText}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        {sessionList?.female?.length > 0 &&
                          (genderType == "all" || genderType == "female") && (
                            <div className="pt-3 pb-2.5">
                              <p className="text-[20px] font-bold mb-3 flex items-center gap-2">
                                {" "}
                                <img src={female} alt="female" />
                                {strings.FEMALE}
                              </p>
                              <div
                                className="gap-2 grid grid-cols-6 max-[1850px]:grid-cols-5 max-[1650px]:grid-cols-4 max-[1350px]:grid-cols-3
                          max-[900px]:grid-cols-2 max-[600px]:grid-cols-1 overflow-auto pb-4 scrollingClass"
                              >
                                {sessionList?.female?.map((items, index) => (
                                  <div key={index}>
                                    <StatisticCards
                                      isMember={true}
                                      image={ptSesstionGray}
                                      isPercent={`${items?.percentage?.toFixed(
                                        1
                                      )}%`}
                                      data={items?.count}
                                      text={items?.productName}
                                      graphgLoader={graphgLoader}
                                      setGraphText={setGraphText}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <SaleGraph
        image={activeMember}
        text={graphText}
        openGraph={openGraph}
        setOpenGraph={setOpenGraph}
        graphValue={graphValue}
        graphgLoader={graphgLoader}
      />
      <AgeGraph
        image={ageIcon}
        text={strings.AGE_DISTRIBUTION}
        openAgeGraph={openAgeGraph}
        setOpenAgeGraph={setOpenAgeGraph}
        graphValue={ageGraphValue}
      />
    </section>
  );
}

export default PtScreen;
