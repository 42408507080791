import React, { useEffect, useState } from "react";
import { strings } from "../../../utils/String";
import { InputGroup, Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";
import ReactDatePicker from "react-datepicker";
import won from "../../../assests/icons/won-currancy.svg";
import MembersSublyApi from "../../../helper/MemberApi";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import errorIcon from "../../../assests/icons/error-icon.svg";
import { CloseButton } from "../AuthButton";

function ErrorModal({ openModal, closeModal, errorMessage, classNameValue }) {
  setTimeout(() => {
    if (openModal) {
      closeModal(false);
    }
  }, 500000);
  return (
    <>
      <Modal
        className={`${"modalBoxClass"} ${classNameValue}`}
        show={openModal}
        onHide={() => {
          closeModal(false);
        }}
      >
        <Modal.Body className="text-white bg-box_bg  py-8 px-8">
          <div className="flex justify-center items-center pb-4 border-b-2 border-[#444444] gap-2">
            <img src={errorIcon} />
            <p className=" !mb-0 text-[40px] max-lg:text-[32px] font-extrabold">
              {strings.ERROR}
            </p>
          </div>
          <div className=" w-[75%] m-auto text-center mt-4">
            <div className="flex justify-center ">
              <p className="text-[30px] font-bold ">{errorMessage}</p>
            </div>
          </div>
          <div className="flex justify-center mt-3">
            <CloseButton
              clickFun={() => {
                closeModal(false);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ErrorModal;
