import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class GymSettingSublyApi {
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${BASE_URL}${endpoint}`;
    const headers = { ...header };
    try {
      const result = await axios({ url, method, data, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  //   ===============API for fetching staff list============
  static async fetchStaffList(token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`staff/list`, undefined, "get", header);
    return res;
  }

  //   ===============API for update staff status============
  static async staffStatus(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`staff/status`, data, "put", header);
    return res;
  }

  //   ===============API for update staff authority============
  static async staffAuthorityUpdate(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`staff/authority`, data, "post", header);
    return res;
  }

  //   ===============API FOR STAFF REGISTER============

  static async staffRegister(data, token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`staff/register`, data, "post", header);
    return res;
  }

  //   ===============API FOR STAFF REGISTER============

  static async editStaffInfo(token, staffId, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`staff/edit/${staffId}`, data, "post", header);
    return res;
  }

  //   ===============API FOR STAFF REGISTER============

  static async getStaffDetails(token, staffId) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`staff/${staffId}`, undefined, "get", header);
    return res;
  }

  //   ===============API FOR GET GYM DETAILS============

  static async getGymDetails(token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`gym`, undefined, "get", header);
    return res;
  }

  //   ===============API FOR GET GYM DETAILS============

  static async getEqupmentDetails(token, search, offset) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `equipment?page=${offset}&search=${search ?? ""}`,
      undefined,
      "get",
      header
    );
    return res;
  }
  //   ===============API FOR FETCHING LOCKER LIST============
  static async fetchLockerList(offset, token, search, order, key, type) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `locker/list?page=${offset}&search=${
        search ?? ""
      }&sortingKey=${key}&sortingOrder=${order}&status=${type}&pageSize=${13}`,
      null,
      "get",
      header
    );
    return res;
  }

  //   ===============API FOR FETCHING LOCKER LIST============
  static async addLockers(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`locker`, data, "post", header);
    return res;
  }

  //   ===============API FOR FETCHING MACHINE DETAILS============

  static async getMachineDetails(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`equipment/${id}`, undefined, "get", header);
    return res;
  }

  //   ===============API FOR DELETE EQUIPMENT============

  static async deleteEquipment(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `equipment/${id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  //   ===============API FOR ADD EQUIPMENT============

  static async addMachine(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`equipment`, data, "post", header);
    return res;
  }

  //   ===============API FOR EDIT EQUIPMENT============

  static async editMachine(token, data, machineId) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`equipment/${machineId}`, data, "put", header);
    return res;
  }

  //   ===============API FOR EDIT FREE WEIGHT ============

  static async editFreeWeight(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`equipment/edit`, data, "put", header);
    return res;
  }

  //   ===============API FOR EDIT LOCKER ============

  static async editLocker(token, data, lockerId) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`locker/${lockerId}`, data, "put", header);
    return res;
  }

  //   ===============API FOR DELETE LOCKER ============

  static async deleteLocker(token, lockerId) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    return this.request(`locker/${lockerId}`, undefined, "delete", header);
  }

  //   ===============API FOR EDIT GYM INFORMATION============

  static async editGymInfo(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`gym`, data, "put", header);
    return res;
  }

  //   ===============API FOR FETCH HOLIDAY============

  static async getHoliday(token, month, year) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `gym/holidays?month=${month}&year=${year}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API FOR POST HOLIDAY============

  static async postHoliday(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`gym/holidays`, data, "post", header);
    return res;
  }

  //   ===============API FOR POST HOLIDAY============

  static async ptCancel(token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `gym/pt-cancel-time`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API FOR POST HOLIDAY============

  static async lockerHistory(token, id, order, key, offset) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `locker/history/${id}?page=${offset}&sortingKey=${key}&sortingOrder=${order}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //   ===============API FOR FETCH USER DETAIL============

  static async fetchUserDetail(token, code) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `user-details/${code}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ==============API for fetching member list============
  static async fetchMemberList(token, search) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `member?search=${search}`,
      undefined,
      "get",
      header
    );
    return res;
  }
  // ==============API for fetching member list============
  static async fetchMessagerList(
    token,
    sortingKey,
    sortingOrder,
    offset,
    search,
    filter
  ) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `center-message?sortingKey=${sortingKey}&sortingOrder=${sortingOrder}&page=${offset}&search=${search}&writingType=${filter}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ==============API for register message============
  static async messageRegister(token, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`center-message`, data, "post", header);
    return res;
  }

  // ==============API for fetching message detail============
  static async fetchMessageDetail(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `center-message/${id}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ==============API for delete message============
  static async deleteMessage(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `center-message/${id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  // ==============API for resend message============
  static async resendMessage(token, id) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `center-message/resend/${id}`,
      undefined,
      "patch",
      header
    );
    return res;
  }

  // ==============API for edit message============
  static async editMessageDetail(token, id, data) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`center-message/${id}`, data, "put", header);
    return res;
  }
}

export default GymSettingSublyApi;
