import React from "react";
import { Modal } from "react-bootstrap";
import logo from "../assests/attendanceIcons/logo-icon.svg";
import { strings } from "../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";

function NotValidInfoModal({
  isNotVaild,
  setIsNotValid,
  subText,
  headText,
  setOtp,
  setConvertedOtp,
}) {
  return (
    <Modal
      className={`${"modalBoxClass attendnaceModalClass"}`}
      show={isNotVaild}
      onHide={() => {
        setIsNotValid(false);
        setOtp("");
        setConvertedOtp("");
      }}
    >
      <Modal.Body className="text-white bg-box_bg text-center py-14 px-8">
        <img src={logo} alt="logo" className="mx-auto" />

        <p className="mb-1 mt-5 text-[30px] font-extrabold">{headText} </p>
        <p className="text-[20px] font-bold pb-2">{subText}</p>
        <div className="flex justify-center items-center pt-2 gap-4">
          <button
            onClick={() => {
              setIsNotValid(false);
              setConvertedOtp("");
              setOtp("");
            }}
            className="text-[20px] h-[55px] font-extrabold flex justify-center items-center gap-1.5 bg-[#CCFF00] text-black w-[155px] rounded-[28px]"
          >
            {strings.CLOSE}
            <Icon
              icon="gg:timer"
              width="25"
              height="25"
              style={{ color: "black" }}
            />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default NotValidInfoModal;
