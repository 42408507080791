import React from "react";
import { Modal } from "react-bootstrap";
import { strings } from "../../../utils/String";
import { CloseButton } from "../AuthButton";
import "../../../assests/CommonStyle.css";
import logo from "../../../assests/icons/Logo.svg";
import { useNavigate } from "react-router-dom";

function SentAccountInfo({
  openInfoModal,
  setOpenInfoModal,
  phone,
  emailValue,
}) {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        className="modalBoxClass "
        show={openInfoModal}
        onHide={() => {
          setOpenInfoModal(false);
          navigate("/Verification", {
            state: { email: emailValue, phone: phone },
          });
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-12 px-12">
          <div>
            <img
              src={logo}
              alt="logo"
              className="w-32 h-16 max-lg:w-30 max-lg:h-14 mx-auto mb-4"
            />
          </div>
          <p className="text-3xl font-bold mt-6 mb-1 max-lg:text-xl">
            {strings.SENT_ACCOUNT_INFORMATION_TO_YOUR_CELL}
          </p>
          <p className="text-2xl font-bold mt-1 mb-10 max-lg:text-lg">
            {strings.CHECK_MESSAGE_IN_KAKAOTALK_OR_CELL}
          </p>
          <div className="flex justify-center">
            <CloseButton
              clickFun={() => {
                navigate("/Verification", {
                  state: { email: emailValue, phone: phone },
                });
                setOpenInfoModal(false);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SentAccountInfo;
