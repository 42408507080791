import React, { useEffect, useState } from "react";
import lifter from "../../assests/icons/lifter.svg";
import { Icon } from "@iconify-icon/react";
import { Col, Row } from "react-bootstrap";
import workoutImage from "../../assests/images/workout image.jpg";
import trainerReview from "../../assests/icons/dietlog-detail-icons/trainer comment.svg";
import imagePlaceholder from "../../assests/images/no-image.png";
import ImageGallery from "react-image-gallery";
import { useDispatch } from "react-redux";
import WorkoutMedia from "./modalScreens/WorkoutMedia";
import { WORKOUT_LISTS_TITLES } from "../../utils/CommonData";

const imageObj = {};
function WorkoutDetailCard({ item, logId, classNameValue }) {
  const [isScrolling, setIsScrolling] = useState({ status: false, id: "" });

  const images = [];
  item?.workoutLog &&
    item?.workoutLog.map((item, index) =>
      [
        ...(item?.files?.images || []).flat(2),
        ...(item?.files?.videos || []).flat(2),
      ]?.map((val, index) => {
        const data = {};
        if (val?.compressed) {
          data.original = val?.compressed;
        }
        if (val?.original) {
          data.video = val?.original;
        }
        if (Object.values(data)?.length) {
          images.push(data);
        }
      })
    );

  return (
    <div className="bg-[#2C2C2E] rounded-[10px] px-3 py-3 relative h-max">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2.5">
          <img
            src={item.exerciseDetails?.exerciseImg || lifter}
            alt="lifter"
            className="w-[60px] h-[60px] rounded-full"
          />
          <p className="mb-0 text-2xl font-bold">
            {item.exerciseDetails?.exerciseNameEng}
          </p>
        </div>
        <div>
          {item?.workoutLogFeedback?.rating && (
            <p
              className={`${
                logId?.sessionStatus == WORKOUT_LISTS_TITLES.TRAINER_CREATED
                  ? "text-[#CCFF00]"
                  : logId?.sessionStatus == WORKOUT_LISTS_TITLES.PERSONAL
                  ? "text-[#FF6E00]"
                  : logId?.sessionStatus ==
                    WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                  ? "text-[#00FAE0]"
                  : ""
              } ${"mb-0 text-[28px] font-extrabold flex items-center gap-2"}`}
            >
              <Icon
                icon="tabler:star-filled"
                width="32"
                height="32"
                style={
                  logId?.sessionStatus == WORKOUT_LISTS_TITLES.TRAINER_CREATED
                    ? { color: "#CCFF00" }
                    : logId?.sessionStatus == WORKOUT_LISTS_TITLES.PERSONAL
                    ? { color: "#FF6E00" }
                    : logId?.sessionStatus ==
                      WORKOUT_LISTS_TITLES.SHARED_BY_MEMBER
                    ? { color: "#00FAE0" }
                    : ""
                }
              />
              {item?.workoutLogFeedback?.rating}
            </p>
          )}
        </div>
      </div>
      {item.writerType == 0 && (
        <Row className="gap-y-3 pt-3 px-1.5 relative">
          {images?.length
            ? item?.workoutLog.map(
                (itemValue, indexValue) =>
                  (itemValue?.files?.images?.length ||
                    itemValue?.files?.videos?.length) &&
                  images?.map((val, index) => (
                    <Col xxl={6} lg={12} className="pr-1.5 pl-1.5" key={index}>
                      <div
                        className="relative h-[140px] closedNotify"
                        onClick={() => {
                          setIsScrolling({ status: true, id: item.id });
                        }}
                      >
                        <img
                          src={val.original}
                          alt="original"
                          className="w-full h-full object-cover"
                        />
                        <span className="absolute bg-[#35363899] rounded-[2px] text-[12px] font-bold font-[Archivo] text-white px-2 py-1.5 top-2 right-2">
                          {indexValue + 1}set
                        </span>
                      </div>
                    </Col>
                  ))
              )
            : ""}
        </Row>
      )}
      <div className="py-2">
        {item?.workoutLog?.map((val, index) => (
          <div
            className="bg-[#353638] flex items-center justify-between gap-2 my-1.5 p-1.5 rounded-[5px]"
            key={index}
          >
            <p className="mb-0 text-lg text-[#E8E8E8] font-bold w-full flex items-center justify-center gap-x-4 py-2">
              <span className="text-white text-2xl font-extrabold">
                {index + 1}
              </span>{" "}
              set
            </p>
            <p className="mb-0 text-lg text-[#E8E8E8] font-bold w-full flex items-center justify-center gap-x-4 border-x-2 border-[#5C5C5C80] py-2">
              <span className="text-white text-[30px] font-extrabold">
                {val.weight}
              </span>{" "}
              kg
            </p>
            <p className="mb-0 text-lg text-[#E8E8E8] font-bold w-full flex items-center justify-center gap-x-4 py-2">
              <span className="text-white text-[30px] font-extrabold">
                {val.reps}
              </span>{" "}
              reps
            </p>
          </div>
        ))}
      </div>
      {item?.workoutLogFeedback?.notes && (
        <div className="flex items-start gap-2.5">
          <img src={trainerReview} alt="trainer-review" className="mt-2" />
          <p className="mb-0 font-bold text-xl mt-2">
            {item?.workoutLogFeedback?.notes}
          </p>
        </div>
      )}
      {isScrolling.status && (
        <WorkoutMedia
          images={images}
          isScrolling={isScrolling}
          setIsScrolling={setIsScrolling}
          classNameValue={classNameValue}
        />
      )}{" "}
    </div>
  );
}

export default WorkoutDetailCard;
