import React, { useState } from "react";
import { placeholder, strings } from "../../../utils/String";
import memberIconGray from "../../../assests/icons/member-group gray.svg";
import { Icon } from "@iconify-icon/react";
import moment from "moment";

function StatusMemberTable({
  memberList,
  statusMemberHandle,
  activeTab,
  setActiveTab,
  memberCount,
  setSorting,
  setMessageList,
  setmemberId,
}) {
  return (
    <div className="bg-[#333333] rounded-[10px] p-3">
      <div className="flex items-center justify-between">
        <p className="mb-0 text-xl font-semibold font-[Archivo]">
          {strings.MEMBER_LIST}
        </p>
        <div className="flex items-center gap-2 justify-center bg-[#2C2C2E] rounded-[10px] w-[178px] p-2 mr-4">
          <img src={memberIconGray} />
          <p className="mb-0 text-lg font-semibold font-[Archivo]">
            {memberCount || 0} {strings.MEMBERS}
          </p>
        </div>
      </div>
      <div className="overflow-auto my-2 2xl:max-h-[calc(100vh_-_340px)] max-2xl:max-h-[600px]">
        <table className="max-2xl:w-[1600px] w-full  border-b border-[#444444]">
          <thead className=" justify-around items-center bg-[#474749]  border-b border-[#353638] sticky top-[-1px]">
            <th
              onClick={() => {
                setActiveTab(activeTab == 0 ? null : 0);
                let sort = activeTab == 0 ? ["name", "DESC"] : ["name", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"!text-center text-[18px] cursor-pointer text-white px-0 py-1 w-[13%] justify-around items-center"}`}
            >
              <span
                className={`${
                  activeTab == 0 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"flex items-center justify-around h-[62px] font-semibold font-[Archivo] px-2"}`}
              >
                {" "}
                {placeholder.NAME}
                {activeTab == 0 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 1 ? null : 1);
                let sort =
                  activeTab == 1 ? ["session", "DESC"] : ["session", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"border-x border-[#444444]"}  ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-0 py-1 w-[8%]   justify-around items-center"}`}
            >
              {" "}
              <span
                className={`${
                  activeTab == 1 ? "kakaoClass" : "border-x border-[#444444]"
                }  ${"flex justify-around items-center h-[62px] px-2"}`}
              >
                {strings.CAP_SESSION}
                {activeTab == 1 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 2 ? null : 2);
                let sort =
                  activeTab == 2
                    ? ["trainerName", "DESC"]
                    : ["trainerName", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-0 py-1 w-[11%] justify-around items-center"}`}
            >
              <span
                className={`${
                  activeTab == 2 ? "kakaoClass" : ""
                } ${"flex justify-around items-center h-[62px] px-2"}`}
              >
                {strings.TRAINER}
                {activeTab == 2 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 3 ? null : 3);
                let sort =
                  activeTab == 3
                    ? ["recentSession", "DESC"]
                    : ["recentSession", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"border-x border-[#444444]"} ${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-0 py-1 w-[12%]    justify-around items-center "}`}
            >
              {" "}
              <span
                className={`${
                  activeTab == 3 ? "kakaoClass" : ""
                }  ${"flex justify-around items-center h-[62px] px-2"}`}
              >
                {strings.RECENT_SESSION}
                {activeTab == 3 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 4 ? null : 4);
                let sort =
                  activeTab == 4
                    ? ["cancelCount", "DESC"]
                    : ["cancelCount", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-0 text-start py-1 w-[8%]  justify-around items-center "}`}
            >
              {" "}
              <span
                className={`${
                  activeTab == 4 ? "kakaoClass" : ""
                } ${"flex justify-around items-center h-[62px] px-2"}`}
              >
                {strings.CANCEL_COUNT}
                {activeTab == 4 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 5 ? null : 5);
                let sort =
                  activeTab == 5
                    ? ["noShowCount", "DESC"]
                    : ["noShowCount", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"border-x border-[#444444]"} ${"!text-start text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-0 py-1 w-[8%]  justify-around items-center "}`}
            >
              {" "}
              <span
                className={`${
                  activeTab == 5 ? "kakaoClass" : "border-x border-[#444444]"
                }  ${"flex justify-around items-center h-[62px] px-2"}`}
              >
                {strings.NOSHOW_COUNT}
                {activeTab == 5 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 6 ? null : 6);
                let sort =
                  activeTab == 6 ? ["PT_Date", "DESC"] : ["PT_Date", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-0 py-1 w-[14%] justify-around items-center "}`}
            >
              {" "}
              <span
                className={`${
                  activeTab == 6 ? "kakaoClass" : ""
                }  ${"flex justify-around items-center h-[62px] px-2"}`}
              >
                {strings.PT_MEMBERSHIP_DATE}
                {activeTab == 6 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 7 ? null : 7);
                let sort =
                  activeTab == 7
                    ? ["registerDate", "DESC"]
                    : ["registerDate", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"border-x border-[#444444]"} ${"!text-start text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-0 py-1 w-[10%] justify-around items-center "}`}
            >
              {" "}
              <span
                className={`${
                  activeTab == 7 ? "kakaoClass" : "border-x border-[#444444]"
                } ${"flex justify-around items-center h-[62px] px-2"}`}
              >
                {strings.REGISTER_DATE}
                {activeTab == 7 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 8 ? null : 8);
                let sort =
                  activeTab == 8 ? ["status", "DESC"] : ["status", "ASC"];
                statusMemberHandle(sort);
                setSorting(sort);
              }}
              className={`${"!text-center text-[18px] cursor-pointer font-semibold font-[Archivo] text-white px-0 py-1 w-[8%] "}`}
            >
              <span
                className={`${
                  activeTab == 8 ? "kakaoClass" : ""
                } ${"flex justify-around items-center h-[62px] px-2"}`}
              >
                {" "}
                {strings.STATUS}
                {activeTab == 8 ? (
                  <Icon
                    icon="ant-design:caret-up-filled"
                    width="20"
                    height="20"
                    style={{ color: "#CCFF00" }}
                  />
                ) : (
                  <Icon
                    icon="ant-design:caret-down-filled"
                    width="20"
                    height="20"
                    style={{ color: "#D9D9D9" }}
                  />
                )}
              </span>
            </th>
            <th className="!text-center text-[18px] font-semibold font-[Archivo] text-white px-2 py-1 w-[8%] justify-center items-center border-x border-[#444444] ">
              {strings.SENT_MSG}
            </th>
          </thead>
          {/* <tbody className="border-b border-[#444444] overflow-auto max-h-[calc(100vh_-_400px)] "> */}
          <tbody>
            {memberList?.length > 0 &&
              memberList?.map((item, index) => (
                <tr
                  key={index}
                  className={`${" justify-around items-center cursor-pointer"}`}
                >
                  {item.userProfile?.name ? (
                    <th className="!text-start text-base font-semibold font-[Archivo] text-white px-2 py-1 border-l border-[#444444]  justify-start items-center">
                      {/* <Icon
                        icon="codicon:debug-stackframe-dot"
                        width="24"
                        height="24"
                        style={{ color: "white" }}
                      /> */}
                      {item.userProfile?.name}
                    </th>
                  ) : (
                    <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1  border-l border-[#444444]">
                      - -
                    </th>
                  )}
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 border-x border-[#444444]  justify-center items-center">
                    {`${item.completeCnt}/${item.totalSession}`}
                  </th>
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1   justify-center items-center">
                    {item.userProfile.gymStaffName || "- -"}
                  </th>
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1  border-x border-[#444444]  justify-center items-center ">
                    {item.latestCompletedSession
                      ? `${moment(item.latestCompletedSession).format(
                          "YY.MM.DD"
                        )}`
                      : "- -"}
                    {item.recentSession ? `( +${item.recentSession}d)` : ""}
                  </th>
                  <th className="text-base font-semibold font-[Archivo] text-center text-white px-2  py-1 justify-center items-center">
                    {item.cancelCnt}
                  </th>
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 justify-center items-center border-x border-[#444444]">
                    {item.noShowCnt}
                  </th>
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 justify-center items-center">
                    {moment(item.membershipStartDate).format("YY.MM.DD")} -{" "}
                    {moment(item.membershipEndDate).format("YY.MM.DD")}
                  </th>
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 justify-center border-x border-[#444444] items-center">
                    {moment(item.userProfile.registrationDate).format(
                      "YY.MM.DD"
                    ) || "- -"}{" "}
                  </th>
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 justify-center items-center">
                    {!item.status ? "Expired" : "- -"}
                  </th>
                  <th className="!text-center text-base font-semibold font-[Archivo] text-white px-2 py-1 justify-center items-center border-x border-[#444444]">
                    {item.conversationExists ? (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setMessageList(true);
                          setmemberId(item?.userProfile?.memberId);
                        }}
                        className="text-black font-[Archivo] bg-[#CCFF00] text-base font-bold h-[28px] w-[100px] rounded-[28px]"
                      >
                        {strings.LIST}
                      </button>
                    ) : (
                      "- -"
                    )}
                  </th>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StatusMemberTable;
