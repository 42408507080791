import React, { useState } from "react";
import profile from "../../../assests/images/demoProfile.svg";
import { strings } from "../../../utils/String";
import moment from "moment";
import { Table } from "react-bootstrap";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import tickIcon from "../../../assests/icons/circule-tick-icon.svg";

function TrainerReqTable({
  activeTab,
  setActiveTab,
  fetchRequestList,
  setSortKey,
  setOrderValue,
  requestList,
  setProductId,
  productId,
  setshowModal,
}) {
  return (
    <div className="overflow-auto 2xl:max-h-[calc(100vh_-_300px)] max-2xl:max-h-[600px]">
      <div className="max-2xl:w-[1600px] staffListClass">
        <Table className="w-full !bg-transparent">
          <thead className="bg-[#353638] sticky top-[-1px] z-10">
            <th
              onClick={() => {
                setActiveTab(activeTab == 1 ? null : 1);
                let sort = activeTab == 1 ? "DESC" : "ASC";
                fetchRequestList(sort, "title");
                setSortKey(sort);
                setOrderValue("title");
              }}
              className="!text-center cursor-pointer !p-0 text-lg font-semibold text-white w-[25%] "
            >
              <div
                className={`${
                  activeTab == 1 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white  border-none flex items-center ps-4"}`}
              >
                {strings.TITLE}
                <Icon
                  icon={`${
                    activeTab == 1
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 1 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 2 ? null : 2);
                let sort = activeTab == 2 ? "DESC" : "ASC";
                fetchRequestList(sort, "name");
                setSortKey(sort);
                setOrderValue("name");
              }}
              className="!text-start text-lg  font-semibold text-white  w-[20%]"
            >
              <div
                className={`${
                  activeTab == 2 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white flex items-center border-none ps-4"}`}
              >
                {strings.NAME}
                <Icon
                  icon={`${
                    activeTab == 2
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 2 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 3 ? null : 3);
                let sort = activeTab == 3 ? "DESC" : "ASC";
                fetchRequestList(sort, "date");
                setSortKey(sort);
                setOrderValue("date");
              }}
              className="!text-center cursor-pointer !p-0 text-lg font-semibold text-white  w-[15%]"
            >
              <div
                className={`${
                  activeTab == 3 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white flex items-center justify-center ps-4 border-none"}`}
              >
                {strings.DATE}
                <Icon
                  icon={`${
                    activeTab == 3
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 3 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 4 ? null : 4);
                let sort = activeTab == 4 ? "DESC" : "ASC";
                fetchRequestList(sort, "currentTrainer");
                setSortKey(sort);
                setOrderValue("currentTrainer");
              }}
              className="!text-center cursor-pointer !p-0 text-lg font-semibold text-white  w-[20%]"
            >
              <div
                className={`${
                  activeTab == 4 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white flex items-center justify-center ps-4 border-none"}`}
              >
                {strings.CURRENT_TRAINER}
                <Icon
                  icon={`${
                    activeTab == 4
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 4 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
            <th
              onClick={() => {
                setActiveTab(activeTab == 5 ? null : 5);
                let sort = activeTab == 5 ? "DESC" : "ASC";
                fetchRequestList(sort, "nextTrainer");
                setSortKey(sort);
                setOrderValue("nextTrainer");
              }}
              className="!text-center cursor-pointer !p-0 text-lg font-semibold text-white  w-[20%] "
            >
              <div
                className={`${
                  activeTab == 5 ? "kakaoClass" : "border-l border-[#444444]"
                } ${"text-white justify-center flex items-center ps-4 border-none"}`}
              >
                {strings.NEXT_TRAINER}
                <Icon
                  icon={`${
                    activeTab == 5
                      ? "ant-design:caret-up-filled"
                      : "ant-design:caret-down-filled"
                  }`}
                  width="20"
                  height="20"
                  className="ms-3"
                  style={{
                    color: `${activeTab == 5 ? "#CCFF00" : "#D9D9D9"}`,
                  }}
                />
              </div>
            </th>
          </thead>
          <tbody>
            {requestList.length > 0 &&
              requestList?.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    setProductId(item.id);
                    setshowModal(true);
                  }}
                  className={`${
                    productId == item.id
                      ? "kakaoClass !border-t-2 border-[#CCFF00]"
                      : "border-y border-[#444444]"
                  } ${"cursor-pointer"}`}
                >
                  <td
                    className={` ${productId == item.id ? "!border-0" : ""}
                          ${"text-white font-bold text-lg relative p-0"}`}
                  >
                    <div className="!text-center text-lg font-bold text-white px-2 py-1 ps-4    flex flex-col  items-start ">
                      <p className=" m-0">
                        {item?.resFeedbackTitle ||
                          strings.REQUEST_FOR_CHANGING_TRAINER}
                      </p>
                      {item.status !== 0 && (
                        <p className=" mt-1 m-0 flex items-center">
                          {item.status == 3 && (
                            <Icon
                              icon="tdesign:refresh"
                              width="22px"
                              height="22px"
                              style={{ color: "#E16B16" }}
                            />
                          )}
                          {item.status == 1 && <img src={tickIcon} />}
                          {item.status == 3 ? (
                            <span className="ms-1 text-[22px] text-[#E16B16] font-extrabold">
                              {"(Processing)"}
                            </span>
                          ) : (
                            <span className="ms-1 text-[22px] text-[#05E700] font-extrabold">
                              {"(Complete)"}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  </td>
                  <td
                    className={`${
                      productId == item.id ? "!border-0" : ""
                    } ${"!text-center text-[18px] p-0 font-bold text-white "}`}
                  >
                    <div className="!text-center text-lg font-bold text-white px-2 py-1 ps-4 flex flex-col  items-start ">
                      <p className=" m-0">{item?.memberInfo?.name}</p>
                      {item.status !== 0 && (
                        <p className=" mt-1 m-0 flex items-center">
                          <Icon
                            icon="material-symbols:line-end-arrow"
                            width="25px"
                            height="25px"
                            style={{ color: "#CCFF00" }}
                          />
                          <span className="ms-1">{item.staffInfo?.name}</span>{" "}
                        </p>
                      )}
                    </div>
                  </td>
                  <td
                    className={`${
                      productId == item.id ? "!border-0" : ""
                    } ${"!text-center text-[18px] font-bold text-white "}`}
                  >
                    <div className="!text-center text-lg font-bold text-white px-2 py-1     ">
                      <p className=" m-0">
                        {moment(item?.requestDate).format("YYYY.MM.DD")}
                      </p>
                      {item.status !== 0 && item?.resFeedbackDate && (
                        <p className=" m-0">
                          {moment(item?.resFeedbackDate).format("YYYY.MM.DD")}
                        </p>
                      )}
                    </div>
                  </td>
                  <td
                    className={`${
                      productId == item.id ? "!border-0" : ""
                    } ${"!text-center text-[18px] font-bold text-white pl-3"}`}
                  >
                    {item?.currentTrainer?.name}
                  </td>
                  <td
                    className={`${
                      productId == item.id ? "!border-0" : ""
                    } ${"!text-center text-[18px] font-bold text-white "}`}
                  >
                    {item?.nextTrainerName ? (
                      <p className="mb-0 text-center">{item?.nextTrainerName}</p>
                    ) : (
                      <div className="flex items-center justify-center">
                        {item?.reqTrainers?.map((val, index) => (
                          <p className="mb-0 " key={index}>
                            {`${index !== 0 ? "," : ""}`} {val?.gymStaffName}
                          </p>
                        ))}
                      </div>
                    )}
                  </td>{" "}
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default TrainerReqTable;
