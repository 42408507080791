import React, { useEffect, useState } from "react";
import { strings } from "../../../../../../utils/String";
import SideBar from "../../../../../commonComponents/SideBar";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import Header from "../../../../../commonComponents/Header";
import { MembershipDropDown } from "../GymInfoScreen";
import watchIcon from "../../../../../../assests/icons/ptWatch-Icon.svg";
import EditInfoSettings from "./EditInfoSettings";
import GymSettingSublyApi from "../../../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import Loader from "../../../../../../utils/Loader";
import $ from "jquery";

function InformationSetting({ activeTab, showLock }) {
  const { token } = useSelector((state) => state.user);
  const [addLocker, setaddLocker] = useState(false);
  const [gymText, setGymText] = useState({
    str: "Information setting",
    val: 0,
  });
  const [showDropDown, setshowDropDown] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [infoData, setInfoData] = useState();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    !editInfo && getPtCancel();
  }, [editInfo]);
  async function getPtCancel() {
    setIsLoader(true);
    await GymSettingSublyApi.ptCancel(token).then((response) => {
      setInfoData(response.data);
      setIsLoader(false);
    });
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0
    ) {
      setEditInfo(false);
    }
  });

  return (
    <section>
      {isLoader && <Loader />}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col xl={10} className={`p-0 relative`}>
            <Header text={strings.INFORMATION_SETTING} />

            <div className="mx-6  ">
              {" "}
              <div className="flex items-center justify-between">
                <h1 className="my-4 text-white font-extrabold text-2xl">
                  {strings.INFORMATION_SETTING}
                </h1>
                <div>
                  <button
                    onClick={() => setEditInfo(true)}
                    className={`${"text-lg font-bold h-[50px] w-[120px]  rounded-[28px] bg-[#CCFF00] text-black kakaoClass"}`}
                  >
                    {strings.EDIT}
                  </button>
                </div>
              </div>
              <div className="bg-[#2C2C2E] p-3 rounded-[10px] flex items-center gap-x-2">
                <p className="text-lg font-bold !mb-0">
                  {strings.POSSIBLE_CANCEL_TIME_OF_PT}
                </p>
                <img src={watchIcon} />
                <p className="!mb-0 text-xl font-extrabold">
                  Before {infoData?.ptCancelHour} hours {infoData?.ptCancelMin}{" "}
                  min
                </p>
              </div>
            </div>
            {editInfo && (
              <EditInfoSettings
                onClose={setEditInfo}
                setIsLoader={setIsLoader}
                classNameValue={`${editInfo ? "block_notify" : "hideNotify"}`}
                infoData={infoData}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default InformationSetting;
