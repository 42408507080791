import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import { strings } from "../../../utils/String";
import Header from "../../commonComponents/Header";
import ReactDatePicker from "react-datepicker";
import { Icon } from "@iconify-icon/react";
import StatisticCards from "../../commonComponents/StatisticCards";
import staticImg from "../../../assests/icons/static.svg";
import money from "../../../assests/icons/moneySign.svg";
import SaleGraph from "../../commonComponents/modalScreens/SaleGraph";
import { useSelector } from "react-redux";
import StatisticsSublyApi from "../../../helper/StatisticsApi";
import Loader from "../../../utils/Loader";
import soldCount from "../../../assests/icons/sold count.svg";
import dollar from "../../../assests/icons/dollar-circle.svg";
import populer from "../../../assests/icons/populer icon.svg";
import { PRODUCT_TYPES } from "../../../utils/CommonData";
import ErrorModal from "../../commonComponents/modalScreens/ErrorModal";
import moment from "moment";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "../../../assests/Datepicker.css";

function Sales() {
  const { token } = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState("");
  const [openGraph, setOpenGraph] = useState(false);
  const [searchType, setSearchType] = useState("month");
  const [saleData, setSaleData] = useState("");
  const [memberData, setMemberData] = useState("");
  const [ptData, setPtData] = useState("");
  const [lockerData, setLockerData] = useState("");
  const [golfData, setGolfData] = useState("");
  const [gxData, setGxData] = useState("");
  const [isLoader, setIsLoader] = useState("");
  const [salesType, setSalesType] = useState("");
  const [graphValue, setGraphValue] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [graphgLoader, setGraphLoader] = useState(false);
  const [graphText, setGraphText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [currentEndMonth, setCurrentEndMonth] = useState(
    moment().format("YYYY.MM.DD")
  );
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  // console.log("-----metaGraph", metaGraph);
  // ============statistics sale API============
  useEffect(() => {
    if (searchType !== "") {
      fetchSaleHandle();
    }
  }, [searchType]);

  async function fetchSaleHandle(event) {
    setIsLoader(true);
    if (event) {
      const requestData = {
        searchType: searchType,
        startDate: startDate,
        endDate: endDate,
      };
      await StatisticsSublyApi.statisticsSaleList(requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            if (startDate || endDate) {
              setSearchType("");
            }
            setSaleData(response.data);
            const member = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "membership"
            );
            setMemberData(member);
            const pt = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "PT"
            );
            setPtData(pt);
            const locker = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "locker"
            );
            setLockerData(locker);
            const golf = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "golf"
            );
            setGolfData(golf);
            const gx = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "gx"
            );
            setGxData(gx);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const requestData = {
        searchType: searchType,
        startDate: "",
        endDate: "",
      };
      await StatisticsSublyApi.statisticsSaleList(requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setSaleData(response.data);
            setEndDate("");
            setStartDate("");
            const member = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "membership"
            );
            setMemberData(member);
            const pt = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "PT"
            );
            setPtData(pt);
            const locker = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "locker"
            );
            setLockerData(locker);
            const golf = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "golf"
            );
            setGolfData(golf);
            const gx = response.data.salesResponse.find(
              (element) => element.prodTypeValue == "gx"
            );
            setGxData(gx);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  // useEffect(() => {

  //   getMetaValue("soldCount",1,"total");
  // }, []);
  async function getGraphValue(type, filter) {
    setGraphLoader(true);
    await StatisticsSublyApi.statisticsSalesGraph(
      token,
      type,
      filter,
      startDate,
      endDate
    ).then((response) => {
      // console.log("-----respose", response);
      if (response.statusCode == 200) {
        setGraphLoader(false);
        setGraphValue(response.data);
      } else {
        setOpenModal(true);
        seterrorMessage(response.data.message);
      }
    });
  }
  async function getMetaValue(key, type, filter) {
    setGraphLoader(true);
    await StatisticsSublyApi.salesMetaGraph(
      token,
      key,
      type,
      filter,
      startDate,
      endDate
    ).then((response) => {
      if (response.statusCode == 200) {
        setGraphLoader(false);
        setGraphValue(response.data);
      } else {
        setOpenModal(true);
        seterrorMessage(response.data.message);
      }
    });
  }

  // const filterMonthDates = (date) => {
  //   const condition =
  //     new Date(date).getMonth() === new Date(currentMonth).getMonth();
  //   console.log(
  //     "---------DATE",
  //     condition,
  //     new Date(date).getMonth(),
  //     new Date(currentMonth).getMonth()
  //   );
  //   return condition;
  // };
  // const filterEndMonthDates = (date) => {
  //   const condition =
  //     new Date(date).getMonth() === new Date(currentEndMonth).getMonth();
  //   console.log(
  //     "---------DATE",
  //     condition,
  //     new Date(date).getMonth(),
  //     new Date(currentMonth).getMonth()
  //   );
  //   return condition;
  // };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
  }, [isCalenderOpen, currentMonth]);

  return (
    <section>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
      />
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col xl={10} className="p-0 relative overflow-auto max-h-screen">
            <Header text={strings.STATISTICS_SALES_DES} />
            <div className="mx-6 ">
              <div className="my-4 flex items-center justify-between gap-3 flex-wrap">
                <div className="flex items-center gap-2 flex-wrap">
                  <button
                    onClick={() => {
                      setSearchType("today");
                    }}
                    className={`${
                      searchType == "today"
                        ? "text-black! bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.TODAY}
                  </button>
                  <button
                    onClick={() => {
                      setSearchType("yesterday");
                    }}
                    className={`${
                      searchType == "yesterday"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${"text-base font-bold  kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.YESTERDAY}
                  </button>
                  <button
                    onClick={() => {
                      setSearchType("week");
                    }}
                    className={`${
                      searchType == "week"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${"text-base font-bold  kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.ONE_WEEK}
                  </button>
                  <button
                    onClick={() => {
                      setSearchType("month");
                    }}
                    className={`${
                      searchType == "month"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${"text-base font-bold kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.ONE_MONTH}
                  </button>
                  <button
                    onClick={() => {
                      setSearchType("last-month");
                    }}
                    className={`${
                      searchType == "last-month"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${" text-base font-bold  kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.LAST_MONTH}
                  </button>
                  <button
                    onClick={() => {
                      setSearchType("year");
                    }}
                    className={`${
                      searchType == "year"
                        ? "text-black bg-[#CCFF00]"
                        : "hover:text-black hover:bg-[#CCFF00] bg-[#2C2C2E] text-white"
                    } ${" text-base font-bold  kakaoClass h-[40px] w-[120px] rounded-[28px]"}`}
                  >
                    {strings.ONE_YEAR}
                  </button>
                </div>
                <div className="flex items-center gap-2 justify-end max-sm:justify-start max-sm:flex-wrap">
                  <div className="flex items-center gap-2 ml-10 dateInputClass pl-3 max-[390px]:-ml-6 max-[430px]:-ml-4 max-[360px]:-ml-8">
                    <Icon
                      icon="lets-icons:date-today"
                      width="30"
                      height="30"
                      style={{ color: "#CCFF00" }}
                    />
                    <div className="w-full flex flex-col md:flex-row gap-2">
                      <div className="newDatePickerClass">
                        <DatePicker
                          oneTap
                          id="datepicker-1"
                          placeholder="From-"
                          format="yyyy.MM.dd"
                          block
                          editable={false}
                          appearance="subtle"
                          className="custom-datepicker"
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          onChangeCalendarDate={(e) => {
                            setCurrentMonth(e);
                          }}
                          onNextMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onPrevMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onOpen={() => {
                            setIsCalenderOpen(true);
                            setCurrentMonth(
                              new Date(startDate ? startDate : new Date())
                            );
                            setDatePickerCheck(1);
                          }}
                          onClose={() => {
                            setIsCalenderOpen(false);
                            setCurrentMonth(
                              new Date(startDate ? startDate : new Date())
                            );
                            setDatePickerCheck("");
                          }}
                          disabledDate={(date) => {
                            const maxDate= new Date();
                            return date > maxDate;
                          }}
                        />
                      </div>
                      <div className="newDatePickerClass">
                        <DatePicker
                          oneTap
                          editable={false}
                          id="datepicker-2"
                          placeholder="To-"
                          format="yyyy.MM.dd"
                          block
                          appearance="subtle"
                          className="custom-datepicker"
                          onChange={(date) => setEndDate(date)}
                          onChangeCalendarDate={(e) => {
                            setCurrentMonth(e);
                          }}
                          value={endDate}
                          onNextMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onPrevMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onOpen={() => {
                            setIsCalenderOpen(true);
                            setCurrentMonth(
                              new Date(endDate ? endDate : new Date())
                            );
                            setDatePickerCheck(2);
                          }}
                          onClose={() => {
                            setIsCalenderOpen(false);
                            setDatePickerCheck("");
                            setCurrentMonth(
                              new Date(endDate ? endDate : new Date())
                            );
                          }}
                          disabledDate={(date) => {
                            const minDate = new Date(startDate);
                            const maxDate= new Date();
                            return date < minDate || date > maxDate;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => fetchSaleHandle(true)}
                    className="text-black bg-[#CCFF00] text-base font-bold h-[50px] w-[120px] rounded-[28px] max-[360px]:ml-10"
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              <div className="bg-[#353638] rounded-[10px] mb-3">
                <div className="pt-3 px-3 pb-4 border-b-2 border-[#444444]">
                  <p className="text-[26px] font-extrabold mb-2">
                    {strings.TOTAL}
                  </p>
                  <div
                    className="gap-2 grid grid-cols-5 max-[1650px]:grid-cols-4 max-[1350px]:grid-cols-3
                          max-[900px]:grid-cols-2 max-[600px]:grid-cols-1 overflow-auto webkit-scrollbar:w-[1px] pb-4 scrollingClass"
                  >
                    <div>
                      <StatisticCards
                        setGraphText={setGraphText}
                        isMember={true}
                        image={staticImg}
                        sale={true}
                        // clickBtn={setOpenGraph}
                        data={saleData?.totalSales}
                        text={strings.SALES}
                        setSalesType={setSalesType}
                        clickBtn={() => {
                          setOpenGraph(true);
                          getGraphValue(null, searchType);
                        }}
                        graphgLoader={graphgLoader}
                      />
                    </div>
                    {saleData?.arpu && (
                      <div>
                        <StatisticCards
                          setGraphText={setGraphText}
                          isMember={true}
                          image={money}
                          sale={true}
                          // clickBtn={setOpenGraph}
                          data={saleData?.totalSales}
                          text={strings.ARPU}
                          graphgLoader={graphgLoader}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="max-h-[calc(100vh_-_420px)] overflow-auto px-3">
                  {saleData?.salesResponse?.length > 0 &&
                    saleData?.salesResponse?.map((val, index) => {
                      return (
                        <>
                          {val?.prodTypeValue !== "PT" ? (
                            <div
                              key={index}
                              className="pt-3 pb-4 border-b-2 border-[#444444]"
                            >
                              <p className="text-[26px] font-extrabold mb-2">
                                {PRODUCT_TYPES[val?.prodTypeValue]}
                              </p>
                              <div
                                className="gap-2 grid grid-cols-5 max-[1650px]:grid-cols-4 max-[1350px]:grid-cols-3
                          max-[900px]:grid-cols-2 max-[600px]:grid-cols-1 overflow-auto webkit-scrollbar:w-[1px] pb-4 scrollingClass"
                              >
                                <div>
                                  <StatisticCards
                                    setGraphText={setGraphText}
                                    isMember={true}
                                    image={staticImg}
                                    sale={true}
                                    // clickBtn={setOpenGraph}
                                    data={val?.totalSales}
                                    text={strings.SALES}
                                    clickBtn={() => {
                                      setOpenGraph(true);
                                      // setSalesType(1);
                                      getGraphValue(val.prodType, searchType);
                                    }}
                                    graphgLoader={graphgLoader}
                                  />
                                </div>
                                <div>
                                  <StatisticCards
                                    setGraphText={setGraphText}
                                    isMember={true}
                                    // addStyle={true}
                                    image={soldCount}
                                    data={val?.soldCount}
                                    text={strings.SOLD_COUNT}
                                    graphgLoader={graphgLoader}
                                    clickBtn={() => {
                                      setOpenGraph(true);
                                      // setSalesType(1);
                                      getMetaValue(
                                        "soldCount",
                                        val.prodType,
                                        searchType
                                      );
                                    }}
                                  />
                                </div>
                                <div>
                                  <StatisticCards
                                    setGraphText={setGraphText}
                                    isMember={true}
                                    image={money}
                                    // addStyle={true}
                                    data={val?.repurchaseCount}
                                    text={strings.REPURCHASE_COUNT}
                                    graphgLoader={graphgLoader}
                                    clickBtn={() => {
                                      setOpenGraph(true);
                                      // setSalesType(1);
                                      getMetaValue(
                                        "repurchaseCount",
                                        val.prodType,
                                        searchType
                                      );
                                    }}
                                  />
                                </div>
                                <div>
                                  <StatisticCards
                                    setGraphText={setGraphText}
                                    isMember={true}
                                    image={populer}
                                    data={
                                      <p className="text-white text-xl font-extrabold">
                                        {val?.prodName}
                                      </p>
                                    }
                                    text={strings.MOST_BUY_PRODUCT}
                                    // addStyle={true}
                                    graphgLoader={graphgLoader}
                                    clickBtn={() => {
                                      setOpenGraph(true);
                                      // setSalesType(1);
                                      getMetaValue(
                                        "prodName",
                                        val.prodType,
                                        searchType
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="pt-3 pb-4 border-b-2 border-[#444444]">
                              <p className="text-[26px] font-extrabold mb-2">
                                {strings.PT_SESSIONS}
                              </p>
                              <div
                                className="gap-2 grid grid-cols-5 max-[1650px]:grid-cols-4 max-[1350px]:grid-cols-3
                          max-[900px]:grid-cols-2 max-[600px]:grid-cols-1 overflow-auto webkit-scrollbar:w-[1px] pb-4 scrollingClass"
                              >
                                <div>
                                  <StatisticCards
                                    setGraphText={setGraphText}
                                    image={dollar}
                                    sale={true}
                                    isMember={true}
                                    text={strings.PT_SESSIONS}
                                    data={val?.totalSales}
                                    graphgLoader={graphgLoader}
                                    clickBtn={() => {
                                      setOpenGraph(true);
                                      // setSalesType(1);
                                      getGraphValue(val.prodType, searchType);
                                      setPopUpText("PT Sessions");
                                    }}
                                  />
                                </div>
                                <div>
                                  <StatisticCards
                                    setGraphText={setGraphText}
                                    isMember={true}
                                    image={staticImg}
                                    // session={strings.SESSION}
                                    data={val?.prodName}
                                    text={strings.MOST_SALE}
                                    graphgLoader={graphgLoader}
                                    clickBtn={() => {
                                      setOpenGraph(true);
                                      // setSalesType(1);
                                      getMetaValue(
                                        "prodName",
                                        val.prodType,
                                        searchType
                                      );
                                    }}
                                  />
                                </div>
                                <div>
                                  <StatisticCards
                                    setGraphText={setGraphText}
                                    isMember={true}
                                    image={soldCount}
                                    // session={strings.SESSION}
                                    data={val?.soldCount}
                                    text={strings.BUYER_COUNT}
                                    graphgLoader={graphgLoader}
                                    clickBtn={() => {
                                      setOpenGraph(true);
                                      // setSalesType(1);
                                      getMetaValue(
                                        "soldCount",
                                        val.prodType,
                                        searchType
                                      );
                                    }}
                                  />
                                </div>
                                <div>
                                  <StatisticCards
                                    setGraphText={setGraphText}
                                    isMember={true}
                                    image={populer}
                                    data={val?.prodName}
                                    text={strings.MOST_POPULAR}
                                    graphgLoader={graphgLoader}
                                    clickBtn={() => {
                                      setOpenGraph(true);
                                      // setSalesType(1);
                                      getMetaValue(
                                        "prodName",
                                        val.prodType,
                                        searchType
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <SaleGraph
        image={staticImg}
        text={graphText}
        openGraph={openGraph}
        setOpenGraph={setOpenGraph}
        graphValue={graphValue}
        graphgLoader={graphgLoader}
      />
    </section>
  );
}

export default Sales;
