import React from "react";
import { formatPrice } from "../../utils/commonUtils";

function StatisticCards({
  image,
  clickBtn,
  addStyle,
  isMember,
  data,
  text,
  sale,
  session,
  subText,
  isPercent,
  isAge,
  confirmSession,
  unconfirmSession,
  setSalesType,
  setGraphText,
  unconfirmStyle,
}) {
  return (
    <>
      <div
        onClick={() => {
          clickBtn && clickBtn(true);
          setGraphText(text);
        }}
        className={`${
          isMember ? "bg-[#2C2C2E]" : "bg-[#353638]"
        } ${"py-3 px-2.5  rounded-[10px] min-h-[147px] h-full"}`}
      >
        <div
          className={`${
            addStyle ? "mb-2" : unconfirmStyle ? "mb-0" : "mb-4"
          } flex items-center  mb-0 gap-3`}
        >
          <img className="w-8 h-8" src={image} />
          <p className="mb-0 text-xl font-bold">{text}</p>
        </div>
        {isAge ? (
          <div className="flex items-center gap-2 overflow-auto mt-3">
            {data?.map((items, index) => (
              <div
                key={index}
                className="p-1.5 text-base font-bold block rounded-[2px] w-full"
                style={{ border: "1px solid #616163" }}
              >
                <p className="mb-0">~{items.age}</p>
                <span className="text-white">
                  {items.percentage.toFixed(0)}%
                </span>
              </div>
            ))}
          </div>
        ) : unconfirmSession || confirmSession ? (
          <p
            className={`   ${
              unconfirmSession && "text-[#FFB9B9]"
            } ${"mb-0 text-[30px] font-extrabold "}`}
          >
            {data ? (!isNaN(data) ? formatPrice(data) : data) : 0}
            <span className={`${"text-white font-extrabold text-[24px] "}`}>
              /{subText && subText}
            </span>
          </p>
        ) : (
          <p className={`${"mb-0 text-[30px] font-extrabold "}`}>
            {sale && "₩"}
            {data ? (!isNaN(data) ? formatPrice(data) : data) : 0}
            {session && session}
            <span className="text-white font-extrabold text-[24px]">
              {" "}{subText && subText}
            </span>
            {isPercent && `/ ${isPercent}`}
          </p>
        )}
      </div>
    </>
  );
}

export default StatisticCards;
