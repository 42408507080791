import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react";
import trainer from "../../../assests/icons/trainerIcon.svg";
import DietCards from "../../commonComponents/DietCards";
import PT_InformationSublyApi from "../../../helper/PT-informationAPI";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import ReactPaginate from "react-paginate";
import DietLogDetail from "./DietLogDetail";
import { scrollToTopFun } from "../gymSettings/StaffSetting";
import { alphaOnly, numberOnly } from "../../../utils/commonUtils";
import $ from "jquery";

function DietLog() {
  const { token } = useSelector((state) => state.user);
  const [dietlogData, setDietlogData] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [active, setActive] = useState("active");
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [trainerList, setTrainerList] = useState("");
  const [openTrainer, setOpenTrainer] = useState(false);
  const [trainerValue, setTrainerValue] = useState({ id: "", name: "All" });
  const [openDetail, setOpenDetail] = useState(false);
  const [detailsData, setDetailsData] = useState({
    id: "",
    date: "",
    name: "",
    rate: "",
    img: "",
  });

  // ===============fetching diet log API================
  useEffect(() => {
    fetchDietlog();
  }, [offset]);

  useEffect(() => {
    fetchDietlog(false, trainerValue.id);
  }, [trainerValue]);

  async function fetchDietlog(isSearch, value) {
    if (isSearch || value) {
      setOffset(1);
    }
    setIsLoader(true);
    await PT_InformationSublyApi.dietLogList(
      token,
      isSearch || value ? 1 : offset,
      name,
      phone,
      trainerValue?.id
    ).then((response) => {
      setIsLoader(false);
      if (response.statusCode == 200) {
        setDietlogData(response.data.response);
        setTrainerList(response.data.trainerList);
        setPage(response.data.count / 10);
      }
    });
  }

  //--------function for handling name input filed------
  $("#nameId").bind("keypress", alphaOnly);

  //--------function for handling contact input filed------
  $("#numberId").bind("keypress", numberOnly);

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // ====function for disable value controlling through scrolling====

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("numberField")
    ) {
      document.activeElement.blur();
    }
  });

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setOpenTrainer(false);
      setOpenDetail(false);
    }
  });

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`${
              openDetail
                ? "!overflow-hidden h-screen"
                : "!overflow-auto  h-screen"
            } ${"p-0 relative"}`}
          >
            <Header text={strings.PT_INFORMATION_DIET_LOG} />
            <div className="mx-6 relative">
              <div className="flex items-center gap-3 flex-wrap my-4">
                <div className="flex items-center justify-between gap-3 flex-wrap">
                  <label
                    htmlFor="nameId"
                    className="bg-[#2C2C2E] w-[310px] max-sm:w-full h-[50px] px-3 flex items-center gap-2 rounded-[30px]"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#A1A1AA] font-bold text-lg whitespace-nowrap">
                      <Icon
                        icon="majesticons:user-line"
                        width="24"
                        height="24"
                        style={{ color: "white" }}
                      />
                    </p>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      className="bg-[#2C2C2E] text-white font-bold"
                      id="nameId"
                      placeholder={placeholder.NAME}
                      autoComplete="off"
                    />
                  </label>
                  <label
                    htmlFor="numberId"
                    className="bg-[#2C2C2E] w-[310px] max-sm:w-full h-[50px] px-3 flex items-center gap-2 rounded-[30px] numberFieldClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#A1A1AA] font-bold text-lg whitespace-nowrap">
                      <Icon
                        icon="ph:phone"
                        width="24"
                        height="24"
                        style={{ color: "white" }}
                      />
                    </p>
                    <input
                      type="number"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      className="bg-[#2C2C2E] text-white font-bold numberField"
                      id="numberId"
                      placeholder={placeholder.CELL_PHONE}
                      autoComplete="off"
                    />
                  </label>
                  <label
                    onClick={() => {
                      setOpenTrainer(!openTrainer);
                    }}
                    className="bg-[#2C2C2E] w-[310px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] relative dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] font-bold text-lg whitespace-nowrap">
                      <img
                        src={trainer}
                        alt="trainer"
                        className="w-[22px] h-[22px]"
                      />
                      {trainerValue?.name}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{ color: "#D9D9D9" }}
                      rotate={openTrainer ? 2 : 4}
                    />
                    {openTrainer && (
                      <TrainerDropdown
                        trainerList={trainerList}
                        setTrainerValue={setTrainerValue}
                      />
                    )}
                  </label>
                </div>
                <button
                  onClick={() => {
                    fetchDietlog(true);
                  }}
                  className="hover:text-black hover:bg-[#CCFF00] text-lg font-extrabold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                >
                  {strings.SEARCH}
                </button>
              </div>
              {dietlogData?.length == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-10">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              <Row className="overflow-auto max-h-[calc(100vh_-_300px)]">
                {dietlogData?.length > 0
                  ? dietlogData?.map((items, index) => (
                      <Col
                        lg={6}
                        xxl={4}
                        // xl={4}
                        className="my-2.5"
                        key={index}
                        onClick={() => {
                          setOpenDetail(true);
                          setDetailsData({
                            id: items.memberInfo.memberId,
                            date: items.date,
                            name: items.memberInfo.name,
                            rate: items?.avgRating,
                            img: items?.memberInfo?.mProfileImg,
                          });
                          scrollToTopFun();
                        }}
                      >
                        <DietCards itemValue={items} />
                      </Col>
                    ))
                  : ""}
              </Row>

              {page && page > 1 && (
                <div className="mt-6 border-t-2 border-[#444444] pt-8">
                  {" "}
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    forcePage={offset == 1 ? 0 : offset - 1}
                    pageCount={page}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={active}
                    onClick={() => setActive("active")}
                  />
                </div>
              )}
            </div>
            {openDetail && (
              <DietLogDetail
                setOpenDetail={setOpenDetail}
                detailsData={detailsData}
                setIsLoader={setIsLoader}
                classNameValue={`${openDetail ? "block_notify" : "hideNotify"}`}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DietLog;

function TrainerDropdown({ trainerList, setTrainerValue }) {
  return (
    <div
      style={{ border: "1px solid #686868" }}
      className="absolute rounded-[10px] bg-[#353638] min-w-[160px] right-4 top-8 max-h-[200px] px-2 overflow-auto"
    >
      <div className="text-white text-lg font-bold py-2 trainerList">
        <p
          onClick={() => {
            setTrainerValue({ id: "", name: "ALL" });
          }}
          className="hover:bg-[#686868] py-1 mb-0 flex items-center gap-0.5 px-2"
        >
          {/* <Icon
            icon="radix-icons:dot-filled"
            width="20"
            height="20"
            style={{ color: "white" }}
          /> */}
          {strings.ALL}
        </p>
        {trainerList?.length > 0 &&
          trainerList?.map((item, index) => (
            <p
              key={index}
              onClick={() => {
                setTrainerValue({ id: item.id, name: item.name });
              }}
              className="hover:bg-[#686868] py-1 mb-0 flex items-center gap-0.5 px-2"
            >
              {/* <Icon
                icon="radix-icons:dot-filled"
                width="20"
                height="20"
                style={{ color: "white" }}
              /> */}
              {item.name}
            </p>
          ))}
      </div>
    </div>
  );
}
