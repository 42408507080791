import React, { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import Header from "../../../commonComponents/Header";
import { Col, Container, Row, Table } from "react-bootstrap";
import { placeholder, strings } from "../../../../utils/String";
import SideBar from "../../../commonComponents/SideBar";
import { Icon } from "@iconify-icon/react";
import moment from "moment";
import Loader from "../../../../utils/Loader";
import { useSelector } from "react-redux";
import SearchMemberModal from "./SearchMemberModal";
import $ from "jquery";
import cashIcon from "../../../../assests/icons/message-setting-icons/cashIcon.svg";
import talk from "../../../../assests/icons/message-setting-icons/talkIcon.svg";
import message from "../../../../assests/icons/message-setting-icons/messageIcon.svg";
import info from "../../../../assests/icons/message-setting-icons/infoIcon.svg";
import { Tooltip } from "react-tooltip";
import MessageRegister from "./MessageRegister";
import DetailMessageScreen from "./DetailMessageScreen";
import GymSettingSublyApi from "../../../../helper/GymSettingApi";
import FundsModal from "./FundsModal";

export const writingTypes = {
  0: "Center Notification",
  1: "Event Notice",
  2: "Individual Dispatch ",
};

export const sendingTarget = {
  0: "Memberships",
  1: "PT Members",
  2: "All Members",
  3: "Individual",
};

// CENTER_NOTIFICATION,
// EVENT_NOTICE,
// INDIVIDUAL_DISPATCH,

function MessageSetting() {
  const { token } = useSelector((state) => state.user);
  const { role } = useSelector((state) => state.user.selectedGym);
  const [value, setValue] = useState({ text: "All" });
  const [typeFilter, setTypeFilter] = useState(false);
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [openRegister, setOpenRegister] = useState(false);
  const [checkedMembers, setCheckedMembers] = useState([]);
  const [detailScreen, setDeatlScreen] = useState(false);
  const [messageData, setMessageData] = useState();
  const [active, setActive] = useState("active");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState();
  const [messageId, setMessageId] = useState("");
  const [defaultValue, setDefaultValue] = useState();

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      if (openRegister && openModal) {
        setOpenRegister(true);
        setOpenModal();
      } else {
        setOpenModal();
        setOpenRegister(false);
      }
      setTypeFilter(false);
      setDeatlScreen(false);
      setMessageId("");
    }
  });

  useEffect(() => {
    !openRegister && !detailScreen && getMessageList();
  }, [openRegister, offset, filter, detailScreen]);

  async function getMessageList(sortingKey, sortingOrder) {
    setIsLoader(true);
    await GymSettingSublyApi.fetchMessagerList(
      token,
      sortingKey,
      sortingOrder,
      offset,
      search,
      filter
    ).then((response) => {
      setIsLoader(false);
      // console.log("-------response", response);
      if (response?.statusCode == 200) {
        setMessageData(response?.data);
        setPage(response.data.count / 10);
      }
    });
  }


  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Container fluid>
        <Row>
          <Col xl={2} className="p-0">
            <SideBar />
          </Col>
          <Col
            xl={10}
            className={`${
              openRegister || detailScreen || openModal
                ? "!overflow-hidden h-screen"
                : "!overflow-auto"
            } ${"p-0 relative"}`}
          >
            <Header
              text={strings.GYM_SETTING_MESSAGE_SETTING}
              currentTime={true}
            />
            <div className="mx-6">
              <div className="flex items-center justify-between gap-3 py-3 flex-wrap">
                <div className="flex items-center gap-3 flex-wrap max-sm:w-full">
                  <label
                    onClick={() => setTypeFilter(!typeFilter)}
                    className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 py-1 rounded-[30px] dropdownClass"
                  >
                    <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap ">
                      {value.text}
                    </p>
                    <Icon
                      icon="icon-park-solid:down-one"
                      width="20"
                      height="20"
                      style={{
                        color: "#D9D9D9",
                        cursor: "pointer",
                        transform: typeFilter
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.01s ease",
                      }}
                    />
                    {typeFilter && (
                      <TypeFilter setValue={setValue} setFilter={setFilter} />
                    )}
                  </label>
                </div>
                <div className="flex items-center gap-3 flex-wrap max-sm:w-full">
                  <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Keywords"
                      className="bg-transparent w-full text-white text-lg font-bold"
                    />
                  </label>
                  <button
                    className="hover:text-black font-[archivo] max-sm:w-full hover:bg-[#CCFF00] text-lg font-bold text-white kakaoClass rounded-[30px] h-[50px] w-[120px]"
                    onClick={() => {
                      getMessageList();
                    }}
                  >
                    {strings.SEARCH}
                  </button>
                </div>
              </div>
              {role == 2 && (
                <div className="flex items-center gap-4 max-xl:flex-wrap h-[200px] max-xl:h-full my-4">
                  <ChargedCashModal cashCount={messageData?.cash} />
                  <MessagesAvailable messageCount={messageData} />
                </div>
              )}
              {messageData?.messageList?.length == 0 && (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
              {messageData?.messageList?.length > 0 && (
                <div className="overflow-auto mt-4">
                  <div
                    className={`${
                      messageId ? "classForHideBorder" : "suggestiontableClass"
                    } ${"max-2xl:w-[1300px]  overflow-auto max-h-[calc(100vh_-_310px)] relative"}`}
                  >
                    <Table className="w-full !bg-transparent mb-0">
                      <thead className="bg-[#353638] sticky top-[-1px] z-10">
                        <th className="!text-center text-[20px] font-semibold text-white w-[6%] py-1">
                          <div
                            className={`${"border-l border-[#444444]"} ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.ID}
                            <Icon
                              icon={`${"ant-design:caret-down-filled"}`}
                              width="20"
                              height="20"
                              className="ms-3"
                            />
                          </div>
                        </th>
                        <th className="!text-center text-[20px] px-4 font-semibold text-white w-[15%] py-1 cursor-pointer">
                          <div
                            onClick={() => {
                              setActiveTab(activeTab == 1 ? null : 1);
                              let sort = activeTab == 1 ? "DESC" : "ASC";
                              getMessageList("staffName", sort);
                            }}
                            className={`${
                              activeTab == 1
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.TITLE}
                            <Icon
                              icon={`${
                                activeTab == 1
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 1 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[35%] py-1 cursor-pointer">
                          <div
                            onClick={() => {
                              setActiveTab(activeTab == 2 ? null : 2);
                              let sort = activeTab == 2 ? "DESC" : "ASC";
                              getMessageList("content", sort);
                            }}
                            className={`${
                              activeTab == 2
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.CONTENT}{" "}
                            <Icon
                              icon={`${
                                activeTab == 2
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 2 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[10%] py-1 cursor-pointer">
                          <div
                            onClick={() => {
                              setActiveTab(activeTab == 3 ? null : 3);
                              let sort = activeTab == 3 ? "DESC" : "ASC";
                              getMessageList("staffName", sort);
                            }}
                            className={`${
                              activeTab == 3
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {placeholder.NAME}{" "}
                            <Icon
                              icon={`${
                                activeTab == 3
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 3 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[8%] py-1 cursor-pointer">
                          <div
                            onClick={() => {
                              setActiveTab(activeTab == 4 ? null : 4);
                              let sort = activeTab == 4 ? "DESC" : "ASC";
                              getMessageList("sentDate", sort);
                            }}
                            className={`${
                              activeTab == 4
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.DATE}{" "}
                            <Icon
                              icon={`${
                                activeTab == 4
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 4 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[12%] py-1 cursor-pointer">
                          <div
                            onClick={() => {
                              setActiveTab(activeTab == 5 ? null : 5);
                              let sort = activeTab == 5 ? "DESC" : "ASC";
                              getMessageList("writingType", sort);
                            }}
                            className={`${
                              activeTab == 5
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.WRITING_TYPE}{" "}
                            <Icon
                              icon={`${
                                activeTab == 5
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 5 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[10%] py-1 cursor-pointer">
                          <div
                            onClick={() => {
                              setActiveTab(activeTab == 6 ? null : 6);
                              let sort = activeTab == 6 ? "DESC" : "ASC";
                              getMessageList("targetType", sort);
                            }}
                            className={`${
                              activeTab == 6
                                ? "kakaoClass"
                                : "border-l border-[#444444]"
                            } ${"text-white flex items-center ps-4 border-none justify-center"}`}
                          >
                            {strings.TARGET}
                            <Icon
                              icon={`${
                                activeTab == 6
                                  ? "ant-design:caret-up-filled"
                                  : "ant-design:caret-down-filled"
                              }`}
                              width="20"
                              height="20"
                              className="ms-3"
                              style={{
                                color: `${
                                  activeTab == 6 ? "#CCFF00" : "#D9D9D9"
                                }`,
                              }}
                            />
                          </div>
                        </th>
                        <th className="!text-center text-[20px] font-semibold text-white w-[15%] py-1 cursor-pointer">
                          <div
                            className={`
                              "border-l border-[#444444]"
                           ${"text-white flex items-center px-4 border-none justify-center"}`}
                          >
                            {strings.STATUS}
                          </div>
                        </th>
                      </thead>
                      <tbody className="">
                        {messageData?.messageList?.map((items, index) => {
                          return (
                            <tr
                              onClick={() => {
                                setDeatlScreen(true);
                                setMessageId(items.id);
                              }}
                              key={index}
                              className={`${
                                messageId == items.id ? "newActiveBorder" : ""
                              } ${"cursor-pointer"}`}
                            >
                              <td
                                align="center"
                                className={` 
                          ${"text-white font-bold text-lg relative"}`}
                              >
                                {10 * (offset - 1) + (index + 1)}
                              </td>
                              <td
                                align="start"
                                className="!text-start text-[18px] px-4 font-bold text-white "
                              >
                                {items?.title}{" "}
                              </td>
                              <td className="!text-center text-[18px] font-bold text-white ">
                                {items?.content}
                              </td>
                              <td className="!text-center text-[18px] font-bold text-white ">
                                {items?.staffName}
                              </td>
                              <td className="!text-center text-[18px] font-bold text-white ">
                                <p className="mb-0 text-lg font-bold">
                                  {moment(items.sentDate).format("YY.MM.DD")}
                                </p>
                              </td>
                              <td className="!text-center text-[18px] font-bold text-white ">
                                {writingTypes[items.writingType]}
                              </td>
                              <td className="!text-center text-[18px] font-bold text-white ">
                                {items.targetType == 3
                                  ? `${items?.targetMembers[0]?.name} +${
                                      items?.targetMembers?.length - 1
                                    }`
                                  : sendingTarget[items.targetType]}
                              </td>
                              {items.status ? (
                                <td className="!text-center text-[18px] font-bold text-white ">
                                  {" "}
                                  {strings.SENT}
                                </td>
                              ) : (
                                <td className="!text-center text-[18px] font-bold text-[#FFB9B9] ">
                                  {strings.FAILED}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
              <div className="flex items-center gap-1 py-4 border-b border-[#444444]">
                <div className="w-full">
                  {page && page > 1 && (
                    <div className="mt-3">
                      {" "}
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        forcePage={offset == 1 ? 0 : offset - 1}
                        pageCount={page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
                <button
                  onClick={() => {
                    setOpenRegister(true);
                    setCheckedMembers([]);
                    setDefaultValue("");
                  }}
                  className="text-black bg-[#CCFF00] text-lg font-extrabold rounded-[30px] h-[50px] w-[160px]"
                >
                  {strings.WRITE}
                </button>
              </div>
            </div>
            {openModal && (
              <SearchMemberModal
                setIsLoader={setIsLoader}
                classNameValue={`${
                  openModal || openRegister ? "block_notify" : "hideNotify"
                }`}
                setOpenModal={setOpenModal}
                setCheckedMembers={setCheckedMembers}
                setOpenRegister={setOpenRegister}
                checkedMembers={checkedMembers}
              />
            )}
            {openRegister && (
              <MessageRegister
                setOpenRegister={setOpenRegister}
                checkedMembers={checkedMembers}
                setCheckedMembers={setCheckedMembers}
                setOpenModal={setOpenModal}
                setIsLoader={setIsLoader}
                classNameValue={`${
                  openRegister ? "block_notify" : "hideNotify"
                }`}
                defaultValue={defaultValue}
                setDefaultValue={setDefaultValue}
                id={messageId}
                setMessageId={setMessageId}
              />
            )}
            {detailScreen && (
              <DetailMessageScreen
                detailScreen={detailScreen}
                classNameValue={`${
                  detailScreen ? "block_notify" : "hideNotify"
                }`}
                setDeatlScreen={setDeatlScreen}
                id={messageId}
                setMessageId={setMessageId}
                setIsLoader={setIsLoader}
                setOpenRegister={setOpenRegister}
                setDefaultValue={setDefaultValue}
              />
            )}
          </Col>
        </Row>
      </Container>
      {/* <FundsModal /> */}
      {/* <MessageConfirmModal/> */}
    </section>
  );
}

export default MessageSetting;

const writingType = [
  "Center notification",
  "Event notice",
  "individual dispatch",
];

function TypeFilter({ setValue, setFilter }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[210px] right-4 top-14 max-h-[250px] overflow-auto z-10 px-3 py-2">
      <div
        onClick={() => {
          setValue({ text: "All" });
          setFilter();
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>

      {writingType.map((val, index) => (
        <div
          key={index}
          onClick={() => {
            setValue({ text: val });
            setFilter(index);
          }}
          className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
        >
          <span className="mb-0 text-lg font-bold">{val}</span>
        </div>
      ))}
    </div>
  );
}

// ===============Messages card design============

function ChargedCashModal({ cashCount }) {
  return (
    <div className="bg-[#353638] rounded-[12px] flex items-start justify-center h-full w-[36%] max-[1800px]:w-[40%] max-[1650px]:w-[50%] max-xl:w-full">
      <div className="py-4 px-5">
        <p className="mb-4 text-[30px] font-bold ">
          {strings.AMOUNT_OF_CHARGED_CASH}
        </p>
        <div className="flex items-center justify-center gap-3 mt-2">
          <img src={cashIcon} alt="cash" />
          <p className="mb-0 text-[33px] font-extrabold">{cashCount} KRW</p>
        </div>
      </div>
    </div>
  );
}

function MessagesAvailable({ messageCount }) {
  return (
    <div className="bg-[#353638] rounded-[12px] h-full w-[36%] max-[1800px]:w-[40%]  max-[1650px]:w-[50%] max-xl:w-full">
      <div className="">
        <div className="relative">
          <p className="mb-1 text-[30px] font-bold text-center pt-3 w-full">
            {strings.MESSAGE_AVAILABLE}
          </p>
          <span className="w-[10%] flex items-center justify-center pt-3 absolute right-0 top-0">
            <img
              src={info}
              alt="icon"
              data-tooltip-id="my-tooltip"
              className="cursor-pointer"
            />
          </span>
          <Tooltip
            id="my-tooltip"
            className="bg-[#5C5C5C] rounded-[10px]"
            place="top-start"
            opacity="1"
          >
            <div className="bg-[#5C5C5C]">
              <img src={talk} alt="cash" className="m-auto py-2" />
              <p className="text-base font-extrabold max-w-[320px] text-center mb-2">
                Messages will be sent via KakaoTalk first. If a member does not
                have KakaoTalk installed, the message will be sent to their
                mobile phone number instead.
              </p>
            </div>
          </Tooltip>
        </div>
        <div className="flex items-center justify-center">
          {" "}
          <div>
            {" "}
            <div className="flex items-center justify-start gap-3 mt-2 px-5">
              <img src={talk} alt="cash" />
              <p className="mb-0 text-[33px] font-extrabold">
                <span className="font-normal">Kakao Messages:</span>{" "}
                {messageCount?.kakao}
              </p>
            </div>
            <div className="flex items-center justify-start gap-3 pb-3 px-5">
              <img src={message} alt="cash" />
              <p className="mb-0 text-[33px] font-extrabold">
                <span className="font-normal">SMS Messages:</span>{" "}
                {messageCount?.sms}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
