import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import demoP from "../../../../../assests/images/demoProfile.svg";
import ReactPaginate from "react-paginate";
import status from "../../../../../assests/icons/status.svg";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import moment from "moment";

function LockerHistory({
  onClose,
  lockerId,
  setIsLoader,
  setLockerId,
  classNameValue,
}) {
  const { token } = useSelector((state) => state.user);
  const [historyData, setHistoryData] = useState();
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [active, setActive] = useState("active");
  const [activeTab, setActiveTab] = useState();
  const [sortKey, setSortKey] = useState("");
  const [orderValue, setOrderValue] = useState("");

  useEffect(() => {
    getLockerHistory();
  }, []);
  async function getLockerHistory(order, key) {
    setIsLoader(true);
    await GymSettingSublyApi.lockerHistory(token, lockerId, order, key, offset)
      .then((response) => {
        // console.log("-----RESPONSE", response);
        if (response.statusCode == 200) {
          setPage(response.data.count / 10);
          setHistoryData(response.data);
          setIsLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  }
  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };
  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[80%] max-h-[92%] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-10 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[90%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.LOCKER_HISTORY}
            </p>
          </div>

          <div className="overflow-auto  mt-4">
            <div className=" staffListClass">
              {historyData?.getLockerHistory.length !== 0 ? (
                <Table className="w-full !bg-transparent">
                  <thead className="bg-[#474749] sticky top-[-1px] z-10 font-bold h-[50px]">
                    <th className="!text-center text-lg font-semibold text-white w-[5%] ">
                      <div className="text-white  border-none flex items-center ps-4">
                        {strings.NO}
                      </div>
                    </th>
                    <th
                      className="!text-start text-lg  font-semibold text-white  w-[25%]"
                      onClick={() => {
                        setActiveTab(activeTab == 1 ? null : 1);
                        let sort = activeTab == 1 ? "DESC" : "ASC";
                        getLockerHistory(sort, "memberName");
                        setSortKey(sort);
                        setOrderValue("memberName");
                      }}
                    >
                      <div
                        className={`${
                          activeTab == 1
                            ? "kakaoClass"
                            : "border-l border-[#444444]"
                        }${` text-white flex items-center border-none ps-4`}`}
                      >
                        {strings.USER_NAME1}
                        <Icon
                          icon={`${
                            activeTab == 1
                              ? "ant-design:caret-up-filled"
                              : "ant-design:caret-down-filled"
                          }`}
                          width="20"
                          height="20"
                          className="ms-3"
                          style={{
                            color: `${activeTab == 1 ? "#CCFF00" : "#D9D9D9"}`,
                          }}
                        />
                      </div>
                    </th>
                    <th
                      className="!text-center text-lg font-semibold text-white  w-[20%]"
                      onClick={() => {
                        setActiveTab(activeTab == 2 ? null : 2);
                        let sort = activeTab == 2 ? "DESC" : "ASC";
                        getLockerHistory(sort, "startDate");
                        setSortKey(sort);
                        setOrderValue("startDate");
                      }}
                    >
                      <div
                        className={`${
                          activeTab == 2
                            ? "kakaoClass"
                            : "border-l border-[#444444]"
                        }${` text-white flex items-center justify-center ps-4 border-none`}`}
                      >
                        {strings.START_DATE}
                        <Icon
                          icon={`${
                            activeTab == 2
                              ? "ant-design:caret-up-filled"
                              : "ant-design:caret-down-filled"
                          }`}
                          width="20"
                          height="20"
                          className="ms-3"
                          style={{
                            color: `${activeTab == 2 ? "#CCFF00" : "#D9D9D9"}`,
                          }}
                        />
                      </div>
                    </th>
                    <th
                      className="!text-center text-lg font-semibold text-white  w-[20%]"
                      onClick={() => {
                        setActiveTab(activeTab == 3 ? null : 3);
                        let sort = activeTab == 3 ? "DESC" : "ASC";
                        getLockerHistory(sort, "startDate");
                        setSortKey(sort);
                        setOrderValue("startDate");
                      }}
                    >
                      <div
                        className={`${
                          activeTab == 3
                            ? "kakaoClass"
                            : "border-l border-[#444444]"
                        }${` text-white flex items-center justify-center ps-4 border-none`}`}
                      >
                        {strings.EXPIRATION_DATE}
                        <Icon
                          icon={`${
                            activeTab == 3
                              ? "ant-design:caret-up-filled"
                              : "ant-design:caret-down-filled"
                          }`}
                          width="20"
                          height="20"
                          className="ms-3"
                          style={{
                            color: `${activeTab == 3 ? "#CCFF00" : "#D9D9D9"}`,
                          }}
                        />
                      </div>
                    </th>
                  </thead>
                  <tbody>
                    {historyData?.getLockerHistory?.map((item, index) => {
                      return (
                        <tr className={`${"cursor-pointer font-extrabold"}`}>
                          <td className="!text-center text-[18px] font-bold text-white pl-3">
                            <p className=" m-0">{index + 1}.</p>
                          </td>
                          <td className="!text-center text-[18px] p-0 font-bold text-white ">
                            <div className="!text-center text-lg font-bold font-[Archivo] text-white px-2 py-1 ps-4    flex flex-col  items-start ">
                              {item.memberName}
                            </div>
                          </td>
                          <td className="!text-center text-[18px] font-bold text-white ">
                            <div className="!text-center text-lg font-bold font-[Archivo] text-white px-2 py-1     ">
                              <p className=" m-0">
                                {moment(item.startDate).format("YY.MM.DD")}
                              </p>
                            </div>
                          </td>
                          <td className="!text-center text-[18px] font-bold text-white pl-3">
                            <p className=" m-0">
                              {moment(item.endDate).format("YY.MM.DD")}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className="text-2xl font-extrabold text-[#444444] text-center my-6">
                  {strings.NO_RECORD_FOUND}
                </p>
              )}
            </div>
          </div>

          {historyData?.getLockerHistory.length !== 0 ? (
            <div className=" pt-4 pb-2">
              {page && page > 1 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  forcePage={offset == 1 ? 0 : offset - 1}
                  pageCount={page}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={active}
                  onClick={() => setActive("active")}
                />
              )}
            </div>
          ) : (
            "  "
          )}

          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-5 gap-y-2.5 mt-1 mb-4"}`}
          >
            <button
              onClick={() => {
                onClose(false);
                setLockerId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[162px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LockerHistory;
