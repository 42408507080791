import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../../commonComponents/SideBar";
import Header from "../../commonComponents/Header";
import { placeholder, strings } from "../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import { EVENT_TYPES, PRODUCT_TYPES_LIST } from "../../../utils/CommonData";
import ReactDatePicker from "react-datepicker";
import HoldingTable from "./HoldingTable";
import HoldingModal from "./HoldingModal";
import ManagementSublyApi from "../../../helper/ManagementApi";
import ReactPaginate from "react-paginate";
import moment from "moment";
import $ from "jquery";
import { DatePicker } from "rsuite";

const HoldingRequest = () => {
  const { token } = useSelector((state) => state.user);
  const [productId, setProductId] = useState("");
  const [page, setPage] = useState("");
  const [offset, setOffset] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [monthValue, setMonthValue] = useState({ text: "All", value: "" });
  const [showModal, setshowModal] = useState(false);
  const [holdingValue, setHoldingValue] = useState("");
  const [orderValue, setOrderValue] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [activeTab, setActiveTab] = useState();
  // const [currentMonth, setCurrentMonth] = useState(
  //   moment().format("YYYY.MM.DD")
  // );
  const [currentEndMonth, setCurrentEndMonth] = useState(
    moment().format("YYYY.MM.DD")
  );
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");
  // ==============API fo fetch holding request list==================
  useEffect(() => {
    getHoldingList();
  }, [offset]);
  async function getHoldingList(sort, order, type, page) {
    setIsLoader(true);
    await ManagementSublyApi.fetchHoldingList(
      token,
      search,
      type,
      startDate || "",
      endDate || "",
      sort ? sort : sortKey,
      order ? order : orderValue,
      page ? page : offset
    )
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setHoldingValue(response.data.pauseRequests);
          setPage(response.data?.count / 10);
          page && setOffset(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //--------function for handling pagination------
  const handlePageClick = async (data) => {
    await setOffset(data.selected + 1);
  };

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if (
      $(e.target).closest(".closedNotify").length == 0 &&
      $(e.target).closest(".block_notify").length == 0 &&
      $(e.target).closest(".dropdownClass").length == 0
    ) {
      setshowModal(false);
      setProductId("");
      setOpenMonth(false);
    }
  });
  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentMonth).getMonth();
    return condition;
  };
  const filterEndMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(currentEndMonth).getMonth();
    return condition;
  };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
  }, [isCalenderOpen, currentMonth]);

  return (
    <div>
      <section>
        {isLoader ? <Loader /> : ""}
        <Container fluid>
          <Row>
            <Col xl={2} className="p-0">
              <SideBar />
            </Col>
            <Col
              xl={10}
              className={` ${
                showModal ? "overflow-hidden" : "overflow-auto"
              } ${"p-0 text-white  h-screen !max-[1500px]:h-full relative"}`}
            >
              <Header text={strings.MANAGEMENT_HOLDING_REQUEST} />
              <div className="text-white px-7 ">
                <div className="mt-4 mb-7 flex items-center justify-between gap-3 flex-wrap">
                  <div className="flex items-center gap-4 max-sm:w-full">
                    <label
                      onClick={() => {
                        setOpenMonth(!openMonth);
                      }}
                      className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px] dropdownClass"
                    >
                      <p className="!mb-0 flex items-center gap-2 text-[#fff] cursor-pointer w-full font-bold text-lg whitespace-nowrap">
                        {monthValue.text}{" "}
                      </p>
                      <Icon
                        icon="icon-park-solid:down-one"
                        width="20"
                        height="20"
                        style={{ color: "#D9D9D9", cursor: "pointer" }}
                        rotate={openMonth ? 2 : 4}
                      />
                      {openMonth && (
                        <MonthFilter
                          setMonthValue={setMonthValue}
                          setStartDate={setStartDate}
                          setEndDate={setEndDate}
                          getHoldingList={getHoldingList}
                        />
                      )}{" "}
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3 gap-y-3 max-sm:flex-wrap">
                    <div className="flex items-center gap-x-3 gap-y-3 flex-wrap">
                      <label className="relative bg-[#353638] w-[300px] max-sm:w-full h-[50px] px-3 flex items-center justify-between gap-2 rounded-[30px]">
                        <input
                          type="text"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          placeholder="Keywords"
                          className="bg-transparent w-full text-white text-lg font-bold"
                        />
                      </label>
                      <div className="flex items-center gap-2 dateInputClass pl-3  max-[360px]:-ml-4  max-[375px]:-ml-4  ">
                        <Icon
                          icon="lets-icons:date-today"
                          width="30"
                          height="30"
                          style={{ color: "#CCFF00" }}
                        />
                        <div className="w-full flex flex-col md:flex-row gap-2">
                          <div className="newDatePickerClass">
                            <DatePicker
                              oneTap
                              editable={false}
                              id="datepicker-1"
                              placeholder="From-"
                              format="yyyy.MM.dd"
                              block
                              appearance="subtle"
                              className="custom-datepicker"
                              value={startDate}
                              onChange={(date) => setStartDate(date)}
                              onChangeCalendarDate={(e) => {
                                setCurrentMonth(e);
                              }}
                              onNextMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onPrevMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onOpen={() => {
                                setIsCalenderOpen(true);
                                setDatePickerCheck(1);
                                setCurrentMonth(
                                  startDate ? new Date(startDate) : new Date()
                                );
                              }}
                              onClose={() => {
                                setIsCalenderOpen(false);
                                setCurrentMonth(
                                  startDate ? new Date(startDate) : new Date()
                                );
                                setDatePickerCheck("");
                              }}
                              disabledDate={(date) => {
                                const maxDate = new Date();
                                return date > maxDate;
                              }}
                            />
                          </div>
                          <div className="newDatePickerClass">
                            <DatePicker
                              oneTap
                              editable={false}
                              id="datepicker-2"
                              placeholder="To-"
                              format="yyyy.MM.dd"
                              block
                              appearance="subtle"
                              className="custom-datepicker"
                              onChange={(date) => setEndDate(date)}
                              onChangeCalendarDate={(e) => {
                                setCurrentMonth(e);
                              }}
                              value={endDate}
                              onNextMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onPrevMonth={(e) => {
                                setCurrentMonth(e);
                              }}
                              onOpen={() => {
                                setIsCalenderOpen(true);
                                setCurrentMonth(
                                  endDate ? new Date(endDate) : new Date()
                                );
                                setDatePickerCheck(2);
                              }}
                              onClose={() => {
                                setIsCalenderOpen(false);
                                setDatePickerCheck("");
                                setCurrentMonth(
                                  endDate ? new Date(endDate) : new Date()
                                );
                              }}
                              disabledDate={(date) => {
                                const minDate = new Date(startDate);
                                const maxDate = new Date();
                                return date < minDate || date > maxDate;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setOffset(1);
                        getHoldingList("", "", monthValue?.value);
                      }}
                      className="hover:text-black hover:bg-[#CCFF00] text-lg font-extrabold text-white kakaoClass rounded-[30px] h-[50px] w-[120px] ml-12"
                    >
                      {strings.SEARCH}
                    </button>
                  </div>
                </div>

                <div className="">
                  <div className="font-extrabold text-3xl mb-[25px]">
                    {strings.HOLDING_REQUEST}
                  </div>
                  {holdingValue?.length == 0 && (
                    <p className="text-2xl font-extrabold text-[#444444] text-center my-4">
                      {strings.NO_RECORD_FOUND}
                    </p>
                  )}
                  {holdingValue?.length > 0 && (
                    <HoldingTable
                      holdingValue={holdingValue}
                      setActiveTab={setActiveTab}
                      activeTab={activeTab}
                      fetchHoldingList={getHoldingList}
                      setOrderValue={setOrderValue}
                      setSortKey={setSortKey}
                      setProductId={setProductId}
                      productId={productId}
                      setshowModal={setshowModal}
                    />
                  )}{" "}
                </div>
                {holdingValue?.length > 0 && page && page > 1 && (
                  <div className="w-full">
                    <div className="mt-3">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        forcePage={offset == 1 ? 0 : offset - 1}
                        pageCount={page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </div>
              {showModal && (
                <HoldingModal
                  setshowModal={setshowModal}
                  getHoldingList={getHoldingList}
                  setProductId={setProductId}
                  setIsLoader={setIsLoader}
                  productId={productId}
                  classNameValue={`${
                    showModal ? "block_notify" : "hideNotify"
                  }`}
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default HoldingRequest;

function MonthFilter({
  setMonthValue,
  setEndDate,
  setStartDate,
  getHoldingList,
}) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[230px] right-4 top-14 max-h-[300px] overflow-auto z-20 px-3">
      <div
        onClick={() => {
          setMonthValue({ text: "All", value: "" });
          setEndDate("");
          setStartDate("");
          getHoldingList("", "", "", 1);
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.ALL}</span>
      </div>
      {PRODUCT_TYPES_LIST.map((item, index) => (
        <div
          onClick={() => {
            setMonthValue({ text: item, value: index });
            setEndDate("");
            setStartDate("");
            getHoldingList("", "", index, 1);
          }}
          className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1.5 cursor-pointer border-b border-[#44444480]"
        >
          <span className="mb-0 text-lg font-bold">{item}</span>
        </div>
      ))}
    </div>
  );
}
