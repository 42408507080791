import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import profile from "../../../../assests/images/demoProfile.svg";
import { placeholder, strings } from "../../../../utils/String";
import { Icon } from "@iconify-icon/react";
import TakeOverModal from "../../../commonComponents/modalScreens/TakeOverModal";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MemberTakeOver from "../../../commonComponents/modalScreens/MemberTakeOver";
import ListingModal from "../../../commonComponents/modalScreens/ListingModal";
import HoldingModal from "../../../commonComponents/modalScreens/HoldingModal";
import MemoListModal from "../../../commonComponents/modalScreens/MemoModal";
import edit from "../../../../assests/icons/editImage.svg";
import medal from "../../../../assests/icons/medal.svg";
import cake from "../../../../assests/icons/cake.svg";
import MembersSublyApi from "../../../../helper/MemberApi";
import Moment from "react-moment";
import PurchaseListModal from "../../../commonComponents/modalScreens/PurchaseListModal";
import moment from "moment";
import group from "../../../../assests/icons/group.svg";
import golf from "../../../../assests/icons/golf.svg";
import pilates from "../../../../assests/icons/pilates.svg";
import { useSelector } from "react-redux";
import { GenderOptionBox } from "../MemberRegisterForm";
import boldTrainer from "../../../../assests/icons/boldTrainer.svg";
import trainerRestart from "../../../../assests/icons/restartTrainer.svg";
import { ROLES } from "../../../../utils/CommonData";
import { useNavigate } from "react-router-dom";
import kakaoBtn from "../../../../assests/images/kakaoLoginBtn-icon.svg";
import appleBtn from "../../../../assests/images/appleLoginBtn-icon.svg";
import naverBtn from "../../../../assests/images/naverLoginBtn-icon.svg";
import AuthModal from "./AuthModal";
import ErrorModal from "../../../commonComponents/modalScreens/ErrorModal";
import $ from "jquery";
import {
  alphaOnly,
  formatCustomNumber,
  numberOnly,
  numberValidate,
  trimPhone,
  validateEmail,
} from "../../../../utils/commonUtils";
import { useDaumPostcodePopup } from "react-daum-postcode";
import TrainerList from "../TrainerList";
import { DatePicker } from "rsuite";
import { HiCalendar } from "react-icons/hi2";

// ========function for getting list of years==========
export const years = Array.from(
  { length: 100 },
  (_, i) => new Date().getFullYear() - 99 + i
).reverse();

function MemberInfoDetail({
  classNameValue,
  setShowMoreInfo,
  id,
  setIsLoader,
  toStatus,
  setShowRegisterForm,
  setAddProduct,
  setTrainerModal,
  setTrainerId,
  setStaffId,
  trainerModal,
  setTrainerValue,
  trainerValue,
  changeTrainer,
  setChangeTrainer,
}) {
  const { token } = useSelector((state) => state.user);
  const { role } = useSelector((state) => state.user.selectedGym);
  const { provider } = useSelector((state) => state.user.userDetails);
  const [birthDate, setBirthDate] = useState("");
  const [openTakeOver, setOpenTakeOver] = useState(false);
  const [listType, setListType] = useState({ name: "", type: "" });
  const [isList, setIsList] = useState({ status: false, type: 0 });
  const [holdingList, setHoldingList] = useState(false);
  const [memoModal, setMemoModal] = useState({
    status: false,
    edit: false,
    modify: false,
  });
  const [isModify, setIsModify] = useState(false);
  const [memberDetail, setMemberDetail] = useState("");
  const [isAddMemo, setIsAddMemo] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [memoValue, setMemoValue] = useState("");
  const [gender, setGender] = useState({
    placeholder: "",
    id: "",
  });
  const [showGender, setShowGender] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [holdingLeft, setHoldingLeft] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [memberProductId, setMemberProductId] = useState({
    memberId: "",
    productId: "",
  });
  const [authModal, setAuthModal] = useState(false);
  const [authId, setAuthId] = useState();
  const [leftDays, setLeftDays] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [openCalender, setOpenCalender] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [memo, setMemo] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isCellNumber, setIsCellNumber] = useState(false);
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState("");
  const [errorHandler, setErrorHandler] = useState({});
  const [holding, setHolding] = useState(false);
  const [birthMonth, setBirthMonth] = useState(moment().format("yyyy.MM.DD"));
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");

  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(birthMonth).getMonth();
    return condition;
  };

  // ===========function for Image handling=============
  const onFileHandle = async (e, index) => {
    const file = e.target.files[0];
    setImage(file);
    if (e.target.files && e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const { result } = e.target;
        if (result) {
          await setImagePreview([result]);
        }
      };
      fileReader.readAsDataURL(file);
    }
  };

  // ==========Calling API For fetch member detail===========
  useEffect(() => {
    async function fetchMemberDetail() {
      setIsLoader(true);
      await MembersSublyApi.memberDetail(id, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setName(response?.data?.name);
            setEmail(response?.data?.email);
            setCellPhone(`${response?.data?.cellphone}`);
            setZipCode(response?.data?.zipCode);
            setAddressOne(response?.data?.address1);
            setAddressTwo(response?.data?.address2);
            setBirthDate(new Date(moment(response?.data?.birth, "DD-MM-YYYY")));
            setBirthMonth(
              moment(response?.data?.birth, "DD-MM-YYYY").format("YYYY-MM-DD")
            );
            setGender({
              placeholder:
                response?.data?.gender == "m"
                  ? "Male"
                  : response?.data?.gender == "f"
                  ? "Female"
                  : placeholder.SELECT_GENDER,
              id: response?.data?.gender,
            });
            setMemberDetail({
              ...response?.data,
              memo: response?.data.memo?.filter((m) => m.isActive),
            });
            setAuthId(response?.data?.memberId);
            setTrainerId(response?.data?.trainerDetails?.id);
            setStaffId(response?.data?.memberId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    !changeTrainer && !holdingList && fetchMemberDetail();
  }, [isUpdate, isModify, refundModal, memoModal, changeTrainer, holdingList]);
  async function memberAuthentication() {
    await MembersSublyApi.memberAuth(token, authId).then((response) => {
      if (response.statusCode == 200) {
        setAuthModal(true);
      } else {
        setOpenModal(true);
        seterrorMessage(response.data.message);
      }
    });
  }

  // const handleTrainerRestart = () => {
  //   const updatedMemberDetail = {
  //     ...memberDetail,
  //     trainerDetails: {
  //       ...memberDetail.trainerDetails,
  //       // Add any additional changes here if necessary
  //     }
  //   };

  //   setMemberDetail(updatedMemberDetail);
  //   setTrainerModal(true);
  // };
  // ===========function for add memo handling=========
  async function handleMemo() {
    if (memoValue) {
      setIsLoader(true);
      const requestData = { memo: memoValue.trim() };
      await MembersSublyApi.addMemo(id, requestData, token)
        .then((response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setIsUpdate(!isUpdate);
            setMemoValue("");
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setShowGender(false);
    }
  });

  // ===========Edit member info API handler=============
  async function editInfoHandle() {
    if (
      name &&
      email &&
      cellPhone &&
      birthDate &&
      gender.id &&
      validateEmail(email) &&
      numberValidate(cellPhone)
    ) {
      setIsLoader(true);
      const requestData = new FormData();
      image && requestData.append("file", image);
      requestData.append("name", name.trim());
      requestData.append("email", email);
      requestData.append("cellphone", `${cellPhone.toString()}`);
      requestData.append("birth", moment(birthDate).format("DD-MM-yyyy"));
      requestData.append("gender", gender.id);
      requestData.append("zipCode", zipCode);
      requestData.append("address1", addressOne);
      requestData.append("address2", addressTwo);
      await MembersSublyApi.editMemberInfo(id, requestData, token).then(
        (response) => {
          setIsLoader(false);
          if (response.statusCode == 200) {
            setIsUpdate(!isUpdate);
            setIsModify(false);
          } else {
            setOpenModal(true);
            seterrorMessage(response.data.message);
          }
        }
      );
    } else {
      const errorObject = {
        ...(!name
          ? {
              name: "Please enter the value of Name",
            }
          : { name: "" }),
        ...(!email
          ? {
              email: "Please enter the value of Email",
            }
          : { email: "" }),
        ...(!cellPhone
          ? {
              cellPhone: "Please enter the value of CellPhone",
            }
          : { cellPhone: "" }),
        ...(email &&
          !validateEmail(email) && {
            email: "Please enter the valid Email",
          }),
        ...(cellPhone &&
          !numberValidate(cellPhone) && {
            cellPhone: "Cellphone must be 11 digit",
          }),
        ...(!gender.id
          ? {
              gender: "Please select the Gender",
            }
          : { gender: "" }),
        ...(!birthDate
          ? {
              birthDate: "Please select the BirthDate",
            }
          : { birthDate: "" }),
      };
      setErrorHandler(errorObject);
    }
  }

  // ================API for holding a product===================\
  async function handleHolding() {
    setIsLoader(true);
    const requestData = {
      memberOwnProductId: memberProductId.productId,
      memberId: memberProductId.memberId,
      startDate: startDate,
      endDate: endDate,
    };
    await MembersSublyApi.memberProductHolding(requestData, token)
      .then((response) => {
        setIsLoader(false);
        if (response.statusCode == 200) {
          setHoldingList(!holdingList);
          setStartDate("");
          setEndDate("");
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //--------function for handling name input filed------
  $("#name").bind("keypress", alphaOnly);

  //--------function for handling contact input filed------
  $("#cell").bind("keypress", numberOnly);

  // =============Code for fetch address through zip code============
  const open = useDaumPostcodePopup(
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );

  const handleComplete = (data) => {
    let fullAddress = data.addressEnglish;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setZipCode(data?.zonecode);
    setAddressOne(fullAddress);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete, width: "400px", height: "400px" });
  };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex justify-center items-center bg-[#000000c2] z-10"}`}
    >
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
      <div
        className={`${classNameValue} ${"bg-[#2C2C2E] rounded-[10px] w-[95%] max-h-[95%] p-10 pb-6 overflow-auto z-10"}`}
      >
        <div className="border-b border-[#444444] pb-4">
          {" "}
          <Row className="py-6 px-3  bg-[#353638] rounded-[10px]">
            <Col xxl={6}>
              <div className="flex gap-4 max-lg:flex-wrap">
                <div className="mb-4">
                  <div className="w-[200px] h-[200px] rounded-[5px] relative">
                    <img
                      src={
                        imagePreview
                          ? imagePreview
                          : memberDetail?.mProfileImg?.prof_img1_org
                          ? memberDetail?.mProfileImg?.prof_img1_org
                          : profile
                      }
                      className="rounded-[5px] w-full h-full"
                    />
                    {isModify && (
                      <label
                        htmlFor="uploadImg"
                        className="absolute top-[182px] left-[42%] cursor-pointer"
                      >
                        <img src={edit} className="w-[35px] h-[35px]" />
                      </label>
                    )}
                    <input
                      type="file"
                      className="hidden"
                      id="uploadImg"
                      onChange={(e) => onFileHandle(e)}
                    />
                    <p className="text-center text-lg font-bold my-3">
                      {ROLES[role]}
                    </p>
                  </div>
                  <div className="mt-14 flex justify-center">
                    {memberDetail.provider == "kakao" ? (
                      <img src={kakaoBtn} />
                    ) : memberDetail.provider == "apple" ? (
                      <img src={appleBtn} />
                    ) : memberDetail.provider == "naver" ? (
                      <img src={naverBtn} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {isModify ? (
                  <div className="w-[100%]">
                    <div className="border-r border-[#444444] pr-6 max-lg:!border-r-0">
                      {" "}
                      <label
                        htmlFor="nameId"
                        className={`${
                          !name && errorHandler.name ? "errorClass" : "mb-3"
                        } bg-[#414142] w-full h-[30px] px-3 flex items-center gap-2 rounded-[5px]`}
                      >
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^[a-zA-Z가-힣- ]*$/;
                            if (regex.test(value)) {
                              setName(value);
                            }
                          }}
                          className="bg-[#414142] text-white w-full"
                          id="name"
                          placeholder={`${placeholder.NAME} :`}
                          autoComplete="off"
                        />
                      </label>
                      {!name && (
                        <p className="mb-1 text-sm text-[#F87171]">
                          {errorHandler?.name}
                        </p>
                      )}
                      <label
                        // htmlFor="nameId"
                        className={`${
                          errorHandler?.email &&
                          (!email || !validateEmail(email))
                            ? "errorClass"
                            : "mb-3"
                        } bg-[#414142] w-full h-[30px] px-3 flex items-center gap-2 rounded-[5px] `}
                      >
                        <input
                          type="text"
                          value={email}
                          onChange={(e) => {
                            if (
                              e.target.value.match(
                                "^[\u1100-\u11FF\u3130-\u318F\uAC00-\uD7A3s]+$"
                              )
                            ) {
                              return false;
                            } else {
                              setIsEmailError(false);
                              setEmail(e.target.value);
                            }
                          }}
                          className="bg-[#414142] text-white w-full"
                          // id="nameId"
                          placeholder={`${placeholder.EMAIL} :`}
                          autoComplete="off"
                        />
                      </label>
                      {(!email || !validateEmail(email)) && (
                        <p className="mb-1 text-sm text-[#F87171]">
                          {errorHandler?.email}
                        </p>
                      )}
                      <label
                        htmlFor="cell"
                        className={`${
                          errorHandler?.cellPhone &&
                          (!cellPhone || !numberValidate(cellPhone))
                            ? "errorClass"
                            : "mb-3"
                        }   bg-[#414142] w-full h-[30px] px-3 flex items-center gap-2 rounded-[5px] `}
                      >
                        <input
                          type="text"
                          value={formatCustomNumber(cellPhone)}
                          onChange={(e) => {
                            const trimmedValue = trimPhone(e.target.value);
                            if (
                              !isNaN(+trimmedValue) ||
                              !trimmedValue?.length
                            ) {
                              setIsCellNumber(false);
                              // const trimmedValue = e.target.value.trim();
                              if (trimmedValue.length <= 11)
                                setCellPhone(trimmedValue);
                            }
                          }}
                          className="bg-[#414142] text-white w-full"
                          id="cell"
                          placeholder={`${placeholder.CELL_PHONE} :`}
                          autoComplete="off"
                        />
                      </label>
                      {(!cellPhone || !numberValidate(cellPhone)) && (
                        <p className="mb-1 text-sm text-[#F87171]">
                          {errorHandler?.cellPhone}
                        </p>
                      )}
                      <div
                        onClick={() => setShowGender(!showGender)}
                        className={`${"bg-[#414142] w-full h-[30px] px-3 z-10 flex items-center gap-2 rounded-[5px] mb-3 relative dropdownClass"}`}
                      >
                        <input
                          type="text"
                          className="bg-[#414142] text-white w-full caret-transparent cursor-pointer"
                          value={gender.placeholder}
                          placeholder={`${gender.placeholder}`}
                          autoComplete="off"
                        />
                        <Icon
                          icon="tabler:caret-down-filled"
                          width="20"
                          height="20"
                          style={{ color: "#D9D9D9" }}
                          rotate={showGender ? 2 : 4}
                        />
                        {showGender && (
                          <GenderOptionBox
                            setGender={setGender}
                            setShowGender={setShowGender}
                          />
                        )}
                      </div>
                      <div
                        htmlFor="birth"
                        className={`${
                          errorHandler?.birthDate && !birthDate
                            ? "errorClass"
                            : "mb-3"
                        } ${"bg-[#414142] w-full h-[30px] px-3 flex items-center gap-2 rounded-[5px] detailDatePicker newDateInputClass"}`}
                      >
                        <DatePicker
                          oneTap
                          caretAs={HiCalendar}
                          id="datepicker-1"
                          placeholder={placeholder.BIRTH_STAR}
                          format="yyyy.MM.dd"
                          block
                          editable={false}
                          value={birthDate}
                          appearance="subtle"
                          className="custom-datepicker bg-transparent border-0 w-full px-0 h-[30px]"
                          onChange={(date) => setBirthDate(date)}
                          onChangeCalendarDate={(e) => {
                            setCurrentMonth(e);
                          }}
                          onNextMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onPrevMonth={(e) => {
                            setCurrentMonth(e);
                          }}
                          onOpen={() => {
                            setIsCalenderOpen(true);
                            setCurrentMonth(
                              birthDate ? new Date(birthDate) : new Date()
                            );
                            setDatePickerCheck(1);
                          }}
                          onClose={() => {
                            setIsCalenderOpen(false);
                            setCurrentMonth(
                              birthDate ? new Date(birthDate) : new Date()
                            );
                            setDatePickerCheck("");
                          }}
                          disabledDate={(date) => {
                            const maxDate = new Date();
                            return date > maxDate;
                          }}
                        />
                      </div>
                      {!birthDate && (
                        <p className="mb-1 text-sm text-[#F87171]">
                          {errorHandler?.birthDate}
                        </p>
                      )}
                      <label
                        htmlFor="cell"
                        onClick={handleClick}
                        className="bg-[#414142] w-full h-[30px] px-3 flex items-center gap-2 rounded-[5px] mb-3"
                      >
                        <input
                          type="text"
                          value={zipCode}
                          placeholder={`${placeholder.ZIP_CODE} :`}
                          // disabled={addProduct ? true : false}
                          autoComplete="off"
                          className="bg-[#414142] text-white w-full caret-transparent cursor-pointer"
                          id="zipCode"
                        />
                        <Icon
                          icon="lucide:search"
                          width="20"
                          height="20"
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      </label>
                      <label
                        onClick={handleClick}
                        className="bg-[#414142] w-full h-[70px] px-3 flex items-start gap-2 rounded-[5px] mb-3"
                      >
                        <input
                          type="text"
                          value={addressOne}
                          className="bg-[#414142] text-white w-full"
                          placeholder={`${placeholder.ADDRESS}1 :`}
                          autoComplete="off"
                        />
                      </label>
                      <label className="bg-[#414142] w-full h-[70px] px-3 flex items-start gap-2 rounded-[5px] mb-3">
                        <input
                          type="text"
                          value={addressTwo}
                          onChange={(e) => {
                            setAddressTwo(e.target.value);
                          }}
                          className="bg-[#414142] text-white w-full"
                          placeholder={`${placeholder.ADDRESS}2 :`}
                          autoComplete="off"
                        />
                      </label>
                    </div>
                    <div className="flex gap-4 items-center">
                      <button
                        onClick={() => setIsModify(false)}
                        className="text-sm font-bold text-white kakaoClass h-[40px] w-[108px] rounded-[28px]"
                      >
                        {strings.CANCEL}
                      </button>
                      <button
                        onClick={() => {
                          editInfoHandle();
                        }}
                        className="bg-common_color font-bold text-sm h-[40px] w-[108px] rounded-[28px]"
                      >
                        {strings.UPDATE}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="w-[100%]">
                    <div className="border-r border-[#444444] pr-6">
                      <h3 className="text-[22px] font-extrabold text-white flex items-center gap-2 mb-3">
                        {memberDetail?.name} <img src={medal} alt="medal" />{" "}
                        {`(#${memberDetail?.userCode})`}
                      </h3>
                      <div className="flex items-center gap-3 mb-3">
                        <Icon
                          icon="lets-icons:date-today"
                          width="24"
                          height="24"
                          style={{ color: "#CCFF00" }}
                        />
                        <p className="mb-0 text-base font-bold">
                          {`${strings.REGISTER_DATE}:`}{" "}
                          {moment(memberDetail?.regDate).format("YYYY.MM.DD")}{" "}
                        </p>
                      </div>
                      <div className="flex items-center gap-3 mb-3">
                        <Icon
                          icon="material-symbols:mail-outline"
                          width="24"
                          height="24"
                          style={{ color: "#CCFF00" }}
                        />
                        <p className="mb-0 text-base font-bold">
                          {`${strings.EMAIL_}:`} {memberDetail?.email}
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                        <Icon
                          icon="heroicons-outline:phone"
                          width="24"
                          height="24"
                          style={{ color: "#CCFF00" }}
                        />
                        <p className="mb-0 text-base font-bold">
                          {strings.PHONE}:{" "}
                          {formatCustomNumber(memberDetail?.cellphone)}
                        </p>
                      </div>
                      <div className="flex items-center gap-3 my-3">
                        <img
                          src={cake}
                          alt="cake"
                          className="w-[24px] h-[24px]"
                        />
                        <p className="mb-0 text-base font-bold">
                          {strings.BIRTH}:{" "}
                          {moment(memberDetail?.birth, "DD-MM-YYYY").format(
                            "YYYY.MM.DD"
                          )}{" "}
                          {`(${
                            memberDetail?.gender &&
                            memberDetail.gender.toUpperCase()
                          })`}
                        </p>
                      </div>
                      {memberDetail?.address1 && (
                        <div className="flex items-center gap-3">
                          <Icon
                            icon="ion:location-outline"
                            width="24"
                            height="24"
                            style={{ color: "#CCFF00" }}
                          />
                          <p className="mb-0 text-base font-bold">
                            {strings.ADDRESS}
                            {memberDetail?.address2 && 1}:{" "}
                            {memberDetail?.address1}
                          </p>
                        </div>
                      )}
                      {memberDetail?.address2 && (
                        <div className="flex items-center gap-3 mt-3">
                          <Icon
                            icon="ion:location-outline"
                            width="24"
                            height="24"
                            style={{ color: "#CCFF00" }}
                          />
                          <p className="mb-0 text-base font-bold">
                            {strings.ADDRESS}2: {memberDetail?.address2}
                          </p>
                        </div>
                      )}
                      <div className="flex gap-4 items-center pt-6">
                        <button
                          className="text-sm hover:text-black hover:bg-[#CCFF00]  font-extrabold text-white kakaoClass h-[40px] w-[130px] rounded-[28px]"
                          onClick={() => {
                            memberAuthentication();
                          }}
                        >
                          {strings.AUTHENTICATION}
                        </button>
                        <button
                          onClick={() => setIsModify(true)}
                          className="text-white hover:text-black hover:bg-[#CCFF00]  kakaoClass font-extrabold text-sm h-[40px] w-[108px] rounded-[28px]"
                        >
                          {strings.MODIFY}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col xxl={6}>
              {memberDetail && memberDetail.memo.length > 0 ? (
                <>
                  {" "}
                  <div className="rounded-[10px] w-full pt-3 pl-10 pr-12">
                    <ol className="text-base font-bold text-white list-decimal mb-0">
                      {memberDetail && memberDetail.memo.length > 0
                        ? memberDetail.memo.map((item, index) => {
                            return (
                              item.isActive &&
                              index < 8 && (
                                <div className="flex items-start justify-between">
                                  <li className="leading-7 w-[60%]">
                                    {item.memo}
                                  </li>

                                  <div className="flex items-centre gap-3">
                                    <p className="!mb-0">
                                      {moment(item.modifyDate).format(
                                        "YY.MM.DD"
                                      )}
                                    </p>
                                    <p className="!mb-0 mt-0">{`(${item.staffName})`}</p>
                                  </div>
                                </div>
                              )
                            );
                          })
                        : ""}
                    </ol>
                    {memberDetail.memo.length > 8 && (
                      <p
                        className="text-base font-bold text-[#CCFF00] pl-3 cursor-pointer"
                        onClick={() => {
                          setMemoModal({ status: true, edit: false });
                        }}
                      >
                        more...
                      </p>
                    )}
                    {isAddMemo && (
                      <div className="flex items-center gap-2 mt-3 max-lg:justify-start">
                        <label
                          style={{ border: "2px solid #4E4E4E" }}
                          className=" rounded-[10px] h-[35px] w-full px-3 flex items-center"
                        >
                          <input
                            type="text"
                            value={memoValue}
                            onChange={(e) => {
                              setMemoValue(e.target.value);
                              setMemo(e.target.value.length > 0 ? true : false);
                            }}
                            className="bg-[#353638] text-white w-full"
                            placeholder={`${placeholder.ADD_MEMO}`}
                            autoComplete="off"
                          />
                        </label>
                        <button
                          disabled={!memo}
                          onClick={() => {
                            handleMemo();
                            setMemo(false);
                          }}
                          className="bg-common_color h-[25px] px-2 rounded-[5px] text-sm font-bold disabled:bg-[#ccff0094]"
                        >
                          {strings.ADD}
                        </button>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${
                      isAddMemo ? "justify-end" : "justify-start"
                    } ${"flex gap-4 items-center mt-3  pl-12 max-lg:justify-start"}`}
                  >
                    {isAddMemo && (
                      <>
                        <button
                          onClick={() => setIsAddMemo(false)}
                          className="text-sm font-bold text-white kakaoClass h-[40px] w-[108px] rounded-[28px]"
                        >
                          {strings.CANCEL}
                        </button>
                        {/* <button
                          onClick={() => setIsAddMemo(false)}
                          className={`${"bg-common_color text-black"} ${"hover:text-black hover:bg-[#CCFF00] kakaoClass font-bold text-sm h-[40px] w-[108px] rounded-[28px]"}`}
                        >
                          {strings.UPDATE}
                        </button> */}
                      </>
                    )}
                    {!isAddMemo && (
                      <>
                        <button
                          onClick={() => {
                            setMemoModal({ status: true, edit: false });
                          }}
                          className="hover:text-black hover:bg-[#CCFF00] text-sm font-bold text-white kakaoClass h-[40px] w-[108px] rounded-[28px]"
                        >
                          {strings.LIST}
                        </button>
                        <button
                          onClick={() => {
                            setMemoModal({ status: true, edit: true });
                          }}
                          className={`${"hover:text-black hover:bg-[#CCFF00] kakaoClass text-white font-bold text-sm h-[40px] w-[108px] rounded-[28px]"}`}
                        >
                          {strings.MODIFY}
                        </button>
                        <button
                          onClick={() => setIsAddMemo(true)}
                          className="hover:text-black hover:bg-[#CCFF00] text-sm font-bold text-white kakaoClass h-[40px] w-[108px] rounded-[28px]"
                        >
                          {strings.ADD_MEMO}
                        </button>
                      </>
                    )}
                  </div>{" "}
                </>
              ) : (
                <div className="flex items-center gap-2 max-lg:pt-4">
                  <label
                    style={{ border: "2px solid #4E4E4E" }}
                    className=" rounded-[10px] h-[35px] w-full px-3 flex items-center"
                  >
                    <input
                      type="text"
                      value={memoValue}
                      onChange={(e) => {
                        setMemoValue(e.target.value);
                      }}
                      className="bg-transparent text-white w-full"
                      placeholder={`${placeholder.ADD_MEMO}`}
                      autoComplete="off"
                    />
                  </label>
                  <button
                    disabled={memoValue ? false : true}
                    onClick={() => {
                      handleMemo();
                    }}
                    className="bg-common_color text-black h-[25px] px-2 rounded-[5px] text-sm font-bold disabled:bg-[#ccff0094]"
                  >
                    {strings.ADD}
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <Row className="pt-4 pb-4 border-b !items-end border-[#444444] gap-y-6 max-h-[550px] overflow-auto">
          {memberDetail?.memberOwnProducts
            ? Object.keys(memberDetail?.memberOwnProducts).map(
                (items, index) => (
                  <Col xxl={3} lg={4} md={6} key={index}>
                    <div>
                      <div className="flex items-end justify-between gap-2">
                        <div className="flex items-center gap-2 mb-2">
                          {items == "membership" ? (
                            <Icon
                              icon="tdesign:cardmembership"
                              width="23"
                              height="23"
                              style={{ color: "white" }}
                            />
                          ) : items == "PT" ? (
                            <Icon
                              icon="tdesign:cardmembership"
                              width="23"
                              height="23"
                              style={{ color: "white" }}
                            />
                          ) : items == "uniform" ? (
                            <Icon
                              icon="fluent-emoji-high-contrast:womans-clothes"
                              width="23"
                              height="23"
                              style={{ color: "white", marginBottom: "4px" }}
                            />
                          ) : items == "gx" ? (
                            <img src={group} />
                          ) : items == "golf" ? (
                            <img src={golf} />
                          ) : items == "yoga" ? (
                            <Icon
                              icon="iconoir:yoga"
                              width="23"
                              height="23"
                              style={{ color: "white" }}
                            />
                          ) : items == "pilates" ? (
                            <img src={pilates} />
                          ) : items == "locker" ? (
                            <Icon
                              icon="ph:lockers-bold"
                              width="23"
                              height="23"
                              style={{ color: "white" }}
                            />
                          ) : (
                            ""
                          )}
                          <p
                            className={`${"text-white"} mb-0 text-lg font-bold`}
                          >
                            {items}
                          </p>
                        </div>
                        {items == "PT" &&
                          memberDetail?.trainerDetails?.gymStaffName && (
                            <div className="kakaoClass rounded-[5px] flex items-center gap-3 p-2 mb-2">
                              <img src={boldTrainer} />
                              <p className="mb-0 text-common_color font-bold text-xl ">
                                {memberDetail?.trainerDetails?.gymStaffName}
                              </p>
                            </div>
                          )}
                        {items == "PT" && (
                          <img
                            src={trainerRestart}
                            className="mb-2"
                            onClick={() => {
                              // setMemberDetail(handleTrainerRestart)
                              setChangeTrainer(true);
                              setUserDetail({
                                memberId: memberDetail?.memberId,
                                trainerId: memberDetail?.trainerDetails?.id,
                              });
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          border:
                            memberDetail?.memberOwnProducts[items]?.isExpired ==
                            1
                              ? "1px solid #C25E5F"
                              : memberDetail?.memberOwnProducts[items]
                                  ?.isCompleted
                              ? " 1px solid #CCFF00"
                              : "transparent",
                        }}
                        className="bg-[#353638] rounded-[10px] p-3 flex items-center text-center justify-center h-[120px]"
                      >
                        <div>
                          <div className="flex-wrap flex items-center justify-center gap-3 ">
                            {/* {memberDetail?.memberOwnProducts[items]
                              ?.isCompleted && (
                              <button
                                className={`${"max-w-full bg-[#CCFF0066] text-white"} ${"text-base font-bold kakaoClass h-[40px] w-[200px] rounded-[28px]"}`}
                              >
                                {"Completed"}
                              </button>
                            )}{" "} */}
                          </div>
                          {items !== "PT" ? (
                            <p
                              className={`${
                                !memberDetail?.memberOwnProducts[items]
                                  ?.isExpired == 1
                                  ? "text-white"
                                  : "text-[#C25E5F]"
                              }  text-lg font-extrabold flex flex-col`}
                            >
                              {!memberDetail?.memberOwnProducts[items]
                                ?.isExpired
                                ? memberDetail?.memberOwnProducts[items]
                                    ?.daysLeft > 1000
                                  ? `Over 1000 ${strings.DAY_LEFT}`
                                  : `${memberDetail?.memberOwnProducts[items]?.daysLeft} ${strings.DAY_LEFT}`
                                : memberDetail?.memberOwnProducts[items]
                                    ?.transferred
                                ? "(Transferred)"
                                : "(Expired)"}
                            </p>
                          ) : (
                            <p
                              className={`${
                                !memberDetail?.memberOwnProducts[items]
                                  ?.isExpired == 1 ||
                                memberDetail?.memberOwnProducts[items]
                                  ?.isCompleted
                                  ? "text-white"
                                  : "text-[#C25E5F]"
                              }  text-lg font-extrabold flex flex-col`}
                            >
                              {!memberDetail?.memberOwnProducts[items]
                                ?.isExpired ||
                              memberDetail?.memberOwnProducts[items]
                                ?.isCompleted ? (
                                <p
                                  className={`${
                                    memberDetail?.memberOwnProducts[items]
                                      ?.isCompleted &&
                                    !memberDetail?.memberOwnProducts[items]
                                      ?.isExpired == 1
                                      ? "text-common_color"
                                      : "text-white"
                                  }  text-lg font-extrabold flex flex-col mb-0`}
                                >
                                  {!memberDetail?.memberOwnProducts[items]
                                    ?.isExpired &&
                                  memberDetail?.memberOwnProducts[items]
                                    ?.isCompleted
                                    ? "(Completed)"
                                    : `${memberDetail?.memberOwnProducts[items]?.daysLeft} ${strings.DAY_LEFT}`}
                                </p>
                              ) : memberDetail?.memberOwnProducts[items]
                                  ?.transferred ? (
                                "(Transferred)"
                              ) : (
                                "(Expired)"
                              )}
                              <span className="text-sm font-normal capitalize ">
                                {
                                  memberDetail?.memberOwnProducts[items]
                                    ?.sessionCompleted
                                }
                                /
                                {
                                  memberDetail?.memberOwnProducts[items]
                                    ?.sessionCount
                                }{" "}
                                {strings.SESSION}
                              </span>
                            </p>
                          )}
                          <p
                            className={`${
                              !memberDetail?.memberOwnProducts[items]
                                ?.isExpired == 1 ||
                              memberDetail?.memberOwnProducts[items]
                                ?.isCompleted
                                ? "text-white"
                                : "text-[#C25E5F]"
                            } flex items-center gap-1 text-base font-bold mb-0`}
                          >
                            <Icon
                              icon="lets-icons:date-today"
                              width="20"
                              height="20"
                              style={
                                !memberDetail?.memberOwnProducts[items]
                                  ?.isExpired == 1 ||
                                memberDetail?.memberOwnProducts[items]
                                  ?.isCompleted
                                  ? { color: "#CCFF00" }
                                  : { color: "#C25E5F" }
                              }
                            />
                            <Moment format="YY.MM.DD">
                              {
                                memberDetail?.memberOwnProducts[items]
                                  ?.membershipStartDate
                              }
                            </Moment>{" "}
                            {"~"}{" "}
                            <Moment format="YY.MM.DD">
                              {
                                memberDetail?.memberOwnProducts[items]
                                  ?.membershipEndDate
                              }
                            </Moment>
                          </p>
                        </div>
                      </div>
                      <div
                        className={`${
                          memberDetail?.memberOwnProducts[items]?.isExpired !==
                          1
                            ? "justify-around"
                            : "justify-start"
                        } ${"flex items-center gap-2 mt-3"}`}
                      >
                        <button
                          onClick={() => {
                            setListType({ name: items, type: "gx" });
                            setIsList({
                              status: true,
                              type: memberDetail?.memberOwnProducts[items]
                                ?.typeValue,
                            });
                            setHolding(false);
                          }}
                          className="hover:text-black hover:bg-[#CCFF00] text-sm font-extrabold text-white kakaoClass h-[40px] w-[108px] rounded-[28px]"
                        >
                          {strings.LIST}
                        </button>{" "}
                        {memberDetail?.memberOwnProducts[items]?.isExpired !==
                          1 && (
                          <button
                            onClick={() => {
                              setLeftDays(
                                memberDetail?.memberOwnProducts[items]
                                  ?.isExpired == 0
                                  ? false
                                  : true
                              );
                              setHolding(true);
                              setIsList({
                                status: true,
                                type: memberDetail?.memberOwnProducts[items]
                                  ?.typeValue,
                              });
                              setMemberProductId({
                                memberId: memberDetail?.memberId,
                              });
                              setHoldingLeft(
                                memberDetail?.memberOwnProducts[items]
                                  ?.holdingLeft
                              );
                            }}
                            className="hover:text-black hover:bg-[#CCFF00] text-sm font-extrabold text-white kakaoClass h-[40px] w-[108px] rounded-[28px]"
                          >
                            {strings.HOLDING}
                          </button>
                        )}
                      </div>
                    </div>
                  </Col>
                )
              )
            : ""}
        </Row>

        <div className="flex-wrap flex items-center justify-center gap-3 mt-6">
          <button
            onClick={() => {
              setShowRegisterForm(true);
              setAddProduct(memberDetail);
              setShowMoreInfo(false);
            }}
            className={`${"max-sm:w-full hover:text-black hover:bg-[#CCFF00] text-white"} ${"text-base font-bold kakaoClass h-[52px] w-[160px] rounded-[28px]"}`}
          >
            {strings.ADD_PRODUCT}
          </button>{" "}
          <button
            onClick={() => setOpenTakeOver(true)}
            className={`${
              openTakeOver
                ? "bg-[#CCFF00] text-black"
                : "hover:text-black hover:bg-[#CCFF00] text-white"
            } ${"max-sm:w-full text-base font-bold kakaoClass h-[52px] w-[160px] rounded-[28px]"}`}
          >
            {strings.TAKE_OVER}
          </button>
          <button
            onClick={() => {
              setRefundModal(true);
            }}
            className={`${
              refundModal
                ? "bg-[#CCFF00] text-black"
                : "hover:text-black hover:bg-[#CCFF00] text-white kakaoClass"
            } ${"max-sm:w-full text-base font-bold kakaoClass h-[52px] w-[160px] rounded-[28px]"}`}
          >
            {strings.REFUND}
          </button>{" "}
          <button
            onClick={() => {
              setShowMoreInfo(false);
              toStatus && navigate("/PT-information/Status");
            }}
            className="max-sm:w-full hover:text-black hover:bg-[#CCFF00] text-base font-bold text-white kakaoClass h-[52px] w-[160px] rounded-[28px]"
          >
            {strings.CLOSE}
          </button>
        </div>
      </div>
      <MemberTakeOver
        classNameValue={classNameValue}
        openTakeOver={openTakeOver}
        setOpenTakeOver={setOpenTakeOver}
        userCode={memberDetail?.userCode}
        setIsLoader={setIsLoader}
        setIsUpdate={setIsUpdate}
        isUpdate={isUpdate}
        id={id}
      />
      <ListingModal
        classNameValue={classNameValue}
        listType={listType}
        setListType={setListType}
        isList={isList}
        setIsLoader={setIsLoader}
        setIsList={setIsList}
        id={id}
        holding={holding}
        setMemberProductId={setMemberProductId}
        memberDetail={memberDetail}
        memberProductId={memberProductId}
        setHoldingList={setHoldingList}
      />
      <HoldingModal
        classNameValue={classNameValue}
        holdingList={holdingList}
        setHoldingList={setHoldingList}
        holdingLeft={holdingLeft}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        endDate={endDate}
        startDate={startDate}
        memberProductId={memberProductId}
        clickBtn={handleHolding}
        leftDays={leftDays}
      />
      {memberDetail && memberDetail.memo.length > 0 && (
        <MemoListModal
          classNameValue={classNameValue}
          memoModal={memoModal}
          setMemoModal={setMemoModal}
          id={id}
          setIsUpdate={setIsUpdate}
          setIsLoader={setIsLoader}
          memoList={
            memberDetail && memberDetail.memo.length > 0 && memberDetail.memo
          }
        />
      )}

      <PurchaseListModal
        classNameValue={classNameValue}
        refundModal={refundModal}
        setRefundModal={setRefundModal}
        setIsLoader={setIsLoader}
        id={id}
      />
      <AuthModal
        authModal={authModal}
        setAuthModal={setAuthModal}
        classNameValue={classNameValue}
      />
      {changeTrainer && (
        <TrainerList
          setTrainerValue={setTrainerValue}
          trainerValue={trainerValue}
          setTrainerModal={setChangeTrainer}
          classNameValue={classNameValue}
          setIsLoader={setIsLoader}
          trainerId={userDetail?.trainerId}
          memberId={userDetail?.memberId}
          isChangeTrainer={true}
        />
      )}
    </section>
  );
}

export default MemberInfoDetail;
