import React, { useEffect, useState } from "react";
import logo from "../assests/attendanceIcons/logo-icon.svg";
import demoProfile from "../assests/images/demoProfile.svg";

import { strings } from "../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import AttendanceSublyApi from "../helper/AttendanceApi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NotValidInfoModal from "./NotValidInfoModal";
import { PRODUCT_IMAGE, PRODUCT_TYPES_LIST } from "../utils/CommonData";
import moment from "moment";

function UserInfoScreen() {
  const { token } = useSelector((state) => state.user.attendanceDetail);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState();

  // ==============APi for fetch user detail API================
  useEffect(() => {
    userDetailHandle();
  }, []);

  async function userDetailHandle() {
    await AttendanceSublyApi.fetchUserDetail(token, state)
      .then((response) => {
        if (response.statusCode == 200) {
          setUserDetail(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setTimeout(() => {
    navigate("/Attendance/Member");
  }, 5000);


  return (
    <section className="flex items-center justify-center my-5">
      <div className="w-[74%] max-lg:w-[80%] max-md:w-[90%]">
        <img src={logo} className="m-auto" />
        <p className="text-[30px] my-3 font-extrabold text-center">
          Power House Gym
        </p>
        <div className="bg-[#2C2C2E] p-3 rounded-[10px]">
          <p className="text-[36px] font-bold text-center">
            <span className="text-common_color">
              Welcome {userDetail?.name.split(" ")[0]}!
            </span>{" "}
            You've entered the gym.
          </p>
          <div className="flex flex-col items-center justify-center">
            <img
              src={userDetail?.mProfileImg?.prof_img1_min || demoProfile}
              alt="profile"
              className="rounded-full w-[110px] h-[110px]"
            />
            <p className="text-[30px] font-extrabold">{userDetail?.name}</p>
          </div>
          <div className="w-[55%] m-auto">
            {userDetail?.products?.map((item, index) => (
              <div className="flex items-center justify-between gap-3 border-b border-[#444444] mb-2.5 pb-3">
                <div className="flex items-center gap-2 justify-start">
                  <img src={PRODUCT_IMAGE[item.type]} alt="card-icon" />
                  <p className="mb-0 font-bold text-2xl">
                    {PRODUCT_TYPES_LIST[item.type]} :
                  </p>
                </div>
                <div>
                  <p className="mb-0 text-2xl font-bold">
                    {moment(item?.membershipStartDate).format("YYYY.MM.DD")} -{" "}
                    {moment(item?.membershipEndDate).format("YYYY.MM.DD")}
                  </p>
                  {item?.daysLeft && (
                    <p className="mb-0 text-[22px] font-bold">{`(${item.daysLeft} days remaining)`}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center pt-2.5 mb-3">
            <button
              className={`flex justify-center gap-2 items-center w-[155px] text-2xl font-extrabold text-black rounded-[30px] p-2.5 bg-common_color`}
            >
              {strings.CLOSE}
              <Icon
                icon="gg:timer"
                width="25"
                height="25"
                style={{ color: "black" }}
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserInfoScreen;
