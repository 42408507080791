import React from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify-icon/react";

function InfoModal({ infoModal, setInfoModal, infoDate }) {
  return (
    <>
      <Modal
        className={`${"modalBoxClass modalWidthClass"}`}
        show={infoModal}
        onHide={() => {
          setInfoModal(false);
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <div className="border-b-2 border-[#555555]">
            <h3 className="flex items-center text-[#FF9191] justify-center gap-3  max-lg:text-[28px] pb-4 mb-6 text-[40px] font-extrabold w-[80%] m-auto ">
              {strings.INFORMATION_NOT_FOUNDED}
            </h3>
            <div className="flex items-center justify-center gap-2 mb-3">
              <Icon
                icon="lets-icons:date-today"
                width="28"
                height="28"
                style={{ color: "#CCFF00" }}
              />
              <p className="!mb-0 text-2xl font-extrabold">{infoDate}</p>
            </div>
          </div>
          <p className="text-[30px] max-lg:text-[18px] font-bold pt-4 m-auto w-[70%]">
            {/* {`The status of ${productStatus.productType} has been changed to`}{" "} */}
            <span className="text-white text-[33px] max-lg:text-[20px] font-bold ">
              {/* {productStatus.action ? "On" : "Off"} */}
            </span>
          </p>
          <p className="mb-0 text-[30px] max-lg:text-[18px] font-bold">
            Center Closed on {infoDate} and the trainer <br /> was off due to
            holiday
          </p>
          <div className="flex justify-center pt-8 gap-4">
            <button
              onClick={() => {
                setInfoModal(false);
              }}
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] text-white font-extrabold h-[60px] w-[160px] rounded-[28px] border-[1px] border-[#CCFF00]"
            >
              {strings.CLOSE}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InfoModal;
