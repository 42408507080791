import React from "react";
import { strings } from "../../../utils/String";
import { Modal } from "react-bootstrap";

function UpdateStaffAuthority({
  updateStaffHandle,
  updateModal,
  setUpdateModal,
  modifiedStaff,
  setUpdatedValue,
}) {
  return (
    <>
      <Modal
        className={`${"modalBoxClass modalWidthClass"}`}
        show={updateModal}
        onHide={() => {
          setUpdateModal(false);
          setUpdatedValue("");
        }}
      >
        <Modal.Body className="text-white bg-box_bg text-center py-8 px-8">
          <p className="border-b-2 border-[#444444] pb-3 mb-6 text-[40px] max-lg:text-[28px] font-extrabold">
            {strings.UPDATE_STAFF_AUTHORITY}{" "}
          </p>
          <p className="text-[30px] max-lg:text-[18px] m-auto w-4/5 max-lg:w-full font-bold">
            {strings.UPDATE_STAFF_SUB_TEXT}
          </p>
          <p className="text-[30px] max-lg:text-[18px] m-auto w-4/5 max-lg:w-full font-bold pb-3">
            {strings.PLEASE_CHECK_THE_BELOW}
          </p>
          <p className="mb-0 text-[36px] max-lg:text-[22px] m-auto w-4/5 max-lg:w-full font-bold pb-2 pt-2">
            {strings.SATFF_AUTHORITY_HAS_BEEN_CHANGE}{" "}
            {modifiedStaff?.length > 0 &&
              modifiedStaff?.map((item, index) => (
                <span
                  key={index}
                  className="text-[36px] max-lg:text-[22px] text-white"
                >
                  {item.name}
                  {index !== modifiedStaff?.length - 1 && ", "}
                </span>
              ))}
          </p>
          <div className="flex justify-center pt-8 gap-4">
            <button
              onClick={() => {
                setUpdatedValue("");
                setUpdateModal(false);
              }}
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] font-extrabold text-white kakaoClass  h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CANCEL}
            </button>
            <button
              onClick={() => updateStaffHandle()}
              className="text-[24px] max-lg:text-[18px] max-lg:h-[45px] text-black bg-common_color font-extrabold h-[60px] w-[160px] rounded-[28px]"
            >
              {strings.CONFIRM}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdateStaffAuthority;
