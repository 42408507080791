import React, { useEffect, useState } from "react";
import { placeholder, strings } from "../../../../../utils/String";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import ReactDatePicker from "react-datepicker";
import GymSettingSublyApi from "../../../../../helper/GymSettingApi";
import { useSelector } from "react-redux";
import Loader from "../../../../../utils/Loader";
import ErrorModal from "../../../../commonComponents/modalScreens/ErrorModal";
import { alphaOnly } from "../../../../../utils/commonUtils";
import $ from "jquery";
import moment from "moment";
import { DatePicker } from "rsuite";
import { HiCalendar } from "react-icons/hi2";
function EditLockerModal({
  seteditLocker,
  lockerData,
  setLockerId,
  classNameValue,
  setIsLoading,
}) {
  // console.log("-----lockerData", lockerData);
  const { token } = useSelector((state) => state.user);
  const [isError, setIsError] = useState("");
  const [eventType, setEventType] = useState({
    text: lockerData?.status,
    type: "",
  });
  const [openType, setOpenType] = useState(false);
  const [isAlways, setisAlways] = useState(
    lockerData.name == null ? true : false
  );
  const [lockerNumber, setLockerNumber] = useState(lockerData?.lockerNumber);
  const [lockerPassword, setLockerPassword] = useState(
    lockerData?.lockerPassword
  );
  const [lockerUserName, setLockerUserName] = useState(lockerData?.name);
  const [lockerRegDate, setLockerRegDate] = useState(
    lockerData?.regDate ? new Date(lockerData?.regDate) : ""
  );
  const [lockerStartDate, setLockerStartDate] = useState(
    lockerData?.startDate ? new Date(lockerData?.startDate) : ""
  );
  const [lockerEndtDate, setLockerEndDate] = useState(
    lockerData?.endDate ? new Date(lockerData?.endDate) : ""
  );
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [openCalender, setOpenCalender] = useState(false);
  const [registerDate, setRegisterDate] = useState(lockerData?.regDate);
  const [startDate, setStartDate] = useState(lockerData?.startDate);
  const [endDate, setEndDate] = useState(lockerData?.endDate);
  const [isCalenderOpen, setIsCalenderOpen] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [datePickerCheck, setDatePickerCheck] = useState("");

  async function updateLocker() {
    setIsLoading(true);
    const updateData = {
      name: lockerUserName,
      lockerNumber: lockerNumber,
      lockerPassword: lockerPassword,
      regDate: lockerRegDate,
      startDate: lockerStartDate,
      endDate: lockerEndtDate,
    };
    await GymSettingSublyApi.editLocker(token, updateData, lockerData.id).then(
      (response) => {
        setIsLoading(false);
        if (response.statusCode == 200) {
          seteditLocker(false);
          setLockerId("");
        } else {
          setOpenModal(true);
          seterrorMessage(response.data.message);
          setLockerId("");
        }
      }
    );
  }

  // =======Condition for close form while click outside the form=========
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".dropdownClass").length == 0) {
      setOpenType(false);
    }
  });

  //--------function for handling name input filed------
  $("#name").bind("keypress", alphaOnly);

  const filterMonthDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(registerDate).getMonth();
    return condition;
  };
  const filterStartDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(startDate).getMonth();
    return condition;
  };
  const filterEndDates = (date) => {
    const condition =
      new Date(date).getMonth() === new Date(endDate).getMonth();
    return condition;
  };

  useEffect(() => {
    const datepickerId =
      datePickerCheck == 2
        ? document.getElementById("datepicker-2-grid-label")
        : datePickerCheck == 1
        ? document.getElementById("datepicker-1-grid-label")
        : datePickerCheck == 3
        ? document.getElementById("datepicker-3-grid-label")
        : datePickerCheck == 4
        ? document.getElementById("datepicker-4-grid-label")
        : "";
    if (isCalenderOpen) {
      datepickerId.innerText = moment(currentMonth).format("yyyy.MM");
    }
    // ================Adding a class to picker===========
    $(document).ready(function () {
      $('[data-testid="picker-popup"]').addClass(classNameValue);
    });
  }, [isCalenderOpen, currentMonth]);

  return (
    <section
      className={` ${"absolute top-0 left-auto right-0 !w-full !h-full flex 2xl:items-center  justify-center bg-[#000000c2] z-20"}`}
    >
      <div
        className={`${classNameValue} ${"bg-[#353638] rounded-[10px] w-[70%] max-lg:w-[90%] 2xl:h-[58] max-lg:p-4 max-lg:py-8 overflow-auto p-6 pb-4 mt-24 sm:mt-0 z-20 "}`}
      >
        <div className="w-[95%] max-lg:w-[100%] m-auto ">
          <div className="w-[78%] m-auto border-b border-[#505050]">
            <p className="mb-0 text-[40px] max-lg:text-[36px] text-center font-bold pb-4">
              {strings.EDIT_LOCKER}
            </p>
          </div>
          <div className="px-32 max-md:px-0">
            <div className="mt-4 flex flex-wrap justify-center gap-3 ">
              <div className=" w-full sm:flex-1">
                <label
                  htmlFor="nameId"
                  className={`
                ${isError && !eventType.type ? "inputErrorClass" : ""} 
                ${" w-full mb-2.5 relative"}`}
                >
                  <span className="text-base font-normal text-[#fff] ml-2 spanClass">
                    {strings.LOCKER_NUMBER}
                  </span>
                  <div className="flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 errorBorder">
                    <input
                      type="text"
                      className="text-white bg-[#414142] border-none w-full font-bold placeholder:font-normal"
                      id="nameId"
                      value={lockerNumber}
                      onChange={(e) => {
                        setLockerNumber(e.target.value);
                      }}
                      // disabled
                      placeholder={strings.LOCKER_NUMBER}
                    />
                  </div>
                </label>
              </div>
              <div className="w-full sm:flex-1">
                <label
                  htmlFor="nameId"
                  className={`
                ${isError && !eventType.type ? "inputErrorClass" : ""} 
                ${" w-full mb-2.5 relative"}`}
                >
                  <span className="text-base font-normal text-[#fff] ml-2 spanClass">
                    {strings.PASSWORD}:
                  </span>
                  <div className="flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 errorBorder">
                    <input
                      type="text"
                      className="text-white bg-[#414142] border-none w-full font-bold placeholder:font-normal"
                      id="nameId"
                      value={lockerPassword}
                      onChange={(e) => {
                        setLockerPassword(e.target.value);
                      }}
                      // disabled
                      placeholder={placeholder.SELECT_EVENT_TYPE}
                    />
                  </div>
                </label>
              </div>
            </div>
            <label
              htmlFor="nameId"
              // onClick={() => {
              //   setOpenType(!openType);
              // }}
              className={`
                ${isError && !eventType.type ? "inputErrorClass" : ""} 
                ${" w-full mb-2.5 relative"}`}
            >
              <span className="text-base font-normal text-[#fff] ml-2 spanClass">
                {strings.USER_NAME}
              </span>
              <div className="flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 errorBorder">
                <input
                  type="text"
                  className="text-white bg-[#414142] w-full !border-0 font-bold placeholder:font-normal"
                  id="name"
                  value={lockerUserName}
                  onChange={(e) => {
                    setLockerUserName(e.target.value);
                  }}
                  // disabled
                  placeholder={placeholder.USER_NAME}
                  autoComplete="off"
                />
              </div>
            </label>
            <div className=" flex flex-wrap justify-center gap-3 ">
              <div className="w-full sm:flex-1">
                <div className="max-lg:w-full">
                  <p className="mb-0 ml-2 text-base font-normal text-[#fff] ">
                    {strings.REGISTRATION_DATE}
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 newDateInputClass">
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-1"
                      placeholder={strings.START_DATE_SMALL}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={lockerRegDate}
                      onChange={(date) => setLockerRegDate(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setIsCalenderOpen(true);
                        setDatePickerCheck(1);
                        setCurrentMonth(
                          lockerRegDate ? new Date(lockerRegDate) : new Date()
                        );
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          lockerRegDate ? new Date(lockerRegDate) : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < maxDate;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full sm:flex-1">
                <label
                  htmlFor="nameId"
                  onClick={() => {
                    setOpenType(!openType);
                  }}
                  className={`
                ${isError && !eventType.type ? "inputErrorClass" : ""} 
                ${" w-full mb-2.5 relative dropdownClass"}`}
                >
                  <span className="text-base font-normal text-[#fff] ml-2 spanClass">
                    {strings.STATUS}:
                  </span>
                  <div className="flex items-center cursor-pointer gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 errorBorder">
                    <input
                      type="text"
                      className="text-white bg-[#414142] !border-0 font-bold placeholder:font-normal"
                      id="nameId"
                      value={eventType.text}
                      disabled
                      placeholder={placeholder.SELECT_EVENT_TYPE}
                    />
                    {openType ? (
                      <Icon
                        icon="flowbite:caret-up-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE" }}
                      />
                    ) : (
                      <Icon
                        icon="flowbite:caret-down-solid"
                        width="20"
                        height="20"
                        style={{ color: "#BEBEBE" }}
                      />
                    )}
                  </div>
                  {openType && <ProductFilter setEventType={setEventType} />}
                </label>
              </div>
            </div>
            <div className=" flex flex-wrap justify-center gap-3 ">
              <div className="w-full sm:flex-1">
                <div className="">
                  <p className="mb-0 ml-2 text-base font-normal text-[#fff] ">
                    {strings.START_DATE}
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 newDateInputClass">
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-2"
                      placeholder={strings.START_DATE_SMALL}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={lockerStartDate}
                      onChange={(date) => setLockerStartDate(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setIsCalenderOpen(true);
                        setDatePickerCheck(2);
                        setCurrentMonth(
                          lockerStartDate
                            ? new Date(lockerStartDate)
                            : new Date()
                        );
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          lockerStartDate
                            ? new Date(lockerStartDate)
                            : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < maxDate;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full sm:flex-1">
                <div className="w-full sm:flex-1">
                  <p className="mb-0 ml-2 text-base font-normal text-[#fff] ">
                    {strings.END_DATE}
                  </p>
                  <div className="flex items-center gap-2 justify-between bg-[#414142] rounded-[10px] h-[45px] px-3 newDateInputClass">
                    <DatePicker
                      oneTap
                      caretAs={HiCalendar}
                      id="datepicker-3"
                      placeholder={strings.END_DATE}
                      format="yyyy.MM.dd"
                      block
                      editable={false}
                      appearance="subtle"
                      className="custom-datepicker bg-transparent border-0 w-full px-0"
                      value={lockerEndtDate}
                      onChange={(date) => setLockerEndDate(date)}
                      onChangeCalendarDate={(e) => {
                        setCurrentMonth(e);
                      }}
                      onNextMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onPrevMonth={(e) => {
                        setCurrentMonth(e);
                      }}
                      onOpen={() => {
                        setIsCalenderOpen(true);
                        setDatePickerCheck(3);
                        setCurrentMonth(
                          lockerEndtDate ? new Date(lockerEndtDate) : new Date()
                        );
                      }}
                      onClose={() => {
                        setIsCalenderOpen(false);
                        setCurrentMonth(
                          lockerEndtDate ? new Date(lockerEndtDate) : new Date()
                        );
                        setDatePickerCheck("");
                      }}
                      disabledDate={(date) => {
                        const minDate = new Date(lockerStartDate);
                        const maxDate = new Date(
                          Date.now() - 24 * 60 * 60 * 1000
                        );
                        return date < minDate || date < maxDate;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={` ${"flex items-center justify-center flex-wrap gap-x-3 gap-y-2.5 my-10"}`}
          >
            <button
              onClick={() => {
                seteditLocker(false);
                setLockerId("");
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.CANCEL}
            </button>
            <button
              onClick={() => {
                updateLocker();
              }}
              className=" max-sm:w-full text-2xl font-extrabold rounded-[30px] text-white kakaoClass hover:text-black hover:bg-common_color w-[160px] h-[60px] disabled:bg-[#ccff0094]"
            >
              {strings.SAVE}
            </button>
          </div>
        </div>
      </div>
      <ErrorModal
        openModal={openModal}
        closeModal={setOpenModal}
        errorMessage={errorMessage}
        classNameValue={classNameValue}
      />
    </section>
  );
}

export default EditLockerModal;

function ProductFilter({ setEventType }) {
  return (
    <div className="absolute rounded-[10px] bg-[#5C5C5C] min-w-[200px] right-0 top-18 max-h-[280px] overflow-auto z-10 px-4 py-1">
      <div
        onClick={() => {
          setEventType({ text: strings.IN_USE, type: 0 });
        }}
        className="flex items-center gap-0.5 hover:!text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.IN_USE}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.EXPIRED, type: 1 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.EXPIRED}</span>
      </div>
      <div
        onClick={() => {
          setEventType({ text: strings.EMPTY, type: 2 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.EMPTY}</span>
      </div>
      {/* <div
        onClick={() => {
          setEventType({ text: strings.FIXED, type: 3 });
        }}
        className="flex items-center gap-0.5 hover:text-[#CCFF00] text-white py-1 cursor-pointer border-b border-[#44444480]"
      >
        <span className="mb-0 text-lg font-bold">{strings.FIXED}</span>
      </div> */}
    </div>
  );
}
